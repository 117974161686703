
<template>
  <!-- 新增角色认知 -->
  <div class="report" id="report" :class="theme">
    <div class="top">
      <header>
        <img :src="avatar" class="headImg">
        <div class="label">
          <b>认知差异分析报告</b><br>
          {{$formatTime(endTime,'y-m-d h:m')}}
          <!-- <span v-if="version">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;版本号 v{{version}}</span> -->
        </div>
      </header>
      <nav v-if="!long">
        <span :class="{current:tabId==1}" @click="tab(1)">工作能量差异分析</span>
        <span v-if="leadership.length" :class="{current:tabId==2}" @click="tab(2)">领导能量差异分析</span>
      </nav>
    </div>
    <ul class="content">
      <!-- 工作能量差异分析 -->
      <div class="title" v-if="long">工作能量差异分析</div>
      <li class="energy" v-show="tabId==1 || long">
        <div class="tips">
          <div>工作能量说明：</div>
          <p>工作能量是个体在工作场景中展现出来的综合状态，源自于个体的内在工作价值倾向，在原动力和职业素质上体现；主要包含自我经营、成就动机、利他思维、乐观坚韧、人际沟通、学习成长、高效行动、规划能力8个方面。</p>
          <p>一般来说，自身和他人的认知存在差异，积极了解差异及背后原因，有助于个体取得更好的成就。</p>
        </div>
        <difference :data="work_energy" :animate="!long"></difference>
        <div class="c_tip"><b>差值</b>为自我认知与他人认知之间的数值。</div>
        <div class="legend legend0">
          <div>自我认知</div>
          <div>他人认知</div>
        </div>
        <div class="word">
          <p v-for="item in energy_text" :key="item" v-html="item"></p>
        </div>
      </li>
      <!-- 领导能量差异分析 -->
      <template v-if="leadership.length">
        <div class="title" v-if="long">领导能量差异分析</div>
        <li class="energy" v-show="tabId==2 || long">
          <div class="tips">
            <div>领导能量说明：</div>
            <p>领导能量是领导行为综合作用的结果，是从管理者个人出发，到团队经营，再到组织成果产出的完整管理闭环；主要包含以身作则、共启愿景、突破创新、激励沟通、推动执行、确保成效六项关键领导行为。</p>
            <p>一般来说，自身和他人对认知存在差异，积极了解差异及背后原因，有助于领导行为的改善，进而提升领导的质量。</p>
          </div>
          <difference :data="leadership" :colotType="1" :animate="!long"></difference>
          <div class="c_tip"><b>差值</b>为自我认知与他人认知之间的数值。</div>
          <div class="legend legend1">
            <div>自我认知</div>
            <div>他人认知</div>
          </div>
          <div class="word">
            <p v-for="item in leadership_text" :key="item" v-html="item"></p>
          </div>
        </li>
      </template>
    </ul>
  </div>
</template>
<script>
import difference from './chart/difference'
export default {
  components:{difference},
  data(){
    return{
      theme: this.$route.query.theme || '',
      tabId: 1, 
      exam_id: this.$route.params.exam_id || '',
      other_ids: this.$route.query.otherId || '',
      long: this.$route.query.long===undefined?false:true,  //是否报告长图
      endTime: 0,                                           //报告生成成时间
      avatar: '',
      work_energy: [],
      energy_text: [],
      leadership: [],
      leadership_text: []
    }
  },
  created(){
    this.$indicator.open({text: '加载中...',spinnerType: 'fading-circle'})
    this.$http.get(`${this.spaceUrl}/CognitiveDifferences/getExamReportInfo`, {
      params: {
        exam_id: this.exam_id,
        other_exam_id: this.other_ids
      }
    }).then(({data}) => {
      this.$indicator.close()
      if (data.code == 0) {
        this.avatar = data.data.user_info.avatar
        this.endTime = data.data.user_info.end_time
        //工作能量差异分析
        let energy = data.data.work_energy
        this.work_energy = energy
        this.energy_text = this.getText(energy)
        //领导能量差异分析
        let leadership = data.data.leadership
        this.leadership = leadership
        this.leadership_text = this.getText(leadership)
      } else {
        this.$toast({message:'加载失败',duration: 2000})
      }
    }).catch(err => {
      this.$indicator.close();
      console.error(err);
      this.$toast({message:'出错了',duration: 1500})
    })
  },
  methods:{
    tab(e){
      if (this.tabId == e) return;
      this.tabId = e;
    },
    getText(obj){
      let text = []
      for (let i in obj) {
        if (obj[i].difference_score <= 15) 
          text[0] = text[0] ? `“${obj[i].name}”、${text[0]}` : `“${obj[i].name}”方面，认知<b style="color:#4573d7">比较一致</b>。`
        else if (obj[i].difference_score <= 35)
          text[1] = text[1] ? `“${obj[i].name}”、${text[1]}` : `“${obj[i].name}”方面，认知<b style="color:#5ec1ff">差异较小</b>。`
        else if (obj[i].difference_score <= 65)
          text[2] = text[2] ? `“${obj[i].name}”、${text[2]}` : `“${obj[i].name}”方面，认知<b style="color:#de67c7">差异略大</b>。`
        else if (obj[i].difference_score <= 85)
          text[3] = text[3] ? `“${obj[i].name}”、${text[3]}` : `“${obj[i].name}”方面，认知<b style="color:#f1994c">差异明显</b>。`
        else
          text[4] = text[4] ? `“${obj[i].name}”、${text[4]}` : `“${obj[i].name}”方面，认知<b style="color:#ee626e">差异较大</b>。`
      }
      text.filter(x => true)
      for (let i in text) text[i] = '在' + text[i]
      return text
    },
  },
}
</script>
<style scoped lang="scss">
.report{
  color:#6f6f6f;
  box-shadow: 0 0 10px rgba(200,200,200,.6);
  padding-bottom: 40px;
  height: 100%;
  overflow-y: auto;
  position: relative;
  .top{
    header{
      display: flex;
      align-items: center;
      font-size: 26px;
      color: #aaa;
      padding: 0 40px;
      height: 140px;
      line-height: 1.1;
      border-bottom: 1px solid #f0f0f0;
      .headImg{
        width: 76px;
        height: 76px;
        border-radius: 50%;
        overflow: hidden;
        display: block;
        margin-right: 20px;
        background: #ccc;
        box-shadow: 0 0 6px rgba(200,200,200,.5)
      }
      .label{
        text-align: left;
        padding-right: 20px;
        font-size: 24px;
        line-height: 1.5;
        b{font-size: 26px}
      }
      .btn{
        width: 130px;
        color: #fff;
        border-radius: 6px;
        background: #33a1fb;
        line-height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 24px;
        padding: 0 10px;
        flex-shrink: 0;
        white-space: nowrap;
        margin-left: 10px;
      }
    }
    nav{
      display: flex;
      border-bottom: 1px solid #f0f0f0;
      color: #999;
      height: 80px;
      box-sizing: border-box;
      font-size: 26px;
      align-items: center;
      &::-webkit-scrollbar{display: none}
      span{
        flex: 1;
        line-height: 1.2;
        height: 100%;
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
      }
      .current{
        font-size: 26px;
        color: #1a1a1a;
        font-weight: bold;
        &::after{
          content: '';
          position: absolute;
          width: 100%;
          height: 4px;
          background: #33a1fb;
          bottom: -2px;
          left: 0;
          z-index: 2;
        }
      }
    }
  }
  .content{
    .title{
      line-height: 80px;
      font-weight: bold;
      font-size: 30px;
      margin-top: 60px;
      color: #272727;
    }
    li{
      margin: 30px 24px;
      border-radius: 10px;
      padding: 40px 0;
      .orange{
        color: #ee7e2e;
        font-size: 28px;
        text-align: left;
        margin: 40px 0 18px 24px;
      }
      h1{
        font-size: 28px;
        text-align: left;
        font-weight: bold;
        margin: 20px 0;
        color: #5c5c5c;
      }
      p{
        padding-left: 24px;
        text-align: justify;
        font-size: 26px;
        position: relative;
        color: #666;
        line-height: 1.5;
        &::before{
          content: '';
          position: absolute;
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background: #7a7a7a;
          left: 4px;
          top: 15px;
        }
      }
      .text{
        text-align: justify;
        font-size: 26px;
        color: #6f6f6f;
        line-height: 1.5;
        b{color: #dd331e}
      }
      .padding{
        height: 14px;
        background: #f2f2f2;
        position: relative;
        margin: 80px 0 0;
        &::before{
          content: '';
          position: absolute;
          width: 100px;
          height: 100%;
          background: #f2f2f2;
          right: 100%;
          top: 0;
        }
        &::after{
          content: '';
          position: absolute;
          width: 100px;
          height: 100%;
          background: #f2f2f2;
          left: 100%;
          top: 0;
        }
      }
      .high span{color: #3fb3ff;}
      .high::before{background: #3fb3ff;}
      .low span{color: #e86c52;}
      .low::before{background: #e86c52;}
      .blue{color: #3fb3ff}
      .yellow{color: #ebb748}
      .red{color: #f8887b}
      .green{color: #88b56c}
    }
    .energy{
      .legend{
        text-align: center;
        div{
          display: inline-block;
          font-size: 28px;
          margin: 40px;
          position: relative;
          padding-left: 30px;
          &::before{
            content: '';
            position: absolute;
            width: 20px;
            height: 20px;
            left: 0;
            top: 10px;
            border-radius: 50%;
            background: #ccc;
          }
        }
      }
      .legend0{div:nth-child(1){color: #46b6ff;&::before{background: #46b6ff}}}
      .legend0{div:nth-child(2){color: #f9973f;&::before{background: #f9973f}}}
      .legend1{div:nth-child(1){color: #26cbdf;&::before{background: #26cbdf}}}
      .legend1{div:nth-child(2){color: #ff6299;&::before{background: #ff6299}}}
    }
    .tips{
      margin: 0 24px 50px;
      font-size: 26px;
      color: #595959;
      text-align: left;
      line-height: 1.6;
    }
    .c_tip{
      margin-top: 20px;
      b{color: #33a1fb}
    }
    .word{
      margin: 50px 30px 20px;
      font-size: 28px;
    }
  }
}
.planet{
  background-color: #2da8ff;
  background-image: url('../../../../static/img/planet_bg14.jpg');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  .top{
    header{color: #fff;}
    nav{
      color: #fff;
      border-color: #fff;
      .current{
        background: #c0e9fa;
        color: #414b5e;
      }
    }
  }
  .content{
    .title{color: #fff}
    li{
      background: rgba(255,255,255,.8);
      p{color: #414b5e}
    }
  }
}
</style>