<template>
  <div class="report" :class="{long:long}">
    <div class="top">
      <header>
        <div class="user">
          <b>{{org_name}}</b> 层级报告分析<br>
          {{$formatTime(time,'y-m-d h:m')}}<span v-if="version">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;版本号 v{{version}}</span>
        </div>
        <div class="btn" v-if="!long" @click="save">报告长图</div> 
      </header>
      <nav v-if="!long">
        <span :class="{current:tabId==0}" @click="tab(0)">TVI</span>
        <span :class="{current:tabId==1}" @click="tab(1)" v-if="team_leadership">领导能量</span>
        <span :class="{current:tabId==2}" @click="tab(2)">工作价值倾向</span>
      </nav>
    </div>
    <ul class="content">
      <!-- 团队活力 -->
      <div class="title" v-if="long">团队活力</div>
      <li class="TVI" v-show="tabId==0 || long">
        <lineCircle :name="'TVI'" :data="team_activity.dim_score" :width="width*.89"></lineCircle>
        <div class="legend">
          <div v-for="(item,i) in team_activity.tier_info" :key="i">
            <div class="circle" :class="'circle'+item.tier_id"><i></i></div>{{item.name}}
          </div>
        </div>
        <div class="index">
          <h6 v-for="(item,i) in team_activity.tier_info" :key="i">{{item.name}}TVI指数：{{Math.floor(item.score)}}</h6>
        </div>
        
        <h1>通过团队成员的答题，我们了解到：</h1>
        <p>{{team_activity.top_text}}</p>
        <p>{{team_activity.low_text}}</p>

        <lineRectChart :data="team_activity.tier_consistency_score" :width="width*.89" :animate="long"></lineRectChart>
        <div class="legend" style="margin-bottom:20px">
          <div v-for="(item,i) in team_activity.tier_info" :key="i">
            <div class="rect" :class="'rect'+item.tier_id"><i></i></div>{{item.name}}
          </div>
        </div>

        <h1>通过团队成员的答题，我们了解到：</h1>
        <p v-for="(item,i) in team_activity.tier_info" :key="i">{{item.name}}在“{{team_activity.tier_consistency_content[item.tier_id].top_dimension}}”方面一致性较高，在“{{team_activity.tier_consistency_content[item.tier_id].low_dimension}}”方面一致性较低。</p>
      </li>
      <!-- 领导能量 -->
      <div class="title" v-if="long">领导能量</div>
      <li v-show="tabId==1 || long" v-if="team_leadership">
        <lineCircle :name="'领导能量'" :data="team_leadership.dim_score" :width="width*.89"></lineCircle>
        <div class="legend">
          <div v-for="(item,i) in team_leadership.tier_info" :key="i">
            <div class="circle" :class="'circle'+item.tier_id"><i></i></div>{{item.name}}
          </div>
        </div>
        <div class="index">
          <h6 v-for="(item,i) in team_leadership.tier_info" :key="i">{{item.name}}指数：{{Math.floor(item.score)}}</h6>
        </div>
        
        <h1>通过团队成员的答题，我们了解到：</h1>
        <p>{{team_leadership.top_text}}</p>
        <p>{{team_leadership.low_text}}</p>

        <lineRectChart :data="team_leadership.tier_consistency_score" :width="width*.89" :animate="long"></lineRectChart>
        <div class="legend" style="margin-bottom:20px">
          <div v-for="(item,i) in team_leadership.tier_info" :key="i">
            <div class="rect" :class="'rect'+item.tier_id"><i></i></div>{{item.name}}
          </div>
        </div>

        <h1>通过团队成员的答题，我们了解到：</h1>
        <p v-for="(item,i) in team_leadership.tier_info" :key="i">{{item.name}}在“{{team_leadership.tier_consistency_content[item.tier_id].top_dimension}}”方面一致性较高，在“{{team_leadership.tier_consistency_content[item.tier_id].low_dimension}}”方面一致性较低。</p>
      </li>
      <!-- 工作价值倾向 -->
      <div class="title" v-if="long">工作价值倾向</div>
      <li v-show="tabId==2 || long">
        <lineCircle :name="'工作价值倾向'" :data="team_workvalue.dim_score" :width="width*.89"></lineCircle>
        <div class="legend">
          <div v-for="(item,i) in team_workvalue.tier_info" :key="i">
            <div class="circle" :class="'circle'+item.tier_id"><i></i></div>{{item.name}}
          </div>
        </div>
        <div class="index">
          <h6 v-for="(item,i) in team_workvalue.tier_info" :key="i">{{item.name}}指数：{{Math.floor(item.score)}}</h6>
        </div>
        
        <h1>通过团队成员的答题，我们了解到：</h1>
        <p>{{team_workvalue.top_text}}</p>
        <p>{{team_workvalue.low_text}}</p>

        <lineRectChart :data="team_workvalue.tier_consistency_score" :width="width*.89"></lineRectChart>
        <div class="legend" style="margin-bottom:20px">
          <div v-for="(item,i) in team_workvalue.tier_info" :key="i">
            <div class="rect" :class="'rect'+item.tier_id"><i></i></div>{{item.name}}
          </div>
        </div>

        <h1>通过团队成员的答题，我们了解到：</h1>
        <p v-for="(item,i) in team_workvalue.tier_info" :key="i">{{item.name}}在“{{team_workvalue.tier_consistency_content[item.tier_id].top_dimension}}”方面一致性较高，在“{{team_workvalue.tier_consistency_content[item.tier_id].low_dimension}}”方面一致性较低。</p>
      </li>
    </ul>
    <div class="img-box" v-if="reportImgShow">
      <p class="title">长按保存图片</p>
      <div class="inner-box"><img :src="reportImg"></div>
      <img @click="closeImg" class="close" src="../../../assets/images/icons/close.png"/>
    </div>
    <div class="mask" v-if="!success"></div>
  </div>
</template>
<script>
import lineCircle from './chart/lineCircleChart'
import lineRectChart from './chart/lineRectChart'
export default {
  components:{lineCircle,lineRectChart},
  props:{
    report_id:{default: null},
    version: {default: ''}
  },
  data(){
    return{
      width: 0,
      tabId: 0,
      success: false,
      // report_id: this.$route.params.report_id || '24',
      hy_session: this.$route.query.hy_session || '',
      long:this.$route.query.long===undefined?false:true,   //是否报告长图
      org_name: '正在加载',                                  //用户姓名
      time: 0,                                              //报告完成时间
      reportImg:'',
      reportImgShow: false,
      timer:'',
      
      // TVI：
        team_activity: {
          tier_info: {},
          top_text: '',
          low_text: '',
          dim_score: null,
          tier_consistency_content: null,
          tier_consistency_score: null,
          tvi_score:{},
        },
      // 领导能量:
        team_leadership:{
          tier_info: {},
          top_text: '',
          low_text: '',
          dim_score: null,
          tier_consistency_content: null,
          tier_consistency_score: null,
          tvi_score:{},
        },
      // 工作价值倾向：
        team_workvalue:{
          tier_info: {},
          top_text: '',
          low_text: '',
          dim_score: null,
          tier_consistency_content: null,
          tier_consistency_score: null,
          tvi_score:{},
        }
    }
  },
  created(){
    this.$indicator.open({text: '加载中...',spinnerType: 'fading-circle'});
    this.$http.get(this.spaceUrl+'/Assess/getTeamReportInfo?team_report_id='+this.report_id+'&is_share=1'+'&type=2').then((e) =>{
      if (!e.data.code) {
        let data = e.data.data
        this.success = true
        for(let key in data){
          if (key=='report_info') {
            this.org_name = data.report_info.org_name
            this.time = data.report_info.report_time
          } else if (data[key]) {
            let obj = data[key], top = '', low = ''
            for(let it of obj.tier_info){
              top += `${it.name}的“${obj.dim_content.top_dimension[it.tier_id]}”，`
              low += `${it.name}的“${obj.dim_content.low_dimension[it.tier_id]}”，`
            }
            obj.top_text = top.substring(0, top.length-1)+'相对较高。'
            obj.low_text = low.substring(0, top.length-1)+'相对较弱。'
            this[key] = obj
          } else {
            this[key] = false
          }
        }
      } else {
        this.org_name = '无法加载'
        this.$toast({message:'加载失败',duration: 1000})
      }
      this.$indicator.close();
    }).catch((err)=>{
      this.org_name = '无法加载'
      this.$toast({message:err})
      this.$indicator.close();
    })
  },
  mounted(){
    this.width = $('#app').width()
  },
  methods:{
    tab(e){
      if(this.tabId==e) return;
      this.tabId = e;
    },
    save(){
      // this.long = true; return;
      if(this.reportImg)
        this.reportImgShow = true
      else
        this.$http.get(this.spaceUrl+'/Assess/getTeamReportImage?team_report_id='+this.report_id+'&hy_session='+this.hy_session).then((e) => {
          if (e.data.code==0) {
            this.reportImg = e.data.data
            this.reportImgShow = true
          } else { 
            var url = location.href.replace(/http:\/\/localhost/,this.mobileUrl) + (location.hash.includes('?')?'&':'?') + 'long'
            this.$http.post(this.ajaxUrlPath+'/CardImage/createshotImg',{
              type: 'vigourReport',
              imageurl: url,
              width: '480',
              height: '1000',
            }).then(({data}) => {
              if (data.code == 0) {
                this.$indicator.close()
                this.reportImg = data.data.image
                this.reportImgShow = true
              } else {
                this.$toast({message: data.msg})
              }
            })
          }
        })
    },
    closeImg(){
      this.reportImgShow = false
    },
  },
}
</script>
<style scoped lang="scss">
.report{
  color:#6f6f6f;
  padding-bottom: 40px;
  &::before{content: '';display: block;height: 194px;}
  .hide{height: 0;overflow: hidden;position: absolute;}
  .top{
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    background: #fff;
    z-index: 2;
    header{
      display: flex;
      align-items: center;
      font-size: 26px;
      color: #aaa;
      padding: 0 40px;
      height: 120px;
      justify-content: space-between;
        line-height: 1.1;
      .user{
        text-align: left;
        padding-right: 20px;
        font-size: 24px;
        line-height: 1.5;
        b{font-size: 26px}
      }
      .btn{
        width: 130px;
        color: #fff;
        border-radius: 6px;
        background: #3fb3ff;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 24px;
        flex-shrink: 0;
      }
    }
    nav{
      display: flex;
      border-top: 1px solid #f0f0f0;
      border-bottom: 1px solid #f0f0f0;
      color: #999;
      height: 80px;
      background: #fff;
      box-sizing: border-box;
      font-size: 24px;
      align-items: center;
      overflow-x: auto;
      overflow-y: hidden;
      &::-webkit-scrollbar{display: none}
      span{
        flex: 1;
        line-height: 1.2;
        height: 100%;
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
      }
      .current{
        font-size: 26px;
        color: #4d4d4d;
        font-weight: bold;
        &::after{
          content: '';
          position: absolute;
          width: 98%;
          height: 4px;
          background: #3fb3ff;
          bottom: -2px;
          left: 0;
        }
      }
    }
  }
  .content{
    overflow-x: hidden;
    .title{
      line-height: 80px;
      font-weight: bold;
      font-size: 30px;
      margin-top: 160px;
      color: #272727;
    }
    li{
      margin: 0 40px;
      h1{
        font-size: 28px;
        text-align: left;
        font-weight: bold;
        margin: 20px 0;
        color: #5c5c5c;
      }
      p{
        padding-left: 24px;
        text-align: justify;
        text-align-last: justify;
        font-size: 26px;
        position: relative;
        color: #666;
        b{color: #3fb3ff}
        &::before{
          content: '';
          position: absolute;
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background: #7a7a7a;
          left: 4px;
          top: 15px;
        }
      }
      .legend{
        display: flex;
        justify-content: space-around;
        font-size: 22px;
        margin-top: -50px;
        .circle{
          width: 50px;
          display: flex;
          justify-content: space-around;
          align-items: center;
          height: 40px;
          margin: 0 auto;
          &::before{
            content: '';
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background: #ccc;
          }
          &::after{
            content: '';
            width: 8px;
            height: 8px;
            border-radius: 50%;
            background: #ccc;
          }
          i{
            width: 16px;
            height: 16px;
            border-radius: 50%;
            background: #ccc;
            display: block;
            margin: 0 auto
          }
        }
        .circle1{
          &::before{background: #f02f7c}
          &::after{background: #f02f7c}
          i{background: #f02f7c}
        }
        .circle2{
          &::before{background: #f0d02f}
          &::after{background: #f0d02f}
          i{background: #f0d02f}
        }
        .circle3{
          &::before{background: #fe8f2c}
          &::after{background: #fe8f2c}
          i{background: #fe8f2c}
        }
        .circle4{
          &::before{background: #3fb3ff}
          &::after{background: #3fb3ff}
          i{background: #3fb3ff}
        }
        .rect{
          position: relative;
          width: 24px;
          height: 10px;
          margin: 12px auto;
          background: red;
          &::after{
            content: '';
            position: absolute;
            width: 34px;
            border: 1px solid red;
            left: -7px;
            top: 3px;
          }
        }
        .rect1{background: #f02f7c;&::after{border: 1px solid #f02f7c}}
        .rect2{background: #f0d02f;&::after{border: 1px solid #f0d02f}}
        .rect3{background: #fe8f2c;&::after{border: 1px solid #fe8f2c}}
        .rect4{background: #3fb3ff;&::after{border: 1px solid #3fb3ff}}
      }
      .index{
        display: flex;
        flex-wrap: wrap;
        font-size: 26px;
        color: #505050;
        margin: 70px 0;
        h6{
          flex-shrink: 0;
          width: 50%;
          line-height: 1.6;
          font-weight: bold;
          text-align: left;
        }
      }
    }
  }
  .img-box{
      width: 100%;
      height: 100%;
      position: fixed;
      background: rgba(0,0,0,0.8);
      top: 0;
      left: 0;
      z-index: 101;
      font-size:30px;
      .title{line-height: 100px;color:#fff;}
      .inner-box{
        flex: 1;
        height: 80%;
        margin:0 30px 20px;
        overflow-y: auto;
        img{width: 100%;height: auto;display: block;}
      }
      .close{
        display: block;
        width: 70px;
        height: 70px;
        margin:0 auto;
      }
  }
  .mask{
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 100;
  }
}
.long::before{height: 0;}
</style>