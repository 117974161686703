<template>
  <div class="TDA_intro">
    <img src="../../../../../static/img/team9.jpg">

    <h1>评估概述</h1>
    <div class="text">
      <p>1、为什么要定期做人才发展评估？ 人的发展是动态的，复杂的，易变的，人才发展评估将帮助您了解自己，更好的发展自己。</p>
      <p>2、人才发展评估包涵哪些？主要是聚焦职场环境中个人的工作心态、动力、职业素质、能力等内容，每年可进行定期的评估，每年的成长体检让职业发展更健康。</p>
      <p>3、人才发展评估如何应用在工作中？ 评估的结果仅仅反映您当下的工作状态，您可以自我觉察，更建议您分享给了解您的上司或同事，主动寻求反馈，并持续改善自己，成为职场达人，实现职业梦想。</p>
    </div>

    <h1>使用说明</h1>
    <div class="text">
      <p>1、人才发展评估分为多个模块，如：目标感、乐观性、沟通力、适应力...，用户可按模块进行购买。</p>
      <p>2、购买成功后，用户可选择单个模块或多个模块组合进行自评或邀请他人评估。</p>
      <p>3、单个模块20题，使用多个模块组合时，题目数量按模块的个数累计增加。</p>
      <img class="img1" src="../../../../../static/img/TDA16.jpg">
      <h3>人才发展评估包含模块</h3>
    </div>
    <h1>评估说明</h1>
    <div class="text">
      <p>1、评估结果主要运用于行为改善，成长发展，不建议直接用于人才选拔、考核等;</p>
      <p>2、评估题目，全部基于实际工作场景，更能如实的还原个体情况。</p>
      <img class="img2" src="../../../../../static/img/TDA17.jpg">
      <h3>报告样式（截选）</h3>
    </div>

    <h1>版权声明</h1>
    <div class="text">
      <p>广州华严数据科技有限公司拥有自驱力评估题目、报告及相关概念的一切权利，所有解释权归广州华严数据科技有限公司所有。</p>
    </div>
    
    <h4>如需帮助，请长按二维码添加客服咨询</h4>
    <img src="../../../../assets/images/kefuQrcode.jpg" class="kefu">
  </div>
</template>
<script>
export default {
  data(){
    return {
    }
  },
  created(){
    //微信分享
    wx.ready(() => {
      wx.onMenuShareAppMessage({
        title : '人才发展评估',
        link : `${this.mobileUrl}/#/TDA/shop`,
        imgUrl : `${this.mobileUrl}/static/img/p-15.jpg`,
      })
    })
  },
  methods:{
  },
}
</script>
<style lang="scss" scoped>
$color:#77a9fe;
.TDA_intro{
  padding-bottom: 80px;
  text-align: left;
  box-shadow:  0 0 8px rgba(50,50,50,.1);
  background: #f1f5f7;
  user-select: none;
  img{display: block;width: 100%;}
  h1{
    line-height: 50px;
    color: #2384ff;
    padding: 0 40px;
    margin: 60px 30px 16px;
    font-size: 28px;
    background: url('../../../../assets/images/product/explain3.png') no-repeat;
    background-size: 26px 26px;
    background-position: left center;
    position: relative;
  }
  .text{
    color: #414b5e;
    text-align: justify;
    border-top: 1px solid #d4e6fa;
    margin: 0 30px;
    padding: 24px 0;
    p{
      font-size: 26px;
      line-height: 1.8;
      user-select: none;
      a{
        color: #999;
        user-select: text;
        text-decoration: none;
      }
    }
  }
  .img1{
    width: 430px;
    margin: 30px auto 20px;
    padding: 10px;
    padding: 10px;
    background: #fff;
    border-radius: 10px;
  }
  .img2{
    width: 350px;
    padding: 20px;
    background: #fff;
    border-radius: 10px;
    margin: 30px auto;
  }
  h3{
    color: #ccc;
    font-size: 24px;
    text-align: center;
    line-height: 50px;
    margin-bottom: 20px;
  }
  h4{
    color: #414c5e;
    text-align: center;
    line-height: 120px;
    margin: 40px 28px 0;
  }
  .kefu{
    width: 200px;
    height: 200px;
    display: block;
    margin: 0 auto;
    border: 1px solid #ccc;
    margin-bottom: 40px;
  }
}
</style>