<template>
  <svg class="lineRect" :width="width" :height="width">
    <g class="bg"></g>
  </svg>
</template>

<script>
import * as d3 from 'd3'
export default {
  props: {
    data: {
      // default:()=>{
      //   return {
      //     [1]:{
      //       [66]:{name: "以身作则",dispersion:0.2},
      //       [67]:{name: "阿斯蒂芬",dispersion:0.3},
      //       [68]:{name: "俺的沙发",dispersion:0.2},
      //       [69]:{name: "阿斯蒂芬",dispersion:0.2},
      //       [70]:{name: "打法撒旦",dispersion:0.2},
      //       [71]:{name: "阿达水费",dispersion:0.2},
      //     },
      //   }
      // }
    },
    width: {    //宽高度
      type: Number,
    },
    animate:{
      type: Boolean,
      default: false,
    },
  },
  data(){
    return{
      color: ['','#f02f7c','#f0d02f','#fe8f2c','#3fb3ff','#f2b739'],
    }
  },
  mounted(){
    if(this.data){
      setTimeout(()=>{
        this.draw(this.data)
      },100)
      return
    }
    this.$watch('data', (val) => {
      this.draw(val)
    })
  },
  methods:{
    getPointY(val){
      let size = this.size,
          h,        //分数的高度
          v1 = .8,  //低
          v2 = .3,  //中等
          v3 = .15  //中上
      if(val>v1){   //低
        h = val>=2.8  ?  size.eachH/10  :  (2.8-val)/(2.8-v1)*size.eachH
      }else if(val>v2){ //中等
        h = size.eachH + (v1 - val)/(v1 - v2)*size.eachH
      }else if(val>v3){ //中上
        h = size.eachH*2 + (v2 - val)/(v2 - v3)*size.eachH
      }else{ //高
        h = size.eachH*3 + (v3 - val)/(v3 - 0)*size.eachH
      }
      return size.startY - h
    },
    draw(e){
      for (let i in e) 
        for (let j in e[i]) 
          if (!e[i][j]) 
            delete e[i][j];
            
      let svg = d3.select(this.$el),
          gap =  this.width * 0.1,
          top = this.width * 0.2,
          bottom = this.width * 0.3,//与move动画的起始点对应
          fontSize = this.width * 0.035,
          vW = this.width - 2*gap,
          vH = this.width - top - bottom,
          level = ['高','中上','中等','低'],
          eachW,
          eachH = vH / 4,
          bg = svg.select('.bg');
      bg.append('polyline')
        .attr('fill', 'none')
        .attr('stroke', '#a5a5a5')
        .attr('stroke-width',1)
        .attr('points', `${gap},${this.width-bottom} ${this.width-gap},${this.width-bottom}`)
      bg.append('text') //维度
        .attr('font-size', fontSize)
        .attr('font-weight', 'bold')
        .attr('fill', '#333')
        .attr('x', this.width - gap/2)
        .attr('y',  this.width - bottom)
        .text('维度')
      bg.append('text') //共识度
        .attr('font-size', fontSize)
        .attr('font-weight', 'bold')
        .attr('fill', '#333')
        .attr('x', gap + 12)
        .attr('y', top - 20)
        .text('共识度')
      //左边刻度
      for(let i = 0; i < level.length; i++){
        bg.append('text')
          .classed('i', true)
          .attr('font-size', fontSize)
          .attr('fill', '#999')
          .attr('x', gap / 2)
          .attr('y', eachH * (i + .5) + top)
          .text(level[i])
        bg.append('polyline')
          .attr('fill', 'none')
          .attr('stroke', '#e0e0e0')
          .attr("stroke-dasharray", "3,3")
          .attr('stroke-width',1)
          .attr('points', ()=>{
            let y = eachH * i + top
            return `${gap+1},${y} ${gap+vW},${y}`
          })
      }
      //绘制X轴元素（维度名，竖虚线，各分数X轴坐标）
      let pointX = [],                  //各x轴的坐标
          pointY = [],                  //各y轴的坐标
          startY = this.width - bottom, //y轴开始位置
          data = svg.append('g').classed('data', true)
      // 1.绘制维度名 与 竖虚线
      for (let i in e) {// 实际只循环一次，找到第一个就退出
        if (e[i]) {
          let index = 0
          eachW = vW / Object.keys(e[i]).length
          for(let j in e[i]){
            let text = e[i][j].name.split(''),
                x = pointX[index] = eachW * ( index + .5) + gap
            index ++
            bg.append('text')
              .attr('font-size', fontSize)
              .attr('fill', '#808080')
              .attr('y',  this.width - bottom + 18)
              .append('tspan')
              .attr('x', x)
              .text(text[0])
              .append('tspan')
              .attr('x', x)
              .attr('dy', '1.2em')
              .text(text[1])
              .append('tspan')
              .attr('x', x)
              .attr('dy', '1.2em')
              .text(text[2])
              .append('tspan')
              .attr('x', x)
              .attr('dy', '1.2em')
              .text(text[3])
            bg.append('polyline')
              .attr('fill', 'none')
              .attr('stroke', '#e0e0e0')
              .attr("stroke-dasharray", "3,3")
              .attr('stroke-width',1)
              .attr('points', `${x},${top} ${x},${this.width-bottom}`)
          }
          break;
        }
      }
      this.size = {
        vH: vH,
        eachW: eachW,
        eachH: eachH,
        startX: gap,
        startY: startY
      }

      // 2.绘制折线 与 矩形块
      let res = Object.entries(e)
      for(let [tierId,item] of res){
        let [str, arr, high, low] = ['', Object.values(item), 1000, 0];
        //先算出个点坐标值和最大最小值(dispersion越小越高)
        for(let i in arr){
          let dispersion = arr[i].dispersion
          pointY[i] = this.getPointY(dispersion)
          str += `${pointX[i]},${pointY[i]} `
          if(arr[i].dispersion<high) high = arr[i].dispersion;
          if(arr[i].dispersion>low) low = arr[i].dispersion;
        }
        data.append('polyline')
            .attr('fill', 'none')
            .attr('stroke', this.color[tierId])
            .attr('stroke-width',2)
            .attr('points', str)
        for(let i in arr){
          data.append('rect')
              .attr('fill', ()=>{
                if (arr[i].dispersion==high && res.length==1)
                  return '#e86a50'
                else if (arr[i].dispersion==low && res.length==1)
                  return '#83b166'
                else
                  return this.color[tierId]
              })
              .attr('stroke', 'none')
              .attr('x', pointX[i] - 7)
              .attr('y', pointY[i] - 2.5)
              .attr('width', 14)
              .attr('height', 5)
        }
      }

      //添加动画
      if (this.animate) data.selectAll('polyline').classed('move', true)
    },
  },
}
</script>
<style scope lang="scss">
.lineRect{
  display: block;
  margin: 10px auto 0;
  text{
    text-anchor: middle;
    dominant-baseline: middle;
  }
  tspan{
    text-anchor: middle;
    font-style: italic;
  }
  .data{
    polyline{
      stroke-dasharray: 170%;
    }
    .move{
      stroke-dashoffset: 170%;
      animation: moveP .6s forwards linear;
    }
    @keyframes moveP {
      100%{stroke-dashoffset:0;}
    }
    rect{
      animation: fadeC .6s forwards;
      opacity: 0;
    }
    @keyframes fadeC {
      100%{opacity: 1;}
    }
  }
  .i{
    font-style: italic;
  }
}
</style>
