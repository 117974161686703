<template>
  <div class="consult">
    <h1>幸福教练 线上咨询</h1>
    <div class="content">
      <h2>一对一专业指导 </h2>
      <h3>做情绪的主人 和压力说 BYE BYE</h3>
      <div class="box">
        <ul class="flex">
          <li>
            <img src="../../../assets/images/bee/consult_icon1.png">个人成长
          </li>
          <li>
            <img src="../../../assets/images/bee/consult_icon2.png">情感咨询
          </li>
          <li>
            <img src="../../../assets/images/bee/consult_icon3.png">情绪同频
          </li>
          <li>
            <img src="../../../assets/images/bee/consult_icon4.png">事业突破
          </li>
        </ul>
        <div class="button">
          <span @click="rise=true">发起咨询</span>
        </div>
      </div>
      <div class="box box2">
        <h4>无论外部世界如何变化，请记住，有一粒光的种子在你心中。</h4>
        <h5>这里是医院，治愈心灵的医院；这里是商店，出售快乐的商店；这里是工厂，制造阳光的工厂；这里是阳光小屋，放下悲伤带走快乐的小屋。</h5>
        <h6>构建心灵家园，拥抱美丽人生</h6>
      </div>
    </div>
    <div class="mask" v-show="rise" @click="rise=false"/>
    <div class="rise" :style="{bottom: rise?'0':'-100%'}">
      <p>请选择咨询类型 <span>*</span></p>
      <textarea/>
      <p>请大概描述你想要咨询的问题（选填）</p>
      <textarea style="height:100px"/>
      <div class="btn">确认支付：9.9元</div>
    </div>
  </div>
</template>
<script>
export default {
  data(){
    return {
      rise: false,
    }
  },
  created(){},
  mounted(){},
  methods:{},
}
</script>
<style lang="scss" scoped>
.consult{
  background: #2a6150;
  padding: 1px 30px 50px;
  position: relative;
  &::before{
    content: 'BLESSEDNESS';
    position: absolute;
    left: 50%;
    z-index: 1;
    transform: translateX(-50%);
    top: 90px;
    font-size: 86px;
    color: #326252;
    opacity: .9;
    background-image: linear-gradient(#3e6a5c, #2a6150);
    background-clip: text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
  h1{
    line-height: 200px;
    color: #ffdb94;
    font-size: 68px;
    font-weight: bold;
    font-family: monospace;
    position: relative;
    z-index: 2;
  }
  .content{
    background: #4faa80;
    border-radius: 10px;
    padding: 30px;
    h2{
      font-size: 44px;
      color: #fff;
      margin-bottom: 16px;
      position: relative;
      &::before, &::after{
        content: '';
        width: 36px;
        height: 36px;
        position: absolute;
        left: 70px;
        top: 12px;
        background: url('../../../assets/images/bee/consult_icon0.png') no-repeat;
        background-size: 100%;
      }
      &::after{left: auto;right: 70px;}
    }
    h3{
      color: #e3d776;
      font-size: 24px;
    }
    .box{
      border-radius: 10px;
      overflow: hidden;
      margin: 30px 0;
    }
    ul{
      background: linear-gradient(#fff, #e7d5c2);
      padding: 40px 0;
      li{
        font-size: 20px;
        color: #653900;
        padding: 20px 0;
        border-right: 1px solid #d5ab88;
        flex: 1;
        img{
          display: block;
          width: 60px;
          margin: 0 auto 20px;
        }
        &:last-child{border: none}
      }
    }
    .button{
      padding: 30px 80px;
      background: linear-gradient(#dab693, #ca8f6f);
      span{
        display: block;
        background: #893f1b;
        line-height: 74px;
        color: #fff;
        font-size: 28px;
      }
    }
    .box2{
      background: #2a6150;
      padding: 40px 30px;
      h4{
        font-size: 30px;
        color: #fed17a;
        text-align: justify;
        margin: 0 16px;
      }
      h5{
        font-size: 28px;
        color: #fff;
        background: #337e5f;
        padding: 30px 16px;
        text-align: justify;
        margin: 30px 0;
        line-height: 1.5;
      }
      h6{
        font-size: 32px;
        margin: 40px 0;
        color: #fed17a;
        display: inline-block;
        line-height: 1.8;
        border-bottom: 1px solid #fed17a;
      }
    }
  }
  .mask{
    position: fixed;
    width: 100%;
    height: 100%;
    max-width: 800px;
    background: rgba(0,0,0,.5);
    left: 50%;
    transform: translateX(-50%);
    top: 0;
    z-index: 10;
  }
  .rise{
    position: fixed;
    z-index: 11;
    width: 100%;
    max-width: 800px;
    left: 50%;
    bottom: -100%;
    transform: translateX(-50%);
    border-top-left-radius: 40px;
    border-top-right-radius: 40px;
    background: #fff;
    padding: 50px 30px 10px;
    text-align: left;
    transition: .2s;
    box-shadow: 0 10px 10px rgba(200,200,200,.2);
    p{
      font-size: 28px;
      color: #333;
      line-height: 80px;
      margin-top: 10px;
      span{color: #dd2929}
    }
    textarea{
      border: 1px solid #f0f0f0;
      border-radius: 10px;
      resize: none;
      font-size: 28px;
      width: 100%;
      height: 120px;
      padding: 10px;
    }
    .btn{
      margin: 40px 110px;
      line-height: 74px;
      color: #fff;
      background: #8c4019;
      text-align: center;
      font-size: 30px;
    }
  }
}
</style>