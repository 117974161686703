<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
export default {
  name: 'App',
  created(){
    this.$http.post(this.spaceUrl+`/Jssdk/getJssdkSign`, {url: this.mobileUrl+'/'}).then(({data}) => {
      if (data.code == 0) {
        wx.config({
          debug: false,                           // 开启调试模式,仅在pc端时才会打印
          appId: data.data.appId,                 // 必填，小程序的唯一标识
          timestamp: data.data.timestamp,         // 必填，生成签名的时间戳
          nonceStr: data.data.nonceStr,           // 必填，生成签名的随机串
          signature: data.data.signature,         // 必填，签名
          jsApiList: data.data.jsApiList,         // 必填至少一个，需要使用的JS接口列表
          openTagList: ['wx-open-launch-weapp'],  // 可选，需要使用的开放标签列表
        })
      } else {
        console.error(data.msg)
      }
    });
  },
}
</script>

<style lang="scss">
*{margin: 0;padding:0;box-sizing: border-box;}
ul, ol{list-style: none}
a {text-decoration: none;}
h1, h2, h3, h4, h5, h6 {font-size: 28px;font-weight: normal;}

#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  height: 100%;
  max-width: 800px;
  margin: 0 auto;
  cursor: default;
  position: relative;
  font-size: 28px;
}
html,body{
  height: 100%;
}
input,textarea{
  border:0;
  background: none;
  color:#000;
  -webkit-appearance: none;
  appearance: none;
  border-radius: 0;
}
.clearfix::after{
  content: '';
  display: block;
  clear: both;
}
.mint-indicator{
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 99;
}
.mint-toast-text{
  font-size: 12PX;
}

.flex {display: flex;}
.flex-c{display: flex;justify-content: center;}
.flex--c{display: flex;align-items: center;}
.flex-c-c{display: flex;justify-content: center;align-items: center;}
.flex-a{display: flex;justify-content: space-around;}
.flex-a-c{display: flex;justify-content: space-around;align-items: center;}
.flex-b{display: flex;justify-content: space-between;}
.flex-b-c{display: flex;justify-content: space-between;align-items: center;}

//AMT报告中需要使用
.E{color: #1ab898}  //E
.M{color: #187add}  //M
.P{color: #ff942a}  //P
.F{color: #f25757}  //F
.S{color: #dd9f22}  //S
</style>

