<template>
  <div class="welfare">
    <div class="content">
      <img src="../../../assets/images/bee/1.jpg" class="pic">
      <h1>读书会高情商表达高情商表达高情商表达高情商表达读书会高情商表达高情商表达高情商表达高情商表达</h1>
      <h2>美丽家园，我们是行动者，也是担当者</h2>
    </div>
  </div>
</template>
<script>
export default {
  data(){
    return {}
  },
  created(){},
  mounted(){},
  methods:{},
}
</script>
<style lang="scss" scoped>
.welfare{
  padding: 1px 0;
  min-height: 100%;
  background: #f1f5f7;
  text-align: left;
  .content{
    border-radius: 10px;
    overflow: hidden;
    margin: 30px;
    background: #fff;
    padding-bottom: 20px;
    img{
      display: block;
      width: 100%;
    }
    h1{
      margin: 20px 20px 10px;
      font-size: 28px;
      color: #333;
      font-weight: bold;
    }
    h2{
      font-size: 24px;
      color: #8f9192;
      margin: 10px 20px;
    }
  }
}
</style>