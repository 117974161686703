<template>
  <!--报告-->
  <self_1_0 v-if="version=='1.0.0'" :version="version" :hy_session="hy_session" :exam_id="exam_id"></self_1_0>
  <!-- version=='2.0.0' -->
  <self_2_0 v-else-if="version=='2.0.0'" :version="version" :hy_session="hy_session" :exam_id="exam_id"></self_2_0>
</template>

<script>
import self_1_0 from './self1.0.vue'
import self_2_0 from './self2.0.vue'
export default {
  components:{self_1_0,self_2_0},
  data(){
    return{
      exam_id: this.$route.params.exam_id || '',
      hy_session: this.$route.params.hy_session || '',
      version: this.$route.query.ver || '', //防止生成图片时空白页
    }
  },
  mounted(){
    if (!this.version) //网页浏览
      this.$http.get(this.ajaxUrlPath+`/AceTeam/getReportVersion?exam_id=${this.exam_id}`).then(({data}) => {
        if (data.code == 0) {
          this.version = data.data.version || '2.0.0'
        } else {
          this.$toast({message:'无法加载',duration: 2000})
          console.error(data.msg)
        }
      })

  },
}
</script>
