<template>
  <div class="order">
    <nav class="flex">
      <span :class="{curr:tab==1}" @click="change(1)">课程</span>
      <span :class="{curr:tab==2}" @click="change(2)">咨询</span>
    </nav>
    <ul>
      <li v-for="(item,i) in list" :key="i">
        <h1>{{item.title}}</h1>
        <h2>下单时间：{{$formatTime(item.payment_time, 'y-m-d h:m')}}</h2>
        <h2>订单编号：{{item.order_num}}</h2>
        <div class="under flex-b">
          <h3>实付款￥{{item.payment_money}}</h3>
          <h4>已支付</h4>
        </div>
      </li>
    </ul>
    <div class="null" v-show="total===0">暂无记录</div>
  </div>
</template>
<script>
export default {
  data(){
    return {
      tab: 1,
      page: 1,
      list: [],
      total: '',
    }
  },
  created(){
    this.getList()
  },
  mounted(){},
  methods:{
    change(type) {
      this.tab = type
      this.getList(1)
    },
    getList(p) {
      if (p == 1) this.page = 1
      this.$http.post(this.ajaxUrlPath+'/Bee/getUserOrder', {
        hy_session: localStorage.getItem("session"),
        page: this.page++,
        per_page: 10,
        type: this.tab,
      }).then(({data}) => {
        if (data.code == 0) {
          this.list = data.data.data
          this.total = data.data.total
        } else {
          this.$toast({message: data.msg})
        }
      }, () => this.$toast({message:'网络错误'}))
    },
  },
}
</script>
<style lang="scss" scoped>
.order{
  background: #f6f6f6;
  min-height: 100%;
  user-select: none;
  nav{
    font-size: 28px;
    color: #8f9192;
    margin: 0 30px;
    span{
      line-height: 100px;
      position: relative;
      width: 100px;
    }
    .curr{
      color: #292929;
      font-weight: bold;
      &::after{
        content: '';
        position: absolute;
        border-radius: 4px;
        width: 50px;
        height: 6px;
        background: #279a35;
        bottom: 24px;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
  li{
    background: #fff;
    border-radius: 10px;
    margin: 0 30px 20px;
    text-align: left;
    h1{
      padding: 20px 30px;
      font-size: 28px;
      color: #1a1a1a;
      font-weight: bold;
      line-height: 1.4;
    }
    h2{
      font-size: 24px;
      color: #808080;
      padding: 0 30px;
    }
    .under{
      margin-top: 20px;
      border-top: 1px solid #f6f6f6;
      padding: 20px 30px;
      line-height: 1.2;
    }
    h3{
      color: #1a1a1a;
    }
    h4{
      color: #2c9e3a;
      font-weight: bold;
    }
  }
  .null{
    color: #b3b3b3;
    font-size: 28px;
    margin-top: 120px;
    padding-top: 300px;
    background: url('../../../assets/images/bee/bee_null.png') no-repeat;
    background-size: 280px 280px;
    background-position: center top;
  }
}
</style>