<template>
  <div class="report" :class="{long:long}">
    <div class="top">
      <header>
        <img :src="avatar" class="head-img">
        <div class="user">
          <div>{{name}} <span>报告分析</span></div>
          <div class="time">{{$formatTime(endTime,'y-m-d h:m')}}<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;版本号 v1.0</span></div>
        </div>
        <div v-if="saveShow && !long" class="btn" @click="save">报告长图</div>
      </header>
      <nav v-if="!long">
        <span :class="{current:tabId==0}" @click="tab(0)">整体<br>报告</span>
        <span :class="{current:tabId==1}" @click="tab(1)">工作<br>价值倾向</span>
        <span :class="{current:tabId==2}" @click="tab(2)">原动力</span>
        <span :class="{current:tabId==3}" @click="tab(3)">职业<br>素质</span>
        <span v-if="sale" :class="{current:tabId==4}" @click="tab(4)">销售<br>行为分析</span>
        <span :class="{current:tabId==5}" @click="tab(5)">思考<br>与建议</span>
      </nav>
    </div>
    <ul class="content">
      <!-- 整体报告 -->
      <div class="title" v-if="long">整体报告</div>
      <li class="whole" v-show="tabId==0">
        <h3>[工作能量]</h3>
        <div class="bar-box">
          <div class="bar" :style="{width:barWidth+'%'}"><span>{{work_capacity[0].work_capacity}}</span></div>
          <b style="left:50%">{{work_capacity[0].work_capacity_content}}</b>
        </div>
        <div class="text">当下，您的工作能量水平<b class="blue">{{work_capacity[0].work_capacity}}</b>，能量等级为<b class="blue">{{work_capacity[0].work_capacity_content}}</b></div>

        <h3 style="margin-top:70px">[原动力&职业素质]</h3>
        <pieChart ref="pie" :width="width*.75" :score="pie.score" :name="pie.name"></pieChart>
        <p v-html="compareText"></p>

        <h3 style="margin-top:60px">[工作价值倾向]</h3>
        <div class="wave">
          <waveBall :width="width*.28" :type="3" :name="work_value[0].name"></waveBall>
        </div>
        <p>当下，<b class="blue">“{{work_value[0].name}}”</b>是您最关注的内在需求，工作中它是您产生动力的触发点，也是快乐工作的根源。</p>
      </li>
      <!-- 工作价值倾向 -->
      <div class="title" v-if="long">工作价值倾向</div>
      <li class="values" v-show="tabId==1 || long">
        <div class="rank">
          <div class="box box1" @click="showIndex=0">{{work_value[0]?work_value[0].name:' '}}<span>NO.1</span></div>
          <div class="box box2" @click="showIndex=1">{{work_value[1]?work_value[1].name:' '}}<span>NO.2</span></div>
          <div class="box box3" @click="showIndex=2">{{work_value[2]?work_value[2].name:' '}}<span>NO.3</span></div>
          <h2>点击维度名称可查看说明</h2>
          
          <div class="tip" v-if="showIndex!=null">
            <h6>{{work_value[showIndex].dimension_detail}}</h6>
            <div class="close" @click="showIndex=null">关 闭</div>
          </div>
        </div>
        <h4>从您的答题选项中，反映出您当下最看重的工作价值是：<span v-for="(it,i) in work_value" :key="i">{{it.name}}{{i!=work_value.length-1?'、':''}}</span></h4>
        <p v-for="(item,index) in work_value" :key="index">{{item.content}}</p>
      </li>
      <!-- 原动力 -->
      <div class="title" v-if="long">原动力</div>
      <li class="motivity" v-show="tabId==2 || long">
        <ringChart :data="motiveTotal" :width="width*.62" :animate="!long" :type="2"></ringChart>
        <h1>原动力指数：<span>{{motive_power[0].score}}</span></h1>
        <div class="fan-box">
          <fanChart v-for="(item,index) in 4" :key="index" :type="index" :width="width*.76/4" :data="motive[index]" :animate="!long"></fanChart>
        </div>
        <div class="word">
          <div class="box" :class="{null:motive_power[2][0].length==0}">
            <h2>发挥良好</h2>
            <p><span v-for="(it,i) in motive_power[2][1]" :key="i">{{it.name}}{{i%2==0&&i!=motive_power[2][1].length-1?'、':''}}</span></p>
          </div>
          <div class="box right" v-if="motive_power[2][0].length>0">
            <h2>有待激发</h2>
            <p><span v-for="(it,i) in motive_power[2][0]" :key="i">{{it.name}}{{i%2==0&&i!=motive_power[2][0].length-1?'、':''}}</span></p>
          </div>
        </div>
      </li>
      <!-- 职业素质 -->
      <div class="title" v-if="long">职业素质</div>
      <li class="motivity" v-show="tabId==3 || long">
        <ringChart :type="1" :data="qualityTotal" :width="width*.62" :animate="!long"></ringChart>
        <h1>职业素质指数：<span>{{basic_quality[0].score}}</span></h1>
        <div class="fan-box">
          <fanChart v-for="(item,index) in 4" :key="index" :type="index+4" :width="width*.76/4" :data="quality[index]" :animate="!long"></fanChart>
        </div>
        <div class="word">
          <div class="box" :class="{null:basic_quality[2][0].length==0}">
            <h2>发挥良好</h2>
            <p><span v-for="(it,i) in basic_quality[2][1]" :key="i">{{it.name}}{{i%2==0&&i!=basic_quality[2][1].length-1?'、':''}}</span></p>
          </div>
          <div class="box right" v-if="basic_quality[2][0].length>0">
            <h2>有待激发</h2>
            <p><span v-for="(it,i) in basic_quality[2][0]" :key="i">{{it.name}}{{i%2==0&&i!=basic_quality[2][0].length-1?'、':''}}</span></p>
          </div>
        </div>
      </li>
      <!-- 销售行为分析 -->
      <div class="title" v-if="long && sale">销售行为分析</div>
      <li class="energy" v-if="sale" v-show="tabId==4 || long">
        <radarChart ref="radar" :width="width*.88" :data="sale[0]" :animate="!long" :lineColor="'#3fb3ff'"></radarChart>
        <h4 class="h4">从您的答题选项中，反映出您当下的销售行为是：</h4>
        <p v-for="(it,i) in sale[1]" :key="i">{{it.content}}</p>
      </li>
      <!-- 思考与建议 -->
      <div class="title" v-if="long">思考与建议</div>
      <li class="suggest" v-show="tabId==5 || long">
        <h4>思考</h4>
        <p>综合您的工作能量来看：</p>
        <p><b v-for="(it,i) in suggestion[0].active_energy" :key="i">{{it}}{{i==suggestion[0].active_energy.length-1?'':'、'}}</b>是您个人认为当前状态相对最佳的领域，您可以继续发挥，同时积极影响其他人；</p>
        <p v-if="suggestion[0].potential_energy.length>0"><b v-for="(it,i) in suggestion[0].potential_energy" :key="i">{{it}}{{i==suggestion[0].potential_energy.length-1?'':'、'}}</b>是您认为不够理想的领域，您需要思考影响您的原因。</p>
        <h4>发展</h4>
        <p>从职级的成长路径来看，您正处在<b>{{suggestion[1].tier}}</b>层级，以下三方面非常重要：</p>
        <p class="nop">①工作理念：{{suggestion[1].work_concept}}</p>
        <p class="nop">②时间管理：{{suggestion[1].time_manage}}</p>
        <p class="nop">③{{suggestion[1].tier.indexOf('管理者')>0?'领导':'工作'}}技能：{{suggestion[1].work_performance}}</p>
        <h4>能量加油站</h4>
        <div class="book-box">
          <div class="book" v-for="(it,i) in suggestion[2]" :key="i">
            <img :src="it.image_path">
            <h5 v-html="it.book_name.replace(/\n/g,'<br>')"></h5>
          </div>
        </div>
      </li>
    </ul>
    <div class="img-box" v-if="reportImgShow">
      <p class="text">长按保存图片</p>
      <div class="inner-box"><img :src="reportImg"></div>
      <img @click="closeImg" class="close" src="../../../assets/images/icons/close.png"/>
    </div>
  </div>
</template>
<script>
import pieChart from './chart/pieChart'
import waveBall from './chart/waveBallChart'
// import ringChart from '../../aceTeam/self/ringChart'
// import fanChart from '../../aceTeam/self/fanChart'
import radarChart from '../../aceTeam/self/radarChart'
export default {
  components:{pieChart,radarChart,waveBall,},
  data(){
    return{
      width: 0,
      tabId: 0,
      exam_id: this.$route.params.exam_id || '',
      hy_session: this.$route.params.hy_session || '',
      origin: this.$route.query.origin || 'mobile',       //
      long:this.$route.query.long===undefined?false:true, //是否报告长图
      name: "正在加载",                                    //用户姓名
      avatar: require('../../../assets/images/icons/user_icon.png'),
      endTime: 0,                                         //报告完成时间
      reportImg:'',
      reportImgShow: false,
      saveShow: true,
      timer:'',
      
      //工作能量(work_capacity)：
        work_capacity:[
          {work_capacity:0,work_capacity_content:''},
          {active_energy:[],potential_energy:[]},
          [],[]
        ],
        pie:{name:'',score:[]},//8份扇形的百分比
        barWidth: 0,
        saveWidth: 0,
      //工作价值倾向：
        work_value:[{name:'',}],
        showIndex:null,
      //原动力：
        motive_power:[
          {score:0},
          [],
          [[],[]],
        ],
        motiveTotal: 0,
        motive: [],
      //职业素质：
        basic_quality:[
          {score:0},
          [],
          [[],[]],
        ],
        qualityTotal: 0,
        quality: [],
      //销售行为分析：
        sale: '',
      //思考与建议：
        suggestion:[
          {active_energy:[],potential_energy:[]},
          {tier:'',time_manage:'',work_concept:'',work_performance:''},
          []
        ]
    }
  },
  created(){
    this.$indicator.open({text: '加载中...',spinnerType: 'fading-circle'});
    this.$http.get(this.spaceUrl+'/Assess/getExamReportInfo',{
      params:{
        origin: this.origin,
        exam_id: this.exam_id,
        hy_session: this.hy_session,
      }
    }).then((e) => {
      let data = e.data.data
      if (!e.data.code) {
        //个人信息
        this.name = data.user_info.realname || data.user_info.nickname
        this.avatar = data.user_info.avatar
        this.endTime = data.user_info.end_time
        //工作能量
        this.work_capacity = data.work_capacity
        this.totalScore = data.work_capacity[0].work_capacity
        this.wordLevel = data.work_capacity[0].work_capacity_content
        let radar = data.work_capacity[3], total = 1;
        this.pie.score = [radar[2], radar[1]]
        this.pie.name = radar[0].name
        setTimeout(()=>{
          // this.barWidth = this.saveWidth = this.getWidth(this.totalScore)
          this.barWidth = this.saveWidth = this.totalScore
        }, 100)
        //销售行为分析
        this.sale = data.sale
        //原动力
        this.motive_power = data.motive_power
        //职业素质
        this.basic_quality = data.basic_quality
        if (this.long) {
          this.qualityTotal = this.basic_quality[0].score
          this.quality = this.basic_quality[1]
          this.motiveTotal = this.motive_power[0].score
          this.motive = this.motive_power[1]
        }
        //工作价值倾向
        this.work_value = data.work_value
        //思考与建议：
        this.suggestion = data.consideration_and_suggestion
      } else {
        this.name = '无法加载'
        this.$toast({message: '加载失败', duration: 1000})
      }
      this.$indicator.close();
      if (this.origin == 'admin') this.saveShow = false
    }).catch((err)=>{
      this.name = '无法加载'
      this.$toast({message: err})
      this.$indicator.close();
    })
  },
  mounted(){
    this.width = $('#app').width()
  },
  methods:{
    tab(e){
      if(this.sale)
        this.$refs.radar.showIndex = null
      this.$refs.pie.showIndex = null
      this.showIndex = null
      if(this.tabId==e) return;
      this.tabId = e;
      this.barWidth = 0
      switch(e){
        case 0:
          setTimeout(()=>{this.barWidth = this.saveWidth},50)
          break;
        case 1:
          this.motiveTotal = 0
          this.motive = []
          this.qualityTotal = 0
          this.quality = []
          break;
        case 2:
          this.qualityTotal = 0
          this.quality = []
          this.motiveTotal = this.motive_power[0].score
          this.motive = this.motive_power[1]
          break;
        case 3:
          this.motiveTotal = 0
          this.motive = []
          this.qualityTotal = this.basic_quality[0].score
          this.quality = this.basic_quality[1]
          break;
        default:
          this.motiveTotal = 0
          this.motive = []
          this.qualityTotal = 0
          this.quality = []
          break;
      }
    },
    save(){
      //   this.long = true
      //   this.qualityTotal = this.basic_quality[0].score
      //   this.quality = this.basic_quality[1]
      //   this.motiveTotal = this.motive_power[0].score
      //   this.motive = this.motive_power[1]
      //   return;
      if (this.reportImg)
        this.reportImgShow = true
      else
        this.$http.get(this.spaceUrl+'/Assess/getReportImage?hy_session='+this.hy_session+'&exam_id='+this.exam_id).then((e) =>{
          if(e.data.code==0){
            this.reportImg = e.data.data.report_image
            this.reportImgShow = true
          }else if(e.data.code==1){
            var url = location.href.replace(/http:\/\/localhost/,this.mobileUrl) + (location.hash.includes('?')?'&':'?') + 'long'
            this.$indicator.open({text: '图片生成中...',spinnerType: 'triple-bounce'});
            this.$http.post(this.ajaxUrlPath+'/CardImage/createshotImg',{
              type: 'vigourReport',
              imageurl: url,
              width: '500',
              height: '800',
            }).then( e => {
              this.$indicator.close()
              if (e.data.code == 0) {
                this.reportImg = e.data.data.image
                this.reportImgShow = true
              } else {
                this.$toast({message: e.data.msg})
              }
            })
          }
        })
    },
    closeImg(){
      this.reportImgShow = false
    },
    getLevel(score){
      let level = '充盈'
      if(score==0) level = 0
      else if(score<45) level = '中等'
      else if(score<60) level = '中上'
      else if(score<70) level = '饱满'
      return level
    },
    getWidth(score){
      let v1 = 60,
          v2 = 75,
          v3 = 85;
      if (score >= v3) {
        return fun(v3,100,75,100)
      } else if (score > v2) {
        return fun(v2,v3,50,75)
      } else if (score > v1) {
        return fun(v1,v2,25,50)
      } else {
        return fun(30,v1,0,25)
      }

      function fun(x1,x2,y1,y2){
        let k = (y2 - y1) / (x2 - x1)
        let b = y1 - k * x1
        return k * score + b
      }
    },
  },
  computed:{
    batteryNum(){
      let score = this.work_capacity[0].work_capacity, num = 5;
      if(score==0) num = 0
      else if(score<40) num = 1
      else if(score<60) num = 3
      else if(score<75) num = 4
      return num
    },
    compareText(){
      let diff = this.motive_power[0].score - this.basic_quality[0].score
      if (diff > 0) {
        return '当下，您的<b style="color:#54a7e9">“原动力”</b>高于<b style="color:#fe7b14">“职业素质”</b>，需要加强技能训练和刻意练习，提升工作品质和效率是改善的重点。'
      } else if (diff < 0) {
        return '当下，您的<b style="color:#fe7b14">“职业素质”</b>高于<b style="color:#54a7e9">“原动力”</b>，挖掘内在动力，加强心态管理，找到工作的意义和价值是改善的重点。'
      } else if(this.motive_power[0].score > 74) {
        return '当下，您的“原动力”跟“职业素质”相当，请保持现有状态，并不断突破成长！'
      } else {
        return '当下，您的“原动力”跟“职业素质”相当，对内需要关注动力的挖掘，在外需要加强技能训练。'
      }
    },
  },
}
</script>
<style scoped lang="scss">
.report{
  color:#4d4d4d;
  padding-bottom: 40px;
  &::before{content: '';display: block;height: 188px;}
  .hide{height: 0;overflow: hidden;position: absolute;}
  .top{
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    background: #fff;
    z-index: 99;
    header{
      display: flex;
      align-items: center;
      font-size: 26px;
      color: #aaa;
      padding: 0 40px;
      height: 120px;
      border-bottom: 1px solid #f0f0f0;
      .head-img{
        width: 76px;
        height: 76px;
        border-radius: 50%;
        overflow: hidden;
        display: block;
        margin-right: 20px;
        background: #ccc;
      }
      .user{
        text-align: left;
        flex: 1;
        .time{
          font-size: 24px;;
          color:#bfbfbf
        }
        span{font-size: 24px;}
      }
      .btn{
        width: 150px;
        color: #fff;
        border-radius: 4px;
        background: #2da9f7;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    nav{
      display: flex;
      border-bottom: 1px solid #f0f0f0;
      color: #999;
      height: 80px;
      background: #fff;
      box-sizing: border-box;
      align-items: center;
      overflow-x: auto;
      overflow-y: hidden;
      justify-content: space-around;
      &::-webkit-scrollbar{display: none}
      span{
        flex-shrink: 0;
        font-size: 26px;
        line-height: 1.1;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        width: 16.66%;
        transition: all .1s;
      }
      .current{
        font-size: 28px;
        color: #4d4d4d;
        font-weight: bold;
        &::after{
          content: '';
          position: absolute;
          width: 98%;
          height: 4px;
          background: #2da9f7;
          bottom: -1px;
          left: 0;
        }
      }
    }
  }
  .content{
    overflow-x: hidden;
    .title{
      line-height: 80px;
      font-weight: bold;
      font-size: 30px;
      margin-top: 160px;
      color: #272727;
    }
    li{
      margin: 0 40px;
      p{
        font-size: 28px;
        color: #6f6f6f;
        padding-left: 30px;
        position: relative;
        text-align: justify;
        line-height: 1.44;
        margin-bottom: 6px;
        &::before{
          content: '';
          position: absolute;
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background: #6f6f6f;
          left: 6px;
          top: 14px;
        }
      }
      .text{
        font-weight: bold;
        font-size: 28px;
      }
      .blue{color: #54a7e9;}
      .orange{color: #fe7b14;}
      .nop::before{display: none}
    }
    .whole{
      padding-top: 40px;
      .bar-box{
        position: relative;
        height: 36px;
        width: 84%;
        margin: 120px auto;
        border: 1px solid #e5e5e5;
        background: #fafafa;
        &::before{
          content: '';
          position: absolute;
          width: 54px;
          height: 54px;
          border-radius: 50%;
          border: 1px solid #e5e5e5;
          background: #fff;
          left: 0;
          top: 50%;
          transform: translate(-50%,-50%);
          z-index: 5;
        }
        &::after{
          content: '';
          position: absolute;
          width: 54px;
          height: 54px;
          border-radius: 50%;
          border: 1px solid #e5e5e5;
          background: #fff;
          left: 100%;
          top: 50%;
          transform: translate(-50%,-50%);
          z-index: 5;
        }
        .bar{
          height: 100%;
          background-image: linear-gradient(to right, #7eccff , #4145ed);
          width: 0%;
          border-radius: 18px;
          position: relative;
          transition: width .6s;
          span{
            position: absolute;
            font-size: 28px;
            right: 0;
            bottom: 130%;
            font-weight: bold;
          }
        }
        i{
          position: absolute;
          border-left: 2px solid #b3b3b3;
          height: 10px;
          top: 130%;
          left: 25%;
        }
        b{
          position: absolute;
          font-size: 24px;
          top: 150%;
          left: 12.5%;
          font-weight: normal;
          transform: translateX(-50%);
        }
      }
      h3{
        font-size: 28px;
        margin: 50px 0;
      }
      .wave{
        // display: flex;
        // justify-content: space-around;
        margin: 20px 0 50px;
      }
    }
    .energy{
      padding-bottom: 30px;
      .total-score{
        width: 330px;
        height: 330px;
        border: 1px solid #fecc65;
        border-radius: 50%;
        margin: 70px auto 40px;
        position: relative;
        h1{
          font-size: 68px;
          color: #2da9f7;
          padding: 80px 0 4px;
          font-weight: bold;
        }
        .battery{
          width: 154px;
          height: 72px;
          padding:6px 24px 6px 6px;
          margin: 0 auto;
          background: url('../../../assets/images/trump/battery.png') no-repeat;
          background-size: 100% 100%;
          display: flex;
          i{
            width: 20%;
            border: 1px solid #fff;
            background: #f8c949;
            border-radius: 6px;
            animation: opacity .15s linear;
            opacity: 0;
            animation-fill-mode:forwards;
            &:nth-child(2){background: #f99351;animation-delay: .15s;}
            &:nth-child(3){background: #7991fd;animation-delay: .3s;}
            &:nth-child(4){background: #cc6ef4;animation-delay: .45s;}
            &:nth-child(5){background: #fa6e7d;animation-delay: .6s;}
          }
          @keyframes opacity {
            100%{opacity: 1;}
          }
        }
        &::after{
          content: '';
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;left: 0;
          background: url('../../../assets/images/trump/score-bg.png') no-repeat;
          background-size: 92% 92%;
          background-position: center;
          animation: rotate 100s linear infinite;
        }
      }
      .level{
        font-size: 30px;
        h2{
          line-height: 2.2;
          font-weight: bold;
        }
        h3{
          line-height: 1.2;
          font-weight: bold;
        }
        b{color: #2da9f7;}
        span{color: #1a1a1a}
      }
      h4{
        font-size: 30px;
        text-align: justify;
        font-weight: bold;
        margin: 30px 0;
      }
      .h4{color: #2da9f7}
      @keyframes rotate {
        100%{transform: rotate(360deg);}
      }
    }
    .motivity{
      h1{
        line-height: 1.4;
        font-weight: bold;
        font-size: 30px;
        span{color: #2da9f7}
      }
      .fan-box{
        // display: flex;
        // justify-content: space-between;
        margin: 30px 0;
      }
      .word{
        display: flex;
        justify-content: space-between;
        font-size: 28px;
        margin:50px 0 40px;
        .box{
          width: 320px;
          min-height: 200px;
          padding-bottom: 30px;
          border-radius: 16px;
          background: #fff6e7;
          color: #d49e3f;
          h2{
            display: flex;
            justify-content: center;
            padding: 30px 0 16px;
          }
          p{
            margin: 0 50px;
            font-size: 24px;
            padding: 0;
            &::before{
              display: none
            }
          }
        }
        .right{
          background: #fef2f2;
          color: #e86674;
        }
        .null{
          width: 100%;
          padding: 0 170px 30px;
          box-sizing: border-box;
        }
      }
    }
    .values{
      .rank{
        position: relative;
        width: 388px;
        height: 380px;
        margin: 60px auto 120px;
        color: #fff;
        font-size: 26px;
        .box{
          position: absolute;
          box-sizing: border-box;
          border-radius: 50%;
          border: 6px solid #f2f2f2;
          line-height: 1.6;
          font-weight: bold;
          span{display: block;font-weight: normal}
        }
        .box1{
          width: 288px;
          height: 288px;
          padding-top: 50px;
          top: 0;
          left: 20px;
          background: #3fb3ff;
          animation: box1 .8s forwards;
        }
        .box2{
          width: 240px;
          height: 240px;
          padding-top: 76px;
          bottom: 20px;
          right: 0;
          background: rgba(78,206,157,0.9);
          animation: box2 1s forwards;
        }
        .box3{
          width: 180px;
          height: 180px;
          padding-top: 48px;
          bottom: 0;
          left: 0;
          background: rgba(249,199,103,0.9);
          animation: box3 1.4s forwards;
        }
        @keyframes box1 {
          0%{opacity: 0;top: 20px}
          95%{opacity: 1;top: 0}
        }
        @keyframes box2 {
          0%{opacity: 0;bottom: -20px}
          12%{opacity: 0;bottom: -20px}
          95%{opacity: 1;bottom: 20px}
        }
        @keyframes box3 {
          0%{opacity: 0;bottom: -20px}
          24%{opacity: 0;bottom: -20px}
          95%{opacity: 1;bottom: 0}
        }
        h2{
          position: absolute;
          font-size: 24px;
          color: #666;
          left: 50%;
          bottom: -70px;
          white-space: nowrap;
          transform: translateX(-50%);
        }
        .tip{
          position: absolute;animation: dimFade .4s forwards;
          width: 540px;padding: 30px 40px 10px;
          background: #fff;border-radius: 10px;
          left: 50%;top: 46.8%;transform: translate(-50%,-50%);
          box-shadow: 0 0 6px 2px rgba(200,200,200,.3);
          h5{
            font-size: 28px;
            color: #333;
            line-height: 2;
          }
          h6{
            text-align: justify;
            font-size: 24px;
            color: #4d4d4d
          }
          .close{
            width: 100px;
            color: #2da9f7;
            height:40px;
            display: flex;
            align-items: center; 
            justify-content: center;
            margin: 20px auto 10px;
            border-radius: 6px;
          }
        }
      }
      h4{
        font-size: 30px;
        text-align: justify;
        font-weight: bold;
        margin-bottom: 24px;
        span{color: #2da9f7;}
      }
      p{
        font-size: 28px;
        color: #6f6f6f;
        padding-left: 30px;
        position: relative;
        text-align: justify;
        margin-bottom: 6px;
        &::before{
          content: '';
          position: absolute;
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background: #6f6f6f;
          left: 6px;
          top: 14px;
        }
      }
    }
    .suggest{
      h4{
        line-height: 1;
        margin: 50px 0 30px;
        padding-left: 30px;
        position: relative;
        text-align: left;
        font-size: 30px;
        &::before{
          content: '';
          position: absolute;
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background: #2da9f7;
          left: 6px;
          top: 10px;
        }
      }
      p{
        text-align: justify;
        font-size: 28px;
        line-height: 1.5;
        b{color: #2da9f7}
      }
    }
    .book-box{
      display: flex;
      margin-bottom: 40px;
      .book{
        width: 33.33%;
        font-size: 20px;
        padding: 0 10px;
        box-sizing: border-box;
        img{
          width: 120px;
          height: 175px;
          margin: 0 auto;
        }
        h5{color: #aaa;}
      }
    }
  }
  .img-box{
      width: 100%;
      height: 100%;
      position: fixed;
      background: rgba(0,0,0,0.8);
      top: 0;
      left: 0;
      z-index: 9999;
      font-size:30px;
      .text{line-height: 100px;color:#fff;}
      .inner-box{
        flex: 1;
        height: 80%;
        margin:0 30px 20px;
        overflow-y: auto;
        img{width: 100%;height: auto;display: block;}
      }
      .close{
        display: block;
        width: 70px;
        height: 70px;
        margin:0 auto;
      }
  }
}
.long::before{height: 0;}
</style>