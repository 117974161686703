<template>
  <div class="login" @click="countryCodeShow=false">
    <div class="tips"><p v-if="link=='bind'">请先绑定手机号</p></div>
    <ul class="container">
      <li :class="{curr: focusType == 1}">
        <h1>请输入手机号</h1>
        <div class="row" :class="{error:errType==1}">
          <div class="country_code flex-c-c" @click.stop="countryCodeShow=!countryCodeShow">
            {{country.label}}<span class="flex-c-c">></span>
            <div class="code_box" :style="`transform:scaleY(${countryCodeShow?1:0})`">
              <p v-for="item in $country" :key="item.country_code" @click="country = item">{{item.label}}</p>
            </div>
          </div>
          <input type="number" @focus="focusType=1;errType=0" @blur="focusType=0" v-model="mobile"  @input="mobile.length>5?mobile=mobile.slice(0,11):mobile">
          <div class="err">{{errText}}</div>
        </div>
      </li>
      <li :class="{curr: focusType == 2}">
        <h1>请输入验证码</h1>
        <div class="row" :class="{error:errType==2}">
          <input style="padding:0 5px" type="number" @focus="focusType=2;errType=0" @blur="focusType=0" v-model="captcha"  @input="captcha.length>5?captcha=captcha.slice(0,6):captcha">
          <div class="get_btn disable" v-if="sencond>0">重新发送{{sencond}}s</div>
          <div class="get_btn" @click="checkCaptcha" v-else>获取验证码</div>
          <div class="err">{{errText}}</div>
        </div>
      </li>
    </ul>
    <div class="btn flex-c-c" @click="login">{{link=='bind'?'确定':'登录'}}</div>
    <div class="wx" v-if="!link" @click="wxLogin">
      <div class="logo"><img src="../../../../../static/img/planet_card2.png"></div>
      <p>微信快捷登录</p>
    </div>
    <!-- <div class="wx" @click="loginOut"><p>退出登录</p></div> -->
  </div>
</template>
<script>
export default {
  data(){
    return {
      focusType: 0,
      mobile: '',
      captcha: '',
      errType: 0,
      errText: '',
      countryCodeShow: false,
      country: {label: '+86', country_code: '86', reg: /^1[3-9]{1}[0-9]{1}[0-9]{8}$/},
      sencond: 0,
      link: this.$route.query.link || '', //change：切换账号；bind：绑定手机号
      loginType: '',
    }
  },
  created(){
    document.title = ''
  },
  mounted() {
    wx.ready(() => {
      wx.onMenuShareAppMessage({
        title : '人才发展评估',
        link : `${this.mobileUrl}/#/TDA/shop`,
        imgUrl : `${this.mobileUrl}/static/img/p-15.jpg`,
      })
    })
  },
  methods:{
    //检验手机号
    checkCaptcha(){
      if (!this.mobile) {
        this.errText = '请先输入手机号码'
        this.errType = 1
      } else if (!this.country.reg.test(this.mobile)){
        this.errText = '手机号码格式不对'
        this.errType = 1
      } else {
        this.$indicator.open({text: '发送中...',spinnerType: 'fading-circle'})
        this.$http.get(`${this.spaceUrl}/User/checkMobile?mobile=${this.mobile}`).then(({data}) => {
          if (data.code == 0) {
            this.getCaptcha('login')
          } else if (data.msg == '手机号未注册') {
            this.getCaptcha('register')
          } else {
            this.$toast({message: data.msg})
            this.$indicator.close()
          }
        }, () => {
          this.$toast({message:'网络错误'})
          this.$indicator.close()
        })
      }
    },
    getCaptcha(type) {
      this.loginType = type
      this.$http.post(this.spaceUrl+'/sms/getSmsCaptcha', {
        type,
        mobile: this.mobile,
        country_code: this.country.country_code,
      }).then(({data}) => {
        this.$indicator.close()
        if (data.code == 0) {
          this.$toast({message: '已发送，请注意查收'})
          this.sencond = 60
          let timer = setInterval(() => {
            if (this.sencond-- < 0) clearInterval(timer)
          }, 1000)
        } else {
          this.$toast({message: data.msg})
        }
      }, () => {
        this.$toast({message:'网络错误'})
        this.$indicator.close()
      })
    },
    login(){
      if (!this.captcha) {
        this.errText = '请先输入验证码'
        this.errType = 2
        return
      }
      if (this.loginType == 'register') { //用户注册
        this.$http.post(this.spaceUrl+'/User/register', {
          captcha: this.captcha,
          mobile: this.mobile,
          country_code: this.country.country_code,
        }).then(({data}) => {
          if (data.code == 0) {
            localStorage.setItem("user", JSON.stringify(data.data))
            // if (this.link == 'bind')
            //   this.$router.replace({path: '/TDA/pay'})
            // else
              this.$router.go(-1)
          } else {
            this.$toast({message: data.msg})
          }
        }, () => this.$toast({message:'网络错误'}))
      } else {  //用户登录
        this.$http.post(this.spaceUrl+'/User/login', {
          captcha: this.captcha,
          mobile: this.mobile,
          country_code: this.country.country_code,
          hy_session: localStorage.getItem("session")
        }).then(({data}) => {
          if (data.code == 0) {
            localStorage.setItem("user", JSON.stringify(data.data))
            this.$router.go(-1)
          } else {
            this.$toast({message: data.msg})
          }
        }, () => this.$toast({message:'网络错误'}))
      }
    },
    loginOut() {
      this.$http.get(this.spaceUrl+'/User/loginOut')
    },
    wxLogin() {
      // let url = this.link = 'detail' ? location.href : '/#/TDA/shop'
      location.replace(`${this.spaceUrl}/WeixinH5/login?url=${encodeURIComponent(this.mobileUrl + '/#/TDA/shop')}`)
    },
  },
}
</script>
<style lang="scss" scoped>
.login{
  padding: 0 60px;
  height: 100%;
  .tips{
    height: 80px;
    p{
      line-height: 80px;
      color: #2c2c2c;
      font-size: 28px;
      font-weight: bold;
    }
  }
  .container{
    text-align: left;
    li{
      margin: 80px 0 100px;
      h1{
        font-size: 30px;
        color: #ccc;
        margin: 30px 0 10px;
      }
      .row{
        display: flex;
        align-items: center;
        border-bottom: 4px solid #ccc;
        height: 70px;
        position: relative;
        input{
          height: 60px;
          width: 100%;
          font-size: 30px;
          flex: 1;
        }
        .get_btn{
          width: 160px;
          line-height: 48px;
          background: #e6f2ff;
          color: #2e90ff;
          border-radius: 8px;
          border: 1px solid #2e90ff;
          font-size: 26px;
          text-align: center;
        }
        .disable{
          color: #999;
          border-color: #999;
          background: #f6f6f6;
        }
        .err{
          color: #ff3846;
          position: absolute;
          top: 80px;
          left: 0;
          right: 0;
          text-align: center;
          font-size: 26px;
          line-height: 1;
          display: none;
        }
      }
      .error{
        border-bottom-color: #ff3846;
        .err{display: block;}
      }
      .country_code{
        line-height: 60px;
        padding: 0 30px;
        font-size: 30px;
        color: #424242;
        position: relative;
        span{
          font-size: 28px;
          width: 40px;
          height: 40px;
        }
        .code_box{
          position: absolute;
          top: 64px;
          min-width: 120px;
          background: #fff;
          border-radius: 4px;
          box-shadow: 0 0 10px rgba(50,50,50,.4);
          font-size: 30px;
          left: 0px;
          transition: transform .1s;
          overflow: hidden;
          transform-origin: 50% 0;
          z-index: 10;
          p{
            line-height: 80px;
            white-space: nowrap;
            padding: 0 20px 0 30px;
          }
        }
      }
    }
    .curr{
      h1{
        font-weight: bold;
        color: #424242;
      }
      .row{
        border-bottom-color: #2e90ff;
      }
    }
  }
  .btn{
    color: #fff;
    background: #2e90ff;
    height: 80px;
    font-size: 30px;
    margin: 120px 0 20px;
    border-radius: 10px;
  }
}
.wx{
  width: 180px;
  margin: 200px auto 0;
  color: #ccc;
  font-size: 24px;
  line-height: 2;
  .logo{
    width: 80px;
    height: 80px;
    background: #28c445;
    border-radius: 50%;
    overflow: hidden;
    margin: 0 auto;
    img{
      width: 100%;
      height: 100%;
    }
  }
}
</style>