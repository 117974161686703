<template>
  <div class="report" id="report" :class="{long:long}">
    <div class="top">
      <header>
        <div class="user">{{team_name}} <span>报告分析</span></div>
        <div class="time">{{$formatTime(endTime,'y-m-d h:m')}}</div>
        <div class="btn" @click="save" v-if="!long">报告长图</div> 
      </header>
      <nav v-if="!long">
        <span :class="{current:tabId==0}" @click="tab(0)">团队<br>基本信息</span>
        <span :class="{current:tabId==1}" @click="tab(1)">团队<br>活力度</span>
        <span :class="{current:tabId==2}" @click="tab(2)" v-if="saleShow">销售<br>行为分析</span>
        <span :class="{current:tabId==3}" @click="tab(3)" v-if="leaderShow">卓越<br>领导力</span>
        <span :class="{current:tabId==4}" @click="tab(4)">工作价值<br>倾向分析</span>
      </nav>
    </div>
    <ul class="content">
      <!-- 团队基本信息 -->
      <li class="message" v-show="tabId==0 || long">
        <div class="title" v-if="long" style="margin-top:0px">团队基本信息</div>
        <div class="sex">
          <div class="bar" :style="{width:womanPercent+'%'}"></div>
          <div class="bar man-bar" :style="{width:100-womanPercent+'%'}"></div>
          <span>{{Math.round(womanPercent)}}%</span>
          <span class="man">{{100-Math.round(womanPercent)}}%</span>
          <img src="../../assets/images/trump/woman.png">
          <img src="../../assets/images/trump/man.png" class="man">
        </div>
        <h6>团队当前成员共有{{totalNum}}人，其中男性成员{{basic_info.sex_info[0].number||0}}人，女性成员{{basic_info.sex_info[1].number||0}}人，男女性别比例为{{sexScale}}。</h6>
        
        <piePeople :width="width*.8" :data="basic_info.tier_info"></piePeople>
        <h6>团队中{{tierText}}。</h6>

        <div class="education">
          <div class="left">
            <div v-for="(item,index) in basic_info.educational_info" :key="index">{{item.name}}</div>
          </div>
          <div class="right">
            <div class="row" v-for="(item,index) in basic_info.educational_info" :key="index">
              <div class="bar"><span :style="{width:item.percent+'%'}"></span></div>
              {{Math.round(item.percent||0)}}%
            </div>
          </div>
        </div>
        <h6>团队中{{eduText}}。</h6>
        
        <div class="age">
         <div class="circle" v-for="(it,i) in basic_info.era_info" :key="i" v-show="i<5"><i :style="{transform: `scale(${it.percent},${it.percent})`}"></i><span>{{it.era}}</span></div>
        </div>
        <h6>团队平均年龄为{{basic_info.avg_age}}岁，{{eraText}}。</h6>
      </li>
      <!-- 团队活力度 -->
      <li class="active" v-show="tabId==1 || long">
        <div class="title" v-if="long">团队活力度</div>
        <radarCircle :width="width*.88" :data="team_activity.dimension" :animate="true" ref="radar1"></radarCircle>
        <div class="young">
          <img src="../../assets/images/trump/young.png">
          指数：<span>{{team_activity.young.score}}</span>
        </div>
        <h1>通过Young成员的答题，我们了解到：</h1>
        <p class="nice" v-for="(it,i) in team_activity.top_dimension" :key="i">{{it.content}}</p>
        <p class="risk" v-for="(it,i) in team_activity.low_dimension" :key="i">{{it.content}}</p>
        <!-- <p v-if="team_activity.young.content">{{team_activity.young.content}}</p> -->
        <div class="warn" v-if="team_warn">
          <h2>团队预警</h2>
          <div v-for="(item,i) in team_warn" :key="i">
            <h3>{{item.name}}</h3>
            <p v-html="item.description"></p>
          </div>
        </div>

        <lineRect :data="team_activity.dimension" :width="width*.86" :animate="true"></lineRect>
        <p class="risk" v-if="team_activity.activeText.length">当前Young团队成员在<b v-for="(it,j) in team_activity.activeText" :key="j">{{it}}{{j==team_activity.activeText.length-1?'':'、'}}</b>方面的一致性较低。</p>

        <!-- <peak :width="width*.86" :data="team_activity.dimension" :animate="true"></peak>
        <p v-for="(item,i) in team_activity.activeText" :key="" v-show="item.length>0">
          当前Young团队成员在
          <b v-for="(it,j) in item" :key="">{{it}}{{j==item.length-1?'':'、'}}</b>
          方面的一致性{{i==3?'较低':i==2?'中等':i==1?'中上':'相对较高'}}。
        </p> -->
      </li>
      <!-- 销售行为分析 -->
      <li class="sale" v-show="tabId==2 || long" v-if="saleShow">
        <div class="title" v-if="long">销售行为分析</div>
        <radarSale ref="radarSale" :width="width*.86" :data="team_sale.dimension" :animate="true"></radarSale>
        <h1>通过Young成员的答题，我们了解到：</h1>
        <p v-for="(it,i) in team_sale.saleText" :key="i" v-html="it"></p>
      </li>
      <!-- 卓越领导力 -->
      <li class="leadership" v-show="tabId==3 || long" v-if="leaderShow">
        <div class="title" v-if="long">卓越领导力</div>
        <bar :width="width*.86" :data="team_leadership.dimension" :animate="true"></bar>
        <h2>卓越领导力指数：<span>{{team_leadership.leadership.score}}</span></h2>
        <h1>通过Young成员的答题，我们了解到：</h1>
        <p class="nice" v-for="(it,i) in team_leadership.top_dimension" :key="i">{{it.content}}</p>
        <p class="risk" v-for="(it,i) in team_leadership.low_dimension" :key="i">{{it.content}}</p>
      </li>
      <!-- 工作价值倾向分析 -->
      <li class="values" v-show="tabId==4 || long">
        <div class="title" v-if="long">工作价值倾向分析</div>
        <radarCircle :width="width*.88" :data="team_workvalue.dimension" :animate="true" ref="radar2"></radarCircle>
        <h1>通过Young成员的答题，我们了解到：</h1>
        <p v-for="(it,i) in team_workvalue.top_dimension" :key="i">{{it.content}}</p>

        <lineRect :data="team_workvalue.dimension" :width="width*.86" :animate="true"></lineRect>
        <p class="risk" v-if="team_workvalue.wordText.length">当前Young团队成员在<b v-for="(it,j) in team_workvalue.wordText" :key="j">{{it}}{{j==team_workvalue.wordText.length-1?'':'、'}}</b>方面的一致性较低。</p>
        
        <!-- <peak :width="width*.86" :data="team_workvalue.dimension" :animate="true"></peak>
        <p v-for="(item,i) in team_workvalue.wordText" :key="" v-show="item.length>0">当前Young团队成员在<b v-for="(it,j) in item" :key="">{{it}}{{j==item.length-1?'':'、'}}</b>方面的一致性{{i==3?'较低':i==2?'中等':i==1?'中上':'较高'}}。</p> -->
      </li>
    </ul>
    <!-- 图像渐变 -->
    <svg class="hide">
      <defs>
        <linearGradient id="red" x1="0%" y1="0%" x2="0%" y2="100%">
          <stop offset="0%" style="stop-color:#ff3d94;stop-opacity:1"/>
          <stop offset="100%" style="stop-color:#ffa603;stop-opacity:1"/>
        </linearGradient>
        <linearGradient id="yellow" x1="0%" y1="0%" x2="0%" y2="100%">
          <stop offset="0%" style="stop-color:#fe7218;stop-opacity:1"/>
          <stop offset="100%" style="stop-color:#feb201;stop-opacity:1"/>
        </linearGradient>
      </defs>
    </svg>
    <div class="img-box" v-if="reportImgShow">
      <p class="tips">长按保存图片</p>
      <div class="inner-box"><img :src="reportImg"></div>
      <img @click="closeImg" class="close" src="../../assets/images/icons/close.png"/>
    </div>
  </div>
</template>
<script>
import piePeople from './group/piePeopleChart'
import radarCircle from './group/radarCircleChart'
// import peak from './group/peakChart'
import radarSale from './group/radarSaleChart'
import bar from './group/barChart'
import lineRect from './group/lineRectChart'
export default {
  components:{piePeople,radarCircle,radarSale,bar,lineRect},
  data(){
    return{
      width: 0,
      tabId: 0,
      report_id: this.$route.params.report_id||'',
      hy_session: this.$route.query.hy_session||'',         //（只用于保存报告长图）
      long: this.$route.query.long===undefined?false:true,  //是否报告长图
      team_name: 'Hello',       //用户姓名
      endTime: 0,               //报告完成时间
      totalNum: 0,
      reportImg: '',
      reportImgShow: false,
      timer: '',
      // 团队基本信息：
        basic_info:{
          avg_age:0,
          educational_info:[
            {name: "高中及以下",number:0},
            {name: "大专",number:0},
            {name: "本科",number:0},
            {name: "硕士研究生",number:0},
            {name: "博士及博士后",number:0},
          ],
          era_info:[
            {era: "00",number:0},
            {era: "90",number:0},
            {era: "80",number:0},
            {era: "70",number:0},
            {era: "60",number:0},
            {era: "其它",number:0},
          ],
          sex_info: [{sex:1},{sex:2}],
          tier_info: '',
        },
      // 团队活力度：
        team_activity:{
          dimension:[],
          top_dimension:[],
          low_dimension:[],
          young:{score:0,content:''},
          activeText:[[],[],[],[]],//高中低维度
        },
        team_warn: null,
      // 销售行为分析:
        team_sale:{
          dimension:[],
          top_dimension:[],
          saleText:[]
        },
        saleText:[
          {name:'积极心态',text:'Young团队成员的<b style="color: #e5b552">心态管理</b>相对较好，能从积极的角度思考，主动采取行动，确保目标达成。'},
          {name:'灵活应变',text:'Young团队成员的<b style="color: #e5b552">心态管理</b>相对较好，面对变化能够主动顺应、调整，保证工作成果。'},
          {name:'自我管理',text:'Young团队成员善于管理自己，时刻保持最佳状态，让<b style="color: #e5b552">拜访活动</b>更加有效。'},
          {name:'自我期待',text:'Young团队成员会在不同时期，为自己设定更高的目标，全力以赴达成，让<b style="color: #e5b552">拜访活动</b>维持在高水平。'},
          {name:'有序行动',text:'Young团队成员能够合理规划目标，安排计划，行动有序，让<b style="color: #e5b552">拜访活动</b>事半功倍。'},
          {name:'倾听交流',text:'Young团队成员善于聆听，让对方感到舒适、愉快，让<b style="color: #e5b552">销售面谈</b>质量更高。'},
          {name:'同理共情',text:'Young团队成员总是能把握他人情绪，理解对方的感受，让<b style="color: #e5b552">销售面谈</b>取得积极成果。'},
          {name:'人际影响',text:'Young团队成员善于洞察他人内在的想法，理解对方的需求，让<b style="color: #e5b552">销售面谈</b>更加有效。'},
          {name:'目标驱动',text:'Young团队成员有效的<b style="color: #e5b552">目标管理</b>，让内心的动力源源不断，不断追求更高的成就。'},
          {name:'保证成果',text:'Young团队成员有效的<b style="color: #e5b552">目标管理</b>，指引他们以终为始，确保每一个目标都能转换为成果。'},
        ],
        saleShow: true,
      // 卓越领导力：
        team_leadership:{
          dimension:[],
          leadership: {score: 0},
          low_dimension:[],
          top_dimension:[]
        },
        leaderShow: true,
      // 工作价值倾向分析：
        team_workvalue:{
          dimension:[],
          top_dimension:[],
          wordText:[[],[],[],[]],//高中低维度
        }
    }
  },
  created(){
    this.$http.get(this.ajaxUrlPath+'/AceTeam/getTeamReportInfo?team_report_id='+this.report_id+'&is_share='+1).then((e) =>{
      let data = e.data.data
      if(!e.data.code){
        //团队基本信息：
          this.team_name = data.team_info.team_name
          this.endTime = data.team_info.report_time
          let total = this.totalNum = data.team_info.total_number
          let basic_info = data.basic_info
          //层级
          for(let i in basic_info.tier_info){
            basic_info.tier_info[i].percent = basic_info.tier_info[i].number / total
          }
          if(basic_info.tier_info.length==1 && basic_info.tier_info[0].tier_id==1)
            this.leaderShow = false
          //学历
          for(let i in basic_info.educational_info){
            if(basic_info.educational_info[i].name == '博士研究生及博士后')
              basic_info.educational_info[i].name = '博士及博士后'
            basic_info.educational_info[i].percent = basic_info.educational_info[i].number / total * 100
          }
          //年龄段
          let maxNum = Math.max.apply(Math, basic_info.era_info.map((e)=>{return e.number}))
          for(let i in basic_info.era_info){
            basic_info.era_info[i].percent = basic_info.era_info[i].number / maxNum * .8 + .2
          }
          this.basic_info = basic_info

        // 团队活力度：
          // let activity = data.team_activity,
          //     activeText = [[],[],[],[]]
          // for(let i in activity.dimension){
          //   if(activity.dimension[i].dispersion>0.8){ //低
          //     activeText[3].push(activity.dimension[i].name)
          //   }else if(activity.dimension[i].dispersion>.3){ //中等
          //     activeText[2].push(activity.dimension[i].name)
          //   }else if(activity.dimension[i].dispersion>.15){ //中上
          //     activeText[1].push(activity.dimension[i].name)
          //   }else //高
          //     activeText[0].push(activity.dimension[i].name)
          // }
          // activity.activeText = activeText
          // this.team_activity = activity
              let activeText = []
              for (let item of data.team_activity.dimension) {
                if (item.dispersion > 0.8) { //低
                  activeText.push(item.name)
                }
              }
              data.team_activity.activeText = activeText
              this.team_activity = data.team_activity
          if (data.team_activity_warn && data.team_activity_warn.length)
            this.team_warn = data.team_activity_warn
        // 销售行为特性：
          if(data.team_info.sale_show==0)
            this.saleShow = false
          let saleText = []
          for(let i in data.team_sale.top_dimension){
            for(let j in this.saleText){
              if(data.team_sale.top_dimension[i]==this.saleText[j].name)
                saleText.push(this.saleText[j].text)
            }
          }
          data.team_sale.saleText = saleText
          this.team_sale = data.team_sale

        // 卓越领导力：
          if(data.team_leadership.leadership)
            this.team_leadership = data.team_leadership

        // 工作价值倾向分析：
          // let workvalue = data.team_workvalue,
          //     wordText = [[],[],[],[]]
          // for(let i in workvalue.dimension){
          //   if(workvalue.dimension[i].dispersion>0.8){ //低
          //     wordText[3].push(workvalue.dimension[i].name)
          //   }else if(workvalue.dimension[i].dispersion>.3){ //中等
          //     wordText[2].push(workvalue.dimension[i].name)
          //   }else if(workvalue.dimension[i].dispersion>.15){ //中上
          //     wordText[1].push(workvalue.dimension[i].name)
          //   }else //高
          //     wordText[0].push(workvalue.dimension[i].name)
          // }
          // workvalue.wordText = wordText
          // this.team_workvalue = workvalue
                  let wordText = []
                  for (let item of data.team_workvalue.dimension) {
                    if (item.dispersion > 0.8) { //低
                      wordText.push(item.name)
                    }
                  }
                  data.team_workvalue.wordText = wordText
                  this.team_workvalue = data.team_workvalue
      }else{
        this.$toast({message:'加载失败',duration: 1000})
      }
    })
  },
  mounted(){
    this.width = document.getElementById('report').clientWidth
  },
  methods:{
    tab(e){
      this.$refs.radar1.showIndex = null
      this.$refs.radar2.showIndex = null
      if (this.saleShow) this.$refs.radarSale.showIndex = null
      if(this.tabId==e) return;
      this.tabId = e;
    },
    save(){
      if (this.reportImg)
        this.reportImgShow = true
      else
        this.$http.get(this.ajaxUrlPath+'/AceTeam/getTeamReportImage?team_report_id='+this.report_id+'&hy_session='+this.hy_session).then((e) =>{
          if (e.data.code == 0) {
            this.reportImg = e.data.data
            this.reportImgShow = true
          } else {
            this.$http.post(this.ajaxUrlPath+'/CardImage/create',{
              type: 'trumpReport',
              imageurl: `${this.mobileUrl}/#/aceTeamGroup/${this.report_id}?long`,
              width: '480',
              height: '1000',
            }).then( e => {
              if(!e.data.code){
                this.$indicator.open({text: '图片生成中...',spinnerType: 'triple-bounce'});
                this.timer = setInterval(()=>{
                  this.$http.post(this.ajaxUrlPath+'/CardImage/getreport',{
                    type: 'trumpReport',//与上保持一致
                    messageId: e.data.data.messageId,
                  }).then(e=>{
                    if (e.data.code == 0) {
                        this.$indicator.close();
                        this.reportImg = e.data.data.image;
                        this.reportImgShow = true
                        clearInterval(this.timer);
                        // 保存图片
                        // this.$http.post(this.ajaxUrlPath+'/AceTeam/updateTeamReportImage?hy_session='+this.hy_session,{
                        //   team_report_id: this.report_id,
                        //   report_image: this.reportImg,
                        // })
                    }
                  })
                },2500)
              }
            })
          }
        })
    },
    closeImg(){
      this.reportImgShow = false
    },
  },
  computed:{
    womanPercent(){
      let woman = this.basic_info.sex_info[1].number||0
      if(this.totalNum){
        return woman/this.totalNum*100
      }else
        return 50
    },
    sexScale(){
      let m = this.basic_info.sex_info[0].number||0 //男
      let n= this.basic_info.sex_info[1].number||0  //女
      let max =+ m, v =+ n, t = v;
      while(v!=0){
        t = max%v;
        max = v;
        v = t;
      }
      if(max)
        return m/max + ":" + n/max
      else
        return '0:0'
    },
    tierText(){
      let text = '', tier = this.basic_info.tier_info;
      if(tier!=''){
        for(let i = tier.length; i--; i>0){
          if(tier[i].number)
            text += tier[i].name + tier[i].number + '人，'
        }
        text = text.substring(0, text.length-1); 
        return text
      } else return ''
    },
    eduText(){
      let text = '', edu = this.basic_info.educational_info;
      if(edu!=''){
        for(let i = 0; i < edu.length; i++){
          if(edu[i].number)
            text += edu[i].name + edu[i].number + '人，'
        }
        text = text.substring(0, text.length-1); 
        return text
      } else return ''
    },
    eraText(){
      let text = '', era = this.basic_info.era_info;
      if(era!=''){
        for(let i = 0; i < era.length; i++){
          if(era[i].number)
            text += era[i].era + era[i].number + '人，'
        }
        text = text.substring(0, text.length-1); 
        return text
      } else return ''
    }
  },
}
</script>
<style scoped lang="scss">
.report{
  color:#6f6f6f;
  padding-bottom: 40px;
  box-shadow: 0 0 10px rgba(200,200,200,.6);
  position: relative;
  &::before{content: '';display: block;height: 194px;}
  .hide{height: 0;overflow: hidden;position: absolute;}
  .top{
    position: absolute;
    width: 100%;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    background: #fff;
    z-index: 2;
    header{
      display: flex;
      align-items: center;
      font-size: 26px;
      color: #aaa;
      padding: 0 40px;
      height: 120px;
      justify-content: space-between;
        line-height: 1.1;
      .user{
        text-align: left;
        padding-right: 20px;
        span{font-size: 24px;}
      }
      .btn{
        width: 130px;
        color: #fff;
        border-radius: 6px;
        background: #e5b552;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 24px;
        white-space: nowrap;
        margin-left: 10px;
      }
    }
    nav{
      display: flex;
      border-top: 1px solid #f0f0f0;
      border-bottom: 1px solid #f0f0f0;
      color: #999;
      height: 80px;
      background: #fff;
      box-sizing: border-box;
      font-size: 24px;
      align-items: center;
      overflow-x: auto;
      overflow-y: hidden;
      &::-webkit-scrollbar{display: none}
      span{
        flex: 1;
        line-height: 1.2;
        height: 100%;
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
      }
      .current{
        font-size: 26px;
        color: #4d4d4d;
        font-weight: bold;
        &::after{
          content: '';
          position: absolute;
          width: 98%;
          height: 4px;
          background: #e5b552;
          bottom: -2px;
          left: 0;
        }
      }
    }
  }
  .content{
    overflow-x: hidden;
    li{
      margin: 0 40px;
      h1{
        font-size: 28px;
        text-align: left;
        font-weight: bold;
        margin: 20px 0;
        color: #5c5c5c;
      }
      p{
        padding-left: 24px;
        text-align: justify;
        font-size: 26px;
        position: relative;
        color: #666;
        b{color: #e5b552}
        &::before{
          content: '';
          position: absolute;
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background: #e5b552;
          left: 4px;
          top: 15px;
        }
      }
      .nice::before{
        width: 12px;
        height: 12px;
        top: 10px;
        left: -2px;
        background: #e5b552;
      }
      .risk{
        position: relative;
        &::before{
          border-radius: 0;
          position: absolute;
          content: '';
          background: url('../../assets/images/report/risk.png') no-repeat;
          background-size: 100%;
          width: 24px;
          height: 24px;
          top: 4px;
          left: -10px;
        }
      }
    }
    .title{
      font-size: 30px;
      color: #e5b552;
      font-weight: bold;
      line-height: 80px;
      margin-top: 150px;
    }
    .message{
      h6{
        font-size: 26px;
        text-align: justify;
        position: relative;
        padding-left: 26px;
        margin: 50px 0;
        &::before{
          content: '';
          position: absolute;
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background: #7a7a7a;
          left: 4px;
          top: 12px;
        }
      }
      .sex{
        position: relative;
        display: flex;
        width: 76%;
        margin: 240px auto 0;
        padding-bottom: 50px;
        .bar{
          border: 1px solid #e86a50;
          height: 12px;
          background: #efa697;
          width: 50%;
          box-sizing: border-box;
          border-radius: 2px;
          transition: width .8s;
        }
        .man-bar{
          border: 1px solid #3076f3;
          background: #acc8fa;
          // flex: 1;
        }
        span{
          position: absolute;
          top: 24px;
          left: 20px;
          font-size: 24px;
          color: #a6a6a6;
        }
        img{
          position: absolute;
          bottom: 70px;
          left: 10px;
          width: 65px;
        }
        .man{
          left: auto;
          right: 10px;
        }
      }
      .education{
        display: flex;
        margin: 180px 0 60px;
        .left{
          line-height: 70px;
          width: 220px;
          border-right: 1px dashed #ccc;
          color: #999;
          text-align: right;
          padding-right: 44px;
        }
        .right{
          flex: 1;
          .row{
            display: flex;
            align-items: center;
            height: 70px;
            justify-content: space-between;
            padding-right: 20px;
            color: #999;
            .bar{
              width: 78%;
              height: 12px;
              background: #f7f7f7;
              border-radius: 2px;
              font-size: 24px;
              span{
                background: #f4d79b;
                display: block;
                height: 100%;
                width: 0%;
                box-sizing: border-box;
                border-radius: 2px;
                box-sizing: border-box;
                border: 1px solid #eab748;
                background: #f4d79b;
                transition: width .6s;
              }
            }
            &:nth-child(even) .bar span{
              border: 1px solid #4589d5;
              background: #a5c0f1;
            }
          }
        }
      }
      .age{
        display: flex;
        justify-content: center;
        margin: 200px 0 90px;
        .circle{
          margin: 0 10px;
          align-self:center;
          border-radius: 50%;
          position: relative;
          width: 100px;
          height: 100px;
          flex-shrink:0;
          i{
            display: block;
            width: 100%;height: 100%;
            border-radius: 50%;
            transform: scale(.2,.2);
            transition: transform .6s;
          }
          &:nth-child(1) i{border: 1px solid #d7562e;background: #efa697;}
          &:nth-child(2) i{border: 1px solid #f5c050;background: #f9d997}
          &:nth-child(3) i{border: 1px solid #88b56d;background: #c7e4b5}
          &:nth-child(4) i{border: 1px solid #72a1f8;background: #a7c3f3}
          &:nth-child(5) i{border: 1px solid #f69c44;background: #f6c18d}
          span{
            position: absolute;
            bottom: -40px;
            left: 50%;
            transform: translateX(-50%);
            color: #999;
            font-size: 24px;
            white-space: nowrap;
          }
        }
      }
    }
    .active{
      .warn{
        h2,h3{
          font-size: 28px;
          margin: 50px 0 10px;
          color: #5c5c5c;
          text-align: left;
          font-weight: bold;
        }
        h2{color: #e86a50}
        h3{
          padding-left: 24px;
          margin: 10px 0 4px;
          position: relative;
          &::before{
            content: '';
            position: absolute;
            width: 12px;
            height: 12px;
            border-radius: 50%;
            background: #f42e29;
            left: 5px;
            top: 15px;
          }
        }
        p::before{display: none}
      }
      .young{
        display: flex;
        align-items: center;
        font-size: 28px;
        justify-content: center;
        margin-top: -80px;
        img{width: 160px;margin-right: 10px}
        span{color: #e5b552;font-weight: bold}
      }
      h1{margin: 60px 0 20px;}
    }
    .sale{
      h1{margin-top: -30px}
    }
    .leadership{
      h2{
        font-size: 30px;
        font-weight: bold;
        margin-bottom: 80px;
        color: #505050;
        span{color: #e5b552}
      }
    }
    .values{
      h1{margin-top: -40px}
    }
  }
  .img-box{
      width: 100%;
      height: 100%;
      position: fixed;
      background: rgba(0,0,0,0.8);
      top: 0;
      left: 0;
      z-index: 3;
      font-size:30px;
      .tips{line-height: 100px;color:#fff;}
      .inner-box{
        flex: 1;
        height: 80%;
        margin:0 30px 20px;
        overflow-y: auto;
        img{width: 100%;height: auto;display: block;}
      }
      .close{
        display: block;
        width: 70px;
        height: 70px;
        margin:0 auto;
      }
  }
}
.long{
  &::before{height: 120px;}
}
</style>