<template>
  <!-- <svg class="columnar">
    <g class="bg"></g>
    <g class="data"></g>
  </svg> -->
  <div class="columnar">
    <svg>
      <g class="bg"></g>
      <g class="data"></g>
    </svg>
    <p v-if="animate && data.length">点击因子名称可查看详情</p>
    <div class="tip" v-if="currIndex!=null">
      <h6>{{data[currIndex].detail}}</h6>
      <div class="close" @click="currIndex=null">关 闭</div>
    </div>
  </div>
</template>

<script>
import * as d3 from 'd3'
export default {
  props: {
    data: {
      // type: Array,
      default: () => []
    },
    width: {    //宽高度
      type: Number,
    },
    animate:{
      type: Boolean,
      default: false,
    },
  },
  data(){
    return{
      size: {max:100,min:10,vH:50,eachH:10},
      color: ['#31a2d1','#5679e8','#956ae4','#56bd86','#95ca65','#c6b14f','#1e9381','#34a560'],
      currIndex: null,
    }
  },
  mounted(){
    if (this.data.length > 0) {
      this.draw(this.data)
    } else {
      this.$watch('data', (val) => this.draw(val))
    }
  },
  methods:{
    draw(e){
      let svg = d3.select(this.$el).select('svg').attr('width', this.width).attr('height', this.width)
      let gap =  this.width * 0.08,         //左边刻度宽度
          top = this.width * 0.05,          //顶部留空
          bottom = this.width * 0.2,        //底部留空
          fontSize = this.width * 0.035,    //文字大小
          vW = this.width - gap,            //可视宽度
          vH = this.width - top - bottom,   //可视高度
          len = e.length,                   //条形个数
          eachW = vW / len,                 //每一列的宽度
          barW = eachW * .55,               //条形的宽度
          xAxis = [],                       //x轴的坐标集合
          max = 0,                          //最大刻度
          min = 100,                        //最小刻度
          bg = svg.select('.bg');
      bg.append('polyline')
        .attr('fill', 'none')
        .attr('stroke', '#e5e5e5')
        .attr('stroke-width', 1)
        .attr('points', `${gap},${this.width-bottom} ${this.width},${this.width-bottom}`)
      //循环画底部标签名 并 算出最大最小刻度和需要计算的参数
      for (let i = 0; i < len; i++) {
        let x = gap+eachW/2 + i*eachW
        xAxis.push(x)
        bg.append('text')
          .attr('font-size', fontSize * 1.1)
          .attr('fill', this.color[i])
          .attr('x', x)
          .attr('y', this.width - bottom * .7)
          .text(e[i].name)
          .on("click", () => this.currIndex = i)
        if (e[i].score > max) max = e[i].score
        if (e[i].score < min) min = e[i].score
        if (e[i].reference_score > max) max = e[i].reference_score
        if (e[i].reference_score < min) min = e[i].reference_score
      }
      //画左边刻度
      min = Math.floor(min / 10)
      max = Math.ceil(max / 10)
      let eachH = vH / (max - min + 1), xIndex = 0;
      this.size = {vH, eachH, max: max * 10, min: min * 10}
      for (let i = max; i >= min; i--) {
        bg.append('text')
          .attr('font-size', fontSize)
          .attr('fill', '#808080')
          .attr('x', gap / 2)
          .attr('y',  top + eachH * (xIndex++))
          .text(i+'0')
      }
      //画条形数据
      let data = svg.select('.data'), lineStr = '';
      for (let i = 0; i < len; i++) {
        let h = this.getBarH(e[i].score),
            x = xAxis[i],
            y = this.width - bottom - h;
        data.append('rect')
            .attr('fill', this.color[i])
            .attr('stroke', 'none')
            .attr('x', x)
            .attr('y', y)
            .attr('rx', 1)
            .attr('width', barW)
            .attr('height', h)
            .attr('transform', `translate(${-barW/2},0)`)
        data.append('text')
            .attr('font-size', fontSize)
            .attr('fill', this.color[i])
            .attr('x', x)
            .attr('y', y - fontSize)
            .text(e[i].score)
        let cy = this.width - bottom - this.getBarH(e[i].reference_score)
        data.append('circle')
            .attr('cx', x)
            .attr('cy', cy)
            .attr('r', 3)
            .attr('stroke', 'none')
            .attr('fill', '#ff7200')
        lineStr += `${x},${cy} `
      }
      data.append('polyline')
          .attr('stroke-width', 2)
          .attr('fill', 'none')
          .attr('stroke', '#ff7200')
          .attr('points', lineStr)

      //添加动画
      if (this.animate) data.classed('move', true)
    },
    getBarH(score){
      let per = (score - this.size.min) / (this.size.max - this.size.min)
      return per * (this.size.vH - this.size.eachH) + this.size.eachH
    },
  },
}
</script>
<style scope lang="scss">
.columnar{
  position: relative;
  svg{
    display: block;
    margin: 0 auto;
    user-select: none;
  }
  text{
    text-anchor: middle;
    dominant-baseline: middle;
  }
  .move{
    transform-origin: center 80%;
    animation: moveData .6s forwards;
  }
  @keyframes moveData {
    0%{transform: scaleY(0)}
    100%{transform: scaleY(1)}
  }
  p{
    font-size: 24px;
    color: #999;
    text-align: center;
    margin: -50px 0 80px;
  }
  .tip{
    position: absolute;
    width: 540px;padding: 30px 40px 10px;
    background: #fff;border-radius: 10px;
    left: 50%;top: 50%;transform: translate(-50%,-50%);
    box-shadow: 0 0 6px 2px rgba(200,200,200,.3);
    animation: dimFade .2s;
    box-sizing: 0 0 20px rgba(50,50,50,.2);
    h5{
      font-size: 28px;
      color: #333;
      line-height: 2;
    }
    h6{
      text-align: justify;
      font-size: 24px;
    }
    .close{
      width: 100px;
      color: #33b580;
      height:40px;
      display: flex;
      align-items: center; 
      justify-content: center;
      margin: 20px auto 10px;
      border-radius: 6px;
    }
  }
}
</style>
