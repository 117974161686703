<!-- 传入数据说明（*代表必要元素）
  type：圆球类型（控制渐变颜色和名称）
  data*：分数
  width*：圆的宽度
  animate：是否启动动画
-->
<template>
  <div class="radar_chart">
    <svg class=""></svg>
    <p v-if="animate">点击维度名称可查看说明</p>
    <div class="tip" v-if="showIndex!=null">
      <!-- <h5>{{data[showIndex].name}}</h5> -->
      <h6>{{data[showIndex].dimension_detail}}</h6>
      <div class="close" @click="showIndex=null">关 闭</div>
    </div>
  </div>
</template>

<script>
import * as d3 from 'd3'
export default {
  props: {
    data: {
      type: Array,
    },
    width: {    //宽高度
      type: Number,
    },
    type:{
      type:Number,
      default:0
    },
    animate:{
      type:Boolean,
      default:false,
    },
    lineColor:{
      type: String,
      default: '#d8a43a'
    }
  },
  data(){
    return{
      svg:'',
      center: 0,
      radius: 0,
      text:['倾听交流','同理共情','人际影响','灵活应变','有序行动','保证成果','积极心态','自我期待','自我管理','目标驱动'],
      color:['#dd9317','#1352c4','#c11e26','#ce0b67','#99178d','#316a88','#1e9381','#34a560','#7aa022','#963937'],
      showIndex:null,
    }
  },
  mounted(){
    if(this.data.length>0){ //本地测试(ctrl+s保存时浏览器自动执行)
      this.init()
      this.drawData(this.data)
    }else{ //实际传入数据:
      this.$watch('width', () => {
        this.init()
      })
      this.$watch('data', (e) => {
        this.drawData(e)
      })
    }
  },
  methods:{
    compute(score,i){
      let per = score / 100 * this.radius
      let angel = 2 * Math.PI / 10 * i
      return [this.center + Math.sin(angel) * per, this.center - Math.cos(angel) * per]
    },
    init(){
      let svg = this.svg = d3.select(this.$el).select('svg').attr('width',this.width).attr('height',this.width)
      this.radius = this.width * 0.56 * 0.5  //圆半径
      let each = this.radius / 5             //每格大小
      let center = this.center = this.width / 2 //中点
      let nets = []
      //背景网格
      let bg = svg.append('g').classed('bg',true)
      for(let i = 0; i < 5; i ++){
        let pStr = '';
        for(let j = 0; j < 10; j ++){
          let p = this.compute(100-20*i,j)
          pStr +=  p[0] + ',' + p[1] + ' '
        }
        bg.append('polygon')
          .attr('fill', 'none')
          .attr('stroke', '#bfbfbf')
          .attr('stroke-dasharray', '3px 3px')
          .attr('stroke-width',1)
          .attr('points', pStr)
      }
    },
    //绘制环形分数
    drawData(e){
      let data = this.svg.append('g')
      let text = this.svg.append('g')
      let str = ''
      for(let i = 0; i < e.length; i ++){
        let p = this.compute(e[i].score, i)
        str +=  p[0] + ',' + p[1] + ' '
        data.append('circle')
            .attr('cx', p[0])
            .attr('cy', p[1])
            .attr('r', 3)
            .attr('stroke', 'none')
            .attr('fill', this.lineColor);
        //维度名称
        let textP = this.compute(122,i)
        text.append('text').classed('dimText', true)
            .attr('fill', this.color[i])
            .attr('font-size', this.radius*0.13)
            .attr('font-weight', 'bold')
            .attr('x', ()=>{
              if(i==2 || i==3) return textP[0] + this.radius*0.14
              else if(i==7 || i==8) return textP[0] - this.radius*0.14
              else return textP[0]
            })
            .attr('y', ()=>{
              if(i==0) return textP[1] + this.radius * 0.05
              else if(i==5) return textP[1] - this.radius * 0.05
              else return textP[1]
            })
            .text(e[i].name)
            .on("click",()=>{
              this.showIndex = i
            })
      }
      data.append('polygon')
          .classed('opacity',true)
          .attr('fill', this.lineColor)
          .attr('stroke', this.lineColor)
          .attr('stroke-width', 2)
          .attr('points', str)
      if(this.animate){
        data.classed('move',true)
      }
    },
  },
}
</script>
<style scope lang="scss">
.radar_chart{
  position: relative;
  p{font-size: 24px;color: #666;margin: -70px 0 100px;}
  .tip{
    position: absolute;
    width: 540px;padding: 30px 40px 10px;
    background: #fff;border-radius: 10px;
    left: 50%;top: 50%;transform: translate(-50%,-50%);
    box-shadow: 0 0 6px 2px rgba(200,200,200,.3);
    animation: dimFade .4s;
    h5{
      font-size: 28px;
      color: #333;
      line-height: 2;
    }
    h6{
      text-align: justify;
      font-size: 24px;
    }
    .close{
      width: 100px;
      color: #e29b0b;
      height:40px;
      display: flex;
      align-items: center; 
      justify-content: center;
      margin: 20px auto 10px;
      border-radius: 6px;
    }
  }
  text{
    text-anchor: middle;
    dominant-baseline: middle;
  }
  .move{
    transform-origin: center center;
    // animation-fill-mode: forwards;
    animation: scale .6s;
  }
  .opacity{
    fill-opacity: .15;
  }
  @keyframes scale {
    0%{transform: scale(0,0);}
    100%{transform: scale(1,1);}
  }
}
</style>
