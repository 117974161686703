<template>
  <div class="bar-row" :class="'bar'+colorType">

    <div class="text">
      <div v-for="(item,index) in data" :key="item.id" @click="toggle(index)">{{item.name}}</div>
    </div>
    <div class="content">
      <div class="box">
        <div>
          <span v-if="version==1">预警一</span>
          <span v-else>警示</span>
        </div>
        <div>
          <span>中等</span>
        </div>
        <div>
          <span>良好</span>
        </div>
        <div>
          <span>优秀</span>
        </div>
        <div style="border:none;">
          <span v-if="version==1">预警二</span>
          <span v-else>警示</span>
        </div>
      </div>
      <div class="bar-box">
        <div 
          class="bar" 
          v-for="(item,index) in data" 
          :key="index" 
          :style="{width:setWidth(item.score)}">
            <i>{{Math.round(item.score)}}</i>
        </div>
      </div>
    </div>

    <p v-if="click">点击维度名称可查看说明</p>
    <div class="tip" v-if="showIndex!=null">
      <h6>{{data[showIndex].dimension_detail}}</h6>
      <div class="close" @click="toggle(null)">关 闭</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Array,
    },
    colorType: {
      type: Number,
      default: 0
    },
    click: {
      type: Boolean,
      default: false
    },
    version: {
      default: 2
    }
  },
  data(){
    return{
      min: 30,
      max: 90,
      range: [30, 40, 50, 60, 80, 90],
      showIndex: null,
      show: true,
    }
  },
  methods:{
    setWidth(num){
      let result = 0;
      num = Math.round(num)
      if (num < this.range[1]) {
        result = (num - this.range[0]) / (this.range[1] - this.range[0]) * .2 
      } else if (num < this.range[2]){
        result = (num - this.range[1]) / (this.range[2] - this.range[1]) * .2  + .2
      } else if (num < this.range[3]){
        result = (num - this.range[2]) / (this.range[3] - this.range[2]) * .2  + .4
      } else if (num < this.range[4]){
        result = (num - this.range[3]) / (this.range[4] - this.range[3]) * .2  + .6
      } else {
        result = (num - this.range[4]) / (this.max - this.range[4]) * .2  + .8
      }
      return result * 100 + '%'
    },
    toggle(e){
      if(this.click) this.showIndex = e
    }
  },
}
</script>
<style lang="scss" scope>
.bar-row{
  display: flex;
  font-size: 24px;
  padding: 70px 0 20px;
  margin: 20px 0 80px 0;
  position: relative;
  .text{
    width: 114px;
    height: 100%;
    text-align: left;
    font-size: 24px;
    flex-shrink: 0;
      padding: 1px 0;
    div{
      line-height: 40px;
      height: 38px;
      margin: 26px 0;
    }
    div:nth-of-type(odd){color: #42b2fc;}
    div:nth-of-type(even){color: #7886f0;}
  }
  .content{
    flex: 1;
    background: #f7f7f7;
    position: relative;
    .box{
      height: 100%;
      width: 100%;
      display: flex;
      left: 0;
      top: 0;
      position: absolute;
      div{
        flex: 1;
        height: 100%;
        position: relative;
        color: #999;
        border-right: 1px dashed #b4b4b4;
        box-sizing: border-box;
        span{
          position: absolute;
          top: -40px;
          left: 0;
          width: 100%;
        }
        i{
          position: absolute;
          bottom: -40px;
          right: -12px;
        }
        &:nth-child(1){color: #e07556;background: #ffeaea}
        // &:nth-child(4){border-color: #e07556;}
        &:nth-child(5){color: #e07556;background: #ffeaea}
      }
    }
    .bar-box{
      position: relative;
      z-index: 2;
      height: 100%;
      padding: 1px 0;
      .bar{
        height: 38px;
        line-height: 40px;
        margin: 26px 0;
        width: 0%;
        text-align: right;
        color: #fff;
        padding-right: 10px;
        animation: width .6s forwards;
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
        &:nth-of-type(odd){
          background: #42b2fc;
        }
        &:nth-of-type(even){
          background: #7886f0;
        }
        i{
          animation: fade .6s forwards;
          opacity: 0;
        }
      }
    }
  }

  p{
    position: absolute;
    width: 100%;
    bottom: -30px;
    left: 0;
    font-size: 22px;
  }
}
.bar1{
  .text{
    div:nth-child(1){color: #51b8fb}
    div:nth-child(2){color: #28c8b7}
    div:nth-child(3){color: #4c88f3}
    div:nth-child(4){color: #ed7d31}
    div:nth-child(5){color: #6c7bf1}
    div:nth-child(6){color: #e96842}
  }
  .content .bar-box{
    .bar:nth-child(1){background: #51b8fb}
    .bar:nth-child(2){background: #28c8b7}
    .bar:nth-child(3){background: #4c88f3}
    .bar:nth-child(4){background: #ed7d31}
    .bar:nth-child(5){background: #6c7bf1}
    .bar:nth-child(6){background: #e96842}
  }
}
.bar2{
  .text{
    div:nth-child(1){color: #7a91fa}
    div:nth-child(2){color: #ecb41c}
    div:nth-child(3){color: #f25768}
    div:nth-child(4){color: #bb3fef}
    div:nth-child(5){color: #ed7d31}
    div:nth-child(6){color: #ff5d68}
  }
  .content .bar-box{
    .bar:nth-child(1){background: #7a91fa}
    .bar:nth-child(2){background: #ecb41c}
    .bar:nth-child(3){background: #f25768}
    .bar:nth-child(4){background: #bb3fef}
    .bar:nth-child(5){background: #ed7d31}
    .bar:nth-child(6){background: #ff5d68}
  }
}

.tip{
  position: absolute;
  width: 540px;padding: 30px 40px 10px;
  background: #fff;border-radius: 10px;
  left: 50%;top: 50%;transform: translate(-50%,-50%);
  box-shadow: 0 0 6px 2px rgba(200,200,200,.3);
  animation: dimFade .3s;
  z-index: 3;
  h5{
    font-size: 28px;
    color: #333;
    line-height: 2;
  }
  h6{
    text-align: justify;
    font-size: 24px;
  }
  .close{
    width: 100px;
    color: #42b2fc;
    height:40px;
    display: flex;
    align-items: center; 
    justify-content: center;
    margin: 20px auto 10px;
    border-radius: 6px;
  }
}

@keyframes width {
  0%{width: 0;}
}
@keyframes fade {
  0%{opacity: 0;}
  100%{opacity: 1;}
}
</style>
