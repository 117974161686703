<template>
  <!--报告-->
  <!-- <component :is="body" :version="version" :report_id="report_id"></component> -->
  <tier_1_0 v-if="version=='1.0.0'" :version="version" :report_id="report_id"></tier_1_0>
  <tier_2_0 v-else-if="version=='2.0.0'" :version="version" :report_id="report_id"></tier_2_0>
  <tier_2_1 v-else :version="version" :report_id="report_id"></tier_2_1>
</template>

<script>
import tier_1_0 from './tier1.0.vue'
import tier_2_0 from './tier2.0.vue'
import tier_2_1 from './tier2.1.vue'
export default {
  components:{tier_1_0, tier_2_0, tier_2_1},
  data(){
    return{
      report_id: this.$route.params.report_id || '',
      body: null,
      version: '',
    }
  },
  mounted(){
    // this.body = tier_2_1; return;
    this.$http.get(this.spaceUrl+`/Assess/getTeamReportVersion?team_report_id=${this.report_id}`).then(({data}) => {
      if (data.code == 0) {
        this.version = data.data.version
      } else {
        this.$toast({message:'无法加载',duration: 2000})
        console.error(data.msg)
      }
    })
  },
}
</script>
