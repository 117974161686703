<template>
  <div class="amtReport" id="amt" :class="{short:leadership.length==1||leadership_score.length==0}">
    <img src="../../../assets/images/report/AMT_report_top.jpg" class="earth">
    <div class="page">
      <span class="line line1"></span>
      <span class="line line2"></span>
      <span class="line line3"></span>
      <span class="line line4"></span>
      <span class="line line5"></span>
      <span class="line line6"></span>
      <span class="line line7"></span>
      <span class="line line8"></span>
      <img src="../../../assets/images/report/AMT_report_title.png" class="title">
      <div class="message">
        <img :src="avatar">
        <div class="name">{{name}}</div>
        <i style="flex:1"></i>
        <div>{{endTime||''}}</div>
      </div>
      <div class="star-box">
        <b>领导思维清晰度:</b>
        <i v-for="item in 5" :key="item" :class="{
          star2: item <= star,
          star3: star < item && star > item-1
        }"></i>
      </div>
      <div class="word" v-for="(item,i) in leadership" :key="'i'+i">
        <h1>第{{text[i]}}顺位：{{item.leadership}}</h1>
        <h2>你的画像：</h2>
        <p v-for="(it,j) in item.description" :key="'j'+j" v-html="it"></p>
        <template v-if="item.feature">
          <h2>你的特征：</h2>
          <p v-for="(it,j) in item.feature" :key="j" v-html="it"></p>
          <h2>你的关注：</h2>
          <div class="follow" :class="'follow'+item.type">
            <div class="box">
              <div class="center">
                <p v-for="(it,j) in item.attention[0]" :key="j" v-html="it"></p>
              </div>
            </div>
            <div class="row"><b>多于</b></div>
            <div class="box">
              <div class="center">
                <p v-for="(it,j) in item.attention[1]" :key="j" v-html="it"></p>
              </div>
            </div>
          </div>
        </template>
      </div>
      <!-- <div class="bottom">切换风格 ></div> -->
    </div>
    <svg id="AMT_think" v-show="leadership_score.length">
      <g class="bg"></g>
      <g class="data"></g>
    </svg>
    <div class="explain" v-if="leadership_score.length">
      <h5>说明：</h5>
      <h6>1【领导思维】的类型：</h6>
      <p>研究表明，领导思维主要有六种，且在领导者身上同时存在，但在具体的工作场景中，领导者惯用的领导思维只有1~2种，其他思维并不明显；</p>
      <p>领导思维类型：<br>E型领导（企业家思维）<br>O型领导（运营思维）<br>M型领导（管理思维）<br>P型领导（专家思维）<br>F型领导（前线思维）<br>S型领导（战略思维）</p>
      <h6 class="dashed">2【AMT领导者画像】的意义：</h6>
      <p>帮助你了解当下工作场景中，惯用的领导思维；</p>
      <p>这些惯用的思维将决定一个人的领导行为、沟通方式，并影响互动关系与管理效率；</p>
      <p>哪一种领导思维并不重要，最重要的是，你要知道哪一种思维最能让你发挥出最佳的领导力水平。</p>
      <h6 class="dashed">3【AMT领导者画像】的应用：</h6>
      <p>自我觉察：<br>分析自己当下的角色定位与思维的匹配度;<br>找出改善空间，提升领导力。</p>
      <p>觉察他人：<br>理解他人与自己的思维差异；<br>换位思考，调整沟通方式；<br>提升核心管理团队之间的合作效率。</p>
    </div>
    <p class="copyright">copyright by @十方实验室</p>
  </div>
</template>
<script>
import * as d3 from 'd3'
export default {
  props:{
    exam_id: {default: ''},
    version: {default: ''}
  },
  data(){
    return{
      hy_session: this.$route.query.hy_session || '',
      template_id: '',                    //10:旧版,11:新版
      name: "正在加载",                    //用户姓名
      avatar: require('../../../assets/images/icons/user_icon.png'),
      endTime: 0,                         //报告完成时间
      star: 0,                            //星级
      leadership: [],                     //
      leadership_score: [],               //折线图的分数

      text: ['一','二','三',],
      reportImg:'',
      reportImgShow: false,
    }
  },
  created(){
    this.$indicator.open({text: '加载中...',spinnerType: 'fading-circle'});
    this.$http.get(this.spaceUrl+'/Amt/getExamReportInfo',{
      params:{exam_id: this.exam_id}
    }).then((e) => {
      if (e.data.code == 0) {
        let user_info = e.data.data.user_info
        this.template_id = user_info.template_id
        this.avatar = user_info.avatar
        this.name = user_info.realname || user_info.nickname
        this.endTime = this.$formatTime(user_info.end_time,'y-m-d h:m')
        this.star = e.data.data.definition
        this.leadership = e.data.data.leader_ship
        this.leadership_score =  e.data.data.all_leader_ship || []
        this.draw()
      } else {
        this.name = '无法加载'
        this.$toast({message: e.data.msg, duration: 2000})
      }
      this.$indicator.close();
    }).catch((err) => {
      console.error(err)
      this.name = '加载失败'
      this.$toast({message: '网络错误', duration: 1500})
      this.$indicator.close();
    })
  },
  mounted(){
  },
  methods:{
    save(){
      // if (this.reportImg) {
      //   this.reportImgShow = true
      // } else {
      //   this.$http.get(this.spaceUrl+'/Amt/getReportImage?hy_session='+this.hy_session+'&exam_id='+this.exam_id).then((e) => {
      //     if (e.data.code==0) {//已保存了长图
      //       this.reportImg = e.data.data
      //       this.reportImgShow = true
      //     } else {//新生成长图
      //       var url = location.href.replace(/http:\/\/localhost/,this.mobileUrl) + (location.hash.includes('?')?'&':'?') + 'long'
      //       this.$http.post(this.ajaxUrlPath+'/CardImage/create',{//此接口地址不一样
      //         type: 'AMTReport',
      //         imageurl: url,
      //         width: '600',
      //         height: '1000',
      //       }).then( e => {
      //         if (!e.data.code) {
      //           this.$indicator.open({text: '图片生成中...',spinnerType: 'triple-bounce'});
      //           let timer = setInterval(()=>{
      //             this.$http.post(this.ajaxUrlPath+'/CardImage/getreport',{
      //               type: 'AMTReport',//与上保持一致
      //               messageId: e.data.data.messageId,
      //             }).then(e=>{
      //               if (!e.data.code) {
      //                 this.$indicator.close();
      //                 this.reportImg = e.data.data.image;
      //                 this.reportImgShow = true
      //                 clearInterval(timer);
      //                 //保存当前报告的图片
      //                 this.$http.post(this.spaceUrl+'/Amt/updateReportImage?hy_session=' + this.hy_session,{
      //                   exam_id: this.exam_id,
      //                   report_image: this.reportImg,
      //                 })
      //               }
      //             })
      //           },2500)
      //         }
      //       })
      //     }
      //   })
      // }
    },
    draw(){
      /***** 背景 *****/
      var svg = d3.select('#AMT_think'),
          width = document.getElementById('amt').clientWidth * 690 / 750,
          height = width,
          left = width * .25,
          pieH = height / 6,
          textStartX = left / 2 + 2,
          startY = pieH / 2,
          bg = svg.select('.bg'),
          min = 5,
          max = 0,
          len = this.leadership_score.length
      svg.attr('width', width).attr('height', height)
      for (let i = 0; i < len; i++) {
        let y = startY + i * pieH, item = this.leadership_score[i]
        bg.append('text')
          .attr('fill', '#eab749')
          .attr('x', textStartX)
          .attr('y', y - 3)
          .append('tspan').text(item.leadership).attr('x', textStartX)
          .append('tspan').text(`(${item.description})`).attr('x', textStartX).attr('dy', '1.2em')
        bg.append('line')
          .attr('stroke-width', 1)
          .attr('stroke', '#d9d9d9')
          .attr("stroke-dasharray", "4,2")
          .attr('x1', left)
          .attr('y1', y)
          .attr('x2', width-1)
          .attr('y2', y)
        if (item.score < min) min = item.score;
        if (item.score > max) max = item.score;
      }
      /***** 数据 *****/
      let minScale = min-.5 < 1 ? 1 : min-.5
      let maxScale = max+.5 > 5 ? 5 : max+.5
      var data = svg.select('.data'), 
          scale = maxScale - minScale,//跨度
          dataWidth = width - left,   //数据区间宽度
          point = '';                 //折线坐标点
      for (let i = 0; i < len; i++) {
        let item = this.leadership_score[i],
            x = (item.score - minScale) / scale * dataWidth + left,
            y = startY + i * pieH
        data.append('circle')
            .attr('cx', x)
            .attr('cy', y)
            .attr('r', () => {
              if (item.bold) return 8
              else return 4
            })
            .attr('fill', '#eab749');
        point += `${x},${y} `
      }
      data.append('polyline')
          .attr('fill', 'none')
          .attr('stroke', '#eab749')
          .attr('stroke-width', 1)
          .attr('points', point)
    },
  },
}
</script>
<style lang="scss">
.amtReport{
  position: relative;
  padding: 1px 0 162px;
  overflow-y: auto;
  min-height: 100%;
  box-sizing: border-box;
  background: #020e29 url('https://m.huayanspace.com/static/img/AMT_report_bg.png');
  background-size: 200px auto;
  user-select: none;
  .earth{
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 1;
  }
  .page{
    // background: url('https://m.huayanspace.com/static/img/AMT_report_border.png') no-repeat;
    // background-size: 100% 100%;
    // border: 1px solid rgba(255,78,78,.5);
    min-height: 800px;
    width: 690px;
    padding: 30px 0 100px;
    margin: 60px auto 40px;
    position: relative;
    z-index: 2;
    .title{
      width: 70%;
      margin-right: 5px;
    }
    .message{
      display: flex;
      align-items: center;
      font-size: 26px;
      color: #fff;
      margin: 20px 60px;
      img{
        height: 68px;
        width: 68px;
        border-radius: 50%;
        overflow: hidden;
        margin-right: 20px;
      }
      .name{
        width: 250px;
        overflow: hidden;
        text-overflow:ellipsis;
        white-space: nowrap;
        text-align: left;
      }
    }
    .star-box{
      margin: 30px 0 20px;
      b{
        font-size: 28px;
        color: #eab749;
        line-height: 40px;
        display: inline-block;
        height: 40px;
      }
      i{
        display: inline-block;
        width: 36px;
        height: 36px;
        margin: 0 8px -6px;
        background-image: url('../../../assets/images/report/star1.png');
        background-repeat: no-repeat;
        background-size: 100%;
      }
      .star2{background-image: url('../../../assets/images/report/star2.png')}
      .star3{background-image: url('../../../assets/images/report/star3.png')}
    }
    .word{
      h1{
        font-size: 30px;
        color: #fff;
        line-height: 40px;
        margin: 80px 50px 0;
        font-weight: bold;
        text-align: left;
        color: #eab749;
      }
      h2{
        color: #eab749;
        text-align: left;
        font-size: 26px;
        margin: 30px 50px 20px;
      }
      p{
        margin: 0 80px;
        color: #fff;
        font-size: 26px;
        position: relative;
        text-align: justify;
        &::before{
          content: '';
          position: absolute;
          width: 10px;
          height: 10px;
          border-radius: 50%;
          left: -24px;
          top: 12px;
          background: #eab749;
        }
      }
      .follow{
        display: flex;
        margin: 0 50px 40px;
        .box{
          border: 1px solid #fff;
          width: 256px;
          height: 360px;
          box-sizing: border-box;
          color: #fff;
          position: relative;
          .center{
            position: absolute;
            left: 0;
            width: 100%;
            top: 50%;
            transform: translateY(-50%);
          }
          p{margin: 0 4px 0 36px;}
        }
        .row{
          width: 80px;
          position: relative;
          b{display: none}
        }
      }
      .follow1 .row{
        b{
          position: absolute;
          width: 100%;
          left: 0;
          top: 58%;
          display: block;
          font-size: 20px;
          color: #fff;
        }
        &::before{
          content: '';
          position: absolute;
          width: 40px;
          height: 40px;
          left: 0;
          top: 0%;
          margin-top: 170%;
          transform: rotate(-45deg);
          border-bottom: 6px solid #fff;
          border-right: 6px solid #fff;
          border-top: 6px solid transparent;
          border-left: 6px solid transparent;
        }
      }
      .follow0 {
        .box{width: 276px}
        .box:nth-child(1){
          border-right: none;
        }
        .box:nth-child(3){
          border-left: none;
        }
        .row{width: 40px}
        .row::before{
          content: '';
          position: absolute;
          z-index: 2;
          width: 44px;
          height: 100%;
          left: -2px;
          top: 0;
          border-top: 1px solid #fff;
          border-bottom: 1px solid #fff;
          box-sizing: border-box;
        }
      }
    }
    .bottom{
      position: absolute;
      width: 160px;
      color: #fff;
      left: 50%;
      bottom: -70px;
      transform: translateX(-50%);
      font-size: 28px;
      line-height: 50px;
    }
  }
  #AMT_think{
    font-size: 26px;
    text{
      text-anchor: middle;
      dominant-baseline: middle;
    }
  }
  .explain{
    padding: 40px;
    font-size: 26px;
    background: rgba(5,32,88,.7);
    text-align: left;
    width: 680px;
    margin: 50px auto 0;
    line-height: 1.4;
    h5{
      color: #eab749;
    }
    h6{
      color: #eab749;
      margin-top: 30px;
    }
    p{
      color: #fff;
      padding-left: 26px;
      position: relative;
      text-align: justify;
      &::before{
        content: '';
        position: absolute;
        left: 2px;
        top: 10px;
        width: 12px;
        height: 12px;
        border-radius: 50%;
        background: #fff;
      }
    }
    .dashed{
      padding-top: 30px;
      border-top: 1px dashed #fff;
    }
  }
  .copyright{
    font-size: 22px;
    color: rgba(255,255,255,.5);
    padding: 110px 0 20px;
    background: url('https://m.huayanspace.com/static/img/AMT_report_bottom.jpg') no-repeat;
    background-size: 100% 100%;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%
  }

  .line{
    position: absolute;
    z-index: 2;
    display: block;
    border-color: #917a41;
    border-width: 4px;
  }
  .line1{
    border-top-style: solid;
    width: 80px;
    left: 20px;
    top: 60px;
  }
  .line2{
    border-top-style: dashed;
    width: 80px;
    right: 20px;
    top: 60px;
  }
  .line3{
    border-left-style: solid;
    height: 34%;
    left: 20px;
    top: 64px;
  }
  .line4{
    border-left-style: solid;
    height: 64%;
    left: 20px;
    bottom: 60px;
    // background-image: linear-gradient(to bottom, #917a41 0%, #917a41 0%, 10px,transparent 10px, transparent);
    // background-size: 100% 20px;
    // width: 4px;
  }
  .line5{
    border-right-style: solid;
    height: 54%;
    right: 20px;
    top: 64px;
  }
  .line6{
    border-right-style: solid;
    height: 42%;
    right: 20px;
    bottom: 60px;
  }
  .line7{
    border-bottom-style: dashed;
    width: 180px;
    left: 20px;
    bottom: 60px;
  }
  .line8{
    border-bottom-style: solid;
    width: 210px;
    right: 20px;
    bottom: 60px;
  }
}

@media (min-height: 800px) {
  .short{padding-top: 150px}
}
</style>