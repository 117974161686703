<template>
  <!--报告-->
  <report_1_0 v-if="version=='1.0.0'" :exam_id="exam_id"/>
  <report_1_1 v-else-if="version=='1.1.0'" :exam_id="exam_id"/>
  <report_1_2 v-else-if="version=='1.2.0'" :exam_id="exam_id"/>
  <report_1_3 v-else-if="version=='1.3.0'" :exam_id="exam_id"/>
  <report_1_4 v-else-if="version=='1.4.0'" :exam_id="exam_id"/>
</template>

<script>
import report_1_0 from './report1.0.vue'
import report_1_1 from './report1.1.vue'
import report_1_2 from './report1.2.vue'
import report_1_3 from './report1.3.vue'
import report_1_4 from './report1.4.vue'
export default {
  components:{report_1_0, report_1_1, report_1_2, report_1_3, report_1_4},
  data(){
    return{
      exam_id: this.$route.query.exam_id || '',
      version: '1.4.0',
      //1.3.0 -> 3762
      //1.4.0 -> 3821
    }
  },
  mounted(){
    this.$http.get(this.ajaxUrlPath+`/TopSale/getReportVersion?exam_id=${this.exam_id}`).then(({data}) => {
      if (data.code == 0) {
        this.version = data.data.version
      } else {
        this.$toast({message:'无法加载',duration: 2000})
        console.error(data.msg)
      }
    })
  },
}
</script>