<template>
  <svg class="same">
    <g class="bg"></g>
  </svg>
</template>

<script>
import * as d3 from 'd3'
export default {
  props: {
    data: {
      type: Array
    },
    width: {    //宽高度
      type: Number,
    },
    animate:{
      type:Boolean,
      default:false,
    },
  },
  data(){
    return{
      color: ['#fac3c9','#fee7bb','#ffcca1','#b0cff2'],
      borderColor: ['#f37785','#fbc24c','#ff851f','#3780d2'],
      textColor:['#dd9317','#1352c4','#c11e26','#ce0b67','#99178d','#316a88','#1e9381','#34a560','#7aa022','#963937'],
      text: ['倾听交流','同理共情','人际影响','灵活应变','有序行动','保证成果'],
    }
  },
  mounted(){
    if(this.data.length>0){
      this.draw(this.data)
      return
    }
    this.$watch('data', (val) => {
      this.draw(val)
    })
  },
  methods:{
    setCurve(val,i){
      let size = this.size,
          x1 = size.startX + size.eachW*i,  //起点坐标
          x2 = x1 + size.eachW,             //终点坐标
          center = (x2 + x1) / 2,           //中点(最高点)x轴坐标
          m = size.eachW * .25,             //控制顶端圆润（+：圆，-：尖）
          n = size.eachW * .32,             //控制腰宽（+：细，-：粗）
          h;                                //山峰的高度
      let v1 = .8,    //低
          v2 = .3,    //中等
          v3 = .15    //中上
          
      if (val > v1) { //低
        h = val>=2.8  ?  size.eachH/10  :  (2.8-val)/(2.8-v1)*size.eachH
      } else if (val > v2) {  //中等
        h = size.eachH + (v1-val)/(v1-v2)*size.eachH
      } else if (val > v3) {  //中上
        h = size.eachH*2 + (v2-val)/(v2-v3)*size.eachH
      } else {  //高
        h = size.eachH*3 + (v3-val)/(v3-0)*size.eachH
      }     
      let my = size.startY - h              //每个山峰的顶点
      let ny = my + h/2
      return {
        points: [center, my],
        d: `M${x1},${size.startY} C${x1+n},${ny} ${center-m},${my} ${center},${my} C${center+m},${my} ${x2-n},${ny} ${x2},${size.startY}`,
      }
    },
    draw(e){
      let svg = d3.select(this.$el).attr('width', this.width).attr('height', this.width)
      let gap =  this.width * 0.1,
          top = this.width * 0.16,
          bottom = this.width * 0.3,//与move动画的起始点对应
          len = e.length,
          fontSize = this.width * 0.035,
          vW = this.width - 2*gap,
          vH = this.width - top - bottom,
          level = ['高','中上','中等','低'],
          eachW = vW / len, 
          eachH = vH / level.length,
          bg = svg.select('.bg')
      bg.append('polyline')
        .attr('fill', 'none')
        .attr('stroke', '#a5a5a5')
        .attr('stroke-width',1)
        .attr('points', `${gap},${this.width-bottom} ${this.width-gap},${this.width-bottom}`)
      bg.append('text')//维度
        .attr('font-size', fontSize)
        .attr('fill', '#666')
        .attr('x', this.width - gap/2)
        .attr('y',  this.width - bottom)
        .text('维度')
      bg.append('text')//共识度
        .attr('font-size', fontSize)
        .attr('fill', '#666')
        .attr('x', gap)
        .attr('y', top-12)
        .text('共识度')
      for(let i = 0; i < level.length; i++){
        bg.append('text')
          .classed('i', true)
          .attr('font-size', fontSize)
          .attr('fill', '#999')
          .attr('x', gap/2)
          .attr('y', eachH * ( i + .5) + top)
          .text(level[i])
        bg.append('polyline')
          .attr('fill', 'none')
          .attr('stroke', '#e0e0e0')
          .attr("stroke-dasharray", "3,3")
          .attr('points', ()=>{
            let y = eachH * i + top
            return `${gap+1},${y} ${gap+vW},${y}`
          })
      }
      for(let i = 0; i < len; i++){
        let text = e[i].name.split(''),
            x = eachW * ( i + .5) + gap
        bg.append('text')
          .classed('i',true)
          .attr('font-size', fontSize)
          .attr('fill', this.textColor[i])
          .attr('y',  this.width - bottom + 18)
          .append('tspan')
          .attr('x', x)
          .text(text[0])
          .append('tspan')
          .attr('x', x)
          .attr('dy', '1.2em')
          .text(text[1])
          .append('tspan')
          .attr('x', x)
          .attr('dy', '1.2em')
          .text(text[2])
          .append('tspan')
          .attr('x', x)
          .attr('dy', '1.2em')
          .text(text[3])
        bg.append('polyline')
          .attr('fill', 'none')
          .attr('stroke', '#e0e0e0')
          .attr("stroke-dasharray", "3,3")
          .attr('points', ()=>{
            let y = eachH * i + top
            return `${x},${top-2} ${x},${this.width-bottom}`
          })
      }
      this.size = {
        vH: vH,
        eachW: eachW,
        eachH: eachH,
        startX: gap,
        startY: this.width - bottom
      }
      
      //开始绘制
      let data = d3.select(this.$el).append('g'), 
          str = '',
          max = 0,
          min = 1000,
          pointsArr = []
      for (let i in e) {
        let res = this.setCurve(e[i].dispersion, i)
        pointsArr.push([res.points[0],res.points[1]])
        str += `${res.points[0]},${res.points[1]} `
        if (e[i].dispersion > max) max = e[i].dispersion
        if (e[i].dispersion < min) min = e[i].dispersion
      }
      data.append('polyline')
          .attr('fill', 'none')
          .attr('stroke', '#e5b552')
          .attr('stroke-width',2)
          .attr('points', str)
      for (let i in pointsArr) {
        data.append('rect')
            .attr('fill', ()=>{
              // if (e[i].dispersion == max)
              //     return '#83b166'
              // else if (e[i].dispersion == min)
              //   return '#e86a50'
              // else
                return '#e5b552'
            })
            .attr('stroke', 'none')
            .attr('x', pointsArr[i][0] - 5)
            .attr('y', pointsArr[i][1] - 2)
            .attr('width', 10)
            .attr('height', 4)
      }
      //添加动画
      // if (this.animate) {
      //   data.classed('move',true)
      // }
    },
  },
}
</script>
<style scope lang="scss">
.same{
  display: block;
  margin: 100px auto 0;
  text{
    text-anchor: middle;
    dominant-baseline: middle;
  }
  tspan{
    text-anchor: middle;
    font-style: italic;
  }
  .i{
    font-style: italic;
  }
  // .move{
  //   stroke-dashoffset: 150%;
  //   animation: moveP .6s forwards linear;
  // }
  // @keyframes moveP {
  //   100%{stroke-dashoffset:50%;}
  // }
}
</style>
