<template>
  <div class="wave-ball" :style="{width:width+'px',height:width+'px','border-color':type==3?'':color[type]}">
    <div class="bg" :style="{background:color[type]}"></div>
    <svg>
      <path :fill="'url(#wave'+type+')'" :d="d1" fill-opacity="0.3">
        <animate attributeType="XML" dur="2.2s" repeatCount="indefinite" attributeName="d" :values="value1"></animate>
      </path>
      <path :fill="'url(#wave'+type+')'" :d="d2" fill-opacity="0.65">
        <animate attributeType="XML" dur="2.6s" repeatCount="indefinite" attributeName="d" :values="value2"></animate>
      </path>
      <text :x='textX' fill-opacity="0.8" :y='textY' :fill="color[type]" :font-size='fontSize'>{{name}}</text>
      <defs>
        <linearGradient :id="'wave'+type" x1="0%" y1="50%" x2="100%" y2="50%">
          <stop offset="0%" :style="'stop-color:'+color[type]+';stop-opacity:.4'"/>
          <stop offset="100%" :style="'stop-color:'+color[type]+';stop-opacity:1'"/>
        </linearGradient>
      </defs>
    </svg>
  </div>
</template>

<script>
import * as d3 from 'd3'
export default {
  props: {
    width: {    //宽高度
      type: Number,
    },
    type:{
      type: Number,
    },
    name:{
      type: String,
    },
  },
  data(){
    return{
      color: ['#83bd5e','#3ab1fb','#f5bc41','#1116e1'],
      d1: 'M0,0 0,0',
      value1: '',
      d2: 'M0,0 0,0',
      value2: '',
      textX: 0,
      textY: 0,
      fontSize:0,
    }
  },
  mounted(){
    if(this.name){
      if(this.width) this.draw();
      else this.$watch('width', () => this.draw());
    }else{
      this.$watch('name', () => this.draw())
    }
  },
  methods:{
    draw(){
      let center = this.textX = this.width / 2,
          moveY = this.textY = center * .6,
          cenTopY = center - moveY,
          cenBotY = center + moveY,
          // 第一条曲线(浅)
            rand1 = Math.floor( (moveY+1) * Math.random() ) + cenTopY,
            d1 = `M0 ${center} C${center-moveY} ${rand1},${center+moveY} ${2*center-rand1},${this.width} ${center} A${center} ${center} 0 0 1 0 ${center}Z`,
            value1 = d1 + `;M0 ${center} C${center-moveY} ${cenTopY},${center+moveY} ${cenBotY},${this.width} ${center} A${center} ${center} 0 0 1 0 ${center}Z;M0 ${center} C${center-moveY} ${cenBotY} ${center+moveY} ${cenTopY} ${this.width} ${center} A${center} ${center} 0 0 1 0 ${center}Z;` + d1,
          // 第二条曲线(深)
            rand2 = Math.floor( (2*moveY+1) * Math.random() ) + cenTopY;
            let d2 = `M0 ${center} C${center} ${rand2},${center} ${2*center-rand2},${this.width} ${center} A${center} ${center} 0 0 1 0 ${center} Z`,
                sub = center - rand2,
                value2;
            if (Math.abs(sub) > center*.1) {
              if(sub >= 0){  //先在上
                value2 = d2 + `;M0 ${center} C${center} ${cenBotY},${center} ${cenTopY},${this.width} ${center} A${center} ${center} 0 0 1 0 ${center} Z;` + d2;
              }else{  //先在下
                value2 = d2 + `;M0 ${center} C${center} ${cenTopY},${center} ${cenBotY},${this.width} ${center} A${center} ${center} 0 0 1 0 ${center} Z;` + d2;
              }
            } else {
              value2 = d2 + `;M0 ${center} C${center} ${cenBotY},${center} ${cenTopY},${this.width} ${center} A${center} ${center} 0 0 1 0 ${center} Z;M0 ${center} C${center} ${cenTopY},${center} ${cenBotY},${this.width} ${center} A${center} ${center} 0 0 1 0 ${center} Z;` + d2;
            }
      this.d1 = d1
      this.d2 = d2
      this.value1 = value1
      this.value2 = value2
      this.fontSize = center * .31
    },
  },
}
</script>
<style lang="scss">
.wave-ball{
  border-radius: 50%;
  border: 1px solid transparent;
  overflow: hidden;
  position: relative;
  display: inline-block;
  margin: 0 20px;
  .bg{
    opacity: 0.15;
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1;
  }
  svg{
    width: 100%;
    height: 100%;
    position: relative;
    z-index: 2;
  }
  text{
    text-anchor: middle;
    dominant-baseline: middle;
  }
}
</style>
