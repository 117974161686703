<template>
  <div class="car">
    <ul>
      <div class="top" v-if="topshow"/>
      <li class="flex--c" v-for="(item,i) in carList" :key="i">
        <div class="radio" :class="{picked:item.pick}" @click="select(i)"/>
        <div class="pic flex-c-c" :class="'pic'+item.module_id"><img :src="require('../../../../../static/img/tda_'+item.module_id+'.png')"></div>
        <div class="content">
          <h3>{{item.product_name}}</h3>
          <div class="flex-b-c">
            <h4>¥ <b>{{item.price}}</b> <s>￥19.9</s></h4>
            <div class="inputBox flex-c" @click.stop>
              <span @click="input(i,-1)">-</span>
              <input maxlength="3" @input="input(i)" @blur="blur(i)" v-model="item.number"/>
              <span @click="input(i,1)">+</span>
            </div>
          </div>
        </div>
      </li>
      <!-- 空数据 -->
      <div v-if="carList.length==0" class="null">
        <img src="../../../../../static/img/TDA13.png">购物车空空如也
      </div>
    </ul>
    <div class="pay flex--c" v-if="carList.length">
      <label class="flex--c" @click="allPick"><span class="radio" :class="{picked:pickNum==carList.length}"/>全选</label>
      <h5>已选{{pickNum}}个模块，总计 <span>¥<b>{{totalPrice}}</b></span></h5>
      <i style="flex:1"/>
      <h6 v-if="totalPrice" @click="toPay">去结算</h6>
      <h6 v-else style="opacity:.6">去结算</h6>
    </div>
    
    <div class="mask" v-if="delIndex!==''">
      <div class="confirmBox">
        <div class="t1">提示</div>
        <div class="t2">确定从购物车中删除此项测试？</div>
        <div class="btn flex">
          <label @click="delIndex=''">取消</label>
          <label @click="del">确定删除</label>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: { topshow: {default: 1} },
  data() {
    return {
      user: {},
      carList: [],
      delIndex: '',
    }
  },
  created() { //获取登录信息
    this.getLocal()
  },
  methods: {
    getLocal() {
      let user = JSON.parse(localStorage.getItem("user"))
      if (user) {
        this.user = user
        this.carList = JSON.parse(localStorage.getItem("car_"+this.user.user_id)||'[]')
      }
    },
    select(i) {
      this.$set(this.carList[i], 'pick', !this.carList[i].pick)
      this.loaclSave()
    },
    allPick() {
      if (this.pickNum == this.carList.length) {
        for (let i in this.carList) this.$set(this.carList[i], 'pick', false)
      } else {
        for (let i in this.carList) this.$set(this.carList[i], 'pick', true)
      }
      this.loaclSave()
    },
    input(i, n) {
      if (n) {  //+-数
        let res = (this.carList[i].number||0) + n
        if (res < 1) {
          this.delIndex = i
        } else if (res > 999) {
          this.$set(this.carList[i], 'number', 999)
        } else {
          this.$set(this.carList[i], 'number', res)
        }
      } else {  //输入数
        this.carList[i].number = Number(event.target.value.replace(/\D/g, '')) || ''
      }
      this.loaclSave()
    },
    blur(i) {
      if (!this.carList[i].number) {
        this.$set(this.carList[i], 'number', 1)
        this.loaclSave()
      }
    },
    del() {
      this.carList.splice(this.delIndex, 1)
      this.delIndex = ''
      this.loaclSave()
    },
    loaclSave() {
      localStorage.setItem("car_"+this.user.user_id, JSON.stringify(this.carList))
    },
    toPay() {
      if (this.user.mobile)
        this.$router.push({path: '/TDA/pay'})
      else
        this.$router.push({path: '/TDA/login', query: {link: 'bind'}})
    },
  },
  computed: {
    totalPrice() {
      let total = 0
      for (let item of this.carList) {
        if (item.pick) total+= item.price * item.number||0
      }
      return parseFloat(total.toFixed(2))
    },
    pickNum() {
      let num = 0
      for (let item of this.carList) 
        if (item.pick) num++
      return num
    },
  },
  mounted() {
    document.title = '人才发展评估'
    wx.ready(() => {
      wx.onMenuShareAppMessage({
        title : '人才发展评估',
        link : `${this.mobileUrl}/#/TDA/shop`,
        imgUrl : `${this.mobileUrl}/static/img/p-15.jpg`,
      })
    })
  },
}
</script>
<style lang="scss" scoped>
.car{
  background: #f1f5f7;
  height: 100%;
  position: relative;
  box-sizing: border-box;
  padding-bottom: 120px;
  user-select: none;
  ul{
    height: 100%;
    overflow-y: auto;
    .top{height: 24px;}
  }
  li{
    background: #fff;
    margin: 0 24px 24px;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 0 10px rgba(200,200,200,.1);
  }
  .pic{
    width: 180px;
    height: 160px;
    margin-left: 10px;
    img{
      width: 80px;
      height: 80px;
      border-radius: 50%;
      box-shadow: 0 4px 8px rgba(200,200,200,.1);
      background: linear-gradient(100deg, rgba(255,255,255,.5) 10%, rgba(200,200,200,0))
    }
  }
  .content{
    flex: 1;
    white-space: nowrap;
    margin-left: 30px;
    h3{
      font-size: 28px;
      color: #292929;
      margin: 20px 0;
      text-align: left;
    }
    h4{
      display: flex;
      align-items: baseline;
      font-size: 24px;
      color: #f81c1c;
      b{
        font-size: 28px;
        margin-left: 8px;
      }
      s{
        color: #b3b3b3;
        margin-left: 20px;
      }
    }
    .inputBox{
      border: 1px solid #dae0e5;
      border-radius: 6px;
      font-size: 28px;
      span{
        width: 48px;
        line-height: 48px;
        color: #b3b7bb;
      }
      input{
        width: 64px;
        height: 48px;
        color: #292929;
        text-align: center;
        font-weight: bold;
        border-left: 1px solid #dae0e5;
        border-right: 1px solid #dae0e5;
      }
    }
  }
  .pay{
    position: absolute;
    width: 100%;
    height: 120px;
    left: 0;
    bottom: 0;
    background: #fff;
    border-bottom: 1px solid #f1f5f7;
    box-sizing: border-box;
    padding: 0 24px 0 44px;
    label{
      font-size: 26px;
      color: #b3b3b3;
      padding: 24px 0;
    }
    h5{
      font-size: 24px;
      color: #292929;
      text-align: left;
      margin-left: 30px;
      span{color: #f81c1c;}
      b{font-size: 28px;}
    }
    h6{
      line-height: 70px;
      width: 180px;
      border-radius: 40px;
      background: #4987f9;
      color: #fff;
      font-size: 28px;
    }
  }
  .radio{
    width: 36px;
    height: 36px;
    border-radius: 50%;
    border: 1px solid #979797;
    margin-right: 10px;
  }
  .picked{
    background: #4987f9 url('https://m.huayanspace.com/static/img/gou.png') no-repeat;
    background-size: 100%;
    border-color: #4987f9;
  }
  .mask{
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    z-index: 100;
    background: rgba($color: #000, $alpha: .5);
    .confirmBox {
      position: fixed;
      left: 50%;
      top: 46%;
      transform: translate(-50%,-50%);
      border-radius: 10px;
      background: #fff;
      width: 500px;
      z-index: 100;
      font-size: 26px;
      color: #4d4d4d;
      overflow: hidden;
      .t1{padding-top: 20px}
      .t2{padding: 40px 30px 50px;color: #333;}
      .btn label:nth-child(1) {border-right: 1px solid #f5f5f5;}
      .btn label {
        line-height: 70px;
        flex: 1;
        box-sizing: border-box;
        border-top: 1px solid #f5f5f5;
      }
    }
  }
  .null{
    font-size: 24px;
    color: #ccc;
    line-height: 60px;
    img{
      display: block;
      width: 50%;
      margin: 300px auto 0;
      opacity: .6;
    }
  }
}

.pic1{background: linear-gradient(135deg, #abcfff 10%, #4889f8 80%)}
.pic2{background: linear-gradient(135deg, #8bf576 10%, #66c254 80%)}
.pic3{background: linear-gradient(135deg, #47e7ff 10%, #51b4f9 80%)}
.pic4{background: linear-gradient(135deg, #ffd37e 10%, #f0a735 80%)}
.pic5{background: linear-gradient(135deg, #939dff 10%, #5757f7 80%)}
.pic6{background: linear-gradient(135deg, #83d4ff 10%, #369dd3 80%)}
.pic7{background: linear-gradient(135deg, #63fffd 10%, #5bc4c3 80%)}
.pic8{background: linear-gradient(135deg, #ffb895 10%, #ec8756 80%)}
.pic9{background: linear-gradient(135deg, #c398ff 10%, #8c5fca 80%)}
</style>