<template>
  <svg class="bar-chart" :width="width" :height="width">
    <g class="bg"></g>
  </svg>
</template>

<script>
import * as d3 from 'd3'
export default {
  props: {
    data: {
      type: Array,
    },
    width: {    //宽高度
      type: Number,
    },
    animate:{
      type:Boolean,
      default:false,
    },
  },
  data(){
    return{
      svg: '',
      color: ['#f5e1ba','#cde0c2','#acc8fa','#f8cbad','#c2c9f9','#bdd7ee'],
      borderColor:['#e9bf67','#89bd69','#4684f4','#ef8b47','#7c8af3','#66a8e4'],
      textColor:['#dd9317','#1352c4','#c11e26','#ce0b67','#99178d','#316a88','#1e9381','#34a560','#7aa022','#963937'],
      size:'',
    }
  },
  mounted(){
    if(this.width){
      this.init()
      this.draw(this.data)
      return
    }
    this.$watch('width', () => {
      this.init()
    })
    this.$watch('data', (val) => {
      this.draw(val)
    })
  },
  methods:{
    init(){
      let svg = this.svg = d3.select(this.$el),
          gap =  this.width * .1,                 //左右两边留空（两边缝隙一样）
          top = this.width * .16,                 //顶部留空
          bottom = this.width * .3,               //底部留空
          fontSize = this.width * .04,            //字体大小
          vW = this.width - 2 * gap,              //坐标轴有效宽度（x轴范围）
          vH = this.width - top - bottom,         //坐标轴有效高度（y轴范围）
          eachW = vW / 6,                         //宽度分为6份
          eachH = vH / 5,                         //高度分为5份
          barW = eachW * .54,                     //矩形的宽度
          bg = this.bg =  svg.select('.bg');
      // 绘制xy轴线
      bg.append('polyline')                      //添加一个连续线段（xy折线）
        .attr('fill', 'none')                    //填充颜色
        .attr('stroke', '#b3b3b3')               //线段颜色
        .attr('stroke-width', 1)                 //线段宽度
        .attr('points', `${gap},${top} ${gap},${this.width-bottom} ${this.width-gap},${this.width-bottom}`)                    //线段的路径
      // 绘制 “维度”
      bg.append('text')                          //添加文本标签
        .attr('font-size', fontSize)             //字体大小
        .attr('fill', '#666')                    //填充颜色
        .attr('x', this.width - gap/2)           //x轴坐标
        .attr('y',  this.width - bottom)         //y轴坐标
        .text('维度')                            //文本内容
      // for(let i = 0; i < 6; i++){
      //   //左边刻度
      //   bg.append('text')
      //     .classed('i', true)
      //     .attr('font-size', fontSize)
      //     .attr('fill', '#999')
      //     .attr('x', gap/2)
      //     .attr('y', eachHH * i + top)
      //     .text(100 - i*20)
      // }
      this.size = {
        gap:gap,
        top:top,
        bottom:bottom,
        fontSize: fontSize,
        vH:vH,
        eachW:eachW,
        barW: barW,
        startX: gap + (eachW - barW)/2,
        startY: this.width - bottom
      }
    },
    draw(e){
      let data = this.svg.append('g'),
          text_g = this.svg.append('g'),
          size = this.size,
          point = [],
          curve ='',          //贝塞尔曲线
          score = [],         //各分数
          max = 0,
          min = 100,
          len = e.length;
      // 底部维度名称
      for (let i = 0; i < len; i++) {
        let text = e[i].name.split('')
        let cx = size.eachW * ( i + .5) + size.gap
        text_g.append('text')
              .classed('i',true)
              .attr('font-size', size.fontSize)
              .attr('fill', this.textColor[i])
              .attr('y',  this.width - size.bottom + 18)
              .append('tspan')
              .attr('x', cx)
              .text(text[0])
              .append('tspan')
              .attr('x', cx)
              .attr('dy', '1.2em')
              .text(text[1])
              .append('tspan')
              .attr('x', cx)
              .attr('dy', '1.2em')
              .text(text[2])
              .append('tspan')
              .attr('x', cx)
              .attr('dy', '1.2em')
              .text(text[3])
        if (e[i].score > max ) max = e[i].score;
        if (e[i].score < min) min = e[i].score;
        score.push(e[i].score)
      }
      // y轴刻度
      let numMin = String(min).split('')[1]<2 ? 1 : 0,
          sMin = Math.floor(min/10-numMin), //在十位的最低值
          sMax = Math.ceil(max/10),         //在十位的最高值
          eachH = size.vH / (sMax - sMin),
          j = 0;
      for(let i = sMax; i >= sMin; i--){
        this.bg.append('text')
                .classed('i', true)
                .attr('font-size', size.fontSize)
                .attr('fill', '#999')
                .attr('x', size.gap / 2)
                .attr('y', eachH * (j++) + size.top)
                .text(i*10)
      }

      // 绘制数据
      max = sMax * 10
      min = sMin * 10
      let scale = max - min
      for(let i = 0; i < len; i++){
        let height = (e[i].score-min) / scale * size.vH,
            x = size.gap + size.eachW/2 + size.eachW*i,
            y = size.startY - height;
        //矩形条
        data.append('rect')
            .attr('fill', this.color[i])
            .attr('stroke', this.borderColor[i])
            .attr('x', size.startX + size.eachW * i)
            .attr('y', y)
            .attr('width', size.barW)
            .attr('height', height)
            .attr('stroke-width', .5)
        //各坐标圆点
        data.append('circle')
            .attr('cx', x)
            .attr('cy', y)
            .attr('r', 2)
            .attr('stroke', 'none')
            .attr('fill', '#f65f54');
        data.append('text')
            .attr('x', x)
            .attr('y', y - 10)
            .attr('font-size', size.fontSize)
            .attr('fill', this.borderColor[i])
            .text(e[i].score)
      }

      // 曲线配置
      let xScale = d3.scaleLinear().domain([0, len-1]).range([
                    size.gap + size.eachW/2,
                    this.width - size.gap - size.eachW/2
                  ]);
      let yScale = d3.scaleLinear().domain([min, max]).range([
                    size.vH + size.top - 1,
                    size.top - 1
                  ]);
      let d = d3.line()
                .curve(d3.curveCatmullRom)
                .x( (e,i) => { return xScale(i) })
                .y( e => { return yScale(e) });
      let line = data.append('g').datum(score)
      line.append('path')
          .attr('stroke', '#f65f54')
          .attr('fill', 'none')
          .attr('d', d)
          
      // 添加动画
      if(this.animate){
        data.classed('move',true)
      }
    },
    // setCurve(score,i){
    //   let size = this.size,
    //       x1 = size.startX + size.eachW*i,  //起点坐标
    //       x2 = x1 + size.eachW,             //终点坐标
    //       center = (x2 + x1) / 2,           //中点坐标
    //       m = size.eachW/2 * .48,           //控制点（调节弯曲度）
    //       y = size.startY - score/100*size.vH;//最高点
    //   return `M${x1},${size.startY} Q${x1+m},${y} ${center},${y} Q${x2-m},${y} ${x2},${size.startY}`
    // },
  },
}
</script>
<style lang="scss">
.bar-chart{
  display: block;
  margin: 0 auto;
  text{
    text-anchor: middle;
    dominant-baseline: middle;
  }
  .i{
    font-style: italic;
  }
  .move{
    transform-origin: center 70%;
    animation: scaleY .6s;
  }
  @keyframes scaleY {
    0%{transform: scaleY(0);}
    100%{transform: scaleY(1);}
  }
}
</style>
