<template>
  <div class="tvsIndex" :class="{fix:riseIndex==10}">
    <img src="../../../static/img/team10.jpg">

    <h1>评估概述</h1>
    <p>“组织活力扫描”旨在帮助企业管理者深入了解组织的健康和活力水平，为其提供客观、真实的数据洞察，便于制定战略和有效决策，帮助组织实现高效、灵活及可持续的组织发展。</p>

    <h1>主要功能</h1>
    <p>1、提供可视化的数据结果：从多个层次和视角提供关键数据指标，比如团队的凝聚力、协作力、目标感、价值感等；管理层的领导能量、激励沟通、突破创新、推动执行等，全面、客观地反映组织的状态，通过数据驱动管理者做出更明智、更有效的战略规划和决策。</p>
    <p>2、挖掘数据背后的原因：结合数据结果及冰山思考模型，引领核心管理人员将组织看成统一的整体和系统，并积极探寻现状背后的心智模式、认知、理念、机制、战略等原因，并寻找有效改善杠杆点。</p>
    <p>3、共创个性化解决方案：在组织的特定需求和战略目标的基础上，结合数据结果，激发团队成员的集体智慧，共同制定针对性的解决方案。</p> 

    <h1>应用场景</h1>
    <p>1、企业文化建设：通过对组织深入剖析，了解组织的愿景、使命、价值观、文化，从而指导组织文化的塑造和推进。</p> 
    <p>2、团队活力建设：组织活力扫描可以反馈团队的活力水平、团队原动力，包含凝聚力、目标感、价值感、协作力、承担力等方面，从而为团队活力建设提供指引和依据。</p> 
    <p>3、领导力发展：作为领导力发展的工具之一，组织活力扫描可以帮助领导者了解其领导团队的表现和潜在问题，为领导力发展计划提供定向指导。</p> 
    <p>4、组织动能发展：通过组织活力扫描，从组织的资源、技能、知识、文化和领导力等方面入手，激发组织内部的能量和动力，适应快速变化的市场和环境，促进组织的高效运作。</p> 
    <p>5、组织规划深入：在组织实现战略目标的过程中，会涉及到明确组织的长期愿景、使命和价值观，通过组织活力扫描，可以及时了解组织的现状与问题，人财物资源与目标的匹配，以便有针对性的调整变化，保障组织的有效规划和顺利进行。</p> 
    <p>6、组织执行力提升：通过组织活力扫描及改善动作，可以保障团队活力状态及执行力度，使得人和资源被充分调动，任务分配合理，确保计划得到适当实施。 </p> 
    <p>7、组织协同增强：通过组织活力扫描，帮助建立更加完整和系统的运营体系，打破部门壁垒、信息孤岛，使得信息能够自由流动，知识共享，更高效地解决问题、创新、实现目标。</p> 

    <h1>联系我们</h1>
    <p class="p2">如需帮助，请长按二维码添加客服咨询</p>
    <img src="../../assets/images/kefuQrcode.jpg" class="kefu">
    <p class="p4">固话：<a href="tel:020-87070220">020-87070220</a></p>
    <p class="p4">手机：<a href="tel:18027418722">18027418722</a></p>
    <div class="under">
      <div class="btn" @click="start">立即购买</div>
    </div>
    <!-- 登录窗口 -->
    <div class="mask" v-if="riseIndex==1" @click="countryShow=false">
      <div class="login box" :class="{rise:riseIndex==1}">
        <img src="../../assets/images/icons/close_2.png" class="close" @click="riseIndex=0">
        <h4>{{loadingText}}</h4>
        <div class="option" :class="{focus:focusIndex==1,error:errorIndex==1}">
          <h5>请输入手机号</h5>
          <div class="inputBox flex--c">
            <label :class="{rotate:countryShow}" @click.stop="countryShow=!countryShow">{{$country[countryIndex].label}}</label>
            <input type="text" maxlength="11" v-model="phone" @focus="focusIndex=1;errorIndex=0" @blur="focusIndex=0">
            <ul  class="countryBox" :class="{show:countryShow}">
              <li v-for="(item,i) in $country" :key="i" @click="countryIndex=i" :class="{curr:countryIndex==i}">{{item.country}}</li>
            </ul>
          </div>
        </div>
        <div class="option" :class="{focus:focusIndex==2,error:errorIndex==2}">
          <h5>请输入验证码</h5>
          <div class="inputBox flex--c">
            <input type="text" maxlength="6" v-model="captcha" @focus="focusIndex=2;errorIndex=0" @blur="focusIndex=0">
            <span v-if="second===0" @click="getCode">获取验证码</span>
            <span class="disable" v-else>重新获取({{second}}s)</span>
          </div>
        </div>
        <div class="btn" @click="login">登 录</div>
      </div>
    </div>
    <!-- 购买窗口 -->
    <div class="mask" v-else-if="riseIndex==2">
      <div class="riseBox">
        <img src="../../assets/images/icons/close_2.png" class="close" @click="riseIndex=0;teamname='';realname=''">
        <header class="flex-a">
          <span :class="{active:tab==1}" @click="tab=1">套餐购买</span>
          <span :class="{active:tab==2}" @click="tab=2">账号购买</span>
        </header>
        <div class="tips" :class="{hide: !teamText}">
          温馨提示：<div v-html="teamText"/>
        </div>
        
        <div class="view" :class="{hide:tab!=1}">
          <h5>产品详情</h5>
          <div class="flex-b">
            组织活力扫描<span>合计：<b class="green">￥29800.00</b></span>
          </div>
          <div class="flex">
            <label>1年有效期</label>
            <label>200个账号</label>
            <label>团队分析</label>
          </div>
        </div>
        <div class="view" :class="{hide:tab!=2}">
          <h5>产品详情</h5>
          <div class="flex-b">
            组织活力扫描<span>价格：<b class="green">￥100/个</b></span>
          </div>
          <div class="input flex-b-c">
            购买数量
            <div class="flex">
              <span @click="changeNum(-1)">-</span>
              <input type="text" v-model="buyNum" maxlength="3">
              <span @click="changeNum(1)">+</span>
            </div>
          </div>
          <div class="flex"><label>1年有效期</label></div>
          <div class="price">合计：<b class="green">¥ {{totalPrice}}</b></div>
        </div>
        <div class="view" :class="{hide: teamText}">
          <h5 style="margin-bottom:2px">购买前请完善以下信息</h5>
          <div class="row flex--c">
            <h6>公司/团队名 </h6><span class="red">*</span><input type="text" v-model="teamname" maxlength="30" placeholder="请输入真实的公司或团队名称">
          </div>
          <div class="row flex--c">
            <h6>真实姓名 </h6><span class="red">*</span><input type="text" v-model="realname" maxlength="30" placeholder="请输入真实姓名">
          </div>
          <div class="row flex--c">
            <h6>备注（选填）</h6><input class="inputPL" type="text" v-model="remark" placeholder="请输入备注">
          </div>
        </div>
        <footer>
          <div @click="pay()">确认付款</div>
        </footer>
      </div>
    </div>
    <!-- 购买成功 -->
    <div class="success" v-else-if="riseIndex==10">
      <img src="../../assets/images/icons/success_cbc.png" class="icon">
      <h5>支付成功</h5>
      <h6>点击下方按钮前往小程序端进行评估管理</h6>
      <wx-open-launch-weapp username="gh_0978e9d0dfeb" :path="path">
        <script type="text/wxtag-template">
          <div style="line-height: 40px;background: #2384ff;color: #fff;border-radius: 4px;letter-spacing: 2px;margin: 50px 30px 20px;font-size: 14px;text-align: center;width:300px">进入小程序</div>
        </script>
      </wx-open-launch-weapp>
    </div>
  </div>
</template>
<script>
export default {
  data(){
    return {
      handler_id: this.$route.query.handler_id || '',
      phone:'',                   //登录手机号
      captcha:'',                 //登录验证码
      second: 0,                  //倒数秒数
      countryShow: false,         //区号选择框显隐
      countryIndex: 0,            //当前选择的区号索引
      errorIndex: 0,              //登录错误索引(1：手机号错误，2：验证码错误)
      focusIndex: 0,              //聚焦索引
      riseIndex: 0,               //弹起窗口索引(1：登录，2：购买，3：已存在公司)
      loadingText: '请先登录',     //是否已经登录
      teamname: '',               //团队名
      realname: '',               //真实姓名
      remark: '',                 //备注
      teamText: '',               //温馨提示
      loginType: 'login',         //登录类型(login:登录,register:注册)
      tab: 1,                     //1:购买套餐，2:增加账号
      path: 'pages/VTS/manage/manage',
      buyNum: 1,
      totalPrice: '100.00',
    }
  },
  created() {
    document.title = '组织活力扫描'
    this.$get(this.spaceUrl+'/User/checkWeixinLogin').then(() => {}, (e) => {
      if (e.code == 1 && location.href.indexOf('//localhost') < 0) location.href = `${this.spaceUrl}/Product/index?url=${encodeURIComponent(location.href)}`
    })
    this.$get(this.spaceUrl+'/User/checklogin').then(() => {
      this.loadingText = '登录成功'
      this.getTVS()
    }, () => {})
    //微信分享
    wx.ready(() => {
      wx.onMenuShareAppMessage({
        title : '组织活力扫描',
        link : `${this.spaceUrl}/Product/index?url=${encodeURIComponent(location.href)}`,
        imgUrl : `${this.mobileUrl}/static/img/p-15.jpg`,
      })
    })
  },
  methods:{
    start() {
      let ua = navigator.userAgent.toLowerCase()
      if (ua.match(/MicroMessenger/i) != 'micromessenger') {
        this.$toast({message: '请在微信内打开'})
      } else if (this.loadingText == '登录成功') {
        this.riseIndex = 2
      } else {
        this.riseIndex = 1
      }
    },
    pay() {
      if (!this.teamname) {
        this.$toast({message: '请输入公司或团队名称', duration: 1000})
      } else if (!this.realname) {
        this.$toast({message: '请输入真实姓名', duration: 1000})
      } else {
        this.$get(this.spaceUrl+'/User/getsession').then(d => this.path = `pages/VTS/manage/manage?sessionID=${d.hy_session}&scene=cp`)//获取小程序路径的session
        this.$indicator.open({text: '加载中...',spinnerType: 'fading-circle'})
        this.$get(this.spaceUrl+'/Product/createShopOrder', {
          type: this.tab,
          product_id: 28, //组织活力扫描产品id
          number: this.tab==1 ? 1 : this.buyNum,
          companyname: this.teamname,
          realname: this.realname,
          remark: this.remark,
          handler_id: this.handler_id,
        }).then(data => {
          WeixinJSBridge.invoke('getBrandWCPayRequest', data, res => {
            if (res.err_msg == "get_brand_wcpay_request:ok") {
              this.riseIndex = 10
            } else {
              this.$toast({message: '支付失败'})
            }
          })
        }, e => {
          if (e.code == 6) {
            location.href = `${this.spaceUrl}/Product/index?url=${encodeURIComponent(location.href)}`
          } else {
            this.$toast({message: e.msg})
          }
        }).then(() => this.$indicator.close())
      }
    },
    getCode() {
      if (this.sending) {
        return
      } else if (!this.phone) {
        this.$toast({message: '请输入手机号', duration: 1000})
        this.errorIndex = 1
      } else if (!this.$country[this.countryIndex].reg.test(this.phone)) {
        this.$toast({message: '手机号格式不对', duration: 1000})
        this.errorIndex = 1
      } else {
        this.sending = true
        this.$http.post(this.spaceUrl+'/User/checkMobile', {
          mobile: this.phone,
          country_code: this.$country[this.countryIndex].country_code,
        }).then(e => {
          if (e.data.msg.indexOf('未注册') > 0) this.loginType = 'register'
          this.$get(this.spaceUrl+'/sms/getSmsCaptcha', {
            mobile: this.phone,
            country_code: this.$country[this.countryIndex].country_code,
            type: this.loginType,
          }).then(() => {
            this.$toast({message: '发送成功'})
            this.second = 60
            var timer = setInterval(() => {
              if (--this.second < 1) clearInterval(timer)
            }, 1000)
          }, () => this.$toast({message: '发送失败'})).then(() => this.sending = false)
        }, () => {
          this.sending = false
          this.$toast({message: '手机检验失败'})
        })
      }
    },
    login() {
      if (this.loadingText == '登录中') {
        return
      } else if (!this.phone) {
        this.$toast({message: '请输入手机号', duration: 1000})
        this.errorIndex = 1
      } else if (!this.$country[this.countryIndex].reg.test(this.phone)) {
        this.$toast({message: '手机号格式不对', duration: 1000})
        this.errorIndex = 1
      } else if (!this.captcha) {
        this.$toast({message: '请输入验证码', duration: 1000})
        this.errorIndex = 2
      } else { //登录或注册
        this.$get(`${this.spaceUrl}/User/${this.loginType}`, {
          mobile: this.phone,
          country_code: this.$country[this.countryIndex].country_code,
          captcha: this.captcha,
        }).then(() => {
            this.loadingText = '登录成功'
            this.getTVS().then(() => this.riseIndex = 2)
        }, ({msg}) => {
          this.loadingText = '请先登录'
          this.$toast({message: msg})
        })
      }
    },
    loginOut() {this.$get(this.spaceUrl+'/User/loginOut')},
    getTVS() {
      return new Promise (resolve => {
        this.$get(this.spaceUrl+'/Tvs/getTvsService').then(d => {
          this.teamText = `你当前所在的团队<b style="color:#2384ff">“${d.companyname}”</b>还剩余<b style="color:#2384ff">${d.may_invite_num}</b>个数量，服务将在<b style="color:#2384ff">${this.$formatTime(d.service_time, '年月日')}到期</b>，请及时续费使用。`
          this.teamname = d.companyname
          this.realname = d.realname || d.nickname
        }, ({code, data}) => {
          if (code == 3) {  //未购买此产品，但已有团队
            this.teamText = `你当前已在团队<b style="color:#2384ff">“${data.companyname}”</b>，购买后将在此团队使用。`
            this.teamname = data.companyname//用于购买时判断是否有公司和姓名
            this.realname = data.realname || data.nickname
          }
        })
      })
    },
    changeNum(n) {
      let res = parseInt(this.buyNum) + n
      if (res > 0 && res < 1000) this.buyNum = res + ''
    }
  },
  watch: {
    captcha(val) {
      this.captcha = val.replace(/[^\d]/g, '')
    },
    buyNum(val) {
      this.buyNum = val.replace(/[^\d]/g, '')
      this.totalPrice = (this.buyNum * 100).toFixed(2)
    }
  }
}
</script>
<style lang="scss" scoped>
$color:#2384ff;
.tvsIndex{
  padding-bottom: 180px;
  text-align: left;
  background: #daf0ff;
  box-shadow:  0 0 8px rgba(50,50,50,.1);
  img{display: block;width: 100%;}
  h1{
    line-height: 80px;
    color: $color;
    margin: 60px 30px 30px;
    font-size: 30px;
    position: relative;
    user-select: none;
    padding-left: 40px;
    border-bottom: 1px solid #aed8ff;
    &::before{
      content: '';
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 0;
      width: 26px;
      height: 26px;
      background: url('../../assets/images/product/explain3.png') no-repeat;
      background-size: 100%;
    }
  }
  p{
    font-size: 26px;
    text-align: justify;
    line-height: 1.6;
    color: #414b5e;
    margin: 24px 40px;
    user-select: none;
    white-space: normal;
  }
  .p2{
    color: #414b5e;
    text-align: center;
    line-height: 120px;
    margin: 0 28px;
    white-space: nowrap;
  }
  .p3{
    margin-bottom: 20px;
    text-align: center;
    color: #414b5e;
  }
  .p4{
    text-align: center;
    line-height: 1.4;
    color: #414b5e;
    a{
      color: #414b5e;
      user-select: text;
      text-decoration: none;
    }
  }
  .kefu{
    width: 200px;
    height: 200px;
    display: block;
    margin: 0 auto;
    border: 1px solid #ccc;
    margin-bottom: 40px;
  }
  .under{
    position: fixed;
    width: 100%;
    max-width: 800px;
    padding: 0 30px;
    height: 120px;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;
    background: #cceaff;
    display: flex;
    align-items: center;
    text-align: center;
    font-size: 24px;
    color: #999;
    z-index: 10;
    .btn{
      line-height: 80px;
      background: $color;
      color: #fff;
      border-radius: 8px;
      letter-spacing: 2px;
      font-size: 28px;
      flex-grow: 1;
    }
  }
  input::-webkit-input-placeholder {color: #ccc;}
  input::-moz-input-placeholder {color: #ccc;}
  input::-ms-input-placeholder {color: #ccc;}
}
.riseBox{
  position: fixed;
  width: 100%;
  left: 0;
  bottom: -100%;
  background: #f5f5f5;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  color: #999;
  font-size: 28px;
  overflow: hidden;
  animation: riseLSN .2s linear forwards;
  @keyframes riseLSN {100%{bottom: 0}}
  .close{
    position: absolute;
    width: 50px;
    height: 50px;
    padding: 12px;
    right: 20px;
    top: 20px;
    opacity: .6;
  }
  header{
    background: #fff;
    padding: 0 40px;
    transition: .3s;
    overflow: hidden;
    span{
      line-height: 100px;
      position: relative;
    }
    .active{
      color: $color;
      &::after{
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 4px;
        background: $color;
      }
    }
  }
  .tips{
    background: #fff;
    padding: 24px 30px;
    border-top: 4px solid #e1e1e1;
    margin-top: -4px;
    overflow: hidden;
    div{
      margin-top: 16px;
      line-height: 1.6;
    }
  }
  .view{
    margin: 24px 28px;
    border-radius: 10px;
    padding: 30px 30px 40px;
    background: #fff;
    overflow: hidden;
    max-height: 400px;
    transition: .2s linear;
    .input{
      margin-top: 24px;
      div{
        border: 1px solid #e5e5e5;
        text-align: center;
        border-radius: 8px;
      }
      span{
        width: 50px;
        line-height: 50px;
        font-size: 36px;
        color: #ccc;
      }
      input{
        width: 80px;
        color: #1a1a1a;
        text-align: center;
        border-left: 1px solid #e5e5e5;
        border-right: 1px solid #e5e5e5;
      }
    }
    h5{
      margin-bottom: 20px;
      color: #333;
      font-weight: bold;
    }
    label{
      font-size: 24px;
      padding: 0 30px;
      line-height: 1.8;
      background: #e9f2ff;
      color: $color;
      margin: 30px 30px 0 0;
      border-radius: 4px;
    }
    .price{
      text-align: right;
      margin-top: 20px;
    }
    .green{color: $color}
    .row{
      border-bottom: 1px solid #ebebeb;
      height: 88px;
      color: #b3b3b3;
      white-space: nowrap;
      h6{
        width: 160px;
        text-align-last: justify;
        margin-right: 10px;
        color: #4d4d4d;
      }
      input{
        padding-left: 24px;
        height: 100%;
        color: #4d4d4d;
        font-size: 28px;
        flex: 1;
      }
      .inputPL{padding-left: 34px;}
    }
    .red{color: #f52525}
  }
  footer{
    background: #fff;
    padding: 20px 28px;
    div{
      line-height: 40PX;
      background: $color;
      color: #fff;
      border-radius: 4PX;
      letter-spacing: 1PX;
      font-size: 14PX;
      text-align: center;
    }
  }
  .hide{
    max-height: 0;
    padding: 0 30px;
    margin-top: 0;
    margin-bottom: 0;
    transition: 0s;
  }
}
.box{
  position: fixed;
  width: 100%;
  height: 80%;
  bottom: -100%;
  padding: 0 30px;
  left: 0;
  overflow-y: auto;
  background: #fff;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  overflow: hidden;
  z-index: 99;
  .close{
    position: absolute;
    width: 50px;
    height: 50px;
    padding: 12px;
    right: 20px;
    top: 20px;
    opacity: .6;
  }
}
.rise{
  transition: bottom .2s linear;
  bottom: 0;
}
.login{
  font-size: 26px;
  h4{
    font-size: 32px;
    font-weight: bold;
    text-align: center;
    color: #4d4d4d;
    line-height: 100px;
  }
  .btn{
    width: 100%;
    margin-top: 165px;
    background: $color;
    line-height: 84px;
    color: #fff;
    font-size: 30px;
    border-radius: 8px;
    user-select: none;
    text-align: center;
  }
  .option{
    h5{
      color: #999;
      text-align: left;
      line-height: 1.8;
      user-select: none;
      margin-top: 100px;
    }
    .inputBox{
      position: relative;
      border-bottom: 2px solid #e1eae8;
      margin-top: 22px;
      input{
        padding-left: 16px;
        height: 70px;
        flex: 1;
        color: #4d4d4d;
        font-size: 28px;
      }
      label{
        width: 80px;
        padding-right: 10px;
        line-height: 70px;
        position: relative;
        color: #808080;
        &:after{
          content:'';
          position: absolute;
          top: 26px;
          right: 0px;
          border-top: 10px solid #ccc;
          border-left: 8px solid transparent;
          border-right: 8px solid transparent;
          transition: transform .1s linear;
          transform-origin: center;
        }
      }
      .rotate::after{transform: rotate(-180deg);}
      span{
        color: $color;
        background: #e9f2ff;
        border-radius: 6px;
        line-height: 54px;
        text-align: center;
        min-width: 148px;
        padding: 0 10px;
        font-size: 24px;
      }
      .disable{
        background: #f0f0f0;
        color: #999;
      }
    }
    .countryBox{
      position: absolute;
      top: 95%;
      left: -10px;
      background: #fff;
      box-shadow: 0 0 2px rgba(50,50,50,.3);
      z-index: 10;
      padding: 12px 0;
      border-radius: 6px;
      transform: scaleY(0);
      transform-origin: top;
      transition: transform .1s linear;
      user-select: none;
      li{
        padding: 0 20px;
        line-height: 2;
        color: #808080;
      }
      .curr{color: $color;}
    }
    .show{
      transform: scaleY(1);
    }
  }
  .focus{
    h5{color: #4d4d4d}
    .inputBox{border-bottom: 2px solid $color}
  }
  .error .inputBox{border-bottom: 2px solid #f96868}
}
.detail{
  padding: 80px 0 0;
  color: #999;
  white-space: nowrap;
  font-size: 28px;
  user-select: none;
  h5{
    line-height: 80px;
    color: #333;
    font-weight: bold;
    margin: 0 30px;
  }
  .flex-b{margin: 0 30px;}
  .green{color: $color}
  .red{color: #f52525}
  label{
    font-size: 24px;
    padding: 0 30px;
    line-height: 1.8;
    background: #eaf8f5;
    color: $color;
    margin: 30px 0 30px 30px;
    border-radius: 4px;
  }
  .line{
    background: #f1f1f1;
    height: 10px;
    margin: 20px 0;
  }
  .row{
    border-bottom: 1px solid #ebebeb;
    margin: 0 30px;
    height: 88px;
    color: #b3b3b3;
    h6{
      width: 200px;
      text-align-last: justify;
      margin-right: 10px;
    }
    input{
      padding-left: 24px;
      height: 100%;
      color: #4d4d4d;
      font-size: 28px;
      flex: 1;
    }
    .inputPL{padding-left: 34px;}
  }
  .btn{
    line-height: 40PX;
    background: $color;
    color: #fff;
    border-radius: 4PX;
    letter-spacing: 1PX;
    margin: 100px 16PX 40px;
    font-size: 14PX;
    text-align: center;
  }
  .btn2{
    margin-top: 0;
    background: none;
    border: 1px solid $color;
    color: $color;
  }
  .p5{
    color: $color;
    line-height: 2.2;
    font-size: 28px;
    font-weight: bold;
  }
  .p6{
    font-size: 28px;
  }
}
.mask{
  position: fixed;
  width: 100%;
  height: 110%;
  top: -4px;
  left: 0;
  z-index: 11;
  background: rgba($color: #000, $alpha: .7);
}
.success{
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  height: 100%;
  padding-top: 240px;
  user-select: none;
  white-space: nowrap;
  background: #fff;
  text-align: center;
  .icon{
    display: block;
    width: 180px;
    height: 180px;
    background: #d7f2ec;
    border-radius: 50%;
    margin: 20px auto;
  }
  h5{
    font-size: 40px;
    color: #4d4d4d;
    font-weight: bold;
  }
  h6{
    color: #999;
    font-size: 26px;
    line-height: 100px;
  }
  .btn{
    line-height: 80px;
    background: $color;
    color: #fff;
    border-radius: 8px;
    letter-spacing: 2px;
    margin: 100px 60px 40px;
    font-size: 28px;
    text-align: center;
  }
}
.fix{
  height: 100%;
  overflow: hidden;
}
</style>