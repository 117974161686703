<template>
  <div class="report" :class="{long:long}">
    <div class="top">
      <header>
        <div class="label">
          <b>{{org_name}}</b> 报告分析<br>
          {{$formatTime(endTime,'y-m-d h:m')}}<span v-if="version">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;版本号 v{{version}}</span>
        </div>
        <!-- <div v-if="!long && !hide" class="btn" @click="save">报告长图</div>  -->
      </header>
      <nav v-if="!long">
        <span :class="{current:tabId==0}" @click="tab(0)">整体<br>报告</span>
        <span :class="{current:tabId==1}" @click="tab(1)">团队<br>活力度</span>
        <span :class="{current:tabId==2}" @click="tab(2)">工作价值<br>倾向</span>
        <span v-if="leaderShow" :class="{current:tabId==3}" @click="tab(3)">领导<br>能量</span>
        <span :class="{current:tabId==5}" @click="tab(5)">团队<br>基本信息</span>
      </nav>
    </div>
    <ul class="content">
      <!-- 整体报告 -->
      <div class="title" v-if="long">整体报告</div>
      <li class="whole" v-show="tabId==0 || long">
        <h3 style="margin-top:30px">[团队活力度]</h3>
        <div class="bar-box">
          <i></i>
          <div class="bar" :style="{width:activeScore+'%'}"><b></b></div>
          <i class="right"></i>
        </div>
        <div class="TVI">当下团队活力指数为<span class="blue">{{Math.round(team_activity.TVI.score)}}</span>，活力等级为<span>{{team_activity.TVI.level}}</span></div>
        <p class="high"><span>团队优势：{{team_activity.top_dimension.name}}，</span>{{team_activity.top_dimension.content}}</p>
        <p class="low"><span>有待激发：{{team_activity.low_dimension.name}}，</span>{{team_activity.low_dimension.content}}</p>

        <div class="padding"></div>
        <h3>[团队工作价值倾向]</h3>
        <div class="wave">
          <waveBall v-for="(item,i) in team_workvalue.top_dimension" :width="width*.22" :type="i" :name="item.name" :key="i"></waveBall>
        </div>
        <div class="text">当下，团队成员最需要在：<span v-for="(item,i) in team_workvalue.top_dimension" :key="i">{{item.name}}{{i==team_workvalue.top_dimension.length-1?'':'、'}}</span>方面得到更多的满足和激励。</div>
        <template v-if="leaderShow && team_leadership.index_dimension">
          <div class="padding"></div>
          <h3>[管理者的领导能量水平]</h3>
          <plate :width="width*.7" :dimension="team_leadership.index_dimension" :score="team_leadership.leadership.score"></plate>
          <div class="text">
            当下，领导能量指数为<b class="red">{{Math.round(team_leadership.leadership.score)}}</b>，等级为<b class="red">{{team_leadership.leadership.level}}</b>，其中，<b class="red">“{{team_leadership.index_dimension[0]}}”</b>方面发挥较好，<b class="green">“{{team_leadership.index_dimension[1]}}”</b>方面发挥较弱。
          </div>
        </template>
      </li>
      <!-- 团队活力度 -->
      <div class="title" v-if="long">团队活力度</div>
      <li v-show="tabId==1 || long">
        <barRow :data="team_activity.dimension" :version="1"></barRow>
        <div class="TVI" style="margin:-70px 0 20px">团队活力度：<span class="blue">{{team_activity.TVI.score}}</span></div>
        <p>预警一区：因子活性有待激发， 不足以释放活力。</p>
        <p>中等区：因子活性一般，活力释放有限，需要进一步激发。</p>
        <p>良好区：因子活性良好，活力释放充沛，能够积极影响团队。</p>
        <p>优秀区：因子活性较强，能释放较强的活力，推动团队发展。</p>
        <p>预警二区：活性因子太过活跃，可能会脱离实际，发展到失控状态。</p>

        <lineRectChart :data="team_activity.consistency" :width="width*.86" :animate="!long"></lineRectChart>
        <p v-for="(item,i) in team_activity.activeText" :key="i" v-show="item.length>0">当前团队成员在“<b v-for="(it,j) in item" :class="i==3?'green':'blue'" :key="j">{{it}}{{j==item.length-1?'':'、'}}</b>”方面的共识度{{i==3?'较低':i==2?'中等':i==1?'中上':'相对较高'}}。</p>
      </li>
      <!-- 工作价值倾向 -->
      <div class="title" v-if="long">工作价值倾向</div>
      <li class="values" v-show="tabId==2 || long">
        <radarCircle :width="width*.88" :data="team_workvalue.dimension" :animate="!long" :lineColor="'#48b3f6'" ref="radar2"></radarCircle>
        <h1>通过团队成员的答题，我们了解到：</h1>
        <p v-for="(it,i) in team_workvalue.top_dimension" :key="i">{{it.content}}</p>

        <lineRectChart :data="team_workvalue.consistency" :width="width*.86" :animate="!long"></lineRectChart>
        <p v-for="(item,i) in team_workvalue.wordText" :key="i" v-show="item.length>0">当前团队成员在“<b v-for="(it,j) in item" :class="i==3?'green':'blue'" :key="j">{{it}}{{j==item.length-1?'':'、'}}</b>”方面的共识度{{i==3?'较低':i==2?'中等':i==1?'中上':'相对较高'}}。</p>
      </li>
      <!-- 领导能量 -->
      <div class="title" v-if="long">领导能量分析</div>
      <li class="leadership" v-show="tabId==3 || long" v-if="leaderShow">
        <barRow :data="team_leadership.dimension" :color-type="1" :version="1"></barRow>
        <h2 style="margin:-70px 0 20px">领导能量指数：<span>{{team_leadership.leadership.score}}</span></h2>
        <p>预警一区：领导能量开发、释放不足，难以对团队形成影响。</p>
        <p>中等区：领导能量释放水平一般，可以对团队形成较小的影响。</p>
        <p>良好区：领导能量释放水平良好，能够有效影响团队。</p>
        <p>优秀区：领导能量释放水平较高，且发挥充分，能够影响并引领团队。</p>
        <p>预警二区：领导能量过度释放，会过犹不及，起到反作用。</p>
      </li>
      <!-- 团队基本信息 -->
      <div class="title" v-if="long">团队基本信息</div>
      <li class="message" v-show="tabId==5 || long">
        <div class="sex">
          <div class="bar" :style="{width:womanPercent+'%'}"></div>
          <div class="bar man-bar" :style="{width:100-womanPercent+'%'}"></div>
          <span>{{Math.round(womanPercent)}}%</span>
          <span class="man">{{100-Math.round(womanPercent)}}%</span>
          <img src="../../../assets/images/trump/woman.png">
          <img src="../../../assets/images/trump/man.png" class="man">
        </div>
        <h6>团队当前成员共有{{totalNum}}人，其中男性成员{{basic_info.sex_info[0].number||0}}人，女性成员{{basic_info.sex_info[1].number||0}}人。</h6>
        
        <piePeople :width="width*.8" :data="basic_info.tier_info"></piePeople>
        <h6>团队中{{tierText}}。</h6>

        <div class="education">
          <div class="left">
            <div v-for="(item,index) in basic_info.educational_info" :key="index">{{item.name}}</div>
          </div>
          <div class="right">
            <div class="row" v-for="(item,i) in basic_info.educational_info" :key="i">
              <div class="bar"><span :style="{width:item.percent+'%'}"></span></div>
              {{Math.round(item.percent||0)}}%
            </div>
          </div>
        </div>
        <h6>团队中{{eduText}}。</h6>
        
        <div class="age">
          <div class="circle" v-for="(it,i) in basic_info.era_info" :key="i" v-show="i<5"><i :style="{transform: `scale(${it.percent},${it.percent})`}"></i><span>{{it.era}}</span></div>
        </div>
        <h6>团队平均年龄为{{basic_info.avg_age}}岁，{{eraText}}。</h6>
      </li>
    </ul>
    <div class="img-box" v-if="reportImgShow">
      <p class="text">长按保存图片</p>
      <div class="inner-box"><img :src="reportImg"></div>
      <img @click="closeImg" class="close" src="../../../assets/images/icons/close.png"/>
    </div>
    <div class="mask" v-if="!success"></div>
  </div>
</template>
<script>
import piePeople from '../../aceTeam/group/piePeopleChart'
import radarCircle from '../../aceTeam/group/radarCircleChart'
// import peak from '../../aceTeam/group/peakChart'
// import radarSale from '../../aceTeam/group/radarSaleChart'
// import bar from '../../aceTeam/group/barChart'

import barRow from './chart/barRowChart'
import waveBall from './chart/waveBallChart'
import plate from './chart/plateChart'
import lineRectChart from './chart/lineRectChart'
export default {
  components:{piePeople,radarCircle,waveBall,plate,lineRectChart,barRow},
  props:{
    report_id: {default: null},
    version: {default: ''}
  },
  data(){
    return{
      width: 0,
      tabId: 0,
      success: false,
      // report_id: this.$route.params.report_id || '',
      hide: this.$route.query.hide || false,                 //
      hy_session: this.$route.query.hy_session || '',
      long:this.$route.query.long===undefined?false:true,   //是否报告长图
      org_name: '正在加载',                                  //企业名
      endTime: 0,                                           //报告生成成时间
      totalNum: 0,
      reportImg:'',
      reportImgShow: false,
      timer:'',
      // 团队基本信息：
        basic_info:{
          avg_age:0,
          educational_info:[
            {name: "高中及以下",number:0},
            {name: "大专",number:0},
            {name: "本科",number:0},
            {name: "硕士研究生",number:0},
            {name: "博士及博士后",number:0},
          ],
          era_info:[
            {era: "00",number:0},
            {era: "90",number:0},
            {era: "80",number:0},
            {era: "70",number:0},
            {era: "60",number:0},
            {era: "其它",number:0},
          ],
          sex_info: [{sex:1},{sex:2}],
          tier_info: '',
        },
      // 团队活力度：
        team_activity: {
          consistency: null,
          dimension:[],
          top_dimension:{content:'',name:''},
          low_dimension:{content:'',name:''},
          TVI:{score:0,level:''},
          activeText:[[],[],[],[]],//高中低维度
        },
        activeScore: 0,
      // 销售行为分析:
        team_sale: null,
        saleText:[
          {name:'积极心态',text:'团队成员的<b style="color: #33a1fb">心态管理</b>相对较好，能从积极的角度思考，主动采取行动，确保目标达成。'},
          {name:'灵活应变',text:'团队成员的<b style="color: #33a1fb">心态管理</b>相对较好，面对变化能够主动顺应、调整，保证工作成果。'},
          {name:'自我管理',text:'团队成员善于管理自己，时刻保持最佳状态，让<b style="color: #33a1fb">拜访活动</b>更加有效。'},
          {name:'自我期待',text:'团队成员会在不同时期，为自己设定更高的目标，全力以赴达成，让<b style="color: #33a1fb">拜访活动</b>维持在高水平。'},
          {name:'有序行动',text:'团队成员能够合理规划目标，安排计划，行动有序，让<b style="color: #33a1fb">拜访活动</b>事半功倍。'},
          {name:'倾听交流',text:'团队成员善于聆听，让对方感到舒适、愉快，让<b style="color: #33a1fb">销售面谈</b>质量更高。'},
          {name:'同理共情',text:'团队成员总是能把握他人情绪，理解对方的感受，让<b style="color: #33a1fb">销售面谈</b>取得积极成果。'},
          {name:'人际影响',text:'团队成员善于洞察他人内在的想法，理解对方的需求，让<b style="color: #33a1fb">销售面谈</b>更加有效。'},
          {name:'目标驱动',text:'团队成员有效的<b style="color: #33a1fb">目标管理</b>，让内心的动力源源不断，不断追求更高的成就。'},
          {name:'保证成果',text:'团队成员有效的<b style="color: #33a1fb">目标管理</b>，指引他们以终为始，确保每一个目标都能转换为成果。'},
        ],
      // 领导能量：
        team_leadership: {
          dimension: [],
          leadership: {score: 0,level: ''},
          low_dimension: [],
          top_dimension: [],
          index_dimension: ['',''],
        },
        leaderShow: true,
      // 工作价值倾向：
        team_workvalue:{
          consistency: null,
          dimension:[],
          top_dimension:[],
          wordText:[[],[],[],[]],//高中低维度
        },
    }
  },
  created(){
    this.$indicator.open({text: '加载中...',spinnerType: 'fading-circle'});
    this.$http.get(this.spaceUrl+'/Assess/getTeamReportInfo?team_report_id='+this.report_id+'&is_share='+1).then((e) =>{
      this.$indicator.close();
      let data = e.data.data
      if (e.data.code == 0) {
        this.success = true
        //团队基本信息：
          this.org_name = data.team_info.org_name
          this.endTime = data.team_info.report_time
          let total = this.totalNum = data.team_info.total_number
          let basic_info = data.basic_info
          //层级
          for(let i in basic_info.tier_info){
            basic_info.tier_info[i].percent = basic_info.tier_info[i].number / total
          }
          
          //学历
          for(let i in basic_info.educational_info){
            if(basic_info.educational_info[i].name == '博士研究生及博士后')
              basic_info.educational_info[i].name = '博士及以上'
            basic_info.educational_info[i].percent = basic_info.educational_info[i].number / total * 100
          }
          //年龄段
          let maxNum = Math.max.apply(Math, basic_info.era_info.map((e)=>{return e.number}))
          for(let i in basic_info.era_info){
            basic_info.era_info[i].percent = basic_info.era_info[i].number / maxNum * .8 + .2
          }
          this.basic_info = basic_info

        // 团队活力度：
          let activity = data.team_activity,
              activeText = [[],[],[],[]]
          for(let i in activity.consistency){
            if(activity.consistency[i].dispersion > 0.8){ //低
              activeText[3].push(activity.consistency[i].name)
            }else if(activity.consistency[i].dispersion > .3){ //中等
              activeText[2].push(activity.consistency[i].name)
            }else if(activity.consistency[i].dispersion > .15){ //中上
              activeText[1].push(activity.consistency[i].name)
            }else //高
              activeText[0].push(activity.consistency[i].name)
          }
          activity.activeText = activeText
          activity.consistency = {4: activity.consistency}  //key只为控制折线颜色
          this.team_activity = activity
          this.activeScore = activity.TVI.score

        // 销售行为特性：
          if (data.team_sale) {
            let saleText = []
            for(let i in data.team_sale.top_dimension){
              for(let j in this.saleText){
                if(data.team_sale.top_dimension[i]==this.saleText[j].name)
                  saleText.push(this.saleText[j].text)
              }
            }
            data.team_sale.saleText = saleText
            this.team_sale = data.team_sale
          }
        // 领导能量：
          let leadership = data.team_leadership
          if (leadership && leadership.leadership) {
            if (!leadership.index_dimension) {
              let arr = leadership.dimension.slice(0).sort((a,b)=>b.score-a.score)
              leadership.index_dimension = [
                arr[0].name,
                arr[arr.length-1].name
              ]
            }
            this.team_leadership = leadership
          } else {
            this.leaderShow = false
          }
        // 工作价值倾向：
          let workvalue = data.team_workvalue,
              wordText = [[],[],[],[]]
          for(let i in workvalue.consistency){
            if(workvalue.consistency[i].dispersion>0.8){ //低
              wordText[3].push(workvalue.consistency[i].name)
            }else if(workvalue.consistency[i].dispersion>.3){ //中等
              wordText[2].push(workvalue.consistency[i].name)
            }else if(workvalue.consistency[i].dispersion>.15){ //中上
              wordText[1].push(workvalue.consistency[i].name)
            }else //高
              wordText[0].push(workvalue.consistency[i].name)
          }
          workvalue.wordText = wordText
          workvalue.consistency = {5: workvalue.consistency}  //key只为控制折线颜色
          this.team_workvalue = workvalue
      } else {
        this.org_name = '无法加载'
        this.$toast({message:'加载失败',duration: 2000})
      }
    }).catch((err)=>{
      this.org_name = '无法加载'
      this.success = false
      this.$indicator.close();
      console.error(err);
      if(err.message.indexOf('org_name')>-1){//code=0接口报错
        this.$toast({message:'报告错误，请联系管理员',duration: 5000})
      }else{
        this.$toast({message:'网络错误',duration: 1500})
      }
    })
  },
  mounted(){
    this.width = $('#app').width()
  },
  methods:{
    tab(e){
      this.$refs.radar2.showIndex = null
      if (this.$refs.radarSale)
        this.$refs.radarSale.showIndex = null
      if (this.tabId == e) return;

      this.activeScore = 0;
      this.tabId = e;
      //第一页需要改变数据达到动画效果
      if (this.tabId == 0) {
        setTimeout(() => {this.activeScore = this.team_activity.TVI.score}, 50)
      } else {
        this.activeScore = 0
      }
    },
    save(){
      // if (this.ajaxUrlPath.indexOf('app')>-1){
      //   this.long = true; return;
      // }
      if (this.reportImg)
        this.reportImgShow = true
      else
        this.$http.get(this.spaceUrl+'/Assess/getTeamReportImage?team_report_id='+this.report_id+'&hy_session='+this.hy_session).then((e) =>{
          if (e.data.code==0) {//已保存了长图
            this.reportImg = e.data.data
            this.reportImgShow = true
          } else {//新生成长图
            var url = location.href.replace(/http:\/\/localhost/,this.mobileUrl) + (location.hash.includes('?')?'&':'?') + 'long&ver=' + this.version
            this.$http.post(this.ajaxUrlPath+'/CardImage/createshotImg',{
              type: 'vigourReport',
              imageurl: url,
              width: '480',
              height: '1000',
            }).then(e => {
              if (!e.data.code) {
                this.$indicator.open({text: '图片生成中...',spinnerType: 'triple-bounce'});
                this.timer = setInterval(()=>{
                  this.$http.post(this.ajaxUrlPath+'/CardImage/getreport',{
                    type: 'vigourReport',//与上保持一致
                    messageId: e.data.data.messageId,
                  }).then(e=>{
                    if (!e.data.code) {
                      this.$indicator.close();
                      this.reportImg = e.data.data.image;
                      this.reportImgShow = true
                      clearInterval(this.timer);
                      //保存当前报告的图片
                      this.$http.post(this.spaceUrl+'/Assess/updateTeamReportImage?hy_session=' + this.hy_session,{
                        team_report_id: this.report_id,
                        report_image: this.reportImg,
                      })
                    }
                  })
                },2500)
              }
            })
          }
        })
    },
    closeImg(){
      this.reportImgShow = false
    },
  },
  computed:{
    womanPercent(){
      let woman = this.basic_info.sex_info[1].number||0
      if(this.totalNum)
        return woman/this.totalNum*100
      else
        return 50
    },
    // sexScale(){
    //   let m = this.basic_info.sex_info[0].number||0 //男
    //   let n= this.basic_info.sex_info[1].number||0  //女
    //   let max =+ m, v =+ n, t = v;
    //   while(v!=0){
    //     t = max%v;
    //     max = v;
    //     v = t;
    //   }
    //   if(max == 0)
    //     return '0:0'
    //   else if (m == 0 || n == 0)
    //     return ''
    //   else if (max)
    //     return m/max + ":" + n/max
    // },
    tierText(){
      let text = '', tier = this.basic_info.tier_info;
      if(tier!=''){
        for(let i = tier.length; i--; i>0){
          if(tier[i].number)
            text += tier[i].name + tier[i].number + '人，'
        }
        text = text.substring(0, text.length-1); 
        return text
      }else return ''
    },
    eduText(){
      let text = '', edu = this.basic_info.educational_info;
      if(edu!=''){
        for(let i = edu.length; i--; i>0){
          if(edu[i].number)
            text += edu[i].name + edu[i].number + '人，'
        }
        text = text.substring(0, text.length-1); 
        return text
      }else return ''
    },
    eraText(){
      let text = '', era = this.basic_info.era_info;
      if(era!=''){
        for(let i = 0;i<era.length; i++){
          if(era[i].number)
            text += era[i].era + era[i].number + '人，'
        }
        text = text.substring(0, text.length-1); 
        return text
      }else return ''
    }
  },
}
</script>
<style scoped lang="scss">
.report{
  color:#6f6f6f;
  padding-bottom: 40px;
  min-height: 100%;
  position: relative;
  &::before{content: '';display: block;height: 194px;}
  .hide{height: 0;overflow: hidden;position: absolute;}
  .top{
    position: absolute;
    width: 100%;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    background: #fff;
    z-index: 999;
    header{
      display: flex;
      align-items: center;
      font-size: 26px;
      color: #aaa;
      padding: 0 40px;
      height: 120px;
      justify-content: space-between;
      line-height: 1.1;
      border-bottom: 1px solid #f0f0f0;
      .label{
        text-align: left;
        padding-right: 20px;
        font-size: 24px;
        line-height: 1.5;
        b{font-size: 26px}
      }
      .btn{
        width: 130px;
        color: #fff;
        border-radius: 6px;
        background: #33a1fb;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 24px;
        padding: 0 10px;
        flex-shrink: 0;
        white-space: nowrap;
        margin-left: 10px;
      }
    }
    nav{
      display: flex;
      border-bottom: 1px solid #f0f0f0;
      color: #999;
      height: 80px;
      background: #fff;
      box-sizing: border-box;
      font-size: 24px;
      align-items: center;
      overflow-x: auto;
      overflow-y: hidden;
      cursor: default;
      &::-webkit-scrollbar{display: none}
      span{
        flex: 1;
        line-height: 1.2;
        height: 100%;
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
      }
      .current{
        font-size: 26px;
        color: #4d4d4d;
        font-weight: bold;
        &::after{
          content: '';
          position: absolute;
          width: 98%;
          height: 4px;
          background: #33a1fb;
          bottom: -2px;
          left: 0;
        }
      }
    }
  }
  .content{
    overflow-x: hidden;
    .title{
      line-height: 80px;
      font-weight: bold;
      font-size: 30px;
      margin-top: 160px;
      color: #272727;
    }
    .TVI{
      font-size: 28px;
      font-weight: bold;
      margin-bottom: 50px;
      color: #424242;
    }
    li{
      margin: 0 40px;
      h1{
        font-size: 28px;
        text-align: left;
        font-weight: bold;
        margin: 20px 0;
        color: #5c5c5c;
      }
      h3{
        font-size: 28px;
        margin: 50px 0;
      }
      p{
        padding-left: 24px;
        text-align: justify;
        font-size: 27px;
        position: relative;
        color: #666;
        line-height: 1.6;
        &::before{
          content: '';
          position: absolute;
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background: #7a7a7a;
          left: 4px;
          top: 15px;
        }
      }
      .padding{
        height: 14px;
        background: #f2f2f2;
        position: relative;
        margin: 80px 0 0;
        &::before{
          content: '';
          position: absolute;
          width: 100px;
          height: 100%;
          background: #f2f2f2;
          right: 100%;
          top: 0;
        }
        &::after{
          content: '';
          position: absolute;
          width: 100px;
          height: 100%;
          background: #f2f2f2;
          left: 100%;
          top: 0;
        }
      }
      .high span{color: #3fb3ff;}
      .high::before{background: #3fb3ff;}
      .low span{color: #e86c52;}
      .low::before{background: #e86c52;}
      .blue{color: #3fb3ff}
      .yellow{color: #ebb748}
      .red{color: #f8887b}
      .green{color: #88b56c}
    }
    .message{
      h6{
        font-size: 26px;
        text-align: justify;
        position: relative;
        padding-left: 26px;
        margin: 50px 0;
        &::before{
          content: '';
          position: absolute;
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background: #7a7a7a;
          left: 4px;
          top: 12px;
        }
      }
      .sex{
        position: relative;
        display: flex;
        width: 76%;
        margin: 240px auto 0;
        padding-bottom: 50px;
        .bar{
          border: 1px solid #e86a50;
          height: 12px;
          background: #efa697;
          width: 50%;
          box-sizing: border-box;
          border-radius: 2px;
          transition: width .8s;
        }
        .man-bar{
          border: 1px solid #3076f3;
          background: #acc8fa;
          // flex: 1;
        }
        span{
          position: absolute;
          top: 24px;
          left: 20px;
          font-size: 24px;
          color: #a6a6a6;
        }
        img{
          position: absolute;
          bottom: 70px;
          left: 10px;
          width: 65px;
        }
        .man{
          left: auto;
          right: 10px;
        }
      }
      .education{
        display: flex;
        margin: 180px 0 60px;
        .left{
          line-height: 70px;
          width: 220px;
          border-right: 1px dashed #ccc;
          color: #999;
          text-align: right;
          padding-right: 44px;
        }
        .right{
          flex: 1;
          .row{
            display: flex;
            align-items: center;
            height: 70px;
            justify-content: space-between;
            padding-right: 20px;
            color: #999;
            .bar{
              width: 78%;
              height: 12px;
              background: #f7f7f7;
              border-radius: 2px;
              font-size: 24px;
              span{
                background: #f4d79b;
                display: block;
                height: 100%;
                width: 0%;
                box-sizing: border-box;
                border-radius: 2px;
                box-sizing: border-box;
                border: 1px solid #eab748;
                background: #f4d79b;
                transition: width .6s;
              }
            }
            &:nth-child(even) .bar span{
              border: 1px solid #4589d5;
              background: #a5c0f1;
            }
          }
        }
      }
      .age{
        display: flex;
        justify-content: center;
        margin: 200px 0 90px;
        .circle{
          margin: 0 10px;
          align-self:center;
          border-radius: 50%;
          position: relative;
          width: 100px;
          height: 100px;
          flex-shrink:0;
          i{
            display: block;
            width: 100%;height: 100%;
            border-radius: 50%;
            transform: scale(.2,.2);
            transition: transform .6s;
          }
          &:nth-child(1) i{border: 1px solid #d7562e;background: #efa697;}
          &:nth-child(2) i{border: 1px solid #f5c050;background: #f9d997}
          &:nth-child(3) i{border: 1px solid #88b56d;background: #c7e4b5}
          &:nth-child(4) i{border: 1px solid #72a1f8;background: #a7c3f3}
          &:nth-child(5) i{border: 1px solid #f69c44;background: #f6c18d}
          span{
            position: absolute;
            bottom: -40px;
            left: 50%;
            transform: translateX(-50%);
            color: #999;
            font-size: 24px;
            white-space: nowrap;
          }
        }
      }
    }
    .whole{
      h1{margin: 60px 0 20px;}
      .bar-box{
        position: relative;
        width: 76%;
        height: 36px;
        margin: 80px auto 70px;
        border: 1px solid #c5e8ff;
        &::before{
          content: '低';
          position: absolute;
          left: 0;
          top: 120%;
        }
        &::after{
          content: '高';
          position: absolute;
          right: 0;
          top: 120%;
        }
        i{
          position: absolute;
          border-left: 1px solid #33a1fb;
          left: -1px;
          top: 50%;
          transform: translateY(-50%);
          height: 54px;
        }
        .right{
          left: auto;
          right: -1px;
        }
        .bar{
          height: 100%;
          position: relative;
          background-image: linear-gradient(to right, #f5fbff , #61bcf7);
          transition: width .7s;
          b{
            position: absolute;
            right: -12px;
            bottom: 106%;
            border-top: 20px solid #33a1fb;
            border-left: 12px transparent solid;
            border-right: 12px transparent solid;
          }
        }
      }
      .wave{
        margin: 20px 0 50px;
        white-space: nowrap;
      }
      .text{
        text-align: left;
        font-size: 26px;
        span{color: #33a1fb;font-weight: bold}
      }
    }
    .sale{
      h1{margin-top: -30px}
    }
    .leadership{
      h2{
        font-size: 30px;
        font-weight: bold;
        margin-bottom: 80px;
        color: #505050;
        span{color: #33a1fb}
      }
    }
    .values{
      h1{margin-top: -40px}
    }
  }
  .img-box{
      width: 100%;
      height: 100%;
      position: fixed;
      background: rgba(0,0,0,0.8);
      top: 0;
      left: 0;
      z-index: 1000;
      font-size:30px;
      .text{line-height: 100px;color:#fff;}
      .inner-box{
        flex: 1;
        height: 80%;
        margin:0 30px 20px;
        overflow-y: auto;
        img{width: 100%;height: auto;display: block;}
      }
      .close{
        display: block;
        width: 70px;
        height: 70px;
        margin:0 auto;
      }
  }
  .mask{
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 99999;
  }
}
.long::before{height: 0;}
</style>