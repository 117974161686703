<template>
  <div class="beeMy" ref="body">
    <header class="flex--c">
      <img :src="user.avatar||require('../../../assets/images/icons/user_icon.png')">
      <div v-if="user">
        <h1>{{user.nickname}}</h1>
        <h2>{{user.mobile}}</h2>
      </div>
      <div @click="login" v-else>未登录</div>
      <h5 @click="tolink('login')" v-if="user.nickname&&!user.mobile">绑定手机号</h5>
    </header>
    <ul>
      <li class="flex--c" @click="tolink('mycourse')">
        <img src="../../../assets/images/bee/my_icon1.png">
        <span>我的课程</span>
      </li>
      <li class="flex--c" @click="tolink('myconsult')">
        <img src="../../../assets/images/bee/my_icon2.png">
        <span>我的咨询</span>
      </li>
      <li class="flex--c" @click="tolink('myorder')">
        <img src="../../../assets/images/bee/my_icon3.png">
        <span>我的订单</span>
      </li>
      <li class="flex--c" @click="kefuShow=true">
        <img src="../../../assets/images/bee/my_icon4.png">
        <span>联系客服</span>
      </li>
      <!-- <li class="flex--c" @click="logout">
        <span>退出登录</span>
      </li> -->
    </ul>
    <footer class="flex-a-c">
      <router-link :to="{path:'/bee/index' }" replace>
        <img src="../../../assets/images/bee/my_tab1.png">首页
      </router-link>
      <div style="color:#333">
        <img src="../../../assets/images/bee/my_tab4.png">我的
      </div>
    </footer>
    <div class="kefu" v-show="kefuShow">
      <div class="mask" @click="kefuShow=false"/>
      <div class="rise">
        <img src="../../../assets/images/kefuQrcode.jpg">
        <h3>长按识别添加企业微信客服</h3>
        <h4>联系电话：<a href="tel:020-87070220">020-87070220</a></h4>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data(){
    return {
      user: '',
      kefuShow: false,
    }
  },
  created(){
    this.getSession().then(d => {
      this.$http.post(this.ajaxUrlPath+'/Bee/getUserInfo?hy_session='+d).then(({data}) => {
        if (data.code == 0) {
          this.user = data.data
        }
      })
    })
      // this.$http.post(this.ajaxUrlPath+'/Bee/getSession').then(({data}) => {
      //   if (data.code == 0) {
      //     console.log(data.data.session_id)
      //   }
      // })
    console.log('https://api.app.huayanspace.com/WeixinH5/login?url='+encodeURIComponent(`http://${location.host}/#/bee/my`))
  },
  mounted(){
    this.$refs.body.addEventListener("touchmove", () => event.preventDefault(), false)//禁止弹性滑动
  },
  methods:{
    tolink(url) {
      if (!this.user)
        this.login()
      else
        this.$router.push({path: '/bee/' + url})
    },
    getSession() {
      return new Promise((resolve) => {
        let s = localStorage.getItem("session") || ''
        if (s) {
          resolve(s)
        } else {
          this.$http.post(this.ajaxUrlPath+'/Bee/getSession').then(({data}) => {
            if (data.code == 0) {
              localStorage.setItem("session", data.data.session_id)
              resolve(data.data.session_id)
            }
          })
        }
      })
    },
    login() {
      location.href = 'https://api.app.huayanspace.com/WeixinH5/login?url='+encodeURIComponent(location.href)
    },
    logout() {
      this.$http.post(this.spaceUrl+'/User/loginOut?hy_session='+localStorage.getItem("session"))
    },
  },
}
</script>
<style lang="scss" scoped>
.beeMy{
  text-align: left;
  header{
    padding: 0 50px 50px;
    color: #fff;
    background: url('../../../assets/images/bee/bee_img.jpg') no-repeat;
    background-size: 100%;
    height: 260px;
    font-size: 32px;
    img{
      width: 100px;
      height: 100px;
      border-radius: 50%;
      background: #fff;
      margin-right: 30px;
    }
    h2{
      font-size: 24px;
      margin-top: 6px;
    }
    div{flex: 1}
    h5{
      line-height: 48px;
      font-size: 28px;
      border-radius: 25px;
      padding: 0 20px;
      border: 1px solid #fff;
    }
  }
  ul{
    margin-top: -50px;
    border-top-left-radius: 50px;
    border-top-right-radius: 50px;
    background: #fff;
    padding: 50px 40px;
    li{
      margin: 40px 0px;
      padding: 20px;
      font-size: 28px;
      color: #333;
      img{
        width: 44px;
        height: 44px;
        margin-right: 20px;
      }
      span{
        flex: 1;
        font-weight: bold;
      }
      &:after{
        content: '';
        width: 16px;
        height: 16px;
        border-radius: 2px;
        border-right: 3px solid #b2b2b2;
        border-bottom: 3px solid #b2b2b2;
        transform: rotate(-45deg) translateX(-2px);
        transform-origin: center;
      }
    }
  }
  footer{
    position: fixed;
    width: 100%;
    max-width: 800px;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;
    background: #fff;
    height: 100px;
    box-shadow: 0 0 10px rgba(200,200,200,.1);
    color: #b4b4b4;
    text-align: center;
    img{
      height: 44px;
      display: block;
      padding: 0 10px 4px;
    }
    a{color: #b4b4b4}
  }
  .kefu{
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    box-shadow: 0 0 6px rgba(200,200,200,.2);
    z-index: 10;
    .mask{
      position: absolute;
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      background: rgba(0,0,0,.4);
    }
    .rise{
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      background: #fff;
      padding: 100px 0;
      border-top-left-radius: 50px;
      border-top-right-radius: 50px;
      text-align: center;
      animation: kefuRise .3s forwards;
      img{
        display: block;
        width: 300px;
        margin: 0 auto;
      }
      h3{
        color: #666;
        line-height: 60px;
      }
      h4{
        color: #000;
        font-size: 26px;
        margin-top: 40px;
      }
      a{
        color: #000;
        user-select: text;
        text-decoration: none;
      }
    }
    @keyframes kefuRise {
      0%{transform: translateY(100%)}
      100%{transform: translateY(0%)}
    }
  }
}
</style>