<template>
  <div class="report" :class="theme" ref="report">
    <div class="top">
      <header>
        <img :src="avatar" class="head-img">
        <div class="user">
          <div>{{name}} <span>- 报告分析</span></div>
          <div class="time">{{$formatTime(endTime,'y-m-d h:m')}}<span>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;版本号 v{{version}}</span></div>
        </div>
        <div v-if="!long" class="btn" @click="save">报告长图</div>
      </header>
      <nav v-if="!long">
        <span :class="{current:tabId==0}" @click="tab(0)">整体报告</span>
        <span :class="{current:tabId==1}" @click="tab(1)">工作价值倾向</span>
        <span :class="{current:tabId==2}" @click="tab(2)">原动力</span>
        <span :class="{current:tabId==3}" @click="tab(3)">职业素质</span>
        <span :class="{current:tabId==4}" @click="tab(4)">职业幸福指数</span>
      </nav>
    </div>
    <ul class="content">
      <!-- 整体报告 -->
      <div class="title" v-if="long" style="margin-top:10px">整体报告</div>
      <li class="whole" v-show="tabId==0">
        <div class="view">
          <h3>[工作能量]</h3>
          <div class="barBox">
            <div class="bar" :class="{barNoMove:long}" :style="{width:barWidth+'%'}"><span>{{work_capacity[0].work_capacity}}</span></div>
            <b style="left:50%">{{work_capacity[0].work_capacity_content}}</b>
          </div>
          <div class="text">当下，您的工作能量水平<b class="blue">{{work_capacity[0].work_capacity}}</b>，能量等级为<b class="blue">{{work_capacity[0].work_capacity_content}}</b></div>
        </div>

        <div class="view">
          <h3>[原动力&职业素质]</h3>
          <pieChart ref="pie" :width="width*.75" :score="pie.score" :name="pie.name"></pieChart>
          <p v-html="compareText"></p>
        </div>

        <div class="view">
          <h3>[工作价值倾向]</h3>
          <div class="wave">
            <waveBall :width="width*.28" :type="3" :name="work_value[0].name"></waveBall>
          </div>
          <p>当下，<b class="blue">“{{work_value[0].name}}”</b>是您最关注的内在需求，工作中它是您产生动力的触发点，也是快乐工作的根源。</p>
        </div>
      </li>
      <!-- 工作价值倾向 -->
      <div class="title" v-if="long">工作价值倾向</div>
      <li class="values" v-show="tabId==1 || long">
        <div class="view">
          <div class="rank" :class="{rankNoMove:long}">
            <div class="box box1" @click="showIndex=0">{{work_value[0]?work_value[0].name:' '}}<span>NO.1</span></div>
            <div class="box box2" @click="showIndex=1">{{work_value[1]?work_value[1].name:' '}}<span>NO.2</span></div>
            <div class="box box3" @click="showIndex=2">{{work_value[2]?work_value[2].name:' '}}<span>NO.3</span></div>
            <h2 v-if="!long">点击维度名称可查看说明</h2>
            
            <div class="tip" v-if="showIndex!=null">
              <h6>{{work_value[showIndex].dimension_detail}}</h6>
              <div class="close" @click="showIndex=null">关 闭</div>
            </div>
          </div>
          <h4>从您的答题选项中，反映出您当下最看重的工作价值是：<span v-for="(it,i) in work_value" :key="i">{{it.name}}{{i!=work_value.length-1?'、':''}}</span></h4>
          <p v-for="(item,index) in work_value" :key="index">{{item.content}}</p>
        </div>
      </li>
      <!-- 原动力 -->
      <div class="title" v-if="long">原动力</div>
      <li class="motivity" v-show="tabId==2 || long">
        <div class="view">
          <div class="scoreBar">
            <span class="left"></span>
            <div class="bar" :class="{barNoMove:long}" :style="{width:`${motive_power[0].score}%`,'animation-name':long?'':'width'}"></div>
            <span class="right"></span>
          </div>
          <h1>当下，您的原动力水平为 <span>{{motive_power[0].score}}</span></h1>
          
          <barRow :data="motive_power[1]" :color-type="1"></barRow>
          <div class="risk" v-if="motive_power.risk_dimension">
            <h4>可能影响：</h4>
            <h5 v-for="item in motive_power.risk_dimension" :key="item.id"><b>{{item.name}}：</b>{{item.content}}</h5>
          </div>
          <div class="risk" v-if="motive_power.norisk_dimension">
            <h4>特征描述：</h4>
            <div v-for="item in motive_power.norisk_dimension" :key="item.id">
              <h6>{{item.name}}</h6>
              <h5 class="dot" v-for="(it,j) in item.content" :key="j">{{it}}</h5>
            </div>
          </div>
        </div>
      </li>
      <!-- 职业素质 -->
      <div class="title" v-if="long">职业素质</div>
      <li class="motivity" v-show="tabId==3 || long">
        <div class="view">
          <div class="scoreBar scoreBar2">
            <span class="left"></span>
            <div class="bar" :class="{barNoMove:long}" :style="{width:`${basic_quality[0].score}%`,'animation-name':long?'':'width'}"></div>
            <span class="right"></span>
          </div>
          <h1>当下，您的职业素质水平为 <span style="color:#fd775f">{{basic_quality[0].score}}</span></h1>
          
          <barRow :data="basic_quality[1]" :color-type="2"></barRow>
          <div class="risk" v-if="basic_quality.risk_dimension">
            <h4>可能影响：</h4>
            <h5 v-for="item in basic_quality.risk_dimension" :color-type="1" :key="item.id"><b>{{item.name}}：</b>{{item.content}}</h5>
          </div>
          <div class="risk" v-if="basic_quality.norisk_dimension">
            <h4>特征描述：</h4>
            <div v-for="item in basic_quality.norisk_dimension" :key="item.id">
              <h6>{{item.name}}</h6>
              <h5 class="dot" v-for="(it,j) in item.content" :key="j">{{it}}</h5>
            </div>
          </div>
        </div>
      </li>
      <!-- 职业幸福指数 -->
      <div class="title" v-if="long">职业幸福指数</div>
      <li class="motivity" v-if="tabId==4 || long">
        <div class="view">
          <plate :width="width*.7" :animate="!long" :data="happiness_index.index" :scale="[50,70,80,100.1]"/>
          <h1 v-if="happiness_index.index<50">您目前的职业幸福指数<span>尚待提高</span></h1>
          <h1 v-else-if="happiness_index.index<70">您目前的职业幸福指数<span>一般</span></h1>
          <h1 v-else-if="happiness_index.index<80">您目前的职业幸福指数<span>较高</span></h1>
          <h1 v-else>当下已经最好，可以带领身边人幸福的工作，但还有改善空间，请继续努力</h1>
          <div class="risk" v-if="happiness_index.dim_content.length">
            <h4>改善建议：</h4>
            <h5 class="dot" v-for="item in happiness_index.dim_content" :color-type="1" :key="item.id">{{item}}</h5>
          </div>
        </div>
      </li>
    </ul>
    <div class="img-box" v-if="reportImgShow">
      <p class="text">长按保存图片</p>
      <div class="inner-box"><img :src="reportImg"></div>
      <img @click="closeImg" class="close" src="../../../assets/images/icons/close.png"/>
    </div>
  </div>
</template>
<script>
import pieChart from '../vigour/chart/pieChart'
import waveBall from '../vigour/chart/waveBallChart'
import barRow from '../vigour/chart/barRowChart'
import plate from './chart/plate.vue'
import html2canvas from 'html2canvas'
export default {
  components:{pieChart,waveBall,barRow,plate},
  props:{
    exam_id: {default: null},
    hy_session: {default: null},
    version: {default: ''}
  },
  data(){
    return{
      theme: this.$route.query.theme || '',
      width: 0,
      tabId: 0,
      // exam_id: this.$route.params.exam_id || '',
      origin: this.$route.query.origin || 'mobile',       //
      long:this.$route.query.long===undefined?false:true, //是否报告长图
      name: "正在加载",                                    //用户姓名
      avatar: require('../../../assets/images/icons/user_icon.png'),
      endTime: 0,                                         //报告完成时间
      reportImg:'',
      reportImgShow: false,
      timer:'',
      
      //工作能量(work_capacity)：
        work_capacity:[
          {work_capacity:0,work_capacity_content:''},
          {active_energy:[],potential_energy:[]},
          [],[]
        ],
        pie:{name:'',score:[]},//8份扇形的百分比
        barWidth: 0,
        saveWidth: 0,
      //工作价值倾向：
        work_value:[{name:'',}],
        showIndex:null,
      //原动力：
        motive_power:{
          0:{score:0},
          1:[],
          2:[[],[]],
          risk_dimension: '',
          norisk_dimension: '',
        },
      //职业素质：
        basic_quality:{
          0:{score:0},
          1:[],
          2:[[],[]],
          risk_dimension: '',
          norisk_dimension: '',
        },
      //思考与建议：
        suggestion:[
          {active_energy:[],potential_energy:[]},
          {tier:'',time_manage:'',work_concept:'',work_performance:''},
          []
        ],
      //职业幸福指数
        happiness_index: {
          index: '',
          content: '',
          dim_content: []
        }
    }
  },
  created(){
    this.$indicator.open({text: '加载中...',spinnerType: 'fading-circle'});
    this.$http.get(this.spaceUrl+'/Tvs/getExamReportInfo',{
      params:{
        origin: this.origin,
        exam_id: this.exam_id,
        hy_session: this.hy_session,
      }
    }).then((e) =>{
      let data = e.data.data
      if (e.data.code == 0) {
        //个人信息
        this.name = data.user_info.realname || data.user_info.nickname
        this.avatar = data.user_info.avatar
        this.endTime = data.user_info.end_time
        //工作能量
        this.work_capacity = data.work_capacity
        this.totalScore = data.work_capacity[0].work_capacity
        this.wordLevel = data.work_capacity[0].work_capacity_content
        let radar = data.work_capacity[3], total = 1;
        this.pie.score = [radar[2], radar[1]]
        this.pie.name = radar[0].name
        setTimeout(() => {
          this.barWidth = this.saveWidth = this.totalScore
        }, 100)
        //原动力
        this.motive_power = data.motive_power
        //职业素质
        this.basic_quality = data.basic_quality
        //工作价值倾向
        this.work_value = data.work_value
        //思考与建议：
        this.suggestion = data.consideration_and_suggestion
        //职业幸福指数
        this.happiness_index = data.happiness_index
      } else {
        this.name = '无法加载'
        this.$toast({message: '加载失败', duration: 1000})
      }
      this.$indicator.close();
    }).catch((err)=>{
      this.name = '无法加载'
      this.$toast({message: err})
      this.$indicator.close();
    })
  },
  mounted(){
    this.width = $('#app').width()
  },
  methods:{
    tab(e){
      this.$refs.pie.showIndex = null
      this.showIndex = null
      if(this.tabId==e) return;
      this.tabId = e;
      this.barWidth = 0
      switch(e){
        case 0:
          setTimeout(()=>{this.barWidth = this.saveWidth},50)
          break;
        default:
          break;
      }
    },
    save(){
      if (this.reportImg)
        this.reportImgShow = true
      else
        this.$http.get(this.spaceUrl+'/Tvs/getReportImage?hy_session='+this.hy_session+'&exam_id='+this.exam_id).then(d =>{
          if (d.data.code == 0) {
            this.reportImg = d.data.data.report_image
            this.reportImgShow = true
          } else if (d.data.code == 1) {
            var url = location.href.replace(/http:\/\/localhost/,this.mobileUrl) + (location.hash.includes('?')?'&':'?') + 'long&ver=' +this.version
            // var url = 'https://m.huayanspace.com/#/'
            console.log(url)
            this.$indicator.open({text: '图片生成中...',spinnerType: 'triple-bounce'});
            this.$http.post(this.ajaxUrlPath+'/CardImage/createshotImg',{
              type: 'TVSreport',
              imageurl: url,
              width: '500',
              height: '1000',
            }).then(e => {
              this.$indicator.close()
              if (e.data.code == 0) {
                this.reportImg = e.data.data.image
                this.reportImgShow = true
                //保存当前报告的图片
                // this.$http.post(this.spaceUrl+'/Tvs/updateReportImage?hy_session='+this.hy_session,{
                //   exam_id: this.exam_id,
                //   report_image: this.reportImg,
                // })
              } else {
                this.$toast({message: e.data.msg})
              }
            })
          }
        })
    },
    closeImg(){
      this.reportImgShow = false
      this.timer && clearInterval(this.timer)
    },
  },
  computed:{
    compareText(){
      let diff = this.motive_power[0].score - this.basic_quality[0].score
      if (diff > 0) {
        return '当下，您的<b style="color:#54a7e9">“原动力”</b>高于<b style="color:#fe7b14">“职业素质”</b>，需要加强技能训练和刻意练习，提升工作品质和效率是改善的重点。'
      } else if (diff < 0) {
        return '当下，您的<b style="color:#fe7b14">“职业素质”</b>高于<b style="color:#54a7e9">“原动力”</b>，挖掘内在动力，加强心态管理，找到工作的意义和价值是改善的重点。'
      } else if(this.motive_power[0].score > 74) {
        return '当下，您的“原动力”跟“职业素质”相当，请保持现有状态，并不断突破成长！'
      } else {
        return '当下，您的“原动力”跟“职业素质”相当，对内需要关注动力的挖掘，在外需要加强技能训练。'
      }
    },
  },
}
</script>
<style scoped lang="scss">
.report{
  color:#4d4d4d;
  padding-bottom: 40px;
  min-height: 100%;
  box-sizing: border-box;
  .hide{height: 0;overflow: hidden;position: absolute;}
  .top{
    // position: fixed;
    // top: 0;
    // left: 0;
    // z-index: 99;
    width: 100%;
    background: #fff;
    color: #aaa;
    header{
      display: flex;
      align-items: center;
      font-size: 26px;
      padding: 0 30px;
      height: 120px;
      .head-img{
        width: 76px;
        height: 76px;
        border-radius: 50%;
        overflow: hidden;
        display: block;
        margin-right: 20px;
        background: #ccc;
      }
      .user{
        text-align: left;
        flex: 1;
        .time{font-size: 24px;}
        span{font-size: 24px;}
      }
      .btn{
        width: 150px;
        color: #fff;
        border-radius: 4px;
        background: #2384ff;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    nav{
      display: flex;
      border-top: 1px solid #f0f0f0;
      border-bottom: 1px solid #f0f0f0;
      color: #999;
      height: 70px;
      line-height: 70px;
      box-sizing: border-box;
      align-items: center;
      overflow: hidden;
      &::-webkit-scrollbar{display: none}
      span{
        flex-shrink: 0;
        font-size: 24px;
        padding: 0 18px;
        box-sizing: border-box;
        white-space: nowrap;
        transition: .1s;
        border-right: 1px solid #f0f0f0;
        &:last-child{
          border: none;
          flex: 1;
        }
      }
      .current{
        color: #4d4d4d;
        font-weight: bold;
        background: #cdebff;
      }
    }
  }
  .content{
    overflow-x: hidden;
    .title{
      line-height: 80px;
      font-weight: bold;
      font-size: 30px;
      margin-top: 100px;
      color: #272727;
    }
    li{
      margin: 0 30px;
      p{
        font-size: 28px;
        color: #6f6f6f;
        padding-left: 30px;
        position: relative;
        text-align: justify;
        line-height: 1.44;
        margin-bottom: 6px;
        &::before{
          content: '';
          position: absolute;
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background: #6f6f6f;
          left: 6px;
          top: 14px;
        }
      }
      .text{
        font-weight: bold;
        font-size: 28px;
      }
      .blue{color: #2384ff;}
      .orange{color: #fe7b14;}
      .nop::before{display: none}
    }
    .view{
      margin: 30px 0;
      padding: 1px 20px 80px;
      border-radius: 10px;
    }
    .whole{
      .barBox{
        position: relative;
        height: 36px;
        width: 84%;
        margin: 120px auto;
        border: 1px solid #e5e5e5;
        background: #fafafa;
        &::before{
          content: '';
          position: absolute;
          width: 54px;
          height: 54px;
          border-radius: 50%;
          border: 1px solid #e5e5e5;
          background: #fff;
          left: 0;
          top: 50%;
          transform: translate(-50%,-50%);
          z-index: 5;
        }
        &::after{
          content: '';
          position: absolute;
          width: 54px;
          height: 54px;
          border-radius: 50%;
          border: 1px solid #e5e5e5;
          background: #fff;
          left: 100%;
          top: 50%;
          transform: translate(-50%,-50%);
          z-index: 5;
        }
        .bar{
          height: 100%;
          background-image: linear-gradient(to right, #7eccff , #4145ed);
          width: 0%;
          border-radius: 18px;
          position: relative;
          transition: width .6s;
          span{
            position: absolute;
            font-size: 28px;
            right: 0;
            bottom: 130%;
            font-weight: bold;
          }
        }
        .barNoMove{transition: 0s;}
        i{
          position: absolute;
          border-left: 2px solid #b3b3b3;
          height: 10px;
          top: 130%;
          left: 25%;
        }
        b{
          position: absolute;
          font-size: 24px;
          top: 150%;
          left: 12.5%;
          font-weight: normal;
          transform: translateX(-50%);
        }
      }
      h3{
        font-size: 28px;
        margin: 50px 0;
      }
      .wave{
        // display: flex;
        // justify-content: space-around;
        margin: 20px 0 50px;
      }
    }
    .energy{
      padding-bottom: 30px;
      .total-score{
        width: 330px;
        height: 330px;
        border: 1px solid #fecc65;
        border-radius: 50%;
        margin: 70px auto 40px;
        position: relative;
        h1{
          font-size: 68px;
          color: #2da9f7;
          padding: 80px 0 4px;
          font-weight: bold;
        }
        .battery{
          width: 154px;
          height: 72px;
          padding:6px 24px 6px 6px;
          margin: 0 auto;
          background: url('../../../assets/images/trump/battery.png') no-repeat;
          background-size: 100% 100%;
          display: flex;
          i{
            width: 20%;
            border: 1px solid #fff;
            background: #f8c949;
            border-radius: 6px;
            animation: opacity .15s linear;
            opacity: 0;
            animation-fill-mode:forwards;
            &:nth-child(2){background: #f99351;animation-delay: .15s;}
            &:nth-child(3){background: #7991fd;animation-delay: .3s;}
            &:nth-child(4){background: #cc6ef4;animation-delay: .45s;}
            &:nth-child(5){background: #fa6e7d;animation-delay: .6s;}
          }
          @keyframes opacity {
            100%{opacity: 1;}
          }
        }
        &::after{
          content: '';
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;left: 0;
          background: url('../../../assets/images/trump/score-bg.png') no-repeat;
          background-size: 92% 92%;
          background-position: center;
          animation: rotate 100s linear infinite;
        }
      }
      .level{
        font-size: 30px;
        h2{
          line-height: 2.2;
          font-weight: bold;
        }
        h3{
          line-height: 1.2;
          font-weight: bold;
        }
        b{color: #2da9f7;}
        span{color: #1a1a1a}
      }
      h4{
        font-size: 30px;
        text-align: justify;
        font-weight: bold;
        margin: 30px 0;
      }
      .h4{color: #2da9f7}
      @keyframes rotate {
        100%{transform: rotate(360deg);}
      }
    }
    .motivity{
      h1{
        line-height: 1.4;
        font-weight: bold;
        font-size: 30px;
        margin: 40px 0 90px;
        span{color: #2da9f7}
      }
      .fan-box{
        margin: 30px 0;
      }
      .scoreBar{
        height: 44px;
        width: 540px;
        margin: 120px auto 40px;
        position: relative;
        box-sizing: border-box;
        border: 1px solid #2272eb;
        border-image: linear-gradient(to right,#2ecfc4,#2272eb) 1 10;
        .bar{
          height: 100%;
          width: 0;
          box-sizing: border-box;
          background-image: linear-gradient(to right,#2ecfc4,#2272eb);
          border-radius: 25px;
          transition: .6s forwards;
          // animation-duration: .6s;
          // animation-fill-mode: forwards;
        }
        .barNoMove{transition: 0s;}
        span{
          position: absolute;
          width: 60px;
          height: 60px;
          border-radius: 50%;
          top: -12px;
          background: #fff;
          box-sizing: border-box;
          border: 1px solid red;
          z-index: 2;
        }
        .left{
          left: -40px;
          border-color: #2eccc5;
        }
        .right{
          right: -40px;
          border-color: #2272eb;
        }
      }
      .scoreBar2{
        border-image: linear-gradient(to right,#f9c144,#fe5c68) 1 10;
        .bar{background-image: linear-gradient(to right,#f9c144,#fe5c68)}
        .left{border-color: #f9c144;}
        .right{border-color: #fe5c68;}
      }
    }
    .values{
      .rank{
        position: relative;
        width: 388px;
        height: 380px;
        margin: 60px auto 120px;
        color: #fff;
        font-size: 26px;
        .box{
          position: absolute;
          box-sizing: border-box;
          border-radius: 50%;
          border: 6px solid #f2f2f2;
          line-height: 1.6;
          font-weight: bold;
          span{display: block;font-weight: normal}
        }
        .box1{
          width: 288px;
          height: 288px;
          padding-top: 50px;
          top: 0;
          left: 20px;
          background: #3fb3ff;
          animation: box1 .8s forwards;
        }
        .box2{
          width: 240px;
          height: 240px;
          padding-top: 76px;
          bottom: 20px;
          right: 0;
          background: rgba(78,206,157,0.9);
          animation: box2 1s forwards;
        }
        .box3{
          width: 180px;
          height: 180px;
          padding-top: 48px;
          bottom: 0;
          left: 0;
          background: rgba(249,199,103,0.9);
          animation: box3 1.4s forwards;
        }
        @keyframes box1 {
          0%{opacity: 0;top: 20px}
          95%{opacity: 1;top: 0}
        }
        @keyframes box2 {
          0%{opacity: 0;bottom: -20px}
          12%{opacity: 0;bottom: -20px}
          95%{opacity: 1;bottom: 20px}
        }
        @keyframes box3 {
          0%{opacity: 0;bottom: -20px}
          24%{opacity: 0;bottom: -20px}
          95%{opacity: 1;bottom: 0}
        }
        h2{
          position: absolute;
          font-size: 24px;
          color: #666;
          left: 50%;
          bottom: -70px;
          white-space: nowrap;
          transform: translateX(-50%);
        }
        .tip{
          position: absolute;animation: dimFade .4s forwards;
          width: 540px;padding: 30px 40px 10px;
          background: #fff;border-radius: 10px;
          left: 50%;top: 46.8%;transform: translate(-50%,-50%);
          box-shadow: 0 0 6px 2px rgba(200,200,200,.3);
          h5{
            font-size: 28px;
            color: #333;
            line-height: 2;
          }
          h6{
            text-align: justify;
            font-size: 24px;
            color: #4d4d4d
          }
          .close{
            width: 100px;
            color: #2da9f7;
            height:40px;
            display: flex;
            align-items: center; 
            justify-content: center;
            margin: 20px auto 10px;
            border-radius: 6px;
          }
        }
      }
      .rankNoMove .box{animation-duration: 0s;}
      h4{
        font-size: 30px;
        text-align: justify;
        font-weight: bold;
        margin-bottom: 24px;
        span{color: #2384ff;}
      }
      p{
        font-size: 28px;
        color: #6f6f6f;
        padding-left: 30px;
        position: relative;
        text-align: justify;
        margin-bottom: 6px;
        &::before{
          content: '';
          position: absolute;
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background: #6f6f6f;
          left: 6px;
          top: 14px;
        }
      }
    }
    .risk{
      font-size: 28px;
      text-align: left;
      margin: 40px 0;
      h4{
        color: #d81e06;
        padding-left: 40px;
        font-weight: bold;
        margin-bottom: 20px;
      }
      h5{
        color: #6f6f6f;
        padding-left: 40px;
        position: relative;
        margin: 6px 0;
        &::before{
          content: '';
          background: url('../../../assets/images/report/risk.png') no-repeat;
          position: absolute;
          background-size: 100%;
          width: 28px;
          height: 28px;
          top: 2px;
          left: 0px;
        }
      }
      h6{
        font-weight: bold;
        padding-left: 40px;
      }
      .dot::before{
        background: #666;
        width: 10px;
        height: 10px;
        border-radius: 50%;
        top: 13px;
        left: 12px;
      }
      b{color: #4d4d4d}
    }
  }
  .img-box{
      width: 100%;
      height: 100%;
      position: fixed;
      background: rgba(0,0,0,0.8);
      top: 0;
      left: 0;
      z-index: 9999;
      font-size:30px;
      .text{line-height: 100px;color:#fff;}
      .inner-box{
        flex: 1;
        height: 80%;
        margin:0 30px 20px;
        overflow-y: auto;
        img{width: 100%;height: auto;display: block;}
      }
      .close{
        display: block;
        width: 70px;
        height: 70px;
        margin:0 auto;
      }
  }
}
.planet{
  background-color: #2da8ff;
  background-image: url('../../../../static/img/planet_bg14.jpg');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  .top{
    background: none;
    color: #fff;
    nav{
      color: #fff;
      border-color: #fff;
      .current{
        background: #c0e9fa;
        color: #414b5e;
      }
    }
  }
  .content{
    .title{color: #fff}
    .view{
      background: rgba(255,255,255,.8);
    }
    .whole{
      h3{color: #414b5e}
    }
    .values{
      h4,p{color: #414b5e}
      p::before{background: #414b5e}
    }
    .risk{
      h5,h6{color: #414b5e}
      // h5::before{background: #414b5e}
    }
  }
}
</style>