<template>
  <svg class="meter">
    <g class="bg"></g>
    <g class="data"></g>
    <defs>
      <linearGradient id="meter0" x1="0%" y1="0%" x2="100%" y2="0%">
        <stop offset="0%" stop-color="#62dce0"/>
        <stop offset="100%" stop-color="#11b6bc "/>
      </linearGradient>
      <linearGradient id="meter1" x1="0%" y1="0%" x2="100%" y2="0%">
        <stop offset="0%" stop-color="#bae3a0"/>
        <stop offset="100%" stop-color="#7cbe54"/>
      </linearGradient>
      <filter id="meterShadow" x="0" y="0" width="200%" height="200%">
        <feOffset result="offOut" in="SourceGraphic" dx="4" dy="" />
        <feGaussianBlur result="blurOut" in="offOut" stdDeviation="10" />
        <feBlend in="SourceGraphic" in2="blurOut" mode="normal" />
      </filter>
    </defs>
  </svg>
</template>

<script>
import * as d3 from 'd3'
export default {
  props: {
    data: {
      default: () => {}
    },
    type: {
      type: Number,
      default: 0,
    },
    width: {    //宽高度
      type: Number,
    },
    animate:{
      type: Boolean,
      default: false,
    },
  },
  data(){
    return{
      color: ['#74d4d8','#98d373'],
      bgColor: ['#ddf6f7','#e8f2e2'],
    }
  },
  mounted(){
    if (this.data.name){
      this.draw()
    } else {
      this.$watch('data', () => this.draw())
    }
  },
  methods:{
    getRing(r,agl){
      agl = agl/360 * 2 * Math.PI
      return [this.cen - Math.sin(agl) * r, this.cen + Math.cos(agl) * r]
    },
    draw(){
      let svg = d3.select(this.$el).attr('width', this.width).attr('height', this.width)
      let top = this.width * 0.2,             //顶部留空
          bottom = this.width * 0.27,         //底部留空
          fontSize = this.width * 0.11,       //文字大小
          R = this.width * .42,               //最大外圆
          cen = this.cen = this.width / 2,
          bgColor = this.bgColor[this.type],
          color = this.color[this.type],
          bg = svg.select('.bg');
      bg.append('circle')//外圆
        .attr('cx', cen)
        .attr('cy', cen)
        .attr('r', R)
        .attr('stroke-width', 4)
        .attr('stroke', bgColor)
        .attr('fill', 'none');
      bg.append('circle')//内圆
        .attr('cx', cen)
        .attr('cy', cen)
        .attr('r', R * .8)
        .attr('stroke', 'none')
        .attr('fill', bgColor);
      bg.append('text') //分数文字
        .attr('font-size', fontSize * .94)
        .attr('fill', color)
        .attr('x', cen)
        .attr('y', this.width - bottom - fontSize*.8)
        .text(this.data.score)
      for (let i = 6; i < 31; i++) {  //刻度
        let p1 = this.getRing(R * .84, 10 * i)
        let p2 = this.getRing(R  * (i%3==0 ? .92 : 0.88), 10 * i)
        bg.append('path')
          .attr('stroke-width', 1)
          .attr('stroke', color)
          .attr('fill', 'none')
          .attr('d', `M${p1[0]} ${p1[1]} L${p2[0]} ${p2[1]}`)
      }

      let data = svg.select('.data')
      data.append('path')//指针
          .attr('stroke', 'none')
          .attr('fill', '#323232')
          .attr('d', `M${cen} ${cen-R*.8} L${cen-2} ${cen+2} A2 2 0 1 0 ${cen+2} ${cen}`)
          .attr('transform', () => {
            if (this.animate) return `rotate(-120,${cen},${cen})`
            else return `rotate(${this.data.score * 2.4 - 120},${cen},${cen})`
          })
          //指针动画
          .append('animateTransform')
          .attr('attributeName', 'transform')
          .attr('type', 'rotate')
          .attr('fill', 'freeze')
          .attr('dur', '.6s')
          .attr('from', `-120 ${cen} ${cen}`)
          .attr('to', `${this.data.score * 2.4 - 120} ${cen} ${cen}`)
      
      let start = this.getRing(R, 59),
          angle = this.data.score * 2.4 + 60,
          end = this.getRing(R, angle),
          girth = (angle - 60) / 180 * R * Math.PI,
          dPath = `M${start[0]} ${start[1]} A${R} ${R} 0 ${angle>240?1:0} 1 ${end[0]} ${end[1]}`
      data.append('path')//外环线
          .attr('stroke-width', 4)
          .attr('stroke', `url(#meter${this.type})`)
          .attr('fill', 'none')
          .attr("stroke-dasharray", `${girth},${girth*3}`)
          .attr("stroke-dashoffset", `${girth}`)
          .attr('d', dPath)
          //环形动画
          .append('animate')
          .attr('attributeName', 'stroke-dashoffset')
          .attr('fill', 'freeze')
          .attr('dur', '.6s')
          .attr('from', '100%')
          .attr('to', '0%')
      
      let smallDot = data.append('circle')//分数当前位置圆点
      smallDot.attr('r', 4)
              .attr('stroke-width', 1.6)
              .attr('stroke', '#fff')
              .attr('fill', color)
              .attr('filter', 'url(#meterShadow)')
      if (this.animate) {
        smallDot.append('animateMotion').attr('path', dPath).attr('fill', 'freeze').attr('dur', '.6s')
      } else {
        smallDot.attr('cx', end[0]).attr('cy', end[1])
      }

      data.append('polyline') //底座横线
          .attr('fill', 'none')
          .attr('stroke', '#e5e5e5')
          .attr('stroke-width', 7)
          .attr('points', `0,${this.width-bottom} ${this.width},${this.width-bottom}`)
      let botW = this.width - bottom / 2
      data.append('polyline') //底部白底遮挡
          .attr('fill', 'none')
          .attr('stroke', '#fff')
          .attr('stroke-width', bottom)
          .attr('points', `0,${botW} ${this.width},${botW}`)
      data.append('text') //底部标签文字
          .attr('font-size', fontSize)
          .attr('fill', color)
          .attr('x', cen)
          .attr('y', this.width - bottom + fontSize)
          .text(this.data.name)
    },
  },
}
</script>
<style scope lang="scss">
.meter{
  display: inline-block;
  text{
    text-anchor: middle;
    dominant-baseline: middle;
  }
}
</style>
