<template>
  <div class="login" @click="countryCodeShow=false">
    <ul class="container">
      <li :class="{curr: focusType == 1}">
        <h1>请输入手机号</h1>
        <div class="row" :class="{error:errType==1}">
          <div class="country_code flex_cen_cen" @click.stop="countryCodeShow=!countryCodeShow">
            {{country.label}}<span class="flex_cen_cen">></span>
            <div class="code_box" :style="`transform:scaleY(${countryCodeShow?1:0})`">
              <p v-for="item in $country" :key="item.country_code" @click="selectCountry(item)">{{item.label}}</p>
            </div>
          </div>
          <input type="number" @focus="focusType=1;errType=0" @blur="focusType=0" v-model="mobile"  @input="mobile.length>5?mobile=mobile.slice(0,11):mobile">
          <div class="err">{{errText}}</div>
        </div>
      </li>
      <li :class="{curr: focusType == 2}">
        <h1>请输入验证码</h1>
        <div class="row" :class="{error:errType==2}">
          <input style="padding:0 5px" type="number" @focus="focusType=2;errType=0" @blur="focusType=0" v-model="captcha"  @input="captcha.length>5?captcha=captcha.slice(0,6):captcha">
          <div class="get_btn disable" v-if="disabled">重新发送{{second}}s</div>
          <div class="get_btn" @click="getCaptcha" v-else>获取验证码</div>
          <div class="err">{{errText}}</div>
        </div>
      </li>
    </ul>
    <div class="btn flex_cen_cen" @click="login">登 录</div>
  </div>
</template>
<script>
export default {
  data(){
    return {
      focusType: 0,
      mobile: '',
      captcha: '',
      errType: 0,
      errText: '',
      countryCodeShow: false,
      country: {label: '+86', country_code: '86', reg: /^1[3-9]{1}[0-9]{1}[0-9]{8}$/},
      disabled: false,
      second: 0,
    }
  },
  created(){
    document.title = '新用户注册'
  },
  mounted(){},
  methods:{
    selectCountry(e){
      this.country = e
    },
    //检验手机号
    check(){
      return new Promise((resolve)=>{
        if (!this.mobile) {
          this.errText = '请先输入手机号码';
          this.errType = 1;
          resolve(0)
        } else if (!this.country.reg.test(this.mobile)){
          this.errText = '手机号码格式不对';
          this.errType = 1;
          resolve(0)
        } else {
          this.$http.post(this.spaceUrl+'/User/checkMobile',{
            mobile: this.mobile
          }).then(({data}) => {
            if (data.code == 1) {
              resolve(1)
            } else if (data.msg == '手机号已注册') {
              resolve(2)
            } else {
              this.errText = data.msg;
              this.errType = 1;
              resolve(0)
            }
          },()=>resolve(0))
        }
      })
    },
    getCaptcha(){
      this.check().then((e) => {
        if (e == 1) {
          this.$indicator.open({text: '发送中...',spinnerType: 'fading-circle'});
          this.$http.post(this.spaceUrl+'/sms/getSmsCaptcha',{
            type: 'register',
            mobile: this.mobile,
            country_code: this.country.country_code,
          }).then(({data}) => {
            this.$indicator.close();
            if (data.code == 0) {
              let time = 60
              this.second = time
              this.disabled = true
              let timer = setInterval(() => {
                this.second -= 1;
                if (this.second < 0) {
                  clearInterval(timer)
                  this.disabled = false
                }
              }, 1000)
            } else {
              this.$toast({message:data.msg})
            }
          }, () => {
            this.$toast({message:'网络错误'});
            this.$indicator.close();
          })
        }
      })
    },
    login(){
      this.check().then((e) => {
        if (!this.captcha) {
          this.errText = '请先输入验证码';
          this.errType = 2;
          return;
        }
        if (e == 1) { //新用户注册
          this.$indicator.open({text: '登录中...',spinnerType: 'fading-circle'});
          this.$http.post(this.spaceUrl+'/User/register', {
            captcha: this.captcha,
            mobile: this.mobile,
            country_code: this.country.country_code,
          }).then(({data}) => {
            this.$indicator.close();
            if (data.code == 0) {
              this.$toast({message:'注册成功'});
              let path = sessionStorage.wxPayProductId ? '/wxPay/index' : '/wxPay'
              this.$router.replace({path: path})
            } else {
              this.$toast({message:data.msg})
            }
          }, () => {
            this.$toast({message:'网络错误'});
            this.$indicator.close();
          })
        } else if (e == 2) {  //用户登录
          this.$indicator.open({text: '登录中...',spinnerType: 'fading-circle'});
          this.$http.post(this.spaceUrl+'/User/login', {
            captcha: this.captcha,
            mobile: this.mobile,
            country_code: this.country.country_code,
          }).then(({data}) => {
              this.$indicator.close();
            if (data.code == 0) {
              this.$toast({message:'登录成功'});
              let path = sessionStorage.wxPayProductId ? '/wxPay/index' : '/wxPay'
              this.$router.replace({path: path})
            } else {
              this.$toast({message:data.msg})
            }
          }, () => {
            this.$toast({message:'网络错误'});
            this.$indicator.close();
          })
        }
      })
    }
  },
}
</script>
<style lang="scss" scoped>
.flex_cen_cen{
  display: flex;
  align-items: center;
  justify-content: center;
}
.login{
  padding: 120px 60px;
  text-align: left;
  height: 100%;
  .container{
    li{
      margin: 80px 0 100px;
      h1{
        font-size: 30px;
        color: #ccc;
        margin: 30px 0 10px;
      }
      .row{
        display: flex;
        align-items: center;
        border-bottom: 4px solid #ccc;
        height: 70px;
        position: relative;
        input{
          height: 60px;
          width: 100%;
          font-size: 30px;
          flex: 1;
        }
        .get_btn{
          width: 160px;
          line-height: 48px;
          background: #e6f2ff;
          color: #2e90ff;
          border-radius: 4px;
          border: 1px solid #2e90ff;
          font-size: 26px;
          text-align: center;
        }
        .disable{
          color: #999;
          border-color: #999;
          background: #f6f6f6;
        }
        .err{
          color: #ff3846;
          position: absolute;
          top: 80px;
          left: 0;
          right: 0;
          text-align: center;
          font-size: 26px;
          line-height: 1;
          display: none;
        }
      }
      .error{
        border-bottom-color: #ff3846;
        .err{display: block;}
      }
      .country_code{
        line-height: 60px;
        padding: 0 30px;
        font-size: 30px;
        color: #424242;
        position: relative;
        span{
          font-size: 28px;
          width: 40px;
          height: 40px;
        }
        .code_box{
          position: absolute;
          top: 64px;
          min-width: 120px;
          background: #fff;
          border-radius: 4px;
          box-shadow: 0 0 10px rgba(50,50,50,.4);
          font-size: 30px;
          left: 0px;
          transition: transform .1s;
          overflow: hidden;
          transform-origin: 50% 0;
          z-index: 10;
          p{
            line-height: 80px;
            white-space: nowrap;
            padding: 0 20px 0 30px;
          }
        }
      }
    }
    .curr{
      h1{
        font-weight: bold;
        color: #424242;
      }
      .row{
        border-bottom-color: #2e90ff;
      }
    }
  }
  .btn{
    color: #fff;
    background: #2e90ff;
    height: 80px;
    font-size: 30px;
    margin: 100px 0 20px;
    border-radius: 4px;
  }
}
</style>