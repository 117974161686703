<template>
  <svg class="berzier" :width="width" :height="width">
    <g class="bg"></g>
  </svg>
</template>

<script>
import * as d3 from 'd3'
export default {
  props: {
    data: {
      // default:()=>{
      //   return {
      //     121: {score: 41, name: "创始团队", },
      //     122: {score: 86, name: "创业经验"},
      //     123: {score: 70, name: "股权",},
      //     124: {score: 71, name: "项目信息",},
      //     125: {score: 53, name: "收益",},
      //     126: {iscored: 72, name: "融资",},
      //   }
      // }
    },
    width: {    //宽高度
      type: Number,
    },
    refer: {
      type: Number,
      default: 60,
    },
    animate: {
      type: Boolean,
      default: true,
    },
  },
  mounted(){
    if (this.data) {
      setTimeout(() => this.draw(this.data), 10)
    } else {
      this.$watch('data', val => this.draw(val))
    }
  },
  methods:{
    draw(e){
      var max = 0, min = 100
      for (let i in e) {
        let score = e[i].score
        if(score > max) max = score;
        if(score < min) min = score;
      }
      if (max<this.refer && min<this.refer) max = this.refer
      if (max>this.refer && min>this.refer) min = this.refer

      let svg = d3.select(this.$el)
      let left =  this.width * .1,
          top = this.width * .2,
          bottom = this.width * .2,  //与move动画的起始点对应
          fontSize = this.width * .04,
          vW = this.width - left,
          vH = this.width - top - bottom,
          bg = svg.select('.bg');
      bg.append('polyline')
        .attr('fill', 'none')
        .attr('stroke', '#b9a88c')
        .attr('stroke-width',1) 
        .attr("stroke-dasharray", "2,3")
        .attr('points', `${left},${this.width-bottom} ${this.width},${this.width-bottom}`)
      bg.append('polyline')
        .attr('fill', 'none')
        .attr('stroke', '#b9a88c')
        .attr('stroke-width',1) 
        .attr("stroke-dasharray", "2,3")
        .attr('points', `${left},${top} ${this.width},${top}`)
      bg.append('text')
        .attr('font-size', fontSize)
        .attr('fill', '#5e5341')
        .attr('x', this.width / 2 + fontSize)
        .attr('y', this.width - bottom*.3)
        .text('请关注各维度与参考线距离，以便改善和提升。')
      // 左边刻度
      let sMin = Math.floor(min/10),                //在十位的最低值
          sMax = max>=90 ? 10 : Math.ceil(max/10)+1,//在十位的最高值
          scale = (sMax - sMin) * 10,
          eachH = vH / (sMax - sMin),
          j = 0;
      // for (let i = sMax; i >= sMin; i--) {
      //   bg.append('text')
      //     .attr('font-size', fontSize)
      //     .attr('fill', '#999')
      //     .attr('x', left / 2)
      //     .attr('y', eachH * (j++) + top)
      //     .text(i*10)
      // }
      bg.append('text')
        .attr('font-size', fontSize)
        .attr('fill', '#5e5341')
        .attr('x', left / 2)
        .attr('y', this.width - bottom*.88)
        .text(sMin * 10)
      bg.append('text')
        .attr('font-size', fontSize)
        .attr('fill', '#5e5341')
        .attr('x', left / 2)
        .attr('y', top - fontSize*.3)
        .text(sMax * 10)
      //参考线
      let referY = this.width - bottom - (this.refer - sMin*10) / scale * vH
      bg.append('text')
        .attr('font-size', fontSize*.8)
        .attr('fill', '#f77c03')
        .attr('x', left/2)
        .attr('y', referY)
        .text('参考线')
      bg.append('polyline')
        .attr('fill', 'none')
        .attr('stroke', '#f77c03')
        .attr('stroke-width',1) 
        .attr("stroke-dasharray", "2,3")
        .attr('points', `${left},${referY} ${this.width},${referY}`)

      //绘制X轴元素（维度名，竖虚线，各分数X轴坐标）
      let pointX = [],                  //各x轴的坐标
          startY = this.width - bottom, //y轴开始位置
          data = svg.append('g').classed('data', true)
      // 1.绘制维度名与竖虚线
      var len = Object.keys(e).length
      let eachW = vW / len, idx = 0
      for (let key in e) {
        let x = pointX[idx] =  eachW * ( idx + .5) + left,
            text = [],
            str = e[key].name;
        idx++
        for (let k = 0; k < str.length ; k += 2) text.push(str.slice(k, k+2))
        bg.append('text')
          .attr('font-size', fontSize)
          .attr('fill', '#5e5341')
          .attr('y',  top * .3)
          .append('tspan')
          .attr('x', x)
          .text(text[0])
          .append('tspan')
          .attr('x', x)
          .attr('dy', '1.2em')
          .text(text[1])
        bg.append('polyline')
          .attr('fill', 'none')
          .attr('stroke', '#b9a88c')
          .attr("stroke-dasharray", "2,3")
          .attr('stroke-width',1)
          .attr('points', `${x},${top+1} ${x},${this.width-bottom}`)
      }
      

      // 先绘制折线 并 计算各分数值坐标
      var pArr = [], Str = '', i = 0
      for(let j in e){
        let height = (e[j].score - sMin*10) / scale * vH   /***主要计算***/
        let p = [pointX[i++], startY - height]
        e[j].p = p
        pArr.push({x:p[0],y:p[1]})
        Str += `${p[0]},${p[1]} `
      }
      data.append('path')
          .attr('fill', 'none')
          .attr('stroke', '#f4bc00')
          .attr('stroke-width', 2)
          .attr('d', getCurr(pArr))
      
      // 再绘制坐标圆点（圆点在上）
      for (let j in e) {
        let p = e[j].p
        data.append('circle')
            .attr('cx', p[0])
            .attr('cy', p[1])
            .attr('r', 4)
            .attr('fill', '#f4bc00');
        data.append('circle')
            .attr('cx', p[0])
            .attr('cy', p[1] - fontSize*1.8)
            .attr('r', fontSize)
            .attr('stroke', '#fff')
            .attr('stroke-width',1)
            .attr('fill', '#f4bc00');
        data.append('text')
            .attr('font-size', fontSize)
            .attr('fill', '#5e5341')
            .attr('font-weight', 'bold')
            .attr('x', p[0])
            .attr('y', p[1] - fontSize*1.75)
            .text(e[j].score)
      }

      //添加动画
      if(this.animate){
        data.selectAll('path').classed('move', true)
      }
      function getCurr(arr) {
        let s = ''
        for (let i = 0; i < arr.length; i++) {
          if (i == 0) {
            s = `M${arr[i].x} ${arr[i].y} `
          } else if (i == arr.length-1) {
            let k = (arr[i].y - arr[i-1].y) / (arr[i].x - arr[i-1].x)
            let x = arr[i].x - fontSize*1.2
            let y = (x - arr[i].x) * k + arr[i].y
            s += `S${x} ${y},${arr[i].x} ${arr[i].y} `
          } else {
            let x1 = arr[i-1].x, y1 = arr[i-1].y
            let x2 = arr[i+1].x, y2 = arr[i+1].y
            let k = (y2 - y1) / (x2 - x1)
            let x = arr[i].x - fontSize*1.2
            let y = (x - arr[i].x) * k + arr[i].y
            s += `S${x} ${y},${arr[i].x} ${arr[i].y} `
          }
        }
        return s
      }

    },
  },
}
</script>
<style scope lang="scss">
.berzier{
  display: block;
  margin: 0 auto;
  text{
    text-anchor: middle;
    dominant-baseline: middle;
  }
  tspan{
    text-anchor: middle;
  }
  .data{
    polyline{
      stroke-dasharray: 170%;
    }
    .move{
      stroke-dashoffset: 170%;
      animation: moveP 1s forwards;
    }
    @keyframes moveP {
      100%{stroke-dashoffset:0;}
    }
    circle{
      animation: fadeC .8s forwards linear;
      opacity: 0;
    }
    @keyframes fadeC {
      100%{opacity: 1;}
    }
  }
}
</style>
