<template>
    <div class="main">
        <div class="head">
            <div class="inner-head">
                <img :src="avatar" class="user-icon" v-if="avatar">
                <p class="name" v-if="username">{{username}}的销售潜能分析报告</p>
            </div>
        </div>
        <report_data :reportData="reportData" v-if="reportData.sale_score"></report_data>
    </div>
</template>

<script>
import report_data from './reportData'

export default {
    data(){
        return{
            exam_id: this.$route.query.exam_id,
            exam_info:{},
            reportData:{},
            avatar:'',
            username:''
        }
    },
    methods: {
        getReport(){
            this.$http.post(
                this.ajaxUrlPath+'/Sale/getReportInfo',
                {
                    exam_id: this.exam_id,
                    type:'1',
                },
                {emulateJSON:true}
            ).then(resp=>{
                if(resp.data.code == 0){
                    this.exam_info = resp.data.data.exam_info;
                    this.reportData = resp.data.data.report_data;
                    this.avatar = this.exam_info.avatar || '../../assets/images/icons/user_icon.png';
                    let username = this.exam_info.realname;
                    username = username.length>10? username.substring(0,10)+'...':username;
                    this.username = username
                }
            })
        },
    },
    mounted() {
        this.getReport();
    },
    components:{
        report_data
    }
}
</script>

<style lang="scss" scoped>
.main{
    background: #fff0e7;
    min-height: 100%;
    padding-bottom: 106px;
}
.head{
    height: 545px;
    background: linear-gradient(180deg,#ff9164,#fe5e1c);
    position: relative;
    width: 100%;
    font-size: 30px;
    color: #903005;
}
.inner-head{
    padding: 96px 90px 0 90px;
    // display: flex;
    // align-items: center;
}
.user-icon{
    width: 108px;
    height: 108px;
    vertical-align: middle;
    // float: left;
    margin-right: 26px;
    border-radius:100%;
}
.name{
    // float: left;
    width: 420px;
    vertical-align: middle;
    display: inline-block;
    text-align: left;
}
</style>