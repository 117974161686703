<template>
  <div class="organize">
    <div class="chart">
      <ul class="box">
        <li ref="li">
          <h6>A区：组织动能</h6>
          <div class="area"><i v-show="data[0].score" :style="getA(data[0].score)"/></div>
        </li>
        <li class="li2">
          <h6>B区：组织战略</h6>
          <div class="area"><i v-show="data[1].score"  :style="getB(data[1].score)"/></div>
        </li>
        <li class="li3">
          <h6>C区：组织执行</h6>
          <div class="area"><i v-show="data[2].score"  :style="getC(data[2].score)"/></div>
        </li>
        <li class="li4">
          <h6>D区：组织协作</h6>
          <div class="area"><i v-show="data[3].score"  :style="getD(data[3].score)"/></div>
        </li>
        <span class="spanTop"/>
        <span class="spanRight"/>
        <span class="spanBottom"/>
        <span class="spanLeft"/>
        <div class="circle"></div>
      </ul>
      <label class="labelTop" @click="toggle(2)">目标</label>
      <label class="labelRight" @click="toggle(1)">组织</label>
      <label class="labelBottom" @click="toggle(3)">运营</label>
      <label class="labelLeft" @click="toggle(0)">人才</label>
    </div>
    <p v-if="click">点击维度名称可查看说明</p>
    <div class="line">标准线</div>
    <div class="tip" v-if="showIndex!=null">
      <h6>{{dimension[showIndex].detail}}</h6>
      <div class="close" @click="toggle(null)">关 闭</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Array,
      default: () => {
        return [{score:0}, {score:0}, {score:0}, {score:0}]
      }
    },
    dimension:{
      type: Array
    },
    click: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      cw: 0,
      ch: 0,
      showIndex: null,
    }
  },
  mounted() {
    let w = this.cw = this.$refs.li.clientWidth
    this.ch = w * 2 / 3 //坐标落地区域240:160
  },
  methods:{
    toggle(e){
      if(this.click) this.showIndex = e
    },
    getA(score){
      let per = score / 100
      let w = this.cw - this.cw * per
      let h = this.ch - this.ch * per
      return `left:${w}px;top:${h}px`
    },
    getB(score){
      let per = score / 100
      let w = this.cw * per
      let h = this.ch - this.ch * per
      return `left:${w}px;top:${h}px`
    },
    getC(score){
      let per = score / 100
      let w = this.cw * per
      let h = this.ch * per
      return `left:${w}px;top:${h}px`
    },
    getD(score){
      let per = score / 100
      let w = this.cw - this.cw * per
      let h = this.ch * per
      return `left:${w}px;top:${h}px`
    },
  },
}
</script>
<style lang="scss" scope>
.organize{
  margin: 40px 0;
  .chart{
    width: 650px;
    height: 560px;
    margin-bottom: 30px;
    position: relative;
    .box{
      width: 510px;
      height: 450px;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      .circle{
        position: absolute;
        width: 330px;
        height: 330px;
        border: 1px dashed #1880b1;
        background: rgba(255,255,255,.3);
        border-radius: 50%;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        z-index: 5;
      }
      li{
        position: absolute;
        background: #89a9f4;
        left: 0;
        top: 0;
        border-radius: 6px;
        width: 240px;
        height: 210px;
        h6{
          color: #fff;
          font-size: 24px;
          line-height: 50px;
          padding: 0 12px;
          text-align: left;
        }
        .area{
          position: relative;
          width: 240px;
          height: 160px;
        }
        i{
          position: absolute;
          width: 18px;
          height: 18px;
          border-radius: 50%;
          background: #f77c03;
          transform: translate(-50%,-50%);
          z-index: 10;
        }
      }
      .li2{
        right: 0;
        left: auto;
        background: #a696f4;
        h6{text-align: right}
      }
      .li3{
        right: 0;
        left: auto;
        top: auto;
        bottom: 0;
        background: #87c9e6;
        h6{
          position: absolute;
          bottom: 0;
          width: 100%;
          text-align: right;
        }
      }
      .li4{
        top: auto;
        bottom: 0;
        background: #8acbaa;
        h6{
          position: absolute;
          bottom: 0;
          width: 100%;
        }
      }
      &::before, &::after{
        content: '';
        position: absolute;
        background: #a2dffa;
        z-index: 2;
      }
      &::before{
        width: 100%;
        height: 12px;
        transform: translateY(-50%);
        left: 0;
        top: 50%;
      }
      &::after{
        width: 12px;
        height: 100%;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
      }
      span{position: absolute;}
      .spanTop{
        border-left: 14px solid transparent;
        border-right: 14px solid transparent;
        border-bottom: 14px solid #a2dffa;
        top: -14px;
        left: 50%;
        transform: translateX(-50%);
      }
      .spanRight{
        border-top: 14px solid transparent;
        border-bottom: 14px solid transparent;
        border-left: 14px solid #a2dffa;
        right: -14px;
        top: 50%;
        transform: translateY(-50%);
      }
      .spanBottom{
        border-left: 14px solid transparent;
        border-right: 14px solid transparent;
        border-top: 14px solid #a2dffa;
        bottom: -14px;
        left: 50%;
        transform: translateX(-50%);
      }
      .spanLeft{
        border-top: 14px solid transparent;
        border-bottom: 14px solid transparent;
        border-right: 14px solid #a2dffa;
        left: -14px;
        top: 50%;
        transform: translateY(-50%);
      }
    }
    label{
      position: absolute;
      font-size: 24px;
      color: #414b5e;
    }
    .labelTop{
      top: 0;
      left: 50%;
      transform: translateX(-50%);
    }
    .labelLeft{
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }
    .labelRight{
      right: 0;
      top: 50%;
      transform: translateY(-50%);
    }
    .labelBottom{
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
    }
  }
  p{
    color: #738897;
    font-size: 24px;
  }
  .line{
    color: #2081a4;
    font-size: 24px;
    margin: 30px 0;
    &::before{
      content: '';
      display: inline-block;
      width: 60px;
      border-top: 1px dashed #1880b1;
      margin: 0 10px 8px 0;
    }
  }
  .tip{
    position: absolute;
    width: 520px;
    padding: 30px 32px 10px;
    background: #fff;
    border-radius: 10px;
    left: 50%;
    top: 30%;
    transform: translateX(-50%);
    box-shadow: 0 0 6px 2px rgba(200,200,200,.3);
    animation: dimTop .3s;
    z-index: 3;
    @keyframes dimTop {
      0%{opacity: 0;top: 34%;}
      100%{opacity: 1;top: 30%;}
    }
    h5{
      font-size: 28px;
      color: #333;
      line-height: 2;
    }
    h6{
      text-align: justify;
      font-size: 24px;
    }
    .close{
      width: 100px;
      color: #42b2fc;
      height:40px;
      display: flex;
      align-items: center; 
      justify-content: center;
      margin: 20px auto 10px;
      border-radius: 6px;
    }
  }
}
</style>
