<template>
  <div class="classTool">
    <div class="pic">
      <span>智慧 (+)</span>
      <span>幸福 (+)</span>
      <span>智慧 (-)</span>
      <span>幸福 (-)</span>
      <ul>
        <li>没快乐<br>有价值有意义</li>
        <li>快乐<br>有价值有意义</li>
        <li>没快乐<br>没价值没意义</li>
        <li>快乐<br>没价值没意义</li>
      </ul>
    </div>
    
    <h1>快乐有价值有意义</h1>
    <p v-html="content1"></p>
    <h1 class="h2">快乐没价值没意义</h1>
    <p>{{content2}}</p>
    <h1 class="h3">没快乐没价值没意义</h1>
    <p>{{content3}}</p>
    <h1 class="h4">没快乐有价值有意义</h1>
    <p>{{content4}}</p>
  </div>
</template>
<script>
export default {
  data(){
    return {
      id: this.$route.query.id || '',
      content1: '',
      content2: '',
      content3: '',
      content4: '',
    }
  },
  created(){
    this.$http.get(this.planetUrl+'/CourseAuxiliary/getDetail?id='+this.id).then(({data}) => {
      if (data.code == 0) {
        this.content1 = data.data.field_content1
        this.content2 = data.data.field_content2
        this.content3 = data.data.field_content3
        this.content4 = data.data.field_content4
      }
    })
  },
  mounted(){},
  methods:{},
}
</script>
<style lang="scss" scoped>
.classTool{
  padding: 1px 0;
  min-height: 100%;
  background: #1d212d;
  color: #fff;
  .pic{
    margin: 30px 30px 60px;
    padding: 60px 100px;
    background: #2d2b43;
    border-radius: 10px;
    white-space: nowrap;
    text-align: center;
    font-size: 24px;
    position: relative;
    span{
      position: absolute;
      &:nth-child(1){left: 50%;transform: translateX(-50%);top: 20px}
      &:nth-child(2){top: 50%;transform: translateY(-50%);right: 16px}
      &:nth-child(3){left: 50%;transform: translateX(-50%);bottom: 20px}
      &:nth-child(4){top: 50%;transform: translateY(-50%);left: 16px}
    }
    ul{
      &::after{
        content: '';
        clear: both;
        display: block;
      }
      li{
        margin: 10px;
        width: 225px;
        background: #7386ff;
        border-radius: 8px;
        font-size: 26px;
        padding: 30px 0;
        float: left;
        &:nth-child(2){background: #ff6600;}
        &:nth-child(3){background: #e45ca4;}
        &:nth-child(4){background: #987df6;}
      }
    }
  }
  h1{
    font-size: 26px;
    margin: 0 30px;
    line-height: 60px;
    padding-left: 30px;
    position: relative;
    text-align: left;
    &::before{
      content: '';
      position: absolute;
      width: 18px;
      height: 18px;
      border-radius: 50%;
      background: #ff6600;
      left: 0;
      top: 50%;
      margin-top: -10px;
    }
  }
  .h2::before{background: #987df6;}
  .h3::before{background: #e45ca4;}
  .h4::before{background: #7386ff;}
  p{
    display: block;
    font-size: 28px;
    margin: 10px 60px 60px;
    text-align: justify;
    word-break: break-all;
    white-space: pre-wrap;
  }
}
</style>