<!-- 传入数据说明（*代表必要元素）
  type：圆球类型（控制渐变颜色和名称）
  data*：分数
  width*：圆的宽度
  animate：是否启动动画
-->
<template>
  <svg class="ring_chart">
    <defs>
      <linearGradient :id="'ring'+type" x1="0%" y1="0%" x2="0%" y2="100%">
        <stop offset="0%" :style="'stop-color:'+startColor[type]"/>
        <stop offset="100%" :style="'stop-color:'+endColor[type]"/>
      </linearGradient>
    </defs>
  </svg>
</template>

<script>
import * as d3 from 'd3'
export default {
  props: {
    data: {
      type: Number,
    },
    width: {    //宽高度
      type: Number,
    },
    type:{
      type:Number,
      default:0
    },
    animate:{
      type:Boolean,
      default:false,
    },
  },
  data(){
    return{
      svg:'',
      center: 0,
      ringWidth: 0,
      radius: 0,
      ringColor: ['#faca66','#faca66','#5abdfe'],
      startColor: ['#ff3d94','#fe7218','#42a6e8'],
      endColor: ['#ffa603','#feb201','#6d39d7'],
    }
  },
  mounted(){
    if (this.width) {
      this.init()
    } else {
      this.$watch('width', () => {
        this.init()
      })
    }
    if (this.data) { //本地测试(保存时浏览器自动执行)
      this.drawData(this.data)
    } else { //实际传入数据:
      this.$watch('data', (e) => {
        this.drawData(e)
      })
    }
  },
  methods:{
    compute(r,score){
      let angel = score * 2 * Math.PI / 100
      return [this.center - Math.sin(angel) * r, this.center + Math.cos(angel) * r]
    },
    init(){
      let svg = this.svg = d3.select(this.$el).attr('width', this.width).attr('height', this.width)
      let R = this.width * 0.8 * 0.5            //圆半径
      let center = this.center = this.width / 2 //中点
      //最外圆
      let bg = svg.append('g').classed('bg',true)
      bg.append('circle')
        .attr('cx', center)
        .attr('cy', center)
        .attr('r', R)
        .attr('stroke', '#d9d9d9')
        .attr('stroke-width', 1)
        .attr('fill', 'none');
      //灰色背景底圆
      let r1 = R * 0.84
      bg.append('circle')
        .attr('cx', center)
        .attr('cy', center)
        .attr('r', r1)
        .attr('stroke', 'none')
        .attr('fill', '#f7f7f7');
      //渐变圆
      let r2 = R * 0.6
      bg.append('circle')
        .attr('fill', 'url(#ring'+this.type+')')
        .attr('cx', center)
        .attr('cy', center)
        .attr('r', r2)
      //刻度
      let scale = [0,25,50,75]
      for(let i in scale){
        let p = this.compute(R*1.12,scale[i])
        bg.append('text').classed('scale', true)
          .attr('fill', '#d9d9d9')
          .attr('font-size', R*0.14)
          .attr('x', p[0])
          .attr('y', p[1])
          .text(scale[i])
      }
      //中心文字
      let span = bg.append('text').classed('center-text', true).attr('y', center-5)
      span.append('tspan')
          .attr('id', 'tspan'+this.type)
          .attr('font-size', R*0.34)
          .attr('x', center)
          .text(this.data)
      span.append('tspan')
          .attr('font-size', R*0.14)
          .attr('x', center)
          .attr('dy', '1.5em')
          .text(()=>{
            return this.type==1 ? '职业素质' : '原动力' 
          })
      this.ringWidth = r1 - r2;
      this.radius = (r1 + r2) / 2
    },
    //绘制环形分数
    drawData(e){
      let ele = d3.select(this.$el).select('.data')
      let path = ele.empty() ? this.svg.append('path').classed('data',true) : ele
      path.attr('fill','none').attr('stroke',this.ringColor[this.type]).attr('stroke-width',this.ringWidth)
      if (this.animate) {
        let n = 0.5   //控制流畅度（0.1~1）
        let t = 1000  //完成动画时间（毫秒）
        let nowScore = 0;
        let timer = setInterval(() => {
          if (nowScore>e) {
            clearInterval(timer)
            return
          }
          let x = this.compute(this.radius,nowScore)
          d3.select('#tspan'+this.type).text(Math.ceil(nowScore))
          nowScore += n
          path.attr('d', `M${this.center} ${this.center+this.radius} A${this.radius} ${this.radius} 0 ${nowScore>50?1:0} 1 ${x[0]} ${x[1]}`)
        }, 6 );//t / (e / n)
      } else {
        let p = this.compute(this.radius,e)
        path.attr('d', `M${this.center} ${this.center+this.radius} A${this.radius} ${this.radius} 0 ${e>50?1:0} 1 ${p[0]} ${p[1]}`)
        d3.select('#tspan'+this.type).text(e)
      }
    }
  },
}
</script>
<style scope lang="scss">
.ring_chart{
  margin: 44px 0 20px;
  .scale{
    text-anchor: middle;
    dominant-baseline: middle;
  }
  .center-text{
    tspan{
      text-anchor: middle;
      fill:#fff;
    }
  }
  .gradient0{
    fill:url('#red')
  }
  .gradient1{
    fill:url('#yellow')
  }
}
</style>
