<template>
  <div class="beeCourse">
    <header></header>
    <ul>
      <li class="flex--c" @click="toDetail(item.id)" v-for="(item,index) in list" :key="index">
        <img :src="item.cover_img">
        <div>
          <h1>{{item.theme}}</h1>
          <h2>{{item.desc}}</h2>
          <h3>0人加入学习</h3>
          <div class="flex-b-c">
            <h4>{{item.total_subject}}讲 / {{item.price}}元</h4>
            <span>购买</span>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  data(){
    return {
      list: [],
      total: 0,
    }
  },
  created(){
    this.$http.post(this.ajaxUrlPath+'/BeeCourse/getCourseList', {
      page: 1,
      per_page: 10,
    }).then(({data}) => {
      if (data.code == 0) {
        this.list = data.data.data
        this.total = data.data.total
      } else {
        this.$toast({message: data.msg})
      }
    }, () => this.$toast({message:'网络错误'}))
  },
  mounted(){},
  methods: {
    toDetail(id) {
      this.$router.push({path: '/bee/detail', query: {id: id}})
    },
  },
}
</script>
<style lang="scss" scoped>
.beeCourse{
  text-align: left;
  box-shadow: 0 0 10px rgba(200,200,200,.2);
  header{
    padding: 0 0 50px;
    height: 260px;
    background: lightgreen;
  }
  ul{
    height: calc(100vh - 210px);
    margin-top: -50px;
    overflow-y: auto;
    border-top-left-radius: 50px;
    border-top-right-radius: 50px;
    background: #fff;
    padding: 1px 30px;
    &::before{
      content: '';
      display: block;
      height: 40px;
    }
    li{
      margin: 30px 0px;
      padding: 0 20px;
      font-size: 28px;
      color: #333;
      img{
        width: 170px;
        height: 260px;
        background: #f1f7f2;
        margin-right: 20px;
        flex-shrink: 0;
      }
      &>div{width: 470px;}
      h1{
        word-break: break-all;
        font-size: 28px;
        color: #292929;
        font-weight: bold;
        white-space: normal;
        line-height: 1.4;
        margin-bottom: 12px;
        overflow: hidden;
        text-overflow: ellipsis;
        text-overflow: -o-ellipsis-lastline;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
      }
      h2{
        font-size: 24px;
        color: #8f9192;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        margin-bottom: 20px;
      }
      h3{
        font-size: 22px;
        color: #8f9192;
        line-height: 32px;
        height: 32px;
        margin: 20px 0;
      }
      h4{
        font-size: 24px;
        color: #148534;
        line-height: 2;
        white-space: nowrap;
      }
      span{
        width: 120px;
        font-size: 26px;
        line-height: 44px;
        color: #148534;
        border-radius: 24px;
        background: #f1f7f2;
        text-align: center;
      }
    }
  }
}
</style>