<template>
  <div class="confirm" v-if="user">
    <div class="userinfo flex--c"><img :src="user.avatar||require('../assets/images/icons/user_icon.png')"> {{user.realname||user.nickname}}</div>
    <ul class="list">
      <li v-for="(item,index) in list" :key="index">
        <p>咨询日期 ：{{item.meeting_time}}</p>
        <p>咨询企业 ：{{item.companyname}}</p>
        <p>管理员 ：{{item.realname}}</p>
        <p>类型 ：<span v-if="item.type==1">付费</span><span class="free" v-else>免费</span></p>
        <div class="flex">
          <b class="btn0" v-if="item.state==-1">已取消</b>
          <b class="btn1" v-else-if="item.state==1">已确认</b>
          <template v-else>
            <b @click="submit(index,-1)">取消预约</b>
            <b @click="submit(index,1)" class="btn">确认预约</b>
          </template>
        </div>
      </li>
      <li v-if="list.length==0" style="padding:40px 0">暂无待确认预约，请前往电脑端查看详情</li>
    </ul>
  </div>
  <div class="confirm" v-else>
    <ul class="login">
      <h1>请先登录</h1>
      <li class="flex--c">
        <label>手机号</label>
        <div class="inputBox flex">
          <input type="number" v-model="mobile" @input="mobile=mobile.slice(0,11)">
          <p v-show="mobile" @click="mobile=''">x</p>
        </div>
      </li>
      <li class="flex--c">
        <label>验证码</label>
        <div class="inputBox flex">
          <input type="text" inputmode="numeric" autocomplete="one-time-code" pattern="\d{6}" v-model="captcha" @input="captcha=captcha.slice(0,6)">
          <span class="grey" v-if="sencond>0">重新发送({{sencond}}s)</span>
          <span v-else @click="getCaptcha">获取验证码</span>
        </div>
      </li>
      <div class="btn" @click="login">立即登录</div>
    </ul>
  </div>
</template>
<script>
export default {
  data(){
    return {
      user: '',
      mobile: this.$route.query.mobile||'',
      captcha: '',
      sencond: 0,
      meetingId: this.$route.query.mid||'',  //会议id
      list: [],
    }
  },
  created(){
    document.title = '待确认预约'
    this.$http.get(this.ajaxUrlPath+'/MeetingConfirm/getUserInfo').then(({data}) => {
      if (data.code == 0) {
        this.user = data.data
        this.getList()
      }
    })
  },
  mounted(){},
  methods:{
    getCaptcha() {
      if (!this.mobile) {
        this.$toast({message: '请先输入手机号'})
      } else if (!/^1[3-9]{1}[0-9]{9}$/.test(this.mobile)) {
        this.$toast({message: '手机号不正确'})
      } else {
        this.$http.get(`${this.ajaxUrlPath}/MeetingConfirm/getSmsCaptcha?mobile=${this.mobile}`).then(({data}) => {
          if (data.code == 0) {
            this.$toast({message: '已发送，请注意查收'})
            this.sencond = 60
            let timer = setInterval(() => {
              if (this.sencond-- < 0) clearInterval(timer)
            }, 1000)
          } else {
            this.$toast({message: data.msg})
          }
        }, () => this.$toast({message: '网络错误'}))
      }
    },
    login() {
      if (!this.mobile) {
        this.$toast({message: '请先输入手机号'})
      } else if (!/^1[3-9]{1}[0-9]{9}$/.test(this.mobile)) {
        this.$toast({message: '手机号不正确'})
      } else if (!this.captcha) {
        this.$toast({message: '请先输入验证码'})
      } else {
        this.$http.post(`${this.ajaxUrlPath}/MeetingConfirm/login`, {
          mobile: this.mobile,
          captcha: this.captcha
        }).then(({data}) => {
          if (data.code == 0) {
            this.user = data.data
            this.getList()
          } else {
            this.$toast({message: data.msg})
          }
        }, () => this.$toast({message:'网络错误，请稍后重试'}))
      }
    },
    getList() {
      this.$http.get(`${this.ajaxUrlPath}/MeetingConfirm/getConfirmList?meeting_id=${this.meetingId}`).then(({data}) => {
        if (data.code == 0) {
          this.list = data.data
        } else if (data.code == 100) {
          this.list = data.data
          this.$toast({message: data.msg, duration: 3000})
        }
      })
    },
    submit(i, state) {
      this.$http.get(`${this.ajaxUrlPath}/MeetingConfirm/operate?meeting_id=${this.list[i].meeting_id}&status=${state}`).then(({data}) => {
        if (data.code == 0) {
          this.list[i].state = state
          this.$forceUpdate()
        } else {
          this.$toast({message: data.msg})
        }
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.confirm{
  background: #f1f5f7;
  min-height: 100%;
  padding: 1px 0;
  box-sizing: border-box;
  .userinfo{
    padding: 30px 36px;
    font-size: 24px;
    color: #4d4d4d;
    img{
      width: 40px;
      height: 40px;
      margin-right: 10px;
      border-radius: 50%;
      overflow: hidden;
    }
  }
  .list{
    margin: 0 30px;
    li{
      background: #fff;
      border-radius: 10px;
      padding: 30px;
      margin-bottom: 30px;
      font-size: 28px;
      color: #666;
      p{
        text-align: left;
        line-height: 56px;
        span{color: #6b6be5}
        .free{color: #4fa7cc}
      }
      .flex{
        justify-content: flex-end;
        margin-top: 4px;
        b{
          padding: 4px 10px;
          border-radius: 6px;
          margin-left: 30px;
          border: 1px solid #f46549;
          color: #f46549;
          font-weight: normal;
        }
        .btn{
          color: #fff;
          background: #6b6be5;
          border-color: #6b6be5;
        }
        .btn0{
          opacity: .6;
        }
        .btn1{
          opacity: .6;
          color: #6b6be5;
          border-color: #6b6be5;
        }
      }
    }
  }
  .login{
    background: #fff;
    border-radius: 10px;
    margin: 40px;
    padding: 50px 40px;
    white-space: nowrap;
    li{margin-bottom: 50px;}
    h1{
      font-size: 30px;
      color: #666;
      line-height: 60px;
      margin-bottom: 40px;
    }
    label{
      font-size: 28px;
      color: #666;
      margin: 0 20px 0 10px;
    }
    .inputBox{
      flex: 1;
      border-radius: 8px;
      border: 1px solid #e5e5e5;
      input{
        line-height: 70px;
        flex: 1;
        padding: 0 20px;
        width: 200px;
        color: #333;
        font-size: 30px;
      }
      p{
        line-height: 70px;
        color: #e5e5e5;
        width: 60px;
      }
      span{
        line-height: 70px;
        font-size: 26px;
        color: #6b6be5;
        width: 200px;
        border-left: 1px solid #e5e5e5;
      }
      .grey{
        color: #ccc;
      }
    }
    .btn{
      line-height: 70px;
      background: #6b6be5;
      border-radius: 8px;
      color: #fff;
      font-size: 28px;
      margin-bottom: 30px;
    }
  }
}
</style>