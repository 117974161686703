<template>
    <div class="report-box">
        <div class="head">
            <img :src="avatar" class="user-icon" >
            <p><span >{{nickname}}</span><span>[{{realname}}]</span>的销售潜能分析</p>
            <p class="time">{{formatTime(exam_info.end_time,'yyyy-MM-dd hh:mm')}}</p>
        </div>
        <div class="s-index">
            <div class="cicle">
                <p class="score" :style="{top:size/2.3+'px'}">{{value}}</p>
            </div>
            <ul class="radar-text" v-if="value >= 85 ">
                <li class="r-f">目前潜能：<span class="red ">特优</span></li>
                <li><span class="bold">建议：</span>提供专业系统的销售训练，大胆给予实践机会，帮助其迅速成长为卓越的销售人员。</li>
            </ul>
            <ul class="radar-text" v-else-if="value >= 75">
                <li class="r-f">目前潜能：<span class="red ">优秀</span></li>
                <li><span class="bold">建议1：</span>提供专业系统的销售训练，主动给予实践机会；</li>
                <li><span class="bold">建议2：</span>定期辅导与激励，帮助其迅速成长为优秀的销售人员。</li>
            </ul>
            <ul class="radar-text" v-else-if="value >= 60">
                <li class="r-f">目前潜能：<span class="red ">良好</span></li>
                <li><span class="bold">建议1：</span>提供专业系统的销售训练，给予适当的实践机会；</li>
                <li><span class="bold">建议2：</span>明确销售目标与行动方案，帮助其迅速成长为合格的销售人员。</li>
            </ul>
            <ul class="radar-text" v-else-if="value >= 45">
                <li class="r-f">目前潜能：<span class="red ">中上</span></li>
                <li><span class="bold">建议：</span>综合考量职业素质及资源，重点沟通销售工作意愿、认知、心态。</li>
            </ul>
            <ul class="radar-text" v-else>
                <li class="r-f">目前潜能：<span class="red ">待挖掘</span></li>
                <li><span class="bold">建议：</span>客观反馈销售潜能状态，倾听对方的职业规划，给与职业发展建议。</li>
            </ul>
        </div>
        <div class="space"></div>
        <div class="radar-box">

            <radar :data="idRadar"></radar>

            <p class="radar-text" v-if="value >= 60">相较整个销售过程而言，{{realname}}在<span class="strong"  v-for="(item,i) in hight_dimension" :key="i">{{item}}<i v-if="i<hight_dimension.length-1">、</i></span>方面潜能较好，在<span class="strong"  v-for="(item,i) in low_dimension" :key="i">{{item}}<i v-if="i<low_dimension.length-1">、</i></span>方面还需要继续关注提升，期待您给与激发与辅导，帮助{{realname}}取得更好的成就！</p>
            <p class="radar-text" v-else-if="value >= 45">相较整个销售过程而言，{{realname}}在<span class="strong"  v-for="(item,i) in low_dimension" :key="i">{{item}}<i v-if="i<low_dimension.length-1">、</i></span>方面需要强化训练。</p>
            <p class="radar-text" v-else>相较整个销售过程而言，{{realname}}暂未表现出明显的优势。</p>
        </div>
    </div>
</template>

<script>
import circleProgress from './circle-progress'
import radar from './radar'

export default {
    data(){
        return{
            flagShowImg: false,
            exam_id: this.$route.query.exam_id,
            exam_info:{},
            realname:'',
            nickname:'',
            avatar:'',
            getReportInterval: null,
            type:'salereport',
            saveImgUrl:'',
            value: 0,
            color:['#f7ae7f', '#fe6122'],
            size: 140,
            valueText:'',
            idRadar:null,
            hight_dimension:[],
            low_dimension:[],
            potentiala_dvantage:[],
            improve:[],
        }
    },
    components:{radar},
    mounted() {
        this.getReportInfo();
        document.title = '销售潜能分析SPA'
    },
    destroyed() {
        clearInterval(this.getReportInterval)
    },
    methods:{
        formatTime (value, dateformat) {
            var date = new Date();
            date.setTime(value*1000);
            var year = date.getFullYear();
            var month = date.getMonth() + 1;
            month = month < 10 ? ('0' + month) : month
            var day = date.getDate();
            day = day < 10 ? ('0' + day) : day;
            var hours = date.getHours();
            var minutes = date.getMinutes();
            minutes = minutes < 10 ? ('0' + minutes) : minutes;
            var seconds = date.getSeconds();
            seconds = seconds < 10 ? ('0' + seconds) : seconds;
            if (dateformat == 'yyyy-MM-dd hh:mm:ss') {
                return year + '-' + month + '-' + day + ' ' + hours + ':' + minutes + ':' + seconds;
            }
            if (dateformat == 'yyyy-MM-dd hh:mm') {
                return year + '-' + month + '-' + day + ' ' + hours + ':' + minutes;
            }
            if (dateformat == 'yyyy-MM-dd') {
                return year + '-' + month + '-' + day;
            }
            if (dateformat == 'yyyy-MM') {
                return year + '-' + month;
            }
            if (dateformat == 'yyyy/MM/dd') {
                return year + '/' + month + '/' + day;
            }
            return year + '年' + month + '月' + day + '日'
        },
        subString(str,len){
            str = str.length>len? str.substring(0,len)+'...':str;
            return str
        },
        getReportInfo(){
            this.$http.post(this.ajaxUrlPath+'/Sale/getReportInfo',{
                exam_id: this.exam_id,
                type:'2'                    
            },{emulateJSON:true}).then(resp=>{
                if(resp.data.code == 0){
                    let exam_info = resp.data.data.exam_info;
                    let nickname = exam_info.nickname || '';
                    nickname = this.subString(nickname,5);
                    let realname = exam_info.realname || '';
                    realname = this.subString(realname,5);
                    this.exam_info = exam_info;
                    this.realname = realname;
                    this.nickname = nickname;
                    this.avatar = exam_info.avatar || '../../assets/images/icons/user_icon.png';
                    let report_data = resp.data.data.report_data
                    let customer_report = report_data.customer_report;
                    let dimension_score = customer_report.dimension_score;
                    let names = [];
                    let scores = [];
                    for(let key in dimension_score){
                        if(exam_info.template_id==1){   //此判断必不可少
                            scores.push(dimension_score[key]);
                            names.push(key);
                        }else{
                            scores.push(dimension_score[key].getPercentileValue);
                            names.push(key);
                        }
                        
                    }
                    this.idRadar = {
                        id: exam_info.template_id,
                        names: names,
                        scores: scores
                    };
                    let hight_dimension = customer_report.hight_dimension;
                    let low_dimension = customer_report.low_dimension;
                    this.low_dimension = low_dimension;
                    this.hight_dimension = hight_dimension;
                    let user_report = report_data.user_report;
                    let value = user_report.sale_score;
                    let potentiala_dvantage = user_report.potentiala_dvantage;
                    let improve = user_report.improve;
                    this.improve = improve;
                    this.potentiala_dvantage = potentiala_dvantage;
                    this.value = value;

                    $('.cicle').circleProgress({
                        size:this.size,
                        value: 220/360*this.value/100,
                        lineCap: 'round',
                        fill: { gradient: this.color },
                        animation: false,
                        emptyFill: '#feede2',
                        start:Math.PI*(0.5+0.5*7/9),
                        end:Math.PI * (2+0.5*2/9),
                    });
                }else{
                    this.$toast({
                        message:resp.data.msg,
                        duration: 1000,
                    })
                    wx.miniProgram.reLaunch({
                        url: '/pages/salesPotentiality/management/management',
                        success: function(){
                            console.log('success')
                        },
                        fail: function(){
                            console.log('fail');
                        },
                        complete:function(){
                            console.log('complete');
                        }
                    })
                }
            })
        },
    },
}
</script>

<style lang="scss" scoped>
.report-box{
    padding-bottom: 140px;
    position:relative;
}
.btn{
    position:fixed;
    width: 100%;
    left: 0;
    bottom: 0;
    height: 108px;
    border-top: 1px solid #ededed;
    background:#fff;
    line-height: 108px;
    font-size: 28px;
    color:#6f6f6f;
}
.head{
    border-bottom: 1px solid #ededed;
    position: relative;
    padding: 37px 30px 33px 110px;
    font-size:26px;
    color:#7e7e7e;
    text-align:left;
}
.user-icon{
    width: 60px;
    height: 60px;
    position: absolute;
    border-radius:100%;
    left: 30px;
    top: 50%;
    margin-top: -30px;
}
.space{
    background:#f7f7f7;
    height: 18px;
}
.s-index{
    padding: 58px 0 77px;
    position: relative;
    font-size:28px;
    color:#6f6f6f;
    .score{
        position: absolute;
        left: 50%;
        z-index: 2;
        width: 100px;
        height: 100px;
        line-height: 100px;
        text-align: center;
        margin-left: -50px;
        margin-top: -50px;
    }
}
.cicle{
    font-size: 52px;
    color: #fe6122;
    position: relative;
    width: 130PX;
    height: 130PX;
    margin: auto;
    background: url(../../assets/images/report/circle-bg.png) no-repeat;
    background-size: 88% ;
    background-position: 8PX 15%;
    canvas{
        display: block;
    } 
}
.radar-text{
    text-align: left;
    font-size:28px;
    color:#6f6f6f;
    width: 640px;
    margin: auto;
    .red{
        font-weight:bold;
        color:#fe6224;
    }
    li{
        list-style: disc;
    }
    .r-f{
        list-style: none;
        text-align: center;
        margin-bottom: 20px;
    }
    .strong{
        font-weight:bold;
        color:#3e3e3e;
        padding: 0 10px;
    }
}
.bold{
    font-weight: bold;
}
</style>