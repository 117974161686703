<template>
  <div class="team" id="lsnTeam">
    <header>
      <h2><b>{{name}}</b> - LSN分析报告<span v-if="version">版本号 v{{version}}</span></h2>
      <h2>{{$formatTime(time, 'y-m-d h:m')}}</h2>
    </header>
    <div class="content">
      <ul class="nav flex-c-c" v-if="!long">
        <li :class="{curr: tab==1}" @click="tab=1">精益创业整体水平</li>
        <i></i>
        <li :class="{curr: tab==2}" @click="tab=2">冲浪指数</li>
        <i></i>
        <li :class="{curr: tab==3}" @click="tab=3">团队卓越因子</li>
        <i></i>
        <li :class="{curr: tab==4}" @click="tab=4">潜在风险</li>
      </ul>
      <!-- 精益创业整体水平 -->
      <section v-if="tab==1">
        <h1>精益创业整体水平</h1>
        <p><b class="green">精益创业整体水平：</b>{{whole.content}}</p>
        <bar
          :score="whole.score"
          :refer="90"
          :mark="[{name:'低',min:20,max:45},{name:'良好',min:45,max:70},{name:'优秀',min:70,max:90},{name:'卓越',min:90,max:105}]"
          :text="'当下创业团队的水平：'"
        ></bar>
        <h4>等级说明：<b>{{whole.level}}</b></h4>
        <p>{{whole.level_description}}</p>
        <h4>建议改善：</h4>
        <p style="margin-bottom:24px">{{whole.advice}}</p>
        <p><b>冲浪指数：</b>{{whole.simple_surfing_advice}}</p>
        <p><b>卓越因子：</b>{{whole.excellence_factor_advice}}</p>
        <p><b>潜在风险：</b>{{whole.simple_risk_advice}}</p>
        <div class="lamp">创业锦囊：</div>
        <div class="tipsBox">
          <p><b>创业认知：</b></p>
          <p v-for="(it,j) in whole.startup_kits.entrepreneurship_cognitive" :key="j">{{it}}</p>
          <p style="margin-top:15px"><b>创业命脉：</b></p>
          <p v-for="(it,j) in whole.startup_kits.the_three_major_lifeline" :key="j">{{it}}</p>
        </div>
      </section>
      <!-- 冲浪指数 -->
      <section v-if="tab==2 || long">
        <h1 :class="{marTop:long}">冲浪指数</h1>
        <p><b class="green">冲浪指数：</b>{{surfing.content}}</p>
        <barCopy
          :score="surfing.score"
          :refer="64"
          :mark="[{name:'C',min:10,max:44},{name:'B',min:44,max:52},{name:'B+',min:52,max:64},{name:'A',min:64,max:90}]"
          :text="'当下创业团队冲浪等级：'"
        ></barCopy>
        <h4 style="margin-top:-10px">等级说明：<b>{{surfing.level}}</b></h4>
        <p><b>状态描述：</b>{{surfing.state_description}}</p>
        <p style="margin-top:6px"><b>解决方案：</b>{{surfing.solution}} </p>
        <bar
          :score="surfing.optimism.score"
          :refer="80"
          :mark="[{name:'C',min:10,max:55},{name:'B',min:55,max:65},{name:'B+',min:65,max:80},{name:'A',min:80,max:105}]"
          :label="surfing.optimism.name"
        ></bar>
        <bar
          :score="surfing.adapt_to_change.score"
          :refer="80"
          :mark="[{name:'C',min:10,max:55},{name:'B',min:55,max:65},{name:'B+',min:65,max:80},{name:'A',min:80,max:105}]"
          :label="surfing.adapt_to_change.name"
        ></bar>
        <h5>解释说明：</h5>
        <p><b>乐观性：</b>{{surfing.optimism.content}}</p>
        <p><b>适应变化：</b>{{surfing.adapt_to_change.content}}</p>
      </section>
      <!-- 团队卓越因子 -->
      <section v-if="tab==3 || long" class="excellent">
        <h1 :class="{marTop:long}">团队卓越因子</h1>
        <p><b class="green">团队卓越因子：</b>{{excellence.content}}</p>
        <!-- <radar :width="width*.82" :animate="!long" :data="excellence.dimension"/> -->
        <columnar :width="width*.84" :animate="!long" :data="excellence.dimension"/>
        <ul class="colLegend" v-if="excellence.name">
          <li><img src="../../../assets/images/icons/lsn_legend_1.jpg"><span>团队水平</span></li>
          <li><img src="../../../assets/images/icons/lsn_legend_2.jpg"><span>参考线</span></li>
        </ul>
        <h4>结果说明：</h4>
        <p><b>柱状图：</b></p>
        <p v-for="(it,j) in excellence.balance" :key="j">{{it}}</p>
        <template v-if="excellence.leverage_point">
          <p><b>杠杆点：</b></p>
          <p>{{excellence.leverage_point}}</p>
        </template>
        <p><b>提升建议：</b></p>
        <p v-for="(it,j) in excellence.advice" :key="j">{{it}}</p>
      </section>
      <!-- 潜在风险 -->
      <section v-if="tab==4 || long" class="excellent">
        <h1 :class="{marTop:long}">潜在风险</h1>
        <p><b class="green">潜在风险：</b>{{risk.content}}</p>
        <bar
          :score="risk.score"
          :refer="60"
          :mark="[{name:'低',min:10,max:60},{name:'中',min:60,max:80},{name:'高',min:80,max:105}]"
          :text="'潜在风险等级：'"
        ></bar>
        <div class="meterBox">
          <metere :width="width*.34" :data="risk.consensus" :animate="!long"></metere>
          <metere :width="width*.34" :data="risk.interactive_collaboration" :type="1" :animate="!long"></metere>
        </div>
        <h4>结果说明：</h4>
        <p><b>状态描述：</b>{{risk.state_description}}</p>
        <p><b>改善思路：</b>{{risk.improve}}</p>
      </section>
    </div>
    <div class="copyright">Copyright © {{getcopyRight()}} Huayanspace. All Rights Reserved.</div>
    <!-- 报告解读申请窗口 -->
    <div class="bot" v-if="!long"/>
    <div class="img-box" v-show="explainShow">
      <div class="singleBox">
        <img src="../../../assets/images/icons/close_2.png" @click="explainShow=false">
        <p class="text-c">提交申请后24小时内将会有专人联系您！创业顾问为您解读数据，预防团队风险。提高团队战斗力，少走弯路，让创业更轻松！</p>
        <div class="btn" @click="addExplain">提交申请</div>
      </div>
    </div>
  </div>
</template>
<script>
import bar from './chart/bar'
import barCopy from './chart/bar'
// import radar from './chart/radar'
import columnar from './chart/columnar'
import metere from './chart/meter'
export default {
  components: {bar,barCopy,columnar,metere},
  props: {
    report_id: {default: null},
    hy_session: {default: null},
    version: {default: ''}
  },
  data(){
    return {
      tab: 1,
      width: 0,
      name: '',
      time: '',
      explainShow: false,
      origin: this.$route.query.origin || 'mobile',             //来源
      long: this.$route.query.long===undefined ? false : true, //是否报告长图
      //精益创业整体水平
      whole: {
        name:'',
        content:'',
        score:0,
        level:'',
        level_description:'',
        advice:'',
        startup_kits:{entrepreneurship_cognitive:[],the_three_major_lifeline:[]},
        simple_surfing_advice: '',
        excellence_factor_advice: '',
        simple_risk_advice: ''
      },
      //冲浪指数
      surfing: {
        name: '',
        content: '',
        score: 0,
        level: '',
        state_description: '',
        solution: '',
        simple_solution: '',
        optimism: {name: '', score: 0, content: ''},
        adapt_to_change: {name: '', score: 0, content: ''},
      },
      //团队卓越因子
      excellence: {
        name: '',
        content: '',
        score: 0,
        dimension: [],
        balance: [],
        leverage_point: '',
        advice: [],
      },
      //潜在风险
      risk: {
        name: '',
        content: '',
        score: 0,
        risk_level: '',
        state_description: '',
        improve: '',
        simple_advice: '',
        consensus: {name: '', score: 0},
        interactive_collaboration: {name: '', score: 0},
      },
    }
  },
  mounted(){
    this.width = document.getElementById('lsnTeam').clientWidth
  },
  created(){
    this.$indicator.open({text: '加载中...',spinnerType: 'fading-circle'});
    this.$http.post(this.spaceUrl+'/Lsn/getTeamReportInfo',{
        origin: this.origin,
        team_report_id: this.report_id,
    }).then(({data}) => {
      if (data.code == 0) {
        var {
          team_info,
          team_over_all,
          surfing_index,
          excellence_factor_index,
          potential_risk,
        } = data.data
        this.name = team_info.org_name
        this.time = team_info.report_time
        this.whole = team_over_all
        this.surfing = surfing_index
        this.excellence = excellence_factor_index
        this.risk = potential_risk
      } else {
        this.name = '无法加载'
        this.$toast({message: '加载失败', duration: 1500})
      }
      this.$indicator.close();
    }).catch((err)=>{
      this.name = '无法加载'
      this.$toast({message: err})
      this.$indicator.close();
    })
  },
  methods:{
    getcopyRight(){
      var y = new Date().getFullYear()
      return `${y-1}-${y}`
    },
    addExplain(){
      this.$http.post(this.planetUrl+'/Lsn/addReportUnscramble', {
        hy_session: this.hy_session
      }).then(({data}) => {
        this.explainShow = false
        if (data.code == 0) {
          this.$toast({message: '提交成功', duration: 1000})
        } else {
          this.$toast({message: data.msg, duration: 2000})
        }
      }, () =>{this.$toast({message: '提交失败', duration: 2000})})
    },
  },
}
</script>
<style lang="scss" scoped>
.team{
  overflow: hidden;
  min-height: 100%;
  background: #f4f5fa;
  box-sizing: border-box;
  padding-bottom: 50px;
  user-select: none;
  .green{color: #29be9c !important}
  header{
    padding: 60px 24px 40px;
    color: #fff;
    position: relative;
    font-size: 24px;
    &::before{
      content: '';
      position: absolute;
      width: 160%;
      height: 260%;
      border-radius: 50%;
      background: #22d0a9;
      // background-image: linear-gradient(90deg,#48e6c2,#22d0a9);
      left: 50%;
      top: 48%;
      transform: translate(-50%,-50%);
    }
    h2{
      text-align: left;
      line-height: 1.5;
      position: relative;
      z-index: 2;
      white-space: nowrap;
      b{
        max-width: 300px;
        text-overflow: ellipsis;
        overflow: hidden;
      }
      span{
        flex: 1;
        text-align: right;
        float: right;
        padding-right: 8px;
      }
    }
  }
  .content{
    margin: 0 24px 40px;
    background: #fff;
    border-radius: 12px;
    padding: 20px 0 60px;
    position: relative;
    z-index: 3;
  }
  .nav{
    font-size: 24px;
    color: #9d9d9d;
    margin: 10px 0;
    li{
      padding: 0 16px;
      line-height: 50px;
      cursor: pointer;
      &::after{
        content: '';
        position: absolute;
        bottom: 2px;
        left: 50%;
        height: 2px;
        border-radius: 2px;
        width: 0;
        transition: all .2s;
      }
    }
    .curr{
      color: #29be9c;
      position: relative;
      &::after{
        background: #29be9c;
        width: 80%;
        left: 10%;
        right: 10%;
      }
    }
    i{
      width: 2px;
      height: 24px;
      background: #e5e5e5;
    }
  }
  section{
    margin: 0 24px;
    font-size: 26px;
    color: #6f6f6f;
    text-align: left;
    h1{
      font-size: 28px;
      color: #4d4d4d;
      font-weight: bold;
      line-height: 140px;
      text-align: center;
    }
    .marTop{margin-top: 100px}
    h4{
      line-height: 64px;
      color: #29be9c;
      font-weight: bold;
      margin-top: 24px;
      b{color: #4d4d4d}
    }
    h5{
      line-height: 60px;
      color: #4d4d4d;
      font-weight: bold;
      margin-top: 10px;
    }
    p{
      text-align: justify;
      line-height: 1.4;
      b{color: #4d4d4d}
    }
  }
  .lamp{
    line-height: 64px;
    color: #1d9278;
    font-weight: bold;
    margin-top: 40px;
    padding-left: 36px;
    position: relative;
    &::before{
      content: '';
      position: absolute;
      width: 26px;
      height: 26px;
      left: 0;
      top: 16px;
      background: url('../../../assets/images/report/lamp.png') no-repeat;
      background-size: 100%;
    }
  }
  .tipsBox{
    background: #f0fff9;
    border-radius: 12px;
    padding: 30px 24px;
    font-size: 25px;
    color: #1d9278;
    p b {color: #1d9278}
  }
  .excellent p{margin: 14px 0;}
  .colLegend{
    text-align: center;
    margin: -40px 0 80px;
    li{
      display: inline-block;
      width: 40%;
      img{
        width: 60px;
        height: 60px;
        display: inline-block;
        vertical-align: bottom;
      }
      span{
        height: 50px;
        line-height: 50px;
        font-size: 24px;
        display: inline-block;
      }
    }
  }
  .meterBox{
    text-align: center;
    svg:nth-child(1){margin-right: 40px}
    svg:nth-child(2){margin-left: 40px}
  }
  .copyright{
    font-size: 20px;
    color: #ccc;
  }
}

.bot{height: 90px;}
.singleBox{
  position: fixed;
  width: 600px;
  left: 50%;
  top: 44%;
  transform: translate(-50%,-50%);
  background: #fff;
  border-radius: 10px;
  padding-top: 50px;
}
.singleBox img{
  position: absolute;
  right: 0;
  margin: 12px 20px;
  top: 0;
  width: 32px;
  height: 32px;
}
.singleBox p{
  padding: 0 30px;
  margin: 30px 0;
  color: #666;
  font-size: 28px;
}
.singleBox .btn{
  width: 210px;
  border-radius: 10px;
  line-height: 66px;
  text-align: center;
  color: #fff;
  background: #15a77b;
  margin: 40px auto;
  font-size: 32px;
}
</style>