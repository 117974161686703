<template>
  <div class="success">
    <!-- 加载中 -->
    <div class="icon" v-show="showType==0">
      <img class="load" src="../../../../assets/images/product/loading.gif">
      <p>处理中...</p>
    </div>
    <!-- 按钮进入小程序 -->
    <div class="icon" v-show="showType==1">
      <img class="ok" src="../../../../assets/images/icons/success_cbc.png">
      <p>支付成功!</p>
      <p class="text">请点击下方按钮前往小程序端进行使用</p>
      <wx-open-launch-weapp id="launch_btn" username="gh_0978e9d0dfeb" :path="path" class="btn" @error="error">
        <script type="text/wxtag-template">
          <p style="color:#fff;text-align:center;width:280px;background:#4987f9;line-height:44px;border-radius:4px;margin:00 auto;">打开小程序</p>
        </script>
      </wx-open-launch-weapp>
    </div>
    <!-- 二维码进入小程序 -->
    <div v-show="showType==2">
      <img class="qrcode" :src="qrcode">
      <p class="text" style="margin:10px;">长按二维码打开小程序进行管理</p>
    </div>
    <!-- 错误提示 -->
    <div class="icon" v-show="showType==3">
      <img src="../../../../assets/images/product/sad.png">
      <p style="font-size:12px;margin:20px 80px">购买成功，因无法进入小程序，可在微信搜索“十方创业星球”小程序进行管理</p>
    </div>
    <div class="version" v-if="version">微信版本：{{version}}</div>
  </div>
</template>
<script>
export default {
  data(){
    return {
      path: 'pages/index/indexNew.html',
      showType: 0,      //是否显示小程序按钮 (默认不支持)
      version: '',      //当前微信版本
      qrcode: '',
    }
  },
  created(){
      let s = localStorage.getItem("session") || ''
      this.path = 'pages/TDA/manage/manage.html?session=' + s
      //判断手机系统和微信版本
      let wxInfo = navigator.userAgent.match(/MicroMessenger\/([\d\.]+)/i)
      this.version = wxInfo[1]
      if ( this.getWxAllow(wxInfo[1].split(".")) ) { //判断微信版本在7.0.12及以上的版本
        this.showType = 1
      } else {
        setTimeout(() => {
          this.$http.post(this.spaceUrl+'/Product/createMiniQrcode',{
            page: 'pages/TDA/manage/manage?session=' + s
          }).then(({data}) => {
            if (data.code == 0) {
              this.showType = 2
              this.qrcode = data.data.qrcode_image
            } else {
              this.showType = 3
              this.$toast({message: data.msg});
            }
          }, () => this.showType = 3)
        }, 600)
      }
  },
  mounted() {
    document.title = '人才发展评估'
    wx.ready(() => {
      wx.onMenuShareAppMessage({
        title : '人才发展评估',
        link : `${this.mobileUrl}/#/TDA/shop`,
        imgUrl : `${this.mobileUrl}/static/img/p-15.jpg`,
      })
    })
  },
  methods:{
    getWxAllow(ver) {
      if (ver[0] > 7) {
        return true
      } else if (ver[0] == 7) {
        if (ver[1] > 0 || ver[2] >= 12) return true
      }
      return false
    },
    error(){
      history.go(-1)
    },
  },
}
</script>
<style lang="scss" scoped>
.success{
  height: 100%;
  padding-top: 120px;
  background: #f1f5f7;
  position: relative;
  .text{
    font-size: 26px;
    color: #b3b3b3;
    margin: 30px 0 120px;
  }
  .btn{
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 80px;
  }
  .qrcode{
    width: 400px;
  }
  .version{
    position: absolute;
    font-size: 24px;
    color: #d9d9d9;
    bottom: 60px;
    left: 0;
    width: 100%;
  }
}
.icon{
  font-size: 40px;
  color: #535353;
  font-weight: bold;
  img{
    width: 160px;
    height: 160px;
    display: block;
    margin: 30px auto;
    border-radius: 50%;
  }
  .ok{
    background: #cfdff7;
  }
  p{
    height: 60px;
  }
}
</style>