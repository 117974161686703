<template>
  <!--报告-->
  <team_1_0 v-if="version=='1.0.0' || version=='1.1.0'" :version="version" :hy_session="hy_session" :report_id="report_id"></team_1_0>
  <div v-else-if="version=='error'">报告版本有误</div>
</template>

<script>
import team_1_0 from './team1.0.vue'
export default {
  components:{team_1_0},
  data(){
    return{
      report_id: this.$route.params.report_id,
      version: this.$route.query.ver || '',
      hy_session: this.$route.query.hy_session || '',
    }
  },
  mounted(){
    if (!this.version)
      this.$http.get(this.spaceUrl+`/Lsn/getTeamReportVersion?team_report_id=${this.report_id}`).then(({data}) => {
        if (data.code == 0) {
          this.version = data.data.version
        } else {
          this.version = 'error'
          this.$toast({message:data.msg,duration: 2000})
        }
      })
  },
}
</script>
