<template>
  <div class="barLine_ts">
    <svg>
      <g class="data"/>
    </svg>
    <p class="ref" v-if="data.length">参考点</p>
  </div>
</template>
<script>
import * as d3 from 'd3'
export default {
  props: {
    data: {
      default: () => [
        // {name:'心态管理',score:20},
        // {name:'目标管理',score:80},
        // {name:'销售面谈',score:50},
        // {name:'拜访活动',score:60},
      ],
    },
    animate: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      color: ['#f9961a','#a3b12a','#f67d3d','#56bd86'],
    }
  },
  created(){},
  mounted(){
    if (this.data.length) {
      this.draw()
    } else {
      this.$watch('data', () => this.draw())
    }
  },
  methods:{
    draw(){
      let width = $('#app').width() * .8,
          eachH = width * .1,
          height = eachH * this.data.length,
          svg = d3.select(this.$el).select('svg').attr('width',width).attr('height',height),
          fontSize = width * .044,       //文字大小
          left = width * .26,
          right = width - left,
          cen = eachH / 2,
          barW = fontSize*1.2;
      svg.append('polyline')
          .attr('fill', 'none')
          .attr('stroke', '#d9d9d9')
          .attr('stroke-width', 1)
          .attr('points', `${left},0 ${left},${height}`)
      for (let i in this.data) {
        svg.append('text')
            .attr('font-size', fontSize)
            .attr('fill', '#936e52')
            .attr('x', width * .13)
            .attr('y', cen + eachH*i)
            .text(this.data[i].name)
      }
      let data = svg.select('.data'), referStr = ''
      for (let i in this.data) {
        let w = this.data[i].score / 100 * right
        let h = cen + eachH*i - 1
        data.append('rect')
            .attr('fill', this.color[i])
            .attr('stroke', 'none')
            .attr('x', left)
            .attr('y', h - barW/2)
            .attr('rx', 3)
            .attr('width', w)
            .attr('height', barW)
        data.append('path')
            .attr('stroke', this.color[i])
            .attr('stroke-width', barW)
            .attr('fill', 'none')
            .attr('d', `M${left} ${h} L${left+3} ${h}`)
        //参考线
        let rw = this.data[i].refer / 100 * right
        data.append('circle')
            .attr('cx', rw+left)
            .attr('cy', h)
            .attr('r', 3)
            .attr('stroke', 'none')
            .attr('fill', '#da503e')
        referStr += `${rw+left},${h} `
      }
      data.append('polyline')
          .attr('stroke-width', 1)
          .attr('fill', 'none')
          .attr("stroke-dasharray", 2)
          .attr('stroke', '#da503e')
          .attr('points', referStr)
      //添加动画
      if (this.animate) data.classed('move', true)
    }
  },
}
</script>
<style lang="scss">
.barLine_ts{
  text{
    text-anchor: middle;
    dominant-baseline: middle;
  }
  .move{
    transform-origin: 26% center;
    animation: barLine_ts .6s forwards;
  }
  @keyframes barLine_ts {
    0%{transform: scaleX(0)}
    100%{transform: scaleX(1)}
  }
  .ref{
    color: #da503e;
    padding-left: 20px;
    position: relative;
    width: 100px;
    font-size: 24px;
    margin: 10px auto;
    &::before{
      content: '';
      position: absolute;
      left: 4px;
      top: 12px;
      border-radius: 50%;
      width: 10px;
      height: 10px;
      background: #da503e;
    }
  }
}
</style>