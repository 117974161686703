<template>
  <div id="AMT">
    <div class="info flex--c">
      <img :src="avatar">
      <div>
        <div class="name">{{name}}</div>
        <div>{{endTime||''}}</div>
      </div>
    </div>
    <div class="main main1" id="main">
      <div class="starBox">
        <b>领导思维清晰度:</b>
        <i v-for="item in 5" :key="item" :class="{
          star2: item <= star,
          star3: star < item && star > item-1
        }"/>
      </div>
      <div class="box1">
        <div class="box2 flex">
          <div class="box3">
            <h1>第一顺位</h1>
            <h6>{{leadership[0].leadership}}</h6>
            <h2>你的画像</h2>
            <p v-for="(it,j) in leadership[0].description" :key="j" v-html="it"/>
          </div>
          <div class="box4">
            <h2>你的特征</h2>
            <p v-for="(it,j) in leadership[0].feature" :key="j" v-html="it"/>
          </div>
        </div>
        <div class="box5">
          <h2>你的关注</h2>
          <div class="follow">
            <p v-for="(it,j) in leadership[0].new_attention" :key="j" v-html="it"/>
          </div>
        </div>
      </div>
      <div class="box1">
        <div class="box2 flex">
          <div class="box3">
            <h1>第二顺位</h1>
            <h6>{{leadership[1].leadership}}</h6>
            <h2>你的画像</h2>
            <p v-for="(it,j) in leadership[1].description" :key="j" v-html="it"/>
          </div>
          <div class="box4">
            <h2>你的特征</h2>
            <p v-for="(it,j) in leadership[1].feature" :key="j" v-html="it"/>
          </div>
        </div>
        <div class="box5">
          <h2>你的关注</h2>
          <p v-for="(it,j) in leadership[1].new_attention" :key="j" v-html="it"/>
          <!-- <div class="follow follow2">
            <div class="left">
              <p v-for="it in leadership[1].attention[0]" :key="" v-html="it"/>
            </div>
            <div class="row"></div>
            <div class="right">
              <p v-for="it in leadership[1].attention[1]" :key="" v-html="it"/>
            </div>
          </div> -->
        </div>
      </div>
    </div>
    <div class="main main2" v-show="leadership_score.length">
      <svg id="AMT_svg">
        <g class="bg"></g>
        <g class="data"></g>
      </svg>
    </div>
    <div class="main main3">
      <h3>【领导思维】的类型：</h3>
      <p>研究表明，领导思维主要有六种，且在领导者身上同时存在，但在具体的工作场景中，领导者惯用的领导思维只有1~2种，其他思维并不明显；</p>
      <h4>领导思维类型</h4>
      <ul>
        <li>E型领导（企业家思维）</li>
        <li>O型领导（运营思维）</li>
        <li>M型领导（管理思维）</li>
        <li>P型领导（专家思维）</li>
        <li>F型领导（前线思维）</li>
        <li>S型领导（战略思维）</li>
      </ul>
      <h3>【AMT领导者画像】的意义：</h3>
      <p>帮助你了解当下工作场景中，惯用的领导思维；</p>
      <p>这些惯用的思维将决定一个人的领导行为、沟通方式，并影响互动关系与管理效率；</p>
      <p>哪一种领导思维并不重要，最重要的是，你要知道哪一种思维最能让你发挥出最佳的领导力水平。</p>
      <h3>【AMT领导者画像】的应用：</h3>
      <div class="apply apply1">
        <img src="../../../assets/images/report/AMT_report2.png">
        <h5>自我觉察</h5>
        <p>分析自己当下的角色定位与思维的匹配度；找出改善空间，提升领导力。</p>
      </div>
      <div class="apply apply2">
        <img src="../../../assets/images/report/AMT_report3.png">
        <h5>觉察他人</h5>
        <p>理解他人与自己的思维差异；换位思考，调整沟通方式；提升核心管理团队之间的合作效率。</p>
      </div>
    </div>
  </div>
</template>
<script>
import * as d3 from 'd3'
export default {
  props:{
    exam_id: {default: ''},
    version: {default: ''}
  },
  data(){
    return {
      // exam_id: this.$route.query.exam_id || '',
      hy_session: this.$route.query.hy_session || '',
      name: "正在加载",                    //用户姓名
      avatar: require('../../../assets/images/icons/user_icon.png'),
      endTime: 0,                         //报告完成时间
      star: 0,                            //星级
      leadership: [
        {leadership:'',description:[],feature:[],attention:[]},
        {leadership:'',description:[],feature:[],attention:[]},
      ],                     //
      leadership_score: [],               //折线图的分数
    }
  },
  created(){
    this.$indicator.open({text: '加载中...',spinnerType: 'fading-circle'});
    this.$http.get(this.spaceUrl+'/Amt/getExamReportInfo',{
      params:{exam_id: this.exam_id}
    }).then((e) => {
      if (e.data.code == 0) {
        let user_info = e.data.data.user_info
        this.avatar = user_info.avatar
        this.name = user_info.realname || user_info.nickname
        this.endTime = this.$formatTime(user_info.end_time,'y-m-d h:m')
        this.star = e.data.data.definition
        this.leadership = e.data.data.leader_ship
        this.leadership_score =  e.data.data.all_leader_ship || []
        this.draw()
      } else {
        this.name = '无法加载'
        this.$toast({message: e.data.msg, duration: 2000})
      }
      this.$indicator.close();
    }).catch((err) => {
      console.error(err)
      this.name = '加载失败'
      this.$toast({message: '网络错误', duration: 1500})
      this.$indicator.close();
    })
  },
  methods:{
    draw(){
      /***** 背景 *****/
      var width = document.getElementById('AMT').clientWidth * 640 / 750,
          svg = d3.select('#AMT_svg'),
          height = width,
          left = width * .28,
          pieH = height / 6,
          textStartX = left / 2 + 2,
          startY = pieH / 2,
          bg = svg.select('.bg'),
          min = 5,
          max = 0,
          len = this.leadership_score.length;
      svg.attr('width', width).attr('height', height)
      for (let i = 0; i < len; i++) {
        let y = startY + i * pieH, item = this.leadership_score[i]
        bg.append('text')
          .attr('fill', '#1a1a1a')
          .attr('x', textStartX)
          .attr('y', y - 3)
          .append('tspan').text(item.leadership).attr('x', textStartX)
          .append('tspan').text(`(${item.description})`).attr('x', textStartX).attr('dy', '1.2em')
        bg.append('line')
          .attr('stroke-width', 1)
          .attr('stroke', '#d9d9d9')
          .attr("stroke-dasharray", "4,2")
          .attr('x1', left)
          .attr('y1', y)
          .attr('x2', width-1)
          .attr('y2', y)
        if (item.score < min) min = item.score;
        if (item.score > max) max = item.score;
      }
      /***** 数据 *****/
      let minScale = min-.5 < 1 ? 1 : min-.5
      let maxScale = max+.5 > 5 ? 5 : max+.5
      var data = svg.select('.data'), 
          scale = maxScale - minScale,//跨度
          dataWidth = width - left,   //数据区间宽度
          point = '';                 //折线坐标点
      for (let i = 0; i < len; i++) {
        let item = this.leadership_score[i],
            x = (item.score - minScale) / scale * dataWidth + left,
            y = startY + i * pieH
        data.append('circle')
            .attr('cx', x)
            .attr('cy', y)
            .attr('r', () => {
              if (item.bold) return 8
              else return 4
            })
            .attr('fill', '#4948ff');
        point += `${x},${y} `
      }
      data.append('polyline')
          .attr('fill', 'none')
          .attr('stroke', '#4948ff')
          .attr('stroke-width', 1)
          .attr('points', point)
    },
  },
}
</script>
<style lang="scss" scoped>
#AMT{
  background: #a880ff url('https://m.huayanspace.com/static/img/planet_bg12.jpg') no-repeat;
  background-size: 100% auto;
  padding-bottom: 40px;
  color: #1a1a1a;
}
.info{
  color: #fff;
  padding: 50px 30px 20px;
  img{
    width: 68px;
    height: 68px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 20px;
  }
  .name{
    text-align: left;
    line-height: 1.5;
    font-size: 26px;
  }
}
.starBox{
  margin: 40px 0 20px;
  b{
    font-size: 28px;
    color: #4948ff;
    line-height: 40px;
    display: inline-block;
    height: 40px;
  }
  i{
    display: inline-block;
    width: 36px;
    height: 36px;
    margin: 0 8px -6px;
    background-image: url('../../../assets/images/report/star4.png');
    background-repeat: no-repeat;
    background-size: 100%;
  }
  .star2{background-image: url('../../../assets/images/report/star5.png')}
  .star3{background-image: url('../../../assets/images/report/star6.png')}
}
.main{
  background: #f1ecff;
  margin: 30px;
  border-radius: 20px;
}
.main1{
  padding: 1px 40px 60px;
  .box1{
    border: 1px solid #1a1a1a;
    margin-top: 60px;
    .box2{
      border-bottom: 1px solid #1a1a1a;
    }
    .box3{
      width: 50%;
      box-sizing: border-box;
      border-right: 1px solid #1a1a1a;
      padding-top: 30px;
      padding-bottom: 160px;
      background: url('../../../assets/images/report/AMT_report1.png') no-repeat;
      background-size: auto 160px;
      background-position: center bottom;
      p{
        position: relative;
        text-align: justify; 
        margin: 0 40px;
        padding: 20px 0;
        border-bottom: 1px dashed #817e88;
        &::after{
          content: '';
          position: absolute;
          left: -16px;
          top: 30px;
          border-radius: 50%;
          width: 8px;
          height: 8px;
          background: #1a1a1a;
        }
        &:last-child{border: none}
      }
    }
    .box4{
      width: 50%;
      padding-bottom: 20px;
      h2{padding-top: 80px;}
      p{
        vertical-align: top;
        position: relative;
        margin: 0 4px;
        padding: 36px 0 20px;
        background: #cfcbff;
        display: inline-block;
        writing-mode: vertical-lr;
        writing-mode: tb-lr;
        &::after{
          content: '';
          position: absolute;
          left: 12px;
          top: 16px;
          border-radius: 50%;
          width: 8px;
          height: 8px;
          background: #1a1a1a;
        }
        &:last-child{border: none}
      }
    }
    .box5{
      padding-bottom: 40px;
      p{
        text-align: justify;
        margin: 0 50px;
        position: relative;
        &::after{
          content: '';
          position: absolute;
          left: -16px;
          top: 12px;
          border-radius: 50%;
          width: 8px;
          height: 8px;
          background: #1a1a1a;
        }
      }
      // .follow{
      //   div{
      //     display: inline-block;
      //     vertical-align: middle;
      //   }
      //   .left, .right{
      //     width: 38%;
      //     padding: 0 20px 0 30px;
      //     p{
      //       position: relative;
      //       text-align: justify;
      //       margin: 6px 0;
      //       &::after{
      //         content: '';
      //         position: absolute;
      //         left: -16px;
      //         top: 12px;
      //         border-radius: 50%;
      //         width: 8px;
      //         height: 8px;
      //         background: #1a1a1a;
      //       }
      //       &:last-child{border: none}
      //     }
      //   }
      //   .row{
      //     width: 16%;
      //     height: 160px;
      //     border-left: 1px dashed #1a1a1a;
      //     line-height: 160px;
      //     color: #4948ff;
      //     b{
      //       display: block;
      //       border-right: 1px dashed #1a1a1a;
      //     }
      //   }
      // }
      // .follow2{
      //   .left, .right{width: 44%;}
      //   .row{width: 4%}
      // }
    }
    h1,h6{
      color: #4948ff;
      font-size: 28px;
    }
    h6{
      font-weight: bold;
      border-bottom: 1px solid #1a1a1a;
      padding-bottom: 30px;
    }
    h2{
      color: #4948ff;
      font-size: 26px;
      white-space: nowrap;
      line-height: 2;
      margin-top: 30px;
    }
  }
}
.main2{
  padding: 30px 0;
  svg{
    font-size: 24px;
    margin-right: 10px;
    text-anchor: middle;
    dominant-baseline: middle;
  }
}
.main3{
  padding: 20px 40px 80px;
  font-size: 26px;
  h3{
    color: #4948ff;
    text-align: left;
    margin: 60px 0 20px;
  }
  p{
    color: #1a1a1a;
    text-align: justify;
  }
  h4{
    color: #4948ff;
    margin: 30px 0 20px;
  }
  ul{
    li{
      display: inline-block;
      width: 280px;
      background: #4948ff;
      color: #fff;
      font-size: 24px;
      line-height: 1.8;
      border-radius: 8px;
      margin: 8px 0;
      &:nth-child(odd){margin-right: 10px}
      &:nth-child(even){margin-left: 10px}
    }
  }
  .apply{
    display: inline-block;
    width: 45%;
    font-size: 24px;
    img{
      display: block;
      margin: 20px auto 0;
      width: 100px;
      height: 100px;
    }
    h5{
      color: #4948ff;
      line-height: 2;
      margin-bottom: 10px;
    }
  }
  .apply1{float: left;}
  .apply2{float: right;}
  &::after{
    content: '';
    display: block;
    clear: both;
  }
}
</style>