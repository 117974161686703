<template>
  <div class="report">
    <div class="topic">
      <img :src="require('../../assets/images/report/topic'+type+'.jpg')">
    </div>
    <header>
      <div class='headImg'><img :src="avatar" alt="头像"></div>{{name}}
    </header>
    <div class="bottom">
      <img :src="qrcode[keycode]" alt="二维码">
      <p>邀请朋友一起看看&nbsp;<br>你是否也是话题终结者呢？</p>
    </div>
  </div>
</template>
 
<script>
export default {
  data(){
    return{
      type:  this.$route.query.type||0,//0:轻度，1：中度，2：重度
      avatar: this.$route.query.avatar||'',
      name: '',
      hy_session: this.$route.query.hy_session||'',
      keycode: this.$route.query.keycode || 'mifeng',
      qrcode: {
        mifeng: require("../../assets/images/report/topic-code.jpg"),
        shifang: require("../../assets/images/report/code_shifang.jpg"),
        planet: require("../../assets/images/report/code_planet.jpg"),
      },
    }
  },
  created(){
    this.$http.get('Sale/getUserInfo?hy_session='+this.hy_session).then( (e) =>{
      let data = e.data.data
      if(!e.data.code){
        this.name = data.realname||data.nickname
        this.avatar = data.avatar
      }
    })
  },
  methods:{
  }
}
</script>

<style scoped lang="scss">
html{
  max-width: 1000px;
  background: #ccc;
}
.report{
  // height: 1208px;
  position: relative;
  color: #fff;
  box-sizing: border-box;
}
.topic{
  img{
    width: 100%;
    display: block;
  }
}
header{
  display: flex;
  align-items: center;
  font-size: 30px;
  position: absolute;
  z-index: 2;
  top: 30px;
  left: 30px;
  .headImg{
    width: 100px;
    height: 100px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 20px;
    img{width:100%;}
  }
}
.bottom{
  position: absolute;
  right: 30px;
  bottom: 20px;
  img{
    width: 100px;
    height: 100px;
    margin-left: 20px;
    float: right;
  }
  p{
    font-size: 22px;
    text-align: right;
    letter-spacing: 1px;
    align-self: flex-end;
    line-height: 1.6;
    float: right;
    padding-top: 20px;
  }
}
</style>