<template>
  <div class="myconsult">
    <ul>
      <li>
        <h1>提交时间：{{$formatTime(1357454650, 'y-m-d h:m')}}</h1>
        <h2 class="flex-b-c">幸福教练咨询 <span>待确认</span></h2>
        <h3>正在为你匹配教练中...</h3>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  data(){
    return {
      page: 1,
      list: [],
      total: '',
    }
  },
  created(){
    this.getList()
  },
  mounted(){
    window.onscroll = () => {
      var scrollTop = document.documentElement.scrollTop || document.body.scrollTop,
          clientHeight = document.documentElement.clientHeight || document.body.clientHeight,
          scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight;
      if (scrollTop + clientHeight >= scrollHeight) {
        console.log(this.page)
      }
    }
  },
  destroyed() {  
    window.onscroll = null
  },
  methods:{
    getList(p) {
      if (p == 1) this.page = 1
      this.$http.post(this.ajaxUrlPath+'/Bee/getUserConsult', {
        hy_session: localStorage.getItem("session"),
        page: this.page++,
        per_page: 10,
      }).then(({data}) => {
        if (data.code == 0) {
          this.list = data.data.data
          this.total = data.data.total
        } else {
          this.$toast({message: data.msg})
        }
      }, () => this.$toast({message:'网络错误'}))
    },
  },
}
</script>
<style lang="scss" scoped>
.myconsult{
  background: #f6f6f6;
  min-height: 100%;
  padding: 1px 0;
  box-sizing: border-box;
  user-select: none;
  li{
    background: #fff;
    border-radius: 10px;
    margin: 30px;
    padding: 30px;
    text-align: left;
    h1{
      color: #808080;
    }
    h2{
      color: #1a1a1a;
      font-weight: bold;
      font-size: 28px;
      height: 100px;
      span{
        font-size: 24px;
        color: #2c9e3a;
      }
    }
    h3{
      color: #2c9e3a;
    }
  }
}
</style>