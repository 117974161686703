<template>
  <div class="report" :class="theme">
    <div class="top">
      <header>
        <div class="user">
          <b>{{org_name}}</b> 层级报告分析<br>
          {{$formatTime(time,'y-m-d h:m')}}<span v-if="version">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;版本号 v{{version}}</span>
        </div>
        <div class="btn" v-if="!long" @click="save">报告长图</div> 
      </header>
      <nav v-if="!long">
        <span :class="{current:tabId==0}" @click="tab(0)">团队活力</span>
        <span :class="{current:tabId==1}" @click="tab(1)" v-if="team_leadership">领导能量</span>
        <span :class="{current:tabId==2}" @click="tab(2)">工作价值倾向</span>
      </nav>
    </div>
    <ul class="content">
      <!-- 团队活力 -->
      <li class="TVI" v-show="tabId==0 || long">
        <div class="view">
          <div class="title" v-if="long">团队活力</div>
          <tierBarRowChart :data="team_activity.dim_score" :h1="'活力因子'"/>
          <div class="legend">
            <div class="label" :class="'label'+item.tier_id" v-for="(item,i) in team_activity.tier_info" :key="i">
              <div class="circle"></div>{{item.name}}：{{Math.floor(item.score)}}
            </div>
          </div>
          
          <h1 v-if="team_activity.risk_dimension">提示：处于警示区的活力因子带给团队的可能影响。</h1>
          <h1 v-else>提示：当下各层级的领导行为水平较好，请继续保持！</h1>
          <template v-if="team_activity.risk_dimension">
            <h2>处于警示区的有：</h2>
            <p class="risk1" v-for="(it,i) in team_activity.riskText" :key="i">{{it}}</p>
            <h2>可能影响：</h2>
            <p class="risk2" v-for="it in team_activity.risk_dimension_content" :key="it.name">
              <b>{{it.name}}</b>：{{it.content}}
            </p>
          </template>
        </div>
      </li>
      <!-- 领导能量 -->
      <template v-if="team_leadership">
        <li v-show="tabId==1 || long">
          <div class="view">
            <div class="title" v-if="long">领导能量</div>
            <tierBarRowChart :data="team_leadership.dim_score"/>
            <div class="legend" :class="{padding:team_leadership.tier_info.length==1}">
              <div class="label" :class="'label'+item.tier_id" v-for="(item,i) in team_leadership.tier_info" :key="i">
                <div class="circle"></div>{{item.name}}：{{Math.floor(item.score)}}
              </div>
            </div>
            <h1 v-if="team_leadership.is_risk_dimension">说明：处于警示区的领导行为带给团队的可能影响。</h1>
            <h1 v-else>说明：当下各层级的领导行为水平较好，请继续保持！</h1>
            <p class="risk1" style="margin:16px 0" v-for="it in team_leadership.risk_dimension_content" :key="it.name">
              <b>{{it.name}}</b>：{{it.content}}
            </p>
          </div>
        </li>
      </template>
      <!-- 工作价值倾向 -->
      <li v-show="tabId==2 || long">
        <div class="view">
          <div class="title" v-if="long">工作价值倾向</div>
          <div class="rank">
            <div class="row" v-for="item in team_workvalue.tier_info" :key="item.tier_id" :class="'row'+item.tier_id">
              <h5>{{item.name}}</h5>
              <h6 v-for="(it,i) in team_workvalue.top_dimension[item.tier_id]" :key="i"><b>{{i+1}}</b>{{it.name}}</h6>
            </div>
          </div>
          <p style="margin-bottom:12px;color:#d81e06">提示：</p>
          <p v-for="(it,i) in team_workvalue.tips" :key="i" class="risk">{{it}}</p>
        </div>
      </li>
    </ul>
    <div class="img-box" v-if="reportImgShow">
      <p class="title">长按保存图片</p>
      <div class="inner-box"><img :src="reportImg"></div>
      <img @click="closeImg" class="close" src="../../../assets/images/icons/close.png"/>
    </div>
    <div class="mask" v-if="!success"/>
  </div>
</template>
<script>
// import lineCircle from '../vigour/chart/lineCircleChart'
// import lineRect from '../vigour/chart/lineRectChart'
import tierBarRowChart from '../vigour/chart/tierBarRowChart'
export default {
  components:{tierBarRowChart},
  props:{
    report_id:{default: null},
    version: {default: ''}
  },
  data(){
    return{
      theme: this.$route.query.theme || '',
      width: 0,
      tabId: 0,
      success: false,
      hy_session: this.$route.query.hy_session || '',
      long:this.$route.query.long===undefined?false:true,   //是否报告长图
      org_name: '正在加载',                                  //用户姓名
      time: 0,                                              //报告完成时间
      reportImg:'',
      reportImgShow: false,
      timer:'',
      
      // TVI：
        team_activity: {
          tier_info: {},
          dim_score: null,
          risk_dimension: false,
          riskText: []
        },
      // 领导能量:
        team_leadership:{
          tier_info: {},
          dim_score: null,
          risk_dimension_content: [],
        },
      // 工作价值倾向：
        team_workvalue:{
          tier_info: {},
          dim_score: null,
          top_dimension: [],
        }
    }
  },
  created(){
    this.$indicator.open({text: '加载中...',spinnerType: 'fading-circle'});
    this.$http.get(`${this.spaceUrl}/Tvs/getTeamReportInfo?team_report_id=${this.report_id}&is_share=1&type=2`).then((e) => {
      if (!e.data.code) {
        this.success = true
        let data = e.data.data
        this.org_name = data.report_info.org_name
        this.time = data.report_info.report_time

        if (data.team_activity.risk_dimension) {
          data.team_activity.riskText = []
          let {risk_dimension, tier_info} = data.team_activity
          for (let i in tier_info) {
            if (risk_dimension[tier_info[i].tier_id])
              data.team_activity.riskText.push(`${tier_info[i].name}：${risk_dimension[tier_info[i].tier_id]}`)
          }
        }

        this.team_activity = data.team_activity
        this.team_leadership = data.team_leadership

        var {top_dimension, tier_info} = data.team_workvalue
        data.team_workvalue.tips = []
        for (let i in tier_info) {
          let id = tier_info[i].tier_id, text = ''
          top_dimension[id].forEach(item => text += `“${item.name}”、`)
          text = text.replace(/、$/g, '')
          data.team_workvalue.tips.push(`${tier_info[i].name}最看中的是${text}。`)
        }
        this.team_workvalue = data.team_workvalue
      } else {
        this.org_name = '无法加载'
        this.$toast({message:'加载失败',duration: 1000})
      }
      this.$indicator.close();
    }).catch((err)=>{
      console.error(err)
      this.org_name = '无法加载'
      this.$toast({message:'网络错误',duration: 1500})
      this.$indicator.close();
    })
  },
  mounted(){
    this.width = $('#app').width()
  },
  methods:{
    tab(e){
      if(this.tabId==e) return;
      this.tabId = e;
    },
    save(){
      // this.long = true; return;
      if(this.reportImg)
        this.reportImgShow = true
      else
        this.$http.get(this.spaceUrl+'/Tvs/getTeamReportImage?team_report_id='+this.report_id+'&hy_session='+this.hy_session).then((e) => {
          if (e.data.code==0) {
            this.reportImg = e.data.data
            this.reportImgShow = true
          } else { 
            var url = location.href.replace(/http:\/\/localhost/,this.mobileUrl) + (location.hash.includes('?')?'&':'?') + 'long'
            console.log('imgUrl:',url)
            this.$indicator.open({text: '图片生成中...',spinnerType: 'triple-bounce'})
            this.$http.post(this.ajaxUrlPath+'/CardImage/createshotImg',{
              type: 'vigourReport',
              imageurl: url,
              width: '480',
              height: '1000',
            }).then(({data}) => {
              this.$indicator.close()
              if(data.code == 0){
                this.reportImg = data.data.image
                this.reportImgShow = true
              } else {
                this.$toast({message: data.msg})
              }
            })
          }
        })
    },
    closeImg(){
      this.reportImgShow = false
    },
  },
}
</script>
<style scoped lang="scss">
.report{
  color:#808080;
  padding-bottom: 40px;
  .top{
    color: #aaa;
    header{
      display: flex;
      align-items: center;
      font-size: 26px;
      padding: 0 40px;
      height: 120px;
      justify-content: space-between;
      line-height: 1.1;
      .user{
        text-align: left;
        padding-right: 20px;
        font-size: 24px;
        line-height: 1.5;
        b{font-size: 26px}
      }
      .btn{
        width: 130px;
        color: #fff;
        border-radius: 6px;
        background: #2384ff;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 24px;
        flex-shrink: 0;
      }
    }
    nav{
      display: flex;
      border-top: 1px solid #f0f0f0;
      border-bottom: 1px solid #f0f0f0;
      color: #999;
      height: 80px;
      box-sizing: border-box;
      font-size: 26px;
      align-items: center;
      span{
        flex: 1;
        line-height: 1.2;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        border-right: 1px solid #f0f0f0;
        width: 25%;
        box-sizing: border-box;
        transition: .1s;
        &:last-child{border: none}
      }
      .current{
        font-size: 28px;
        color: #4d4d4d;
        font-weight: bold;
        background: #cdebff;
        color: #2384ff;
      }
    }
  }
  .content{
    overflow-x: hidden;
    .title{
      line-height: 80px;
      font-weight: bold;
      font-size: 30px;
      margin-top: 40px;
      color: #272727;
    }
    li{
      margin: 0 30px;
      font-size: 26px;
      h1{
        text-align: left;
        font-weight: bold;
        margin: 20px 0;
        color: #d81e06;
      }
      h2{
        padding-left: 36px;
        text-align: justify;
        position: relative;
        line-height: 1.6;
        color: #d81e06;
        margin-top: 50px;
        font-weight: bold;
      }
      p{
        text-align: justify;
        position: relative;
        line-height: 1.5;
      }
      .risk{
        padding-left: 24px;
        &::before{
          content: '';
          position: absolute;
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background: #7a7a7a;
          left: 4px;
          top: 15px;
        }
      }
      .risk1{
        padding-left: 40px;
        position: relative;
        &::before{position: absolute;content: '';}
        b{color: #545454;}
        &::before{
          background: url('../../../assets/images/report/risk.png') no-repeat;
          background-size: 100%;
          background-position: center;
          width: 28px;
          height: 28px;
          top: 4px;
          left: -2px;
        }
      }
      .risk2{
        padding-left: 40px;
        &::before{
          content: '';
          position: absolute;
          width: 18px;
          height: 18px;
          border-radius: 50%;
          background: #d81e06;
          left: 5px;
          top: 10px;
        }
      }
      .legend{
        width: 76%;
        margin: 0 auto 60px;
        font-size: 22px;
        &::after{
          content: '';
          clear: both;
          display: block;
        }
        .label{
          white-space: nowrap;
          display: flex;
          width: 50%;
          float: left;
          font-size: 26px;
          font-weight: bold;
          padding: 4px 0;
        }
        .circle{
          width: 12px;
          height: 12px;
          border-radius: 50%;
          display: block;
          margin: 10px 10px 0;
        }
      }
      .padding .label:nth-child(1){
        margin-left: 120px;
      }
      .lineLegend{
        margin: -10px 0 80px;
        div{
          font-size: 24px;
          font-weight: bold;
          display: inline-block;
          width: 25%;
          white-space: nowrap;
          box-sizing: border-box;
          padding-left: 24px;
          position: relative;
          text-align: left;
          &::before{
            content: '';
            position: absolute;
            left: 0;
            top: 10px;
            width: 14px;
            height: 14px;
            border-radius: 50%;
          }
        }
      }
      .label1{color: #3ba4e8;&::before{background: #3ba4e8}}
      .label2{color: #28c8b7;&::before{background: #28c8b7}}
      .label3{color: #ed6631;&::before{background: #ed6631}}
      .label4{color: #f147bb;&::before{background: #f147bb}}
    }
    .view{
      margin: 30px 0;
      padding: 1px 20px 60px;
      border-radius: 10px;
    }
    .rank{
      margin: 80px auto;
      width: 650px; 
      font-size: 26px;
      .row{
        display: flex;
        height: 60px;
        margin: 16px 0;
        border: 1px solid #666;
        h5{
          width: 150px;
          line-height: 60px;
          color: #fff;
        }
        h6{
          width: 165px;
          box-sizing: border-box;
          padding-left: 30px;
          line-height: 60px;
          position: relative;
          b{
            position: absolute;
            font-weight: normal;
            left: 12px;
            width: 32px;
            height: 32px;
            line-height: 32px;
            border-radius: 50%;
            background: #ccc;
            color: #fff;
            top: 12px;
            font-size: 24px;
          }
        }
      }
      .row1{
        border-color: #3fb3ff;
        h5{background: #3fb3ff}
        h6{color: #3fb3ff;b{background: #3fb3ff}}
      }
      .row2{
        border-color: #28c8b7;
        h5{background: #28c8b7}
        h6{color: #28c8b7;b{background: #28c8b7}}
      }
      .row3{
        border-color: #ed6631;
        h5{background: #ed6631}
        h6{color: #ed6631;b{background: #ed6631}}
      }
      .row4{
        border-color: #f02f7c;
        h5{background: #f02f7c}
        h6{color: #f02f7c;b{background: #f02f7c}}
      }
    }
  }
  .img-box{
      width: 100%;
      height: 100%;
      position: fixed;
      background: rgba(0,0,0,0.8);
      top: 0;
      left: 0;
      z-index: 99;
      font-size:30px;
      .title{line-height: 100px;color:#fff;}
      .inner-box{
        flex: 1;
        height: 80%;
        margin:0 30px 20px;
        overflow-y: auto;
        img{width: 100%;height: auto;display: block;}
      }
      .close{
        display: block;
        width: 70px;
        height: 70px;
        margin:0 auto;
      }
  }
  .mask{
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 99;
  }
}

.planet{
  min-height: 100%;
  background-color: #2da8ff;
  background-image: url('../../../../static/img/planet_bg14.jpg');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  .top{
    color: #fff;
    nav{
      color: #fff;
      border-color: #fff;
      .current{
        background: #c0e9fa;
        color: #414b5e;
      }
    }
  }
  .content{
    .view{
      background: rgba(255,255,255,.8);
    }
    li{
      .text, p, h1, .risk1 b{color: #414b5e}
    }
  }
}
</style>