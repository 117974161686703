
import Router from 'vue-router'
import HelloWorld from '@/components/HelloWorld'
import sales_report from '@/components/sales/report'
import sales_report_img from '@/components/sales/reportImg'
import sales_inviteReport from '@/components/sales/inviteReport'
import sales_inviteReport_img from '@/components/sales/inviteReportImg'
import delay_report_img from '@/components/saveImg/delayReport'
import topic_report_img from '@/components/saveImg/topicReport'
import MBTI_report_img from '@/components/saveImg/MBTIReport'
import class_wisdom from '@/components/saveImg/class_wisdom'
import class_correct from '@/components/saveImg/class_correct'
import class_value from '@/components/saveImg/class_value'
import expert_confirm from '@/components//expertConfirm'
//王牌团队
import ace_report_self from '@/components/aceTeam/self'
import ace_report_group from '@/components/aceTeam/reportGroup'
//十方/创业小程序
import space_vigour_personal from '@/components/space/vigour/personal'
import space_vigour_group from '@/components/space/vigour/group'
import space_vigour_tier from '@/components/space/vigour/tier'
import space_vts_personal from '@/components/space/VTS/personal'
import space_vts_group from '@/components/space/VTS/group'
import space_vts_tier from '@/components/space/VTS/tier'
import space_professionTend_report from '@/components/space/professionTend/report'
import space_professionTend_report_mini from '@/components/space/professionTend/reportMini'
import space_AMT_report from '@/components/space/AMT/report'
import space_cognition_report from '@/components/space/cognition/report'
import space_LSN_personal from '@/components/space/LSN/personal'
import space_LSN_team from '@/components/space/LSN/team'
import space_VC_report from '@/components/space/VC/report'
import space_card from '@/components/saveImg/card'
//微信h5支付
import wxPayProduct from '@/components/space/wxPay/product'
import wxPayIndex from '@/components/space/wxPay/index'
import wxPayLogin from '@/components/space/wxPay/login'
import wxPaySuccess from '@/components/space/wxPay/success'
//公众号产品购买页h5
// import buyLogin from '@/components/buyPage/login'
import buyLSN from '@/components/buyPage/LSN'
import buyCBC from '@/components/buyPage/CBC'
import buyCBC_zhiyu from '@/components/buyPage/CBC_zhiyu'
import buyTVS from '@/components/buyPage/TVS'
import buyAMT from '@/components/buyPage/AMT'
//产品模块化
import TDA_self from '@/components/planet/TDA/self'
import TDA_intro from '@/components/planet/TDA/buy/intro'
import TDA_shop from '@/components/planet/TDA/buy/shop'
import TDA_detail from '@/components/planet/TDA/buy/detail'
import TDA_car from '@/components/planet/TDA/buy/car'
import TDA_pay from '@/components/planet/TDA/buy/pay'
import TDA_login from '@/components/planet/TDA/buy/login'
import TDA_success from '@/components/planet/TDA/buy/success'
//青鸟幸福家h5
import bee_index from '@/components/sales/bee/index'
import bee_login from '@/components/sales/bee/login'
import bee_my from '@/components/sales/bee/my'
import bee_myCourse from '@/components/sales/bee/my_course'
import bee_myConsult from '@/components/sales/bee/my_consult'
import bee_myOrder from '@/components/sales/bee/my_order'
import bee_consult from '@/components/sales/bee/consult'
import bee_community from '@/components/sales/bee/community'
import bee_welfare from '@/components/sales/bee/welfare'
import bee_course from '@/components/sales/bee/course'
import bee_detail from '@/components/sales/bee/courseDetail'
import bee_play from '@/components/sales/bee/play'
import bee_html from '@/components/sales/bee/HTML'
//top销售力
import topSale_buy from '@/components/sales/topSale/buy'
import topSale_report from '@/components/sales/topSale/report'

export default new Router({
  routes: [
    {path: '/',name: 'HelloWorld',component: HelloWorld},
    {path: '/sales/report', component:sales_report},
    {path: '/sales/reportImg', component:sales_report_img},
    {path: '/sales/inviteReport', component:sales_inviteReport},
    {path: '/sales/inviteReportImg', component:sales_inviteReport_img},
    {path: '/delayReportImg', component:delay_report_img},
    {path: '/topicReport', component:topic_report_img},
    {path: '/MBTIReport', component:MBTI_report_img},
    {path: '/classWisdom', component: class_wisdom},
    {path: '/classCorrect', component: class_correct},
    {path: '/classValue', component: class_value},
    {path: '/confirm', component: expert_confirm},
    //王牌团队
    {path: '/aceTeamSelf/:hy_session/:exam_id', component:ace_report_self},
    {path: '/aceTeamGroup/:report_id', component:ace_report_group},
    //十方/创业小程序
    {path: '/vigourSelf/:exam_id/:hy_session',component:space_vigour_personal},
    {path: '/vigourGroup/:report_id', component:space_vigour_group},
    {path: '/vigourTier/:report_id', component:space_vigour_tier},
    {path: '/vtsSelf/:exam_id/:hy_session',component:space_vts_personal},
    {path: '/vtsTeam/:report_id', component:space_vts_group},
    {path: '/vtsTier/:report_id', component:space_vts_tier},
    {path: '/professionTend/:exam_id/:hy_session', component:space_professionTend_report},
    {path: '/professionTendMini/:exam_id/:hy_session', component:space_professionTend_report_mini},
    {path: '/AMT/report', component:space_AMT_report},
    {path: '/cognitionReport/:exam_id', component:space_cognition_report},
    {path: '/lsnSelf/:exam_id/:hy_session',component:space_LSN_personal},
    {path: '/lsnTeam/:report_id',component:space_LSN_team},
    {path: '/VC/report',component:space_VC_report},
    {path: '/planetCard',component:space_card},
    //微信h5支付
    {path: '/wxPay', component:wxPayProduct},
    {path: '/wxPay/index',component: wxPayIndex},
    {path: '/wxPay/login',component: wxPayLogin},
    {path: '/wxPay/success',component: wxPaySuccess},
    //h5购买页
    {path:'/buyLSN',component: buyLSN},
    {path:'/buyCBC',component: buyCBC},
    {path:'/zypay',component: buyCBC_zhiyu},
    {path:'/buyTVS',component: buyTVS},
    {path:'/buyAMT',component: buyAMT},
    //产品模块化
    {path: '/TDA/intro',component: TDA_intro},
    {path: '/TDA/self',component: TDA_self},
    {path: '/TDA/shop',component: TDA_shop},
    {path: '/TDA/detail',component: TDA_detail},
    {path: '/TDA/car',component: TDA_car},
    {path: '/TDA/pay',component: TDA_pay},
    {path: '/TDA/login',component: TDA_login},
    {path: '/TDA/success',component: TDA_success},
    //青鸟幸福家h5
    {path: '/bee/index',component: bee_index},
    {path: '/bee/login',component: bee_login},
    {path: '/bee/my',component: bee_my},
    {path: '/bee/mycourse',component: bee_myCourse},
    {path: '/bee/myconsult',component: bee_myConsult},
    {path: '/bee/myorder',component: bee_myOrder},
    {path: '/bee/consult',component: bee_consult},
    {path: '/bee/community',component: bee_community},
    {path: '/bee/welfare',component: bee_welfare},
    {path: '/bee/course',component: bee_course},
    {path: '/bee/detail',component: bee_detail},
    {path: '/bee/play',component: bee_play},
    {path: '/bee/html',component: bee_html},
    //top销售力
    {path: '/topsale/buy',component: topSale_buy},
    {path: '/topsale/report',component: topSale_report},
  ],
})