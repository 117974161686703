<template>
    <div class="report-box">
        <div class="head">
            <img :src="avatar" class="user-icon" >
            <p><span >{{nickname}}</span><span>[{{realname}}]</span>的销售潜能分析</p>
            <p class="time" v-if="exam_info.end_time">{{$formatTime(exam_info.end_time,'y-m-d h:m')}}</p>
        </div>
        <div class="s-index">
            <div class="cicle">
                <p class="score" :style="{top:size/2.3+'px'}">{{value}}</p>
            </div>
            <ul class="radar-text" v-if="value >= 85 ">
                <li class="r-f">目前潜能：<span class="red ">特优</span></li>
                <li><span class="bold">建议：</span>提供专业系统的销售训练，大胆给予实践机会，帮助其迅速成长为卓越的销售人员。</li>
            </ul>
            <ul class="radar-text" v-else-if="value >= 75">
                <li class="r-f">目前潜能：<span class="red ">优秀</span></li>
                <li><span class="bold">建议1：</span>提供专业系统的销售训练，主动给予实践机会；</li>
                <li><span class="bold">建议2：</span>定期辅导与激励，帮助其迅速成长为优秀的销售人员。</li>
            </ul>
            <ul class="radar-text" v-else-if="value >= 60">
                <li class="r-f">目前潜能：<span class="red ">良好</span></li>
                <li><span class="bold">建议1：</span>提供专业系统的销售训练，给予适当的实践机会；</li>
                <li><span class="bold">建议2：</span>明确销售目标与行动方案，帮助其迅速成长为合格的销售人员。</li>
            </ul>
            <ul class="radar-text" v-else-if="value >= 45">
                <li class="r-f">目前潜能：<span class="red ">中上</span></li>
                <li><span class="bold">建议：</span>综合考量职业素质及资源，重点沟通销售工作意愿、认知、心态。</li>
            </ul>
            <ul class="radar-text" v-else>
                <li class="r-f">目前潜能：<span class="red ">待挖掘</span></li>
                <li><span class="bold">建议：</span>客观反馈销售潜能状态，倾听对方的职业规划，给与职业发展建议。</li>
            </ul>
        </div>
        <div class="space"></div>
        <div class="explain">
            <p class="e-title">维度说明</p>
            <p><span class="bold">销售心理：</span>摆正定位、端正心态去面对销售工作中发生的各种状况。</p>
            <p><span class="bold">工作认知：</span>对销售工作的职责、义务、责任、未来前景和价值等方面的理解。</p>
            <p><span class="bold">工作心态：</span>对销售工作所持有的评价、心理与行为倾向，包括工作的认真度、责任度、努力程度等。</p>
            <p><span class="bold">产品说明：</span>使客户认识、了解产品的价值和功能。</p>
            <p><span class="bold">异议处理：</span>处理客户的疑问、不满、抱怨。</p>
            <p><span class="bold">成交：</span>使客户同意并购买产品或服务。</p>
            <p><span class="bold">互动关系：</span>与客户之间正向、稳定、良好的互动。</p>
        </div>
        <div class="space"></div>
        <div class="radar-box hide">
            <p class="clearfix">
                <span class="e-btn" v-tap="{methods:showExplain}"><i></i>维度说明</span>
            </p>

            <radar :data="idRadar"></radar>

            <p class="radar-text" v-if="value >= 60">相较整个销售过程而言，{{realname}}在<span class="strong"  v-for="(item,i) in hight_dimension" :key="i">{{item}}<i v-if="i<hight_dimension.length-1">、</i></span>方面潜能较好，在<span class="strong"  v-for="(item,i) in low_dimension" :key="i">{{item}}<i v-if="i<low_dimension.length-1">、</i></span>方面还需要继续关注提升，期待您给与激发与辅导，帮助{{realname}}取得更好的成就！</p>
            <p class="radar-text" v-else-if="value >= 45">相较整个销售过程而言，{{realname}}在<span class="strong"  v-for="(item,i) in low_dimension" :key="i">{{item}}<i v-if="i<low_dimension.length-1">、</i></span>方面需要强化训练。</p>
            <p class="radar-text" v-else>相较整个销售过程而言，{{realname}}暂未表现出明显的优势。</p>
        </div>
        <div class="btn" @click="getReportImage" v-if="origin!='admin'">保存报告</div>
        <div class="img-box" v-if="flagShowImg">
            <div class="inner-box">
                <p class="title">长按保存图片</p>
                <img :src="saveImgUrl" class="save-img">
                <img @click="closeImg" class="close" src="../../assets/images/icons/close.png"/>
            </div>
        </div>
    </div>
</template>

<script>
import circleProgress from './circle-progress'
import radar from './radar'

export default {
    data(){
        return{
            origin: this.$route.query.origin || '',
            flagShowImg: false,
            exam_id: this.$route.query.exam_id,
            session_id:this.$route.query.session_id,
            exam_info:{},
            realname:'',
            nickname:'',
            avatar:'',
            getReportInterval: null,
            type:'salereport',
            saveImgUrl:'',
            value: 0,
            color:['#f7ae7f', '#fe6122'],
            size: 140,
            valueText:'',
            idRadar: null,
            hight_dimension:[],
            low_dimension:[],
            potentiala_dvantage:[],
            improve:[],
        }
    },
    components:{radar},
    mounted() {
        this.getReportInfo();
        document.title = '销售潜能分析SPA'
    },
    destroyed() {
        clearInterval(this.getReportInterval)
    },
    methods:{
        showExplain(){
            let target = $('.radar-box');
            if(target.hasClass('hide')){
                $('.radar-box').removeClass('hide');
                $('.radar-box').animate({'margin-top': '0'},500);
            }else{
                $('.radar-box').animate({'margin-top': '-339px'},500);
                $('.radar-box').addClass('hide')
            }
        },
        subString(str,len){
            str = str.length>len? str.substring(0,len)+'...':str;
            return str
        },
        getReportInfo(){
            this.$http.post(this.ajaxUrlPath+'/Sale/getReportInfo',{
                exam_id: this.exam_id,
                type:'2'                    
            },{emulateJSON:true}).then(resp=>{
                if(resp.data.code == 0){
                    let exam_info = resp.data.data.exam_info;
                    let nickname = exam_info.nickname || '';
                    nickname = this.subString(nickname,5);
                    let realname = exam_info.realname || '';
                    realname = this.subString(realname,5);
                    this.exam_info = exam_info;
                    this.realname = realname;
                    this.nickname = nickname;
                    this.avatar = exam_info.avatar || '../../assets/images/icons/user_icon.png';
                    let report_data = resp.data.data.report_data
                    let customer_report = report_data.customer_report;
                    let dimension_score = customer_report.dimension_score;
                    let names = [];
                    let scores = [];
                    for(let key in dimension_score){
                        if(exam_info.template_id==1){   //此判断必不可少
                            scores.push(dimension_score[key]);
                            names.push(key);
                        }else{
                            scores.push(dimension_score[key].getPercentileValue);
                            names.push(key);
                        }
                        
                    }
                    this.idRadar = {
                        id: exam_info.template_id,
                        names: names,
                        scores: scores
                    };
                    let hight_dimension = customer_report.hight_dimension;
                    let low_dimension = customer_report.low_dimension;
                    this.low_dimension = low_dimension;
                    this.hight_dimension = hight_dimension;
                    let user_report = report_data.user_report;
                    let value = user_report.sale_score;
                    let potentiala_dvantage = user_report.potentiala_dvantage;
                    let improve = user_report.improve;
                    this.improve = improve;
                    this.potentiala_dvantage = potentiala_dvantage;
                    this.value = value;

                    $('.cicle').circleProgress({
                        size:this.size,
                        value: 220/360*this.value/100,
                        lineCap: 'round',
                        fill: { gradient: this.color },
                        animation: false,
                        emptyFill: '#feede2',
                        start:Math.PI*(0.5+0.5*7/9),
                        end:Math.PI * (2+0.5*2/9),
                    });
                }else{
                    this.$toast({
                        message:resp.data.msg,
                        duration: 1000,
                    })
                    wx.miniProgram.reLaunch({
                        url: '/pages/salesPotentiality/management/management',
                        success: function(){
                            console.log('success')
                        },
                        fail: function(){
                            console.log('fail');
                        },
                        complete:function(){
                            console.log('complete');
                        }
                    })
                }
            })
        },
        creatImg(){
            let imgUrl = this.mobileUrl+'/#/sales/inviteReportImg?exam_id='+this.exam_id;
            this.$http.post(
                this.ajaxUrlPath+'/CardImage/create', 
                {
                    type: this.type,
                    imageurl: imgUrl,
                    width:'400',
                    height:'700',
                },
                {emulateJSON:true}
            ).then(resp=>{
                let data = resp.data;
                data = typeof data === 'string' ? JSON.parse(data):data;
                if(data.code == 0){
                    let messageId = data.data.messageId;
                    this.$indicator.open({
                        text: '图片生成中...',
                        spinnerType: 'triple-bounce'
                    });
                    this.getReportInterval = setInterval(()=>{
                        this.getreport(messageId);
                    },1000)
                }
            })
        },
        getreport(messageId){
            this.$http.post(
                this.ajaxUrlPath+'/CardImage/getreport',
                {
                    type: this.type,
                    messageId: messageId,
                },
                {emulateJSON:true}
            ).then(resp=>{
                let data = resp.data;
                data = typeof data === 'string' ? JSON.parse(data):data;
                if(data.code == 0){
                    this.$indicator.close();
                    this.saveImgUrl = data.data.image;
                    this.fnShowImg();
                    clearInterval(this.getReportInterval);
                    this.updateReportImage();
                }else{

                }
            })
        },
        getReportImage(){
            this.$http.post(
                this.ajaxUrlPath+'/Sale/getReportImage',
                {
                    type: 2,
                    exam_id:this.exam_id,
                    hy_session:this.session_id,
                },
                {emulateJSON:true}
            ).then(resp=>{
                let data = resp.data;
                data = typeof data === 'string' ? JSON.parse(data):data;
                if(data.code == 0){
                    let report_image = data.data.report_image;
                    if(report_image){
                        this.saveImgUrl = data.data.report_image;
                        this.fnShowImg();
                    }else{
                        this.creatImg();
                    }
                }else{
                    this.creatImg();
                }
            })
        },
        updateReportImage(){
            this.$http.post(
                this.ajaxUrlPath+'/Sale/updateReportImage',
                {
                    type: 2,
                    exam_id:this.exam_id,
                    report_image:this.saveImgUrl,
                    hy_session:this.session_id,
                },
                {emulateJSON:true}
            ).then(resp=>{
                let data = resp.data;
                data = typeof data === 'string' ? JSON.parse(data):data;
                if(data.code == 0){
                    
                }else{
                    
                }
            })
        },
        fnShowImg(){
            this.flagShowImg = true;
        },
        closeImg(){
            this.flagShowImg = false;
        },
    },
}
</script>

<style lang="scss" scoped>
.report-box{
    padding-bottom: 170px;
    position:relative;
}
.btn{
    position:fixed;
    width: 100%;
    left: 0;
    bottom: 0;
    height: 138px;
    border-top: 1px solid #ededed;
    background:#fff;
    line-height: 138px;
    font-size: 28px;
    color:#6f6f6f;
}
.head{
    border-bottom: 1px solid #ededed;
    position: relative;
    padding: 37px 30px 33px 110px;
    font-size:26px;
    color:#7e7e7e;
    text-align:left;
}
.user-icon{
    width: 60px;
    height: 60px;
    position: absolute;
    border-radius:100%;
    left: 30px;
    top: 50%;
    margin-top: -30px;
}
.space{
    background:#f7f7f7;
    height: 9PX;
}
.s-index{
    padding: 58px 0 77px;
    position: relative;
    font-size:28px;
    color:#6f6f6f;
    .score{
        position: absolute;
        left: 50%;
        z-index: 2;
        width: 100px;
        height: 100px;
        line-height: 100px;
        text-align: center;
        margin-left: -50px;
        margin-top: -50px;
    }
}
.cicle{
    font-size: 52px;
    color: #fe6122;
    position: relative;
    width: 130PX;
    height: 130PX;
    margin: auto;
    background: url(../../assets/images/report/circle-bg.png) no-repeat;
    background-size: 88% ;
    background-position: 8PX 15%;
    canvas{
        display: block;
    } 
}
.radar-text{
    text-align: left;
    font-size:28px;
    color:#6f6f6f;
    width: 640px;
    margin: auto;
    li{
        list-style: disc;
    }
    .r-f{
        list-style: none;
        text-align: center;
        margin-bottom: 20px;
    }
    .red{
        font-weight:bold;
        color:#fe6224;
    }
    .strong{
        font-weight:bold;
        color:#3e3e3e;
        padding: 0 10px;
    }
}
.img-box{
    width: 100%;
    height: 100%;
    position: fixed;
    background: rgba(0,0,0,0.8);
    top: 0;
    left: 0;
    z-index: 3;
    overflow-y: scroll;
    text-align: center;
    font-size:30px;
    .inner-box{
        height: 100%;
    }
    .title{
        line-height: 100px;
        color:#fff;
    }
    .close{
        width: 60px;
        height: 60px;
        margin: 0 auto 20px;
    }
    .save-img{
        width: auto;
        height: 80%;
        margin: 0 auto 20px;
        display: block;
        max-width:400PX;
        max-height: 700PX;
    }
}
.explain{
    font-size: 28px;
    color: #6f6f6f;
    padding: 78px 50px 0;
    height: 330PX;
    box-sizing: border-box;
    background: #fff;
    text-align: justify;
    .e-title{
        text-align: center;
        margin-bottom: 40px;
        font-weight: 600;
        color:#2c44fd;
    }
    .bold{
        color: #3e3e3e;
    }
}
.bold{
    font-weight: bold;
}
.radar-box{
    padding-top: 36px;
    background: #fff;
    position: relative;
    margin-top: -309PX;
}
.e-btn{
    display: inline-block;
    float: right;
    margin-right: 50px;
    font-size: 28px;
    color:#2c44fd;
    display: flex;
    align-items: center;
    padding: 8px 15px;
    border: 1PX solid #2c44fd;
    border-radius: 8px;
    i{
        width: 25px;
        height: 27px;
        display: inline-block;
        background: url(../../assets/images/icons/explain.png) no-repeat;
        background-size: 100%;
        margin-right: 5px;
    }
}
</style>