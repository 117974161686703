<template>
  <div class="topsaleBuy">
    <div class="main">
      <h1>选择购买方式</h1>
      <ul class="flex-b">
        <li :class="{active:tab==0}" @click="changeTab(0)">
          <div>
            <h2>按量购买</h2>
            <h3></h3>
            <h4>¥ 19.9/个</h4>
          </div>
        </li>
        <li :class="{active:tab==1}" @click="changeTab(1)">
          <div>
            <h2>优惠包购买</h2>
            <h3>原价 ¥ 199</h3>
            <h4>¥ 99/包</h4>
            <h5>（含10个）</h5>
          </div>
        </li>
      </ul>
      <div class="box">
        <div class="input flex-b-c">
          选择购买{{tab==0?'个数':'优惠包'}}
          <div class="flex">
            <span @click="changeNum(-1)">-</span>
            <input type="text" v-model="buyNum" maxlength="3">
            <span @click="changeNum(1)">+</span>
          </div>
        </div>
        <p>合计：<b>¥ {{totalPrice}}</b></p>
      </div>
      <div class="buyBtn" @click="pay">确认支付  ¥ {{totalPrice}}</div>

      <div class="desc">
        <h6>购买说明</h6>
        <p>购买成功后请根据提示打开小程序进行评估，后续可直接在小程序继续答题或查看报告</p>
        <p>账号长期有效，可用于自评或邀请好友进行评估，并可查看好友的报告</p>
        <p>如有疑问，请添加企业微信或致电咨询客服</p>
        <img src="../../../assets/images/kefuQrcode.jpg">
        <a href="tel:18027418722">手机：18027418722</a>
      </div>
    </div>
    <div class="succssed" v-if="success">
      <img src="../../../assets/images/icons/success_topsale.png" class="icon">
      <h5>支付成功</h5>
      <h6>点击下方按钮前往小程序端进行评估管理</h6>
      <wx-open-launch-weapp username="gh_63b94156b5e7" :path="path">
        <script type="text/wxtag-template">
          <div style="line-height: 40px;background: #e96118;color: #fff;border-radius: 4px;letter-spacing: 2px;margin: 50px 30px 20px;font-size: 14px;text-align: center;width:300px">进入小程序</div>
        </script>
      </wx-open-launch-weapp>
    </div>
  </div>
</template>
<script>
export default {
  data(){
    return {
      handler_id: this.$route.query.handler_id || '',
      tab: 0,
      buyNum: 1,
      totalPrice: '19.90',
      path: 'pages/TopSale/3_manage/manage',
      pro: [{id: 29, price: '19.90'}, {id: 30, price: '99.00'}],
      success: false, //支付成功
    }
  },
  created(){
    document.title = 'Top销售力'
    this.$get(this.spaceUrl+'/User/checkWeixinLogin').then(() => {}, (e) => {
      if (e.code == 1 && location.href.indexOf('//localhost') < 0) location.href = `${this.spaceUrl}/Product/index?url=${encodeURIComponent(location.href)}`
    })
    //微信分享
    wx.ready(() => {
      wx.onMenuShareAppMessage({
        title : 'Top销售力',
        link : `${this.spaceUrl}/Product/index?url=${encodeURIComponent(this.mobileUrl)}%2F%23%2Ftopsale%2Fbuy`,
        imgUrl : `${this.mobileUrl}/static/img/p-16.jpg`,
      })
    })
  },
  methods:{
    changeTab(i) {
      this.tab = i
      this.buyNum = '1'
      this.totalPrice = this.pro[i].price
    },
    changeNum(n) {
      let res = parseInt(this.buyNum) + n
      if (res > 0 && res < 1000) this.buyNum = res + ''
    },
    pay() {
      if (!this.buyNum) {
        this.$toast({message: '请输入购买数量', duration: 1500})
        return
      }
      this.$indicator.open({text: '支付中...', spinnerType: 'fading-circle'})
      this.$get(this.spaceUrl+'/Product/createSaleOrder', {
        product_id: this.pro[this.tab].id,
        number: this.buyNum,
        handler_id: this.handler_id,
      }).then(d => {
        WeixinJSBridge.invoke('getBrandWCPayRequest', d, res => {
          if (res.err_msg == "get_brand_wcpay_request:ok") {
            this.success = true
          } else {
            this.$toast({message: '支付失败'})
          }
        })
      }, e => {
        if (e.code == 3) {
          location.href = `${this.spaceUrl}/Product/index?url=${encodeURIComponent(location.href)}`
        } else {
          this.$toast({message: e.msg})
        }
      }).then(() => this.$indicator.close())
    },
  },
  watch: {
    buyNum(val){
      if (val.startsWith('0')) val = val.substring(1)
      this.buyNum = val.replace(/[^\d]/g, '')
      this.totalPrice = (this.buyNum * this.pro[this.tab].price).toFixed(2)
    }
  }
}
</script>
<style lang="scss" scoped>
.topsaleBuy{
  .main{
    background: linear-gradient(#fef8ed,#fff4ed);
    padding: 40px;
    h1{
      font-size: 28px;
      margin: 0 20px 40px;
      text-align: left;
      color: #1a1a1a;
    }
    ul{
      margin: 0 20px;
      li{
        background: #e8e2de;
        padding: 6px;
        border-radius: 30px;
        border-top-left-radius: 0;
        cursor: pointer;
        div{
          background: #fff;
          border-radius: 24px;
          border-top-left-radius: 0;
          width: 280px;
          height: 330px;
          text-align: center;
        }
        h2{
          color: #8a764b;
          font-size: 28px;
          padding: 40px 0;
        }
        h3{
          font-size: 24px;
          color: #808080;
          text-decoration: line-through;
          line-height: 24px;
          height: 24px;
        }
        h4{
          color: #8a764b;
          font-size: 36px;
          margin-top: 30px;
          font-weight: bold;
        }
        h5{
          color: #8a764b;
          font-size: 30px;
          margin-top: 6px;
        }
      }
      .active{
        background: linear-gradient(#fea51a,#e96118);
        div{background: #ffeee0;}
        h2{color: #1a1a1a;font-weight: bold;}
        h4,h5{color: #eb6655}
      }
    }
    .box{
      padding: 30px;
      margin: 40px 20px;
      border-radius: 20px;
      // box-shadow: 0 0 16px rgba(247,188,140,.4);
      background: #fee8df;
      color: #1a1a1a;
      .input{
        color: #1a1a1a;
        font-size: 28px;
        div{
          border: 1px solid #fda21e;
          text-align: center;
          border-radius: 8px;
        }
        span{
          width: 54px;
          line-height: 54px;
          font-size: 36px;
          color: #292929;
        }
        input{
          width: 80px;
          color: #1a1a1a;
          text-align: center;
          border-left: 1px solid #fda21e;
          border-right: 1px solid #fda21e;
        }
      }
      p{
        text-align: right;
        font-size: 30px;
        margin-top: 30px;
        b{color: #eb6655}
      }
    }
    .buyBtn{
      margin: 50px 20px 40px;
      line-height: 90px;
      border-radius: 50px;
      box-shadow: 0 4px 18px rgba(255,126,69,.3);
      font-size: 32px;
      color: #fff;
      background: linear-gradient(to right,#fab065,#f86e64)
    }
    .desc{
      background: #fee8df;
      border-radius: 20px;
      padding: 30px 50px 60px;
      h6{
        font-size: 28px;
        color: #353535;
        line-height: 60px;
        margin-bottom: 20px;
      }
      p{
        font-size: 24px;
        text-align: justify;
        position: relative;
        padding-left: 30px;
        color: #666;
        line-height: 1.6;
        margin: 16px 0;
        &::before{
          content: '';
          position: absolute;
          left: 0;
          top: 8px;
          width: 16px;
          height: 16px;
          border-radius: 10px;
          background: linear-gradient(to right,#fab065,#f86e64);
        }
      }
      img{
        display: block;
        width: 200px;
        margin: 50px auto 30px;
      }
      a{color: #e96118}
    }
  }
  .succssed{
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
    width: 100%;
    height: 100%;
    padding-top: 240px;
    user-select: none;
    white-space: nowrap;
    background: #fff;
    text-align: center;
    .icon{
      display: block;
      width: 180px;
      height: 180px;
      background: #ffe9da;
      border-radius: 50%;
      margin: 20px auto;
    }
    h5{
      font-size: 40px;
      color: #4d4d4d;
      font-weight: bold;
    }
    h6{
      color: #999;
      font-size: 26px;
      line-height: 100px;
    }
    .btn{
      line-height: 80px;
      background: #ccc;
      color: #fff;
      border-radius: 8px;
      letter-spacing: 2px;
      margin: 100px 60px 40px;
      font-size: 28px;
      text-align: center;
    }
  }
}
</style>