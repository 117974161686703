<template>
  <svg class="pie_tier"></svg>
</template>

<script>
import * as d3 from 'd3'
export default {
  props: {
    data: {
    },
    width: {    //宽高度
      type: Number,
    },
  },
  data(){
    return{
      centerX: 0,
      centerY: 0,
      color: ['#f6d48d','#c7e4b5','#a7c3f3','#f4c1b6'],
    }
  },
  mounted(){
    if(this.data){
      this.draw(this.data)
      return
    }
    this.$watch('data', (val) => {
      this.draw(val)
    })
  },
  methods:{
    compute(r,percent){
      if(percent==1) percent = 0.999
      let angel = percent * 2 * Math.PI
      return [this.centerX + Math.sin(angel) * r, this.centerY - Math.cos(angel) * r]
    },
    draw(data){
      let height = this.width * 0.5
      let svg = d3.select(this.$el).attr('width', this.width).attr('height', height)
      let R = height * 0.7 * 0.5   //圆半径
      let centerX = this.centerX = this.width / 2         //x轴中点
      let centerY = this.centerY = height / 2             //y轴中点
      let gap =  height * 0.15
      
      //计算坐标
      let point = [],
          total = 0,      // 总分
          linePoint = [], // 折线坐标字符串
          textPoint = []   // 折线终点坐标
      for(let i = 0; i <data.length; i++){
          total += data[i].percent
          point.push(this.compute(R,total)) //各饼状弧终点坐标（顺时针）
          let p = this.compute(R, total - data[i].percent/2)//弧形中点坐标
          if(p[0]>centerX){
            linePoint.push(`${p[0]},${p[1]} ${p[0]+R/2},${p[1]}`)
            textPoint.push([p[0]+R+10, p[1]])
          }else{
            linePoint.push(`${p[0]},${p[1]} ${p[0]-R/2},${p[1]}`)
            textPoint.push([p[0]-R-10, p[1]])
          }
      }
      let circle = svg.append('g').classed('circle',true)
      let line = svg.append('g').classed('line', true)
      if(point.length==1){
        circle.append('circle')
              .attr('cx', centerX)
              .attr('cy', centerY)
              .attr('r', R)
              .attr('stroke', 'none')
              .attr('fill', '#f6d48d');
        line.append('polyline')
          .attr('fill', 'none')
          .attr('stroke', '#f6d48d')
          .attr('stroke-width', 1)
          .attr('points', `${centerX-R},${centerY} ${centerX-R-20},${centerY}`)
        line.append('text')
            .attr('font-size', R*.2)
            .attr('fill', '#999')
            .attr('y', centerY-8)
            .append('tspan')
            .attr('x', centerX-R-44)
            .text(data[0].name)
            .append('tspan')
            .attr('x', centerX-R-44)
            .attr('dy', '1.2em')
            .text(data[0].number+'人')
        return
      }
      for(let i=0; i < point.length; i++){
        let p = point[i], startP
        if(i) startP = point[i-1]
        else startP = [centerX,gap]
        circle.append('path')
              .classed('pie',true)
              .attr('fill', this.color[i])
              .attr('stroke', '#fff')
              .attr('stroke-width',()=>{
                if(i==data.length-1) return 1
                else return 2
              })
              .attr('d', `M${startP[0]} ${startP[1]} A${R} ${R} 0 ${data[i].percent>.5?1:0} 1 ${p[0]} ${p[1]} L${centerX} ${centerY}`)
        line.append('polyline')
          .attr('fill', 'none')
          .attr('stroke', this.color[i])
          .attr('stroke-width',1)
          .attr('points', linePoint[i])
        line.append('text')
            .attr('font-size', R*.2)
            .attr('fill', '#999')
            .attr('x', textPoint[i][0])
            .attr('y',  ()=>{
              if(i==point.length-1&&point.length>3){
                return textPoint[i][1] - 8
              }else{
                return textPoint[i][1]
              }
            })
            .text(data[i].name+data[i].number+'人')
            // .append('tspan')
            // .attr('x', textPoint[i][0])
            // .attr('dy', '1.2em')
            // .text(data[i].number+'人')
      }
    }
  },
}
</script>
<style scope lang="scss">
.pie_tier{
  display: block;
  margin: 180px auto -10px;
  .arc_text{
    text-anchor: middle;
    dominant-baseline: middle;
  }
  tspan{text-anchor: middle;}
  .line{
    opacity: 0;
    text{
      text-anchor: middle;
      dominant-baseline: middle;
    }
    animation: tierFade .6s .4s forwards;
  }
}
@keyframes tierFade {
  100%{opacity: 1;}
}
</style>
