<template>
  <div class="content">
    <div v-html="content" />
  </div>
</template>
<script>
export default {
  data(){
    return {
      content: sessionStorage.getItem('html') || '',
    }
  },
  mounted(){},
  methods:{},
}
</script>
<style lang="scss" scoped>
.content{
  text-align: left;
}
</style>