import { render, staticRenderFns } from "./group2.5.vue?vue&type=template&id=34b5f3d3&scoped=true"
import script from "./group2.5.vue?vue&type=script&lang=js"
export * from "./group2.5.vue?vue&type=script&lang=js"
import style0 from "./group2.5.vue?vue&type=style&index=0&id=34b5f3d3&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "34b5f3d3",
  null
  
)

export default component.exports