<template>
  <div class="barBox">
    <label v-if="label">{{label}}</label>
    <div class="line" :class="{shorter:label}">
      <div class="bar" :class="'bar'+levelIndex">
        <span :style="{width:getWidth(score,1)+'%'}"><i v-show="score"></i></span>
      </div>
      <div class="scale" >
        <span :class="'bot'+i" :style="`width:${100/mark.length}%`" v-for="(it,i) in mark" :key="i">
          <i v-for="(it,j) in 20" :key="it" :style="{height:j%5==0?'10px':'5px'}"></i>
          <b>{{it.name}}</b>
        </span>
        <div class="refer" v-if="refer" :style="{left:getWidth(refer)+'%'}"></div>
      </div>
    </div>
    <p v-if="text">{{text}}<span :class="'color'+levelIndex" v-if="levelIndex!==''">{{mark[levelIndex].name}}</span></p>
  </div>
</template>
<script>
export default {
  props: {
    score: {
      default: 0,
    },
    refer: {
      type: Number,
      default: 0,
    },
    mark: {
      type: Array,
      default: ()=>[]
    },
    label: {
      type: String,
    },
    text: {
      default: ''
    },
  },
  data(){
    return {
      levelIndex: '',
    }
  },
  methods:{
    getWidth(s, set = false){
      let len = this.mark.length, each = 1 / len || 0, w = 0;
      for (let i = 0; i < len; i++) {
        let item = this.mark[i]
        if (s < item.max) {
          if (set) this.levelIndex = i
          w = ((s - item.min) / (item.max - item.min) || 0) * each + each * i
          break
        }
      }
      return w * 100
    }
  },
}
</script>
<style lang="scss" scoped>
.barBox{
  margin:  140px auto 100px;
  font-size: 0;
}
label{
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 134px;
  text-align: center;
  line-height: 80px;
  vertical-align: top;
  color: #4d4d4d;
  font-size: 26px;
  font-weight: bold;
}
.line{
  display: inline-block;
  width: 654px;
  margin-bottom: 70px;
}
.shorter{width: 520px;}
.bar{
  height: 32px;
  border-radius: 20px;
  background: #e5e5e5;
  position: relative;
  span{
    position: absolute;
    height: 100%;
    width: 0%;
    border-radius: 20px;
    background-image: linear-gradient(to right,#9be7f8,#07b7dc);
    top: 0;
    left: 0;
    animation: w0 1s;
    @keyframes w0 {0%{width: 0}}
    i{
      position: absolute;
      right: 6px;
      bottom: 120%;
      border-left: 10px transparent solid;
      border-right: 10px transparent solid;
      border-top: 24px #ccc solid;
    }
  }
}
.bar0 span{
  background-image: linear-gradient(to right,#69e8b4,#33b580);
  i{border-top-color: #33b580}
}
.bar1 span{
  background-image: linear-gradient(to right,#9be7f8,#07b7dc);
  i{border-top-color: #0eb9dc}
}
.bar2 span{
  background-image: linear-gradient(to right,#f4b27c,#f87912);
  i{border-top-color: #ea7e27}
}
.bar3 span{
  background-image: linear-gradient(to right,#fab0b5,#eb4a54);
  i{border-top-color: #e84f59}
}
.scale{
  margin-top: 16px;
  font-size: 0;
  position: relative;
  span{
    display: inline-block;
    font-size: 0;
    position: relative;
    b{
      position: absolute;
      width: 100%;
      height: 40px;
      line-height: 40px;
      left: 0;
      top: 120%;
      text-align: center;
      font-size: 26px;
    }
  }
  i{
    vertical-align: top;
    display: inline-block;
    width: 5%;
    box-sizing: border-box;
    border-left: 1px solid #ccc;
  }
  .bot0{
    i{border-color: #33b580}
    b{color: #33b580;}
  }
  .bot1{
    i{border-color: #0eb9dc}
    b{color: #0eb9dc;}
  }
  .bot2{
    i{border-color: #ea7e27}
    b{color: #ea7e27;}
  }
  .bot3{
    i{border-color: #e84f59}
    b{color: #e84f59;}
  }
  .refer{
    position: absolute;
    left: 0%;
    top: -84px;
    border-left: 1px dashed #df5342;
    height: 140px;
    &::before{
      content: '参考线';
      position: absolute;
      color: #808080;
      font-size: 22px;
      width: 80px;
      text-align: center;
      left: -40px;
      top: -40px;
    }
  }
}
p{
  color: #4d4d4d;
  font-weight: bold;
  text-align: center;
  font-size: 25px;
}
.color0{color: #33b580;font-size: 28px;}
.color1{color: #0eb9dc;font-size: 28px;}
.color2{color: #ea7e27;font-size: 28px;}
.color3{color: #e84f59;font-size: 28px;}
</style>