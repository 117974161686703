<template>
    <div class="report-img-box">
        <div class="line-bg"></div>
        <div class="s-index">
            <div class="cicle">
                <p class="score" :style="{top:size/2.3+'px'}">{{value}}</p>
            </div>
            <ul class="radar-text" v-if="value >= 85 ">
                <li class="r-f">目前潜能：<span class="red ">特优</span></li>
                <li>建议：大胆尝试，积极实践，你将会迅速成长为一名卓越的销售人员。</li>
            </ul>
            <ul class="radar-text" v-else-if="value >= 75">
                <li class="r-f">目前潜能：<span class="red ">优秀</span></li>
                <li>重点发展：<span class="red ">“{{potentiala_dvantage[0]}}”</span>和<span class="red ">“{{potentiala_dvantage[1]}}”</span>;</li>
                <li>重点改善：<span class="red ">“{{improve[0]}}”</span>和<span class="red ">“{{improve[1]}}”</span>;</li>
                <li>建议：主动学习，刻意练习，你将会迅速成长为优秀的销售人员。</li>
            </ul>
            <ul class="radar-text" v-else-if="value >= 60">
                <li class="r-f">目前潜能：<span class="red ">良好</span></li>
                <li>激发培养：<span class="red ">“{{potentiala_dvantage[0]}}”</span>；</li>
                <li>强化改善：<span class="red ">“{{improve[0]}}”</span>；</li>
                <li>建议：增强信心，明确目标，积极训练，你的销售潜能将会得以施展。</li>
            </ul>
            <ul class="radar-text" v-else-if="value >= 45">
                <li class="r-f">目前潜能：<span class="red ">中上</span></li>
                <li>重点强化：<span class="red ">“{{potentiala_dvantage[0]}}”</span>；</li>
                <li>重点提升：<span class="red ">“{{improve[0]}}”</span>；</li>
                <li>建议：了解销售工作的意义与价值，结合个人的实际情况，制定发展目标。</li>
            </ul>
            <ul class="radar-text" v-else>
                <li class="r-f">目前潜能：<span class="red ">待挖掘</span></li>
                <li>培养提升：<span class="red ">“{{potentiala_dvantage[0]}}”</span>，<span class="red ">“{{improve[0]}}”</span>；</li>
                <li>建议：思考未来的职业发展方向，合理规划自己工作。</li>
            </ul>
        </div> 
        <div class="adv-box">
            <p class="title">潜能优势</p>
            <ul class="clearfix">
                <li v-for="(item,i) in potentiala_dvantage" :key="i" :style="potentiala_dvantage.length==1?'width:100%':''">
                    <div class="value-bg"><p class="value-1" :style="{width:v1+'%'}"></p></div>
                    <p>{{item}}</p>
                </li>
            </ul>
        </div>
        <div class="space"></div>
        <div class="adv-box improve-box">
            <p class="title">改善提升</p>
            <ul class="clearfix">
                <li v-for="(item,i) in improve" :key="i" :style="improve.length==1?'width:100%':''">
                    <div class="value-bg"><p class="value-1" :style="{width:v3+'%'}"></p></div>
                    <p>{{item}}</p>
                </li>
            </ul>
        </div>
    </div>
</template>

<script>
export default {
    props:['reportData'],
    data(){
        return{
            value: 0,
            potentiala_dvantage:[],
            potentiala_dvantage_content:'',
            improve:[],
            improve_content:'',
            color:['#f7ae7f', '#fe6122'],
            size: 110,
            v1: 77,
            v3: 50,
        }
    },
    methods: {
        init(){
            if(this.reportData){
                let data = this.reportData;
                let value = data.sale_score
                this.value = value;
                this.potentiala_dvantage = data.potentiala_dvantage;
                this.potentiala_dvantage_content = data.potentiala_dvantage_content;
                this.improve = data.improve;
                this.improve_content = data.improve_content;
            }
        },
    },
    mounted() {
        this.init()
        this.$nextTick(() => {
            $('.cicle').circleProgress({
                size:this.size,
                value: 220/360*this.value/100,
                lineCap: 'round',
                fill: { gradient: this.color },
                animation: false,
                emptyFill: '#feede2',
                start:Math.PI*(0.5+0.5*7/9),
                end:Math.PI * (2+0.5*2/9),
            });
        })   
        
    },
}
</script>

<style lang="scss" scoped>
.report-img-box{
    position: relative;
    background: #fff;
    width: 680px;
    margin: -270px auto 0;
}
.line-bg{
    background: #903005;
    width: 710px;
    border-radius: 14px;
    height: 28px;
    position: absolute;
    top: -11px;
    left: 50%;
    margin-left: -355px;
}
.s-index{
    font-size: 28px;
    text-align: center;
    padding-top: 20px;
    box-shadow: inset 0px 0px 15px rgba(253, 122, 25, 0.45);
    padding-bottom: 37px;
    position: relative;
    z-index: 1;
    background: #fff;
    .cicle{
        font-size: 52px;
        color: #fe6122;
        position: relative;
        width: 100PX;
        height: 100PX;
        margin: auto;
        background: url(../../assets/images/report/circle-bg.png) no-repeat;
        background-size: 83% ;
        background-position: 9PX 20%;
        canvas{
            display: block;
        } 
    }
    .score{
        position: absolute;
        left: 50%;
        z-index: 2;
        width: 100px;
        height: 100px;
        line-height: 100px;
        text-align: center;
        margin-left: -50px;
        margin-top: -50px;
    }
}
.adv-box{
    color: #6f6f6f;
    font-size: 28px;
    padding-top: 38px;
    box-shadow: 0px 0px 15px rgba(254, 97, 34, 0.1);
    position: relative;
    z-index: 2;
    background: #fff;
    padding-bottom:30px;
    .title{
        color: #fe6122;
        text-align: center;
        margin-bottom: 50px;
    }
    .line{
        border-top: 1PX solid rgba(229, 229, 229, 0.58);
        text-align: center;
        padding: 20px 0 24px;
        width: 654px;
        margin: 35px auto 0;
        .color{
            color: #fe6122;
            font-weight: bold;
        }
    }
    ul{
        width: 560px;
        margin: auto;
    }
    li{
        width: 50%;
        float: left;
        text-align: center;
    }
    .value-bg{
        width: 154px;
        background: #f0e9e6;
        height: 16px;
        border-radius: 10px;
        margin: 0 auto 32px;
    }
    .value-1{
        height: 100%;
        border-radius: 10px;
        background: linear-gradient(90deg, #fe581a, #feb24f);
    }
}
.improve-box{
    .title{
        color: #fc3146;
    }
    .value-1{
        background: linear-gradient(90deg, #ff3d4f, #ff5598);
    }
    .line{
        
        .color{
            color: #fc3146;
        }
    }
}
.space{
    height: 40px;
    margin-top: -20px;
    box-shadow: inset 0px 0px 15px rgba(253, 122, 25, 0.45);
}
.value-text{
    font-weight: bold;
    color: #fe6122;
    font-size: 30px;
}
.radar-text{
    text-align: left;
    font-size:30px;
    color:#6f6f6f;
    width: 580px;
    margin: auto;
    li{
        list-style: disc;
    }
    .r-f{
        list-style: none;
        text-align: center;
        margin-bottom: 20px;
    }
    .red{
        font-weight:bold;
        color:#fe6224;
    }
}
</style>