var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{attrs:{"id":"AMT"}},[_c('div',{staticClass:"info flex--c"},[_c('img',{attrs:{"src":_vm.avatar}}),_c('div',[_c('div',{staticClass:"name"},[_vm._v(_vm._s(_vm.name)+" 的领导者画像")]),_c('div',[_vm._v(" "+_vm._s(_vm.endTime||'')+"      "),(_vm.version)?_c('span',[_vm._v("版本号 v"+_vm._s(_vm.version))]):_vm._e()])])]),_c('div',{staticClass:"main main1",attrs:{"id":"main"}},[_c('div',{staticClass:"starBox"},[_c('b',[_vm._v("领导思维清晰度:")]),_vm._l((5),function(item){return _c('i',{key:item,class:{
        star2: item <= _vm.star,
        star3: _vm.star < item && _vm.star > item-1
      }})})],2),_c('h1',[_vm._v("领导思维类型")]),_c('div',{staticClass:"box1"},[_c('div',{staticClass:"box2"},[_vm._v("你的思维类型："),_c('b',[_vm._v(_vm._s(_vm.leader.leadership))])]),_c('div',{staticClass:"flex"},[_c('div',{staticClass:"box3"},[_c('h2',[_vm._v("思维特征")]),_vm._l((_vm.leader.thinking_feature),function(it,j){return _c('p',{key:j,domProps:{"innerHTML":_vm._s(it)}})})],2),_c('div',{staticClass:"box4"},[_c('h2',[_vm._v("你的关注")]),_vm._l((_vm.leader.new_attention),function(it,j){return _c('p',{key:j,domProps:{"innerHTML":_vm._s(it)}})})],2)]),_c('div',{staticClass:"box5"},[_c('h2',[_vm._v("你的画像")]),_c('div',{staticClass:"word"},_vm._l((_vm.leader.description),function(it,j){return _c('p',{key:j,domProps:{"innerHTML":_vm._s(it)}})}),0)])])]),_c('div',{staticClass:"main main2"},[_c('h1',[_vm._v("领导思维的相互关系")]),_c('ol',[_vm._l((_vm.relation),function(item,i){return _c('li',{key:i,class:'linear'+item.id},[_c('label',[_vm._v("利我")]),_c('label',[_vm._v("利他")]),_c('span',{domProps:{"innerHTML":_vm._s(item.name)}})])}),_c('i'),_c('div'),_c('i',{staticClass:"i2"})],2),_vm._m(0),_vm._l((_vm.likeMe),function(it,j){return _c('p',{key:j,domProps:{"innerHTML":_vm._s(it)}})}),_vm._m(1),_vm._l((_vm.likeHe),function(it,j){return _c('p',{key:j,domProps:{"innerHTML":_vm._s(it)}})})],2),_vm._m(2)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('b',[_vm._v("利我的思维：")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',{staticStyle:{"margin-top":"20px"}},[_c('b',[_vm._v("利他的思维：")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main main3"},[_c('h1',[_vm._v("领导思维的概况")]),_c('h3',[_vm._v("【领导思维】的说明：")]),_c('p',[_vm._v("1、每位领导者身上同时存在5种领导思维；")]),_c('p',[_vm._v("2、大部分领导者在工作中常用一种思维；")]),_c('p',[_vm._v("3、领导思维也是一种能量，不同的思维散发的能量不同，对人和事物的影响不同。")]),_c('ul',[_c('li',{staticClass:"linear1"},[_vm._v("E型（企业家思维）")]),_c('li',{staticClass:"linear3"},[_vm._v("M型（管理思维）")]),_c('li',{staticClass:"linear4"},[_vm._v("P型（专家思维）")]),_c('li',{staticClass:"linear5"},[_vm._v("F型（前线思维）")]),_c('li',{staticClass:"linear6"},[_vm._v("S型（战略思维）")])]),_c('p'),_c('h3',[_vm._v("【领导思维】的意义：")]),_c('p',[_vm._v("1、帮助领导者了解自己常用的领导思维，深刻理解与其他思维的相互关系；")]),_c('p',[_vm._v("2、有效管理自己的思维、角色、行为的一致性，善于与他人建立有效的协作关系。")]),_c('h3',[_vm._v("【领导思维】的应用：")]),_c('div',{staticClass:"apply apply1"},[_c('img',{attrs:{"src":require("../../../assets/images/report/AMT_report4.png")}}),_c('h5',[_vm._v("自我觉察")]),_c('p',[_vm._v("分析自己当下的角色定位与思维的匹配度；找出改善空间，提升领导力。")])]),_c('div',{staticClass:"apply apply2"},[_c('img',{attrs:{"src":require("../../../assets/images/report/AMT_report5.png")}}),_c('h5',[_vm._v("觉察他人")]),_c('p',[_vm._v("理解他人与自己的思维差异；换位思考，调整沟通方式；提升核心管理团队之间的合作效率。")])])])
}]

export { render, staticRenderFns }