<template>
  <svg class="lineCircle" :width="width" :height="width">
    <g class="bg"></g>
  </svg>
</template>

<script>
import * as d3 from 'd3'
export default {
  props: {
    data: {
      type: Object,
      // default:()=>{
      //   return {
      //     [1]:[{id: 73,name: "以身作则",score: 73},{id: 74,name: "阿斯蒂芬",score: 50},{id: 75,name: "俺的沙发",score: 33},{id: 76,name: "阿斯蒂芬",score: 63},{id: 77,name: "打法撒旦",score: 80},{id: 78,name: "阿达水费",score: 44}],
      //   }
      // }
    },
    width: {    //宽高度
      type: Number,
    },
    name:{
      type: String
    },
    animate:{
      type: Boolean,
      default: true,
    },
  },
  data(){
    return{
      svg: '',
      color: ['','#3ba4e8','#28c8b7','#ed6631','#f147bb'],
    }
  },
  mounted(){
    if(this.data){
      setTimeout(()=>{this.draw(this.data)},100)
      return
    }
    this.$watch('data', (val) => {
      this.draw(val)
    })
  },
  methods:{
    draw(e){
      var max = 0, min = 100
      for(let i in e){
        for(let j in e[i]){
          let score = e[i][j].score
          if(score > max) max = score;
          if(score < min) min = score;
        }
      }

      let svg = d3.select(this.$el)
      let gap =  this.width * 0.1,
          top = this.width * 0.2,
          bottom = this.width * 0.3,//与move动画的起始点对应
          fontSize = this.width * 0.035,
          vW = this.width - 2*gap,
          vH = this.width - top - bottom,
          bg = svg.select('.bg');
      bg.append('polyline')
        .attr('fill', 'none')
        .attr('stroke', '#a5a5a5')
        .attr('stroke-width',1)
        .attr('points', `${gap},${this.width-bottom} ${this.width-gap},${this.width-bottom}`)
      bg.append('text')//维度
        .attr('font-size', fontSize)
        .attr('font-weight', 'bold')
        .attr('fill', '#333')
        .attr('x', this.width - gap/2)
        .attr('y',  this.width - bottom)
        .text('维度')
      if (this.name)
        bg.append('text')//指数
          .attr('font-size', fontSize)
          .attr('font-weight', 'bold')
          .attr('fill', '#333')
          .attr('x', gap + 24)
          .attr('y', top-26)
          .text(this.name+'指数')

      // 左边刻度
      let sMin = Math.floor(min/10),      //在十位的最低值
          sMax = Math.ceil(max/10),       //在十位的最高值
          scale = (sMax - sMin) * 10,
          eachH = vH / (sMax - sMin),
          j = 0;
      for(let i = sMax; i >= sMin; i--){
        bg.append('text')
          .classed('i', true)
          .attr('font-size', fontSize)
          .attr('fill', '#999')
          .attr('x', gap / 2)
          .attr('y', eachH * (j++) + top)
          .text(i*10)
      }

      //绘制X轴元素（维度名，竖虚线，各分数X轴坐标）
      let pointX = [],                  //各x轴的坐标
          startY = this.width - bottom, //y轴开始位置
          data = svg.append('g').classed('data', true)
      // 1.绘制维度名与竖虚线
      for(let i in e){// 实际只循环一次，找到第一个就退出
        if (e[i]) {
          let eachW = vW / e[i].length
          for(let j = 0; j < e[i].length; j++){
            let text = e[i][j].name.split(''),
                x = pointX[j] =  eachW * ( j + .5) + gap
            bg.append('text')
              .attr('font-size', fontSize)
              .attr('fill', '#808080')
              .attr('y',  this.width - bottom + 18)
              .append('tspan')
              .attr('x', x)
              .text(text[0])
              .append('tspan')
              .attr('x', x)
              .attr('dy', '1.2em')
              .text(text[1])
              .append('tspan')
              .attr('x', x)
              .attr('dy', '1.2em')
              .text(text[2])
              .append('tspan')
              .attr('x', x)
              .attr('dy', '1.2em')
              .text(text[3])
            bg.append('polyline')
              .attr('fill', 'none')
              .attr('stroke', '#e0e0e0')
              .attr("stroke-dasharray", "3,3")
              .attr('stroke-width',1)
              .attr('points', `${x},${top-2} ${x},${this.width-bottom}`)
          }
          break;
        }
      }
      // 先绘制折线 并 计算各分数值坐标
      for(let i in e){
        let str = ''
        for(let j in e[i]){
          let height = (e[i][j].score - sMin*10) / scale * vH   /***主要计算***/
          let p = [pointX[j], startY - height]
          e[i][j].p = p
          str += `${p[0]},${p[1]} `
        }
        data.append('polyline')
            .attr('fill', 'none')
            .attr('stroke', this.color[i])
            .attr('stroke-width',2)
            .attr('points', str)
      }
      // 再绘制坐标圆点（圆点在上）
      for(let i in e){
        for(let j in e[i]){
          let p = e[i][j].p
          data.append('circle')
              .attr('cx', p[0])
              .attr('cy', p[1])
              .attr('r', 4)
              .attr('stroke', '#fff')
              .attr('stroke-width',1)
              .attr('fill', this.color[i]);
        }
      }

      //添加动画
      if(this.animate){
        data.selectAll('polyline').classed('move', true)
      }
    },
  },
}
</script>
<style scope lang="scss">
.lineCircle{
  display: block;
  margin: 0 auto;
  text{
    text-anchor: middle;
    dominant-baseline: middle;
  }
  tspan{
    text-anchor: middle;
    font-style: italic;
  }
  .data{
    polyline{
      stroke-dasharray: 170%;
    }
    .move{
      stroke-dashoffset: 170%;
      animation: moveP 1s forwards;
    }
    @keyframes moveP {
      100%{stroke-dashoffset:0;}
    }
    circle{
      animation: fadeC .8s forwards linear;
      opacity: 0;
    }
    @keyframes fadeC {
      100%{opacity: 1;}
    }
  }
  .i{
    font-style: italic;
  }
}
</style>
