<template>
  <div class="radar">
    <svg></svg>
  </div>
</template>

<script>
import * as d3 from 'd3'
export default {
  props: {
    data: {
      default: null,
    },
  },
  data(){
    return{
      width: 300,
      center: 0,
      radius: 0,
      length: 0,
      scale: [0, 100]
    }
  },
  mounted(){
    if(this.data){//本地测试(ctrl+s保存时浏览器自动执行)：
      this.draw(this.data)
    }else{ //实际传入数据:
      this.$watch('data', (e) => {
        this.draw(e)
      })
    }
  },
  methods:{
    compute(score, i, text){
      let per = text ? 1.35*this.radius : (score - this.scale[0]) / (this.scale[1] - this.scale[0]) * this.radius
      let angel = 2 * Math.PI / this.length * i
      return [this.center + Math.sin(angel) * per, this.center - Math.cos(angel) * per]
    },
    draw(e){
      let svg = this.svg = d3.select(this.$el).select('svg').attr('width',this.width).attr('height',this.width)
      let bg = svg.append('g').classed('bg',true)
      let text = this.svg.append('g').classed('text',true)
      let data = this.svg.append('g').classed('data',true)
      this.radius = this.width * 0.56 * 0.5     //圆半径
      let each = this.radius / 4                //每格大小
      let center = this.center = this.width / 2 //中点
      let len = this.length = e.names.length    //维度（分数）个数
      let polygon = ''                          //多边形坐标
      //先计算最小最大刻度
      let max = 0, min = 100
      for(let i = 0; i < len; i++){
        if(e.scores[i] > max) max = e.scores[i]
        if(e.scores[i] < min) min = e.scores[i]
      }
      if (e.id == 8) {
        let ten = Math.floor(min/10-1) * 10
        this.scale[0] = ten < 20 ? 1 : ten
      } else if (e.id == 1){
        this.scale = [min<1 ? 0 :  min-1, max+1]
      }

      //背景网格
      for(let i = 0; i < 4; i ++){
        let pStr = '';
        for(let j = 0; j < len; j ++){
          let p = this.compute(this.scale[1]-(this.scale[1]-this.scale[0])/4*i, j)
          pStr +=  p[0] + ',' + p[1] + ' '
          if(i==0){
            bg.append('line')
              .attr('x1', p[0])
              .attr('y1', p[1])
              .attr('x2', center)
              .attr('y2', center)
              .attr('stroke', '#fe7f4c')
              .attr('stroke-dasharray', '4px 4px')
              .attr('stroke-width', 1)
          }
        }
        bg.append('polygon')
          .attr('fill', 'none')
          .attr('stroke', '#fe7f4c')
          .attr('stroke-dasharray', '3px 3px')
          .attr('stroke-width',1)
          .attr('points', pStr)
      }

      //绘制维度名
      for(let i = 0; i < len; i++){
        let textP = this.compute(1, i, true)
        text.append('text')
            .attr('fill', '#3e3e3e')
            .attr('font-weight', 'bold')
            .attr('font-size', this.radius*0.15)
            .attr('x', textP[0])
            .attr('y', ()=>{
              if(i==0)
                return textP[1] + this.radius * 0.08
              else
                return textP[1]
            })
            .text(e.names[i])
        let p = this.compute(e.scores[i], i)
        polygon +=  p[0] + ',' + p[1] + ' '
        data.append('circle')
            .attr('cx', p[0])
            .attr('cy', p[1])
            .attr('r', 2)
            .attr('stroke', 'none')
            .attr('fill', '#fbb443');
      }

      //绘制数据多边形
      data.append('polygon')
          .attr('fill', '#fed9a3')
          .attr('stroke', '#fbb443')
          .attr('stroke-width', 1)
          .attr('points', polygon)
    },
  },
}
</script>
<style scope lang="scss">
.radar{
  position: relative;
  display: block;
  margin: 0 auto;
  polygon{
    fill-opacity: .6;
  }
  text{
    text-anchor: middle;
    dominant-baseline: middle;
  }
}
</style>
