<template>
  <div class="mycourse">
    <h1>已购买</h1>
    <ul>
      <li class="flex--c" v-for="(item,i) in list" :key="i" @click="toPlay(item.course_id)">
        <img :src="item.cover_img">
        <div>
          <h2>{{item.theme}}</h2>
          <h3 class="flex-b-c">{{item.total_subject}}讲 <span class="flex-c-c">播放</span></h3>
        </div>
      </li>
    </ul>
    <div class="null" v-show="total===0">暂无记录</div>
  </div>
</template>
<script>
export default {
  data(){
    return {
      page: 1,
      list: [],
      total: '',
    }
  },
  created(){
    this.getList()
  },
  mounted(){
    window.onscroll = () => {
      var scrollTop = document.documentElement.scrollTop || document.body.scrollTop,
          clientHeight = document.documentElement.clientHeight || document.body.clientHeight,
          scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight;
      if (scrollTop + clientHeight >= scrollHeight) {
        console.log(this.page)
      }
    }
  },
  destroyed() {  
    window.onscroll = null
  },
  methods:{
    getList(p) {
      if (p == 1) this.page = 1
      this.$http.post(this.ajaxUrlPath+'/Bee/getUserCourse', {
        hy_session: localStorage.getItem("session"),
        page: this.page++,
        per_page: 10,
      }).then(({data}) => {
        if (data.code == 0) {
          this.list = data.data.data
          this.total = data.data.total
        } else {
          this.$toast({message: data.msg})
        }
      }, () => this.$toast({message:'网络错误'}))
    },
    toPlay(id) {
      this.$router.push({path: '/bee/play', query: {id}})
    },
  },
}
</script>
<style lang="scss" scoped>
.mycourse{
  background: #f6f6f6;
  min-height: 100%;
  user-select: none;
  text-align: left;
  h1{
    padding: 30px;
    font-size: 28px;
    color: #292929;
  }
  li{
    background: #fff;
    border-radius: 10px;
    padding: 30px;
    margin: 0 30px 30px;
    &>div{flex: 1;}
    img{
      width: 120px;
      height: 150px;
      background: #f1f7f2;
      margin-right: 30px;
      flex-shrink: 0;
    }
    h2{
      word-break: break-all;
      font-size: 28px;
      color: #292929;
      font-weight: bold;
      white-space: normal;
      line-height: 1.4;
      overflow: hidden;
      text-overflow: ellipsis;
      text-overflow: -o-ellipsis-lastline;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    h3{
      font-size: 24px;
      color: #808080;
      margin-top: 20px;
      span{
        background: #e0f1e2;
        height: 44px;
        font-size: 24px;
        border-radius: 30px;
        width: 120px;
        color: #2c9e3a;
        &::before{
          content: '';
          border-left: 14px solid #2c9e3a;
          border-top: 8px solid transparent;
          border-bottom: 8px solid transparent;
          margin: 0 10px 0 6px;
        }
      }
    }
  }
  .null{
    color: #b3b3b3;
    font-size: 28px;
    margin-top: 120px;
    padding-top: 300px;
    background: url('../../../assets/images/bee/bee_null.png') no-repeat;
    background-size: 280px 280px;
    background-position: center top;
  }
}
</style>