<template>
  <div class="singleBar flex">
    <h1>
      <div></div>
      <span>中等</span>
    </h1>
    <h1>
      <div></div>
      <span>良好</span>
    </h1>
    <h1>
      <div></div>
      <span>卓越</span>
    </h1>
    <h2 class="refer" :style="{left:referLeft+'%'}"/>
    <h3 class="slider" :style="{left:left+'%'}"/>
    <p>{{text}}</p>
  </div>
</template>
<script>
export default {
  props: {
    data: {default: 0},
    refer: {default: 0},
    text: {default: ''},
  },
  data(){
    return {
      scale: [0,60,80],
      left: 0,
      referLeft: 0,
    }
  },
  mounted(){
    this.referLeft =  this.setLeft(this.refer)
    if (this.data) {
      this.left = this.setLeft(this.data)
    } else {
      this.$watch('data', e => this.left = this.setLeft(e))
    }
  },
  methods:{
    setLeft(e){
      for (let i = this.scale.length-1; i >= 0; i--) {
        if (e >= this.scale[i]) {
          let next = this.scale[i+1] ? this.scale[i+1] : 100
          let left = ( (e-this.scale[i]) / (next-this.scale[i]) + i ) * 33.3334
          return left==33.3334 ? 34 : left==66.6668 ? 67 : left
        }
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.singleBar{
  margin: 0 40px;
  padding: 90px 0 170px;
  position: relative;
  h1{
    flex: 1;
    margin: 0 2px;
    div{
      width: 100%;
      height: 32px;
    }
    span{
      display: block;
      color: #936e52;
      font-size: 24px;
      margin-top: 20px;
    }
    &:nth-child(1){
      div{
        background: #a3b12a;
        border-top-left-radius: 20px;
        border-bottom-left-radius: 20px;
      }
    }
    &:nth-child(2){
      div{background: #f9961a}
    }
    &:nth-child(3){
      div{
        background: #f67d3d;
        border-top-right-radius: 20px;
        border-bottom-right-radius: 20px;
      }
    }
  }
  .refer{
    position: absolute;
    height: 160px;
    // left: 50%;
    top: 70px;
    border-left: 1px dashed #da503e;
    &::after{
      content: '参考线';
      white-space: nowrap;
      font-size: 22px;
      color: #da503e;
      position: absolute;
      left: 0;
      bottom: -40px;
      transform: translateX(-50%);
    }
  }
  .slider{
    position: absolute;
    width: 1px;
    height: 74px;
    top: 24px;
    left: 0;
    animation: slip .4s linear;
    &::before{
      content: '您的位置';
      font-weight: bold;
      white-space: nowrap;
      font-size: 26px;
      color: #323232;
      position: absolute;
      left: 0;
      top: 0px;
      transform: translateX(-50%);
    }
    &::after{
      content: '';
      position: absolute;
      left: 0;
      bottom: 0px;
      transform: translateX(-50%);
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-top: 36px solid #323232;
    }
  }
  @keyframes slip {
    0%{left: 0;}
  }
  p{
    position: absolute;
    left: 0;
    width: 100%;
    bottom: 20px;
    color: #936e52;
    font-size: 26px;
  }
}
</style>