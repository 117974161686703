<template>
  <div class="report">
    <!-- 职场特质 -->
    <section>
      <div class="title"><span>职场特质</span></div>
      <div class="special">{{special}}</div>
      <div class="hole"><i></i><i></i><i></i><i></i></div>
    </section>
    <!-- 工作价值倾向 -->
    <section>
      <div class="title"><span>工作价值倾向</span></div>
      <ul class="value">
        <li v-for="(item,index) in dimension" :key="index">
          <div class="NO">NO.{{index+1}}</div>
          <p>{{item.name}}</p>
        </li>
      </ul>
      <div class="hole holeTop"><i></i><i></i><i></i><i></i></div>
      <div class="hole"><i></i><i></i><i></i><i></i></div>
    </section>
    <!-- 成就意愿 -->
    <section>
      <div class="title"><span>成就意愿</span></div>
      <ul class="wish">
        <li>
          <div class="name">目标驱动</div>
          <i v-for="item in 8" :key="item" :class="{dark: item <= getDark(desire[0])}"></i>
          <span>弱</span><span class="span2">中</span><span class="span3">强</span>
        </li>
        <li>
          <div class="name">自我期待</div>
          <i v-for="item in 8" :key="item" :class="{dark: item <= getDark(desire[1])}"></i>
          <p>{{desireTips}}</p>
        </li>
      </ul>
      <div class="hole holeTop"><i></i><i></i><i></i><i></i></div>
      <div class="hole"><i></i><i></i><i></i><i></i></div>
    </section>
    <!-- 职场及岗位方向 -->
    <section>
      <div class="title"><span>职场及岗位方向</span></div>
      <ul class="job">
        <li class="left">
          <p v-for="(item,index) in job.left" :key="index" :style="item.style" v-html="item.name"></p>
        </li>
        <li class="right">
          <p v-for="(item,index) in job.right" :key="index" :style="item.style" v-html="item.name"></p>
        </li>
      </ul>
      <div class="special">{{job.desc}}</div>
      <div class="hole holeTop"><i></i><i></i><i></i><i></i></div>
    </section>

    <div class="copyright">{{report_version}}</div>
  </div>
</template>
<script>
export default {
  data(){
    return{
      exam_id: this.$route.params.exam_id || '',
      hy_session: this.$route.params.hy_session || '',
      name: "正在加载",                                    //用户姓名
      avatar: require('../../../assets/images/icons/user_icon.png'),
      endTime: 0,                                         //报告完成时间
      reportImg:'',
      reportImgShow: false,
      timer:'',
      //职业特质
      special: '',
      //工作价值倾向维度
      dimension: [],
      //成就意愿
      desire: [],
      desireTips: '',
      //职场及岗位方向
      job: {
        left:[],
        right:[],
        desc:''
      },
      report_version: '',
    }
  },
  created(){
    this.imgUrl = this.spaceUrl
    this.$indicator.open({text: '加载中...',spinnerType: 'fading-circle'});
    this.$http.get(this.spaceUrl+'/Career/getExamReportInfo',{
      params:{
        exam_id: this.exam_id,
        type: 2
      }
    }).then((e) =>{
      let data = e.data.data
      if (e.data.code == 0) {
        //职业特质
        this.special = data.work_special

        //工作价值倾向维度
        this.dimension = data.strong_dimension

        //成就意愿
        let desire = Object.values(data.desire)
        for(let item of desire){
          this.desire.push(item.score)
        }
        if (desire[0].score == 45 && desire[0].score == 45) {
          this.desireTips = 'Ta在工作中可能显得过于被动'
        } else if (desire[0].score==85 && desire[0].score==85) (
          this.desireTips = 'Ta是否过于执着某个目标的达成'
        )

        //职场及岗位方向
        let {left,right,desc} = data.work_job;
        for(let i = 0; i < left.length; i++){
          if(left[i].length>9){
            left[i] = {style: 'transform:translateX(-8px)', name: left[i]}
          }else if(left[i].length>8){
            left[i] = {style: 'transform:translateX(-2px)', name: left[i]}
          }else if(i==0){
            left[i] = {style: `transform:translateX(${100 - left[i].length * 12}%)`, name: left[i]}
          }else if(i==1){
            left[i] = {style: `transform:translateX(2px)`, name: left[i]}
          }else if(i==2){
            left[i] = {style:  `transform:translateX(${95 - left[i].length * 12}%)`, name: left[i]}
          }else if(i==3){
            left[i] = {style: `transform:translateX(10%)`, name: left[i]}
          }
        }
        this.job.left = left

        let len = right.length, temp
        if(len >3 && right[len-1].length>8){
          temp = right[len-1]
          right[len-1] = right[len-2]
          right[len-2] = temp
        }
        for(let i = 0; i < len; i++){
          if(right[i].length>8){
            right[i] = {style:  `transform:translateX(2%)`, name: right[i]}
          }else if(right[i].length>6){
            right[i] = {style:  `transform:translateX(10%)`, name: right[i]}
          }else if(i==0){
            right[i] = {style:  `transform:translateX(12%)`, name: right[i]}
          }else if(i==1){
            right[i] = {style:  `transform:translateX(68%)`, name: right[i]}
          }else if(i==2){
            right[i] = {style: `transform:translateX(24%)`, name: right[i]}
          }else if(i==3){
            right[i] = {style: `transform:translateX(50%)`, name: right[i]}
          }
        }
        this.job.right = right
        this.job.desc = desc
        this.report_version = data.report_version || '职涯优选CBC copyright by @十方实验室'
      }else{
        this.name = '无法加载'
        this.$toast({message: '加载失败', duration: 1000})
      }
      this.$indicator.close();
    }).catch((err)=>{
      console.error(err)
      this.name = '加载失败'
      this.$toast({message: '网络错误', duration: 1500})
      this.$indicator.close();
    })
  },
  methods:{
    closeImg(){
      this.reportImgShow = false
    },
    getDark(e){
      let num = 0
      if(e <= 45)  num = 1
      else if(e < 60)  num = 2
      else if(e < 65)  num = 3
      else if(e < 70)  num = 4
      else if(e < 75)  num = 5
      else if(e < 80)  num = 6
      else num = 7
      return num
    },
  },
}
</script>
<style scoped lang="scss">
section{
  background: #fff;
  border-radius: 20px;
  margin: 36px 0;
  padding: 38px 36px 60px;
  text-align: left;
  position: relative;
  .title{
    position: relative;
    font-size: 28px;
    display: inline-block;
    margin: 20px 0;
    span{
      font-weight: bold;
      color: #393939;
      position: relative;
      z-index: 2;
    }
    &::before{
      content: '';
      position: absolute;
      left: -2%;
      bottom: 3px;
      width: 104%;
      height: 12px;
      border-radius: 6px;
      background: #bbd4fe;
    }
  }
  .hole{
    position: absolute;
    left: 0;
    width: 100%;
    height: 46px;
    bottom: 0;
    i{
      width: 16px;
      height: 16px;
      border-radius: 50%;
      background: #5fb7fd;
      box-shadow: 0 1px 2px 2px rgba(92,181,254,.2);
      bottom: 15px;
      position: absolute;
      &::after{
        content: '';
        position: absolute;
        left: 4px;
        width: 8px;
        top: 5px;
        height: 48px;
        background: #e8f1f5;
        border-radius: 4px;
      }
      &:nth-child(1){left: 32px;}
      &:nth-child(2){left: 88px;}
      &:nth-child(3){right: 88px;}
      &:nth-child(4){right: 32px;}
    }
  }
  .holeTop{
    bottom: auto;top: 0;
    i::after{top: auto;bottom: 5px;}
  }
}
.report{
  color:#6f6f6f;
  background: #77a9fe url('https://m.huayanspace.com/static/img/professionTend.png');
  background-size: 100% auto;
  padding: 1px 32px 20px;
  font-size: 28px;
  color: #999;
  .message{
    display: flex;
    align-items: center;
    .head{
      border-radius: 50%;
      width: 82px;
      height: 82px;
      overflow: hidden;
      box-shadow: 0 0 2px rgba(150,150,150,.4)
    }
    .name{
      flex: 1;
      margin-left: 20px;
      font-size: 28px;
      text-align: left;
      color: #999;
      h5{
        line-height: 44px;
      }
    }
    .btn{
      width: 130px;
      color: #fff;
      border-radius: 6px;
      background: #33a1fb;
      height: 48px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 26px;
      padding: 0 10px;
      flex-shrink: 0;
      white-space: nowrap;
      margin-left: 10px;
    }
  }
  .word{
    border-top: 1px solid #77a9fe;
    border-bottom: 1px solid #77a9fe;
    position: relative;
    padding: 36px 0;
    margin: 30px 0 20px;
    color: #77a9fe;
    font-size: 26px;
    &::before{
      content: '“';
      position: absolute;
      font-size: 60px;
      left: 2px;
      top: -2px;
    }
    color: #77a9fe;
    &::after{
      content: '”';
      position: absolute;
      font-size: 60px;
      right: 2px;
      bottom: -36px;
    }
  }
  .special{
    font-size: 26px;
    margin: 20px 0;
  }
  .value{
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin: 30px auto;
    width: 500px;
    position: relative;
    min-height: 200px;
    li{
      width: 116px;
      height: 240px;
      text-align: center;
      color: #fff;
      border-top-left-radius: 58px;
      border-top-right-radius: 58px;
      position: relative;
      box-shadow: 0 0 0 8px #f7f7f7;
      .NO{
        width: 96px;
        height: 96px;
        line-height: 90px;
        border-radius: 50%;
        background: #fff;
        margin: 10px auto;
      }
      p{
        position: absolute;
        width: 100%;
        left: 0;
        bottom: 30px;
      }
      &:nth-child(1){
        background: #3fb3ff;
        .NO{color: #3fb3ff}
      }
      &:nth-child(2){
        height: 215px;
        background: #4ece9d;
        .NO{color: #4ece9d}
      }
      &:nth-child(3){
        height: 190px;
        background: #f8af4b;
        .NO{color: #f8af4b}
      }
    }
    &::before{
      content: '';
      position: absolute;
      border-left: 500px solid #f7f7f7;
      border-top: 60px solid transparent;
      height: 130px;
      bottom: -8px;;
    }
    .tip{
      position: absolute;
      background: #fff;
      border-radius: 10px;
      top: 260px;
      left: 0;
      color: #000;
      width: 500px;
      z-index: 999;
      padding: 16px;
      text-align: left;
      box-shadow: 0 0 8px rgba(150,150,150,0.8);
      &::after{
        content: '';
        position: absolute;
        width: 20px;
        height: 20px;
        transform: rotate(45deg);
        top: -10px;
        left: 48px;
        z-index: 3;
        background: #fff;
        box-shadow: -2px -2px 4px rgba(150,150,150,0.6);
      }
    }
  }
  .wish{
    position: relative;
    margin: 40px 0 50px;
    li{
      display: flex;
      position: relative;
      align-items: center;
      height: 40px;
      margin: 48px 0;
      color: #666;
      .name{
        width: 130px;
      }
      i{
        width: 56px;
        height: 24px;
        background: #d9f0ff;
        margin: 0 2px;
        display: inline-block;
      }
      .dark{
        background: #3fb3ff;
      }
      &:nth-child(2){
        i{background: #dcf5eb}
        .dark{background: #4ece9d}
      }
      span{
        position: absolute;
        bottom: -40px;
        color: #3fb3ff;
        left: 150px;
        font-size: 20px;
      }
      .span2{left: 328px;}
      .span3{left: 480px;}
      p{
        position: absolute;
        bottom: -50px;
        left: 50%;
        transform: translateX(-50%);
        font-size: 20px;
        color: #ff502f;
        white-space: nowrap;
      }
    }
  }
  .job{
    display: flex;
    justify-content: center;
    color: #fff;
    position: relative;
    margin: 30px 0;
    overflow-x: hidden;
    li{
      height: 240px;
      width: 260px;
      position: relative;
      z-index: 2;
      display: flex;
      flex-direction: column;
      justify-content: center;
      p{
        font-size: 28px;
        font-weight: bold;
        // white-space: nowrap;
        line-height: 1.2;
        margin: 9px 0;
      }
    }
    .left{
      margin-top: 30px;
      background: #4ece9d;
      &::before{
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        border-right: #4ece9d solid 50px;
        border-top: transparent solid 120px;
        border-bottom: transparent solid 120px;
        right: 100%;
        top: 0;
      }
    }
    .right{
      background: #63c1ff;
      border-left: 2px dashed #fff;
      &::before{
        content: '';
        position: absolute;
        width: 0;
        height: 0;
        border-left: #63c1ff solid 50px;
        border-top: transparent solid 120px;
        border-bottom: transparent solid 120px;
        left: 100%;
        top: 0;
      }
    }
  }
  .copyright{
    font-size: 20px;
    color: #f0f0f0;
    margin: 40px 0 20px;
    opacity: .8;
  }
}

.img-box{
    width: 100%;
    height: 100%;
    position: fixed;
    background: rgba(0,0,0,0.8);
    top: 0;
    left: 0;
    z-index: 9999;
    font-size:30px;
    .text{line-height: 100px;color:#fff;}
    .inner-box{
      flex: 1;
      height: 80%;
      margin:0 30px 20px;
      overflow-y: auto;
      img{width: 100%;height: auto;display: block;}
    }
    .close{
      display: block;
      width: 70px;
      height: 70px;
      margin:0 auto;
    }
}
</style>