<template>
  <div class="pie_chart">
    <svg>
      <defs>
        <linearGradient id="pie0" x1="50%" y1="0%" x2="50%" y2="100%">
          <stop offset="0%" style="stop-color:#fd7616;"/>
          <stop offset="100%" style="stop-color:#feac02;"/>
        </linearGradient>
        <linearGradient id="pie1" x1="50%" y1="0%" x2="50%" y2="100%">
          <stop offset="0%" style="stop-color:#654fda;"/>
          <stop offset="100%" style="stop-color:#64c1e7;"/>
        </linearGradient>
      </defs>
    </svg>
  </div>
</template>

<script>
import * as d3 from 'd3'
export default {
  props: {
    name:{
      type: String,
    },
    score:{
      type: Array
    },
    width: {    //宽高度
      type: Number,
    },
  },
  data(){
    return{
      centerX: 0,
      centerY: 0,
      color:['#ed4c5f','#ed4c81','#ed4cb2','#ed4ce7','#f8a023','#ffb922','#fac628','#f6e632'],
      // text:['成就动机','学习成长','人际沟通','高效行动','规划能力','利他思维','乐观坚韧','自我经营'],
      data:[],
    }
  },
  mounted(){
    if(this.name){
      this.draw()
      return
    }
    this.$watch('name', () => {
      this.draw()
    })
  },
  methods:{
    compute(r,score){
      let angel = score * 2 * Math.PI
      return [this.centerX + Math.sin(angel) * r, this.centerY - Math.cos(angel) * r]
    },
    draw(){
      let height = this.width * 0.5
      let svg = d3.select(this.$el).select('svg').attr('width', this.width).attr('height', height)
      let gap =  height * 0.1
      let R = (height - 2 * gap) / 2                //圆半径
      let centerX = this.centerX = this.width / 2   //x轴中点
      let centerY = this.centerY = height / 2       //y轴中点

      //计算坐标
      let total = 0;      // 总分
      let total1 = 0;     // 第一部分(职业素质)总分
      for(let i = 0; i < this.score.length; i++){
        for(let j =0; j < this.score[i].length; j++){
          total += this.score[i][j].percent
          if(i===0) total1 = total
        }
      }
      
      let circle = svg.append('g').classed('circle',true)
      let p = this.compute(R, total1)
      
      //职业素质
      let path1 = circle.append('path')
                        .classed('path1',true)
                        .attr('fill', 'url(#pie0)')
                        .attr('d', `M${centerX} ${gap} A${R} ${R} 0 ${total1>.5?1:0} 1 ${p[0]} ${p[1]} L${centerX} ${centerY}z`)
      let cenP1 = this.compute(R, total1/2)
      circle.append('polyline')
            .attr('fill', 'none')
            .attr('stroke', '#999')
            .attr('stroke-width', 1)
            .attr('points', `${cenP1[0]},${cenP1[1]} ${cenP1[0]+R*.5},${cenP1[1]}`)
      circle.append('text')
            .attr('font-size', R*.22)
            .attr('fill', '#999')
            .attr('x', cenP1[0]+R)
            .attr('y', cenP1[1])
            .text('职业素质')
            
      //原动力
      let path2 = circle.append('path')
                        .attr('fill', 'url(#pie1)')
                        .attr('d', `M${p[0]} ${p[1]} A${R} ${R} 0 ${total1>.5?0:1} 1 ${centerX} ${gap} L${centerX} ${centerY}z`)
      let cenP2 = this.compute(R, 0.5 + 0.5 * total1)
      circle.append('polyline')
            .attr('fill', 'none')
            .attr('stroke', '#999')
            .attr('stroke-width',1)
            .attr('points', `${cenP2[0]},${cenP2[1]} ${cenP2[0]-R*.5},${cenP2[1]}`)
      circle.append('text')
            .attr('font-size', R*.22)
            .attr('fill', '#999')
            .attr('x', cenP2[0]-R)
            .attr('y', cenP2[1])
            .text('原动力')
    },
  },
}
</script>
<style scope lang="scss">
@keyframes dimFade {
  0%{opacity: 0;transform: translate(-50%,-44%)}
  95%{opacity: 1;transform: translate(-50%,-50%)}
}
.pie_chart{
  margin: 50px 0;
  position: relative;
  svg{
    display: block;
    margin: 0 auto;
  }
    text{
      text-anchor: middle;
      dominant-baseline: middle;
    }
}
</style>
