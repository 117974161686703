<template>
  <div class="detail">
    <header>
      <img v-if="info.module_id" :src="require('../../../../../static/img/tda_'+info.module_id+'.png')">
      <p>{{info.product_name}}</p>
    </header>
    <section>
      <h1 class="flex--c">基本介绍</h1>
      <p v-html="info.intro"></p>
      <h1 class="flex--c">报告说明</h1>
      <p v-html="info.report"></p>
      <img class="report" src="../../../../../static/img/TDA15.jpg">
      <h2>报告样式（截选）</h2>
      <h1 class="flex--c">使用说明</h1>
      <p v-html="info.using"></p>
      <h1 class="flex--c">版权说明</h1>
      <p v-html="info.copyright"></p>
    </section>
    <div class="bottom flex--c">
      <div class="icon" @click="tolink('back')">
        <img src="../../../../../static/img/TDA12.png">返回
      </div>
      <div class="icon" @click="tolink('car')">
        <img src="../../../../../static/img/TDA9.png">购物车
      </div>
      <div class="btn btn1" @click="selectType(1)">加入购物车</div>
      <div class="btn btn2" @click="selectType(2)">直接购买</div>
    </div>

    <template v-if="showType==1">
      <div class="mask" @click="close"/>
      <div class="container">
        <div class="up flex--c">
          <div class="pic flex-c-c" :class="'pic'+info.module_id"><img :src="require('../../../../../static/img/tda_'+info.module_id+'.png')"></div>
          <div class="content">
            <h3>{{info.product_name}}</h3>
            <div class="flex-b-c">
              <h4>¥ <b>{{info.price}}</b> <s>￥19.9</s></h4>
              <div class="inputBox flex-c">
                <span @click="input('number',-1)">-</span>
                <input maxlength="3" @input="input('number')" @blur="blur('number')" v-model="number"/>
                <span @click="input('number',1)">+</span>
              </div>
            </div>
          </div>
        </div>
        <div class="under flex--c">
          <div class="button" @click="addCar">确认</div>
        </div>
      </div>
    </template>
    <template v-if="showType==2">
      <div class="mask" @click="close"/>
      <div class="container">
        <div class="up flex--c">
          <div class="pic flex-c-c" :class="'pic'+info.module_id"><img :src="require('../../../../../static/img/tda_'+info.module_id+'.png')"></div>
          <div class="content">
            <h3>{{info.product_name}}</h3>
            <div class="flex-b-c">
              <h4>¥ <b>{{info.price}}</b> <s>￥19.9</s></h4>
              <div class="inputBox flex-c">
                <span @click="input('payNum',-1)">-</span>
                <input maxlength="3" @input="input('payNum')" @blur="blur('payNum')" v-model="payNum"/>
                <span @click="input('payNum',1)">+</span>
              </div>
            </div>
          </div>
        </div>
        <div class="under flex--c">
          <span>小计 <b>{{subTotal}}</b></span>
          <div class="button button2" @click="toPay">结算</div>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
export default {
  data(){
    return {
      pid: this.$route.query.id || '',
      info: {},
      carList: [],
      number: 1,      //加入购物车数
      payNum: 1,      //直接购买数
      currIndex: -1,
      showType: 0,    //1:购物车框，2:直接购买框
      user: {},
    }
  },
  created(){
    let user = JSON.parse(localStorage.getItem("user"))
    if (user) {
      this.user = user
      this.carList = JSON.parse(localStorage.getItem("car_"+this.user.user_id)||'[]')
    }
    //寻找在购物车中的索引和数量
    for (let i in this.carList) {
      if (this.carList[i].product_id == this.pid) {
        this.currIndex = i
        break
      }
    }
    //获取产品信息
    this.$http.get(this.spaceUrl+'/Product/getModuleProductInfo?product_id='+this.pid).then(({data}) => {
      if (data.code == 0) {
        this.info = data.data
      }
    })
  },
  methods:{
    tolink(e) {
      if (e == 'back') {
        this.$router.back()
      } else if (!this.user.user_id) {
        this.$router.push({path: '/TDA/login', query: {link: this.user.nickname ? 'bind' : 'detail'}})
      } else {
        this.$router.push({path: '/TDA/car'})
      }
    },
    selectType(e) {
      if (!this.user.user_id) 
        this.$router.push({path: '/TDA/login', query: {link: this.user.nickname ? 'bind' : 'detail'}})
      else
        this.showType = e
    },
    input(key, n) {
      if (n) {  //+-数
        let res = this[key] + n
        if (res < 1) {
          this.$toast({message: '不能再少了', duration: 1000})
        } else if (res > 999) {
          this.$toast({message: '超过上限', duration: 1000})
        } else {
          this[key] = res
        }
      } else {  //输入数
        this[key] = Number(event.target.value.replace(/\D/g, '')) || ''
      }
    },
    blur(key) {
      if (!this[key]) this[key] = 1
    },
    addCar() {
      let list = this.carList, i = this.currIndex
      // let i = list.findIndex(it => it.product_id == this.pid)
      if (i > -1) {
        let num = list[i].number + this.number
        if (num > 999) {
          this.$toast({message: '超过最大购买数，请先去购物车结算'})
          return
        }
        list[i].number = num 
        list[i].pick = true
      } else {
        let d = this.info
        list.unshift({
          product_id: d.product_id,
          product_name: d.product_name,
          module_id: d.module_id,
          price: d.price,
          number: this.number, 
          pick: true
        })
      }
      this.carList = list
      localStorage.setItem("car_"+this.user.user_id, JSON.stringify(list))
      this.showType = 0
      this.$toast({message: '已加入购物车', duration: 1000})
    },
    toPay() {
      if (this.user.mobile) {
        let arr = [{
          product_id: this.info.product_id, 
          number: this.payNum, 
          price: this.info.price
        }]
        sessionStorage.setItem("product", JSON.stringify(arr))
        this.$router.push({path: '/TDA/pay', query: {way: 'direct'}})
      } else {
        this.$router.push({path: '/TDA/login', query: {link: 'bind'}})
      }
    },
    close() {
      this.showType = 0
    },
  },
  computed: {
    subTotal() {
      let total = this.payNum * this.info.price
      return parseFloat(total.toFixed(2))
    },
  },
  mounted() {
    document.title = '人才发展评估'
    wx.ready(() => {
      wx.onMenuShareAppMessage({
        title : '人才发展评估',
        link : `${this.mobileUrl}/#/TDA/shop`,
        imgUrl : `${this.mobileUrl}/static/img/p-15.jpg`,
      })
    })
  },
}
</script>
<style lang="scss" scoped>
.detail{
  min-height: 100%;
  background: #f1f5f7;
  overflow-x: hidden;
  &::before{
    content: '';
    position: fixed;
    background-image: url('../../../../../static/img/TDA14.png');
    background-color: #5791f5;
    background-size: 1000px 100%;
    background-position: center;
    background-repeat: no-repeat;
    left: 50%;
    transform: translateX(-50%);
    top: -360px;
    width: 1200px;
    height: 800px;
    z-index: 1;
    border-bottom-left-radius: 50%;
    border-bottom-right-radius: 50%;
  }
  &::after{
    content: '';
    position: fixed;
    background: #f1f5f7;
    width: 100%;
    height: 100%;
    z-index: -1;
    top: 0;
    left: 0;
  }
  header{
    font-weight: bold;
    font-size: 34px;
    color: #fff;
    padding: 30px 0 20px;
    position: relative;
    z-index: 2;
    img{
      width: 80px;
      height: 80px;
      margin: 0 auto;
      border-radius: 50%;
      margin-bottom: 10px;
      box-shadow: 0 4px 8px rgba(200,200,200,.1);
      background: linear-gradient(100deg, rgba(255,255,255,.5) 10%, rgba(200,200,200,0))
    }
  }
  section{
    background: #fff;
    border-radius: 10px;
    margin: 24px 24px 144px;
    position: relative;
    z-index: 2;
    text-align: left;
    padding: 0 30px 1px;
    font-size: 28px;
    h1{
      color: #4987f9;
      padding: 30px 0 20px;
      &::before{
        content: '';
        width: 6px;
        height: 26px;
        background: #4987f9;
        margin-right: 20px;
      }
    }
    p{
      line-height: 1.8;
      color: #292929;
      font-size: 26px;
      margin-bottom: 30px;
      text-align: justify;
      white-space: pre-wrap;
    }
    .report{
      display: block;
      width: 400px;
      margin: 0 auto;
    }
    h2{
      color: #b3b3b3;
      font-size: 24px;
      text-align: center;
      line-height: 80px;
    }
  }
  .bottom{
    position: fixed;
    width: 100%;
    height: 120px;
    box-sizing: border-box;
    padding: 0 24px;
    left: 0;
    bottom: 0;
    background: #fff;
    color: #fff;
    z-index: 4;
    user-select: none;
    font-size: 28px;
    .icon{
      font-size: 22px;
      color: #b3b3b3;
      width: 80px;
      padding-top: 10px;
      margin: 0 12px;
      line-height: 1.8;
      img{
        width: 36px;
        height: 36px;
        display: block;
        margin: 0 auto;
      }
    }
    .btn{
      line-height: 74px;
      background: #ff7a4b;
      flex: 1;
    }
    .btn1{
      border-top-left-radius: 40px;
      border-bottom-left-radius: 40px;
      margin-left: 30px;
    }
    .btn2{
      border-top-right-radius: 40px;
      border-bottom-right-radius: 40px;
      background: #4987f9;
    }
  }
  .mask{
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: 8;
    background: rgba(50,50,50,.2);
    left: 0;
    top: 0;
  }
  .container{
    position: fixed;
    width: 100%;
    box-sizing: border-box;
    padding: 0 24px;
    left: 0;
    bottom: 0;
    background: #fff;
    z-index: 10;
    font-size: 28px;
    user-select: none;
    transform: translateY(100%);
    animation: show .1s forwards;
    .up{
      border-bottom: 1px solid #f6f6f6;
      padding: 30px 0 34px;
      .pic{
        width: 140px;
        height: 140px;
        border-radius: 10px;
        img{
          width: 70px;
          height: 70px;
          border-radius: 50%;
          box-shadow: 0 4px 8px rgba(200,200,200,.1);
          background: linear-gradient(100deg, rgba(255,255,255,.5) 10%, rgba(200,200,200,0))
        }
      }
      .content{
        white-space: nowrap;
        flex: 1;
        margin-left: 30px;
        h3{
          font-size: 28px;
          color: #292929;
          margin: 20px 0;
          text-align: left;
        }
        h4{
          display: flex;
          align-items: baseline;
          font-size: 24px;
          color: #f81c1c;
          b{
            font-size: 28px;
            margin-left: 8px;
          }
          s{
            color: #b3b3b3;
            margin-left: 20px;
          }
        }
        .inputBox{
          border: 1px solid #dae0e5;
          border-radius: 6px;
          font-size: 28px;
          span{
            width: 48px;
            line-height: 48px;
            color: #b3b7bb;
          }
          input{
            width: 64px;
            height: 48px;
            color: #292929;
            text-align: center;
            font-weight: bold;
            border-left: 1px solid #dae0e5;
            border-right: 1px solid #dae0e5;
          }
        }
      }
    }
    .under{
      height: 120px;
      span{
        font-size: 26px;
        color: #292929;
        margin: 0 100px 0 10px; 
        b{
          color: #f81c1c;
          font-size: 30px;
        }
      }
      .button{
        flex: 1;
        line-height: 74px;
        color: #fff;
        font-size: 30px;
        border-radius: 40px;
        background: #ff7a4b;
      }
      .button2{background: #4987f9}
    }
  }
  @keyframes show {
    100%{transform: translateY(0)}
  }
}
.pic1{background: linear-gradient(135deg, #abcfff 10%, #4889f8 80%)}
.pic2{background: linear-gradient(135deg, #8bf576 10%, #66c254 80%)}
.pic3{background: linear-gradient(135deg, #47e7ff 10%, #51b4f9 80%)}
.pic4{background: linear-gradient(135deg, #ffd37e 10%, #f0a735 80%)}
.pic5{background: linear-gradient(135deg, #939dff 10%, #5757f7 80%)}
.pic6{background: linear-gradient(135deg, #83d4ff 10%, #369dd3 80%)}
.pic7{background: linear-gradient(135deg, #63fffd 10%, #5bc4c3 80%)}
.pic8{background: linear-gradient(135deg, #ffb895 10%, #ec8756 80%)}
.pic9{background: linear-gradient(135deg, #c398ff 10%, #8c5fca 80%)}
</style>