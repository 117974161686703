<template>
  <svg class="difference" :width="width">
    <g class="bg"></g>
  </svg>
</template>

<script>
import * as d3 from 'd3'
export default {
  props: {
    data: {
      default: () => {
        return []
      }
    },
    colotType: {
      default: 0
    },
    animate: {
      default: true
    }
  },
  data(){
    return{
      width: 400,
      color:[['#46b6ff','#f9973f'],['#26cbdf','#ff6299']],
    }
  },
  mounted(){
    this.width = document.getElementById('report').clientWidth * .9
    if (this.data.length) 
      this.draw(this.data)
    else
      this.$watch('data', (val) => {
        this.draw(val)
      })
  },
  methods:{
    draw(d){
      var svg = d3.select(this.$el),
          bg = svg.select('.bg'),
          eachH = this.width / 9,               //每项的高度
          gap =  18,                            //上下缝隙
          left = this.width * 0.2,              //左边维度文字宽度
          fontSize = this.width * 0.035,        //字体大小
          startY = eachH / 2 + gap,             //开始的y轴坐标
          vW = this.width - left - eachH * 1.8,   //数据落入宽度（底下2个刻度之间的距离）
          len = d.length,                       //维度数
          height = eachH * len + gap * 2,       //整个svg的高度
          min = 100, 
          max = 0
      //绘制背景，第一个循环要算出最大最小值
      svg.attr('height', height)
      for (let j = 0; j < len; j++) {
        let y = j * eachH + startY
        bg.append('text')
          .classed('center', true)
          .attr('font-size', fontSize)
          .attr('fill', '#999')
          .attr('x', left / 2)
          .attr('y', y)
          .text(d[j].name)
        bg.append('line')
          .attr('x1', left)
          .attr('y1', y)
          .attr('x2', this.width)
          .attr('y2', y)
          .attr('stroke', '#b3b3b3')
          .attr('stroke-dasharray', '2px 4px')
          .attr('stroke-width', 1)
        if (d[j].percentage_score < min) {
          min = d[j].percentage_score
        }
        if (d[j].other_percentage_score < min) {
          min = d[j].other_percentage_score
        }
        if (d[j].percentage_score > max) {
          max = d[j].percentage_score
        } 
        if (d[j].other_percentage_score > max) {
          max = d[j].other_percentage_score
        }
      }
      // console.log('最小值',min,'最大值',max)
      min = Math.floor(min / 10) * 10
      max = Math.ceil(max / 10) * 10 + 10
      max = max > 100 ? 100 : max
      bg.append('text') //最小刻度
        .classed('center', true)
        .attr('font-size', fontSize)
        .attr('fill', '#999')
        .attr('x', left + 4)
        .attr('y', height - gap + 2)
        .text(min)
      bg.append('text') //最大刻度
        .attr('text-anchor', 'end')
        .attr('font-size', fontSize)
        .attr('fill', '#999')
        .attr('x', left + vW)
        .attr('y', height - gap + 2)
        .text(max)

      
      //绘制数据，第二个循环算出每项最大
        function getDot(s, i) {
          let x = (s - min) / (max - min) * vW + left
          let y = i * eachH + startY
          return [x, y]
        }
      var line = svg.append('g'),
          dot1 = [],
          dot2 = [],
          str1 = '', 
          str2 = '',
          maxList = []
      //添加动画
      if (this.animate) line.classed('lineMove', true)
      for (let i = 0; i < len; i++) {
        let p1 = getDot(d[i].percentage_score, i)
        let p2 = getDot(d[i].other_percentage_score, i)
        let eachMax = 0
        if (d[i].percentage_score > eachMax) {
          eachMax = d[i].percentage_score
          maxList[i] = p1
        }
        if (d[i].other_percentage_score > eachMax) {
          eachMax = d[i].other_percentage_score
          maxList[i] = p2
        }
        dot1[dot1.length] = p1
        dot2[dot2.length] = p2
        str1 += `${p1[0]},${p1[1]} `
        str2 += `${p2[0]},${p2[1]} `
      }
      //绘制第一根线
        for (let dot of dot2) {
          line.append('circle')
              .attr('cx', dot[0])
              .attr('cy', dot[1])
              .attr('r', 5)
              .attr('fill', this.color[this.colotType][1]);
        }
        line.append('polyline')
            .attr('fill', 'none')
            .attr('stroke', this.color[this.colotType][1])
            .attr('stroke-width', 2)
            .attr('points', str2)
      //绘制第二根线
        for (let dot of dot1) {
          line.append('circle')
              .attr('cx', dot[0])
              .attr('cy', dot[1])
              .attr('r', 5)
              .attr('fill', this.color[this.colotType][0]);
        }
        line.append('polyline')
            .attr('fill', 'none')
            .attr('stroke', this.color[this.colotType][0])
            .attr('stroke-width', 3)
            .attr('points', str1)
      //绘制矩形块
        for (let i in maxList) {
          let diff = svg.append('g').classed('diffLabel', true)
          let score = d[i].difference_score
          let text = `差值 : ${d[i].difference_score}`,
              w = text.length * fontSize  * .72,
              h = eachH * .6,
              x = maxList[i][0] + w / 1.5,
              y = maxList[i][1] - eachH / 2,
              color = ''
          if (score <= 15) color = '#4573d7'
          else if (score <= 35) color = '#5ec1ff'
          else if (score <= 65) color = '#de67c7'
          else if (score <= 85) color = '#f1994c'
          else color = '#ee626e'
          diff.append('rect')
              .attr('fill', color)
              .attr('x', x)
              .attr('y', y - 1)
              .attr('rx', 3)
              .attr('ry', 3)
              .attr('width', w)
              .attr('height', h)
              .attr('transform', `translate(-${w/2},-${h/2})`)
          diff.append('polyline')
              .attr('fill', color)
              .attr('points', `${x-h*.9},${y} ${x-h},${y+h*.8} ${x},${y}`)
          diff.append('text')
              .classed('center', true)
              .attr('font-size', fontSize)
              .attr('fill', '#fff')
              .attr('x', x)
              .attr('y', y)
              .text(text)
        }
    },
  },
}
</script>
<style scope lang="scss">
text{dominant-baseline: middle;}
.center{text-anchor: middle;}
.end{text-anchor: end;}

.diffLabel{
  animation: a1 1s .3s forwards;
  transform: translate(-10%,0);
  opacity: 0;
}
@keyframes a1 {
  0%{
    transform: translate(-10%,0);
    opacity: 0;
  }
  100%{
    transform: translate(0,0);
    opacity: 1;
  }
}

.lineMove{animation: a2 .6s;transform-origin: 20% center}
@keyframes a2 {
  0%{
    transform: scaleX(0);
    opacity: 0;
  }
  100%{
    transform: scaleX(1);
    opacity: 1;
  }
}
</style>
