<template>
  <div class="report" id="report" :class="{long:long}">
    <div class="top">
      <header>
        <img :src="avatar" class="head-img">
        <div class="user">
          <div>{{name}} <span>报告分析</span></div>
          <div class="time">{{$formatTime(endTime,'y-m-d h:m')}}</div>
        </div>
        <div class="btn" v-if="saveShow && !long" @click="save">报告长图</div>
      </header>
      <nav v-if="!long">
        <span :class="{current:tabId==0}" @click="tab(0)">工作能量</span>
        <span :class="{current:tabId==1}" @click="tab(1)">工作价值倾向</span>
        <span :class="{current:tabId==2}" @click="tab(2)">原动力</span>
        <span :class="{current:tabId==3}" @click="tab(3)">职业素质</span>
      </nav>
    </div>
    <ul class="content">
      <!-- 工作能量 -->
      <li class="energy" v-show="tabId==0 || long">
        <div class="title">[工作能量]</div>
        <div class="total-score">
          <h1>{{work_capacity[0].work_capacity}}</h1>
          <div class="battery" :class="{fade:!long}">
            <i v-for="(e,i) in batteryNum" :key="i"></i>
          </div>
        </div>
        <div class="level">
          <h2>当下，您的工作能量水平<b>{{work_capacity[0].work_capacity}}</b>，能量等级为<b>{{work_capacity[0].work_capacity_content}}</b></h2>
        </div>
        <div class="title">[原动力&职业素质]</div>
        <pieChart ref="pie" :width="width*.75" :score="pie.score" :name="pie.name"></pieChart>
        <p v-html="compareText"></p>
        <div class="title">[工作价值倾向]</div>
        <div style="margin:40px 0">
          <waveBall :width="width*.28" :type="2" :name="work_value[0].name"></waveBall>
        </div>
        <p>当下，<b style="color:#e29b0b">“{{work_value[0].name}}”</b>是您最关注的内在需求，工作中它是您产生动力的触发点，也是快乐工作的根源。</p>
      </li>
      <!-- 工作价值倾向 -->
      <div class="t1" v-if="long">工作价值倾向</div>
      <li class="values" v-show="tabId==1 || long">
        <div class="rank">
          <div class="box box1" @click="showIndex=0">{{work_value[0]?work_value[0].name:' '}}<span>NO.1</span></div>
          <div class="box box2" @click="showIndex=1">{{work_value[1]?work_value[1].name:' '}}<span>NO.2</span></div>
          <div class="box box3" @click="showIndex=2">{{work_value[2]?work_value[2].name:' '}}<span>NO.3</span></div>

          <h2 v-if="!long">点击维度名称可查看说明</h2>
          <div class="tip" v-if="showIndex!=null">
            <h6>{{work_value[showIndex].dimension_detail}}</h6>
            <div class="close" @click="showIndex=null">关 闭</div>
          </div>
        </div>
        <h4>从您的答题选项中，反映出您当下最看重的工作价值是：<span v-for="(it,i) in work_value" :key="i">{{it.name}}{{i!=work_value.length-1?'、':''}}</span></h4>
        <p v-for="(item,index) in work_value" :key="index">{{item.content}}</p>
      </li>
      <!-- 原动力 -->
      <div class="t1" v-if="long">原动力</div>
      <li class="motivity" v-show="tabId==2 || long">
        <ringChart :data="motiveTotal" :width="width*.62" :animate="!long"></ringChart>
        <h1>原动力指数：<span>{{motive_power[0].score}}</span></h1>
        <barRow :data="motive_power[1]" :color-type="1"></barRow>
        <div class="risk" v-if="motive_power.risk_dimension">
          <h4>可能影响：</h4>
          <h5 v-for="item in motive_power.risk_dimension" :key="item.id"><b>{{item.name}}：</b>{{item.content}}</h5>
        </div>
        <div class="risk" v-if="motive_power.norisk_dimension.length">
          <h4>特征描述：</h4>
          <div v-for="item in motive_power.norisk_dimension" :key="item.id">
            <h6>{{item.name}}</h6>
            <h5 class="dot" v-for="(it,j) in item.content" :key="j">{{it}}</h5>
          </div>
        </div>
      </li>
      <!-- 职业素质 -->
      <div class="t1" v-if="long">职业素质</div>
      <li class="motivity" v-show="tabId==3 || long">
        <ringChart :type="1" :data="qualityTotal" :width="width*.62" :animate="!long"></ringChart>
        <h1>职业素质指数：<span>{{basic_quality[0].score}}</span></h1>
        
        <barRow :data="basic_quality[1]" :color-type="2"></barRow>
        <div class="risk" v-if="basic_quality.risk_dimension">
          <h4>可能影响：</h4>
          <h5 v-for="item in basic_quality.risk_dimension" :color-type="1" :key="item.id"><b>{{item.name}}：</b>{{item.content}}</h5>
        </div>
        <div class="risk" v-if="basic_quality.norisk_dimension.length">
          <h4>特征描述：</h4>
          <div v-for="item in basic_quality.norisk_dimension" :key="item.id">
            <h6>{{item.name}}</h6>
            <h5 class="dot" v-for="(it,j) in item.content" :key="j">{{it}}</h5>
          </div>
        </div>
      </li>
    </ul>
    <!-- 图像渐变 -->
    <svg class="hide">
      <defs>
        <linearGradient id="red" x1="0%" y1="0%" x2="0%" y2="100%">
          <stop offset="0%" style="stop-color:#ff3d94;stop-opacity:1"/>
          <stop offset="100%" style="stop-color:#ffa603;stop-opacity:1"/>
        </linearGradient>
        <linearGradient id="yellow" x1="0%" y1="0%" x2="0%" y2="100%">
          <stop offset="0%" style="stop-color:#fe7218;stop-opacity:1"/>
          <stop offset="100%" style="stop-color:#feb201;stop-opacity:1"/>
        </linearGradient>
      </defs>
    </svg>
    <div class="img-box" v-if="reportImgShow">
      <p class="text">长按保存图片</p>
      <div class="inner-box"><img :src="reportImg"></div>
      <img @click="closeImg" class="close" src="../../assets/images/icons/close.png"/>
    </div>
  </div>
</template>
<script>
import pieChart from '../space/vigour/chart/pieChart'
import waveBall from '../space/vigour/chart/waveBallChart'
import barRow from '../space/vigour/chart/barRowChart'
import ringChart from './self/ringChart'
export default {
  components:{pieChart,waveBall,ringChart,barRow},
  data(){
    return{
      width: 0,
      tabId: 0,
      origin: this.$route.query.origin || 'mobile',  
      exam_id: this.$route.params.exam_id||'',
      hy_session: this.$route.params.hy_session||'',
      long:this.$route.query.long===undefined?false:true, //是否报告长图
      name: '无法加载',      //用户姓名
      avatar: require('../../assets/images/icons/user_icon.png'),
      endTime: 0,         //报告完成时间
      reportImg:'',
      reportImgShow: false,
      saveShow: true,
      timer:'',
      //工作能量(work_capacity)：
        work_capacity:[
          {work_capacity:0,work_capacity_content:''},
          {active_energy:[],potential_energy:[]},
          [],[]
        ],
        pie:{name:'',score:[]},//8份扇形的百分比
      //工作价值倾向：
        work_value:[{name:'',}],
        showIndex:null,
      //原动力：
        motive_power:{
          0:{score:0},
          1:[],
          2:[[],[]],
          risk_dimension: '',
          norisk_dimension: [],
        },
        motiveTotal: 0,
        motive: [],
      //职业素质：
        basic_quality:{
          0:{score:0},
          1:[],
          2:[[],[]],
          risk_dimension: '',
          norisk_dimension: [],
        },
        qualityTotal: 0,
        quality: [],
      //销售行为分析：
        sale:[[],[]],
        saleShow: true,
      //思考与建议：
        suggestion:[
          {active_energy:[],potential_energy:[]},
          {tier:'',time_manage:'',work_concept:'',work_performance:''},
          []
        ]
    }
  },
  created(){
    this.$http.post(this.ajaxUrlPath+'/AceTeam/getExamReportInfo', {
      origin: this.origin,
      exam_id: this.exam_id,
      hy_session: this.hy_session,
    }).then((e) => {
      let data = e.data.data
      if (e.data.code == 0) {
        //个人信息
        this.name = data.user_info.realname||data.user_info.nickname
        this.avatar = data.user_info.avatar
        this.endTime = data.user_info.end_time
        //工作能量
        this.work_capacity = data.work_capacity
        this.totalScore = data.work_capacity[0].work_capacity
        this.wordLevel = data.work_capacity[0].work_capacity_content
        let radar = data.work_capacity[3]
        this.pie.score = [radar[2], radar[1]]
        this.pie.name = radar[0].name
        //销售行为分析
        this.sale = data.sale
        if(data.user_info.sale_show==0)
          this.saleShow = false
        //原动力
        this.motive_power = data.motive_power
        //职业素质
        this.basic_quality = data.basic_quality
        if (this.long) {
          this.qualityTotal = this.basic_quality[0].score
          this.quality = this.basic_quality[1]
          this.motiveTotal = this.motive_power[0].score
          this.motive = this.motive_power[1]
        }
        //工作价值倾向
        this.work_value = data.work_value
        
        if (this.origin == 'admin') this.saveShow = false
      } else {
        this.$toast({message:'加载失败',duration: 1000})
      }
    })
  },
  mounted(){
    this.width = $('#app').width()
    // window.onscroll = ()=>{//监听滚动
      //   let scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
      //   if(scrollTop >= document.getElementById('nav').offsetTop){
      //     this.fix = true
      //   }else{
      //     this.fix = false
      //   }
    // }
  },
  methods:{
    tab(e){
      this.$refs.pie.showIndex = null
      this.showIndex = null
      if (this.tabId==e) return;
      this.tabId = e;
      switch(e) {
        case 1:
          this.motiveTotal = 0
          this.motive = []
          this.qualityTotal = 0
          this.quality = []
          break;
        case 2:
          this.qualityTotal = 0
          this.quality = []
          this.motiveTotal = this.motive_power[0].score
          this.motive = this.motive_power[1]
          break;
        case 3:
          this.motiveTotal = 0
          this.motive = []
          this.qualityTotal = this.basic_quality[0].score
          this.quality = this.basic_quality[1]
          break;
        default:
          this.motiveTotal = 0
          this.motive = []
          this.qualityTotal = 0
          this.quality = []
          break;
      }
    },
    save(){
      // this.$router.push({path:`/aceTeamSelf/${this.hy_session}/${this.exam_id}?long`}); return;
      if (this.reportImg)
        this.reportImgShow = true
      else
        this.$http.get(this.ajaxUrlPath+'/AceTeam/getReportImage?hy_session='+this.hy_session+'&exam_id='+this.exam_id).then(({data}) => {
          if (data.code == 0) {
            this.reportImg = data.data.report_image
            this.reportImgShow = true
          } else if (data.code == 1) {
            this.$indicator.open({text: '图片生成中...',spinnerType: 'triple-bounce'});
            this.$http.post(this.ajaxUrlPath+'/CardImage/createshotImg',{
              type: 'trumpReport',
              imageurl: `${this.mobileUrl}/#/aceTeamSelf/${this.hy_session}/${this.exam_id}?long`,
              width: '400',
              height: '600',
            }).then(({data}) => {
              this.$indicator.close()
              if (data.code == 0) {
                this.reportImg = data.data.image
                this.reportImgShow = true
              } else {
                this.$toast({message: data.msg})
              }
            })
          }
        })
    },
    closeImg(){
      this.reportImgShow = false
    },
  },
  computed:{
    batteryNum(){
      let score = this.work_capacity[0].work_capacity, num = 5;
      if(score==0) num = 0
      else if(score<40) num = 1
      else if(score<60) num = 3
      else if(score<75) num = 4
      return num
    },
    compareText(){
      let diff = this.motive_power[0].score - this.basic_quality[0].score
      if (diff > 0) {
        return '当下，您的<b style="color:#54a7e9">“原动力”</b>高于<b style="color:#fe7b14">“职业素质”</b>，需要加强技能训练和刻意练习，提升工作品质和效率是改善的重点。'
      } else if (diff < 0) {
        return '当下，您的<b style="color:#fe7b14">“职业素质”</b>高于<b style="color:#54a7e9">“原动力”</b>，挖掘内在动力，加强心态管理，找到工作的意义和价值是改善的重点。'
      } else if(this.motive_power[0].score > 74) {
        return '当下，您的“原动力”跟“职业素质”相当，请保持现有状态，并不断突破成长！'
      } else {
        return '当下，您的“原动力”跟“职业素质”相当，对内需要关注动力的挖掘，在外需要加强技能训练。'
      }
    },
  },
}
</script>
<style scoped lang="scss">
.report{
  color:#4d4d4d;
  &::before{content: '';display: block;height: 188px;}
  .hide{height: 0;overflow: hidden;position: absolute;}
  .top{
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    background: #fff;
    z-index: 10;
    header{
      display: flex;
      align-items: center;
      font-size: 26px;
      color: #aaa;
      padding: 0 40px;
      height: 120px;
      .head-img{
        width: 76px;
        height: 76px;
        border-radius: 50%;
        overflow: hidden;
        display: block;
        margin-right: 20px;
        background: #ccc;
      }
      .user{
        text-align: left;
        flex: 1;
        .time{
          font-size: 24px;;
          color:#bfbfbf
        }
        span{font-size: 24px;}
      }
      .btn{
        width: 150px;
        color: #fff;
        border-radius: 4px;
        background: #e5b552;
        height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    nav{
      display: flex;
      border-top: 1px solid #f0f0f0;
      border-bottom: 1px solid #f0f0f0;
      color: #999;
      height: 80px;
      background: #fff;
      box-sizing: border-box;
      justify-content: space-around;
      font-size: 26px;
      align-items: center;
      overflow-x: auto;
      overflow-y: hidden;
      &::-webkit-scrollbar{display: none}
      span{
        flex-shrink: 0;
        padding: 0 8px;
        line-height: 1.1;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        white-space: nowrap;
      }
      .current{
        font-size: 28px;
        color: #4d4d4d;
        font-weight: bold;
        &::after{
          content: '';
          position: absolute;
          width: 98%;
          height: 4px;
          background: #e5b552;
          bottom: -1px;
          left: 0;
        }
      }
    }
  }
  .content{
    overflow-x: hidden;
    .t1{
      line-height: 80px;
      font-weight: bold;
      font-size: 30px;
      margin-top: 160px;
      color: #272727;
    }
    li{margin: 0 40px}
    .title{
      font-size: 28px;
      margin: 120px 0 0;
    }
    .energy{
      padding-bottom: 30px;
      .total-score{
        width: 330px;
        height: 330px;
        border: 1px solid #fecc65;
        border-radius: 50%;
        margin: 70px auto 40px;
        position: relative;
        h1{
          font-size: 68px;
          color: #e5b552;
          padding: 80px 0 4px;
          font-weight: bold;
        }
        .battery{
          width: 154px;
          height: 72px;
          padding:6px 24px 6px 6px;
          margin: 0 auto;
          background: url('../../assets/images/trump/battery.png') no-repeat;
          background-size: 100% 100%;
          text-align: left;
          // display: flex;
          i{
            display: inline-block;
            width: 20%;
            height: 60px;
            border: 1px solid #fff;
            background: #f8c949;
            border-radius: 6px;
            &:nth-child(2){background: #f99351;}
            &:nth-child(3){background: #7991fd;}
            &:nth-child(4){background: #cc6ef4;}
            &:nth-child(5){background: #fa6e7d;}
          }
        }
        .fade i{
          opacity: 0;
          animation: opacity .15s linear;
          animation-fill-mode:forwards;
          &:nth-child(2){animation-delay: .15s;}
          &:nth-child(3){animation-delay: .3s;}
          &:nth-child(4){animation-delay: .45s;}
          &:nth-child(5){animation-delay: .6s;}
        }
        @keyframes opacity {100%{opacity: 1;}}
        &::after{//转圈
          content: '';
          position: absolute;
          width: 100%;
          height: 100%;
          top: 0;left: 0;
          background: url('../../assets/images/trump/score-bg.png') no-repeat;
          background-size: 92% 92%;
          background-position: center;
          animation: rotate 100s linear infinite;
        }
      }
      .level{
        font-size: 30px;
        h2{
          line-height: 2.2;
          font-weight: bold;
        }
        h3{
          line-height: 1.2;
          font-weight: bold;
        }
        b{color: #e29b0b;}
        span{color: #1a1a1a}
      }
      h4{
        font-size: 30px;
        text-align: justify;
        font-weight: bold;
        margin: 30px 0;
      }
      .h4{color: #e29b0b}
      p{
        font-size: 28px;
        color: #6f6f6f;
        padding-left: 30px;
        position: relative;
        text-align: justify;
        line-height: 1.44;
        margin-bottom: 6px;
        &::before{
          content: '';
          position: absolute;
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background: #6f6f6f;
          left: 6px;
          top: 14px;
        }
      }
      @keyframes rotate {
        100%{transform: rotate(360deg);}
      }
    }
    .motivity{
      h1{
        line-height: 1.4;
        font-weight: bold;
        font-size: 30px;
        margin-bottom: 50px;
        span{color: #e29b0b}
      }
      .risk{
        font-size: 28px;
        text-align: left;
        margin: 40px 0;
        h4{
          color: #d81e06;
          padding-left: 40px;
          font-weight: bold;
          margin-bottom: 20px;
        }
        h5{
          color: #6f6f6f;
          padding-left: 40px;
          position: relative;
          margin: 6px 0;
          &::before{
            content: '';
            background: url('../../assets/images/report/risk.png') no-repeat;
            position: absolute;
            background-size: 100%;
            width: 28px;
            height: 28px;
            top: 5px;
            left: 0px;
          }
        }
        h6{
          font-weight: bold;
          padding-left: 40px;
        }
        .dot::before{
          background: #666;
          width: 10px;
          height: 10px;
          border-radius: 50%;
          top: 15px;
          left: 10px;
        }
        b{color: #4d4d4d}
      }
    }
    .values{
      .rank{
        position: relative;
        width: 388px;
        height: 380px;
        margin: 60px auto 120px;
        color: #fff;
        font-size: 26px;
        .box{
          position: absolute;
          box-sizing: border-box;
          border-radius: 50%;
          border: 6px solid #f2f2f2;
          line-height: 1.6;
          font-weight: bold;
          span{display: block;font-weight: normal}
        }
        .box1{
          width: 288px;
          height: 288px;
          padding-top: 50px;
          top: 0;
          left: 20px;
          background: #f9c767;
          animation: box1 .8s forwards;
        }
        .box2{
          width: 240px;
          height: 240px;
          padding-top: 76px;
          bottom: 20px;
          right: 0;
          background: rgba(78,206,157,0.9);
          animation: box2 1s forwards;
        }
        .box3{
          width: 180px;
          height: 180px;
          padding-top: 48px;
          bottom: 0;
          left: 0;
          background: rgba(122,145,250,0.9);
          animation: box3 1.4s forwards;
        }
        @keyframes box1 {
          0%{opacity: 0;top: 20px}
          95%{opacity: 1;top: 0}
        }
        @keyframes box2 {
          0%{opacity: 0;bottom: -20px}
          12%{opacity: 0;bottom: -20px}
          95%{opacity: 1;bottom: 20px}
        }
        @keyframes box3 {
          0%{opacity: 0;bottom: -20px}
          24%{opacity: 0;bottom: -20px}
          95%{opacity: 1;bottom: 0}
        }
        h2{
          position: absolute;
          font-size: 24px;
          color: #666;
          left: 50%;
          bottom: -70px;
          white-space: nowrap;
          transform: translateX(-50%);
        }
        .tip{
          position: absolute;animation: dimFade .4s forwards;
          width: 540px;padding: 30px 40px 10px;
          background: #fff;border-radius: 10px;
          left: 50%;top: 46.8%;transform: translate(-50%,-50%);
          box-shadow: 0 0 6px 2px rgba(200,200,200,.3);
          h5{
            font-size: 28px;
            color: #333;
            line-height: 2;
          }
          h6{
            text-align: justify;
            font-size: 24px;
            color: #4d4d4d
          }
          .close{
            width: 100px;
            color: #e29b0b;
            height:40px;
            display: flex;
            align-items: center; 
            justify-content: center;
            margin: 20px auto 10px;
            border-radius: 6px;
          }
        }
      }
      h4{
        font-size: 30px;
        text-align: justify;
        font-weight: bold;
        margin-bottom: 24px;
        span{color: #e29b0b;}
      }
      p{
        font-size: 28px;
        color: #6f6f6f;
        padding-left: 30px;
        position: relative;
        text-align: justify;
        margin-bottom: 6px;
        &::before{
          content: '';
          position: absolute;
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background: #6f6f6f;
          left: 6px;
          top: 14px;
        }
      }
    }
  }
  .img-box{
      width: 100%;
      height: 100%;
      position: fixed;
      background: rgba(0,0,0,0.8);
      top: 0;
      left: 0;
      z-index: 100;
      font-size:30px;
      .text{line-height: 100px;color:#fff;}
      .inner-box{
        flex: 1;
        height: 80%;
        margin:0 30px 20px;
        overflow-y: auto;
        img{width: 100%;height: auto;display: block;}
      }
      .close{
        display: block;
        width: 70px;
        height: 70px;
        margin:0 auto;
      }
  }
}
.long{
  .top{border-bottom: 1px solid #f6f6f6;}
  &::before{height: 80px;}
}
</style>