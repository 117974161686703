<template>
    <div class="main">
        <div class="head">
            <img :src="avatar" class="user-icon" />
            <p class="r-title">您的分析报告已完成</p>
            <p class="r-score">已获得1积分</p>
            <span class="r-btn"  @click="getReportImage">保存报告</span>
        </div>
        <report_data :reportData="reportData" v-if="reportData.sale_score"></report_data>
        <p class="back" @click="goBack" ><span>返回个人中心</span></p>
        
        <div class="img-box" v-if="flagShowImg">
            <div class="inner-box">
                <p class="title">长按保存图片</p>
                <img :src="saveImgUrl" class="save-img">
                <img @click="closeImg" class="close" src="../../assets/images/icons/close.png"/>
            </div>
        </div>
    </div>
    
</template>

<script>
// import circleProgress from './circle-progress'
import report_data from './reportData'
export default {
    data(){
        return{
            flagShowImg: false,
            exam_id: this.$route.query.exam_id||'',
            exam_info:{},
            reportData:{},
            avatar:'',
            getReportInterval: null,
            type:'salereport',
            saveImgUrl:'',
            session_id:this.$route.query.session_id,
        }
    },
    components:{
        report_data
    },
    mounted() {
        this.getReportInfo();
    },
    destroyed() {
        clearInterval(this.getReportInterval)
    },
    methods:{
        getReportInfo(){
            this.$http.post(
                this.ajaxUrlPath+'/Sale/getReportInfo',
                {
                    exam_id: this.exam_id,
                    type: '1',
                }
            ).then(resp=>{
                if(resp.data.code == 0){
                    this.exam_info = resp.data.data.exam_info;
                    this.reportData = resp.data.data.report_data;
                    this.avatar = this.exam_info.avatar || '../../assets/images/icons/user_icon.png'
                }else{
                    this.$toast({
                        message:resp.data.msg,
                        duration: 1000,
                    })
                    wx.miniProgram.reLaunch({
                        url: '/pages/userCenter/userCenter',
                        success: function(){
                            console.log('success')
                        },
                        fail: function(){
                            console.log('fail');
                        },
                        complete:function(){
                            console.log('complete');
                        }
                    })
                }
            })
        },
        goBack(){
            console.log(wx)
            wx.miniProgram.reLaunch({
                url: '/pages/userCenter/userCenter',
                success: function(){
                    console.log('success')
                },
                fail: function(){
                    console.log('fail');
                },
                complete:function(){
                    console.log('complete');
                }
            })
        },
        creatImg(){
            let imgUrl = this.mobileUrl+'/#/sales/reportImg?exam_id='+this.exam_id;
            this.$http.post(
                this.ajaxUrlPath+'/CardImage/create',
                {
                    type: this.type,
                    imageurl: imgUrl,
                    width:'400',
                    height:'600',
                },
            ).then(resp=>{
                let data = resp.data;
                data = typeof data === 'string' ? JSON.parse(data):data;
                if(data.code == 0){
                    let messageId = data.data.messageId;
                    this.$indicator.open({
                        text: '图片生成中...',
                        spinnerType: 'triple-bounce'
                    });
                    this.getReportInterval = setInterval(()=>{
                        this.getReport(messageId);
                    },1000)
                }
            })
        },
        getReport(messageId){
            this.$http.post(
                this.ajaxUrlPath+'/CardImage/getreport',
                {
                    type: this.type,
                    messageId: messageId,
                },
            ).then(resp=>{
                let data = resp.data;
                data = typeof data === 'string' ? JSON.parse(data):data;
                if(data.code == 0){
                    this.$indicator.close();
                    this.saveImgUrl = data.data.image;
                    this.fnShowImg();
                    clearInterval(this.getReportInterval);
                    this.updateReportImage();
                }
            })
        },
        getReportImage(){
            this.$http.post(
                this.ajaxUrlPath+'/Sale/getReportImage',
                {
                    type: 1,
                    exam_id:this.exam_id,
                    hy_session:this.session_id,
                },
            ).then(resp=>{
                let data = resp.data;
                data = typeof data === 'string' ? JSON.parse(data):data;
                if(data.code == 0){
                    let report_image = data.data.report_image;
                    if(report_image){
                        this.saveImgUrl = data.data.report_image;
                        this.fnShowImg();
                    }else{
                        this.creatImg();
                    }
                }else{
                    this.creatImg();
                }
            })
        },
        updateReportImage(){
            this.$http.post(
                this.ajaxUrlPath+'/Sale/updateReportImage',
                {
                    type: 1,
                    exam_id:this.exam_id,
                    report_image:this.saveImgUrl,
                    hy_session:this.session_id,
                },
            )
        },
        fnShowImg(){
            this.flagShowImg = true;
        },
        closeImg(){
            this.flagShowImg = false;
        },
    },
}
</script>

<style lang="scss" scoped>
.main{
    min-height: 100%;
    background: #fff0e7;
}
.head{
    width: 100%;
    height: 430px;
    background: linear-gradient(180deg,#ff9164,#fe5e1c);
    position: relative;
}
.user-icon{
  width: 54px;
  height: 54px;
  border-radius: 100%;
  position: absolute;
  top: 50px;
  left: 50px;
}

.r-title{
  position: absolute;
  top: 46px;
  font-size: 28px;
  color: #903005;
  width: 100%;
  left: 0;
  text-align: center;
}
.r-score{
  color: #903005;
  font-size: 24px;
  position: absolute;
  width: 100%;
  left: 0;
  text-align: center;
  top: 89px;
}
.r-btn{
  position: absolute;
  font-size: 24px;
  color: #fff;
  top: 65px;
  right: 47px;
  border-bottom: 1px solid rgba(255,255,255,1);
}
.back{
    font-size: 24px;
    color: #fe6122;
    text-align: center;
    padding: 25px 0;
    span{
        border-bottom: 1px solid rgba(254, 97, 34, 1);
    }
}
.img-box{
    width: 100%;
    height: 100%;
    position: fixed;
    background: rgba(0,0,0,0.8);
    top: 0;
    left: 0;
    z-index: 3;
    overflow-y: scroll;
    text-align: center;
    font-size:30px;
    .inner-box{
        height: 100%;
    }
    .title{
        line-height: 100px;
        color:#fff;
    }
    .close{
        width: 60px;
        height: 60px;
        margin: 0 auto 20px;
    }
    .save-img{
        width: auto;
        height: 80%;
        margin: 0 auto 20px;
        display: block;
        max-width:400PX;
        max-height: 600PX;
    }
}
</style>