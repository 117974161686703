<template>
  <div id="AMT">
    <div class="info flex--c">
      <img :src="avatar">
      <div>
        <div class="name">{{name}} 的领导者画像</div>
        <div>
          {{endTime||''}}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          <span v-if="version">版本号 v{{version}}</span>
        </div>
      </div>
    </div>
    <div class="main main1" id="main">
      <div class="starBox">
        <b>领导思维清晰度:</b>
        <i v-for="item in 5" :key="item" :class="{
          star2: item <= star,
          star3: star < item && star > item-1
        }"/>
      </div>
      <h1>领导思维类型</h1>
      <div class="box1">
        <div class="box2">你的思维类型：<b>{{leader.leadership}}</b></div>
        <div class="flex">
          <div class="box3">
            <h2>思维特征</h2>
            <p v-for="(it,j) in leader.thinking_feature" :key="j" v-html="it"/>
          </div>
          <div class="box4">
            <h2>你的关注</h2>
            <p v-for="(it,j) in leader.new_attention" :key="j" v-html="it"/>
          </div>
        </div>
        <div class="box5">
          <h2>你的画像</h2>
          <div class="word">
            <p v-for="(it,j) in leader.description" :key="j" v-html="it"/>
          </div>
        </div>
      </div>
    </div>
    <div class="main main2">
      <h1>领导思维的相互关系</h1>
      <ol>
        <li v-for="(item,i) in relation" :key="i" :class="'linear'+item.id">
          <label>利我</label><label>利他</label>
          <span v-html="item.name"/>
        </li>
        <i/><div/><i class="i2"/>
      </ol>
      <p><b>利我的思维：</b></p>
      <p v-for="(it,j) in likeMe" :key="j" v-html="it"></p>
      <p style="margin-top:20px"><b>利他的思维：</b></p>
      <p v-for="(it,j) in likeHe" :key="j" v-html="it"></p>
    </div>
    <div class="main main3">
      <h1>领导思维的概况</h1>
      <h3>【领导思维】的说明：</h3>
      <p>1、每位领导者身上同时存在5种领导思维；</p>
      <p>2、大部分领导者在工作中常用一种思维；</p>
      <p>3、领导思维也是一种能量，不同的思维散发的能量不同，对人和事物的影响不同。</p>
      <ul>
        <li class="linear1">E型（企业家思维）</li>
        <li class="linear3">M型（管理思维）</li>
        <li class="linear4">P型（专家思维）</li>
        <li class="linear5">F型（前线思维）</li>
        <li class="linear6">S型（战略思维）</li>
      </ul>
      <p></p>
      <h3>【领导思维】的意义：</h3>
      <p>1、帮助领导者了解自己常用的领导思维，深刻理解与其他思维的相互关系；</p>
      <p>2、有效管理自己的思维、角色、行为的一致性，善于与他人建立有效的协作关系。</p>
      <h3>【领导思维】的应用：</h3>
      <div class="apply apply1">
        <img src="../../../assets/images/report/AMT_report4.png">
        <h5>自我觉察</h5>
        <p>分析自己当下的角色定位与思维的匹配度；找出改善空间，提升领导力。</p>
      </div>
      <div class="apply apply2">
        <img src="../../../assets/images/report/AMT_report5.png">
        <h5>觉察他人</h5>
        <p>理解他人与自己的思维差异；换位思考，调整沟通方式；提升核心管理团队之间的合作效率。</p>
      </div>
    </div>
  </div>
</template>
<script>
import * as d3 from 'd3'
export default {
  props:{
    exam_id: {default: ''},
    version: {default: ''}
  },
  data(){
    return {
      // exam_id: this.$route.query.exam_id || '',
      hy_session: this.$route.query.hy_session || '',
      name: "正在加载",                    //用户姓名
      avatar: require('../../../assets/images/icons/user_icon.png'),
      endTime: 0,                         //报告完成时间
      star: 0,                            //星级
      leader: {
        leadership: '',
        thinking_feature: '',             //思维特征
        new_attention: [],                //你的关注       
        description: [],                  //你的画像  
      },
      relation: [],
      likeMe: [],
      likeHe: [],
    }
  },
  created(){
    this.$indicator.open({text: '加载中...',spinnerType: 'fading-circle'});
    this.$http.get(this.spaceUrl+'/Amt/getExamReportInfo',{
      params:{exam_id: this.exam_id}
    }).then((e) => {
      if (e.data.code == 0) {
        let userInfo = e.data.data.user_info
        this.avatar = userInfo.avatar
        this.name = userInfo.realname || userInfo.nickname
        this.endTime = this.$formatTime(userInfo.end_time,'y-m-d h:m')
        this.star = e.data.data.definition

        let leader = e.data.data.leader_ship[0]
        this.leader = leader
        //互相关系
        let arr =  [
          ...leader.benefit_me, 
          ...leader.benefit_he, 
          ...[{leader_ship_id: leader.leader_ship_id, leadership: leader.benefit_self}]
        ]
        for (let i in arr) {
          let text = arr[i].leadership.split('（')
          arr[i].name = `${text[0]}<br>（${text[1]}`
          arr[i].id = arr[i].leader_ship_id
        }
        this.relation = arr
        //利我思维描述
        for (let i = 0; i < leader.benefit_me.length; i++) {
          let str = leader.benefit_me[i].content.replace(/([A-Z]\S{1,4}思维)/g, n => `<span class='${n.replace(/[^A-Z]/g,'')}'>${n}</span>`)
          this.likeMe.push(`${i+1}、${str}`)
        }
        //利他思维描述
        for (let i = 0; i < leader.benefit_he.length; i++) {
          let str = leader.benefit_he[i].content.replace(/([A-Z]\S{1,4}思维)/g, n => `<span class='${n.replace(/[^A-Z]/g,'')}'>${n}</span>`)
          this.likeHe.push(`${i+1}、${str}`)
        }
      } else {
        this.name = '无法加载'
        this.$toast({message: e.data.msg, duration: 2000})
      }
      this.$indicator.close();
    }).catch((err) => {
      console.error(err)
      this.name = '加载失败'
      this.$toast({message: '网络错误', duration: 1500})
      this.$indicator.close();
    })
  },
}
</script>
<style lang="scss" scoped>
#AMT{
  background: #ccdfff url('../../../assets/images/report/AMT_report0.png') no-repeat;
  background-size: 100% auto;
  padding-bottom: 40px;
  color: #000;
  font-size: 26px;
}
.info{
  color: #fff;
  padding: 60px 30px 30px;
  img{
    width: 78px;
    height: 78px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 20px;
  }
  .name{
    text-align: left;
    line-height: 1.5;
    font-size: 26px;
  }
}
.starBox{
  margin: 40px 0 60px;
  b{
    font-size: 28px;
    color: #4948ff;
    line-height: 40px;
    display: inline-block;
    height: 40px;
  }
  i{
    display: inline-block;
    width: 36px;
    height: 36px;
    margin: 0 8px -6px;
    background-image: url('../../../assets/images/report/star4.png');
    background-repeat: no-repeat;
    background-size: 100%;
  }
  .star2{background-image: url('../../../assets/images/report/star5.png')}
  .star3{background-image: url('../../../assets/images/report/star6.png')}
}
.main{
  background: rgba(255,255,255,.9);
  margin: 30px;
  border-radius: 20px;
  position: relative;
  z-index: 0; //为让h3底部横线显示出来
  h1{
    font-size: 28px;
    color: #4948ff;
  }
}
.main1{
  padding: 1px 30px 60px;
  .box1{
    border: 1px solid #1a1a1a;
    margin-top: 40px;
    .box2{
      border-bottom: 1px solid #1a1a1a;
      color: #4948ff;
      font-size: 28px;
      padding: 30px 0;
    }
    .box3{
      width: 50%;
      box-sizing: border-box;
      border-right: 1px solid #1a1a1a;
      padding-bottom: 100px;
      p{
        position: relative;
        text-align: justify; 
        margin: 0 40px;
        padding: 24px 0;
        border-bottom: 1px dashed #817e88;
        &::after{
          content: '';
          position: absolute;
          left: -16px;
          top: 36px;
          border-radius: 50%;
          width: 8px;
          height: 8px;
          background: #1a1a1a;
        }
        &:last-child{border: none}
      }
    }
    .box4{
      width: 50%;
      padding-bottom: 40px;
      h2{padding-bottom: 26px;}
      p{
        vertical-align: top;
        position: relative;
        margin: 0 6px;
        padding: 36px 4px 20px;
        background: #cfcbff;
        display: inline-block;
        writing-mode: vertical-lr;
        writing-mode: tb-lr;
        &::after{
          content: '';
          position: absolute;
          left: 18px;
          top: 16px;
          border-radius: 50%;
          width: 8px;
          height: 8px;
          background: #1a1a1a;
        }
        &:nth-child(2){background: #cfe6ff}
        &:nth-child(3){background: #c8eaf5}
        &:nth-child(4){background: #f9ead2}
        &:nth-child(5){background: #f8d5d5}
      }
    }
    .box5{
      border-top: 1px solid #1a1a1a;
      padding-bottom: 20px;
      p{
        text-align: justify;
        margin: 10px 50px 30px;
        position: relative;
        &::after{
          content: '';
          position: absolute;
          left: -16px;
          top: 12px;
          border-radius: 50%;
          width: 8px;
          height: 8px;
          background: #1a1a1a;
        }
      }
    }
    h2{
      color: #4948ff;
      font-size: 26px;
      white-space: nowrap;
      line-height: 2;
      margin-top: 30px;
    }
  }
}
.main2{
  padding: 50px 30px;
  ol{
    position: relative;
    height: 360px;
    margin: 60px 0;
    font-size: 24px;
    li{
      position: absolute;
      width: 180px;
      padding: 18px 0;
      border-radius: 10px;
      z-index: 2;
      &:nth-child(1){left: 0;top: 0;&::after{bottom: -5px}}
      &:nth-child(2){left: 0;bottom: 0;&::after{top: -5px}}
      &:nth-child(3){right: 0;top: 0;&::after{bottom: -5px}}
      &:nth-child(4){right: 0;bottom: 0;&::after{top: -5px}}
      &:nth-child(5){
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
        label{display: block}
        &::before{
          content: '';
          position: absolute;
          width: 8px;
          height: 8px;
          border-radius: 50%;
          background: inherit;
          border: 2px solid #fff;
          left: 0;
          top: 50%;
          transform: translate(-50%,-50%);
        }
        &::after{
          left: 100%;
          top: 50%;
          transform: translate(-50%,-50%);
        }
      }
      &::after{
        content: '';
        position: absolute;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background: inherit;
        border: 2px solid #fff;
        left: 50%;
        transform: translateX(-50%);
      }
    }
    label{
      position: absolute;
      line-height: 40px;
      background: inherit;
      border-radius: 4px;
      padding: 0 14px 0 8px;
      top: 50%;
      transform: translateY(-50%);
      display: none;
      left: -100px;
      clip-path: polygon(0 0, 80% 0, 100% 50%, 80% 100%, 0 100%);
      &:nth-child(2){left: auto;right: -102px;}
    }
    div{
      position: absolute;
      width: calc(100% - 180px);
      border-top: 1px dashed #ccc;
      left: 90px;
      top: 50%;
    }
    i{
      position: absolute;
      height: 80%;
      top: 10%;
      left: 90px;
      border-left: 1px dashed #ccc;
    }
    .i2{left: auto;right: 90px;}
  }
  p{
    text-align: justify;
    font-size: 26px;
    line-height: 1.5;
  }
}
.main3{
  padding: 50px 40px 80px;
  font-size: 26px;
  h3{
    position: relative;
    text-align: left;
    margin: 60px 0 20px;
    font-weight: bold;
    width: fit-content;
    &::before{
      content: '';
      position: absolute;
      left: 4%;
      bottom: 2px;
      width: 92%;
      height: 8px;
      border-radius: 5px;
      background: #d3d4ff;
      z-index: -1;
    }
  }
  p{
    text-align: justify;
    line-height: 1.6;
  }
  ul{
    text-align: left;
    margin-top: 40px;
    li{
      display: inline-block;
      width: 280px;
      font-size: 24px;
      line-height: 2;
      text-align: center;
      border-radius: 8px;
      margin: 10px;
      // &:nth-child(odd){margin-right: 10px}
      // &:nth-child(even){margin-left: 10px}
    }
  }
  .apply{
    display: inline-block;
    width: 45%;
    font-size: 24px;
    img{
      display: block;
      margin: 20px auto 0;
      width: 100px;
      height: 100px;
    }
    h5{
      color: #50589b;
      line-height: 2;
      margin-bottom: 10px;
    }
  }
  .apply1{float: left;}
  .apply2{float: right;}
  &::after{
    content: '';
    display: block;
    clear: both;
  }
}

.linear1{background: #18d3ae linear-gradient(to right, #55edcf, #18d3ae);}
.linear3{background: #74b9ff linear-gradient(to right, #b7d2ff, #74b9ff);}
.linear4{background: #fdb269 linear-gradient(to right, #ffcfa1, #fdb269);}
.linear5{background: #ff7474 linear-gradient(to right, #ffabab, #ff7474);}
.linear6{background: #ffd178 linear-gradient(to right, #ffe7ba, #ffd178);}
</style>