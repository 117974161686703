<template>
  <div class="barSvg">
    <svg :width="width">
      <g class="bg"></g>
      <g class="data"></g>
    </svg>

    <div class="tip" v-if="showIndex!=null">
      <h6><span>{{data[showIndex].name}}</span>{{data[showIndex].dimension_detail}}</h6>
      <div class="close" @click="showIndex=null">关 闭</div>
    </div>
    <p v-if="animate && onclick">点击维度名称可查看说明</p>

    <ul class="colLegend">
      <li><img src="../../../../assets/images/icons/tvr_legend1.png"><span>数据结果</span></li>
      <li v-if="data[0]&&data[0].sub_score"><img src="../../../../assets/images/icons/tvr_legend2.png"><span>{{label}}</span></li>
    </ul>
  </div>
</template>

<script>
import * as d3 from 'd3'
export default {
  props: {
    data: {
      // default: () => {
      //   return [
      //     {name: "凝聚力",score:30},
      //     {name: "承担力",score:38},
      //     {name: "协作力",score:42},
      //     {name: "适应力",score:50},
      //     {name: "自驱力",score:30},
      //     {name: "目标感",score:48},
      //     {name: "价值感",score:40},
      //   ]
      // }
    },
    color: {
      default: () => {
        return ['#42b2fc','#7886f0']
      }
    },
    width: {    //宽高度
      type: Number,
    },
    animate:{
      type: Boolean,
      default: false,
    },
    onclick:{
      type: Boolean,
      default: false,
    },
    label:{
      default: '管理层感知水平',
    },
  },
  data(){
    return{
      range: [0, 40, 50, 60, 80, 100],//预警<40，40≤中等＜50，50≤良好＜60，60≤优秀＜80，80≤预警＜100
      showIndex: null,
    }
  },
  mounted(){
    if (this.data.length)
      setTimeout(() => this.draw(this.data), 10)
    else
      this.$watch('data', val => this.draw(val))
  },
  methods:{
    draw(d){
      let svg = d3.select(this.$el).select('svg'),
          left =  this.width * .16, //与move动画同时改（transform-origin）
          top = this.width * .098,  //顶部留空（且控制每条的高度）
          fontSize = this.width * 0.036,
          vW = this.width - left,
          vH = top * d.length,
          level = ['警示','中等','良好','优秀','警示'],
          eachW = vW / level.length,//每格的宽度
          bg = svg.select('.bg');
      let data = svg.select('.data'), 
          barH = top * .64,         //每条的高度
          gap = top - barH,         //上下缝隙留空
          tgap = gap / 2,           //上缝隙
          points = '';              //折线坐标
      svg.attr('height', vH + top)
      svg.selectAll("g > *").remove()//清空画布
      //背景
      for (let i = 0; i < level.length; i++) {
        let startx = left + eachW*i
        bg.append('rect')
          .attr('fill', () => {
            if (i==0 || i==4) return '#ffeaea'
            else return '#f7f7f7'
          })
          .attr('stroke', 'none')
          .attr('x', startx)
          .attr('y', top - gap)
          .attr('width', eachW)
          .attr('height', vH + gap)
        bg.append('text')
          .attr('font-size', fontSize)
          .attr('fill', () => {
            if (i==0 || i==4) return '#e07556'
            else return '#999'
          })
          .attr('x', startx + eachW/2)
          .attr('y', top/3)
          .text(level[i])
        if (i) {
          bg.append('polyline')
            .attr('fill', 'none')
            .attr('stroke', '#b4b4b4')
            .attr("stroke-dasharray", "3,3")
            .attr('stroke-width', 1)
            .attr('points', `${startx},${top - gap} ${startx},${this.width}`)
        }
      }
      //数据
      for (let i = 0; i < d.length; i++) {
        let color = this.color[i%this.color.length],
            x =  this.setWidth(d[i].score) * vW,
            y = top * (1.5 + i) - tgap
        bg.append('text')
          .attr('font-size', fontSize)
          .attr('fill', color)
          .attr('x', left/2)
          .attr('y', y+1)
          .text(d[i].name)
          .on("click", () => {
            if (this.onclick) this.showIndex = i
          })

        data.append('rect')
            .attr('fill', color)
            .attr('stroke', 'none')
            .attr('x', left)
            .attr('y', y - barH/2)
            .attr('width', x)
            .attr('height', barH)
            .attr('rx', 2)
        data.append('text')
            .attr('font-size', fontSize)
            .attr('fill', '#fff')
            .attr('x', left + x - fontSize)
            .attr('y', y+1)
            .text(Math.round(d[i].score))
        if (d[i].sub_score) {
          let cx = this.setWidth(d[i].sub_score) * vW + left
          points += `${cx},${y} `
          data.append('circle')
              .attr('cx', cx)
              .attr('cy', y)
              .attr('r', fontSize*.2)
              .attr('fill', '#ff7200');
        }
      }
      if (points) { //如果有折线
        data.append('polyline')
            .attr('fill', 'none')
            .attr('stroke', '#ff7200')
            .attr('stroke-width', 1)
            .attr('points', points)
      }

      //添加动画
      if (this.animate) data.classed('move', true)
    },
    setWidth(s) {
      let result = 0
      if (s < this.range[1])  //预警<40
        result = (s - this.range[0]) / (this.range[1] - this.range[0]) * .2 
      else if (s < this.range[2])   //40≤中等＜50
        result = (s - this.range[1]) / (this.range[2] - this.range[1]) * .2 + .2
      else if (s < this.range[3])   //50≤良好＜60
        result = (s - this.range[2]) / (this.range[3] - this.range[2]) * .2  + .4
      else if (s < this.range[4])   //60≤优秀＜80
        result = (s - this.range[3]) / (this.range[4] - this.range[3]) * .2  + .6
      else    //80≤预警＜100
        result = (s - this.range[4]) / (this.range[5] - this.range[4]) * .2  + .8
      return result
    },
  },
}
</script>
<style scope lang="scss">
  @keyframes dimFade {
    0%{opacity: 0;transform: translate(-50%,-44%)}
    95%{opacity: 1;transform: translate(-50%,-50%)}
  }
  .barSvg{
    position: relative;
    margin: 40px 0 70px 0;
    svg{
      display: block;
      margin: 10px auto 0;
      text{
        text-anchor: middle;
        dominant-baseline: middle;
      }
      .move{
        transform-origin: 16% center;
        animation: moveLine .4s forwards;
      }
      @keyframes moveLine {
        0%{transform: scaleX(0)}
        100%{transform: scaleX(1)}
      }
    }
    .tip{
      position: absolute;
      width: 540px;padding: 30px 40px 10px;
      background: #fff;border-radius: 10px;
      left: 50%;top: 50%;transform: translate(-50%,-50%);
      box-shadow: 0 0 6px 2px rgba(200,200,200,.3);
      animation: dimFade .3s;
      z-index: 3;
      h5{
        font-size: 28px;
        color: #333;
        line-height: 2;
      }
      h6{
        text-align: justify;
        font-size: 24px;
        span{color: #42b2fc;}
      }
      .close{
        width: 100px;
        color: #42b2fc;
        height:40px;
        display: flex;
        align-items: center; 
        justify-content: center;
        margin: 20px auto 10px;
        border-radius: 6px;
      }
    }
    p{
      color: #999;
      font-size: 22px;
      margin-top: 10px;
    }
    .colLegend{
      text-align: center;
      margin-top: 30px;
      li{
        display: inline-block;
        width: 40%;
        img{
          width: 50px;
          height: 50px;
          display: inline-block;
          vertical-align: bottom;
          margin-right: 10px;
        }
        span{
          height: 50px;
          line-height: 50px;
          font-size: 24px;
          display: inline-block;
        }
      }
    }
  }
</style>
