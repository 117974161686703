<template>
  <!--报告-->
  <!-- <component :is="body" :version="version" :report_id="report_id"></component> -->
  <group_1_0 v-if="version=='1.0.0'" :version="version" :report_id="report_id"></group_1_0>
  <group_2_1 v-else-if="version=='2.0.0' || version=='2.1.0'" :version="version" :report_id="report_id"></group_2_1>
  <group_2_2 v-else-if="version=='2.2.0' || version=='2.3.0'" :version="version" :report_id="report_id"></group_2_2>
  <group_2_4 v-else-if="version=='2.4.0'" :version="version" :report_id="report_id"/>
  <group_2_5 v-else :version="version" :report_id="report_id"/>
</template>

<script>
import group_1_0 from './group1.0.vue'
import group_2_1 from './group2.1.vue'
import group_2_2 from './group2.2.vue'
import group_2_4 from './group2.4.vue'
import group_2_5 from './group2.5.vue'
export default {
  components:{group_1_0, group_2_1, group_2_2, group_2_4, group_2_5},
  data(){
    return{
      report_id: this.$route.params.report_id || '',
      version: this.$route.query.ver || '',
    }
  },
  mounted(){
    if (!this.version)
      this.$http.get(this.spaceUrl+`/Assess/getTeamReportVersion?team_report_id=${this.report_id}`).then(({data}) => {
        if (data.code == 0) {
          this.version = data.data.version
        } else {
          this.$toast({message:'无法加载',duration: 2000})
          console.error(data.msg)
        }
      })
  },
}
</script>
