<!-- 传入数据说明（*代表必要元素）
  data*：分数
  width*：圆的宽度
  animate：是否启动动画
-->
<template>
  <div class="radar_cicle">
    <svg></svg>
    <p v-if="animate">点击维度名称可查看说明</p>
    <div class="tip" v-if="showIndex!=null">
      <h6>{{data[showIndex].dimension_detail}}</h6>
      <div class="close" @click="showIndex=null">关 闭</div>
    </div>
  </div>
</template>

<script>
import * as d3 from 'd3'
export default {
  props: {
    data: {
      type: Array,
    },
    width: {    //宽高度
      type: Number,
    },
    animate:{
      type: Boolean,
      default: false,
    },
    lineColor:{
      type: String,
      default: '#e5b552',
    },
  },
  data(){
    return{
      center: 0,
      radius: 0,
      length:0,
      color:['#dd9317','#1352c4','#c11e26','#ce0b67','#99178d','#316a88','#1e9381','#34a560','#7aa022','#963937'],
      showIndex: null,
    }
  },
  mounted(){
    if(this.data.length>0){//本地测试(ctrl+s保存时浏览器自动执行)
      this.draw(this.data)
    }else{ //实际传入数据:
      this.$watch('data', (e) => {
        this.draw(e)
      })
    }
  },
  methods:{
    compute(i, score, add=0){
      if (score < 100 ) {
        var per = (score-this.minScale) / this.scale * this.radius + add
      } else {
        var per = score / 100 * this.radius
      }
      let angel = 2 * Math.PI / this.length * i
      return [this.center + Math.sin(angel) * per, this.center - Math.cos(angel) * per]
    },
    draw(e){
      let svg = d3.select(this.$el).select('svg').attr('width',this.width).attr('height',this.width),
          radius = this.radius = this.width * 0.5 * 0.5,//最大半径
          each = radius / 4,                            //每格大小
          center = this.center = this.width / 2,        //中点
          len = this.length = e.length,                 //数据长度
          polygon = '',                                 //多边形points
          max = 0,                                      //最大值
          min = 100,                                    //最小值
          point = [],                                   //各分数坐标
          bg = svg.append('g').classed('bg',true),
          data = svg.append('g'),
          text = svg.append('g').classed('label',true)
      // 背景网格
      for(let i = 0; i < 4; i ++){
        bg.append('circle')
          .attr('fill', 'none')
          .attr('stroke', '#ccc')
          .attr('stroke-dasharray', '3px 3px')
          .attr('stroke-width', 1)
          .attr('cx', center)
          .attr('cy', center)
          .attr('r', each * (i+1))
      }
      for(let i = 0; i < len; i++){
        // 最大端点
        let p1 = this.compute(i, 100)
        bg.append('line')
          .attr('x1',p1[0])
          .attr('y1',p1[1])
          .attr('x2',center)
          .attr('y2',center)
          .attr('stroke', '#ccc')
          .attr('stroke-dasharray', '3px 3px')
          .attr('stroke-width', 1)
        // 维度名称
        let textP = this.compute(i, 140)
        text.append('text')
            .attr('fill', this.color[i])
            .attr('font-size', radius*0.13)
            .attr('x', textP[0])
            .attr('y', ()=>{
              if(i == 0)
                return textP[1] + radius * 0.16
              else if(i%(len/2) == 0)
                return textP[1] - radius * 0.16
              else
                return textP[1]
            })
            .text(e[i].name)
            .on("click",()=>{
              this.showIndex = i
            })
        if(e[i].score>max)
          max = e[i].score
        if(e[i].score<min)
          min = e[i].score
      }
      this.minScale = Math.floor(min/10-1) * 10 //最低值
      this.scale = Math.ceil(max/10) * 10 - this.minScale + 5 //最高值-最低值
      // this.minScale = min-5              //最低值
      // this.scale = max+5 - this.minScale //最高值-最低值
      // console.log('最小刻度：',this.minScale,'最大刻度：',max+10)
      
      // 循环数据组 
      for(let i = 0; i < len; i ++){
        let p = this.compute(i, e[i].score)
        point.push(p)
        polygon +=  p[0] + ',' + p[1] + ' ';
        
        let scoreP = this.compute(i, e[i].score, 11)
        data.append('text')
            .classed('score'+i, true)
            .attr('fill', this.lineColor)
            .attr('font-weight', 'bold')
            .attr('font-size', radius * 0.14)
            .attr('x', scoreP[0])
            .attr('y', scoreP[1])
            .text(e[i].score)
      }

      // 实心多边形
      data.append('polygon')
          .attr('fill', this.lineColor)
          .attr('stroke', this.lineColor)
          .attr('stroke-width', 2)
          .attr('points', polygon)

      // 最大最小点
      if(max!=min){
        for(let i = 0; i < len; i ++){
          if(e[i].score==max){
            data.append('circle')//最大绿点
                .attr('cx', point[i][0])
                .attr('cy', point[i][1])
                .attr('r', 3)
                .attr('stroke', 'none')
                .attr('fill', '#e86a50');
            data.select('.score' + i).attr('fill', '#e86a50')//分值红色
          }else if(e[i].score==min){
            data.append('circle')//最小红色
                .attr('cx', point[i][0])
                .attr('cy', point[i][1])
                .attr('r', 3)
                .attr('stroke', 'none')
                .attr('fill', '#83b166');
            data.select('.score'+i).attr('fill', '#83b166')//分值绿色
          }
        }
      }
      // 动画
      if(this.animate){
        data.classed('move',true)
      }
    },
  },
}
</script>
<style scope lang="scss">
.radar_cicle{
  position: relative;
  display: block;
  margin: 0 auto;
  p{font-size: 24px;color: #666;margin: -100px 0 120px;}
  .tip{
    position: absolute;
    width: 540px;padding: 30px 40px 10px;
    background: #fff;border-radius: 10px;
    left: 50%;top: 50%;transform: translate(-50%,-50%);
    box-shadow: 0 0 6px 2px rgba(200,200,200,.3);
    animation: dimFade .4s;
    h5{
      font-size: 28px;
      color: #333;
      line-height: 2;
    }
    h6{
      text-align: justify;
      font-size: 24px;
    }
    .close{
      width: 100px;
      color: #e29b0b;
      height:40px;
      display: flex;
      align-items: center; 
      justify-content: center;
      margin: 20px auto 10px;
      border-radius: 6px;
    }
  }
  polygon{
    fill-opacity: .25;
  }
  text{
    text-anchor: middle;
    dominant-baseline: middle;
  }
  .move{
    transform-origin: center center;
    animation: scale .6s;
  }
  @keyframes scale {
    0%{transform: scale(0,0);}
    100%{transform: scale(1,1);}
  }
  @keyframes dimFade {
    0%{opacity: 0;transform: translate(-50%,-44%)}
    100%{opacity: 1;transform: translate(-50%,-50%)}
  }
}
</style>
