
<template>
  <!-- 修改雷达图 -->
  <div class="report" id="report" :class="{long:long}">
    <div class="top">
      <header>
        <div class="label">
          <b>{{org_name}}</b> 报告分析<br>
          {{$formatTime(endTime,'y-m-d h:m')}}<span v-if="version">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;版本号 v{{version}}</span>
        </div>
        <div v-if="!long && !hide" class="btn" @click="save">保存报告</div> 
      </header>
      <nav v-if="!long">
        <span :class="{current:tabId==4}" @click="tab(4)">报告说明</span>
        <span :class="{current:tabId==0}" @click="tab(0)">团队概况</span>
        <span :class="{current:tabId==1}" @click="tab(1)">团队活力</span>
        <span :class="{current:tabId==2}" @click="tab(2)">工作价值<br>倾向</span>
        <span v-if="leaderShow" :class="{current:tabId==3}" @click="tab(3)">领导能量</span>
      </nav>
    </div>
    <ul class="content">
      <!-- 报告说明 -->
      <li class="explain" v-show="tabId==4 || long">
        <h3 style="margin:0px 0 20px">报告说明</h3>
        <div><b>感谢您</b>的团队完成了TVR团队活力识别器的评估，这是您的团队通过<b>数据赋能，迈向高绩效管理</b>的重要一步。</div>
        <br>
        <div>TVR团队活力识别器的<b>作用</b>在于快速识别团队的<b>工作价值倾向、活力、领导能量、预警</b>等信息，并基于真实客观的数据，结合日常观察和分析，为团队管理改善提供科学、高效的依据。</div>
        <br>
        <div>当您<b>阅读</b>报告时，分数高或低并不代表好、坏、对、错，它只是团队当下真实的面貌，是员工体验层面的群体化学反应，这可能是您平时无法观察到或忽略的团队现状。</div>
        <br>
        <div>当您<b>应用</b>报告时，请结合团队文化、战略、机制、人才现状进行分析，并建议与核心管理层共同探讨和分析数据背后的原因，制定改善目标、行动方案，达成共识，采取一致行动，提高报告的应用价值。</div>
        <br>
        <div v-if="leaderShow">TVR团队活力<b>报告</b>由六部分组成：1.团队基本信息、2.团队画像分析、3.团队活力分析、4.工作价值倾向分析、5.领导能量分析，6.管理认知，帮助您带领团队更好的发展。</div>
        <div v-else>TVR团队活力<b>报告</b>由五部分组成：1.团队基本信息、2.团队画像分析、3.团队活力分析、4.工作价值倾向分析、5.管理认知，帮助您带领团队更好的发展。</div>
      </li>
      <!-- 团队概况 -->
      <li v-show="tabId==0 || long">
        <h3>团队基本信息</h3>
        <div class="text">
          <b>团队基本信息：</b>包含参评人员的性别比例、层级比例、学历分布、年龄分布的信息。
        </div>
        <div class="text">
          <b>应用建议：</b>对照团队人才结构与标准，可根据公司人才标准进行动态管理。
        </div>
        <div class="message" :class="{messageMini:width<330}">
          <div class="above">
            <div class="box">
              <h4>性别</h4>
              <div class="line" v-for="(item,index) in basic_info.sex_info" :key="index">
                <h5>{{item.name}}</h5>
                <fan :data="item.rate" :index="index" :animate="!long"></fan>
                <h6>{{item.number}}人,{{item.rate}}%</h6>
              </div>
              <div class="number">总人数：{{totalNum}}人</div>
            </div>
            <div class="box">
              <h4>层级</h4>
              <div class="line" v-for="(item,index) in basic_info.tier_info" :key="index">
                <h5>{{item.name}}</h5>
                <fan :data="item.rate" :index="index" :animate="!long"></fan>
                <h6>{{item.number}}人,{{item.rate}}%</h6>
              </div>
            </div>
          </div>
          <div class="under">
            <div class="box">
              <h4>学历</h4>
              <div class="line" v-for="(item,index) in basic_info.educational_info" :key="index">
                <h5>{{item.name}}</h5>
                <fan :data="item.rate" :index="index" :animate="!long"></fan>
                <h6>{{item.number}}人,{{item.rate}}%</h6>
              </div>
            </div>
            <div class="box">
              <h4>年龄</h4>
              <div class="line" v-for="(item,index) in basic_info.era_info" :key="index">
                <h5>{{item.name}}</h5>
                <fan :data="item.rate" :index="index" :animate="!long"></fan>
                <h6>{{item.number}}人,{{item.rate}}%</h6>
              </div>
              <div class="number">平均年龄：{{basic_info.avg_age}}岁</div>
            </div>
          </div>
        </div>

        <div class="padding"></div>
        <h3>团队画像</h3>
        <div class="text">
          <b>团队画像：</b>让您的团队情况一目了然。画像中心是团队成员对工作的需求倾向和行为偏好；【原动力】是驱使团队采取行动的内在力量；【职业素质】是团队成员支撑工作行为的能力基础；【领导能量】是管理层带领团队实现目标的能量；【团队活力】是团队发展和创新的原动力来源。
        </div>
        <div class="text">
          <b>应用建议：</b>从全局视角，快速识别团队成员的需求、动力、能力，从组织思维进行系统改善。
        </div>
        <target 
          :activity="team_activity.TVI.score" 
          :leadership="team_leadership.leadership.score" 
          :motivity="team_motivity.team_motivity.score" 
          :quality="team_quality.team_quality.score" 
          :dimension="team_workvalue.top_dimension"
        ></target>
      </li>
      <!-- 团队活力 -->
      <li v-show="tabId==1 || long">
        <h3>团队活力分析</h3>
        <div class="text">
          <b>团队活力分析：</b>从7个方面探索团队活力激发程度，综合反映了团队文化、管理机制、管理层的领导风格和能力、以及团队成员的心态和能力水平。团队活力因子数据分布在五个区间，不同区间反映不同的状态，“警示区”是您需要特别留意的；“中等”、“良好”、“优秀”是需要定期评估、跟进和提升的。
        </div>
        <div class="text">
          <b>应用建议：</b>关注7个活力因子的分布区间，探讨它们之间的相互关联关系，及早防范团队深层次的风险。
        </div>
        <barRow :data="team_activity.dimension" :click="true" ref="barRow1"></barRow>

        <h3>提示</h3>
        <div class="text">
          <b>提示：</b>从单个活力因子和团队整体两个层面进行提示，避免团队发展可能面临的阻碍。
        </div>
        <div class="word" v-if="team_activity.low_dimension.length || team_activity.top_dimension.length">
          <div>活力因子</div>
          <div class="risk risk1" v-for="(item,i) in team_activity.low_dimension" :key="i">
            <b>{{item.name}}</b>：{{item.content}}
          </div>
          <div class="risk risk1" v-for="(item,i) in team_activity.top_dimension" :key="i">
            <b>{{item.name}}</b>：{{item.content}}
          </div>
        </div>

        <div class="word" v-if="team_risk.length">
          <div>团队预警</div>
          <div 
            class="risk risk2" 
            :class="{risk3: item.risk_level=='danger'}" 
            v-for="(item,i) in team_risk" 
            :key="i"
          >
            <b>{{item.risk_name}}</b>：
            {{item.risk_level=='danger'?'预警已经形成':'预警正在形成'}}<br>
            可能影响：{{item.risk_content}}
          </div>
        </div>
      </li>
      <!-- 工作价值倾向 -->
      <li class="values" v-show="tabId==2 || long">
        <h3>工作价值倾向分析</h3>
        <div class="text">
          <b>工作价值倾向分析：</b>深入了解团队成员对工作的内在需求和期待，通过团队文化、激励机制、优质培训、发展平台等管理方式有效激励团队成员，也可以针对个体成员进行个性化的激励和辅导，持续赋能人才和组织发展。
        </div>
        <div class="text">
          <b>应用建议：</b>根据报告中的激励建议，对照团队激励机制和方法是否能够满足员工的需求，尽快调整团队文化和激励机制，并对团队管理者进行关于沟通和激励的专业训练。
        </div>
        <div class="rank">
          <div class="box" :class="'box'+(i+1)" v-for="(item,i) in team_workvalue.top_dimension" :key="i">{{item.name}}<span>NO.{{i+1}}</span></div>
        </div>

        <h1>激励方向：</h1>
        <p v-for="(e,i) in team_workvalue.top_dimension" :key="i">{{e.content}}</p>
      </li>
      <!-- 领导能量 -->
      <li v-show="tabId==3 || long" v-if="leaderShow">
        <h3>领导能量分析</h3>
        <div class="text">
          <b>领导能量分析：</b>从6个领导行为分析管理者的角色认知、领导力认知水平，了解管理者在当下的领导行为是否匹配组织的需要，领导行为数据将分布在五个区间，不同区间反映不同的状态，“警示区”是您需要特别留意的；“中等”、“良好”、“优秀”是需要定期评估、跟进和提升的。
        </div>
        <div class="text">
          <b>应用建议：</b>结合团队管理者的现状和能力水平，按6个领导行为的数据，制定合宜、针对性的领导力发展计划。
        </div>
        <barRow :data="team_leadership.dimension" :color-type="1"></barRow>
        
        <h3>提示</h3>
        <div class="text">
          <b>提示：</b>从关键领导行为进行提示，及时改善，避免可能阻碍领导能量发挥的行为。
        </div>
        <div class="word" v-if="team_leadership.low_dimension.length || team_leadership.top_dimension.length">
          <div></div>
          <div class="risk risk1" v-for="(item,i) in team_leadership.low_dimension" :key="i">
            <b>{{item.name}}</b>：{{item.content}}
          </div>
          <div class="risk risk1" v-for="(item,i) in team_leadership.top_dimension" :key="i">
            <b>{{item.name}}</b>：{{item.content}}
          </div>
        </div>
        
        <template v-if="team_leadership.manager_part && team_leadership.manager_part.length">
          <div class="padding"></div>
          <h3>管理认知</h3>
          <div class="text" style="margin-left:12px">
            <span style="font-weight:bold">当前管理团队的管理认知如下：</span>
          </div>
          <div class="cognition">
            <div>
              <div class="cricle">第一顺位<span>{{team_leadership.manager_part[0]}}</span></div>
            </div>
            <div>
              <div class="cricle">第二顺位<span>{{team_leadership.manager_part[1]}}</span></div>
            </div>
          </div>
          <h1 class="orange">1、关于管理：</h1>
          <p v-for="(e,i) in cognitionText.text1" :key="i">{{e}}</p>
          <h1 class="orange">2、关于管理者：</h1>
          <p v-for="(e,i) in cognitionText.text2" :key="i">{{e}}</p>
          <h1 class="orange">3、关于不同层级的管理：</h1>
          <p v-for="(e,i) in cognitionText.text3" :key="i">{{e}}</p>
        </template>
      </li>
    </ul>
    <div class="img-box" v-if="reportImgShow">
      <p class="text">长按保存图片</p>
      <div class="inner-box"><img :src="reportImg"></div>
      <img @click="closeImg" class="close" src="../../../assets/images/icons/close.png"/>
    </div>
    <div class="mask" v-if="!success"></div>
  </div>
</template>
<script>
import fan from './chart/fanChart'
import target from './chart/targetChart'
import barRow from './chart/barRowChart'
// import waveBall from './chart/waveBallChart'
// import lineRectChart from './chart/lineRectChart'
export default {
  components:{barRow,target,fan},
  props:{
    report_id: {default: null},
    version: {default: ''}
  },
  data(){
    return{
      width: 0,
      tabId: 0,
      success: false,
      // report_id: this.$route.params.report_id || '',
      hide: this.$route.query.hide || false,                //
      hy_session: this.$route.query.hy_session || '',
      long: this.$route.query.long===undefined?false:true,  //是否报告长图
      org_name: '正在加载',                                  //企业名
      endTime: 0,                                           //报告生成成时间
      totalNum: 0,
      reportImg: '',
      reportImgShow: false,
      timer: '',
      // 团队基本信息：
        basic_info:{
          avg_age: 0,
          educational_info: [
            {name: "高中及以下",number:0,rate:0},
            {name: "大专",number:0,rate:0},
            {name: "本科",number:0,rate:0},
            {name: "硕士研究生",number:0,rate:0},
            {name: "博士及博士后",number:0,rate:0},
          ],
          era_info: [
            {name: "25以下",number:0,rate:0},
            {name: "26~35",number:0,rate:0},
            {name: "36~45",number:0,rate:0},
            {name: "46~55",number:0,rate:0},
            {name: "55以上",number:0,rate:0},
          ],
          sex_info: [
            {name: "男性",number:0,rate:0},
            {name: "女性",number:0,rate:0}
          ],
          tier_info: [
            {name: "一线员工",number:0,rate:0},
            {name: "基层管理者",number:0,rate:0},
            {name: "中层管理者",number:0,rate:0},
            {name: "高层管理者",number:0,rate:0},
          ],
        },
      // 区域象限
        quadrant: {k:[],p:[],i:[]},
      // 团队活力度：
        team_activity: {
          consistency: null,
          dimension: [],
          top_dimension: {content:'',name:''},
          low_dimension: {content:'',name:''},
          TVI: {score: 0, level: ''},
        },
        activeScore: 0,
      // 原动力：
        team_motivity: {
          consistency: null,
          dimension: [],
          team_motivity: {score: 0,}
        },
      // 职业素质:
        team_quality: {
          consistency: null,
          dimension: [],
          team_quality: {score: 0}
        },
      // 领导能量：
        team_leadership: {
          dimension: [],
          leadership: {score: 0,level: ''},
          low_dimension: [],
          top_dimension: [],
          manager_part: [],
        },
        leaderShow: true,
        cognitionText: {
          text1: ['管理的核心是：“人”，并让“人”有所成就及成就感。','管理的任务是：让一群人扬长避短，共同创造成果；让“人”具有生产力，团队才能运作，企业才能创造绩效。','管理的过程是：确立目标和标准，赋予应有的责任，使成员具有生产力，进而创造良好绩效。','管理的要务是：创造一种环境，使团队成员既能达成本身的个人目标，又能促成团队的成功；因此，团队文化、管理和激励机制等的建设就显得很重要。'],
          text2: ['管理者的任务：带领团队，整合资源，创造成果；在每个决策与行动之间，调和并兼顾短、中、长期利益，从中取得平衡。','管理者的责任：创造良好的工作环境、工作氛围，激发成员的活力，使之成为优秀员工。','管理者的重点：建立畅通的管理机制，确保下情充分上达，信息反馈及时，团队成员充分参与。'],
          text3: ['高层管理者是“做对的事情”，负责企业的长期发展，明确方向，把不确定的事确定下来。','中层管理者是“把事情做对”，起到承上启下的作用，负责团队的稳定和效率，把确定的事情分解下去并执行到位。','基层管理者是“愉快的做事”，负责把分解下来的任务转化为成果。'],
        },
      // 工作价值倾向：
        team_workvalue:{
          consistency: null,
          dimension: [],
          top_dimension: ['','',''],
          wordText: [ [], [], [], [], ],//高中低维度
        },
      // 团队风险：
        team_risk: [],
    }
  },
  created(){
    this.$indicator.open({text: '加载中...',spinnerType: 'fading-circle'});
    this.$http.get(this.spaceUrl+'/Assess/getTeamReportInfo?team_report_id='+this.report_id+'&is_share='+1).then((e) =>{
      this.$indicator.close();
      let data = e.data.data
      if (e.data.code==0) {
        this.success = true
        //团队基本信息：
          this.org_name = data.team_info.org_name
          this.endTime = data.team_info.report_time
          let total = this.totalNum = data.team_info.total_number
          this.basic_info = data.basic_info
        // 风险
          let arr = []
          for (let i in data.team_risk) {
            if (data.team_risk[i].is_show) arr.push(data.team_risk[i])
          }
          this.team_risk = arr
        // 区域象限
          this.quadrant = data.quadrant
        // 团队活力度：
          let activity = data.team_activity
          activity.consistency = {4: activity.consistency}  //key只为控制折线颜色
          this.team_activity = activity
          this.activeScore = activity.TVI.score

        // 原动力：
          this.team_motivity = data.team_motivity
        // 职业素质：
          this.team_quality = data.team_quality
        // 领导能量：
          if(data.team_leadership && data.team_leadership.leadership)
            this.team_leadership = data.team_leadership
          else
            this.leaderShow = false
        // 工作价值倾向：
          let workvalue = data.team_workvalue, wordText = [[],[],[],[]]
          for(let i in workvalue.consistency){
            if(workvalue.consistency[i].dispersion>0.8){ //低
              wordText[3].push(workvalue.consistency[i].name)
            }else if(workvalue.consistency[i].dispersion>.3){ //中等
              wordText[2].push(workvalue.consistency[i].name)
            }else if(workvalue.consistency[i].dispersion>.15){ //中上
              wordText[1].push(workvalue.consistency[i].name)
            }else //高
              wordText[0].push(workvalue.consistency[i].name)
          }
          workvalue.wordText = wordText
          workvalue.consistency = {5: workvalue.consistency}  //key只为控制折线颜色
          this.team_workvalue = workvalue
      } else {
        this.org_name = '无法加载'
        this.$toast({message:'加载失败',duration: 2000})
      }
    }).catch((err)=>{
      this.org_name = '无法加载'
      this.success = false
      this.$indicator.close();
      console.error(err);
      if (err.message.indexOf('org_name') > -1) {//code=0接口报错
        this.$toast({message:'报告错误，请联系管理员',duration: 5000})
      } else {
        this.$toast({message:'网络错误',duration: 1500})
      }
    })
  },
  mounted(){
    this.width = document.getElementById('report').clientWidth
  },
  methods:{
    tab(e){
      this.$refs.barRow1.showIndex = null
      if (this.tabId == e) return;

      this.activeScore = 0;
      this.tabId = e;
      //第一页需要改变数据达到动画效果
      if (this.tabId == 0) {
        setTimeout(() => {this.activeScore = this.team_activity.TVI.score}, 50)
      } else {
        this.activeScore = 0
      }
    },
    save(){
      // if (this.ajaxUrlPath.indexOf('app')>-1){
        // this.long = true; return;
      // }
      if (this.reportImg)
        this.reportImgShow = true
      else
        this.$http.get(this.spaceUrl+'/Assess/getTeamReportImage?team_report_id='+this.report_id+'&hy_session='+this.hy_session).then((e) =>{
          if (e.data.code == 0) {//已保存了长图
            this.reportImg = e.data.data
            this.reportImgShow = true
          } else {//新生成长图
            var url = location.href.replace(/http:\/\/localhost/,this.mobileUrl) + (location.hash.includes('?')?'&':'?') + 'long&ver=' + this.version
            this.$indicator.open({text: '图片生成中...',spinnerType: 'triple-bounce'});
            this.$http.post(this.ajaxUrlPath+'/CardImage/createshotImg', {
              type: 'vigourReport',
              imageurl: url,
              width: '500',
              height: '1000',
            }).then(e => {
              this.$indicator.close()
              if (e.data.code == 0) {
                this.reportImg = e.data.data.image
                this.reportImgShow = true
              } else {
                this.$toast({message: e.data.msg})
              }
            })
          }
        })
    },
    closeImg(){
      this.reportImgShow = false
    },
  },
}
</script>
<style scoped lang="scss">
.report{
  color:#6f6f6f;
  box-shadow: 0 0 10px rgba(200,200,200,.6);
  padding-bottom: 40px;
  min-height: 100%;
  position: relative;
  &::before{content: '';display: block;height: 194px;}
  .hide{height: 0;overflow: hidden;position: absolute;}
  .top{
    position: absolute;
    width: 100%;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    background: #fff;
    z-index: 20;
    header{
      display: flex;
      align-items: center;
      font-size: 26px;
      color: #aaa;
      padding: 0 40px;
      height: 120px;
      justify-content: space-between;
      line-height: 1.1;
      border-bottom: 1px solid #f0f0f0;
      .label{
        text-align: left;
        padding-right: 20px;
        font-size: 24px;
        line-height: 1.5;
        b{font-size: 26px}
      }
      .btn{
        width: 130px;
        color: #fff;
        border-radius: 6px;
        background: #33a1fb;
        line-height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 24px;
        padding: 0 10px;
        flex-shrink: 0;
        white-space: nowrap;
        margin-left: 10px;
      }
    }
    nav{
      display: flex;
      border-bottom: 1px solid #f0f0f0;
      color: #999;
      height: 80px;
      background: #fff;
      box-sizing: border-box;
      font-size: 26px;
      align-items: center;
      overflow-x: auto;
      overflow-y: hidden;
      &::-webkit-scrollbar{display: none}
      span{
        flex: 1;
        line-height: 1.2;
        height: 100%;
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
      }
      .current{
        font-size: 26px;
        color: #1a1a1a;
        font-weight: bold;
        &::after{
          content: '';
          position: absolute;
          width: 98%;
          height: 4px;
          background: #33a1fb;
          bottom: -2px;
          left: 0;
        }
      }
    }
  }
  .content{
    overflow-x: hidden;
    .title{
      line-height: 80px;
      font-weight: bold;
      font-size: 30px;
      margin-top: 160px;
      color: #272727;
    }
    .TVI{
      font-size: 28px;
      font-weight: bold;
      margin-bottom: 50px;
      color: #424242;
    }
    li{
      margin: 0 40px;
      .orange{
        color: #ee7e2e;
        font-size: 28px;
        text-align: left;
        margin: 40px 0 18px 24px;
      }
      h1{
        font-size: 28px;
        text-align: left;
        font-weight: bold;
        margin: 20px 0;
        color: #5c5c5c;
      }
      h3{
        font-size: 28px;
        margin: 60px 0 40px;
        color: #0072ff;
        font-weight: bold;
      }
      p{
        padding-left: 24px;
        text-align: justify;
        font-size: 26px;
        position: relative;
        color: #666;
        line-height: 1.5;
        &::before{
          content: '';
          position: absolute;
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background: #7a7a7a;
          left: 4px;
          top: 15px;
        }
      }
      .text{
        text-align: justify;
        font-size: 26px;
        color: #6f6f6f;
        line-height: 1.5;
        b{color: #dd331e}
      }
      .padding{
        height: 14px;
        background: #f2f2f2;
        position: relative;
        margin: 80px 0 0;
        &::before{
          content: '';
          position: absolute;
          width: 100px;
          height: 100%;
          background: #f2f2f2;
          right: 100%;
          top: 0;
        }
        &::after{
          content: '';
          position: absolute;
          width: 100px;
          height: 100%;
          background: #f2f2f2;
          left: 100%;
          top: 0;
        }
      }
      .high span{color: #3fb3ff;}
      .high::before{background: #3fb3ff;}
      .low span{color: #e86c52;}
      .low::before{background: #e86c52;}
      .blue{color: #3fb3ff}
      .yellow{color: #ebb748}
      .red{color: #f8887b}
      .green{color: #88b56c}
    }
    .explain{
      padding-top: 60px;
      div{
        text-align: justify;
        font-size: 26px;
        line-height: 1.5;
      }
      .blue{color: #dd331e;}
      b{color: #dd331e;}
      span{color: #dd331e;}
    }
    .message{
      font-size: 24px;
      color: #999;
      border: 1px solid #6ec6fd;
      margin: 40px 0;
      &>div{display: flex;}
      .above{
        border-bottom: 1px dashed #6ec6fd;
        &>div:nth-child(1){
          border-right: 1px dashed #6ec6fd;
          h5{width: 100px}
          .number{margin-left: 50px}
        }
        &>div:nth-child(2){
          h5{width: 144px}
        }
      }
      .under{
        &>div:nth-child(1){
          border-right: 1px dashed #6ec6fd;
          h5{width: 150px}
        }
        &>div:nth-child(2){
          h5{width: 120px}
          .number{margin-left: 42px;}
        }
      }
      .box{
        box-sizing: border-box;
        padding: 10px 0 40px;
        width: 50%;
        position: relative;
        .line{
          display: flex;
          line-height: 24PX;
          height: 24PX;
          margin: 16px 0;
          svg{
            flex-shrink: 0;
            margin: -2px 10px 0 12px;
          }
        }
        h4{
          font-size: 26px;
          width: 160px;
          background: #3fb3ff;
          margin: 20px auto 40px;
          border-radius: 45px;
          color: #fff;
          line-height: 2;
        }
        h5{
          text-align: right;
          white-space: nowrap;
          font-size: 24px;
        }
        h6{
          white-space: nowrap;
          font-size: 24px;
        }
        .number{
          text-align: left;
          font-size: 24px;
          color: #999;
        }
      }
    }
    .messageMini .box .line svg{//小屏的团队基本信息缩小间距
      margin: -2px 0 0;
    }
    .values{
      h1{margin-top: -40px}
    }
    .word{
      padding-left: 30px;
      margin: 40px 0;
      div{
        &:nth-child(1){
          font-weight: bold;
          color: #dd331e;
          line-height: 2;
          font-size: 28px;
        }
        font-size: 26px;
        color: #6f6f6f;
        text-align: justify;
      }
      .risk{
        position: relative;
        line-height: 1.5;
        &::before{position: absolute;content: '';}
        b{color: #545454;}
      }
      .risk1::before{
        background: url('../../../assets/images/report/risk.png') no-repeat;
        background-size: 100%;
        width: 28px;
        height: 28px;
        top: 4px;
        left: -36px;
      }
      .risk2::before{
        background: linear-gradient(to right,#ffcdc7 50%,#dd331e 50%);
        border-radius: 50%;
        width: 22px;
        height: 22px;
        top: 8px;
        left: -32px;
      }
      .risk3::before{background: #dd331e}
    }
    .area{
      margin: 20px 40px 50px;
      & > div{
        display: flex;
        margin: 30px 0;
        & > div{
          flex: 1;
          h5:nth-child(1){border-bottom: none}
        }
        h4{
          min-height: 210px;
          color: #fff;
          padding: 0 20px;
          // display: flex;
          // flex-direction: column;
          // justify-content: center;
          // align-items: center;
          div{
            width: 20px;
            height: 20px;
            margin: 6px auto;
            transform: translateY(76px);
          }
          img{width: 100%;}
          b{
            display: block;
            font-size: 26px;
            transform: translateY(76px);
          }
        }
        h5{
          box-sizing: border-box;
          min-height: 105px;
          border: 1px solid #ccc;
          display: flex;
          align-items: center;
          text-align: justify;
          line-height: 1.3;
          padding:10px 0 10px 24px;
          flex-wrap: wrap;
          border-left: none;
          span{
            font-size: 24px;
            margin-right: 20px;
          }
        }
        
        &:nth-child(1){
          h4{background: #e96842}
          h5{border-color: #e96842;color: #e96842}
        }
        &:nth-child(2){
          h4{background: #42b2fc}
          h5{border-color: #42b2fc;color: #42b2fc}
        }
        &:nth-child(3){
          h4{background: #7886f0}
          h5{border-color: #7886f0;color: #7886f0}
        }
        &:nth-child(4){
          h4{background: #28c8b7}
          h5{border-color: #28c8b7;color: #28c8b7}
        }
      }
    }
    .cognition{
      width: 624px;
      margin: 70px auto;
      & > div{
        width: 50%;
        border-top: 12px solid #ccc;
        border-bottom: 12px solid #ccc;
        height: 180px;
        position: relative;
        &::before{
          content: '';
          position: absolute;
          border-top: 18px solid transparent;
          border-bottom: 18px solid transparent;
          border-right: 24px solid #ccc;
        }
        &::after{
          content: '';
          position: absolute;
          border-top: 18px solid transparent;
          border-bottom: 18px solid transparent;
          border-left: 24px solid #ccc;
        }
        &:nth-child(1){
          border-color: #3db5ff;
          border-left: 12px solid #3db5ff;
          border-top-left-radius: 90px;
          border-bottom-left-radius: 90px;
          &::before{
            border-right: 24px solid #3db5ff;
            top: -24px;
            right: 0;
          }
          &::after{
            border-left: 24px solid #3db5ff;
            bottom: -24px;
            right: 100px;
          }
          .cricle{
            background: #3db5ff;
            span{
              left: 108%;
              color: #3db5ff;
            }
          }
        }
        &:nth-child(2){
          border-color: #ee7e2e;
          border-right: 12px solid #ee7e2e;
          border-top-right-radius: 90px;
          border-bottom-right-radius: 90px;
          margin: -12px 0 0 50%;
          &::before{
            border-right: 24px solid #ee7e2e;
            bottom: -24px;
            left: -24px;
          }
          &::after{
            border-left: 24px solid #ee7e2e;
            top: -24px;
            left: 100px;
          }
          .cricle{
            background: #ee7e2e;
            float: right;
            span{
              right: 108%;
              color: #ee7e2e;
            }
          }
        }
        .cricle{
          width: 136px;
          height: 136px;
          line-height: 136px;
          border-radius: 50%;
          margin: 10px;
          background: #ccc;
          position: relative;
          color: #fff;
          font-size: 25px;
          span{
            line-height: 32px;
            position: absolute;
            width: 430px;
            top: 50%;
            transform: translateY(-50%);
            text-align: left;
          }
        }
      }
    }
  }
  .img-box{
      width: 100%;
      height: 100%;
      position: fixed;
      background: rgba(0,0,0,0.8);
      top: 0;
      left: 0;
      z-index: 100;
      font-size:30px;
      .text{
        line-height: 100px;
        color:#fff;font-size:26px;
      }
      .inner-box{
        flex: 1;
        height: 80%;
        margin:0 30px 20px;
        overflow-y: auto;
        img{width: 100%;height: auto;display: block;}
      }
      .close{
        display: block;
        width: 70px;
        height: 70px;
        margin:0 auto;
      }
  }
  .mask{
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 100;
  }
  .rank{
    position: relative;
    width: 388px;
    height: 380px;
    margin: 50px auto 60px;
    color: #fff;
    font-size: 26px;
    .box{
      position: absolute;
      box-sizing: border-box;
      border-radius: 50%;
      border: 6px solid #f2f2f2;
      line-height: 1.6;
      font-weight: bold;
      top: 50%;
      span{display: block;font-weight: normal}
    }
    .box1{
      width: 240px;
      height: 240px;
      padding-top: 80px;
      margin-top: -120px;
      left: -120px;
      background: #3fb3ff;
      animation: box .6s forwards;
    }
    .box2{
      width: 200px;
      height: 200px;
      margin-top: -100px;
      padding-top: 60px;
      left: 136px;
      background: rgba(78,200,183,0.9);
      animation: box 1s forwards;
    }
    .box3{
      width: 160px;
      height: 160px;
      margin-top: -80px;
      padding-top: 40px;
      left: 350px;
      background: rgba(237,125,49,0.9);
      animation: box 1.4s forwards;
    }
    @keyframes box {
      0%{opacity: 0;transform: translateY(40px)}
      100%{opacity: 1;transform: translateY(0)}
    }
  }
}
.long::before{height: 100px;}
.long .content li h3{margin-top: 100px;}
</style>