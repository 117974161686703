<template>
  <!--报告-->
  <personal_1_0 v-if="version=='1.0.0'" :version="version" :hy_session="hy_session" :exam_id="exam_id"></personal_1_0>
</template>

<script>
import personal_1_0 from './personal1.0.vue'
export default {
  components:{personal_1_0},
  data(){
    return{
      exam_id: this.$route.params.exam_id || '',
      hy_session: this.$route.params.hy_session || '',
      version: this.$route.query.ver || '',
    }
  },
  mounted(){
    // if (!this.version)
      this.$http.get(this.spaceUrl+`/Lsn/getReportVersion?exam_id=${this.exam_id}`).then(({data}) => {
        if (data.code == 0) {
          this.version = data.data.version
        } else {
          this.$toast({message:'无法加载',duration: 2000})
          console.error(data.msg)
        }
      })
  },
}
</script>
