import { render, staticRenderFns } from "./tier2.0.vue?vue&type=template&id=56ff3478&scoped=true"
import script from "./tier2.0.vue?vue&type=script&lang=js"
export * from "./tier2.0.vue?vue&type=script&lang=js"
import style0 from "./tier2.0.vue?vue&type=style&index=0&id=56ff3478&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "56ff3478",
  null
  
)

export default component.exports