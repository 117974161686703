import { render, staticRenderFns } from "./my.vue?vue&type=template&id=50444314&scoped=true"
import script from "./my.vue?vue&type=script&lang=js"
export * from "./my.vue?vue&type=script&lang=js"
import style0 from "./my.vue?vue&type=style&index=0&id=50444314&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "50444314",
  null
  
)

export default component.exports