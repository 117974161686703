<template>
  <div class="TDA_shop">
    <div class="top flex-b-c">
      <template v-if="userInfo">
        <div class="flex--c">当前账号：<img class="head" :src="userInfo.avatar||require('../../../../assets/images/icons/user_icon.png')"> {{userInfo.mobile||userInfo.nickname}}</div>
        <div class="switch flex--c" @click="toLogin('change')">切换账号<img src="../../../../../static/img/TDA6.png"></div>
      </template>
      <div v-else @click="toLogin('')">未登录</div>
    </div>
    <div class="window">
      <!-- 模块 -->
      <section class="module" :class="{trans: tab==0}">
        <div class="banner" @click="$router.push({path:'/TDA/intro'})">
          <img src="../../../../../static/img/TDA11.jpg">
        </div>
        <ul class="pro flex">
          <li v-for="item in proList" :key="item.product_id" @click="toDetail(item.product_id)">
            <div class="pic flex-c-c" :class="'pic'+item.module_id"><img :src="require('../../../../../static/img/tda_'+item.module_id+'.png')"></div>
            <h1>{{item.product_name}}</h1>
            <h2><span>¥</span> 9.9 <s>￥19.9</s></h2>
          </li>
        </ul>
      </section>
      <!-- 购物车 -->
      <section class="container" :class="{trans: tab==1}">
        <car :topshow="0"/>
      </section>
    </div>
    <div class="bottom flex-a-c">
      <div :class="{active:tab==0}" @click="changeTab(0)">
        <img src="../../../../../static/img/TDA8.png" v-if="tab==0">
        <img src="../../../../../static/img/TDA7.png" v-else>
        <p>模块</p>
      </div>
      <div :class="{active:tab==1}" @click="changeTab(1)">
        <img src="../../../../../static/img/TDA10.png" v-if="tab==1">
        <img src="../../../../../static/img/TDA9.png" v-else>
        <p>购物车</p>
      </div>
    </div>
  </div>
</template>
<script>
import car from './car'
export default {
  components: {car},
  data() {
    return {
      tab: sessionStorage.getItem("tab") || 0,  //0：模块，1：购物车
      userInfo: '',
      proList: [
        // {product_id:1,module_id:1,product_name:'目标感',price:9.9},
      ],
    }
  },
  created() {
    document.title = '人才发展评估'
    //获取产品列表
    this.$http.get(this.spaceUrl+'/Product/getModuleProductList').then(({data}) => {
      if (data.code == 0) {
        this.proList = data.data
      }
    })
    //获取登录信息
    this.getUserInfo()
  },
  methods:{
    changeTab(e) {
      this.tab = e
      sessionStorage.setItem("tab", e)
      //未登录
      if (e == 1 && !this.userInfo) this.toLogin('shopcar')
    },
    toDetail(id) {
      this.$router.push({path: '/TDA/detail', query: {id: id}})
    },
    toLogin(e) {
      this.$router.push({path: '/TDA/login', query: {link: e}})
    },
    getUserInfo() {
      this.getSession().then(s => {
        this.$http.get(this.spaceUrl+'/User/checklogin?hy_session='+s).then(({data}) => {
          if (data.code == 0) {
            let user = JSON.parse(localStorage.getItem("user"))
            if (user)
              this.userInfo = user
            else
              this.$http.get(this.spaceUrl+'/Product/getUserInfo?hy_session='+s).then(e => {
                this.userInfo = e.data.data
                localStorage.setItem("user", JSON.stringify(e.data.data))
              })
          } else if (data.code == 2) {
            this.userInfo = data.data
            localStorage.setItem("user", JSON.stringify(data.data))
          } else {
            localStorage.removeItem('user')
            // location.href = this.spaceUrl + '/WeixinH5/login?url=' + encodeURIComponent(this.mobileUrl+'/#/TDA/shop?hy_session='+s)
          }
        })
      })
    },
    getSession() {
      return new Promise((resolve) => {
        let s = localStorage.getItem("session") || ''
        if (s) {
          resolve(s)
        } else {
          this.$http.get(this.spaceUrl+'/index/getSession').then(({data}) => {
            localStorage.setItem("session", data.data.session_id)
            resolve(data.data.session_id)
          }, () => resolve())
        }
      })
    }
  },
  mounted() {
    document.title = '人才发展评估'
    wx.ready(() => {
      wx.onMenuShareAppMessage({
        title : '人才发展评估',
        link : location.href,
        imgUrl : `${this.mobileUrl}/static/img/p-15.jpg`,
      })
    })
  },
}
</script>
<style lang="scss" scoped>
.TDA_shop{
  background: #f1f5f7;
  user-select: none;
  &::before{
    content: '';
    position: fixed;
    background: linear-gradient(to bottom, #f1f5f7 50%, #fff 50%);
    left: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -2;
  }
  .top{
    margin: 0 24px;
    font-size: 24px;
    color: #404040;
    white-space: nowrap;
    padding: 0 4px;
    height: 68px;
    .head{
      width: 36px;
      height: 36px;
      margin-right: 10px;
      border-radius: 50%;
      overflow: hidden;
    }
    .switch{
      height: 68px;
      opacity: .5;
      img{
        width: 26px;
        height: 26px;
        margin-left: 10px;
      }
    }
  }
  .window{
    height: calc(100vh - 188px);
    position: relative;
    overflow: hidden;
    .module{
      width: 100%;
      height: 100%;
      overflow-y: auto;
      transform: translateX(-100%);
      transition: .3s;
      .banner{
        margin: 0 24px;
        border-radius: 10px;
        overflow: hidden;
        img{
          display: block;
          width: 100%;
        }
      }
      .pro{
        margin: 0 12px;
        flex-wrap: wrap;
        font-size: 28px;
        text-align: left;
        padding: 12px 0;
        color: #292929;
        li{
          background: #fff;
          border-radius: 12px;
          margin: 12px;
          padding: 20px;
        }
        .pic{
          width: 299px;
          height: 240px;
          margin-bottom: 16px;
          img{
            width: 120px;
            height: 120px;
            border-radius: 50%;
            box-shadow: 0 4px 8px rgba(200,200,200,.1);
            background: linear-gradient(100deg, rgba(255,255,255,.5) 10%, rgba(200,200,200,0))
          }
        }
        h2{
          display: flex;
          align-items: baseline;
          font-size: 30px;
          color: #f81c1c;
          padding: 4px 0;
          span{
            font-size: 24px;
            margin-right: 8px;
          }
          s{
            font-size: 24px;
            color: #b3b3b3;
            margin-left: 20px;
          }
        }
      }
    }
    .container{
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      z-index: 2;
      box-sizing: border-box;
      transform: translateX(100%);
      transition: .3s;
    }
    .trans{transform: translateX(0);}
    .pic1{background: linear-gradient(135deg, #abcfff 10%, #4889f8 80%)}
    .pic2{background: linear-gradient(135deg, #8bf576 10%, #66c254 80%)}
    .pic3{background: linear-gradient(135deg, #47e7ff 10%, #51b4f9 80%)}
    .pic4{background: linear-gradient(135deg, #ffd37e 10%, #f0a735 80%)}
    .pic5{background: linear-gradient(135deg, #939dff 10%, #5757f7 80%)}
    .pic6{background: linear-gradient(135deg, #83d4ff 10%, #369dd3 80%)}
    .pic7{background: linear-gradient(135deg, #63fffd 10%, #5bc4c3 80%)}
    .pic8{background: linear-gradient(135deg, #ffb895 10%, #ec8756 80%)}
    .pic9{background: linear-gradient(135deg, #c398ff 10%, #8c5fca 80%)}
  }
  .bottom{
    position: fixed;
    width: 100%;
    height: 120px;
    left: 0;
    bottom: 0;
    background: #fff;
    line-height: 1.8;
    color: #979797;
    font-size: 26px;
    z-index: 10;
    img{
      width: 44px;
      height: 44px;
      display: block;
      margin: 0 auto;
    }
    .active{color: #4987f9}
  }
}
</style>