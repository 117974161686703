<template>
  <div class="target" :class="'target_'+theme">
    <!-- 原动力/职业素质 -->
    <div class="line">
      <div class="score" v-show="motivity" :style="{left:getPosition(motivity)+'%'}"><i>{{Math.round(motivity)}}</i></div>
      <div class="score" :style="{left:getPosition(quality,1)+'%'}"><i>{{Math.round(quality)}}</i></div>
    </div>
    <!-- 团队活力/领导能量 -->
    <div class="line col">
      <div class="score" v-show="activity" :style="{top:getPosition(activity)+'%'}"><i>{{Math.round(activity)}}</i></div>
      <div class="score" v-show="leadership" :style="{top:getPosition(leadership,1)+'%'}"><i>{{Math.round(leadership)}}</i></div>
    </div>
    <!-- <div class="redDash"></div> -->
    <div class="circle">
      <div class="left"></div>
      <div class="right"></div>
      <p style="left:11%;top:12%">{{dimension[0].name}}</p>
      <p style="right:11%;top:12%;">{{dimension[1].name}}</p>
      <p style="left: 32%;bottom: 2px;">{{dimension[2].name}}</p>
    </div>
    <div class="text text1 text3" style="right:105%;">原动力</div>
    <div class="text text1" style="left:105%;">职业素质</div>
    <div class="text text2" style="bottom:105%;">团队活力</div>
    <div class="text text2" style="top:105%;">领导能量</div>
  </div>
</template>

<script>
export default {
  props: {
    activity: {
      type: Number,
      default: 0
    },
    leadership:{
      type: Number,
      default: 0
    },
    motivity:{
      type:Number,
      default: 0
    },
    quality:{
      type: Number,
      default: 0
    },
    dimension:{
      type: Array
    },
    theme:{
      type: String,
      default: ''
    },
  },
  methods:{
    getPosition(score,type){
      // 左：2% - 26%， 右72% - 95% （35分-85分）
      if(type){
        return Math.round(23 * score / 50 + 55.9)
      }else{
        return Math.round(-23 * score / 50 + 42.1)
      }
    }
  },
}
</script>
<style lang="scss" scope>
.target{
  $width: 440;
  width: $width + px;
  height: $width + px;
  margin: 120px auto 100px;
  border: 1px solid #6ec6fd;
  background: #f4fbff;
  position: relative;
  .line{
    border-top: 1px solid #92d4fe;
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 0;
    .score{
      position: absolute;
      width: 8px;
      height: 8px;
      border-radius: 50%;
      background: #e96842;
      color: #e96842;
      font-size: 24px;
      top: 50%;
      transform: translateY(-50%);
      animation: fade .6s .5s forwards;
      opacity: 0;
      i{
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        top: 8px;
      }
    }
    @keyframes fade {
      100%{opacity: 1;}
    }
  }
  .col{
    height: 100%;
    width: 0;
    left: 50%;
    top: 0;
    border-right: 1px solid #92d4fe;
    .score{
      left: 50%;
      transform: translateX(-50%);
      i{
        top: 50%;
        transform: translateY(-50%);
        left: -34px;
      }
    }
  }
  .redDash{
    width: $width*.6 + px;
    height: $width*.6 + px;
    border-radius: 50%;
    border: 1px dashed #e8552a;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
  }
  .circle{
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    width: $width*.38 + px;
    height: $width*.38 + px;
    border: 4px solid #6ec6fd;
    border-radius: 50%;
    background: #5da7e5;
    overflow: hidden;
    .left{
      position: absolute;
      height: 200px;
      width: 0;
      border-bottom: 80px solid #5da7e5;
      border-left: 160px solid #5ec3e4;
      right: 50%;
      bottom: 0px;
    }
    .right{
      position: absolute;
      height: 200px;
      width: 0;
      border-bottom: 80px solid #5da7e5;
      border-right: 160px solid #5e90e4;
      left: 50%;
      bottom: 0px;
    }
    p{
      width: 60px;
      color: #fff;
      font-size: 24px;
      position: absolute;
    }
  }
  .text{
    position: absolute;
    font-size: 26px;
    color: #6f6f6f;
  }
  .text1{
    width: 36px;
    height: 132px;
    top: 50%;
    transform: translateY(-50%);
    line-height: 1.3;
    text-align: center;
  }
  .text2{
    width: 120px;
    left: 50%;
    transform: translateX(-50%);
  }
  .text3{
    box-sizing: border-box;
    padding-top: 20px;
  }
}
.target_planet{
  background: #bee7ff
}
</style>
