<template>
  <svg class="sector_ts">
    <defs>
      <path id="txtPath"/>
      <linearGradient gradientUnits="userSpaceOnUse" id="shadow1" x1="1%" y1="0%" x2="99%" y2="0%">
        <stop offset="0%" stop-color="#fea51a"/>
        <stop offset="100%" stop-color="#e96118"/>
      </linearGradient>
      <linearGradient gradientUnits="userSpaceOnUse" id="shadow2">
        <stop offset="0%" stop-color="#b7af29"/>
        <stop offset="100%" stop-color="#f7aa1c"/>
      </linearGradient>
    </defs>
  </svg>
</template>
<script>
import * as d3 from 'd3'
export default {
  props: {
    data: {
      default: 0,
    },
    refer: {
      default: 0,
    },
    scale: {
      type: Array,
      default: () => [60,80,100.1],
    },
    animate:{
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    if (this.data) {
      this.draw()
    } else {
      this.$watch('data', () => this.draw())
    }
  },
  methods: {
    getRing(r,agl) {
      agl = agl / 360 * 2 * Math.PI
      return [this.cen - Math.sin(agl) * r, this.cen + Math.cos(agl) * r]
    },
    getScore(e) {
      for (let i in this.scale) {
        let last = this.scale[i-1] || 0
        if (e < this.scale[i]) {
          return (e-last) / (this.scale[i]-last) * 60 + 60 * i
        }
      }
    },
    draw(){
      let width = $('#app').width()*.7,
          svg = d3.select(this.$el).attr('width', width).attr('height', width*.54),
          fontSize = width * .048,       //文字大小
          R1 = width * .42,               //最大外圆
          R2 = width * .34,
          r3 = width * .2,
          cen = this.cen = width / 2;
      //外弧线
      let outArc = svg.append('g').classed('out',true)
      let start1 = this.getRing(R1, 90)
      let end1 = this.getRing(R1, 270)
      outArc.append('path')
            .attr('stroke-width', 2)
            .attr('stroke', 'url(#shadow1)')
            .attr('fill', 'none')
            .attr('d', `M${start1[0]} ${start1[1]} A${R1} ${R1} 0 1 1 ${end1[0]} ${end1[1]}`)
      for (let i = 0; i < 4; i++) {
        let p1 = this.getRing(R1-1, 90 + 60 * i)
        let p2 = this.getRing(R1*1.05, 90 + 60 * i)
        outArc.append('line')
              .attr('x1', p1[0])
              .attr('y1', p1[1])
              .attr('x2', p2[0])
              .attr('y2', p2[1])
              .attr('stroke', 'url(#shadow1)')
              .attr('stroke-width', 2)
      }
      //外弧文字
      let txtr = width * .46, txtList = ['中等','良好','卓越']
      let startT = this.getRing(txtr, 90)
      let endT = this.getRing(txtr, 270)
      svg.select('#txtPath').attr('d', `M${startT[0]} ${startT[1]} A${txtr} ${txtr} 0 1 1 ${endT[0]} ${endT[1]}`)
      let arcTxt = svg.append('text')
      for (let i in txtList) {
        arcTxt.append('textPath')
              .attr('fill', '#936e52')
              .attr('xlink:href', '#txtPath')
              .attr('startOffset', `${i*33.33+16.66}%`)
              .attr('font-size', fontSize)
              .text(txtList[i])
      }
      //中间弧线
      let start2 = this.getRing(R2, 90)
      let end2 = this.getRing(R2, 270)
      svg.append('path')
         .attr('stroke-width', fontSize*2)
         .attr('stroke', '#ffe1d2')
         .attr('fill', 'none')
         .attr('d', `M${start2[0]} ${start2[1]} A${R2} ${R2} 0 1 1 ${end2[0]} ${end2[1]}`)
      //内弧线
      let inArc = svg.append('g'), start3 = this.getRing(r3, 90), end3 = this.getRing(r3, 270)
      inArc.append('path')
           .attr('stroke-width', 1)
           .attr('stroke', 'url(#shadow1)')
           .attr('fill', 'none')
           .attr('d', `M${start3[0]} ${start3[1]} A${r3} ${r3} 0 1 1 ${end3[0]} ${end3[1]}`)
      for (let i = 0; i < 2; i++) {
        let p1 = this.getRing(r3-1, 90 + 180 * i)
        let p2 = this.getRing(r3*1.05, 90 + 180 * i)
        inArc.append('line')
            .attr('x1', p1[0])
            .attr('y1', p1[1])
            .attr('x2', p2[0])
            .attr('y2', p2[1])
            .attr('stroke', 'url(#shadow1)')
            .attr('stroke-width', 1)
      }
      //分数弧
      let angle = this.getScore(this.data), //旋转角度
          end  = this.getRing(R2, 90 + angle), 
          girth = angle / 180 * R1 * Math.PI //周长
      svg.select('#shadow2')
          .attr('x1', start2[0])
          .attr('y1', start2[1])
          .attr('x2', end[0])
          .attr('y2', end[1])
      svg.append('path')
          .attr('stroke-width', fontSize*2)
          .attr('stroke', 'url(#shadow2)')
          .attr('fill', 'none')
          .attr('d', `M${start2[0]} ${start2[1]} A${R2} ${R2} 0 0 1 ${end[0]} ${end[1]}`)
          .attr("stroke-dasharray", girth)
          .attr("stroke-dashoffset", 0)
          .append('animate')
          .attr('attributeName', 'stroke-dashoffset')
          .attr('fill', 'freeze')
          .attr('dur', '.6s')
          .attr('from', girth)
          .attr('to', 0)
      //参考线
      if (this.refer) {
        let referAngle = this.getScore(this.refer)
        let referP = this.getRing(R2+fontSize, 90+referAngle)
        svg.append('line')
            .attr('x1', referP[0])
            .attr('y1', referP[1])
            .attr('x2', cen)
            .attr('y2', cen)
            .attr("stroke-dasharray", 2)
            .attr('stroke', '#da503e')
            .attr('stroke-width', 1)
        svg.append('text')
            .append('textPath')
            .attr('fill', '#da503e')
            .attr('xlink:href', '#txtPath')
            .attr('startOffset', `${referAngle/180*100}%`)
            .attr('font-size', fontSize*.9)
            .text('参考线')
      }
      //中间圆点
      svg.append('circle')
          .classed('circle1',true)
          .attr('cx', cen)
          .attr('cy', cen)
          .attr('r', fontSize*.6)
          .attr('stroke', 'none')
          .attr('fill', '#faddcf')
      svg.append('circle')
          .classed('circle2',true)
          .attr('cx', cen)
          .attr('cy', cen)
          .attr('r', fontSize*.45)
          .attr('stroke', 'none')
          .attr('fill', '#323232')
      //指针
      svg.append('path')
          .attr('stroke', 'none')
          .attr('fill', '#323232')
          .attr('d', `M${cen-R2-fontSize} ${cen} L${cen} ${cen+4},${cen} ${cen-4}`)
          // .attr('transform', () => `rotate(${angle} ${cen} ${cen})`)//无动画绘制
          // 添加指针动画
          .append('animateTransform')
          .attr('attributeName', 'transform')
          .attr('type', 'rotate')
          .attr('fill', 'freeze')
          .attr('dur', '.5s')
          .attr('from', `0 ${cen} ${cen}`)
          .attr('to', `${angle} ${cen} ${cen}`)
    },
  },
}
</script>
<style lang="scss">
.sector_ts{
  margin-top: 30px;
  text{
    text-anchor: middle;
    dominant-baseline: middle;
  }
}
</style>