<template>
  <!--报告-->
  <report_3_1 v-if="version=='3.1.0'" :version="version" :exam_id="exam_id"/>
  <report_3_0 v-else-if="version=='3.0.0'" :version="version" :exam_id="exam_id"/>
  <report_2_0 v-else-if="version=='2.0.0'" :version="version" :exam_id="exam_id"/>
  <report_1_2 v-else-if="version" :exam_id="exam_id"/>
</template>

<script>
import report_3_1 from './report3.1.vue'
import report_3_0 from './report3.0.vue'
import report_2_0 from './report2.0.vue'
import report_1_2 from './report1.2.vue'
export default {
  components:{report_1_2,report_2_0,report_3_0,report_3_1},
  data(){
    return{
      exam_id: this.$route.query.exam_id || '',
      version: '',
    }
  },
  mounted(){
      this.$http.get(this.spaceUrl+`/Amt/getReportVersion?exam_id=${this.exam_id}`).then(({data}) => {
        if (data.code == 0) {
          this.version = data.data.version
        } else {
          this.$toast({message:'无法加载',duration: 2000})
          console.error(data.msg)
        }
      })
  },
}
</script>