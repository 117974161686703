<template>
  <div class="cbcIndex" :class="{fix:riseIndex==10}">
    <img src="../../../static/img/team3.jpg">
    <div class="how">
      <div>你了解自己内心真正的需求吗？</div>
      <div>你明确自己在工作中最想达成的目标吗？</div>
      <div>你知道如何选择满足自己需求的工作吗？</div>
    </div>

    <h1>关于工作价值倾向</h1>
    <div class="talk">
      <div class="tbox">价值倾向是我们无意识，但却深植于大脑的假设和价值观，决定了我们对事物的预期，为我们预设一个框架，影响我们做事的方式。<img src="../../../static/img/cbc_index_5.png"></div>
      <div class="tbox tbox2">工作价值倾向，则反映出我们在工作场景中最迫切的需求倾向，体现出个人的选择偏好，影响个人的原动力和职业素质的激发程度、工作态度、行动及思考判断。<img src="../../../static/img/cbc_index_6.png"></div>
    </div>

    <h1>关于“职涯优选CBC”</h1>
    <p>基于理论结合实践研究，聚焦成就实现、自我成长、寻求变化、自主空间、经济收入、权力地位、兴趣特长7个价值方向，帮助个体明确自己内在真正的价值需求。</p>

    <h1>通过“职涯优选CBC”</h1>
    <h2>你将了解：</h2>
    <div class="learn">
      <div class="item">
        <div class="h3"><img class="icon1" src="../../../static/img/cbc_index_7.png">你的工作价值倾向</div>
        <p>揭示自己在工作场景中内心最迫切的需求。</p>
      </div>
      <div class="item">
        <div class="h3"><img class="icon2" src="../../../static/img/cbc_index_8.png">你的成就意愿</div>
        <p>从目标驱动、自我期待两个方面分析自己成就意愿的强烈程度。</p>
      </div>
      <div class="item">
        <div class="h3"><img class="icon3" src="../../../static/img/cbc_index_9.png">你的职场及岗位方向</div>
        <p>根据你当下的工作价值倾向及成就意愿程度，推荐可能适合的职场岗位及职业方向。</p>
      </div>
      <div class="item">
        <div class="h3"><img class="icon4" src="../../../static/img/cbc_index_10.png">你的职场关键词</div>
        <p>根据你当下的状态，展示你可能的职业标签。</p>
      </div>
    </div>

    <h1>适合对象</h1>
    <div class="crowd flex-b">
      <div class="icon"><img src="../../../static/img/cbc_index_11.png">职场新人</div>
      <div class="icon"><img src="../../../static/img/cbc_index_12.png">对职业发展<br>感到迷茫的人</div>
      <div class="icon"><img src="../../../static/img/cbc_index_13.png">需要进一步<br>晋升的人</div>
    </div>

    <h1>报告样例</h1>
    <div class="picture">
      <img src="../../../static/img/cbc_index_1.png" @click="picIndex=1">
      <img src="../../../static/img/cbc_index_2.png" @click="picIndex=2">
      <img src="../../../static/img/cbc_index_3.png" @click="picIndex=3">
      <img src="../../../static/img/cbc_index_4.png" @click="picIndex=4">
    </div>
    <h3>（部分报告页展示，点击可查看大图）</h3>

    <h1>注意事项</h1>
    <p>① 点击下方“立即购买”按钮进行购买，购买成功后请按页面提示点击进入小程序进行操作。</p>
    <p>② 已购买的用户点击下方“立即购买”按钮进入选择再次购买或直接跳转小程序操作。</p>
    <p v-if="lowVersion">③ 你当前的微信版本过低，请更新微信或在微信搜索“十方实验室”小程序进入操作。</p>
    <p class="p2">如有疑问，请扫码添加客服微信或致电工作人员咨询</p>
    <img src="../../assets/images/zhiyuQrcode.jpg" class="kefu">
    <p class="p3">（可长按识别）</p>
    <p class="p4">固话：<a href="tel:020-87070220">020-87070220</a></p>
    <p class="p4">手机：<a href="tel:18027418722">18027418722</a></p>
    <div class="under">
      <div class="btn" @click="start">立即购买 ￥9.9/次</div>
    </div>
    <!-- 报告图片放大 -->
    <div class="mask" v-if="picIndex" @click="picIndex=0"/>
    <div v-if="picIndex" class="large">
      <img class="img" :src="`../../../static/img/cbc_index_${picIndex}.png`">
      <img class="close" @click="picIndex=0" src="../../assets/images/icons/close.png">
    </div>
    <div class="mask" v-if="riseIndex" @click="countryShow=false"/>
    <!-- 登录窗口 -->
    <div class="login box" :class="{rise:riseIndex==1}" @click="countryShow=false">
      <img src="../../assets/images/icons/close_2.png" class="close" @click="riseIndex=0">
      <h4>{{loadingText}}</h4>
      <div class="option" :class="{focus:focusIndex==1,error:errorIndex==1}">
        <h5>请输入手机号</h5>
        <div class="inputBox flex--c">
          <label :class="{rotate:countryShow}" @click.stop="countryShow=!countryShow">{{$country[countryIndex].label}}</label>
          <input type="number" v-model="phone" @focus="focusIndex=1;errorIndex=0" @blur="focusIndex=0">
          <ul  class="countryBox" :class="{show:countryShow}">
            <li v-for="(item,i) in $country" :key="i" @click="countryIndex=i" :class="{curr:countryIndex==i}">{{item.country}}</li>
          </ul>
        </div>
      </div>
      <div class="option" :class="{focus:focusIndex==2,error:errorIndex==2}">
        <h5>请输入验证码</h5>
        <div class="inputBox flex--c">
          <input type="text" v-model="code" maxlength="6" @focus="focusIndex=2;errorIndex=0" @blur="focusIndex=0">
          <span v-if="second===0" @click="getCode">获取验证码</span>
          <span class="disable" v-else>重新获取({{second}}s)</span>
        </div>
      </div>
      <div class="btn" @click="login">登 录</div>
    </div>
    <!-- 个人-购买窗口 -->
    <div class="detail box" :class="{rise:riseIndex==4}">
      <img src="../../assets/images/icons/close_2.png" class="close" @click="riseIndex=0">
      <h5>产品详情</h5>
      <div class="flex-b">职涯优选CBC（个人版）<div>价格：<b class="green">￥9.9</b> / 次</div></div>
      <div class="flex-b-c">
        <span>购买数量</span>
        <div class="numInput">
          <span class="flex-c-c" @click="changeNum(-1)">-</span>
          <input type="number" maxlength="4" v-model="buyNum" @input="changeNum">
          <span class="flex-c-c" @click="changeNum(1)">+</span>
        </div>
      </div>
      <div class="totalText">合计：<span>￥{{totalPrice}}</span></div>
      <div class="btn" @click="pay()">确认购买</div>
      <wx-open-launch-weapp username="gh_f68c5eef1b60" :path="path" style="display:block" v-if="hasBuy">
        <script type="text/wxtag-template">
          <div style="line-height: 40px;border-radius: 4px;letter-spacing: 1px;font-size: 14px;border: 1px solid #77a9fe;color: #77a9fe;text-align: center;margin:0 16px;">暂不购买，跳转小程序</div>
        </script>
      </wx-open-launch-weapp>
    </div>
    <!-- 购买成功 -->
    <div class="success" v-if="riseIndex==10">
      <img src="../../assets/images/icons/success_cbc.png" class="icon">
      <h5>支付成功</h5>
      <h6>点击下方按钮前往小程序端进行评估管理</h6>
      <wx-open-launch-weapp username="gh_f68c5eef1b60" :path="path">
        <script type="text/wxtag-template">
          <div style="line-height: 40px;background: #77a9fe;color: #fff;border-radius: 4px;letter-spacing: 2px;margin: 50px 30px 20px;font-size: 14px;text-align: center;width:300px">进入小程序</div>
        </script>
      </wx-open-launch-weapp>
    </div>
  </div>
</template>
<script>
export default {
  data(){
    return {
      handler_id: this.$route.query.handler_id || '',
      picIndex: 0,                //点击放大图片的索引
      phone:'',                   //登录手机号
      code:'',                    //登录验证码
      second: 0,                  //倒数秒数
      countryShow: false,         //区号选择框显隐
      countryIndex: 0,            //当前选择的区号索引
      errorIndex: 0,              //登录错误索引(1：手机号错误，2：验证码错误)
      focusIndex: 0,              //聚焦索引
      riseIndex: 0,               //弹起窗口索引(1：登录，2：产品新购，3：已存在公司)
      loadingText: '请先登录',     //是否已经登录
      loginType: 'login',         //登录类型(login:登录,register:注册)
      lowVersion: false,          //低版本不支持跳转
      product_id: 14,             //购买产品的id
      hasBuy: false,              //是否已经购买过
      path: '',
      buyNum: 1,
      totalPrice: 9.9,
    }
  },
  created(){
    document.title = '职涯优选CBC'
    this.$http.get(this.spaceUrl+'/User/checkWeixinLogin').then(e => {
      if (e.data.code == 1 && location.host != 'localhost') 
        location.href = `${this.spaceUrl}/Product/index?url=${encodeURIComponent(location.href)}`
    })
    this.$http.get(this.spaceUrl+'/User/checklogin').then(res => {
      if (res.data.code == 0) {
        this.loadingText = '登录成功'
        this.$http.post(this.spaceUrl + '/Career/getPersonalService').then(({data}) => {
          this.product_id = data.data.product_id
          if (data.code == 0) { //已购买
            this.hasBuy = true
            this.$http.get(this.spaceUrl+'/User/getsession').then(({data}) => this.path = `pages/professionTend/manage/manage?sessionID=${data.data.hy_session}`)
          }
        })
      }
    })
    //判断微信版本过低
    var wechat = navigator.userAgent.match(/MicroMessenger\/([\d\.]+)/i)
    if (wechat && wechat[1] < '7.0.12') this.lowVersion = true
    //微信分享
    wx.ready(() => {
      wx.onMenuShareAppMessage({
        title : '职涯优选CBC',
        link : `${this.spaceUrl}/Product/index?url=${encodeURIComponent(location.href)}`,
        imgUrl : `${this.mobileUrl}/static/img/p-6.jpg`,
      })
    })
  },
  methods:{
    start(){
      let ua = navigator.userAgent.toLowerCase()
      if (ua.match(/MicroMessenger/i) != 'micromessenger') {
        this.$toast({message: '请在微信内打开'})
      } else if (this.loadingText != '登录成功') {
        this.riseIndex = 1
      } else {
        this.riseIndex = 4
      }
    },
    pay(){
      if (!this.buyNum) {
        this.$toast({message: '请先输入数量', duration: 1000}); return;
      }
      //获取小程序路径的session
      this.$http.get(this.spaceUrl+'/User/getsession').then(({data}) => this.path = `pages/professionTend/manage/manage?sessionID=${data.data.hy_session}`)
      this.$indicator.open({text: '加载中...', spinnerType: 'fading-circle'})
      this.$http.get(this.spaceUrl+'/Product/createOrder', {params: {
        product_id: this.product_id,
        number: this.buyNum,
        pay_type: 1,
        handler_id: this.handler_id,
      }}).then(({data}) => {
        this.$indicator.close()
        if (data.code == 0) {
          WeixinJSBridge.invoke('getBrandWCPayRequest', data.data, res => {
            if (res.err_msg == "get_brand_wcpay_request:ok") {
              this.riseIndex = 10
              this.buyNum = 1
            } else {
              this.$toast({message: '支付失败'})
            }
          })
        } else {
          this.$toast({message: data.msg, duration: 2000})
        }
      })
    },
    getCode(){
      if (this.sending) {
        return
      } else if (!this.phone) {
        this.$toast({message: '请输入手机号', duration: 1000})
        this.errorIndex = 1
      } else if (!this.$country[this.countryIndex].reg.test(this.phone)) {
        this.$toast({message: '手机号格式不对', duration: 1000})
        this.errorIndex = 1
      } else {
        this.sending = true
        this.$http.post(this.spaceUrl+'/User/checkMobile', {
          mobile: this.phone,
          country_code: this.$country[this.countryIndex].country_code,
        }).then(e => {
          if (e.data.msg.indexOf('未注册') > 0) this.loginType = 'register'
          this.$http.post(this.spaceUrl+'/sms/getSmsCaptcha', {
            mobile: this.phone,
            country_code: this.$country[this.countryIndex].country_code,
            type: this.loginType,
          }).then(({data}) => {
            this.sending = false
            if (data.code == 0) {
              this.$toast({message: '发送成功'})
              this.second = 60
              var timer = setInterval(() => {
                if (--this.second < 1) clearInterval(timer)
              }, 1000)
            } else {
              this.$toast({message: '发送失败'})
            }
          }, () => {
            this.sending = false
            this.$toast({message: '发送失败，请重试'})
          })
        }, () => {
          this.sending = false
          this.$toast({message: '手机检验失败'})
        })
      }
    },
    login(){
      if (this.loadingText == '登录中') {
        return
      } else if (!this.phone) {
        this.$toast({message: '请输入手机号', duration: 1000})
        this.errorIndex = 1
      } else if (!this.$country[this.countryIndex].reg.test(this.phone)) {
        this.$toast({message: '手机号格式不对', duration: 1000})
        this.errorIndex = 1
      } else if (!this.code) {
        this.$toast({message: '请输入验证码', duration: 1000})
        this.errorIndex = 2
      } else { //登录或注册
        this.$http.post(`${this.spaceUrl}/User/${this.loginType}`, {
          mobile: this.phone,
          country_code: this.$country[this.countryIndex].country_code,
          captcha: this.code,
        }).then(res => {
          if (res.data.code == 0) {
            this.loadingText = '登录成功'
            this.riseIndex = 4
            //获取服务信息
            this.$http.post(this.spaceUrl + '/Career/getPersonalService').then(({data}) => {
              this.product_id = data.data.product_id
              if (data.code == 0) { //已购买
                this.hasBuy = true
                this.$http.get(this.spaceUrl+'/User/getsession').then(({data}) => this.path = `pages/professionTend/manage/manage?sessionID=${data.data.hy_session}`)
              }
            }, () => this.$toast({message: '网络错误，请稍后重试'})) 
          } else if (res.data.msg.indexOf('未注册') > 0) {
            this.loginType = 'register'
            this.loadingText = '登录失败'
          } else {
            this.loadingText = '请先登录'
            this.$toast({message: res.data.msg})
          }   
        }, () => this.loadingText = '登录失败')
      }
    },
    // 改变数量
    changeNum(e){
      let num;
      if (e.target) 
        num = e.target.valueAsNumber || 0
      else
        num = this.buyNum + e
      if (num > 999) {
        this.buyNum = 999
        this.totalPrice = (999 * 9.9).toFixed(2)
      } else if (num < 0) {
        this.buyNum = 0
        this.totalPrice = 0
        this.$toast({message: '不能再少了', duration: 1000})
      } else {
        this.buyNum = num
        this.totalPrice = parseFloat( (num * 9.9).toFixed(2) )
      }
    },
    loginOut(){this.$http.get(this.spaceUrl+'/User/loginOut')},
  },
  watch: {
    code(val){
      this.code = val.replace(/[^\d]/g, '')
    }
  }
}
</script>
<style lang="scss" scoped>
$color:#77a9fe;
.cbcIndex{
  padding-bottom: 180px;
  text-align: left;
  box-shadow:  0 0 8px rgba(50,50,50,.1);
  img{display: block;width: 100%;}
  .how{
    text-align: center;
    font-size: 28px;
    color: $color;
    font-weight: bold;
    line-height: 1.6;
    margin: 60px 0 0;
  }
  h1{
    line-height: 60px;
    background: $color;
    color: #fff;
    padding: 0 20px;
    margin: 60px 30px 40px;
    font-size: 28px;
    border-radius: 6px;
    display: inline-block;
    position: relative;
    user-select: none;
    z-index: 2;
    &::after{
      content: '';
      position: absolute;
      top: 4px;
      left: 4px;
      width: 100%;
      height: 100%;
      border-radius: 6px;
      border: 2px solid $color;
      z-index: 1;
    }
  }
  h2{
    color: #4d4d4d;
    font-size: 26px;
    margin: 0 36px 20px;
    font-weight: bold;
  }
  h3{
    color: #ccc;
    font-size: 24px;
    text-align: center;
    line-height: 50px;
    margin-bottom: 20px;
  }
  p{
    font-size: 26px;
    text-align: justify;
    line-height: 1.6;
    color: #999;
    margin: 0 36px;
    user-select: none;
    white-space: normal;
    a{
      color: #999;
      user-select: text;
      text-decoration: none;
    }
  }
  .p2{
    color: #4d4d4d;
    text-align: center;
    line-height: 120px;
    margin: 0 28px;
  }
  .p3{
    margin-bottom: 30px;
    text-align: center;
    font-size: 24px;
    line-height: 2;
  }
  .kefu{
    width: 200px;
    height: 200px;
    display: block;
    margin: 0 auto;
    border: 1px solid #ccc;
  }
  .p4{
    text-align: center;
    line-height: 2;
  }
  .talk{
    margin: 0 40px 90px;
    &::after{
      content: '';
      clear: both;
      display: block;
    }
    .tbox{
      font-size: 26px;
      background: #77a9fe;
      color: #fff;
      line-height: 1.7;
      padding: 20px 30px;
      border-radius: 8px;
      width: 480px;
      margin: 20px 10px 0;
      position: relative;
      text-align: justify;
      &::after{
        content: '';
        position: absolute;
        border-top: 50px solid #77a9fe;
        border-left: 30px solid transparent;
        bottom: -40px;
        left: 30px;
        transform: skew(-40deg);
        transform-origin: bottom;
      }
      img{
        width: 64px;
        height: 64px;
        position: absolute;
        left: -8px;
        bottom: -94px;
      }
    }
    .tbox2{
      background: #e8af24;
      margin-top: 60px;
      float: right;
      &::after{
        border: none;
        left: auto;
        right: 30px;
        border-top: 50px solid #e8af24;
        border-right: 30px solid transparent;
        transform: skew(40deg);
      }
      img{
        left: auto;
        right: -8px;
      }
    }
  }
  .learn{
    display: flex;
    justify-content: space-between;
    margin: 0 40px;
    flex-wrap: wrap;
    .item{
      width: 300px;
      font-size: 26px;
      margin: 30px 0 10px;
    }
    .h3{
      color: #4d4d4d;
      display: flex;
      align-items: center;
      white-space: nowrap;
      .icon1{background: #77a9fe;}
      .icon2{background: #e3a91c;}
      .icon3{background: #14c0aa;}
      .icon4{background: #8371f2;}
      img{
        width: 44px;
        height: 44px;
        padding: 4px;
        border-radius: 4px;
        margin-right: 8px;
      }
    }
    p{
      display: block;
      margin: 30px 0;
      color: #999;
      line-height: 1.4;
    }
  }
  .crowd{
    margin: 20px 50px;
    .icon{
      display: flex;
      width: 200px;
      flex-direction: column;
      align-items: center;
      text-align: center;
      font-size: 30px;
      color: #4d4d4d;
      background: #e4f0f7;
      font-weight: bold;
      line-height: 1.5;
      padding: 50px 0;
      border-radius: 10px;
      img{
        width: 120px;
        height: 120px;
        margin-bottom: 20px;
      }
    }
  }
  .picture{
    margin: 0 40px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    img{
      width: 300px;
      height: 420px;
      border: 1px solid #81c5cc;
      border-radius: 12px;
      margin: 20px 0;
      display: block;
    }
  }
  .under{
    border-top: 1px solid #f6f6f6;
    position: fixed;
    width: 100%;
    max-width: 800px;
    padding: 0 30px;
    height: 120px;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;
    background: #fff;
    display: flex;
    align-items: center;
    text-align: center;
    font-size: 24px;
    color: #999;
    z-index: 10;
    .btn{
      line-height: 80px;
      background: #ff9a56;
      color: #fff;
      border-radius: 8px;
      letter-spacing: 2px;
      font-size: 28px;
      flex-grow: 1;
    }
  }
  .large{
    position: fixed;
    width: 700px;
    left: 25px;
    top: 8%;
    z-index: 20;
    transform-origin: center;
    animation: large .2s linear;
    transition: all .2s;
  }
  @keyframes large {
    0%{transform: scale(0)}
    100%{transform: scale(1)}
  }
  .large img{border-radius: 10px;}
  .large .close{
    position: absolute;
    width: 70px;
    height: 70px;
    left: 50%;
    top: auto;
    bottom: -9%;
    transform: translateX(-50%);
    z-index: 12;
  }
  input::-webkit-input-placeholder {color: #ccc;}
  input::-moz-input-placeholder {color: #ccc;}
  input::-ms-input-placeholder {color: #ccc;}
}
.box{
  position: fixed;
  width: 100%;
  height: 82%;
  bottom: -100%;
  padding: 0 30px;
  left: 0;
  overflow-y: auto;
  background: #fff;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  overflow: hidden;
  z-index: 99;
  .close{
    position: absolute;
    width: 50px;
    height: 50px;
    padding: 12px;
    right: 20px;
    top: 20px;
    opacity: .6;
  }
}
.rise{
  transition: bottom .2s linear;
  bottom: 0;
}
.login{
  font-size: 26px;
  h4{
    font-size: 32px;
    font-weight: bold;
    text-align: center;
    color: #4d4d4d;
    line-height: 100px;
  }
  .btn{
    width: 100%;
    margin-top: 165px;
    background: $color;
    line-height: 84px;
    color: #fff;
    font-size: 30px;
    border-radius: 8px;
    user-select: none;
    text-align: center;
  }
  .option{
    h5{
      color: #999;
      text-align: left;
      line-height: 1.8;
      user-select: none;
      margin-top: 100px;
    }
    .inputBox{
      position: relative;
      border-bottom: 2px solid #e1eae8;
      margin-top: 22px;
      input{
        padding-left: 16px;
        height: 70px;
        flex: 1;
        color: #4d4d4d;
        font-size: 28px;
      }
      label{
        width: 80px;
        padding-right: 10px;
        line-height: 70px;
        position: relative;
        color: #808080;
        &:after{
          content:'';
          position: absolute;
          top: 26px;
          right: 0px;
          border-top: 10px solid #ccc;
          border-left: 8px solid transparent;
          border-right: 8px solid transparent;
          transition: transform .1s linear;
          transform-origin: center;
        }
      }
      .rotate::after{transform: rotate(-180deg);}
      span{
        color: $color;
        background: #eef5ff;
        border-radius: 6px;
        line-height: 54px;
        text-align: center;
        min-width: 148px;
        padding: 0 10px;
        font-size: 24px;
      }
      .disable{
        background: #f0f0f0;
        color: #999;
      }
    }
    .countryBox{
      position: absolute;
      top: 95%;
      left: -10px;
      background: #fff;
      box-shadow: 0 0 2px rgba(50,50,50,.3);
      z-index: 10;
      padding: 12px 0;
      border-radius: 6px;
      transform: scaleY(0);
      transform-origin: top;
      transition: transform .1s linear;
      user-select: none;
      li{
        padding: 0 20px;
        line-height: 2;
        color: #808080;
      }
      .curr{color: $color;}
    }
    .show{
      transform: scaleY(1);
    }
  }
  .focus{
    h5{color: #4d4d4d}
    .inputBox{border-bottom: 2px solid $color}
  }
  .error .inputBox{border-bottom: 2px solid #f96868}
}
.detail{
  padding: 80px 0 0;
  color: #999;
  white-space: nowrap;
  font-size: 28px;
  user-select: none;
  h5{
    line-height: 80px;
    color: #333;
    font-weight: bold;
    margin: 0 30px;
  }
  .flex-b, .flex-b-c{margin: 0 30px;}
  .green{color: $color}
  .red{color: #f52525}
  label{
    font-size: 24px;
    padding: 0 30px;
    line-height: 1.8;
    background: #f1f6ff;
    color: $color;
    margin: 30px 0 30px 30px;
    border-radius: 4px;
  }
  .line{
    background: #f1f1f1;
    height: 10px;
    margin: 20px 0;
  }
  .row{
    border-bottom: 1px solid #ebebeb;
    margin: 0 30px;
    height: 88px;
    color: #666;
    h6{
      width: 200px;
      text-align-last: justify;
      margin-right: 10px;
    }
    input{
      padding-left: 24px;
      height: 100%;
      color: #4d4d4d;
      font-size: 28px;
      flex: 1;
    }
    .inputPL{padding-left: 34px;}
  }
  .btn{
    line-height: 40PX;
    background: $color;
    color: #fff;
    border-radius: 4PX;
    letter-spacing: 1PX;
    margin: 100px 16PX 40px;
    font-size: 14PX;
    text-align: center;
  }
  .btn2{
    margin-top: 0;
    background: none;
    border: 1px solid $color;
    color: $color;
  }
  .p5{
    color: $color;
    line-height: 2.2;
    font-size: 28px;
    font-weight: bold;
  }
  .p6{
    font-size: 28px;
  }
  .p7{
    text-decoration: line-through;
    font-size: 24px;
    color: #d9d9d9;
    text-align: right;
    margin: 0 50px;
    line-height: 2;
  }
}
.mask{
  position: fixed;
  width: 100%;
  height: 110%;
  top: -4px;
  left: 0;
  z-index: 11;
  background: rgba($color: #000, $alpha: .7);
}
.success{
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  height: 100%;
  padding-top: 240px;
  user-select: none;
  white-space: nowrap;
  background: #fff;
  text-align: center;
  z-index: 20;
  .icon{
    display: block;
    width: 180px;
    height: 180px;
    background: #e4eeff;
    border-radius: 50%;
    margin: 20px auto;
  }
  h5{
    font-size: 40px;
    color: #4d4d4d;
    font-weight: bold;
  }
  h6{
    color: #999;
    font-size: 26px;
    line-height: 100px;
  }
  .btn{
    line-height: 80px;
    background: $color;
    color: #fff;
    border-radius: 8px;
    letter-spacing: 2px;
    margin: 100px 60px 40px;
    font-size: 28px;
    text-align: center;
  }
}
.fix{
  height: 100%;
  overflow: hidden;
}
.numInput{
  display: flex;
  margin: 40px 0;
  width: 180px;
  box-sizing: border-box;
  border: 1px solid #999;
  border-radius: 6px;
  span{
    font-size: 36px;
    width: 50px;
    height: 50px;
  }
  input{
    width: 80px;
    border-left: 1px solid #999;
    border-right: 1px solid #999;
    color: #333;
    font-weight: bold;
    text-align: center;
  }
}
.totalText{
  border-top: 1px solid #f6f6f6;
  margin: 0 30px;
  line-height: 100px;
  text-align: center;
  color: #333;
  font-weight: bold;
  font-size: 30px;
  span{color: #77a9fe}
}
</style>