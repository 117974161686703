<template>
  <div class="report">
    <header>
      <img :src="avatar">
      <div v-if="name">
        <div><b>{{name}}</b> 的报告</div>
        <div class="time">{{$formatTime(time,'y-m-d h:m')}}<span v-if="version">版本号 v{{version}}</span></div>
      </div>
    </header>
    <div class="tabBox" :class="{tabMore}">
      <ul class="tab" @touchmove.stop>
        <li v-for="(item,i) in tabList" :key="i" :class="{active:tabIndex==i}" :id="'tabItem'+i" @click="changeTab(i)">{{item.module_name}}</li>
      </ul>
      <div class="nav">
        <span @click="navShow=true" :class="{open:navShow}"><i/><i/><i/></span>
        <div class="mask" @click="navShow=false" v-show="navShow"/>
        <dl :class="{show:navShow}">
          <dd v-for="(item,i) in tabList" :key="i" :class="{curr:tabIndex==i}" @click="changeTab(i)">{{item.module_name}}</dd>
        </dl>
      </div>
    </div>
    <ul class="container" :class="{box0:tabIndex===0}">
      <!-- 此标签内不能加任何元素 -->
      <li 
        class="scrollBox" 
        v-for="(item,i) in tabList" 
        :key="i" 
        @touchstart="touchStart()" 
        @touchmove="touchMove()" 
        @touchend="touchEnd()" 
        :style="`transform:translate(${item.x+(i==tabIndex||i==tabIndex-1||i==tabIndex+1?moveX:0)}px)`" 
        :class="{trans:(i==tabIndex||i==last)&&touched}">

        <pointer :width="width*.8" :data="item.score" v-if="version=='1.0.0'"/>
        <pointer1_1 :width="width*.8" :data="item.score" v-else/>
        <p class="warn" v-if="item.star==2">警示说明：发挥至极致，需警惕失衡带来的风险！</p>
        <div class="star" v-else>
          <span v-for="it in 5" :key="it">
            <img v-if="it<=item.star" src="../../../../static/img/TDA1.png">
            <img v-else src="../../../../static/img/TDA2.png">
          </span>
        </div>
        <div class="text">
          <h1>关于{{item.module_name}}</h1>
          <p>内涵：{{item.define}}</p>
          <p>低分特征：{{item.low_feature}}</p>
          <p>高分特征：{{item.high_feature}}</p>
          <h1>个人建议</h1>
          <p>{{item.advice}}</p>
        </div>

      </li>
    </ul>
  </div>
</template>
<script>
import pointer from '../chart/pointer'
import pointer1_1 from '../chart/pointer1.1'
var _tab, startX = 0, startY = 0, startTop = 0, canMove;
export default {
  components: {pointer, pointer1_1},
  props:{
    exam_id: {default: null},
    hy_session: {default: null},
    version: {default: ''}
  },
  data(){
    return {
      // exam_id: this.$route.query.exam_id || '',
      time: '',
      name: '',
      avatar: require('../../../assets/images/icons/user_icon.png'),

      tabList: [],
      tabIndex: 0,
      last: 0,        //上一个tabindex
      tabMore: false, //是否有更多按钮
      navShow: false, //长导航显隐
      width: 0,       //容器宽度
      scroll: 0,      //1：左右滑，2：上下滑
      moveX: 0,       //左右拖动的x偏移量
      touched: true,  //滑动过渡
    }
  },
  created() {
    document.addEventListener('touchmove', () => {
      if (!canMove) event.preventDefault()
    }, {passive: false})

    this.$indicator.open({text: '加载中...',spinnerType: 'fading-circle'});
    this.$http.get(this.planetUrl+'/Module/getExamReportInfo',{
      params:{exam_id: this.exam_id}
    }).then(e => {
      this.$indicator.close()
      if (e.data.code == 0) {
        //个人信息
        let userInfo = e.data.data.user_info
        this.avatar = userInfo.avatar
        this.name = userInfo.realname || userInfo.nickname
        this.time = userInfo.end_time
        //报告内容
        this.tabList = e.data.data.module_report
        this.$nextTick(() => {
          _tab = $('.tab')
          let w = this.width = _tab[0].clientWidth
          //判断是否有<更多>按钮
          if (_tab[0].scrollWidth>w && this.tabList.length>6)
            this.tabMore = true
          //初始化偏移位置
          for (let i in this.tabList)
            this.tabList[i].x = i == this.tabIndex ? 0 : i < this.tabIndex ? -w : w
        }) 
      } else {
        this.name = '无法加载'
        this.$toast({message: e.data.msg, duration: 2000})
      }
    }).catch((err) => {
      console.error(err)
      this.name = '加载失败'
      this.$toast({message: '网络错误', duration: 1500})
      this.$indicator.close()
    })
  },
  methods: {
    changeTab(next) {
      this.navShow = false
      if (this.tabIndex == next) return
      let pre = this.last = this.tabIndex
      this.tabIndex = next
      
      //左右切换
      this.tabList[next].x = 0
      if (next > pre) {
        for (let i = pre; i < next; i++) this.tabList[i].x = -this.width
      } else {
        for (let i = pre; i > next; i--) this.tabList[i].x = this.width
      }

      //tab跟随滚动
      let {parentElement, offsetLeft, offsetWidth} = document.getElementById('tabItem'+next)
      let c = this.width/2 || parentElement.clientWidth/2
      let ele = _tab || $('.tab')
      ele.animate({scrollLeft: offsetLeft - c + offsetWidth}, 100)
    },
    touchStart() {
      if (this.scroll) return
      startX = event.touches[0].clientX
      startY = event.touches[0].clientY
      startTop = -event.currentTarget.scrollTop
      canMove = true
      this.touched = false
    },
    touchMove() {
      let x = event.touches[0].clientX - startX
      let y = event.touches[0].clientY - startY
      if (this.scroll == 1) {
        event.preventDefault()  //禁止滑动
        if ((this.tabIndex==0 && x>0) || (this.tabIndex+1==this.tabList.length && x<0))
          this.moveX = x / 10
        else
          this.moveX = x
        return
      } else if (this.scroll == 2) {
        let {scrollTop, clientHeight, scrollHeight} = event.currentTarget
        let dy = startTop + y
        if ( dy < 0 && dy > clientHeight-scrollHeight )
          canMove = true
        else
          canMove = false
        return
      }

      if (Math.abs(x) > Math.abs(y)) //左右滑
        this.scroll = 1
      else   //上下滑
        this.scroll = 2
    },
    touchEnd() {
      let mox = this.moveX
      if (Math.abs(mox) > Math.abs(this.width/4)) {
        let n = this.tabIndex + (mox > 0 ? -1 : 1)
        if (n >= 0 && n < this.tabList.length) this.changeTab(n)
      }
      this.touched = true
      this.moveX = 0
      this.scroll = 0
    },
  },
}
</script>
<style lang="scss" scoped>
.report{
  box-shadow: 0 0 2px 0 rgba(200,200,200,.5);
  user-select: none;
  height: 100%;
  background: radial-gradient(at left bottom, #e1f4fa, #c3e3fd);
}
header{
  padding: 24px;
  background: #6fccfc url('../../../../static/img/TDA0.jpg') no-repeat;
  background-size: 100%;
  display: flex;
  align-items: center;
  font-size: 28px;
  text-align: left;
  color: #fff;
    img{
      width: 80px;
      height: 80px;
      border-radius: 50%;
      overflow: hidden;
      margin-right: 20px;
    }
    b{font-size: 30px;}
    .time{
      font-size: 24px;
      margin-top: 4px;
      span{
        display: inline-block;
        margin-left: 40px;
      }
    }
}

.tabBox{
  position: relative;
  z-index: 10;
  margin: 20px 24px 0;
  .tab{
    height: 72px;
    overflow-x: auto;
    overflow-y: hidden;
    display: flex;
    flex-wrap: nowrap;
    position: relative;
    scrollbar-width: none; /* firefox */
    -ms-overflow-style: none; /* IE 10+ */
    &::-webkit-scrollbar{display: none} /* Chrome Safari */
    li{
      padding: 0 36px;
      background: #e0f4ff;
      color: #a3b3cc;
      line-height: 50px;
      height: 50px;
      font-size: 28px;
      white-space: nowrap;
      box-sizing: content-box;
      position: relative;
      border-radius: 10px;
      margin-right: 20px;
      box-shadow: 0 0 8px rgba(200,200,200,.1);
    }
    .active{
      z-index: 11;
      color: #fff;
      background: #61c6fb;
      &::after{
        content: '';
        position: absolute;
        height: 60px;
        left: 50%;
        transform: translateX(-50%);
        top: 100%;
        border-style: solid;
        border-width: 12px 12px 0 12px;
        border-color: #61c6fb transparent transparent transparent;
        filter: drop-shadow(0px 2px 1px rgba(200,200,200,.1));
      }
    }
  }
  .nav{display: none}
}
.tabMore{
  padding-right: 60px;
  .nav{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 60px;
    height: 50px;
    position: absolute;
    z-index: 12;
    right: 0px;
    top: 0;
    span{
      padding: 6px 15px;
      position: relative;
      z-index: 2;
      i{
        display: block;
        width: 30px;
        height: 4px;
        background: #333;
        border-radius: 3px;
        margin: 6px 0;
        transition: .3s;
      }
      i:nth-child(2){width: 24px;}
      i:nth-child(3){width: 18px;}
    }
    .open{
      i:nth-child(2){margin-left: 6px}
      i:nth-child(3){margin-left: 12px}
    }
    .mask{
      position: fixed;
      width: 100%;
      height: 100%;
      z-index: 13;
      left: 0;
      top: 0;
    }
    dl{
      position: absolute;
      border-radius: 12px;
      background: #fff;
      right: 0;
      top: 70px;
      z-index: 14;
      transform: scaleY(0);
      transform-origin: center -20px;
      box-shadow: 0 0 10px rgba(200,200,200,.6);
      &::before, &::after{
        content: '';
        position: absolute;
        bottom: 100%;
        right: 18px;
        border-style: solid;
        border-width: 0 12px 16px 12px;
        border-color: transparent transparent #fff transparent;
      }
      &::before{filter: drop-shadow(0px -2px 2px rgba(200,200,200,.4));}
      dd{
        text-align: left;
        font-size: 28px;
        white-space: nowrap;
        line-height: 64px;
        height: 64px;
        padding: 0 40px;
        position: relative;
        &:first-child{
          border-top-left-radius: 16px;
          border-top-right-radius: 16px;
        }
        &:last-child{
          border-bottom-left-radius: 16px;
          border-bottom-right-radius: 16px;
        }
      }
      .curr{
        background: #ebf8ff;
        &::after{
          content: '';
          position: absolute;
          width: 20px;
          height: 20px;
          right: 10px;
          top: 20px;
          background: url('../../../assets/images/icons/tick1.png') no-repeat;
          background-size: 100%;
        }
      }
    }
    .show{
      transition: .2s;
      transform: scaleY(1);
      dt{display: block}
    }
  }
}

.container{ //ul
  margin: 0 24px;
  background: rgba(255,255,255,.5);
  border-radius: 10px;
  overflow: hidden;
  height: calc(100vh - 250px);
  box-shadow: 0 0 8px rgba(200,200,200,.1);
  position: relative;
  box-sizing: border-box;
  padding: 1px 0;
  .scrollBox{ //li
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    padding: 40px 30px;
    box-sizing: border-box;
    overflow-y: auto;
    overflow-x: hidden;
    scrollbar-width: none; /* firefox */
    -ms-overflow-style: none; /* IE 10+ */
    &::-webkit-scrollbar{display: none} /* Chrome Safari */
  }
  .trans{transition: transform .2s linear;}
  .star{
    margin: 30px 0 60px;
    span{
      display: inline-block;
      padding: 8px;
      border-radius: 50%;
      background: #fff;
      margin: 0 10px;
      box-shadow: 0 2px 8px rgba(169,181,193,.3);
      img{
        display: block;
        width: 42px;
        height: 42px;
      }
    }
  }
  .warn{
    color: #f35f4a;
    line-height: 2;
    margin: 20px 0 60px;
    font-weight: bold;
    font-size: 28px;
  }
  .text{
    text-align: justify;
    font-size: 26px;
    line-height: 1.5;
    h1{
      color: #495763;
      margin-top: 50px;
      font-weight: bold;
    }
    p{
      color: #718699;
    }
  }
  
}
</style>