<template>
  <div class="topSaleReport" @scroll="scroll">
    <header>
      <img :src="avatar">
      <div style="flex:1">
        <div v-if="time"><b>{{name}}</b> 的报告</div>
        <div v-else>{{name}}</div>
        <div class="time flex-b-c">{{$formatTime(time,'y-m-d h:m')}}<span>版本号 v1.0</span></div>
      </div>
    </header>
    <div class="tabBox">
      <ul class="flex-b" :class="{fix:fixed}">
        <li :class="{active:tabIndex==1}" @click="changeTab(1)">综合说明</li>
        <li :class="{active:tabIndex==2}" @click="changeTab(2)">心态管理</li>
        <li :class="{active:tabIndex==3}" @click="changeTab(3)">目标管理</li>
        <li :class="{active:tabIndex==4}" @click="changeTab(4)">拜访活动</li>
        <li :class="{active:tabIndex==5}" @click="changeTab(5)">销售面谈</li>
      </ul>
    </div>
    <!-- 综合说明 -->
    <section v-if="tabIndex==1"><!-- if、show不可随便更改 -->
      <p>{{saleData.description}}</p>
      <sector :data="saleData.score" :refer="81"/>
      <div class="sectorTxt" >{{saleData.level_tips}}</div>
      <barLine :data="saleBar" :animate="true"/>
      <h1>优势领域</h1>
      <p>{{saleData.advantage_content}}</p>
      <h1>改善领域</h1>
      <p class="orange">{{saleData.improve_content.title}}：</p>
      <p v-for="(it,i) in saleData.improve_content.content" :key="i" class="dot"><span class="orange">{{it.name}}：</span>{{it.text}}</p>
      <h1>持续学习方向</h1>
      <p class="orange">{{saleData.learning_content.title}}：</p>
      <p v-for="(it,i) in saleData.learning_content.content" :key="'p'+i" class="dot">{{it}}</p>
    </section>
    <!-- 心态管理 -->
    <section v-show="tabIndex==2">
      <p>{{mindData.description}}</p>
      <singleBar :data="mindData.score" :refer="81" :text="mindData.level_tips"/>

      <h1>心态管理的作用</h1>
      <p class="orange">心态管理包含：</p>
      <p>①面对销售工作的积极心态、②面对突发状况的灵活应变、③对销售工作难度的接受心态、④面对逆境时抵御压力的心态。</p>
      <div v-for="(item,i) in mindData.useful" :key="'p2'+i" :class="{boxOpen:item.opened, boxWhite:bgIndex===i}" @click="open('mindData.useful', i)" @touchstart="touch('mindData.useful', i)">
        <p class="orange">{{no[i]}}{{item.title}}</p>
        <p v-for="(it,j) in item.content" :key="'p3'+j" class="dot" v-show="j==0||item.opened">
          <span class="orange">{{it.name}}：</span>{{it.text}}
          <label v-if="j==0">展开全部</label>
        </p>
      </div>

      <h1>改善建议</h1> 
      <div :class="{boxOpen:mindData.mindData_best, boxWhite:bgIndex=='mindData_best'}" @click="open('mindData', 'mindData_best')" @touchstart="touch('mindData', 'mindData_best')">
        <p class="orange">最佳行动建议：</p>
        <p v-for="(it,i) in mindData.best_suggestion" :key="'p4'+i" class="dot" v-show="i==0||mindData.mindData_best">
          {{it}}<label v-if="i==0">展开全部</label>
        </p>
      </div>
      <div class="box3p" :class="{boxOpen:mindData.mindData_improve, boxWhite:bgIndex=='mindData_improve'}" @click="open('mindData', 'mindData_improve')" @touchstart="touch('mindData', 'mindData_improve')">
        <p class="orange">改善方向：</p>
        <div v-for="(item,i) in mindData.improve_suggestion" :key="i">
          <p class="orange" v-show="i==0||mindData.mindData_improve">{{no[i]}}{{item.title}}</p>
          <p v-for="(it,j) in item.content" :key="'p6'+j+i" class="dot" v-show="(i==0&&j==0) || mindData.mindData_improve">
            <span class="orange">{{it.name}}：</span>{{it.text}}
            <label v-if="j==0">展开全部</label>
          </p>
        </div>
      </div>

      <h1>BEE的作用<br>(信念、热情、共情)</h1>
      <div class="box1p" :class="{boxOpen:mindData.mindData_bee, boxWhite:bgIndex=='mindData_bee'}" @click="open('mindData', 'mindData_bee')" @touchstart="touch('mindData', 'mindData_bee')">
        <p v-for="(it,i) in mindData.bee" :key="'p7'+i" class="dot" v-show="i==0||mindData.mindData_bee">
          {{it}}<label v-if="i==0">展开全部</label>
        </p>
      </div>
    </section>
    <!-- 目标管理 -->
    <section v-show="tabIndex==3">
      <p>{{goalData.description}}</p>
      <singleBar :data="goalData.score" :refer="83" :text="goalData.level_tips"/>
      
      <h1>目标管理的作用</h1>
      <p class="orange">目标管理包含：</p>
      <p>①目标驱动、②对每个阶段的重视度、③对达成目标的规划仔细度、④保证成果的心态和动作。</p>
      <div v-for="(item,i) in goalData.useful" :key="'p2'+i" :class="{boxOpen:item.opened, boxWhite:bgIndex===i}" @click="open('goalData.useful', i)" @touchstart="touch('goalData.useful', i)">
        <p class="orange">{{no[i]}}{{item.title}}</p>
        <p v-for="(it,j) in item.content" :key="'p3'+j" class="dot" v-show="j==0||item.opened">
          <span class="orange">{{it.name}}：</span>{{it.text}}
          <label v-if="j==0">展开全部</label>
        </p>
      </div>

      <h1>改善建议</h1>
      <div :class="{boxOpen:goalData.goalData_best, boxWhite:bgIndex=='goalData_best'}" @click="open('goalData', 'goalData_best')" @touchstart="touch('goalData', 'goalData_best')">
        <p class="orange">最佳行动建议：</p>
        <p v-for="(it,i) in goalData.best_suggestion" :key="'p4'+i" class="dot" v-show="i==0||goalData.goalData_best">
          {{it}}<label v-if="i==0">展开全部</label>
        </p>
      </div>
      <div class="box3p" :class="{boxOpen:goalData.goalData_improve, boxWhite:bgIndex=='goalData_improve'}" @click="open('goalData', 'goalData_improve')" @touchstart="touch('goalData', 'goalData_improve')">
        <p class="orange">改善方向：</p>
        <div v-for="(item,i) in goalData.improve_suggestion" :key="i">
          <p class="orange" v-show="i==0||goalData.goalData_improve">{{no[i]}}{{item.title}}</p>
          <p v-for="(it,j) in item.content" :key="'p6'+j+i" class="dot" v-show="(i==0&&j==0) || goalData.goalData_improve">
            <span class="orange" v-if="it.name">{{it.name}}：</span>{{it.text}}
            <label v-if="j==0">展开全部</label>
          </p>
        </div>
      </div>
      
      <h1>BEE的作用<br>(信念、热情、共情)</h1>
      <div class="box1p" :class="{boxOpen:goalData.goalData_bee, boxWhite:bgIndex=='goalData_bee'}" @click="open('goalData', 'goalData_bee')" @touchstart="touch('goalData', 'goalData_bee')">
        <p v-for="(it,i) in goalData.bee" :key="'p7'+i" class="dot" v-show="i==0||goalData.goalData_bee">
          {{it}}<label v-if="i==0">展开全部</label>
        </p>
      </div>
    </section>
    <!-- 拜访活动 -->
    <section v-show="tabIndex==4">
      <p>{{meetData.description}}</p>
      <singleBar :data="meetData.score" :refer="83" :text="meetData.level_tips"/>

      <h1>拜访活动的作用</h1>
      <p class="orange">拜访活动包含：</p>
      <p>①拜访行程的规划（合理、有序）、②拜访工作前/中段的准备程度、③对自我的管理（行程安排/拜访时间/客户资料收集等）、④对拜访活动的期待程度。</p>
      <div v-for="(item,i) in meetData.useful" :key="'p2'+i" :class="{boxOpen:item.opened, boxWhite:bgIndex===i}" @click="open('meetData.useful', i)" @touchstart="touch('meetData.useful', i)">
        <p class="orange">{{no[i]}}{{item.title}}</p>
        <p v-for="(it,j) in item.content" :key="'p3'+j" class="dot" v-show="j==0||item.opened">
          <span class="orange">{{it.name}}：</span>{{it.text}}
          <label v-if="j==0">展开全部</label>
        </p>
      </div>

      <h1>改善建议</h1>
      <div :class="{boxOpen:meetData.meetData_best, boxWhite:bgIndex=='meetData_best'}" @click="open('meetData', 'meetData_best')" @touchstart="touch('meetData', 'meetData_best')">
        <p class="orange">最佳行动建议：</p>
        <p v-for="(it,i) in meetData.best_suggestion" :key="'p4'+i" class="dot" v-show="i==0||meetData.meetData_best">
          {{it}}<label v-if="i==0">展开全部</label>
        </p>
      </div>
      <div class="box3p" :class="{boxOpen:meetData.meetData_improve, boxWhite:bgIndex=='meetData_improve'}" @click="open('meetData', 'meetData_improve')" @touchstart="touch('meetData', 'meetData_improve')">
        <p class="orange">改善方向：</p>
        <div v-for="(item,i) in meetData.improve_suggestion" :key="i">
          <p class="orange" v-show="i==0||meetData.meetData_improve">{{no[i]}}{{item.title}}</p>
          <p v-for="(it,j) in item.content" :key="'p6'+j+i" class="dot" v-show="(i==0&&j==0) || meetData.meetData_improve">
            <span class="orange" v-if="it.name">{{it.name}}：</span>{{it.text}}
            <label v-if="j==0">展开全部</label>
          </p>
        </div>
      </div>
      
      <h1>BEE的作用<br>(信念、热情、共情)</h1>
      <div class="box1p" :class="{boxOpen:meetData.meetData_bee, boxWhite:bgIndex=='meetData_bee'}" @click="open('meetData', 'meetData_bee')" @touchstart="touch('meetData', 'meetData_bee')">
        <p v-for="(it,i) in meetData.bee" :key="'p7'+i" class="dot" v-show="i==0||meetData.meetData_bee">
          {{it}}<label v-if="i==0">展开全部</label>
        </p>
      </div>
    </section>
    <!-- 销售面谈 -->
    <section v-show="tabIndex==5">
      <p>{{talkData.description}}</p>
      <singleBar :data="talkData.score" :refer="77" :text="talkData.level_tips"/>
      
      <h1>销售面谈的作用</h1>
      <p class="orange">销售面谈包含：</p>
      <p>①产品的熟悉度（知识/功能/利益等）、②说明技巧/话术、③与客户面谈时间的合理度、④和客户良好互动的程度、⑤对客户问题的理解度、⑥把握局面的能力。</p>
      <div v-for="(item,i) in talkData.useful" :key="'p2'+i" :class="{boxOpen:item.opened, boxWhite:bgIndex===i}" @click="open('talkData.useful', i)" @touchstart="touch('talkData.useful', i)">
        <p class="orange">{{no[i]}}{{item.title}}</p>
        <p v-for="(it,j) in item.content" :key="'p3'+j" class="dot" v-show="j==0||item.opened">
          <span class="orange">{{it.name}}：</span>{{it.text}}
          <label v-if="j==0">展开全部</label>
        </p>
      </div>

      <h1>改善建议</h1>
      <div :class="{boxOpen:talkData.talkData_best, boxWhite:bgIndex=='talkData_best'}" @click="open('talkData', 'talkData_best')" @touchstart="touch('talkData', 'talkData_best')">
        <p class="orange">最佳行动建议：</p>
        <p v-for="(it,i) in talkData.best_suggestion" :key="'p4'+i" class="dot" v-show="i==0||talkData.talkData_best">
          {{it}}<label v-if="i==0">展开全部</label>
        </p>
      </div>
      <div class="box3p" :class="{boxOpen:talkData.talkData_improve, boxWhite:bgIndex=='talkData_improve'}" @click="open('talkData', 'talkData_improve')" @touchstart="touch('talkData', 'talkData_improve')">
        <p class="orange">改善方向：</p>
        <div v-for="(item,i) in talkData.improve_suggestion" :key="i">
          <p class="orange" v-show="i==0||talkData.talkData_improve">{{no[i]}}{{item.title}}</p>
          <p v-for="(it,j) in item.content" :key="'p6'+j+i" class="dot" v-show="(i==0&&j==0) || talkData.talkData_improve">
            <span class="orange" v-if="it.name">{{it.name}}：</span>{{it.text}}
            <label v-if="j==0">展开全部</label>
          </p>
        </div>
      </div>
      
      <h1>BEE的作用<br>(信念、热情、共情)</h1>
      <div class="box1p" :class="{boxOpen:talkData.talkData_bee, boxWhite:bgIndex=='talkData_bee'}" @click="open('talkData', 'talkData_bee')" @touchstart="touch('talkData', 'talkData_bee')">
        <p v-for="(it,i) in talkData.bee" :key="'p7'+i" class="dot" v-show="i==0||talkData.talkData_bee">
          {{it}}<label v-if="i==0">展开全部</label>
        </p>
      </div>
    </section>
  </div>
</template>
<script>
import sector from './chart/sector'
import barLine from './chart/barLine'
import singleBar from './chart/singleBar'
var _tab, startX = 0, startY = 0, startTop = 0, canMove;
export default {
  components: {sector,barLine,singleBar},
  props:{
    exam_id: {default: null},
    hy_session: {default: null},
  },
  data(){
    return {
      // exam_id: this.$route.query.exam_id || '3762',
      time: '',
      name: '',
      avatar: require('../../../assets/images/icons/user_icon.png'),

      tabIndex: 1,
      tabTop: 0,
      fixed: false,
      bgIndex: '',  //白色背景显示(数字/字符)
      no: ['①','②','③','④','⑤','⑥','⑦','⑧','⑨','⑩','⑪','⑫','⑬','⑭','⑮'],

      saleData: {
        description: '',
        score: 0,
        level_tips: '',
        advantage_content: '',
        improve_content: {title:'',content:[]},
        learning_content: {title:'',content:[]},
      },
      saleBar: [],  //综合说明横向条形图
      mindData: {
        description: '',        //描述
        score: 0,               //分数
        level_tips: '',         //等级文字
        useful: [],             //作用
        best_suggestion: [],    //最佳行动建议
        improve_suggestion: [], //改善方向
        bee: [],
      },
      goalData: {
        description: '',        //描述
        score: 0,               //分数
        level_tips: '',         //等级文字
        useful: [],             //作用
        best_suggestion: [],    //最佳行动建议
        improve_suggestion: [], //改善方向
        bee: [],
      },
      meetData: {
        description: '',        //描述
        score: 0,               //分数
        level_tips: '',         //等级文字
        useful: [],             //作用
        best_suggestion: [],    //最佳行动建议
        improve_suggestion: [], //改善方向
        bee: [],
      },
      talkData: {
        description: '',        //描述
        score: 0,               //分数
        level_tips: '',         //等级文字
        useful: [],             //作用
        best_suggestion: [],    //最佳行动建议
        improve_suggestion: [], //改善方向
        bee: [],
      },
    }
  },
  created() {
    this.$indicator.open({text: '加载中...',spinnerType: 'fading-circle'});
    this.$http.get(this.ajaxUrlPath+'/TopSale/getExamReportInfo',{
      params:{exam_id: this.exam_id}
    }).then(e => {
      this.$indicator.close()
      if (e.data.code == 0) {
        //个人信息
        let data = e.data.data
        this.avatar = data.user_info.avatar
        this.name = data.user_info.realname
        this.time = data.user_info.end_time

        this.saleData = data.sale_data
        this.saleBar = [
          {name: data.mind_data.name, score: data.mind_data.score, refer: 79},
          {name: data.goal_data.name, score: data.goal_data.score, refer: 83},
          {name: data.meet_data.name, score: data.meet_data.score, refer: 83},
          {name: data.talk_data.name, score: data.talk_data.score, refer: 77},
        ]
        this.mindData = data.mind_data
        this.goalData = data.goal_data
        this.meetData = data.meet_data
        this.talkData = data.talk_data
      } else {
        this.name = '无法加载'
        this.$toast({message: e.data.msg, duration: 2000})
      }
    }).catch((err) => {
      console.error(err)
      this.name = '加载失败'
      this.$toast({message: '网络错误', duration: 1500})
      this.$indicator.close()
    })
  },
  mounted(){
    let top = this.tabTop = $(".tabBox").offset().top
    // window.addEventListener("scroll", () => {
    //   if ($(document).scrollTop() > top) {
    //     console.log(1)
    //     this.fixed = true
    //   } else {
    //     console.log(0)
    //     this.fixed = false
    //   }
		// })
  },
  methods: {
    changeTab(next) {
      if (this.tabIndex == next) return
      if (this.fixed) this.$el.scrollTop = this.tabTop  //滚动到每页顶部
      this.tabIndex = next
      this.bgIndex = ''
    },
    open(s, i) {
      if (this.bgIndex === i) return
      this.bgIndex = i
      let key = s.split('.')
      if (key[1]) {
        this.$set(this[key[0]][key[1]][i], 'opened', true)
      } else {
        this.$set(this[key[0]], i, true)
      }
    },
    touch(s, i) {
      if (this.bgIndex === i) return
      let key = s.split('.')
      if (key[1]) {
        if (this[key[0]][key[1]][i].opened) this.bgIndex = i
      } else {
        if (this[key[0]][i]) this.bgIndex = i
      }
    },
    scroll({srcElement}){
      if (srcElement.scrollTop > this.tabTop) {
        this.fixed = true
      } else {
        this.fixed = false
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.topSaleReport{
  box-shadow: 0 0 2px 0 rgba(200,200,200,.5);
  user-select: none;
  padding-bottom: 1px;
  height: auto;
  background: linear-gradient(to right,#ffd4bf,#ffd8c0);
  position: relative;
  height: 100%;
  overflow-y: auto;
  &::before{
    content: '';
    position: fixed;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
    width: 100%;
    max-width: 800px;
    height: 320px;
    border-bottom-right-radius: 40px;
    background: linear-gradient(#fea51a,#e96118);
  }
  header{
    padding: 40px 30px 10px;
    background-size: 100%;
    display: flex;
    align-items: center;
    font-size: 28px;
    text-align: left;
    color: #fff;
    position: relative;
    z-index: 2;
      img{
        width: 80px;
        height: 80px;
        border-radius: 50%;
        overflow: hidden;
        margin-right: 20px;
      }
      b{font-size: 30px;}
      .time{
        font-size: 26px;
        margin-top: 6px;
      }
  }

  .tabBox{
    position: relative;
    z-index: 10;
    height: 100px;
    ul{
      padding: 24px 24px 20px;
      li{
        position: relative;
        padding: 0 12px;
        background: rgba(255,255,255,.4);
        color: #fff;
        line-height: 54px;
        height: 54px;
        font-size: 26px;
        white-space: nowrap;
        box-sizing: content-box;
        border-radius: 10px;
        box-shadow: 0 0 6px rgba(221, 129, 54, 0.1);
      }
      .active{
        z-index: 11;
        color: #e96118;
        background: #fff2ec;
        &::after{
          content: '';
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          top: 99%;
          border-style: solid;
          border-width: 12px 12px 0 12px;
          border-color: #fff2ec transparent transparent transparent;
          filter: drop-shadow(0px 2px 1px rgba(221, 129, 54, 0.1));
        }
      }
    }
    .fix{
      position: fixed;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 100%;
      max-width: 800px;
      background: linear-gradient(#fea51a,#f88f1a);
    }
  }
  section{
    margin: 0 24px 30px;
    padding: 30px 0;
    background: #fff2ec;
    border-radius: 20px;
    box-shadow: 0 0 10px rgba(200,200,200,.1);
    position: relative;
    z-index: 2;
    animation: fadeIn .2s;
    min-height: 90vh;
    p{
      padding: 10px 30px;
      font-size: 26px;
      color: #635349;
      text-align: justify;
      line-height: 1.5;
      overflow: hidden;
    }
    h1{
      margin: 70px 0 30px;
      color: #e96118;
      font-size: 28px;
      font-weight: bold;
    }
    .sectorTxt{
      color: #936e52;
      font-size: 26px;
      margin: 20px 30px 80px;
    }
    .orange{color: #e96118}
    .dot{
      padding-left: 54px;
      position: relative;
      &::before{
        content: '';
        position: absolute;
        width: 8px;
        height: 8px;
        left: 36px;
        top: 22px;
        border-radius: 50%;
        background: #e96118;
      }
    }
    label{
      color: #b6b1ae;
      display: inline-block;
      font-size: 24px;
      position: relative;
      &::after{
        content: '';
        position: absolute;
        top: 14px;
        right: -24px;
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        border-top: 8px solid #d1cbc7;
      }
    }
    .boxOpen{
      label{display: none}
      p:nth-of-type(n+3){animation: expand .8s forwards;}
    }
    .boxOpen.boxWhite{
      transition: .6s;
      background: #fff;
      box-shadow: inset 0 0 8px rgba(200,200,200,.5);
    }
    @keyframes expand {
      0%{max-height: 0;padding-top: 0;padding-bottom: 0;}
      100%{max-height: 160px;padding-top: 10px;padding-bottom: 10px;}
    }
    .box3p p:nth-child(3){animation: expand 0s forwards;}
    .box1p p:nth-child(2){animation: expand .8s forwards;}
  }
  @keyframes fadeIn {
    0%{transform: translateY(100px);opacity: 0;}
    100%{transform: translateY(0%);opacity: 1;}
  }
  .container{ //ul
    margin: 0 24px;
    background: #fff;
    border-radius: 10px;
    overflow: hidden;
    height: calc(100vh - 250px);
    box-shadow: 0 0 8px rgba(200,200,200,.1);
    position: relative;
    box-sizing: border-box;
    padding: 1px 0;
    .scrollBox{ //li
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      padding: 40px 30px;
      box-sizing: border-box;
      overflow-y: auto;
      overflow-x: hidden;
      scrollbar-width: none; /* firefox */
      -ms-overflow-style: none; /* IE 10+ */
      &::-webkit-scrollbar{display: none} /* Chrome Safari */
    }
    .trans{transition: transform .2s linear;}
    .star{
      margin: 30px 0 60px;
      span{
        display: inline-block;
        padding: 8px;
        border-radius: 50%;
        background: #fff;
        margin: 0 10px;
        box-shadow: 0 2px 8px rgba(169,181,193,.3);
        img{
          display: block;
          width: 42px;
          height: 42px;
        }
      }
    }
    .warn{
      color: #f35f4a;
      line-height: 2;
      margin: 20px 0 60px;
      font-weight: bold;
      font-size: 28px;
    }
    .text{
      text-align: justify;
      font-size: 26px;
      line-height: 1.5;
      h1{
        color: #495763;
        margin-top: 50px;
        font-weight: bold;
      }
      p{
        color: #718699;
      }
    }
    
  }
}
</style>