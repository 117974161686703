<template>
  <div class="card">
    <header :style="'background-image:url('+styleImg+')'">
      <div class="flex--c">
        <img class="avatar" :src="avatar||'../../../static/img/icon_head.png'">
        <div>
          <div class="name">{{realname||'未编辑'}}</div>
          <div>{{job||'未编辑'}}</div>
        </div>
      </div>
      <div class="flex marginTop">
        <img class="icon" src="../../../static/img/planet_card12.png"/>
        <div class="text">{{phone||'未编辑'}}</div>
      </div>
      <div class="flex">
        <img class="icon" src="../../../static/img/planet_card13.png"/>
        <div class="text">{{email||'未编辑'}}</div>
      </div>
      <div class="flex">
        <img class="icon" src="../../../static/img/planet_card11.png"/>
        <div class="text">{{company||'未编辑'}}</div>
      </div>
      <div class="flex">
        <img class="icon" src="../../../static/img/planet_card14.png"/>
        <div class="text">{{address||'未编辑'}}</div>
      </div>
      <img class="logo" :src="logo" v-if="logo"/>
    </header>

    <img class="ewm" :src="qrcode"/>
    <h6>长按或识别小程序码查看更多</h6>
  </div>
</template>
<script>
export default {
  data(){
    return {
      //地址中获取session和card_id
      qrcode: '',
      styleImg: '',
      avatar: '',
      realname: '',
      job: '',
      logo: '',
      company: '',
      phone: '',
      email: '',
      address: '',
    }
  },
  created(){
    let {card_id='', hy_session=''} = this.$route.query
    this.$http.post(this.planetUrl+'/BusinessCard/getCardInfo',{
      is_self: 0,
      card_id,
    }).then(({data}) => {
      if (data.code === 0) {
        let d = data.data
        this.styleImg = d.skin_url
        this.avatar = d.avatar
        this.realname = d.realname
        this.job = d.position
        this.logo = d.company_logo
        this.company = d.company_name
        this.phone = d.mobile
        this.email = d.email
        this.address = d.address
      } else {
        this.realname = '出错了'
        this.$toast({message: data.msg, duration: 2000})
      }
    }).catch(() => {
      this.name = '加载失败'
      this.$toast({message: '网络错误', duration: 1500})
    })
    //获取小程序码
    this.$http.post(this.planetUrl+'/BusinessCard/getMiniQrcode', {
      hy_session,
      card_id,
    }).then(({data}) => {
      if (data.code === 0) {
        this.qrcode = data.data.qrcode_url
      } else {
        this.$toast({message: data.msg, duration: 2000})
      }
    })
  },
  mounted(){},
  methods:{},
}
</script>
<style lang="scss" scoped>
.card{
  padding-bottom: 100px;
  header{
    height: 600px;
    box-sizing: border-box;
    padding: 60px 30px 0;
    background: #f5f5f5;
    background-repeat: no-repeat;
    background-size: 100% 100%;
    font-size: 26px;
    color: #1d212d;
    position: relative;
    text-align: left;
  }
  .avatar{
    width: 90px;
    height: 90px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 20px;
    box-shadow: 0 0 8px rgba(200,200,200,.4);
  }
  .name{
    font-weight: bold;
    font-size: 32px;
    line-height: 1.5;
  }
  .marginTop{margin-top: 80px}
  .logo{
    position: absolute;
    width: 160px;
    height: 160px;
    right: 36px;
    bottom: 160px;
    border-radius: 8px;
    box-shadow: 10px 10px 0 rgba(255,255,255,.4);
  }
  .icon{
    margin: 10px 12px 10px 30px;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: #1d212d;
    flex-shrink: 0;
  }
  .text{
    margin-top: 12px;
    max-width: 420px;
  }
  .ewm{
    width: 300px;
    display: block;
    margin: 60px auto 40px;
  }
  h6{
    line-height: 30px;
    font-size: 24px;
    color: #333
  }
}
</style>