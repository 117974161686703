<template>
  <div class="bar-row_1_2" :class="'bar'+colorType">

    <div class="text">
      <div v-for="item in data" :key="item.id">{{item.name}}</div>
    </div>
    <div class="content">
      <div class="box">
        <div>
          <span @click="toggle('亟需改进')">亟需<br>改进</span>
        </div>
        <div>
          <span @click="toggle('达到预期')">达到<br>预期</span>
        </div>
        <div>
          <span @click="toggle('表现良好')">表现<br>良好</span>
        </div>
        <div>
          <span @click="toggle('表现优秀')">表现<br>优秀</span>
        </div>
        <div>
          <span @click="toggle('卓越警示')">卓越<br>警示</span>
        </div>
      </div>
      <div class="bar-box">
        <div class="bar" v-for="(item,index) in data" :key="index" :style="{width:setWidth(item.score)}">
          <i>{{Math.round(item.score)}}</i>
        </div>
      </div>
    </div>

    <p v-if="levelTxt">点击上方区域名称可查看对应的区域说明</p>

    <div class="tip" v-show="showIndex!=null">
      <h6>{{levelTxt[showIndex]}}</h6>
      <div class="close" @click="toggle(null)">关 闭</div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Array,
    },
    colorType: {
      type: Number,
      default: 0
    },
    // click: {
    //   type: Boolean,
    //   default: false
    // },
    levelTxt: {
      default: ''
    }
  },
  data(){
    return{
      range: [30, 40, 50, 60, 80, 90],  //25~85
      showIndex: null,
      show: true,
    }
  },
  methods:{
    setWidth(num){
      let result = 0
      num = Math.round(num)
      // if (num < this.range[1]) {
      //   result = (num - this.range[0]) / (this.range[1] - this.range[0]) * .2 
      // } else if (num < this.range[2]) {
      //   result = (num - this.range[1]) / (this.range[2] - this.range[1]) * .2  + .2
      // } else if (num < this.range[3]) {
      //   result = (num - this.range[2]) / (this.range[3] - this.range[2]) * .2  + .4
      // } else if (num < this.range[4]) {
      //   result = (num - this.range[3]) / (this.range[4] - this.range[3]) * .2  + .6
      // } else {
      //   result = (num - this.range[4]) / (this.range[5] - this.range[4]) * .2  + .8
      // }
      for (let i = 0; i < this.range.length-1; i++) {
        if (num < this.range[i+1]) {
          result = (num - this.range[i]) / (this.range[i+1] - this.range[i]) * .2 + .2*i
          break
        }
      }
      return result * 100 + '%'
    },
    toggle(e){
      if (this.levelTxt) this.showIndex = e
    }
  },
}
</script>
<style lang="scss" scope>
.bar-row_1_2{
  display: flex;
  font-size: 24px;
  margin: 140px 0 180px 0;
  position: relative;
  border-radius: 10px;
  .text{
    width: 114px;
    height: 100%;
    font-size: 24px;
    flex-shrink: 0;
    padding: 1px 0;
    background: #f5fbff;
    border-top-left-radius: 6px;
    border-bottom-left-radius: 6px;
    div{
      line-height: 40px;
      height: 38px;
      margin: 28px 0;
    }
    div:nth-of-type(odd){color: #42b2fc;}
    div:nth-of-type(even){color: #7886f0;}
  }
  .content{
    flex: 1;
    background: #f5fbff;
    position: relative;
    border-top-right-radius: 6px;
    border-bottom-right-radius: 6px;
    .box{
      height: 100%;
      width: 100%;
      display: flex;
      left: 0;
      top: 0;
      position: absolute;
      div{
        flex: 1;
        height: 100%;
        position: relative;
        color: #738897;
        border-right: 1px dashed #b4b4b4;
        box-sizing: border-box;
        span{
          position: absolute;
          bottom: 102%;
          left: 0;
          line-height: 1.2;
          width: 100%;
        }
        i{
          position: absolute;
          bottom: -40px;
          right: -12px;
        }
        &:nth-child(1){color: #ed571c;background: #ffeaea}
        &:nth-child(5){
          color: #ed571c;
          background: #ffeaea;
          border-top-right-radius: 6px;
          border-bottom-right-radius: 6px;
          border: none;
          position: relative;
          &::after{
            content: '避免出现极端现象和行为';
            position: absolute;
            left: 50%;
            transform: translateX(-54%);
            top: 102%;
            font-size: 22px;
            width: 140px;
          }
        }
      }
    }
    .bar-box{
      position: relative;
      z-index: 2;
      height: 100%;
      padding: 1px 0;
      .bar{
        height: 38px;
        line-height: 40px;
        margin: 28px 0;
        width: 0%;
        text-align: right;
        color: #fff;
        padding-right: 10px;
        animation: width .6s forwards;
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
        &:nth-of-type(odd){
          background: #42b2fc;
        }
        &:nth-of-type(even){
          background: #7886f0;
        }
        i{
          animation: fade .6s forwards;
          opacity: 0;
        }
      }
    }
  }

  p{
    position: absolute;
    width: 100%;
    bottom: -90px;
    left: 0;
    font-size: 22px;
  }
}
.bar1{
  .text{
    div:nth-child(1){color: #51b8fb}
    div:nth-child(2){color: #28c8b7}
    div:nth-child(3){color: #4c88f3}
    div:nth-child(4){color: #ed7d31}
    div:nth-child(5){color: #6c7bf1}
    div:nth-child(6){color: #e96842}
  }
  .content .bar-box{
    .bar:nth-child(1){background: #51b8fb}
    .bar:nth-child(2){background: #28c8b7}
    .bar:nth-child(3){background: #4c88f3}
    .bar:nth-child(4){background: #ed7d31}
    .bar:nth-child(5){background: #6c7bf1}
    .bar:nth-child(6){background: #e96842}
  }
}
.bar2{
  .text{
    div:nth-child(1){color: #7a91fa}
    div:nth-child(2){color: #ecb41c}
    div:nth-child(3){color: #f25768}
    div:nth-child(4){color: #bb3fef}
    div:nth-child(5){color: #ed7d31}
    div:nth-child(6){color: #ff5d68}
  }
  .content .bar-box{
    .bar:nth-child(1){background: #7a91fa}
    .bar:nth-child(2){background: #ecb41c}
    .bar:nth-child(3){background: #f25768}
    .bar:nth-child(4){background: #bb3fef}
    .bar:nth-child(5){background: #ed7d31}
    .bar:nth-child(6){background: #ff5d68}
  }
}

.tip{
  position: absolute;
  width: 540px;padding: 30px 40px 10px;
  background: #fff;border-radius: 10px;
  left: 50%;top: 50%;transform: translate(-50%,-50%);
  box-shadow: 0 0 6px 2px rgba(200,200,200,.3);
  animation: dimFade .3s;
  z-index: 3;
  h5{
    font-size: 28px;
    color: #333;
    line-height: 2;
  }
  h6{
    text-align: justify;
    font-size: 24px;
  }
  .close{
    width: 100px;
    color: #42b2fc;
    height:40px;
    display: flex;
    align-items: center; 
    justify-content: center;
    margin: 20px auto 10px;
    border-radius: 6px;
  }
}

@keyframes width {
  0%{width: 0;}
}
@keyframes fade {
  0%{opacity: 0;}
  100%{opacity: 1;}
}
</style>
