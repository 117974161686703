<template>
  <!--报告-->
  <personal_1_0 v-if="version=='1.0.0'" :version="version" :hy_session="hy_session" :exam_id="exam_id"/>
  <personal_1_1 v-else-if="version=='1.1.0'" :version="version" :hy_session="hy_session" :exam_id="exam_id"/>
  <personal_1_2 v-else-if="version=='1.2.0'" :version="version" :hy_session="hy_session" :exam_id="exam_id"/>
  <personal_1_3 v-else :version="version" :hy_session="hy_session" :exam_id="exam_id"/>
</template>

<script>
import personal_1_0 from './personal1.0.vue'
import personal_1_1 from './personal1.1.vue'
import personal_1_2 from './personal1.2.vue'
import personal_1_3 from './personal1.3.vue'
export default {
  components:{personal_1_0, personal_1_1, personal_1_2, personal_1_3},
  data(){
    return{
      exam_id: this.$route.params.exam_id || '',
      hy_session: this.$route.params.hy_session || '',
      version: this.$route.query.ver || '',
    }
  },
  mounted(){
    if (!this.version) //网页浏览
      this.$http.get(this.spaceUrl+`/Assess/getReportVersion?exam_id=${this.exam_id}`).then(({data}) => {
        if (data.code == 0) {
          this.version = data.data.version
        } else {
          this.$toast({message:'无法加载',duration: 2000})
          console.error(data.msg)
        }
      })

  },
}
</script>
