<template>
  <div class="beeIndex" ref="body">
    <mt-swipe :show-indicators="false" :auto="5000">
      <mt-swipe-item v-for="(item,i) in banner" :key="i">
        <!-- <img src="../../../assets/images/bee/bee_banner1.jpg"> -->
        <img :src="item.imgurl" @click="toHTML(i)">
      </mt-swipe-item>
    </mt-swipe>
    <div class="coach" @click="tolink('consult')">
      <h4>幸福教练</h4>
      <h5>爱的倾听·专业陪跑</h5>
      <h6>立即前往</h6>
    </div>
    <div class="grid">
      <div @click="tolink('course')">
        <h1>人文课堂</h1>
        <h2>以心唤心 用爱化人</h2>
      </div>
      <div @click="tolink('community')">
        <h1>青鸟幸福社区 </h1>
        <h2>共生·共荣·共享</h2>
      </div>
      <div @click="$toast({message:'敬请期待'})">
        <h1>测评工具</h1>
        <h2>场景化 多样化</h2>
      </div>
    </div>
    <footer class="flex-a-c">
      <div style="color:#333">
        <img src="../../../assets/images/bee/my_tab2.png">首页
      </div>
      <router-link :to="{path:'/bee/my' }" replace>
        <img src="../../../assets/images/bee/my_tab3.png">我的
      </router-link>
    </footer>
  </div>
</template>
<script>
export default {
  data(){
    return {
      banner: [],
    }
  },
  created(){
    document.title = '青鸟幸福家'
    this.$http.post(this.ajaxUrlPath+'/Bee/getBannerList', {course_id: this.id}).then(({data}) => {
      if (data.code == 0) {
        this.banner = data.data
      }
    })
  },
  mounted(){
    this.$refs.body.addEventListener("touchmove", ()=>{event.preventDefault()}, false)
  },
  methods:{
    // getUserInfo() {
    //   let s = localStorage.getItem("session")
    //   if (s) {
    //     this.$http.post(this.ajaxUrlPath+'/Bee/getUserInfo?hy_session='+s).then(({data}) => {
    //       if (data.code == 0) {
    //       }
    //     })
    //   }
    // },
    tolink(url) {
      this.$router.push({path: '/bee/' + url})
    },
    toHTML(i) {
      sessionStorage.setItem('html', this.banner[i].content)
      this.$router.push({path: '/bee/html'})
    }
  },
}
</script>
<style lang="scss" scoped>
.beeIndex{
  padding: 30px 0 100px;
  height: 100%;
  background: #f6f6f6;
  .mint-swipe{
    height: 250px;
    margin: 0 30px;
    border-radius: 10px;
    img{width: 100%}
    .mint-swipe-item:nth-child(1){
      background: linear-gradient(90deg, #1ce3d3, #12bab4);
    }
    .mint-swipe-item:nth-child(2){
      background: linear-gradient(90deg, #807aff, #be7dfc);
    }
    .mint-swipe-item:nth-child(3){
      background: linear-gradient(90deg, #f8ab73, #f48569);
    }
  }
  .coach{
    height: 180px;
    border-radius: 10px;
    margin: 30px;
    letter-spacing: 4px;
    background: linear-gradient(90deg, #d3ddf7, #a6bceb);
    position: relative;
    h4{
      font-size: 36px;
      text-align: left;
      color: #467de0;
      text-shadow: 0 6px 6px rgba(58,117,222,.4);
      padding-top: 40px;
      margin-left: 30px;
      font-weight: bold;
    }
    h5{
      font-size: 30px;
      text-align: left;
      color: #fff;
      text-shadow: 0 6px 6px rgba(58,117,222,.4);
      margin-left: 30px;
      line-height: 1.8;
    }
    h6{
      line-height: 54px;
      font-size: 26px;
      width: 170px;
      border-radius: 36px;
      background: #ffa000;
      color: #fff;
      position: absolute;
      right: 30px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  .grid{
    display: grid;
    gap: 30px;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    height: 400px;
    margin: 30px 30px 80px;
    div{
      border-radius: 10px;
      color: #fff;
      position: relative;
      text-align: left;
      padding: 24px 0 0 30px;
      h1{
        font-size: 32px;
      }
      h2{
        font-size: 24px;
        margin-top: 8px;
      }
      &:after{
        content: '';
        position: absolute;
        right: 30px;
        bottom: 30px;
        width: 64px;
        height: 64px;
        background-repeat: no-repeat;
        background-size: 100% 100%;
      }
    }
    div:nth-child(1){
      grid-column: 1 / 2;
      grid-row: 1 / 3;
      background: linear-gradient(90deg, #1ce3d3, #12bab4);
      &:after{
        background-image: url('../../../assets/images/bee/index_icon1.png');
        width: 108px;
        height: 108px;
        bottom: 40px;
      }
    }
    div:nth-child(2){
      background: linear-gradient(90deg, #807aff, #be7dfc);
      &:after{
        background-image: url('../../../assets/images/bee/index_icon2.png');
      }
    }
    div:nth-child(3){
      background: linear-gradient(90deg, #f8ab73, #f48569);
      &:after{
        background-image: url('../../../assets/images/bee/index_icon4.png');
      }
    }
  }
  footer{
    position: fixed;
    width: 100%;
    max-width: 800px;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;
    background: #fff;
    height: 100px;
    box-shadow: 0 0 10px rgba(200,200,200,.1);
    color: #b4b4b4;
    text-align: center;
    img{
      height: 44px;
      display: block;
      padding: 0 10px 4px;
    }
    a{color: #b4b4b4}
  }
}
</style>