<template>
  <div class="lsnIndex" :class="{fix:riseIndex==10}">
    <img src="../../../static/img/team6.jpg">
    <div class="how">
      <div>如何打造卓越的创业团队？</div>
      <div>如何面对多变的市场环境？</div>
      <div>如何防范创业过程中的潜在风险？</div>
    </div>

    <h1>关于“LSN精益创业导航”</h1>
    <p>LSN精益创业导航是帮助创业者健康创业的决策工具，根据精益创业整体水平、冲浪指数、团队卓越因子、潜在风险的数据结果，精准匹配创业课程与教练，帮助创业者少走弯路，健康成长。</p>

    <h1>通过“LSN精益创业导航”</h1>
    <h2>你将明确：</h2>
    <div class="ibox">
      <i>1</i><label>精益创业整体水平：</label>帮助创业团队发现问题，看到差距。
    </div>
    <div class="ibox">
      <i>2</i><label>冲浪指数：</label>创业团队应对外在环境的能力和水平，以及改善方向。
    </div>
    <div class="ibox">
      <i>3</i><label>团队卓越因子：</label>找到改善杠杆点，持续提升团队卓越度。
    </div>
    <div class="ibox">
      <i>4</i><label>潜在风险：</label>准确把握团队潜在风险，及时干预，提前防范。
    </div>
    <div class="ibox">
     <i>5</i><label>创业教练：</label>由创业教练针对性指导，改变思维和能力。
    </div>
    <div class="ibox">
      <i>6</i><label>创业资源：</label>基于当下创业团队水平及发展潜力，引入资金和人才，帮助创业团队获得创业成功。
    </div>

    <h1>适合对象</h1>
    <div class="crowd flex-b">
      <div class="icon"><img src="../../../static/img/lsn_index_5.png">创业团队</div>
      <div class="icon"><img src="../../../static/img/lsn_index_6.png">投资机构</div>
      <div class="icon"><img src="../../../static/img/lsn_index_7.png">创业教练</div>
    </div>

    <h1>报告样例</h1>
    <div class="picture">
      <img src="../../../static/img/lsn_index_1.png" @click="picIndex=1">
      <img src="../../../static/img/lsn_index_2.png" @click="picIndex=2">
      <img src="../../../static/img/lsn_index_3.png" @click="picIndex=3">
      <img src="../../../static/img/lsn_index_4.png" @click="picIndex=4">
    </div>
    <h3>（部分报告页展示，点击可查看大图）</h3>

    <h1>注意事项</h1>
    <p>① 点击下方“立即购买”按钮进行购买，购买成功后请按页面提示点击进入小程序进行操作。</p>
    <p>② 已购买的用户点击下方“立即购买”按钮进入选择再次购买或直接跳转小程序操作。</p>
    <p v-if="lowVersion">③ 你当前的微信版本过低，请更新微信或在微信搜索“十方实验室”小程序进入操作。</p>
    <p class="p2">如有疑问，请长按二维码添加客服微信或致电工作人员咨询</p>
    <p class="p3">微信号：<span>sfhuayan020</span></p>
    <img src="../../assets/images/kefuQrcode.jpg" class="kefu">
    <p class="p4">固话：<a href="tel:020-87070220">020-87070220</a></p>
    <p class="p4">手机：<a href="tel:18027418722">18027418722</a></p>
    <div class="under">
      <div class="btn" @click="start">立即购买</div>
    </div>
    <!-- 报告图片放大 -->
    <div class="mask" v-if="picIndex" @click="picIndex=0"/>
    <div v-if="picIndex" class="large">
      <img class="img" :src="`../../../static/img/lsn_index_${picIndex}.png`">
      <img class="close" @click="picIndex=0" src="../../assets/images/icons/close.png">
    </div>
    <!-- 登录窗口 -->
    <div class="mask" v-if="riseIndex==1" @click="countryShow=false">
      <div class="login box" :class="{rise:riseIndex==1}">
        <img src="../../assets/images/icons/close_2.png" class="close" @click="riseIndex=0">
        <h4>{{loadingText}}</h4>
        <div class="option" :class="{focus:focusIndex==1,error:errorIndex==1}">
          <h5>请输入手机号</h5>
          <div class="inputBox flex--c">
            <label :class="{rotate:countryShow}" @click.stop="countryShow=!countryShow">{{$country[countryIndex].label}}</label>
            <input type="text" maxlength="11" v-model="phone" @focus="focusIndex=1;errorIndex=0" @blur="focusIndex=0">
            <ul  class="countryBox" :class="{show:countryShow}">
              <li v-for="(item,i) in $country" :key="i" @click="countryIndex=i" :class="{curr:countryIndex==i}">{{item.country}}</li>
            </ul>
          </div>
        </div>
        <div class="option" :class="{focus:focusIndex==2,error:errorIndex==2}">
          <h5>请输入验证码</h5>
          <div class="inputBox flex--c">
            <input type="text" maxlength="6" v-model="captcha" @focus="focusIndex=2;errorIndex=0" @blur="focusIndex=0">
            <span v-if="second===0" @click="getCode">获取验证码</span>
            <span class="disable" v-else>重新获取({{second}}s)</span>
          </div>
        </div>
        <div class="btn" @click="login">登 录</div>
      </div>
    </div>
    <!-- 购买窗口 -->
    <div class="mask" v-else-if="riseIndex==2">
      <div class="riseBox">
        <img src="../../assets/images/icons/close_2.png" class="close" @click="riseIndex=0;teamname='';realname=''">
        <header class="flex-a">
          <span :class="{active:tab==1}" @click="tab=1">套餐购买</span>
          <span :class="{active:tab==2}" @click="tab=2">账号购买</span>
        </header>
        <div class="tips" :class="{hide: !teamText}">
          温馨提示：<div v-html="teamText"/>
        </div>
        
        <div class="view" :class="{hide:tab!=1}">
          <h5>产品详情</h5>
          <div class="flex-b">
            LSN精益创业导航<span>合计：<b class="green">￥3000.00</b></span>
          </div>
          <div class="flex">
            <label>1年有效期</label>
            <label>50个账号</label>
            <label>团队分析</label>
          </div>
        </div>
        <div class="view" :class="{hide:tab!=2}">
          <h5>产品详情</h5>
          <div class="flex-b">
            LSN精益创业导航<span>价格：<b class="green">￥100/个</b></span>
          </div>
          <div class="input flex-b-c">
            购买数量
            <div class="flex">
              <span @click="changeNum(-1)">-</span>
              <input type="text" v-model="buyNum" maxlength="3">
              <span @click="changeNum(1)">+</span>
            </div>
          </div>
          <div class="flex"><label>1年有效期</label></div>
          <div class="price">合计：<b class="green">¥ {{totalPrice}}</b></div>
        </div>
        <div class="view" :class="{hide: teamText}">
          <h5 style="margin-bottom:2px">购买前请完善以下信息</h5>
          <div class="row flex--c">
            <h6>公司/团队名 </h6><span class="red">*</span><input type="text" v-model="teamname" maxlength="30" placeholder="请输入真实的公司或团队名称">
          </div>
          <div class="row flex--c">
            <h6>真实姓名 </h6><span class="red">*</span><input type="text" v-model="realname" maxlength="30" placeholder="请输入真实姓名">
          </div>
          <div class="row flex--c">
            <h6>备注（选填） </h6><input class="inputPL" type="text" v-model="remark" placeholder="请输入备注">
          </div>
        </div>
        <footer>
          <div @click="pay()">确认付款</div>
        </footer>
      </div>
    </div>
    <!-- 购买成功 -->
    <div class="success" v-else-if="riseIndex==10">
      <img src="../../assets/images/icons/success_lsn.png" class="icon">
      <h5>支付成功</h5>
      <h6>点击下方按钮前往小程序端进行评估管理</h6>
      <wx-open-launch-weapp username="gh_0978e9d0dfeb" :path="path">
        <script type="text/wxtag-template">
          <div style="line-height: 40px;background: #35bc9e;color: #fff;border-radius: 4px;letter-spacing: 2px;margin: 50px 30px 20px;font-size: 14px;text-align: center;width:300px">进入小程序</div>
        </script>
      </wx-open-launch-weapp>
    </div>
  </div>
</template>
<script>
export default {
  data(){
    return {
      handler_id: this.$route.query.handler_id || '',
      picIndex: 0,                //点击放大图片的索引
      phone:'',                   //登录手机号
      captcha:'',                 //登录验证码
      second: 0,                  //倒数秒数
      countryShow: false,         //区号选择框显隐
      countryIndex: 0,            //当前选择的区号索引
      errorIndex: 0,              //登录错误索引(1：手机号错误，2：验证码错误)
      focusIndex: 0,              //聚焦索引
      riseIndex: 0,               //弹起窗口索引(1：登录，2：购买，3：已存在公司)
      loadingText: '请先登录',     //是否已经登录
      teamname: '',               //团队名
      realname: '',               //真实姓名
      remark: '',                 //备注
      teamText: '',               //温馨提示
      loginType: 'login',         //登录类型(login:登录,register:注册)
      lowVersion: false,          //低版本不支持跳转
      product_id: 13,             //购买产品的id
      tab: 1,                     //1:购买套餐，2:增加账号
      path: 'pages/LSN/manage/manage',
      buyNum: 1,
      totalPrice: '100.00',
    }
  },
  created(){
    document.title = 'LSN精益创业导航'
    this.$get(this.spaceUrl+'/User/checkWeixinLogin').then(() => {}, (e) => {
      if (e.code == 1 && location.href.indexOf('//localhost') < 0) location.href = `${this.spaceUrl}/Product/index?url=${encodeURIComponent(location.href)}`
    })
    this.$get(this.spaceUrl+'/User/checklogin').then(() => {
      this.loadingText = '登录成功'
      this.getLSN()
    }, () => {})
    //判断微信版本过低
    var wechat = navigator.userAgent.match(/MicroMessenger\/([\d\.]+)/i)
    if (wechat && wechat[1] < '7.0.12') this.lowVersion = true
    //微信分享
    wx.ready(() => {
      wx.onMenuShareAppMessage({
        title : 'LSN精益创业导航',
        // desc : 'LSN精益创业导航',
        link : `${this.spaceUrl}/Product/index?url=${encodeURIComponent(this.mobileUrl)}%2F%23%2FbuyLSN`,
        imgUrl : `${this.mobileUrl}/static/img/p-12.jpg`,
      })
    })
  },
  methods:{
    start(){
      let ua = navigator.userAgent.toLowerCase()
      if (ua.match(/MicroMessenger/i) != 'micromessenger') {
        this.$toast({message: '请在微信内打开'})
      } else if (this.loadingText == '登录成功') {
        this.riseIndex = 2
      } else {
        this.riseIndex = 1
      }
    },
    pay(){
      if (!this.teamname) {
        this.$toast({message: '请输入公司或团队名称', duration: 1000})
      } else if (!this.realname) {
        this.$toast({message: '请输入真实姓名', duration: 1000})
      } else {
        this.$get(this.spaceUrl+'/User/getsession').then(d => this.path = `pages/LSN/manage/manage?sessionID=${d.hy_session}&scene=pc`)//获取小程序路径的session
        this.$indicator.open({text: '加载中...',spinnerType: 'fading-circle'})
        this.$get(this.spaceUrl+'/Product/createShopOrder', {
          type: this.tab,
          product_id: this.product_id,
          number: this.tab==1 ? 1 : this.buyNum,
          companyname: this.teamname,
          realname: this.realname,
          remark: this.remark,
          handler_id: this.handler_id,
        }).then(d => {
          WeixinJSBridge.invoke('getBrandWCPayRequest', d, res => {
            if (res.err_msg == "get_brand_wcpay_request:ok") {
              this.riseIndex = 10
            } else {
              this.$toast({message: '支付失败'})
            }
          })
        }, e => {
          if (e.code == 6) {
            location.href = `${this.spaceUrl}/Product/index?url=${encodeURIComponent(location.href)}`
          } else {
            this.$toast({message: e.msg})
          }
        }).then(() => this.$indicator.close())
      }
    },
    getCode(){
      if (this.sending) {
        return
      } else if (!this.phone) {
        this.$toast({message: '请输入手机号', duration: 1000})
        this.errorIndex = 1
      } else if (!this.$country[this.countryIndex].reg.test(this.phone)) {
        this.$toast({message: '手机号格式不对', duration: 1000})
        this.errorIndex = 1
      } else {
        this.sending = true
        this.$http.post(this.spaceUrl+'/User/checkMobile', {
          mobile: this.phone,
          country_code: this.$country[this.countryIndex].country_code,
        }).then(e => {
          if (e.data.msg.indexOf('未注册') > 0) this.loginType = 'register'
          this.$get(this.spaceUrl+'/sms/getSmsCaptcha', {
            mobile: this.phone,
            country_code: this.$country[this.countryIndex].country_code,
            type: this.loginType,
          }).then(() => {
            this.$toast({message: '发送成功'})
            this.second = 60
            var timer = setInterval(() => {
              if (--this.second < 1) clearInterval(timer)
            }, 1000)
          }, () => this.$toast({message: '发送失败'})).then(() => this.sending = false)
        }, () => {
          this.sending = false
          this.$toast({message: '手机检验失败'})
        })
      }
    },
    login(){
      if (this.loadingText == '登录中') {
        return
      } else if (!this.phone) {
        this.$toast({message: '请输入手机号', duration: 1000})
        this.errorIndex = 1
      } else if (!this.$country[this.countryIndex].reg.test(this.phone)) {
        this.$toast({message: '手机号格式不对', duration: 1000})
        this.errorIndex = 1
      } else if (!this.captcha) {
        this.$toast({message: '请输入验证码', duration: 1000})
        this.errorIndex = 2
      } else { //登录或注册
        this.$get(`${this.spaceUrl}/User/${this.loginType}`, {
          mobile: this.phone,
          country_code: this.$country[this.countryIndex].country_code,
          captcha: this.captcha,
        }).then(() => {
            this.loadingText = '登录成功'
            //获取LSN（0已购买，1请登录，2所有产品未购买，3购买其他产品未购买lsn）
            this.getLSN()
            this.riseIndex = 2
            return
            this.$get(this.spaceUrl+'/Lsn/getLsnService').then(d => {
              this.teamText = `你当前所在的团队<b style="color:#35bc9e">“${d.companyname}”</b>还剩余<b style="color:#35bc9e">${d.may_invite_num}</b>个数量，服务将在<b style="color:#35bc9e">${this.$formatTime(d.service_time, '年月日')}到期</b>，请及时续费使用。`
              this.teamname = d.companyname
              this.realname = d.realname || d.nickname
            }, ({code, data}) => {
              if (code == 3) {  //已有团队新购
                this.teamText = `你当前已在团队<b style="color:#35bc9e">“${data.companyname}”</b>，是否要为此团队购买？`
                this.teamname = data.companyname
                this.realname = data.realname || data.nickname
              }
            }).then(() => this.riseIndex = 2) //登录完弹出购买窗
        }, ({msg}) => {
          this.loadingText = '请先登录'
          this.$toast({message: msg})
        })
      }
    },
    loginOut(){this.$get(this.spaceUrl+'/User/loginOut')},
    getLSN(){
      this.$get(this.spaceUrl+'/Lsn/getLsnService').then(d => {
        this.teamText = `你当前所在的团队<b style="color:#35bc9e">“${d.companyname}”</b>还剩余<b style="color:#35bc9e">${d.may_invite_num}</b>个数量，服务将在<b style="color:#35bc9e">${this.$formatTime(d.service_time, '年月日')}到期</b>，请及时续费使用。`
        this.teamname = d.companyname
        this.realname = d.realname || d.nickname
      }, ({code, data}) => {
        if (code == 3) {  //已有团队新购
          this.teamText = `你当前已在团队<b style="color:#35bc9e">“${data.companyname}”</b>，是否要为此团队购买？`
          this.teamname = data.companyname
          this.realname = data.realname || data.nickname
        }
      })
    },
    changeNum(n){
      let res = parseInt(this.buyNum) + n
      if (res > 0 && res < 1000) this.buyNum = res + ''
    }
  },
  watch: {
    captcha(val){
      this.captcha = val.replace(/[^\d]/g, '')
    },
    buyNum(val){
      this.buyNum = val.replace(/[^\d]/g, '')
      this.totalPrice = (this.buyNum * 100).toFixed(2)
    }
  }
}
</script>
<style lang="scss" scoped>
$color:#35bc9e;
.lsnIndex{
  padding-bottom: 180px;
  text-align: left;
  box-shadow:  0 0 8px rgba(50,50,50,.1);
  img{display: block;width: 100%;}
  .how{
    text-align: center;
    font-size: 28px;
    color: $color;
    font-weight: bold;
    line-height: 1.6;
    margin: 60px 0 0;
  }
  h1{
    line-height: 60px;
    background: $color;
    color: #fff;
    padding: 0 20px;
    margin: 60px 30px 40px;
    font-size: 28px;
    border-radius: 6px;
    display: inline-block;
    position: relative;
    user-select: none;
    z-index: 2;
    &::after{
      content: '';
      position: absolute;
      top: 4px;
      left: 4px;
      width: 100%;
      height: 100%;
      border-radius: 6px;
      border: 2px solid $color;
      z-index: 1;
    }
  }
  h2{
    color: #4d4d4d;
    font-size: 26px;
    margin: 0 36px 20px;
    font-weight: bold;
  }
  h3{
    color: #ccc;
    font-size: 24px;
    text-align: center;
    line-height: 50px;
    margin-bottom: 20px;
  }
  p{
    font-size: 26px;
    text-align: justify;
    line-height: 1.6;
    color: #999;
    margin: 0 36px;
    user-select: none;
    white-space: normal;
    a{
      color: #999;
      user-select: text;
      text-decoration: none;
    }
  }
  .p2{
    color: #4d4d4d;
    text-align: center;
    line-height: 120px;
    margin: 0 28px;
    white-space: nowrap;
  }
  .p3{
    margin-bottom: 20px;
    text-align: center;
  }
  .kefu{
    width: 200px;
    height: 200px;
    display: block;
    margin: 0 auto;
    border: 1px solid #ccc;
    margin-bottom: 40px;
  }
  .p4{
    text-align: center;
    line-height: 2;
  }
  .ibox{
    position: relative;
    padding: 40px 50px 32px;
    border: 1px solid $color;
    color: $color;
    font-size: 26px;
    margin: 30px 40px 40px 50px;
    line-height: 1.8;
    text-align: justify;
    background: #fff;
    z-index: 2;
    &::before{
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      border-left: 1px solid $color;
      border-bottom: 1px solid $color;
      z-index: 1;
      left: -12px;
      bottom: -12px;
    }
    &::after{
      content: '';
      position: absolute;
      width: 16px;
      height: 6px;
      border-top: 1px solid $color;
      border-bottom: 1px solid $color;
      background: #fff;
      z-index: 1;
      right: -4px;
      bottom: -6px;
      transform: rotate(-40deg) skewX(-40deg);
      transform-origin: center;
    }
    i{
      position: absolute;
      background: $color;
      color: #fff;
      top: -10px;
      left: -12px;
      width: 64px;
      line-height: 44px;
      text-align: center;
      z-index: 3;
      &::before{
        content: '';
        position: absolute;
        left: 0;
        top: 0;
        border-left: 18px solid #fff;
        border-bottom: 18px solid transparent;
      }
      &::after{
        content: '';
        position: absolute;
        right: 0;
        bottom: 0;
        border-right: 10px solid #fff;
        border-top: 10px solid transparent;
      }
    }
    label{
      color: #199cab;
      font-weight: bold;
    }
  }
  .crowd{
    margin: 20px 50px;
    .icon{
      display: flex;
      width: 200px;
      flex-direction: column;
      align-items: center;
      font-size: 30px;
      color: #4d4d4d;
      background: #e4f6f7;
      font-weight: bold;
      line-height: 90px;
      padding: 50px 0 40px;
      border-radius: 10px;
      img{
        width: 120px;
        height: 120px;
      }
    }
  }
  .picture{
    margin: 0 40px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    img{
      width: 300px;
      height: 420px;
      border: 1px solid #81c5cc;
      border-radius: 12px;
      margin: 20px 0;
      display: block;
    }
  }
  .under{
    border-top: 1px solid #f6f6f6;
    position: fixed;
    width: 100%;
    max-width: 800px;
    padding: 0 30px;
    height: 120px;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;
    background: #fff;
    display: flex;
    align-items: center;
    text-align: center;
    font-size: 24px;
    color: #999;
    z-index: 10;
    .btn{
      line-height: 80px;
      background: #33a1fb;
      color: #fff;
      border-radius: 8px;
      letter-spacing: 2px;
      font-size: 28px;
      flex-grow: 1;
    }
  }
  .large{
    position: fixed;
    width: 700px;
    left: 25px;
    top: 8%;
    z-index: 20;
    transform-origin: center;
    animation: large .2s linear;
    transition: all .2s;
  }
  @keyframes large {
    0%{transform: scale(0)}
    100%{transform: scale(1)}
  }
  .large img{border-radius: 10px;}
  .large .close{
    position: absolute;
    width: 70px;
    height: 70px;
    left: 50%;
    top: auto;
    bottom: -9%;
    transform: translateX(-50%);
    z-index: 12;
  }
  input::-webkit-input-placeholder {color: #ccc;}
  input::-moz-input-placeholder {color: #ccc;}
  input::-ms-input-placeholder {color: #ccc;}
}
.riseBox{
  position: fixed;
  width: 100%;
  left: 0;
  bottom: -100%;
  background: #f5f5f5;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  color: #999;
  font-size: 28px;
  overflow: hidden;
  animation: riseLSN .2s linear forwards;
  @keyframes riseLSN {100%{bottom: 0}}
  .close{
    position: absolute;
    width: 50px;
    height: 50px;
    padding: 12px;
    right: 20px;
    top: 20px;
    opacity: .6;
  }
  header{
    background: #fff;
    padding: 0 40px;
    transition: .3s;
    overflow: hidden;
    span{
      line-height: 100px;
      position: relative;
    }
    .active{
      color: $color;
      &::after{
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 4px;
        background: $color;
      }
    }
  }
  .tips{
    background: #fff;
    padding: 24px 30px;
    border-top: 4px solid #e1e1e1;
    margin-top: -4px;
    overflow: hidden;
    div{
      margin-top: 16px;
      line-height: 1.6;
    }
  }
  .view{
    margin: 24px 28px;
    border-radius: 10px;
    padding: 30px 30px 40px;
    background: #fff;
    overflow: hidden;
    max-height: 400px;
    transition: .2s linear;
    .input{
      margin-top: 24px;
      div{
        border: 1px solid #e5e5e5;
        text-align: center;
        border-radius: 8px;
      }
      span{
        width: 50px;
        line-height: 50px;
        font-size: 36px;
        color: #ccc;
      }
      input{
        width: 80px;
        color: #1a1a1a;
        text-align: center;
        border-left: 1px solid #e5e5e5;
        border-right: 1px solid #e5e5e5;
      }
    }
    h5{
      margin-bottom: 20px;
      color: #333;
      font-weight: bold;
    }
    label{
      font-size: 24px;
      padding: 0 30px;
      line-height: 1.8;
      background: #eaf8f5;
      color: $color;
      margin: 30px 30px 0 0;
      border-radius: 4px;
    }
    .price{
      text-align: right;
      margin-top: 20px;
    }
    .green{color: $color}
    .row{
      border-bottom: 1px solid #ebebeb;
      height: 88px;
      color: #b3b3b3;
      white-space: nowrap;
      h6{
        width: 160px;
        text-align-last: justify;
        margin-right: 10px;
        color: #4d4d4d;
      }
      input{
        padding-left: 24px;
        height: 100%;
        color: #4d4d4d;
        font-size: 28px;
        flex: 1;
      }
      .inputPL{padding-left: 34px;}
    }
    .red{color: #f52525}
  }
  footer{
    background: #fff;
    padding: 20px 28px;
    div{
      line-height: 40PX;
      background: $color;
      color: #fff;
      border-radius: 4PX;
      letter-spacing: 1PX;
      font-size: 14PX;
      text-align: center;
    }
  }
  .hide{
    max-height: 0;
    padding: 0 30px;
    margin-top: 0;
    margin-bottom: 0;
    transition: 0s;
  }
}
.box{
  position: fixed;
  width: 100%;
  height: 80%;
  bottom: -100%;
  padding: 0 30px;
  left: 0;
  overflow-y: auto;
  background: #fff;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  overflow: hidden;
  z-index: 99;
  .close{
    position: absolute;
    width: 50px;
    height: 50px;
    padding: 12px;
    right: 20px;
    top: 20px;
    opacity: .6;
  }
}
.rise{
  transition: bottom .2s linear;
  bottom: 0;
}
.login{
  font-size: 26px;
  h4{
    font-size: 32px;
    font-weight: bold;
    text-align: center;
    color: #4d4d4d;
    line-height: 100px;
  }
  .btn{
    width: 100%;
    margin-top: 165px;
    background: $color;
    line-height: 84px;
    color: #fff;
    font-size: 30px;
    border-radius: 8px;
    user-select: none;
    text-align: center;
  }
  .option{
    h5{
      color: #999;
      text-align: left;
      line-height: 1.8;
      user-select: none;
      margin-top: 100px;
    }
    .inputBox{
      position: relative;
      border-bottom: 2px solid #e1eae8;
      margin-top: 22px;
      input{
        padding-left: 16px;
        height: 70px;
        flex: 1;
        color: #4d4d4d;
        font-size: 28px;
      }
      label{
        width: 80px;
        padding-right: 10px;
        line-height: 70px;
        position: relative;
        color: #808080;
        &:after{
          content:'';
          position: absolute;
          top: 26px;
          right: 0px;
          border-top: 10px solid #ccc;
          border-left: 8px solid transparent;
          border-right: 8px solid transparent;
          transition: transform .1s linear;
          transform-origin: center;
        }
      }
      .rotate::after{transform: rotate(-180deg);}
      span{
        color: $color;
        background: #e6f7f3;
        border-radius: 6px;
        line-height: 54px;
        text-align: center;
        min-width: 148px;
        padding: 0 10px;
        font-size: 24px;
      }
      .disable{
        background: #f0f0f0;
        color: #999;
      }
    }
    .countryBox{
      position: absolute;
      top: 95%;
      left: -10px;
      background: #fff;
      box-shadow: 0 0 2px rgba(50,50,50,.3);
      z-index: 10;
      padding: 12px 0;
      border-radius: 6px;
      transform: scaleY(0);
      transform-origin: top;
      transition: transform .1s linear;
      user-select: none;
      li{
        padding: 0 20px;
        line-height: 2;
        color: #808080;
      }
      .curr{color: $color;}
    }
    .show{
      transform: scaleY(1);
    }
  }
  .focus{
    h5{color: #4d4d4d}
    .inputBox{border-bottom: 2px solid $color}
  }
  .error .inputBox{border-bottom: 2px solid #f96868}
}
.detail{
  padding: 80px 0 0;
  color: #999;
  white-space: nowrap;
  font-size: 28px;
  user-select: none;
  h5{
    line-height: 80px;
    color: #333;
    font-weight: bold;
    margin: 0 30px;
  }
  .flex-b{margin: 0 30px;}
  .green{color: $color}
  .red{color: #f52525}
  label{
    font-size: 24px;
    padding: 0 30px;
    line-height: 1.8;
    background: #eaf8f5;
    color: $color;
    margin: 30px 0 30px 30px;
    border-radius: 4px;
  }
  .line{
    background: #f1f1f1;
    height: 10px;
    margin: 20px 0;
  }
  .row{
    border-bottom: 1px solid #ebebeb;
    margin: 0 30px;
    height: 88px;
    color: #b3b3b3;
    h6{
      width: 200px;
      text-align-last: justify;
      margin-right: 10px;
    }
    input{
      padding-left: 24px;
      height: 100%;
      color: #4d4d4d;
      font-size: 28px;
      flex: 1;
    }
    .inputPL{padding-left: 34px;}
  }
  .btn{
    line-height: 40PX;
    background: $color;
    color: #fff;
    border-radius: 4PX;
    letter-spacing: 1PX;
    margin: 100px 16PX 40px;
    font-size: 14PX;
    text-align: center;
  }
  .btn2{
    margin-top: 0;
    background: none;
    border: 1px solid $color;
    color: $color;
  }
  .p5{
    color: $color;
    line-height: 2.2;
    font-size: 28px;
    font-weight: bold;
  }
  .p6{
    font-size: 28px;
  }
}
.mask{
  position: fixed;
  width: 100%;
  height: 110%;
  top: -4px;
  left: 0;
  z-index: 11;
  background: rgba($color: #000, $alpha: .7);
}
.success{
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  width: 100%;
  height: 100%;
  padding-top: 240px;
  user-select: none;
  white-space: nowrap;
  background: #fff;
  text-align: center;
  .icon{
    display: block;
    width: 180px;
    height: 180px;
    background: #d7f2ec;
    border-radius: 50%;
    margin: 20px auto;
  }
  h5{
    font-size: 40px;
    color: #4d4d4d;
    font-weight: bold;
  }
  h6{
    color: #999;
    font-size: 26px;
    line-height: 100px;
  }
  .btn{
    line-height: 80px;
    background: $color;
    color: #fff;
    border-radius: 8px;
    letter-spacing: 2px;
    margin: 100px 60px 40px;
    font-size: 28px;
    text-align: center;
  }
}
.fix{
  height: 100%;
  overflow: hidden;
}
</style>