var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"report",class:_vm.theme,attrs:{"id":"report"}},[_c('div',{staticClass:"top"},[_c('header',[_c('img',{staticClass:"headImg",attrs:{"src":_vm.avatar}}),_c('div',{staticClass:"label"},[_c('b',[_vm._v("认知差异分析报告")]),_c('br'),_vm._v(" "+_vm._s(_vm.$formatTime(_vm.endTime,'y-m-d h:m'))+" ")])]),(!_vm.long)?_c('nav',[_c('span',{class:{current:_vm.tabId==1},on:{"click":function($event){return _vm.tab(1)}}},[_vm._v("工作能量差异分析")]),(_vm.leadership.length)?_c('span',{class:{current:_vm.tabId==2},on:{"click":function($event){return _vm.tab(2)}}},[_vm._v("领导能量差异分析")]):_vm._e()]):_vm._e()]),_c('ul',{staticClass:"content"},[(_vm.long)?_c('div',{staticClass:"title"},[_vm._v("工作能量差异分析")]):_vm._e(),_c('li',{directives:[{name:"show",rawName:"v-show",value:(_vm.tabId==1 || _vm.long),expression:"tabId==1 || long"}],staticClass:"energy"},[_vm._m(0),_c('difference',{attrs:{"data":_vm.work_energy,"animate":!_vm.long}}),_vm._m(1),_vm._m(2),_c('div',{staticClass:"word"},_vm._l((_vm.energy_text),function(item){return _c('p',{key:item,domProps:{"innerHTML":_vm._s(item)}})}),0)],1),(_vm.leadership.length)?[(_vm.long)?_c('div',{staticClass:"title"},[_vm._v("领导能量差异分析")]):_vm._e(),_c('li',{directives:[{name:"show",rawName:"v-show",value:(_vm.tabId==2 || _vm.long),expression:"tabId==2 || long"}],staticClass:"energy"},[_vm._m(3),_c('difference',{attrs:{"data":_vm.leadership,"colotType":1,"animate":!_vm.long}}),_vm._m(4),_vm._m(5),_c('div',{staticClass:"word"},_vm._l((_vm.leadership_text),function(item){return _c('p',{key:item,domProps:{"innerHTML":_vm._s(item)}})}),0)],1)]:_vm._e()],2)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tips"},[_c('div',[_vm._v("工作能量说明：")]),_c('p',[_vm._v("工作能量是个体在工作场景中展现出来的综合状态，源自于个体的内在工作价值倾向，在原动力和职业素质上体现；主要包含自我经营、成就动机、利他思维、乐观坚韧、人际沟通、学习成长、高效行动、规划能力8个方面。")]),_c('p',[_vm._v("一般来说，自身和他人的认知存在差异，积极了解差异及背后原因，有助于个体取得更好的成就。")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"c_tip"},[_c('b',[_vm._v("差值")]),_vm._v("为自我认知与他人认知之间的数值。")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"legend legend0"},[_c('div',[_vm._v("自我认知")]),_c('div',[_vm._v("他人认知")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tips"},[_c('div',[_vm._v("领导能量说明：")]),_c('p',[_vm._v("领导能量是领导行为综合作用的结果，是从管理者个人出发，到团队经营，再到组织成果产出的完整管理闭环；主要包含以身作则、共启愿景、突破创新、激励沟通、推动执行、确保成效六项关键领导行为。")]),_c('p',[_vm._v("一般来说，自身和他人对认知存在差异，积极了解差异及背后原因，有助于领导行为的改善，进而提升领导的质量。")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"c_tip"},[_c('b',[_vm._v("差值")]),_vm._v("为自我认知与他人认知之间的数值。")])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"legend legend1"},[_c('div',[_vm._v("自我认知")]),_c('div',[_vm._v("他人认知")])])
}]

export { render, staticRenderFns }