<template>
  <div class="barBox">
    <div class="line">
      <div class="bar">
        <span :style="{width:score+'%'}"><i v-show="score"></i></span>
      </div>
      <div class="scale">
        <span class="fl">0</span>
        <span class="fr">100</span>
        <div class="refer" v-if="refer" :style="{left:refer+'%'}"></div>
      </div>
    </div>
    <p v-if="text">{{text}}<span>{{score}}</span></p>
  </div>
</template>
<script>
export default {
  props: {
    score: {
      default: 0,
    },
    refer: {
      type: Number,
      default: 0,
    },
    text: {
      default: ''
    },
  },
  methods:{
  },
}
</script>
<style lang="scss" scoped>
.barBox{
  margin:  140px auto 100px;
  font-size: 0;
}
.line{
  display: inline-block;
  width: 654px;
  margin-bottom: 70px;
}
.bar{
  height: 32px;
  background: #e5e5e5;
  position: relative;
  background-image: linear-gradient(to right,#2bbd77,#e7be16);
  span{
    position: absolute;
    height: 100%;
    width: 0%;
    border-radius: 20px;
    top: 0;
    left: 0;
    animation: w0 1s;
    @keyframes w0 {0%{width: 0}}
    i{
      position: absolute;
      right: -10px;
      bottom: 140%;
      border-left: 10px transparent solid;
      border-right: 10px transparent solid;
      border-top: 24px #f2c53c solid;
      &::after{
        content: '';
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        width: 2px;
        height: 60px;
        background: #fff;
        top: 0px;
      }
    }
  }
}
.scale{
  position: relative;
  height: 40px;
  span{
    font-size: 26px;
    padding: 10px 4px;
    color: #5e5341;
  }
  .fl{float: left;}
  .fr{float: right;}
  .refer{
    position: absolute;
    left: 0%;
    top: -84px;
    border-left: 1px dashed #f77c03;
    height: 140px;
    &::before{
      content: '参考线';
      position: absolute;
      color: #808080;
      font-size: 22px;
      width: 80px;
      text-align: center;
      left: -40px;
      top: -40px;
    }
  }
}
p{
  color: #5e5341;
  font-weight: bold;
  text-align: center;
  font-size: 26px;
  span{color: #f77c03;}
}
</style>