<template>
  <div class="pay">
    <div class="box">
      <div class="row flex--c" @click="type=1">
        <label :class="{picked:type==1}"/>
        <h1>个人购买</h1>
        <h2>当前账号：{{userInfo.mobile}}</h2>
      </div>
      <div class="message" :class="{show:type==1}">
        <div class="content flex--c">
          <h4 class="flex--c">姓名<span>*</span></h4>
          <input type="text" placeholder="请输入真实姓名" v-model="mess[1].realname">>
        </div>
        <div class="content flex--c">
          <h4 class="flex--c">备注（选填）</h4>
          <input type="text" placeholder="如有需要请备注" v-model="mess[1].remark">>
        </div>
      </div>
    </div>
    <div class="box">
      <div class="row flex--c" @click="type=2">
        <label :class="{picked:type==2}"/>
        <h1>企业购买</h1>
        <h2 v-if="userInfo.is_company==1">{{userInfo.companyname}}</h2> 
        <h2 v-else>未绑定企业</h2>
      </div>
      <div class="message" :class="{show:type==2}">
        <h3 v-if="!userInfo.companyname">当前未绑定企业，请先完善企业基本信息</h3>
        <div class="content flex--c">
          <h4 class="flex--c">企业名<span>*</span></h4>
          <input type="text" placeholder="请输入企业名称" v-model="mess[2].companyname">>
        </div>
        <div class="content flex--c">
          <h4 class="flex--c">姓名<span>*</span></h4>
          <input type="text" placeholder="请输入真实姓名" v-model="mess[2].realname">>
        </div>
        <div class="content flex--c">
          <h4 class="flex--c">备注（选填）</h4>
          <input type="text" placeholder="如有需要请备注" v-model="mess[2].remark">>
        </div>
      </div>
    </div>
    <div class="box" v-if="teamshow">
      <div class="row flex--c" @click="type=3">
        <label :class="{picked:type==3}"/>
        <h1>团队购买</h1>
        <h2 v-if="userInfo.is_company==2">{{userInfo.companyname}}</h2> 
        <h2 v-else>未绑定团队</h2>
      </div>
      <div class="message" :class="{show:type==3}">
        <h3>当前未绑定团队，请先完善企业基本信息</h3>
        <div class="content flex--c">
          <h4 class="flex--c">团队名<span>*</span></h4>
          <input type="text" placeholder="请输入团队名称" v-model="mess[3].companyname">>
        </div>
        <div class="content flex--c">
          <h4 class="flex--c">姓名<span>*</span></h4>
          <input type="text" placeholder="请输入真实姓名" v-model="mess[3].realname">>
        </div>
        <div class="content flex--c">
          <h4 class="flex--c">备注（选填）</h4>
          <input type="text" placeholder="如有需要请备注" v-model="mess[3].remark">>
        </div>
      </div>
    </div>
    <div class="bottom flex--c">
      <h5>已选{{productInfo.length}}个模块，总计 <span>¥<b>{{parseFloat(totalPrice.toFixed(2))}}</b></span></h5>
      <i style="flex:1"/>
      <h6 @click="pay">支付</h6>
    </div>
  </div>
</template>
<script>
export default {
  data(){
    return {
      userInfo: {},
      type: 1,
      mess: {
        1: {realname: '', remark: ''},
        2: {realname: '', remark: '', companyname: ''},
        3: {realname: '', remark: '', companyname: ''},
      },
      teamshow: true,
      way: this.$route.query.way || '', //direct:直接购买,其他是购物车结算
      productInfo: [],
      totalPrice: 0,
    }
  },
  created() {
    let user = this.userInfo = JSON.parse(localStorage.getItem("user"))
    this.mess[1].realname = user.realname || ''
    this.mess[2].realname = user.realname || ''
    this.mess[3].realname = user.realname || ''
    if (user.is_company == 1) {
      this.mess[2].companyname = user.companyname || ''
      this.teamshow = false
    } else {
      this.mess[3].companyname = user.companyname || ''
    }

    if (this.way == 'direct') { //直接购买
      this.productInfo = JSON.parse(sessionStorage.getItem("product"))
      this.totalPrice = this.productInfo[0].price * this.productInfo[0].number
    } else {    //购物车结算
      let carList = JSON.parse(localStorage.getItem("car_"+user.user_id)||'[]')
      let arr = []
      for (let item of carList) {
        if (item.pick) {
          this.productInfo.push({product_id: item.product_id, number: item.number})
          this.totalPrice += item.price * item.number
        } else {
          arr.push(item)
        }
      }
      this.newCarList = arr
    }
  },
  methods: {
    pay() {
      if (!this.mess[this.type].realname) {
        this.$toast({message: '请输入姓名', duration: 1000})
        return
      } else if (this.type == 2 && !this.mess[2].companyname) {
        this.$toast({message: '请输入企业名', duration: 1000})
        return
      } else if (this.type == 3 && !this.mess[3].companyname) {
        this.$toast({message: '请输入团队名', duration: 1000})
        return
      }
      let s = localStorage.getItem("session") || ''
      this.$indicator.open({text:'支付中...', spinnerType:'fading-circle'})
      this.$http.get(this.spaceUrl+'/Product/createModuleOrder', {params: {
        hy_session: s,
        user_type: this.type,
        product_info: this.productInfo,
        companyname: this.mess[this.type].companyname,
        realname: this.mess[this.type].realname,
        remark: this.mess[this.type].remark,
        // handler_id: this.handler_id,
      }}).then(({data}) => {
        this.$indicator.close()
        if (data.code == 0) {
          WeixinJSBridge.invoke('getBrandWCPayRequest', data.data, res => {
            if (res.err_msg == "get_brand_wcpay_request:ok") {
              if (this.way == 'direct') { //直接购买
                sessionStorage.removeItem('product')
              } else {  //购物车结算
                localStorage.setItem("car_"+this.userInfo.user_id, JSON.stringify(this.newCarList))
              }
              this.$router.replace({path: '/TDA/success'})
            } else {
              this.$toast({message: '支付失败'})
            }
          });
        } else {
          this.$toast({message: data.msg})
        }
      })
    },
  },
  destroyed() { //离开页面
    // sessionStorage.removeItem('product')
  },
  mounted() {
    wx.ready(() => {
      wx.onMenuShareAppMessage({
        title : '人才发展评估',
        link : `${this.mobileUrl}/#/TDA/shop`,
        imgUrl : `${this.mobileUrl}/static/img/p-15.jpg`,
      })
    })
  },
}
</script>
<style lang="scss" scoped>
.pay{
  background: #f1f5f7;
  height: 100%;
  position: relative;
  box-sizing: border-box;
  padding: 24px 0 120px;
  .box{
    background: #fff;
    border-radius: 10px;
    margin: 0 24px 24px;
    padding: 30px 20px;
    font-size: 28px;
    white-space: nowrap;
    .row{
      padding: 20px 0;
    }
    label{
      width: 36px;
      height: 36px;
      border-radius: 50%;
      border: 1px solid #979797;
      margin-right: 20px;
    }
    .picked{
      background: #4987f9 url('https://m.huayanspace.com/static/img/gou.png') no-repeat;
      background-size: 100%;
      border-color: #4987f9;
    }
    h1{
      color: #292929;
    }
    h2{
      color: #aaa;
      flex: 1;
      text-align: right;
      margin: 0 10px 0 50px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .message{
    text-align: left;
    padding: 0 10px 0 54px;
    margin-bottom: 0;
    max-height: 0;
    transition: .2s;
    overflow: hidden;
    h3{
      color: #ccc;
      line-height: 80px;
      font-size: 26px;
      font-weight: normal;
    }
    .content{
      border-bottom: 1px solid #f5f5f5;
      height: 100px;
      color: #d9d9d9;
      h4{
        color: #454545;
        width: 200px;
        span{
          color: #f52525;
          margin-left: 20px;
        }
      }
      input{
        height: 60px;
        flex: 1;
      }
      input::-webkit-input-placeholder {color: #ccc;}
      input::-moz-input-placeholder {color: #ccc;}
      input::-ms-input-placeholder {color: #ccc;}
    }
  }
  .show{
    max-height: 400px;
    margin-bottom: 50px;
  }
  .bottom{
    position: absolute;
    width: 100%;
    height: 120px;
    left: 0;
    bottom: 0;
    background: #fff;
    box-sizing: border-box;
    padding: 0 24px;
    h5{
      font-size: 24px;
      color: #292929;
      text-align: left;
      margin-left: 30px;
      span{color: #f81c1c;}
      b{font-size: 28px;}
    }
    h6{
      line-height: 70px;
      width: 280px;
      border-radius: 40px;
      background: #4987f9;
      color: #fff;
      font-size: 28px;
    }
  }
}
</style>