<template>
  <div class="success">
    <!-- 加载中 -->
    <div class="icon" v-show="showType==0">
      <img src="../../../assets/images/product/loading.gif">
      <p>处理中...</p>
    </div>
    <!-- 按钮进入小程序 -->
    <div class="icon" v-show="showType==1">
      <img src="../../../assets/images/product/success.png">
      <p>支付成功!</p>
      <p class="text">请点击下方按钮在小程序端进行管理</p>
      <wx-open-launch-weapp id="launch_btn" username="gh_f68c5eef1b60" :path="path" class="btn" @error="error">
          <script type="text/wxtag-template">
            <p style="color:#fff;text-align:center;width:280px;background:#33a1fb;line-height:44px;border-radius:2px;">打开小程序</p>
          </script>
      </wx-open-launch-weapp>
    </div>
    <!-- 二维码进入小程序 -->
    <div v-show="showType==2">
      <img class="qrcode" :src="qrcode">
      <p class="text" style="margin:10px;">长按二维码打开小程序进行管理</p>
    </div>
    <!-- 错误提示 -->
    <div class="icon" v-show="showType==3">
      <img src="../../../assets/images/product/sad.png">
      <p style="font-size:12px;margin:20px 80px">购买成功，因无法进入小程序，可在微信搜索“十方实验室”小程序进行管理</p>
    </div>
    <div class="version" v-if="version">微信版本：{{version}}</div>
  </div>
</template>
<script>
export default {
  data(){
    return {
      path: 'pages/pageIndex/pageIndex.html',
      showType: 0,      //是否显示小程序按钮 (默认不支持)
      version: '',      //当前微信版本
      qrcode: '',
    }
  },
  created(){
    this.$http.get(this.spaceUrl+'/User/getsession').then(({data}) => {
      if (data.code == 0) {
        let productId = sessionStorage.wxPayProductId || 11, session = data.data.hy_session
        if (productId == 11) {
          this.path = 'pages/AMT/manage/manage.html?sessionID=' + session
        } else if (productId == 6) {
          this.path = 'pages/professionTend/manage/manage.html?sessionID=' + session
        }
        //判断手机系统和微信版本
        let u = navigator.userAgent,
            wxInfo = u.match(/MicroMessenger\/([\d\.]+)/i), //微信浏览器信息
            ver = wxInfo[1].split("."),                     //版本信息
            client = false;                                 //当前版本号是否支持
        this.version = wxInfo[1]
        if (ver[0] > 7) {
          client = true;
        } else if (ver[0] == 7) {
          if (ver[1] > 0) client = true;
          else if (ver[2] >= 12) client = true;
        }
        if (this.$system == 'ios' && client) { //判断苹果微信版本在7.0.12及以上的版本
          setTimeout(() => {
            this.showType = 1
          }, 1000);
        } else {
          setTimeout(() => {
            this.$http.post(this.spaceUrl+'/Product/createMiniQrcode',{
              page: 'pages/AMT/manage/manage'
            }).then(({data}) => {
              if (data.code == 0) {
                this.showType = 2
                this.qrcode = data.data.qrcode_image
              } else {
                this.showType = 3
                this.$toast({message: data.msg});
              }
            }, () => this.showType = 3)
          }, 1000);
        }
      } else {
        this.$toast({message: data.msg});
      }
    })
      
  },
  mounted(){
    document.title = '产品与服务'
    // var btn = document.getElementById('launch_btn');
    // btn.addEventListener('launch', (e) => {
    //   setTimeout(()=>{
    //     history.go(-1)
    //   }, 1000)
    // });
  },
  methods:{
    error(){
      history.go(-1)
    },
  },
}
</script>
<style lang="scss" scoped>
.success{
  height: 100%;
  padding-top: 160px;
  background: #f1f5f7;
  position: relative;
  .text{
    font-size: 28px;
    color: #808080;
    margin: 120px 0 40px;
  }
  .btn{
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 80px;
  }
  .qrcode{
    width: 400px;
  }
  .version{
    position: absolute;
    font-size: 24px;
    color: #d9d9d9;
    bottom: 60px;
    left: 0;
    width: 100%;
  }
}
.icon{
  font-size: 40px;
  color: #535353;
  font-weight: bold;
  img{
    width: 140px;
    height: 140px;
    display: block;
    margin: 30px auto;
  }
  p{
    height: 60px;
  }
}
</style>