<template>
  <div class="beeDetail">
    <header>
      <img :src="info.banner_img">
    </header>
    <nav class="flex-a">
      <span :class="{curr:tab==0}" @click="tab=0">目录</span>
      <span :class="{curr:tab==1}" @click="tab=1">详情</span>
    </nav>
    <ul v-if="tab==0">
      <li class="flex-b-c" v-for="(item,i) in mp3" :key="i" :class="{active:currIndex===i}">
        <div>
          <h3 class="flex--c">
            <b v-if="item.is_free">试听</b>
            <p>{{item.title}}</p>
            <span class="flex-b-c" :class="{stop:!playing}"><i/><i/><i/></span>
          </h3>
          <h4>时长 {{setTime(item.total_time)}}</h4>
        </div>
        <div class="button play" @click="toPlay(i)" v-if="state=='pay'"><i/></div>
        <div class="button" :class="currIndex===i&&playing?'pause':'play'" @click="play(i)" v-else-if="item.is_free"><i/></div>
        <div class="button lock" @click="toBuy" v-else><i/></div>
      </li>
    </ul>
    <div class="content" v-else>
      <div v-html="info.content"></div>
    </div>
    <div class="foot flex--c">
      <div class="contact flex--c">
        <img src="../../../assets/images/bee/bee_kefu.png">联系客服
      </div>
      <div class="buy" style="opacity:.5" v-if="state=='pay'">已购买</div>
      <div class="buy" v-else @click="buy">购买：{{info.price}}元</div>
    </div>
  </div>
</template>
<script>
var audio = document.createElement('audio')
export default {
  data(){
    return {
      id: this.$route.query.id,
      tab: 0,
      info: {},
      mp3: [],
      currIndex: '',
      playing: false,
      state: 'login',  //login:已登录, unlogin:未登录, pay:已购买
    }
  },
  created(){
    this.$http.post(this.ajaxUrlPath+'/BeeCourse/getCourseSubject', {course_id: this.id}).then(({data}) => {
      if (data.code == 0) {
        this.info = data.data.info
        this.mp3 = data.data.subject
      } else {
        this.$toast({message: data.msg})
      }
    }, () => this.$toast({message:'网络错误'}))
    //检查是否购买
    this.$http.post(`${this.ajaxUrlPath}/BeeCourse/checkbuy?hy_session=${localStorage.getItem("session")}&course_id=${this.id}`).then(({data}) => {
      if (data.code == 2) {
        this.state ='pay'
      } else if (data.code == 101) {
        this.state ='unlogin'
      }
    })
    audio.onended = () => {
      this.playing = false
      this.currIndex = ''
      this.$toast({message: '已试听完毕'})
    }
    audio.onpause = () => this.playing = false
    audio.onplaying = () => this.playing = true
  },
  methods: {
    toBuy() {
      if (this.state =='unlogin') 
        location.href = 'https://api.app.huayanspace.com/WeixinH5/login?url='+encodeURIComponent(location.href)
      else
        this.$toast({message: '请先购买'})
    },
    toPlay(i) {
      sessionStorage.setItem('play', i)
      this.$router.push({path: '/bee/play', query: {id: this.id}})
    },
    setTime(time) {
      let t = new Date(time * 1000).toISOString()
      return t.substr(14, 5)
    },
    play(i) {
      if (this.currIndex === i && this.playing) {
        audio.pause()
        this.playing = false
      } else {
        if (this.currIndex !== i) {
          audio.src = this.mp3[i].play_url
          this.$http.post(`${this.ajaxUrlPath}/BeeCourse/playRecord?subject_id=${this.mp3[i].id}`)
        }
        this.currIndex = i
        audio.play()
        this.playing = true
      }
    },
    buy() {
      this.$http.post(this.ajaxUrlPath+'/BeeCourse/buy', {
        hy_session: localStorage.getItem("session") || '',
        course_id: this.id,
      }).then(({data}) => {
        if (data.code == 0) {
          WeixinJSBridge.invoke('getBrandWCPayRequest', data.data, res => {
            if (res.err_msg == "get_brand_wcpay_request:ok") {
              console.log('购买成功')
              this.state ='pay'
            } else {
              this.$toast({message: '支付失败'})
            }
          });
        } else if (data.code == 101) {
          location.href = 'https://api.app.huayanspace.com/WeixinH5/login?url='+encodeURIComponent(location.href)
        } else if (data.code == 102) {
          this.$router.push({path: '/bee/login'})
        } else {
          this.$toast({message: data.msg})
        }
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.beeDetail{
  box-shadow: 0 -16px 10px rgba(200,200,200,.2);
  header{
    background: lightgreen;
    height: 260px;
    img{
      display: block;
      width: 100%;
      height: 260px;
    }
  }
  nav{
    border-bottom: 1px solid #f0f0f0;
    font-size: 28px;
    color: #8f9192;
    background: #fff;
    span{
      line-height: 80px;
      position: relative;
      width: 100px;
    }
    .curr{
      color: #292929;
      font-weight: bold;
      &::after{
        content: '';
        position: absolute;
        width: 50px;
        height: 6px;
        background: #279a35;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
  ul{
    height: calc(100vh - 440px);
    overflow-y: auto;
    li{
      text-align: left;
      margin: 20px 30px 0;
      padding: 0 10px 20px;
      line-height: 1.8;
      border-bottom: 1px solid #f0f0f0;
      .button{
        padding: 12px 10px;
        position: relative;
        &::before{
          content: '';
          position: absolute;
          right: 100%;
          top: 0;
          height: 100%;
          width: 610px;
        }
        i{
          display: block;
          width: 48px;
          height: 48px;
          border-radius: 50%;
          border: 1px solid #8f9192;
          position: relative;
          &::after{
            content: '';
            position: absolute;
            left: 50%;
            top: 50%;
            transform: translate(-50%,-50%);
          }
        }
      }
      .play i::after{
        left: 58%;
        border-left: 16px solid #8f9192;
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
      }
      .pause i::after{
        width: 10px;
        height: 16px;
        border-left: 2px solid #8f9192;
        border-right: 2px solid #8f9192;
      }
      .lock i::after{
        width: 40px;
        height: 40px;
        background: url('../../../assets/images/bee/bee_lock.png') no-repeat;
        background-size: 100% 100%;
      }
      h3{
        white-space: nowrap;
        b{
          color: #2c9e3a;
          border: 1px solid #2c9e3a;
          border-radius: 3px;
          font-size: 20px;
          line-height: 1;
          padding: 2px;
          margin-right: 6px;
          font-weight: normal;
        }
        p{
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 450px;
          font-size: 28px;
          color: #292929;
        }
        span{
          width: 24px;
          height: 40px;
          margin-left: 16px;
          display: none;
          i{
            width: 4px;
            height: 0px;
            border-radius: 2px;
            background: #c7f1cb;
            animation: equa .7s infinite;
          }
          i:nth-child(2){animation-duration: 1s;}
          i:nth-child(3){animation-duration: 1.3s;}
        }
        .stop i{animation-play-state: paused}
        @keyframes equa {
          50%{height: 24px;}
          100%{height: 0;}
        }
      }
      h4{
        font-size: 22px;
        color: #b3b3b3;
      }
    }
    .active{
      h3 span{display: flex}
      .button i{border-color: #65b16d}
      .play i::after{border-left: 16px solid #65b16d;}
      .pause i::after{border-color: #65b16d}
    }
  }
  .content{
    height: calc(100vh - 440px);
    overflow-y: auto;
  }
  .foot{
    position: absolute;
    width: 100%;
    height: 100px;
    padding: 0 30px;
    left: 0;
    bottom: 0;
    box-shadow: 0 0 10px rgba(200,200,200,.2);
    .contact{
      font-size: 22px;
      color: #8f9192;
      padding: 10px;
      img{
        width: 50px;
        height: 50px;
        margin-right: 10px;
      }
    }
    .buy{
      flex: 1;
      line-height: 74px;
      background: #2c9e3a;
      box-shadow: 0 6px 10px rgba(73,167,91,.3);
      color: #fff;
      border-radius: 50px;
      margin-left: 60px;
      font-size: 28px;
    }
    .loginBtn{
      flex: 1;
      line-height: 74px;
      background: #2c9e3a;
      color: #fff;
      border-radius: 10px;
      font-size: 28px;
    }
  }
}
</style>