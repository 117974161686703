<template>
  <svg class="pointer">
    <g class="bg"></g>
    <defs>
      <linearGradient id="shadow2" x1="0%" y1="0%" x2="100%" y2="0%">
        <stop offset="0%" stop-color="#4fd8e4"/>
        <stop offset="100%" stop-color="#619df7"/>
      </linearGradient>
    </defs>
  </svg>
</template>

<script>
import * as d3 from 'd3'
export default {
  props: {
    data: {
      default: 0,
    },
    width: {    //宽高度
      type: Number,
    },
    scale: {
      type: Array,
      default: () =>  [50,70,80,100.1]
      // default: [50,70,80,100.1],//表情也要同步修改
    },
    animate:{
      type: Boolean,
      default: false,
    },
  },
  mounted() {
    if (this.data) {
      this.draw()
    } else {
      this.$watch('data', () => this.draw())
    }
  },
  methods: {
    getRing(r,agl) {
      agl = agl / 360 * 2 * Math.PI
      return [this.cen - Math.sin(agl) * r, this.cen + Math.cos(agl) * r]
    },
    getScore(e) {
      for (let i in this.scale) {
        let last = this.scale[i-1] || 0
        if (e < this.scale[i]) {
          return (e-last) / (this.scale[i]-last) * 45 + 45 * i
        }
      }
    },
    draw(){
      let width = this.width,
          svg = d3.select(this.$el).attr('width', width).attr('height', width*.6),
          fontSize = width * .048,       //文字大小
          r = width * .28,
          dr = width * .4,
          R = width * .48,               //最大外圆
          cen = this.cen = width / 2,
          bg = svg.select('.bg');
      //外弧线
      let start1 = this.getRing(R, 90)
      let end1 = this.getRing(R, 270)
      bg.append('path')
        .attr('stroke-width', 2)
        .attr('stroke', '#5f9ef4')
        .attr('fill', 'none')
        .attr('d', `M${start1[0]} ${start1[1]} A${R} ${R} 0 1 1 ${end1[0]} ${end1[1]}`)
      for (let i = 0; i < 5; i++) {
        let p1 = this.getRing(R-1, 90 + 45 * i)
        let p2 = this.getRing(cen, 90 + 45 * i)
        bg.append('line')
          .attr('x1', p1[0])
          .attr('y1', p1[1])
          .attr('x2', p2[0])
          .attr('y2', p2[1])
          .attr('stroke', '#5f9ef4')
          .attr('stroke-width', 2)
      }
      bg.append('text')
        .attr('font-size', fontSize)
        .attr('fill', '#738897')
        .attr('x', fontSize*.5)
        .attr('y', cen + fontSize*1.4)
        .text('低')
      bg.append('text')
        .attr('font-size', fontSize)
        .attr('fill', '#738897')
        .attr('x', width - fontSize*.5)
        .attr('y', cen + fontSize*1.4)
        .text('高')
      //中间弧线
      let start2 = this.getRing(dr, 90)
      let end2 = this.getRing(dr, 270)
      bg.append('path')
        .attr('stroke-width', fontSize)
        .attr('stroke', '#FFF')
        .attr('fill', 'none')
        .attr('d', `M${start2[0]} ${start2[1]} A${dr} ${dr} 0 1 1 ${end2[0]} ${end2[1]}`)
      //内半圆
      let start3 = this.getRing(r, 90)
      let end3 = this.getRing(r, 270)
      bg.append('path')
        .attr('fill', 'url(#shadow2)')
        .attr('stroke', 'none')
        .attr('d', `M${start3[0]} ${start3[1]} A${r} ${r} 0 1 1 ${end3[0]} ${end3[1]}`)
      //表情
      bg.append('line')
        .classed('eye', this.animate ? true : false)
        .attr('x1', cen-fontSize*1.2)
        .attr('y1', r+fontSize*.4)
        .attr('x2', cen-fontSize*1.2)
        .attr('y2', r+fontSize)
        .attr('stroke', '#c5efff')
        .attr('stroke-width', fontSize*.66)
        .attr('stroke-linecap', 'round')
        .attr('style', `transform-origin:${cen-fontSize*1.2}px ${r+fontSize*.4}px`)
      bg.append('line')
        .classed('eye', this.animate ? true : false)
        .attr('x1', cen+fontSize*1.2)
        .attr('y1', r+fontSize*.4)
        .attr('x2', cen+fontSize*1.2)
        .attr('y2', r+fontSize)
        .attr('stroke', '#c5efff')
        .attr('stroke-width', fontSize*.66)
        .attr('stroke-linecap', 'round')
        .attr('style', `transform-origin:${cen+fontSize*1.2}px ${r+fontSize*.4}px`)
        // .append('animateTransform')
        // .attr('attributeName', 'transform')
        // .attr('type', 'scale')
        // .attr('repeatCount', 'indefinite')
        // .attr('dur', '2s')
        // .attr('from', `1 1`)
        // .attr('to', `1 0`)

      //分数弧
      let angle = this.getScore(this.data), //旋转角度
          end  = this.getRing(dr, 90 + angle), 
          girth = angle / 180 * R * Math.PI, //周长
          arcPath = svg.append('path')
      arcPath.attr('stroke-width', fontSize)
              .attr('stroke', 'url(#shadow2)')
              .attr('fill', 'none')
              .attr('d', `M${start2[0]} ${start2[1]} A${dr} ${dr} 0 0 1 ${end[0]} ${end[1]}`)
              .attr("stroke-dasharray", girth)
              .attr("stroke-dashoffset", 0)
      if (this.animate) {
        arcPath.append('animate')
                .attr('attributeName', 'stroke-dashoffset')
                .attr('fill', 'freeze')
                .attr('dur', '.6s')
                .attr('from', girth)
                .attr('to', 0)
      }
      //口型
      let mouth = svg.append('path'), X1 = cen-fontSize*1.5, X2 = cen+fontSize*1.5
      if (this.data < this.scale[0]) { //难过
        mouth.attr('stroke-linecap', 'round')
            .attr('fill', 'none')
            .attr('stroke', '#c5efff')
            .attr('stroke-width', fontSize*.5)
            .attr('d', `M${X1} ${r*1.6} Q${cen} ${R-fontSize*2} ${X2} ${r*1.6}`)//难过
        if (this.animate) {
          mouth.append('animate')
              .attr('attributeName', 'd')
              .attr('dur', '.6s')
              .attr('from', `M${X1} ${r*1.6} Q${cen} ${r*1.6} ${X2} ${r*1.6}`)
              .attr('to', `M${X1} ${r*1.6} Q${cen} ${R-fontSize*2} ${X2} ${r*1.6}`)
        }
      } else if (this.data < this.scale[1]) {  //微笑
        mouth.attr('stroke-linecap', 'round')
            .attr('fill', 'none')
            .attr('stroke', '#c5efff')
            .attr('stroke-width', fontSize*.5)
            .attr('d', `M${X1} ${r*1.5} Q${cen} ${R} ${X2} ${r*1.5}`)//微笑
        if (this.animate) {
          mouth.append('animate')
              .attr('attributeName', 'd')
              .attr('dur', '.6s')
              .attr('from', `M${X1} ${r*1.5} Q${cen} ${r*1.5} ${X2} ${r*1.5}`)
              .attr('to', `M${X1} ${r*1.5} Q${cen} ${R} ${X2} ${r*1.5}`)
        }
      } else {  //大笑
        mouth.attr('fill', '#c5efff')
            .attr('stroke', 'none')
            .attr('d', `M${X1} ${r*1.4} Q${cen} ${width*.51} ${X2} ${r*1.4}`)//大笑
        if (this.animate) {
          mouth.append('animate')
              .attr('attributeName', 'd')
              .attr('dur', '.4s')
              .attr('from', `M${X1} ${r*1.4} Q${cen} ${r*1.4} ${X2} ${r*1.4}`)
              .attr('to', `M${X1} ${r*1.4} Q${cen} ${width*.51} ${X2} ${r*1.4}`)
        }
      }
    },
  },
}
</script>
<style scope lang="scss">
.pointer{
  display: inline-block;
  margin-top: 60px;
  text{
    text-anchor: middle;
    dominant-baseline: middle;
  }
  .eye{
    animation: wink 8s linear infinite;
  }
  @keyframes wink {
    0%{transform: scaleY(1)}
    98%{transform: scaleY(1)}
    99%{transform: scaleY(0)}
    100%{transform: scaleY(1)}
  }
}
</style>
