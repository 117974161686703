<template>
  <div class="beePlay">
    <img :src="info.cover_img" class="front">
    <h1>{{currIndex!=='' ? mp3[currIndex].title : '-'}}</h1>
    <h2>课程主题:{{info.theme}}</h2>
    <div class="bar">
      <mt-range v-model="rate" :min="0" :max="200" :bar-height="6
       " @input="touch=true" @change="touchend" :disabled="currIndex===''"/>
      <div class="flex-b">
        <span>{{setTime(nowTime)}}</span>
        <span>{{setTime(totalTime)}}</span>
      </div>
    </div>
    <div class="btn flex-c-c">
      <b @click="next(-1)"></b>
      <i class="load" v-if="state=='load'" />
      <i class="pause" @click="pause" v-else-if="state=='pause'" />
      <i class="play" @click="play(currIndex||0)" v-else/>
      <b @click="next(1)"></b>
    </div>
    <audio id="player" preload="auto"/>
    <div class="menu">
      <nav class="flex-a">
        <span :class="{curr:tab==0}" @click="tab=0">目录</span>
        <span :class="{curr:tab==1}" @click="tab=1">详情</span>
      </nav>
      <ul v-if="tab==0">
        <li class="flex-b-c" :class="{active:currIndex===i}" v-for="(item,i) in mp3" :key="i">
          <div>
            <h3 class="flex--c">{{item.title}}<span class="flex-b-c" :class="{stop:state=='play'}"><i/><i/><i/></span></h3>
            <h4>时长 {{setTime(item.total_time)}}</h4>
          </div>
          <div class="button" v-if="currIndex==i && state=='pause'" @click="pause"><i class="pause"/></div>
          <div class="button" v-else @click="play(i)"><i class="play"/></div>
        </li>
      </ul>
      <div class="content" v-else>
        <div v-html="info.content"></div>
      </div>
    </div>
  </div>
</template>
<script>
var audio
export default {
  data(){
    return {
      id: this.$route.query.id,
      info: {},
      state: 'play',  // play,pause,load
      tab: 0,
      nowTime: 0,
      totalTime: 0,
      rate: 0,
      touch: false,
      currIndex: '',
      mp3: [
        // {play_url:'https://api.44h4.com/wz/mp3.php/288010178.mp3',title:'罗刹海市'},
        // {play_url:'https://api.44h4.com/wz/mp3.php/175381933.mp3',title:'万疆'},
        // {play_url:'https://api.44h4.com/wz/mp3.php/440623.mp3',title:'说好的幸福呢'},
      ],
    }
  },
  created(){
    this.$http.post(`${this.ajaxUrlPath}/BeeCourse/checkbuy?hy_session=${localStorage.getItem("session")}&course_id=${this.id}`).then(({data}) => {
      if (data.code == 2) {
        this.$http.post(this.ajaxUrlPath+'/BeeCourse/getCourseSubject', {course_id: this.id}).then(({data}) => {
          if (data.code == 0) {
            this.info = data.data.info
            this.mp3 = data.data.subject
            //进入播放
            let i = sessionStorage.getItem('play'),
                obj = JSON.parse(localStorage.getItem("rate_"+this.id))
            if (i) {
              sessionStorage.removeItem('play')
              this.play(Number(i))
            } else if (obj) {
              this.currIndex = obj.index
              this.totalTime = this.mp3[obj.index].total_time
              this.nowTime = audio.currentTime = obj.time
              audio.src = this.mp3[obj.index].play_url
              audio.ontimeupdate = () => this.nowTime = audio.currentTime
            }
          } else {
            this.$toast({message: data.msg})
          }
        }, () => this.$toast({message:'网络错误'}))
      } else if (data.code == 1) {
        this.$toast({message: '请先购买'})
      } else if (data.code == 101) {
        console.log('https://api.app.huayanspace.com/WeixinH5/login?url='+encodeURIComponent(location.href))
      }
    })
  },
  mounted(){
    audio = document.getElementById("player")
    audio.onended = () => {
      console.log('end')
      let j = this.currIndex + 1
      if (this.mp3[j]) {
        this.play(j)
      } else {
        this.state = 'play'
        this.currIndex = ''
        this.$toast({message: '已播放完毕'})
        this.touch = false
        this.rate = 200
        localStorage.removeItem('rate_' + this.id)
      }
    }
    audio.onpause = () => this.state = 'play'
    audio.onwaiting = () => this.state = 'load'
    audio.onplaying = () => this.state = 'pause'

    window.addEventListener('beforeunload', this.saveTime)
  },
  destroyed() {
    window.removeEventListener('beforeunload', this.saveTime)
    this.saveTime()
  },
  methods:{
    play(i) {
      if (this.currIndex !== i) {
        audio.ontimeupdate = null
        this.nowTime = 0
        audio.src = this.mp3[i].play_url
        this.$http.post(`${this.ajaxUrlPath}/BeeCourse/playRecord?subject_id=${this.mp3[i].id}`)
      }
      this.currIndex = i
      audio.play()
      audio.oncanplay = () => {
        console.log('canplay')
        this.state = 'pause'
        this.totalTime = audio.duration //获取总时长
        audio.ontimeupdate = () => this.nowTime = audio.currentTime //获取当前时间
      }
    },
    pause() {
      audio.pause()
    },
    touchend() {
      this.touch = false
      let time = this.rate / 200 * this.totalTime
      audio.currentTime = time
      audio.oncanplay = null
    },
    next(i) {
      if (typeof(this.currIndex) == 'number') {
        let j = this.currIndex + i
        if (this.mp3[j]) {
          this.play(j)
        } else {
          this.$toast({message: '没有更多了'})
        }
      }
    },
    setTime(time) {
      let t = new Date(time * 1000).toISOString()
      // if (time > 3599)
      //   return t.substr(11, 8)
      // else
        return t.substr(14, 5)
    },
    saveTime() {
      if (this.currIndex !== '') {
        let obj = {index: this.currIndex, time: this.nowTime}
        localStorage.setItem("rate_"+this.id, JSON.stringify(obj))
      }
    },
  },
  watch: {
    nowTime(e){
      if (!this.touch) this.rate = e / this.totalTime * 200
    }
  },
  // beforeRouteLeave(to, from, next){
  //   if (this.currIndex !== '') {
  //     let obj = {index: this.currIndex, time: this.nowTime}
  //     localStorage.setItem("rate_"+this.id, JSON.stringify(obj))
  //   }
  //   next()
  // }
}
</script>
<style lang="scss">
.beePlay{
  overscroll-behavior-y: none;
  padding: 1px 0;
  background: #f6f6f6;
  height: 100%;
  user-select: none;
  .front{
    width: 170px;
    height: 210px;
    background: #f1f7f2;
    margin: 30px auto;
  }
  h1{
    font-size: 30px;
    color: #292929;
    margin-bottom: 10px;
    line-height: 40px;
    height: 40px;
  }
  h2{
    font-size: 24px;
    color: #8f9192;
    line-height: 2;
  }
  .bar{
    margin: 40px 30px 20px;
    span{
      font-size: 24px;
      color: #ccc;
      margin-right: 2PX;
    }
    .mt-range-content{margin-right: 20PX;}
    .mt-range-runway{border-radius: 6PX;right: -18PX;border-top-color: #e5e5e5}
    .mt-range-progress{border-top-left-radius: 6PX;border-bottom-left-radius: 6PX;background-color: #50bf5e}
    .mt-range-thumb{background-color: #50bf5e;width: 20PX;height: 20PX;top: 5PX;border: 5PX solid #fff;}
    .mt-range--disabled{opacity: .8;}
  }
  .btn{
    margin: 40px 30px 10px;
    b{
      position: relative;
      border-right: 36px solid #2c9e3a;
      border-top: 20px solid transparent;
      border-bottom: 20px solid transparent;
      &::before{
        content: '';
        position: absolute;
        height: 36px;
        left: -1px;
        top: -18px;
        border-left: 1px solid #2c9e3a;
      }
      &:last-child{transform: rotate(180deg)}
    }
    i{
      width: 120px;
      height: 120px;
      margin: 0 120px;
      border-radius: 50%;
      background: linear-gradient(to right,#50bf5e,#279a35);
      box-shadow: 0 10px 16px rgba(73,167,91,.3);
      position: relative;
      &::before{
        content: '';
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%,-50%);
      }
    }
    .pause::before{
      width: 20px;
      height: 40px;
      border-left: 4px solid #fff;
      border-right: 4px solid #fff;
    }
    .play::before{
      left: 55%;
      border-left: 36px solid #fff;
      border-top: 20px solid transparent;
      border-bottom: 20px solid transparent;
    }
    .load::before{
      background: url('../../../assets/images/product/loading.gif') no-repeat;
      background-size: 100%;
      width: 40px;
      height: 40px;
    }
  }
  .menu{
    position: absolute;
    left: 0;
    bottom: 0;
    background: #fff;
    box-shadow: 0 0 12px rgba(200,200,200,.2);
    width: 100%;
    height: calc(100vh - 710px);
    padding: 0 30px;
    border-top-left-radius: 40px;
    border-top-right-radius: 40px;
    nav{
      border-bottom: 1px solid #f0f0f0;
      font-size: 28px;
      color: #8f9192;
      span{
        line-height: 80px;
        position: relative;
        width: 100px;
      }
      .curr{
        color: #292929;
        font-weight: bold;
        &::after{
          content: '';
          position: absolute;
          width: 50px;
          height: 6px;
          background: #279a35;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }
    ul{
      height: calc(100% - 82px);
      overflow-y: auto;
      li{
        text-align: left;
        margin: 24px 0;
        padding-left: 10px;
        line-height: 1.8;
        white-space: nowrap;
        .button{
          padding: 12px 10px;
          position: relative;
          &::before{
            content: '';
            position: absolute;
            right: 100%;
            top: 0;
            height: 100%;
            width: 620px;
          }
          i{
            display: block;
            width: 48px;
            height: 48px;
            border-radius: 50%;
            border: 1px solid #8f9192;
            position: relative;
            &::after{
              content: '';
              position: absolute;
              left: 50%;
              top: 50%;
              transform: translate(-50%,-50%);
            }
          }
          .play::after{
            left: 58%;
            border-left: 16px solid #8f9192;
            border-top: 10px solid transparent;
            border-bottom: 10px solid transparent;
          }
          .pause::after{
            width: 10px;
            height: 16px;
            border-left: 2px solid #8f9192;
            border-right: 2px solid #8f9192;
          }
        }
        h3{
          font-size: 28px;
          color: #292929;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 500px;
          span{
            width: 24px;
            height: 40px;
            margin-left: 20px;
            display: none;
            i{
              width: 4px;
              height: 0px;
              border-radius: 2px;
              background: #c7f1cb;
              animation: equa .7s infinite;
            }
            i:nth-child(2){animation-duration: 1s;}
            i:nth-child(3){animation-duration: 1.3s;}
          }
          .stop i{animation-play-state: paused}
          @keyframes equa {
            50%{height: 24px;}
            100%{height: 0;}
          }
        }
        h4{
          font-size: 22px;
          color: #b3b3b3;
        }
      }
      .active{
        h3 span{display: flex}
        .button{
          i{border-color: #65b16d}
          .play::after{border-left: 16px solid #65b16d;}
          .pause::after{border-color: #65b16d}
        }
      }
      &:after{
        content: '';
        position: absolute;
        width: 100%;
        height: 40px;
        left: 0;
        bottom: 0;
        z-index: 2;
        background: linear-gradient(rgba(255,255,255,.1), #fff)
      }
    }
    .content{
      height: calc(100% - 82px);
      overflow-y: auto;
    }
  }
  .audio{display: none;}
}
</style>