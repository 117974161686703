<template>
  <div class="report">
    <header>
      <img :src="avatar" alt="头像">{{name}}
    </header>
    <h1>您的MBTI人格为</h1>
    <h2>{{result}}</h2>
    <h3>{{report[result].type}}</h3>
    <h4>{{report[result].desc}}</h4>
    <h5>
      <span>适合职业</span>{{report[result].job}}
    </h5>
    <!-- <h2>{{MBTI.name}}</h2>
    <div class="h3">{{MBTI.type}}</div>
    <div class="h4">{{MBTI.desc}}</div> -->
    <div class="bottom">
      <img :src="qrcode[keycode]" alt="二维码">
      <p>邀请朋友一起看看&nbsp;<br>身边的同伴是什么类型呢？</p>
    </div>
  </div>
</template>
 
<script>
export default {
  data(){
    return{
      result:  this.$route.query.result || 'ESTP',
      avatar: this.$route.query.avatar || '',
      name: '',
      hy_session: this.$route.query.hy_session || '',
      keycode: this.$route.query.keycode || 'mifeng',
      qrcode: {
        mifeng: require("../../assets/images/report/topic-code.jpg"),
        shifang: require("../../assets/images/report/code_shifang.jpg"),
        planet: require("../../assets/images/report/code_planet.jpg"),
      },
      report: {
        INFP: {type: '知性特质的哲学家型', desc: '视内在的和谐高于一切，有好奇心和洞察力，在日常事务上又比较灵活多变', job: '各类艺术家、作家、心理咨询师、社会工作者等，都是比较适合哲学家型性格人的职业'},
        INFJ: {type: '灵性特质的作家型', desc: '坚持原则，富有洞察力，诚挚而深切地关心他人', job: '适合的职业有心理咨询师、教育学、哲学等领域的研究者、社会工作者、艺术家等'},
        ENFP: {type: '冒险特质的记者型', desc: '对“可能性”很感兴趣，视灵感高于一切，足智多谋', job: '适合的职业和领域有：广告创意、营销策划、市场调研人员、公关、发言人、老师等'},
        ENFJ: {type: '理性特质的教育家型', desc: '彬彬有礼、富有魅力，通常能够看到其他人的优点', job: '适合的职业有培训师、心理咨询师、节目主持人、大学教授、销售等'},
        INTP: {type: '一板一眼的学者型', desc: '理性，善于分析，喜欢思考复杂的问题并解决难题', job: '适合软件开发工程师、大学教授、经济学家、律师、作家和艺术家等'},
        INTJ: {type: '实事求是的专家型', desc: '完美主义者，独立、具怀疑精神，是优秀的战略思想家，对于感兴趣的问题，他们也是出色的组织者', job: '适合的岗位有科学家、研究人员、各类咨询顾问、证券分析师以及艺术家、设计师等'},
        ENTP: {type: '推陈出新的发明家型', desc: '喜欢挑战、足智多谋、聪明健谈', job: '适合做投资顾问、广告创意、访谈类节目主持人、政治家等'},
        ENTJ: {type: '外刚内柔的统帅型', desc: '热情而有真诚的天生领导者，有远见，乐于解决复杂的问题', job: '适合的职业有企业创始人或者高管、财务顾问、企业战略顾问、培训师、律师、大学教授等 '},
        ISFJ: {type: '让人依靠的照顾者型', desc: '安静友好，乐于为他人服务', job: '没有明显的职业倾向，可以考虑从事行政管理人员、总经理助理、秘书及护士等相关职业'},
        ISTJ: {type: '按部就班的公务员型', desc: '责任心强、严肃可靠，能够把工作和家庭生活各方面都安排得井井有条', job: '适合的行业和职业包括：政府机构、医疗领域、金融银行业里的公务员、医师、药剂师和信贷员、精算师等'},
        ESFJ: {type: '善于照顾人的主人型', desc: '实事求是、注意细节，特别注意与他人的人际关系是否融洽', job: '适合的职业有秘书、总经理助理、服务业从业人员、项目经理、学校管理者等'},
        ESTJ: {type: '卓越领导式的大男人型', desc: '务实有条理，讲究纪律，喜欢监督他人', job: '各大中型企业的员工、基层管理者；生产制造型企业的中基层管理者'},
        ISFP: {type: '浪漫另类的艺术家型', desc: '平和、敏感，常常具备比较好的艺术天赋和审美能力', job: '适合的职业有时装首饰的设计师、画家、舞蹈演员，旅游行业、体育用品行业等的销售人员'},
        ISTP: {type: '求新求变的冒险家型', desc: '擅长分析，讲究实效和行动', job: '适合做机械、电气等技术工程师、证券分析师等以分析和逻辑见长的工作，以及赛车手、飞行员等具备一定冒险性的职业，警察也是非常适合ISTP类型的人从事的'},
        ESFP: {type: '引人瞩目的表演者型', desc: '富有同情心，擅长交际，常常是社交群体注意力中心', job: '适合的职业有各类销售人员、创意人员、节目主持人、社区工作者等'},
        ESTP: {type: '魅力四射的挑战者型', desc: '优秀的问题解决者，天真率直，多才多艺，社交气氛活跃者', job: '适合的工作有销售人员、自由职业者、娱乐节目主持人、脱口秀演员等'},
      },
    }
  },
  created(){
    this.$http.get('Sale/getUserInfo?hy_session='+this.hy_session).then(({data})  => {
      if (data.code==0) {
        this.name = data.data.realname || data.data.nickname
        this.avatar = data.data.avatar
      }
    })
  },
}
</script>

<style scoped lang="scss">
.report{
  position: relative;
  color: #fff;
  width: 750px;
  height: 1090px;
  box-sizing: border-box;
  padding: 30px 40px;
  background: url('../../../static/img/MBTI_bg2.jpg') no-repeat;
  background-size: 100% 100%;
}
header{
  display: flex;
  align-items: center;
  font-size: 30px;
  line-height: 80px;
  img{
    width: 80px;
    height: 80px;
    border-radius: 50%;
    background: #c2ddff;
    overflow: hidden;
    margin-right: 20px;
  }
}
h1{
  text-align: center;
  letter-spacing: 10px;
  padding-left: 10px;
  margin-top: 30px;
  font-size: 32px;
}
h2{
  color: #ffda5b;
  font-size: 76px;
  text-align: center;
  margin-top: 66px;
  line-height: 1;
}
h3{
  color: #ffda5b;
  font-size: 34px;
  text-align: center;
  margin-top: 22px;
  font-weight: bold;
}
h4{
  margin: 90px 50px 0;
  font-size: 30px;
  line-height: 1.5;
  text-align: justify;
}
h5{
  margin: 70px 40px 0;
  padding: 40px 0;
  border-top: 1px dashed #fff;
  border-bottom: 1px dashed #fff;
  font-size: 28px;
  text-align: left;
}
h5 span{
  display: block;
  margin-bottom: 20px;
}
.bottom{
  position: absolute;
  right: 30px;
  bottom: 30px;
  img{
    width: 100px;
    height: 100px;
    margin-left: 20px;
    float: right;
  }
  p{
    font-size: 22px;
    text-align: right;
    letter-spacing: 1px;
    align-self: flex-end;
    line-height: 1.6;
    float: right;
    padding-top: 20px;
  }
}
</style>