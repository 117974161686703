<template>
  <!--报告-->
  <self_1_0 v-if="version=='1.0.0'||version=='1.1.0'" :version="version" :hy_session="hy_session" :exam_id="exam_id"/>
</template>

<script>
import self_1_0 from './self1.0.vue'
export default {
  components:{self_1_0},
  data(){
    return{
      exam_id: this.$route.query.exam_id || '',
      hy_session: this.$route.query.hy_session || '',
      version: this.$route.query.ver || '',
    }
  },
  mounted(){
      this.$http.get(this.planetUrl+`/Module/getReportVersion?exam_id=${this.exam_id}`).then(({data}) => {
        if (data.code == 0) {
          this.version = data.data.version
        } else {
          this.$toast({message:'无法加载',duration: 2000})
          console.error(data.msg)
        }
      })
  },
}
</script>
