<template>
  <div class="index">
    <img class="pic" :src="banner">
    <div class="h1" @click="loginOut"><img src="../../../assets/images/product/explain1.png">评估概述</div>
    <div v-html="content" class="text"></div>
    
    <div class="bottom">
      价格 ¥&nbsp;<span>{{price}}</span>
      <div class="btn" @click="show">立即购买</div>
    </div>

    <div class="mask" v-show="payShow" @click="payShow=false"></div>
    <div class="buy" :style="`transform: translateY(${payShow?0:100}%)`">
      <div class="close" @click="payShow=false"><img src="../../../assets/images/icons/close_2.png"></div>
      <div class="num_input">
        购买数量
        <div class="input-box">
          <div @click="changeNum(-1)">-</div>
          <input type="number" maxlength="5" v-model="payNum" @input="changeNum">
          <div @click="changeNum(1)">+</div>
        </div>
      </div>
      <div class="total">合计：<span>¥ {{payMoney}}</span></div>
      <div class="pay" @click="pay">确认支付</div>
    </div>
  </div>
</template>
<script>
export default {
  data(){
    return {
      product_id: sessionStorage.wxPayProductId||this.$route.query.productId,//6,11
      banner: require('../../../../static/img/team3.jpg'),
      content: '',
      payShow: false,
      price: 0,
      payNum: 1,
      payMoney: 0,
      login: false,
    }
  },
  created(){
    document.title = '产品与服务'
    this.$http.get(this.spaceUrl+'/User/checklogin',).then(e => {
      if (e.data.code == 0) {
        this.login = true
      }
    })
    this.$http.get(this.spaceUrl+'/Product/getInfo?product_id='+this.product_id,).then(({data}) => {
      if (data.code == 0) {
        this.content = data.data.content
        this.banner = data.data.product_img
        this.price = this.payMoney = data.data.price
      } else {
        this.$toast({message: data.msg});
      }
    })
  },
  methods:{
    loginOut(){this.$http.get(this.spaceUrl+'/User/loginOut')},
    show(){
      if (!this.login) {
        location.href = this.spaceUrl + '/WeixinH5/login?url=' + encodeURIComponent(this.mobileUrl+'/#/wxPay/index')
        this.login = true
      } else {
        this.payShow = true
      }
    },
    // 改变数量
    changeNum(e){
      let num;
      if (e.target) num = e.target.valueAsNumber || 0
      else num = this.payNum + e
      if (num > 9999) {
        this.payNum = 9999
        this.payMoney = (9999 * this.price).toFixed(2)
      } else if (num < 0) {
        this.payNum = 0
        this.payMoney = 0
        this.$toast({message: '不能再少了', duration: 1000})
      } else {
        if (this.product_id == 11 && num > 10) {
          this.payNum = 10
          this.payMoney = (10 * this.price).toFixed(2)
          this.$toast({message: '每人每天只能买10个', duration: 1000})
          return
        }
        this.payNum = num
        this.payMoney = parseFloat( (num * this.price).toFixed(2) )
      }
    },
    //购买
    pay(){
      if (!this.payNum) {
        this.$toast({message: '请先输入数量', duration: 1000}); return;
      }
      this.$indicator.open({text: '加载中...',spinnerType: 'fading-circle'});
      this.$http.get(this.spaceUrl+'/Product/createOrder',{params:{
        product_id: this.product_id,
        number: this.payNum,
      }}).then(({data}) => {
        this.$indicator.close();
        if (data.code == 0) {
          // onBridgeReady();
          // function onBridgeReady() {
              WeixinJSBridge.invoke('getBrandWCPayRequest', data.data,(res)=>{
                this.payShow = false
                if (res.err_msg == "get_brand_wcpay_request:ok") {
                  this.login = true
                  this.$router.push({path: '/wxPay/success'});
                } else {
                  this.$toast({message: '支付失败', duration: 1000})
                }
              });
          // }
        } else {
          this.$toast({message: data.msg})
        }
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.index{
  padding-bottom: 140px;
  height: auto;
  user-select: none
}
.pic{height: auto;display: block;}
img{width: 100%;}
.h1{
  display: flex;
  height: 100px;
  border-bottom: 1px solid #f6f6f6;
  align-items: center;
  color: #48b3f6;
  margin: 50px 40px 24px;
  font-size: 30px;
}
.h1 img{
  width: 26px;
  height: 26px;
  margin-right: 16px;
}
.text{
  text-align: justify;
  font-size: 26px;
  line-height: 1.6;
  color: #999;
  margin: 0 40px;
}
.bottom{
  border-top: 1px solid #f6f6f6;
  position: fixed;
  width: 100%;
  padding: 0 30px;
  height: 120px;
  left: 0;
  bottom: 0;
  box-sizing: border-box;
  background: #fff;
  display: flex;
  align-items: center;
  text-align: center;
  font-size: 24px;
  color: #999;
  span{color: #f15454;font-size: 30px}
}
.btn{
  line-height: 80px;
  background: #33a1fb;
  color: #fff;
  border-radius: 6px;
  letter-spacing: 2px;
  margin: 0 20px;
  font-size: 28px;
  flex-grow: 1;
}
.red{
  background: #f15454;
  margin: 0;
  flex-grow: 0;
  flex-shrink: 0;
  padding: 0 20px;
}

/* 购买弹窗 */
.mask{
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: rgba($color: #000, $alpha: .5);
  z-index: 10;
}
.buy{
  position: fixed;
  width: 100%;
  box-sizing: border-box;
  background: #fff;
  left: 0;
  bottom: 0;
  transition: transform .2s;
  padding:  80px 40px 60px;
  font-size: 28px;
  z-index: 11;
}
.close{
  position: absolute;
  top: 10px;
  right: 30px;
  padding: 10px;
}
.close img{
  width: 26px;
  height: 26px;
}
.num_input{
  display: flex;
  justify-content: space-between;
  color: #b3b3b3;
  align-items: center;
  margin: 14px 0 20px;
  padding:20px 0 60px;
  border-bottom: 1px solid #f6f6f6;
  position: relative;
}
.num_input .buy_text{
  position: absolute;
  height: 30px;
  left: 0;
  right: 50px;
  top: -80px;
  color: #f15454;
  font-size: 26px;
}
.input-box{
  display: flex;
  justify-content: center;
  height: 70px;
  width: 260px;
  text-align: center;
  margin: 0 auto ;
  border: 1px solid #e5e5e5;
  border-radius: 6px;
  user-select: none;
}
.input-box div{
  width: 70px;
  line-height: 70px;
  flex-shrink: 0;
}
.input-box input{
  width: 50%;
  height: 100%;
  box-sizing: border-box;
  font-size: 30px;
  border-left: 1px solid #e5e5e5;
  border-right: 1px solid #e5e5e5;
  color: #1a1a1a;
  font-weight: bold;
  flex: 1;
  text-align: center;
}
.total{
  text-align: center;
  font-size: 28px;
  font-weight: bold;
}
.total span{
  color: #33a1fb;
}
.pay{
  line-height: 90px;
  background: #33a1fb;
  border-radius: 8px;
  text-align: center;
  color: #fff;
  font-size: 32px;
  margin: 60px 0 60px;
}
.payNot{
  background: #e5e5e5;
  color: #4d4d4d;
  font-size: 30px;
}
</style>