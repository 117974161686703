<!-- 传入数据说明（*代表必要元素）
  type：扇形类型（控制颜色和维度名称，实际是颜色和维度的索引值）
  data*：分数
  width*：圆的宽度
  animate：是否启动动画
-->
<template>
  <svg class="fan_chart"></svg>
</template>
<script>
import * as d3 from 'd3'
export default {
  props: {
    data: {
      type: Object,
    },
    width: {
      type: Number,
    },
    type:{
      typ:Number,
      default:0
    },
    animate:{
      type:Boolean,
      default:false,
    },
  },
  data(){
    return{
      svg:'',
      center: 0,
      radius:0,
      bgcolor:['#fcda7e','#81b9f8','#fb8f9a','#d789f8','#f8d67b','#7a91fa','#f7ab79','#79e0b9'],
      color:['#daa209','#156bcb','#e42e42','#9f15d9','#e3ac05','#3954ce','#d96e28','#1fab75'],
      text:['自我经营','成就动机','利他思维','乐观坚韧','人际沟通','学习成长','规划能力','高效行动'],
    }
  },
  mounted(){
    if(this.data){ //本地测试
      this.init()
      this.drawData(this.data)
    }else{ //实际传入数据
      this.$watch('width', () => {
        this.init()
      })
      this.$watch('data', (e) => {
        this.drawData(e)
      })
    }
  },
  methods:{
    compute(r,score){
      let angel = score * 2 * Math.PI / 100
      return [this.center - Math.sin(angel) * r, this.center + Math.cos(angel) * r]
    },
    //初始化图像背景
    init(){
      let svg = this.svg = d3.select(this.$el).attr('width', this.width).attr('height', this.width*1.5)
      let center = this.center = this.width / 2 //中点
      //底层圆
      let bg = svg.append('g').classed('bg',true)
      bg.append('circle')
        .attr('cx', center)
        .attr('cy', center)
        .attr('r', center)
        .attr('stroke', 'none')
        .attr('fill', '#f2f2f2');
      //彩色圆
      let r1 = this.radius = center * 0.92
      bg.append('circle')
        .attr('cx', center)
        .attr('cy', center)
        .attr('r', r1)
        .attr('stroke', 'none')
        .attr('fill', this.bgcolor[this.type]);
      //分数
      bg.append('text')
        .attr('id', 'fan' + this.type)
        .attr('fill', this.color[this.type])
        .attr('font-size', center*0.36)
        .attr('font-weight', 'bold')
        .attr('x', center)
        .attr('y', this.width*1.18)
        .text(0)
      //维度名
      // bg.append('text')
      //   .attr('font-size', center*0.32)
      //   .attr('fill', '#6f6f6f')
      //   .attr('x', center)
      //   .attr('y', this.width*1.44)
      //   .text(this.text[this.type])
    },
    //绘制环形分数
    drawData(e){
      if(e){
        let ele = d3.select(this.$el).select('.path')
        let path = ele.empty() ? this.svg.append('path').classed('path',true) : ele
        path.attr('fill', this.color[this.type]).attr('stroke', 'none')
        d3.select('#fan'+this.type).text(e.score)
        this.svg.append('text')
                .attr('font-size', this.center*0.32)
                .attr('fill', '#6f6f6f')
                .attr('x', this.center)
                .attr('y', this.width*1.42)
                .text(e.name)
        if(this.animate){ //动画展示
          let n = 0.5   //控制流畅度（0.1~1）
          let t = 1000  //完成动画时间（毫秒）
          let nowScore = 0;
          let timer = setInterval(() => {
            if(nowScore>e.score){
              clearInterval(timer)
              return
            }
            let x = this.compute(this.radius,nowScore)
            nowScore += n
            path.attr('d', `M${this.center} ${this.center+this.radius} A${this.radius} ${this.radius} 0 ${nowScore>50?1:0} 1 ${x[0]} ${x[1]} L${this.center} ${this.center}`)
          }, t / ( e.score / n) );
        }else{ //直接画
          let p = this.compute(this.radius,e.score)
          path.attr('d', `M${this.center} ${this.center+this.radius} A${this.radius} ${this.radius} 0 ${e.score>50?1:0} 1 ${p[0]} ${p[1]} L${this.center} ${this.center}`)
        }
      }
    }
  },
}
</script>
<style scoped lang="scss">
.fan_chart{
  margin: 44px 10px 20px;
  display: inline-block;
  text{
    text-anchor: middle;
    dominant-baseline: middle;
  }
}
</style>
