<template>
  <div class="pie_chart">
    <svg></svg>
    <p v-if="!hide">点击维度名称可查看说明</p>
    <div class="tip" v-if="showIndex!=null">
      <h6>{{data[showIndex].dimension_detail}}</h6>
      <div class="close" @click="showIndex=null">关 闭</div>
    </div>
  </div>
</template>

<script>
import * as d3 from 'd3'
export default {
  props: {
    name:{
      type: String,
    },
    score:{
      type: Array
    },
    width: {    //宽高度
      type: Number,
    },
    hide:{
      default:0
    },
  },
  data(){
    return{
      centerX: 0,
      centerY: 0,
      color:['#ed4c5f','#ed4c81','#ed4cb2','#ed4ce7','#f8a023','#ffb922','#fac628','#f6e632'],
      // text:['成就动机','学习成长','人际沟通','高效行动','规划能力','利他思维','乐观坚韧','自我经营'],
      showIndex:null,
      data:[],
    }
  },
  mounted(){
    if(this.name){
      this.draw()
      return
    }
    this.$watch('name', () => {
      this.draw()
    })
  },
  methods:{
    compute(r,score){
      let angel = score * 2 * Math.PI
      return [this.centerX + Math.sin(angel) * r, this.centerY - Math.cos(angel) * r]
    },
    draw(){
      let height = this.width * 0.75
      let svg = d3.select(this.$el).select('svg').attr('width', this.width).attr('height', height)
      let R = height * 0.7 * 0.5   //圆半径
      let fontSize = R*.15
      let centerX = this.centerX = this.width / 2         //x轴中点
      let centerY = this.centerY = height / 2             //y轴中点
      let gap =  height * 0.15

      //计算坐标
      let point = [],
          dataArr = [],   // 二维数组数据 -> 一维8项数据
          total = 0,      // 总分
          total1 = 0,     // 第一部分(职业素质)总分
          r1 = R * 2 / 3, // 中间圆半径
          linePoint = [], // 折线坐标字符串
          dotPoint = []   // 折线终点坐标
      for(let i = 0; i < this.score.length; i++){
        for(let j =0; j < this.score[i].length; j++){
          let item = this.score[i][j]
          dataArr.push(item)
          total += item.percent
          point.push(this.compute(R,total)) //各饼状弧终点坐标（顺时针）
          if(i===0) total1 = total
          //以下是计算：圆外各元素的坐标
          let p = this.compute(R, total - item.percent/2)//弧形中点坐标
          if(p[0]>centerX && p[1]<centerY-r1){ //右上角
            let n1 = (p[0]- centerX) * .9
            let n2 = (centerY - p[1]) * .15
            var dotX = p[0]+n1+8, dotY = p[1]-n2;
            linePoint.push(`${p[0]},${p[1]} ${p[0]+8},${dotY} ${dotX},${dotY}`)
          }else if(p[0]>centerX && p[1]>centerY+r1){ //右下角
            let n1 = (p[0]- centerX) * .9
            let n2 = (p[1] - centerY) * .15
            var dotX = p[0]+n1+8, dotY = p[1]+n2;
            linePoint.push(`${p[0]},${p[1]} ${p[0]+8},${dotY} ${dotX},${dotY}`)
          }else if(p[0]>centerX){ //右中
            let n = (p[0]- centerX) * .44
            var dotX = p[0]+n, dotY = p[1];
            linePoint.push(`${p[0]},${p[1]} ${dotX},${dotY}`)
          }else if(p[0]<centerX && p[1]<centerY-r1){ //左上角
            let n1 = (centerX - p[0]) * .9
            let n2 = (centerY - p[1]) * .15
            var dotX = p[0]-n1-8, dotY = p[1]-n2;
            linePoint.push(`${p[0]},${p[1]} ${p[0]-8},${dotY} ${dotX},${dotY}`)
          }else if(p[0]<centerX && p[1]>centerY+r1){ //左下角
            let n1 = (centerX - p[0]) * .9
            let n2 = (p[1] - centerY) * .15
            var dotX = p[0]-n1-8, dotY = p[1]+n2;
            linePoint.push(`${p[0]},${p[1]} ${p[0]-8},${dotY} ${dotX},${dotY}`)
          }else if(p[0]<centerX){ //左中
            let n = (centerX - p[0]) * .44
            var dotX = p[0]-n, dotY = p[1];
            linePoint.push(`${p[0]},${p[1]} ${dotX},${dotY}`)
          }else{
            var dotX = p[0]+40, dotY = p[1]+5;
            linePoint.push(`${p[0]},${p[1]} ${p[0]},${dotY} ${dotX},${dotY}`)
          }
          dotPoint.push([dotX,dotY])
        }
      }
      this.data = dataArr
      let circle = svg.append('g').classed('circle',true)
      //循环8个点绘制饼状图
      for(let i=0; i < point.length; i++){
        let p = point[i], startP
        if(i) startP = point[i-1]
        else startP = [centerX,gap]
        circle.append('path')
              .attr('fill', this.color[i])
              .attr('stroke', '#fff')
              .attr('stroke-width',1)
              .attr('d', `M${startP[0]} ${startP[1]} A${R} ${R} 0 0 1 ${p[0]} ${p[1]} L${centerX} ${centerY}`)

      }
      //绘制内圆
        let p_1 = this.compute(r1, total1)
        circle.append('path')//职业素质半圆
              .attr('fill', '#ed674c')
              .attr('stroke', '#fff')
              .attr('stroke-width',1)
              .attr('d', `M${centerX} ${gap+R/3} A${r1} ${r1} 0 ${total1>.5?1:0} 1 ${p_1[0]} ${p_1[1]} L${centerX} ${centerY}`)
        circle.append('path')//原动力半圆
              .attr('fill', '#e5a728')
              .attr('stroke', '#fff')
              .attr('stroke-width',1)
              .attr('d', `M${p_1[0]} ${p_1[1]} A${r1} ${r1} 0 ${total1>.5?0:1} 1 ${centerX} ${gap+R/3} L${centerX} ${centerY}`)
        circle.append('circle')//中间圆
              .attr('cx', centerX)
              .attr('cy', centerY)
              .attr('r', R/3)
              .attr('stroke', '#fff')
              .attr('stroke-width', 1)
              .attr('fill', '#f68a32');
      
      // 绘制文字
        let t_1 = this.compute(R/2, total1)
        let text = svg.append('g').classed('arc', true)
        let arcTxt = text.append('text')
        // '职业素质'文字
        arcTxt.append('path')
              .attr('id', 'path1')
              .attr('stroke', '#fff')
              .attr('d',  () => {
                return `M${centerX} ${gap+R/2} A${R/2} ${R/2} 0 ${total1>.5?1:0} 1 ${t_1[0]} ${t_1[1]}`
              })
        arcTxt.append('textPath')
              .classed('arc_text', true)
              .attr('fill', '#fff')
              .attr('font-size', 12)
              .attr('xlink:href', '#path1')
              .attr('startOffset', '50%')
              .attr('font-size', fontSize)
              .text('职业素质')
        // '原动力'文字
        arcTxt.append('path')
              .attr('id', 'path2')
              .attr('stroke', '#fff')
              .attr('d',  () => {
                return `M${t_1[0]} ${t_1[1]} A${R/2} ${R/2} 0 ${total1>.5?0:1} 1 ${centerX} ${gap+R/2}`
              })
        arcTxt.append('textPath')
              .classed('arc_text', true)
              .attr('fill', '#fff')
              .attr('font-size', 12)
              .attr('xlink:href', '#path2')
              .attr('startOffset', '50%')
              .attr('font-size', fontSize)
              .text('原动力')
        //中心文字
        text.append('text')
            .attr('font-size', fontSize)
            .attr('fill', '#fff')
            .attr('y', centerY - 4)
            .append('tspan')
            .attr('x', centerX)
            .text( this.name.substring(0,2) )
            .append('tspan')
            .attr('x', centerX)
            .attr('dy', '1.4em')
            .text( this.name.substring(2,5) )

        //绘制线条指引
        let line = svg.append('g').classed('line', true)
        for(let i = 0; i < linePoint.length; i++){
          let lineP = linePoint[i]
          line.append('polyline')
              .attr('fill', 'none')
              .attr('stroke', this.color[i])
              .attr('stroke-width',1)
              .attr('points', lineP)
          line.append("circle")
              .attr('cx', dotPoint[i][0])
              .attr('cy', dotPoint[i][1])
              .attr('r', 3)
              .attr('stroke', this.color[i])
              .attr('stroke-width', 1)
              .attr('fill', '#fff');
          line.append('text')
              .attr('font-size', R*.14)
              .attr('fill', '#e29b0b')//'#7991fd' '#f99351'
              .attr('x', dotPoint[i][0])
              .attr('y', ()=>{
                let y = dotPoint[i][1], dy = R*0.15
                y = y > centerY  ?  y + dy  :  y - dy;
                return y
              })
              .text(dataArr[i].name)
              .on("click",()=>{
                this.showIndex = i
              })
        }
    },
  },
}
</script>
<style scope lang="scss">
@keyframes dimFade {
  0%{opacity: 0;transform: translate(-50%,-44%)}
  95%{opacity: 1;transform: translate(-50%,-50%)}
}
.pie_chart{
  margin: 100px 0 60px;
  position: relative;
  p{font-size: 24px;color: #666;}
  .tip{
    position: absolute;
    width: 540px;padding: 30px 40px 10px;
    background: #fff;border-radius: 10px;
    left: 50%;top: 46.8%;transform: translate(-50%,-50%);
    box-shadow: 0 0 6px 2px rgba(200,200,200,.3);
    animation: dimFade .4s forwards;
    h5{
      font-size: 28px;
      color: #333;
      line-height: 2;
    }
    h6{
      text-align: justify;
      font-size: 24px;
    }
    .close{
      width: 100px;
      color: #e29b0b;
      height:40px;
      display: flex;
      align-items: center; 
      justify-content: center;
      margin: 20px auto 10px;
      border-radius: 6px;
    }
  }
  svg{
    display: block;
    margin: 0 auto;
  }
  .arc_text{
    text-anchor: middle;
    dominant-baseline: middle;
  }
  tspan{text-anchor: middle;}
  .line{
    text{
      text-anchor: middle;
      dominant-baseline: middle;
    }
  }
}
</style>
