<!-- 传入数据说明（*代表必要元素）
  type：扇形类型（控制颜色和维度名称，实际是颜色和维度的索引值）
  data*：分数
  width*：圆的宽度
  animate：是否启动动画
-->
<template>
  <svg class="fan">
    <circle :cx="center" :cy="center" :r="center" stroke="none" :fill="bgcolor[index]"></circle>
  </svg>
</template>
<script>
import * as d3 from 'd3'
export default {
  props: {
    data: {
      type: Number,
      default: 0,
    },
    width: {
      type: Number,
      default: 24
    },
    index:{
      typ: Number,
      default: 0
    },
    animate:{
      type: Boolean,
      default: false,
    },
  },
  data(){
    return{
      svg:'',
      center: 0,
      bgcolor:['#90e1fb','#f8a288','#89d0ff','#8896ff','#cf72f6'],
      color: ['#2ebfed','#dc5b34','#2094df','#5264e9','#a41bd7'],
    }
  },
  mounted(){
    this.svg = d3.select(this.$el).attr('width', this.width).attr('height', this.width)
    let center = this.center = this.width / 2 //中点

    if (this.data) { //本地测试
      this.draw()
    } else { //实际传入数据
      this.$watch('data', () => {
        this.draw()
      })
    }
  },
  methods:{
    compute(r,score){
      score = score==100?99.9:score
      let angel = score * 2 * Math.PI / 100
      return [this.center - Math.sin(angel) * r, this.center + Math.cos(angel) * r]
    },
    //初始化图像背景
    draw(){
        let path = this.svg.append('path').classed('path',true)
        path.attr('fill', this.color[this.index]).attr('stroke', 'none')
        if (this.animate) {   //动画展示
            let n = 0.5       //控制流畅度（0.1~1）
            let t = 1000      //完成动画时间（毫秒）
            let nowScore = 0;
            let timer = setInterval(() => {
              if(nowScore>this.data){
                clearInterval(timer)
                return
              }
              let x = this.compute(this.center,nowScore)
              nowScore += n
              path.attr('d', `M${this.center} ${this.center+this.center} A${this.center} ${this.center} 0 ${nowScore>50?1:0} 1 ${x[0]} ${x[1]} L${this.center} ${this.center}`)
            }, t / ( this.data / n) );
        } else { //直接画
            let p = this.compute(this.center,this.data)
            path.attr('d', `M${this.center} ${this.center+this.center} A${this.center} ${this.center} 0 ${this.data>50?1:0} 1 ${p[0]} ${p[1]} L${this.center} ${this.center}`)
        }
    },
  },
}
</script>
