<template>
  <div class="classTool">
    <div class="pic">
      <span>我们做得如何~<b>优秀</b></span>
      <span>我们做了什么事~<b>正确</b></span>
      <span>我们做得如何~<b>糟糕</b></span>
      <span>我们做了什么事~<b>不正确</b></span>
      <ul>
        <li>③<br>将不正确的事<br>做得很好</li>
        <li>④<br>将正确的事<br>做得很好</li>
        <li>②<br>将不正确的事<br>做得很糟糕</li>
        <li>①<br>将正确的事<br>做得很糟糕</li>
      </ul>
    </div>
    
    <h1>将正确的事做得很糟糕</h1>
    <p v-html="content1"></p>
    <h1 class="h2">将不正确的事做得很糟糕</h1>
    <p v-html="content2"></p>
    <h1 class="h3">将不正确的事做得很好</h1>
    <p v-html="content3"></p>
    <h1 class="h4">将正确的事做得很好</h1>
    <p v-html="content4"></p>
  </div>
</template>
<script>
export default {
  data(){
    return {
      id: this.$route.query.id || '',
      content1: '',
      content2: '',
      content3: '',
      content4: '',
    }
  },
  created(){
    this.$http.get(this.planetUrl+'/CourseAuxiliary/getDetail?id='+this.id).then(({data}) => {
      if (data.code == 0) {
        this.content1 = data.data.field_content1
        this.content2 = data.data.field_content2
        this.content3 = data.data.field_content3
        this.content4 = data.data.field_content4
      }
    })
  },
  mounted(){},
  methods:{},
}
</script>
<style lang="scss" scoped>
.classTool{
  padding: 1px 0;
  min-height: 100%;
  background: #1d212d;
  color: #fff;
  .pic{
    margin: 30px 30px 60px;
    padding: 60px 90px;
    background: #2d2b43;
    border-radius: 10px;
    text-align: center;
    font-size: 24px;
    position: relative;
    span{
      position: absolute;
      &:nth-child(1){left: 50%;transform: translateX(-50%);top: 20px}
      &:nth-child(2){top: 50%;transform: translateY(-50%);right: 32px;width: 30px;line-height: 1.2;}
      &:nth-child(3){left: 50%;transform: translateX(-50%);bottom: 20px}
      &:nth-child(4){top: 50%;transform: translateY(-50%);left: 32px;width: 30px;line-height: 1.2;}
      b{font-weight: normal;color: #a9b5ff}
    }
    ul{
      &::after{
        content: '';
        clear: both;
        display: block;
      }
      li{
        margin: 10px;
        width: 235px;
        background: #1ba950;
        border-radius: 8px;
        font-size: 26px;
        padding: 20px 0;
        float: left;
        &:nth-child(2){background: #d73b79;}
        &:nth-child(3){background: #9aa91b;}
        &:nth-child(4){background: #1b75a9;}
      }
    }
  }
  h1{
    font-size: 26px;
    margin: 0 30px;
    line-height: 60px;
    padding-left: 30px;
    position: relative;
    text-align: left;
    &::before{
      content: '';
      position: absolute;
      width: 18px;
      height: 18px;
      border-radius: 50%;
      background: #1b75a9;
      left: 0;
      top: 50%;
      margin-top: -10px;
    }
  }
  .h2::before{background: #9aa91b;}
  .h3::before{background: #1ba950;}
  .h4::before{background: #d73b79;}
  p{
    display: block;
    font-size: 28px;
    margin: 10px 60px 60px;
    text-align: justify;
    word-break: break-all;
    white-space: pre-wrap;
  }
}
</style>