
<template>
  <div class="report" id="report" :class="theme">
    <div class="top">
      <header>
        <div class="label">
          <b>{{org_name}}</b> 报告分析<br>
          {{$formatTime(endTime,'y-m-d h:m')}}<span v-if="version">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;版本号 v{{version}}</span>
        </div>
        <div v-if="!long && !hide" class="btn" @click="save">保存报告</div> 
      </header>
      <nav v-if="!long">
        <span :class="{current:tabId==4}" @click="tab(4)">报告说明</span>
        <span :class="{current:tabId==0}" @click="tab(0)">团队概况</span>
        <span :class="{current:tabId==1}" @click="tab(1)">团队活力</span>
        <span :class="{current:tabId==2}" @click="tab(2)">工作价值<br>倾向</span>
        <span v-if="leaderShow" :class="{current:tabId==3}" @click="tab(3)">领导能量</span>
        <span :class="{current:tabId==5}" @click="tab(5)">组织活力</span>
      </nav>
    </div>
    <ul class="content">
      <!-- 报告说明 -->
      <li class="explain" v-show="tabId==4 || long">
        <div class="view">
          <h3>报告说明</h3>
          <div><b>感谢您</b>的团队完成了组织活力扫描的评估，这是您的团队通过<b>数据赋能，迈向高绩效管理</b>的重要一步。</div>
          <br>
          <div>组织活力扫描的<b>作用</b>在于快速识别团队的<b>工作价值倾向、活力、领导能量、预警</b>等信息，并基于真实客观的数据，结合日常观察和分析，为团队管理改善提供科学、高效的依据。</div>
          <br>
          <div>当您<b>阅读</b>报告时，分数高或低并不代表好、坏、对、错，它只是团队当下真实的面貌，是员工体验层面的群体化学反应，这可能是您平时无法观察到或忽略的团队现状。</div>
          <br>
          <div>当您<b>应用</b>报告时，请结合团队文化、战略、机制、人才现状进行分析，并建议与核心管理层共同探讨和分析数据背后的原因，制定改善目标、行动方案，达成共识，采取一致行动，提高报告的应用价值。</div>
          <br>
          <div v-if="leaderShow">组织活力扫描<b>报告</b>由六部分组成：1.团队基本信息、2.团队画像分析、3.团队活力分析、4.工作价值倾向分析、5.领导能量分析、6.组织活力，帮助您带领团队更好的发展。</div>
          <div v-else>组织活力扫描<b>报告</b>由五部分组成：1.团队基本信息、2.团队画像分析、3.团队活力分析、4.工作价值倾向分析、5.组织活力，帮助您带领团队更好的发展。</div>
        </div>
      </li>
      <!-- 团队概况 -->
      <li v-show="tabId==0 || long">
        <div class="view">
          <h3>团队基本信息</h3>
          <div class="text">
            <b>团队基本信息：</b>包含参评人员的性别比例、层级比例、学历分布、年龄分布的信息。
          </div>
          <div class="text">
            <b>应用建议：</b>对照团队人才结构与标准，可根据公司人才标准进行动态管理。
          </div>
          <div class="message" :class="{messageMini:width<330}">
            <div class="above">
              <div class="box">
                <h4>性别</h4>
                <div class="line" v-for="(item,index) in basic_info.sex_info" :key="index">
                  <h5>{{item.name}}</h5>
                  <fan :data="item.rate" :index="index" :animate="!long"></fan>
                  <h6>{{item.number}}人,{{item.rate}}%</h6>
                </div>
                <div class="number">总人数：{{totalNum}}人</div>
              </div>
              <div class="box">
                <h4>层级</h4>
                <div class="line" v-for="(item,index) in basic_info.tier_info" :key="index">
                  <h5>{{item.name}}</h5>
                  <fan :data="item.rate" :index="index" :animate="!long"></fan>
                  <h6>{{item.number}}人,{{item.rate}}%</h6>
                </div>
              </div>
            </div>
            <div class="under">
              <div class="box">
                <h4>学历</h4>
                <div class="line" v-for="(item,index) in basic_info.educational_info" :key="index">
                  <h5>{{item.name}}</h5>
                  <fan :data="item.rate" :index="index" :animate="!long"></fan>
                  <h6>{{item.number}}人,{{item.rate}}%</h6>
                </div>
              </div>
              <div class="box">
                <h4>年龄</h4>
                <div class="line" v-for="(item,index) in basic_info.era_info" :key="index">
                  <h5>{{item.name}}</h5>
                  <fan :data="item.rate" :index="index" :animate="!long"></fan>
                  <h6>{{item.number}}人,{{item.rate}}%</h6>
                </div>
                <div class="number">平均年龄：{{basic_info.avg_age}}岁</div>
              </div>
            </div>
          </div>
        </div>

        <div class="view">
          <h3>团队画像</h3>
          <div class="text">
            <b>团队画像：</b>让您的团队情况一目了然。画像中心是团队成员对工作的需求倾向和行为偏好；【原动力】是驱使团队采取行动的内在力量；【职业素质】是团队成员支撑工作行为的能力基础；【领导能量】是管理层带领团队实现目标的能量；【团队活力】是团队发展和创新的原动力来源。
          </div>
          <div class="text">
            <b>应用建议：</b>从全局视角，快速识别团队成员的需求、动力、能力，从组织思维进行系统改善。
          </div>
          <target 
            :activity="team_activity.TVI.score" 
            :leadership="team_leadership.leadership.score" 
            :motivity="team_motivity.team_motivity.score" 
            :quality="team_quality.team_quality.score" 
            :dimension="team_workvalue.top_dimension"
            :theme="theme"
          ></target>
        </div>
      </li>
      <!-- 团队活力 -->
      <li v-show="tabId==1 || long">
        <div class="view">
          <h3>团队活力分析</h3>
          <div class="text">
            <b>团队活力分析：</b>从7个方面探索团队活力激发程度，综合反映了团队文化、管理机制、管理层的领导风格和能力、以及团队成员的心态和能力水平。团队活力因子数据分布在五个区间，不同区间反映不同的状态，“警示区”是您需要特别留意的；“中等”、“良好”、“优秀”是需要定期评估、跟进和提升的。
          </div>
          <div class="text">
            <b>应用建议：</b>关注7个活力因子的分布区间，探讨它们之间的相互关联关系，及早防范团队深层次的风险。
          </div>
          <bar ref="barRow1" :width="width*.85" :data="team_activity.dimension" :animate="!long" :onclick="true" :label="'高层感知水平'"/>

          <h3>提示</h3>
          <div class="text">
            <b>提示：</b>以测评数据为基础，从单个活力因子和团队整体两个层面进行提示，避免团队发展可能面临的阻碍。
          </div>
          <div class="word" v-if="team_activity.low_dimension.length || team_activity.top_dimension.length">
            <div>活力因子</div>
            <div class="risk risk1" v-for="(item,i) in team_activity.low_dimension" :key="i">
              <b>{{item.name}}</b>：{{item.content}}
            </div>
            <div class="risk risk1" v-for="(item,i) in team_activity.top_dimension" :key="i">
              <b>{{item.name}}</b>：{{item.content}}
            </div>
          </div>

          <div class="word" v-if="team_risk.length">
            <div>团队预警</div>
            <div 
              class="risk risk2" 
              :class="{risk3: item.risk_level=='danger'}" 
              v-for="(item,i) in team_risk" 
              :key="i"
            >
              <b>{{item.risk_name}}</b>：
              {{item.risk_level=='danger'?'已经形成':'正在形成'}}<br>
              可能影响：{{item.risk_content}}
            </div>
          </div>
        </div>
      </li>
      <!-- 工作价值倾向 -->
      <li class="values" v-show="tabId==2 || long">
        <div class="view">
          <h3>工作价值倾向分析</h3>
          <div class="text">
            <b>工作价值倾向分析：</b>深入了解团队成员对工作的内在需求和期待，通过团队文化、激励机制、优质培训、发展平台等管理方式有效激励团队成员，也可以针对个体成员进行个性化的激励和辅导，持续赋能人才和组织发展。
          </div>
          <div class="text">
            <b>应用建议：</b>根据报告中的激励建议，对照团队激励机制和方法是否能够满足员工的需求，尽快调整团队文化和激励机制，并对团队管理者进行关于沟通和激励的专业训练。
          </div>
          <div class="rank">
            <div class="box" :class="'box'+(i+1)" v-for="(item,i) in team_workvalue.top_dimension" :key="i">{{item.name}}<span>NO.{{i+1}}</span></div>
          </div>

          <h1>激励方向：</h1>
          <p v-for="(e,j) in team_workvalue.top_dimension" :key="j">{{e.content}}</p>
        </div>
      </li>
      <!-- 领导能量 -->
      <li v-show="tabId==3 || long" v-if="leaderShow">
        <div class="view">
          <h3>领导能量分析</h3>
          <div class="text">
            <b>领导能量分析：</b>从6个领导行为分析管理者的角色认知、领导力认知水平，了解管理者在当下的领导行为是否匹配组织的需要，领导行为数据将分布在五个区间，不同区间反映不同的状态，“警示区”是您需要特别留意的；“中等”、“良好”、“优秀”是需要定期评估、跟进和提升的。
          </div>
          <div class="text">
            <b>应用建议：</b>结合团队管理者的现状和能力水平，按6个领导行为的数据，制定合宜、针对性的领导力发展计划。
          </div>
          <bar ref="barRow1" :width="width*.85" :data="team_leadership.dimension" :color="['#51b8fb','#28c8b7','#4c88f3','#ed7d31','#6c7bf1','#e96842']" :animate="!long" :label="'高层感知水平'"/>
          <h3>提示</h3>
          <div class="text">
            <b>提示：</b>以测评数据为基础，从关键领导行为进行提示，及时改善，避免可能阻碍领导能量发挥的行为。
          </div>
          <div class="word" v-if="team_leadership.low_dimension.length || team_leadership.top_dimension.length">
            <div></div>
            <div class="risk risk1" v-for="(item,i) in team_leadership.low_dimension" :key="i">
              <b>{{item.name}}</b>：{{item.content}}
            </div>
            <div class="risk risk1" v-for="(item,i) in team_leadership.top_dimension" :key="i">
              <b>{{item.name}}</b>：{{item.content}}
            </div>
          </div>
        </div>
      </li>
      <!-- 组织活力 -->
      <li class="org" v-if="tabId==5 || long">
        <div class="view">
          <h3>组织活力分析</h3>
          <organize :click="!long" :dimension="organize.dimension" :data="organize.dimension_region"/>
          <p>点在圈内，表示该区域需要改善提升的空间较大，请参照该区域相关要素提升完善。</p>
          <p>点在圈外，表示该区域水平较好，但依然可以参照相关要素进一步提升。</p>
          <h3>改善建议</h3>
          <dl>
            <dt @click="openA=!openA" :class="{fold:openA,'flex-b':!long}">A区：组织动能</dt>
            <dd :class="{open:openA||long}">
              <h4>以下这些要素可以帮助组织创建一个能够吸引、发展、激励员工的环境，有助于提高组织的员工满意度、绩效、创新能力以及长期的成功。组织动能关注于人力资源的管理和发展，是组织战略的关键组成部分。</h4>
              <h5>基本需求：</h5>
              <h6>薪酬、福利、工作环境等满足员工的基本需求，这是吸引和留住员工的基础。</h6>
              <h5>心理需求：</h5>
              <h6>提供具有挑战性的工作任务、职业发展机会、以及让员工获得成就感的机制，能够满足员工的心理需求。</h6>
              <h5>成长需求：</h5>
              <h6>关注员工的职业成长，提供培训、学习、晋升机会，使员工感到工作有目的和意义。</h6>
              <h5>人才规划：</h5>
              <h6>制定人才储备和发展计划，以确保组织拥有适应未来需求的合适人才。</h6>
              <h5>人才评价：</h5>
              <h6>建立绩效评价体系，帮助了解员工的表现、强项和改进方向。</h6>
              <h5>人才发展：</h5>
              <h6>提供培训、跨部门机会和发展计划，支持员工在组织内部成长。</h6>
              <h5>领导力：</h5>
              <h6>培养和发展领导力，确保领导者具备激发团队和实现组织目标的能力。</h6>
              <h5>绩效管理：</h5>
              <h6>跟踪和管理员工的绩效，确保目标的实现和组织整体绩效的提升。</h6>
              <h5>激励体系：</h5>
              <h6>建立有效的激励体系，包括薪酬、奖励、晋升等，以激励员工为组织创造价值。</h6>
              <h5>敬业度：</h5>
              <h6>关注员工的敬业度，即员工对工作的投入程度。高敬业度的员工更有可能持续为组织做出积极贡献。</h6>
            </dd>
            <dt @click="openB=!openB" :class="{fold:openB,'flex-b':!long}" style="background:#9e80f1">B区：组织战略</dt>
            <dd :class="{open:openB||long}">
              <h4>以下这些要素通常在战略制定和执行的过程中相互交织，共同构成一个全面的组织战略。同时，这些要素也需要与组织的目标、资源、市场定位等因素相互关联，以确保战略的一致性和可行性</h4>
              <h5>使命、愿景、文化：</h5>
              <h6>组织战略的起点通常涉及明确使命（组织存在的目的）、愿景（组织未来的理想状态）以及核心价值观。这些元素为战略制定提供了一个明确的方向，指导组织在未来发展中追求什么。</h6>
              <h5>订立目标：</h5>
              <h6>制定明确的目标是组织战略的关键步骤。这些目标应该是具体、可衡量的，有助于指导组织在战略实施过程中追求的结果。</h6>
              <h5>战略规划与执行：</h5>
              <h6>这涉及到如何将制定的战略转化为实际行动。战略规划涉及资源分配、时间表、策略选择等。执行阶段涉及具体的项目和行动，确保战略能够在组织内部落地。</h6>
              <h5>战略澄清：</h5>
              <h6>战略澄清确保组织内部所有成员都理解并支持所制定的战略。澄清战略可以减少误解和混淆，确保组织内部协调一致地朝着相同的方向努力。</h6>
              <h5>内外部环境分析：</h5>
              <h6>在制定战略时，了解内外部环境非常重要。这包括了解市场、竞争、技术发展、政策变化等外部因素，以及内部资源、能力、人才等因素。环境分析有助于制定符合实际情况的战略。</h6>
              <h5>文化建设：</h5>
              <h6>组织文化在战略的实施中起到至关重要的作用。文化可以影响员工行为、决策方式、创新能力等。战略的成功实施需要与组织文化相一致，或者在文化方面做出相应调整。</h6>
            </dd>
            <dt @click="openC=!openC" :class="{fold:openC,'flex-b':!long}" style="background:#7bc495">C区：组织执行</dt>
            <dd :class="{open:openC||long}">
              <h4>以下这些要素是组织执行的关键要素之一。它们确保组织内部的工作能够有序进行，员工能够发挥潜力，组织能够迅速响应市场变化，同时保持高效和合规。组织的执行力取决于这些要素的有效运作。</h4>
              <h5>职业发展与培训：</h5>
              <h6>职业发展计划和培训是促进员工成长和提高组织绩效的重要手段。组织需要为员工提供发展机会、培训计划，以提高员工的技能和素质。</h6>
              <h5>岗位体系：</h5>
              <h6>岗位体系包括岗位的设计、评价以及相关的权责。明确岗位职责和权责有助于组织内部协作，避免冲突，提高效率。</h6>
              <h5>管控模式：</h5>
              <h6>管控模式涉及组织内部的管理和监督方式。明确的管控模式可以帮助确保战略的执行，确保各部门和团队按照战略目标运作。</h6>
              <h5>流程制定：</h5>
              <h6>明确的流程制定有助于组织内部的工作协同，避免重复劳动，提高效率。流程可以涵盖各种方面，包括生产、销售、服务等。</h6>
              <h5>制度设计：</h5>
              <h6>制度设计包括内部规章制度和流程，以确保组织的正常运转和合规性。良好的制度设计可以提高组织的纪律性和稳定性。</h6>
              <h5>组织结构：</h5>
              <h6>组织结构明确了不同部门、团队之间的关系，以及决策权和沟通渠道。合适的组织结构有助于协调工作，提高效率。</h6>
            </dd>
            <dt @click="openD=!openD" :class="{fold:openD,'flex-b':!long}" style="background:#78d2e0">D区：组织协作</dt>
            <dd :class="{open:openD||long}">
              <h4>以下这些要素共同构成了一个良好的协作环境。在一个协作良好的组织中，成员之间目标明确、责权明晰、相互信任、高效沟通、主动参与、关注整体利益，并持有服务精神，这样的组织能够更好地应对挑战并实现共同的成功。</h4>
              <h5>目标明确：</h5>
              <h6>在协作中，明确的共同目标对于团队的行动和努力至关重要。清晰的目标可以帮助团队成员理解他们的工作如何与整体目标相关联，从而更好地协同工作。</h6>
              <h5>职责权力清晰：</h5>
              <h6>在团队中，每个成员的职责和权力都应该明确界定。这有助于避免重复工作、减少冲突，并确保工作流程的高效进行。</h6>
              <h5>信任：</h5>
              <h6>信任是协作的基础。团队成员需要相信彼此的能力和承诺，以建立良好的合作关系。</h6>
              <h5>沟通：</h5>
              <h6>有效的沟通是协作的关键。团队成员应该能够开放地交流信息、意见和反馈，以确保信息流动畅通，并减少误解。</h6>
              <h5>做事主动：</h5>
              <h6>团队成员应该具备主动性，愿意积极参与工作和解决问题。这有助于提高效率和团队整体的积极性。</h6>
              <h5>利他精神：</h5>
              <h6>利他精神是指关注团队和集体的利益，而不仅仅关注个人利益。在协作中，团队成员应该愿意帮助他人、分享知识，以促进整个团队的成功。</h6>
              <h5>服务精神：</h5>
              <h6>在协作中，以服务为导向的态度可以促进团队的和谐发展。团队成员应该愿意为团队的共同目标和其他成员的需求提供支持和帮助。</h6>
            </dd>
          </dl>
        </div>
      </li>
    </ul>
    <div class="imgBox" v-if="reportImgShow">
      <p class="text">长按保存图片</p>
      <div class="inner-box"><img :src="reportImg"></div>
      <img @click="closeImg" class="close" src="../../../assets/images/icons/close.png"/>
    </div>
    <div class="mask" v-if="!success"/>
  </div>
</template>
<script>
import fan from '../vigour/chart/fanChart'
import target from '../vigour/chart/targetChart'
import bar from '../vigour/chart/barSvgChart'
import organize from './chart/organize'
// import barRow from '../vigour/chart/barRowChart'
// import waveBall from '../vigour/chart/waveBallChart'
// import lineRectChart from '../vigour/chart/lineRectChart'
export default {
  components:{bar,target,fan,organize},
  props:{
    report_id: {default: null},
    version: {default: ''}
  },
  data(){
    return{
      theme: this.$route.query.theme || '',
      width: 0,
      tabId: 0,
      success: false,
      hide: this.$route.query.hide || false,                //
      hy_session: this.$route.query.hy_session || '',
      long: this.$route.query.long===undefined?false:true,  //是否报告长图
      org_name: '正在加载',                                  //企业名
      endTime: 0,                                           //报告生成成时间
      totalNum: 0,
      reportImg: '',
      reportImgShow: false,
      timer: '',
      // 团队基本信息：
        basic_info:{
          avg_age: 0,
          educational_info: [
            {name: "高中及以下",number:0,rate:0},
            {name: "大专",number:0,rate:0},
            {name: "本科",number:0,rate:0},
            {name: "硕士研究生",number:0,rate:0},
            {name: "博士及博士后",number:0,rate:0},
          ],
          era_info: [
            {name: "25以下",number:0,rate:0},
            {name: "26~35",number:0,rate:0},
            {name: "36~45",number:0,rate:0},
            {name: "46~55",number:0,rate:0},
            {name: "55以上",number:0,rate:0},
          ],
          sex_info: [
            {name: "男性",number:0,rate:0},
            {name: "女性",number:0,rate:0}
          ],
          tier_info: [
            {name: "一线员工",number:0,rate:0},
            {name: "基层管理者",number:0,rate:0},
            {name: "中层管理者",number:0,rate:0},
            {name: "高层管理者",number:0,rate:0},
          ],
        },
      // 区域象限
        quadrant: {k:[],p:[],i:[]},
      // 团队活力度：
        team_activity: {
          consistency: null,
          dimension: [],
          top_dimension: {content:'',name:''},
          low_dimension: {content:'',name:''},
          TVI: {score: 0, level: ''},
        },
        activeScore: 0,
      // 原动力：
        team_motivity: {
          consistency: null,
          dimension: [],
          team_motivity: {score: 0,}
        },
      // 职业素质:
        team_quality: {
          consistency: null,
          dimension: [],
          team_quality: {score: 0}
        },
      // 领导能量：
        team_leadership: {
          dimension: [],
          leadership: {score: 0,level: ''},
          low_dimension: [],
          top_dimension: [],
        },
        leaderShow: true,
      // 工作价值倾向：
        team_workvalue:{
          consistency: null,
          dimension: [],
          top_dimension: ['','',''],
          wordText: [ [], [], [], [], ],//高中低维度
        },
      // 团队风险：
        team_risk: [],
      // 组织活力：
        organize: {
          dimension: [], 
          dimension_region: [{score:0}, {score:0}, {score:0}, {score:0}]
        },
      openA: false,
      openB: false,
      openC: false,
      openD: false,
    }
  },
  created(){
    this.$indicator.open({text: '加载中...',spinnerType: 'fading-circle'});
    this.$http.get(this.spaceUrl+'/Tvs/getTeamReportInfo?team_report_id='+this.report_id+'&is_share=1').then((e) =>{
      this.$indicator.close();
      let data = e.data.data
      if (e.data.code==0) {
        this.success = true
        //团队基本信息：
          this.org_name = data.team_info.org_name
          this.endTime = data.team_info.report_time
          let total = this.totalNum = data.team_info.total_number
          this.basic_info = data.basic_info
        // 风险
          let arr = []
          for (let i in data.team_risk) {
            if (data.team_risk[i].is_show) arr.push(data.team_risk[i])
          }
          this.team_risk = arr
        // 区域象限
          this.quadrant = data.quadrant
        // 团队活力度：
          let activity = data.team_activity
          activity.consistency = {4: activity.consistency}  //key只为控制折线颜色
          this.team_activity = activity
          this.activeScore = activity.TVI.score

        // 原动力：
          this.team_motivity = data.team_motivity
        // 职业素质：
          this.team_quality = data.team_quality
        // 领导能量：
          if(data.team_leadership && data.team_leadership.leadership)
            this.team_leadership = data.team_leadership
          else
            this.leaderShow = false
        // 工作价值倾向：
          let workvalue = data.team_workvalue, wordText = [[],[],[],[]]
          for(let i in workvalue.consistency){
            if(workvalue.consistency[i].dispersion>0.8){ //低
              wordText[3].push(workvalue.consistency[i].name)
            }else if(workvalue.consistency[i].dispersion>.3){ //中等
              wordText[2].push(workvalue.consistency[i].name)
            }else if(workvalue.consistency[i].dispersion>.15){ //中上
              wordText[1].push(workvalue.consistency[i].name)
            }else //高
              wordText[0].push(workvalue.consistency[i].name)
          }
          workvalue.wordText = wordText
          workvalue.consistency = {5: workvalue.consistency}  //key只为控制折线颜色
          this.team_workvalue = workvalue
        // 组织活力：
          this.organize = data.organizational_vitality_model
      } else {
        this.org_name = '无法加载'
        this.$toast({message:'加载失败',duration: 2000})
      }
    }).catch((err)=>{
      this.org_name = '无法加载'
      this.success = false
      this.$indicator.close();
      console.error(err);
      if (err.message.indexOf('org_name') > -1) {//code=0接口报错
        this.$toast({message:'报告错误，请联系管理员',duration: 5000})
      } else {
        this.$toast({message:'网络错误',duration: 1500})
      }
    })
  },
  mounted(){
    this.width = document.getElementById('report').clientWidth
  },
  methods:{
    tab(e){
      if (this.leaderShow) this.$refs.barRow1.showIndex = null
      if (this.tabId == e) return;

      this.activeScore = 0;
      this.tabId = e;
      //第一页需要改变数据达到动画效果
      if (this.tabId == 0) {
        setTimeout(() => {this.activeScore = this.team_activity.TVI.score}, 50)
      } else {
        this.activeScore = 0
      }
    },
    save(){
      // if (this.ajaxUrlPath.indexOf('app')>-1){
        // this.long = true; return;
      // }
      if (this.reportImg)
        this.reportImgShow = true
      else
        this.$http.get(this.spaceUrl+'/Tvs/getTeamReportImage?team_report_id='+this.report_id+'&hy_session='+this.hy_session).then((e) =>{
          if (e.data.code == 0) {//已保存了长图
            this.reportImg = e.data.data
            this.reportImgShow = true
          } else {//新生成长图
            this.$indicator.open({text: '图片生成中...',spinnerType: 'triple-bounce'})
            var url = location.href.replace(/http:\/\/localhost/,this.mobileUrl) + (location.hash.includes('?')?'&':'?') + 'long&ver=' + this.version
            this.$http.post(this.ajaxUrlPath+'/CardImage/createshotImg', {
              type: 'tvsTeamReport',
              imageurl: url,
              width: '500',
              height: '1000',
            }).then(e => {
              if (e.data.code == 0) {
                this.$indicator.close()
                this.reportImg = e.data.data.image
                this.reportImgShow = true
                //保存当前报告的图片
                // this.$http.post(this.spaceUrl+'/Tvs/updateTeamReportImage?hy_session=' + this.hy_session,{
                //   team_report_id: this.report_id,
                //   report_image: this.reportImg,
                // })
              }
            })
          }
        })
    },
    closeImg(){
      this.reportImgShow = false
      this.timer && clearInterval(this.timer)
    },
  },
}
</script>
<style scoped lang="scss">
.report{
  color:#666;
  box-shadow: 0 0 10px rgba(200,200,200,.6);
  padding-bottom: 40px;
  min-height: 100%;
  position: relative;
  .hide{height: 0;overflow: hidden;position: absolute;}
  .top{
    color: #808080;
    header{
      display: flex;
      align-items: center;
      font-size: 26px;
      padding: 0 40px;
      height: 120px;
      justify-content: space-between;
      line-height: 1.1;
      .label{
        text-align: left;
        padding-right: 20px;
        font-size: 24px;
        line-height: 1.5;
        b{font-size: 26px}
      }
      .btn{
        width: 130px;
        color: #fff;
        border-radius: 6px;
        background: #2384ff;
        line-height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 24px;
        padding: 0 10px;
        flex-shrink: 0;
        white-space: nowrap;
        margin-left: 10px;
      }
    }
    nav{
      display: flex;
      border-top: 1px solid #f0f0f0;
      border-bottom: 1px solid #f0f0f0;
      color: #999;
      height: 80px;
      box-sizing: border-box;
      font-size: 26px;
      align-items: center;
      span{
        flex: 1;
        line-height: 1.2;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        border-right: 1px solid #f0f0f0;
        width: 25%;
        box-sizing: border-box;
        transition: .1s;
        &:last-child{border: none}
      }
      .current{
        font-size: 28px;
        color: #4d4d4d;
        font-weight: bold;
        background: #cdebff;
        color: #2384ff;
      }
    }
  }
  .content{
    overflow-x: hidden;
    .TVI{
      font-size: 28px;
      font-weight: bold;
      margin-bottom: 50px;
      color: #424242;
    }
    li{
      margin: 0 30px;
      .orange{
        color: #ee7e2e;
        font-size: 28px;
        text-align: left;
        margin: 40px 0 18px 24px;
      }
      h1{
        font-size: 28px;
        text-align: left;
        font-weight: bold;
        margin: 20px 0;
        color: #5c5c5c;
      }
      h3{
        font-size: 28px;
        margin: 40px 0;
        color: #0072ff;
        font-weight: bold;
      }
      p{
        padding-left: 24px;
        text-align: justify;
        font-size: 26px;
        position: relative;
        line-height: 1.5;
        &::before{
          content: '';
          position: absolute;
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background: #7a7a7a;
          left: 4px;
          top: 15px;
        }
      }
      .text{
        text-align: justify;
        font-size: 26px;
        color: #6f6f6f;
        line-height: 1.6;
        b{color: #dd331e}
      }
      .high span{color: #3fb3ff;}
      .high::before{background: #3fb3ff;}
      .low span{color: #e86c52;}
      .low::before{background: #e86c52;}
      .blue{color: #3fb3ff}
      .yellow{color: #ebb748}
      .red{color: #f8887b}
      .green{color: #88b56c}
    }
    .view{
      margin: 30px 0;
      padding: 1px 20px 60px;
      border-radius: 10px;
    }
    .explain{
      .view div{
        text-align: justify;
        font-size: 26px;
        line-height: 1.5;
      }
      .blue{color: #dd331e;}
      b{color: #dd331e;}
      span{color: #dd331e;}
    }
    .message{
      font-size: 24px;
      color: #808080;
      border: 1px solid #6ec6fd;
      margin: 40px 0;
      &>div{display: flex;}
      .above{
        border-bottom: 1px dashed #6ec6fd;
        &>div:nth-child(1){
          border-right: 1px dashed #6ec6fd;
          h5{width: 100px}
          .number{margin-left: 50px}
        }
        &>div:nth-child(2){
          h5{width: 144px}
        }
      }
      .under{
        &>div:nth-child(1){
          border-right: 1px dashed #6ec6fd;
          h5{width: 150px}
        }
        &>div:nth-child(2){
          h5{width: 120px}
          .number{margin-left: 42px;}
        }
      }
      .box{
        box-sizing: border-box;
        padding: 10px 0 40px;
        width: 50%;
        position: relative;
        .line{
          display: flex;
          line-height: 24PX;
          height: 24PX;
          margin: 16px 0;
          svg{
            flex-shrink: 0;
            margin: -2px 10px 0 12px;
          }
        }
        h4{
          font-size: 26px;
          width: 160px;
          background: #3fb3ff;
          margin: 20px auto 40px;
          border-radius: 45px;
          color: #fff;
          line-height: 2;
        }
        h5{
          text-align: right;
          white-space: nowrap;
          font-size: 24px;
        }
        h6{
          white-space: nowrap;
          font-size: 24px;
        }
        .number{
          text-align: left;
          font-size: 24px;
        }
      }
    }
    .messageMini .box .line svg{//小屏的团队基本信息缩小间距
      margin-top: -2px;
    }
    .values{
      h1{margin-top: -40px}
    }
    .word{
      padding-left: 30px;
      margin: 40px 0;
      div{
        &:nth-child(1){
          font-weight: bold;
          color: #dd331e;
          line-height: 2;
          font-size: 28px;
        }
        font-size: 26px;
        text-align: justify;
      }
      .risk{
        position: relative;
        line-height: 1.5;
        &::before{position: absolute;content: '';}
        b{color: #545454;}
      }
      .risk1::before{
        background: url('../../../assets/images/report/risk.png') no-repeat;
        background-size: 100%;
        width: 28px;
        height: 28px;
        top: 4px;
        left: -36px;
      }
      .risk2::before{
        background: linear-gradient(to right,#ffcdc7 50%,#dd331e 50%);
        border-radius: 50%;
        width: 22px;
        height: 22px;
        top: 8px;
        left: -32px;
      }
      .risk3::before{background: #dd331e}
    }
    .area{
      margin: 20px 40px 50px;
      & > div{
        display: flex;
        margin: 30px 0;
        & > div{
          flex: 1;
          h5:nth-child(1){border-bottom: none}
        }
        h4{
          min-height: 210px;
          color: #fff;
          padding: 0 20px;
          div{
            width: 20px;
            height: 20px;
            margin: 6px auto;
            transform: translateY(76px);
          }
          img{width: 100%;}
          b{
            display: block;
            font-size: 26px;
            transform: translateY(76px);
          }
        }
        h5{
          box-sizing: border-box;
          min-height: 105px;
          border: 1px solid #ccc;
          display: flex;
          align-items: center;
          text-align: justify;
          line-height: 1.3;
          padding:10px 0 10px 24px;
          flex-wrap: wrap;
          border-left: none;
          span{
            font-size: 24px;
            margin-right: 20px;
          }
        }
        
        &:nth-child(1){
          h4{background: #e96842}
          h5{border-color: #e96842;color: #e96842}
        }
        &:nth-child(2){
          h4{background: #42b2fc}
          h5{border-color: #42b2fc;color: #42b2fc}
        }
        &:nth-child(3){
          h4{background: #7886f0}
          h5{border-color: #7886f0;color: #7886f0}
        }
        &:nth-child(4){
          h4{background: #28c8b7}
          h5{border-color: #28c8b7;color: #28c8b7}
        }
      }
    }
    .cognition{
      width: 624px;
      margin: 70px auto;
      & > div{
        width: 50%;
        border-top: 12px solid #ccc;
        border-bottom: 12px solid #ccc;
        height: 180px;
        position: relative;
        &::before{
          content: '';
          position: absolute;
          border-top: 18px solid transparent;
          border-bottom: 18px solid transparent;
          border-right: 24px solid #ccc;
        }
        &::after{
          content: '';
          position: absolute;
          border-top: 18px solid transparent;
          border-bottom: 18px solid transparent;
          border-left: 24px solid #ccc;
        }
        &:nth-child(1){
          border-color: #3db5ff;
          border-left: 12px solid #3db5ff;
          border-top-left-radius: 90px;
          border-bottom-left-radius: 90px;
          &::before{
            border-right: 24px solid #3db5ff;
            top: -24px;
            right: 0;
          }
          &::after{
            border-left: 24px solid #3db5ff;
            bottom: -24px;
            right: 100px;
          }
          .cricle{
            background: #3db5ff;
            span{
              left: 108%;
              color: #3db5ff;
            }
          }
        }
        &:nth-child(2){
          border-color: #ee7e2e;
          border-right: 12px solid #ee7e2e;
          border-top-right-radius: 90px;
          border-bottom-right-radius: 90px;
          margin: -12px 0 0 50%;
          &::before{
            border-right: 24px solid #ee7e2e;
            bottom: -24px;
            left: -24px;
          }
          &::after{
            border-left: 24px solid #ee7e2e;
            top: -24px;
            left: 100px;
          }
          .cricle{
            background: #ee7e2e;
            float: right;
            span{
              right: 108%;
              color: #ee7e2e;
            }
          }
        }
        .cricle{
          width: 136px;
          height: 136px;
          line-height: 136px;
          border-radius: 50%;
          margin: 10px;
          background: #ccc;
          position: relative;
          color: #fff;
          font-size: 25px;
          span{
            line-height: 32px;
            position: absolute;
            width: 430px;
            top: 50%;
            transform: translateY(-50%);
            text-align: left;
          }
        }
      }
    }
    .org{
      p{
        color: #2081a4;
        &::before{
          width: 16px;
          height: 16px;
          background: #f77c03;
          left: 0;
          top: 10px;
        }
      }
      dl{
        text-align: left;
        border-radius: 10px;
        overflow: hidden;
        dt{
          background: #7a99f1;
          color: #fff;
          font-size: 26px;
          padding: 0 20px;
          line-height: 100px;
          position: relative;
        }
        .flex-b::after{
          content: '展开';
          font-size: 24px;
          margin-right: 50px;
        }
        .flex-b::before{
          content: '';
          position: absolute;
          right: 30px;
          top: 36px;
          width: 14px;
          height: 14px;
          border-radius: 2px;
          border-right: 2px solid #fff;
          border-bottom: 2px solid #fff;
          transform: rotate(45deg);
          transform-origin: center;
          transition: .2s;
        }
        .fold::after{content: '收起'}
        .fold::before{transform: rotate(-135deg) translateX(-6px);}
        dd{
          background: rgba(255,255,255,.7);
          font-size: 26px;
          padding: 0 20px;
          overflow: hidden;
          max-height: 0;
          transition: .6s linear;
          &::before, &::after{content: '';display: block;height: 40px;}
          h4{
            color: #4c71db;
            font-weight: bold;
          }
          h5{
            margin-top: 30px;
            color: #4c71db;
            font-weight: bold;
          }
          h6{
            color: #414b5e;
          }
        }
        .open{max-height: 1700px}
      }
    }
  }
  .imgBox{
      width: 100%;
      height: 100%;
      position: fixed;
      background: rgba(0,0,0,0.8);
      top: 0;
      left: 0;
      z-index: 100;
      font-size:30px;
      .text{
        line-height: 100px;
        color:#fff;font-size:26px;
      }
      .inner-box{
        flex: 1;
        height: 80%;
        margin:0 30px 20px;
        overflow-y: auto;
        img{width: 100%;height: auto;display: block;}
      }
      .close{
        display: block;
        width: 70px;
        height: 70px;
        margin:0 auto;
      }
  }
  .mask{
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 100;
  }
  .rank{
    position: relative;
    width: 388px;
    height: 380px;
    margin: 50px auto 60px;
    color: #fff;
    font-size: 26px;
    .box{
      position: absolute;
      box-sizing: border-box;
      border-radius: 50%;
      border: 6px solid #f2f2f2;
      line-height: 1.6;
      font-weight: bold;
      top: 50%;
      span{display: block;font-weight: normal}
    }
    .box1{
      width: 240px;
      height: 240px;
      padding-top: 80px;
      margin-top: -120px;
      left: -120px;
      background: #3fb3ff;
      animation: box .6s forwards;
    }
    .box2{
      width: 200px;
      height: 200px;
      margin-top: -100px;
      padding-top: 60px;
      left: 136px;
      background: rgba(78,200,183,0.9);
      animation: box 1s forwards;
    }
    .box3{
      width: 160px;
      height: 160px;
      margin-top: -80px;
      padding-top: 40px;
      left: 350px;
      background: rgba(237,125,49,0.9);
      animation: box 1.4s forwards;
    }
    @keyframes box {
      0%{opacity: 0;transform: translateY(40px)}
      100%{opacity: 1;transform: translateY(0)}
    }
  }
}

.planet{
  background-color: #2da8ff;
  background-image: url('../../../../static/img/planet_bg14.jpg');
  background-repeat: no-repeat;
  background-size: 100% 100%;
  .top{
    color: #fff;
    nav{
      color: #fff;
      border-color: #fff;
      .current{
        background: #c0e9fa;
        color: #414b5e;
      }
    }
  }
  .content{
    .view{
      background: rgba(255,255,255,.8);
    }
    .explain, li .text, li p, li h1{color: #414b5e}
    .word{
      div{color: #414b5e}
      .risk b{color: #414b5e}
    }
  }
}
</style>