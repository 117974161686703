<template>
  <div class="plate">
    <div class="name">
      <div><img src="../../../../assets/images/icons/icon-like.png">{{dimension[0]}}</div>
      <div style="color: #88b56c;"><img src="../../../../assets/images/icons/icon-oil.png">{{dimension[1]}}</div>
    </div>
    <div class="content" :style="{width:width+'px',height:width*.45+'px'}">
      <div class="ring">
        <div class="out" :style="{width:width*.7+'px',height:width*.7+'px'}">
          <div class="in"></div>
        </div>
      </div>
      <p class="p1">50</p>
      <p class="p2">0</p>
      <p class="p3">100</p>
      <div class="center" :style="{width:width*.04+'px',height:width*.04+'px'}"></div>
      <div class="pointer" :class="{move:animate}" :style="{'border-left-width':width*.02+'px','border-right-width':width*.02+'px','border-bottom-width':width*.4+'px','transform': deg}"></div>
    </div>
  </div>
</template>

<script>
// import * as d3 from 'd3'
export default {
  props: {
    width: {    //宽高度
      type: Number,
    },
    dimension:{
      type: Array,
    },
    score:{
      type:Number
    },
    animate:{
      type: Boolean,
      default: false,
    },
  },
  computed:{
    deg(){
      let deg = 1.8 * this.score - 90
      return 'rotate(' + deg + 'deg) translate(-50%)'
    }
  },
}
</script>
<style lang="scss">
.plate{
  font-size: 24px;
  color: #999;
  margin-bottom: 70px;
  .name{
    display: flex;
    justify-content: center;
    div{
      display: flex;
      align-items: center;
      margin: 0 24px;
      color: #f8887b;
      img{
        width: 34px;
        height: 34px;
        margin-right: 6px;
      }
    }
  }
  .content{
    border-bottom: 1px dashed #b3b3b3;
    margin: 0 auto;
    position: relative;
    &::before{
      content: '低';
      position: absolute;
      bottom: 5%;
      left: 0;
    }
    &::after{
      content: '高';
      position: absolute;
      bottom: 5%;
      right: 0;
    }
    p{
      position: absolute;
      transform: translateX(-50%);
      width: 60px;
    }
    .p1{
      top: 8%;
      left: 50%;
    }
    .p2{
      top: 105%;
      left: 18%;
    }
    .p3{
      top: 105%;
      left: 81%;
    }
    .ring{
      overflow: hidden;
      width: 100%;
      height: 100%;
      position: relative;
      .out{
        border-radius: 50%;
        position: absolute;
        left: 50%;
        top: 100%;
        transform: translate(-50%,-50%);
        border: 1px solid #84c55d;
        background: linear-gradient(to right,#aaf67a,#f5796f);
      }
      .in{
        width: 80%;
        height: 80%;
        border-radius: 50%;
        margin: 10% auto;
        border: 1px solid #84c55d;
        background: #fff;
      }
    }
    .center{
      position: absolute;
      top: 100%;
      left: 50%;
      transform: translate(-50%,-45%);
      background: #aaa;
      border-radius: 50%;
    }
    .pointer{
      position: absolute;
      left: 50%;
      bottom: 0;
      border-style: solid;
      border-bottom-color: #aaa;
      border-left-color: transparent;
      border-right-color: transparent;
      border-top: 0;
      transform-origin:0% 100%;
      transform: rotate(-90deg) translate(-50%);
      
    }
    .move{
      transition: transform 1s;
    }
  }
}
</style>
