<template>
  <svg class="pointer">
    <g class="data"></g>
    <g class="bg"></g>
    <defs>
      <path id="txtPath" stroke="#5f9ef4"/>
      <!-- 警示 -->
      <linearGradient id="shadow1" x1="0%" y1="0%" x2="100%" y2="0%">
        <stop offset="10%" stop-color="#ed6c5a"/>
        <stop offset="100%" stop-color="#ee975b"/>
      </linearGradient>
      <!-- 警示 -->
      <linearGradient id="shadow2" x1="0%" y1="0%" x2="100%" y2="0%">
        <stop offset="10%" stop-color="#619df7"/>
        <stop offset="100%" stop-color="#4fd8e4"/>
      </linearGradient>
    </defs>
  </svg>
</template>

<script>
import * as d3 from 'd3'
export default {
  props: {
    data: {
      type: Number,
      default: 0,
    },
    width: {    //宽高度
      type: Number,
    },
    animate:{
      type: Boolean,
      default: false,
    },
  },
  data(){
    return{
      txtList: ['警示','中等','良好','优秀','警示'],
      scale: [40,60,75,90,100.1],
    }
  },
  mounted() {
    if (this.width) {
      this.draw()
    } else {
      this.$watch('width', () => this.draw())
    }
  },
  methods: {
    getRing(r,agl) {
      agl = agl / 360 * 2 * Math.PI
      return [this.cen - Math.sin(agl) * r, this.cen + Math.cos(agl) * r]
    },
    getScore(e) {
      for (let i in this.scale) {
        let last = this.scale[i-1] || 0
        if (e < this.scale[i]) {
          return {
            angle: (e-last) / (this.scale[i]-last) * 36 + 36 * i,
            level: this.txtList[i]
          }
        }
      }
    },
    draw(){
      let width = this.width,
          svg = d3.select(this.$el).attr('width', width).attr('height', width*.7),
          fontSize = width * .048,       //文字大小
          r = width * .18,
          dr = width * .34,
          R = width * .48,               //最大外圆
          cen = this.cen = this.width / 2,
          bg = svg.select('.bg'),
          warn = this.data < 40 || this.data >= 85 ? true: false,
          {angle, level} = this.getScore(this.data);
      //曲线刻度文字
      let txtr = (dr + R) / 2
      let start0 = this.getRing(txtr, 90)
      let end0 = this.getRing(txtr, 270)
      svg.select('#txtPath').attr('d', `M${start0[0]} ${start0[1]} A${txtr} ${txtr} 0 1 1 ${end0[0]} ${end0[1]}`)
      let arcTxt = bg.append('text')
      for (let i in this.txtList) {
        arcTxt.append('textPath')
              .attr('fill', '#527593')
              .attr('xlink:href', '#txtPath')
              .attr('startOffset', `${i*20+10}%`)
              .attr('font-size', fontSize)
              .text(this.txtList[i])
      }
      //外弧线
      let start1 = this.getRing(R, 90)
      let end1 = this.getRing(R, 270)
      bg.append('path')
          .attr('stroke-width', 2)
          .attr('stroke', '#5f9ef4')
          .attr('fill', 'none')
          .attr('d', `M${start1[0]} ${start1[1]} A${R} ${R} 0 1 1 ${end1[0]} ${end1[1]}`)
      for (let i = 0; i < 6; i++) {
        let p = this.getRing(R, 90 + 36 * i)
        bg.append('circle')
          .attr('r', fontSize*.38)
          .attr('cx', p[0])
          .attr('cy', p[1])
          .attr('stroke-width', .5)
          .attr('stroke', '#5f9ef4')
          .attr('fill', '#fff')
      }
      //中弧线
      let start2 = this.getRing(dr, 90)
      let end2 = this.getRing(dr, 270)
      bg.append('path')
        .attr('stroke-width', fontSize)
        .attr('stroke', '#FFF')
        .attr('stroke-linecap', 'round')
        .attr('fill', 'none')
        .attr('d', `M${start2[0]} ${start2[1]} A${dr} ${dr} 0 1 1 ${end2[0]} ${end2[1]}`)
      //圆内文字
      bg.append('text')
        .attr('font-size', fontSize)
        .attr('fill', '#fff')
        .attr('x', cen)
        .attr('y', cen - fontSize)
        .text('综合评估')
      bg.append('text')
        .attr('font-size', fontSize * 1.5)
        .attr('fill', '#fff')
        .attr('x', cen)
        .attr('y', cen + fontSize)
        .text(level)
      //分数弧
      let end  = this.getRing(dr, 90 + angle)
      let girth = angle / 180 * R * Math.PI //周长
      svg.append('path')
          .attr('stroke-width', fontSize)
          .attr('stroke', warn ? 'url(#shadow1)' : 'url(#shadow2)')
          .attr('stroke-linecap', 'round')
          .attr('fill', 'none')
          .attr('d', `M${start2[0]} ${start2[1]} A${dr} ${dr} 0 0 1 ${end[0]} ${end[1]}`)
          .attr("stroke-dasharray", girth)
          .attr("stroke-dashoffset", this.animate ? girth : 0)
          //弧动画
          .append('animate')
          .attr('attributeName', 'stroke-dashoffset')
          .attr('fill', 'freeze')
          .attr('dur', '.6s')
          .attr('from', girth)
          .attr('to', 0)
      //指针
      let data = svg.select('.data'), 
          rx = cen - r*1.2,
          color = warn ? '#ed6c5a' : '#619df7'
      data.append('path')
          .attr('stroke', 'none')
          .attr('fill', color)
          .attr('d', `M${cen} ${cen+r*.5} L${rx} ${cen+r*.05},${rx} ${cen-r*.05},${cen} ${cen-r*.5}`)
      data.append('circle')
          .attr('cx', rx)
          .attr('cy', cen)
          .attr('r', r*.05)
          .attr('stroke', 'none')
          .attr('fill', color)
      data.append('circle')
          .attr('cx', cen)
          .attr('cy', cen)
          .attr('r', r)
          .attr('stroke', 'none')
      data.attr('transform', `rotate(${angle},${cen},${cen})`)
          .attr('fill', warn ? 'url(#shadow1)' : 'url(#shadow2)')
          //指针动画
          .append('animateTransform')
          .attr('attributeName', 'transform')
          .attr('type', 'rotate')
          .attr('fill', 'freeze')
          .attr('dur', '.4s')
          .attr('from', `0 ${cen} ${cen}`)
          .attr('to', `${angle} ${cen} ${cen}`)
    },
  },
}
</script>
<style scope lang="scss">
.pointer{
  display: inline-block;
  text{
    text-anchor: middle;
    dominant-baseline: middle;
  }
}
</style>
