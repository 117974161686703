<template>
  <div class="login" @click="countryCodeShow=false">
    <h1>请先完善手机号</h1>
    <div class="input flex">
      <div class="codeArea flex-c-c" @click.stop="countryCodeShow=!countryCodeShow">
        <label>{{country.label}}</label>
        <span class="triangle"/>
        <ul :style="`transform:scaleY(${countryCodeShow?1:0})`">
          <li v-for="item in $country" :key="item.country_code" @click="country=item">{{item.label}}</li>
        </ul>
      </div>
      <input placeholder="请输入手机号" type="number" v-model="mobile" @input="mobile=mobile.slice(0,11)"/>
      <div class="btn" v-if="second>0">重新获取({{second}}s)</div>
      <div class="btn" v-else @click="getCaptcha">获取验证码</div>
    </div>
    <div class="input fade flex">
      <input placeholder="请输入验证码" type="number" inputmode="numeric" autocomplete="one-time-code" pattern="\d{6}" v-model="captcha" @input="captcha=captcha.slice(0,6)"/>
    </div>
    <div class="finish" @click="login">完 成</div>
  </div>
</template>
<script>
export default {
  data(){
    return {
      mobile: '',
      captcha: '',
      countryCodeShow: false,
      country: {label: '+86', country_code: '86', reg: /^1[3-9]{1}[0-9]{1}[0-9]{8}$/},
      second: 0,
    }
  },
  created(){
    document.title = '完善信息'
  },
  mounted(){},
  methods:{
    getCaptcha() {
      if (!this.mobile) {
        this.$toast({message: '请先输入手机号'})
      } else if (!this.country.reg.test(this.mobile)) {
        this.$toast({message: '手机号码格式不对'})
      } else {
        this.$http.post(this.ajaxUrlPath+'/Bee/getSmsCaptcha', {
          mobile: this.mobile,
          country_code: this.country.country_code,
        }).then(({data}) => {
          if (data.code == 0) {
            this.second = 60
            let timer = setInterval(() => {
              if (--this.second < 0) clearInterval(timer)
            }, 1000)
          } else {
            this.$toast({message: data.msg})
          }
        }, () => this.$toast({message:'网络错误'}))
      }
    },
    login(){
      if (!this.mobile) {
        this.$toast({message: '请先输入手机号'})
      } else if (!this.country.reg.test(this.mobile)) {
        this.$toast({message: '手机号码格式不对'})
      } else if (!this.captcha) {
        this.$toast({message: '请先输入验证码'})
      } else {
        this.$http.post(this.ajaxUrlPath+'/Bee/bindMobile', {
          captcha: this.captcha,
          mobile: this.mobile,
          country_code: this.country.country_code,
        }).then(({data}) => {
          if (data.code == 0) {
            // this.$router.replace({path: path})
            this.$goback()
          } else {
            this.$toast({message: data.msg})
          }
        }, () => this.$toast({message:'网络错误'}))
      }
    }
  },
}
</script>
<style lang="scss" scoped>
.login{
  padding: 1px 50px;
  text-align: left;
  height: 100%;
  h1{
      font-size: 32px;
      text-align: left;
      margin: 120px 0 40px;
      color: #333;
  }
  .input{
    background: #f8f8f8;
    border-radius: 8px;
    margin: 30px 0;
    color: #333;
    .codeArea{
      position: relative;
      label{
        font-size: 26px;
        min-width: 70px;
        text-align: center;
        padding-left: 10px;
      }
      .triangle{
        border-left: 8px solid transparent;
        border-right: 8px solid transparent;
        border-top: 10px solid #333;
        margin: 0 8px;
      }
      ul{
        position: absolute;
        top: 80px;
        min-width: 120px;
        background: #fff;
        border-radius: 4px;
        box-shadow: 0 0 10px rgba(50,50,50,.2);
        font-size: 30px;
        left: 0px;
        transition: transform .1s;
        overflow: hidden;
        transform-origin: 50% 0;
        z-index: 10;
        li{
          line-height: 70px;
          white-space: nowrap;
          padding: 0 20px 0 30px;
        }
      }
    }
    input{
      font-size: 28px;
      color: #262626;
      flex: 1; 
      text-align: left;
      height: 80px;
      padding: 0 20px;
      width: 200px;
    }
    .btn{
      text-align: center;
      width: 200px;
      line-height: 80px;
      font-size: 26px;
      white-space: nowrap;
    }
    ::placeholder{color: #ccc}
  }
  .finish{
    text-align: center;
    line-height: 80px;
    margin-top: 100px;
    background: #f8f8f8;
    font-size: 32px;
    font-weight: normal;
    color: #fff;
    border-radius: 8px;
    background:  #161824;
  }
}
</style>