<template>
  <div class="report" id="VC">
    <header>
      <h2><b>{{info.project_name}}</b> - 创投项目测评</h2>
      <h2>{{$formatTime(info.end_time, 'y-m-d h:m')}}<span v-if="version">版本号 v{{version}}</span></h2>
    </header>
    <ul class="nav flex-a-c" v-if="!long">
      <li :class="{curr: tab===0}" @click="tab=0">项目信息</li>
      <li :class="{curr: tab===1}" @click="tab=1">项目评分</li>
      <li :class="{curr: tab===2}" @click="tab=2" v-if="answerList.length">答题记录</li>
      <!-- <i :style="{left: width/(answerList.length?3:2)*tab+'px', width: (answerList.length?'33.34%':'50%')}"/> -->
    </ul>
    <div class="container" v-show="tab===0 || long">
      <h3>项目信息</h3>
      <dl class="flex--c">
        <dt class="flex-c-c"/>
        <dd>
          <p><span>公司名称：</span>{{info.company_name}}</p>
        </dd>
      </dl>
      <dl class="flex--c">
        <dt class="flex-c-c"/>
        <dd>
          <p><span>项目名称：</span>{{info.project_name}}</p>
        </dd>
      </dl>
      <dl class="flex--c">
        <dt class="flex-c-c"/>
        <dd>
          <p><span>负责人：</span>{{info.realname}}</p>
        </dd>
      </dl>
      <dl class="flex--c">
        <dt class="flex-c-c"/>
        <dd class="flex">
          <p><span>联系方式：</span>{{info.mobile}}</p>
          <a :href="'tel:'+info.mobile" class="call flex-c-c" v-if="admin"><img src="../../../assets/images/icons/callPhone.png">拨打</a>
        </dd>
      </dl>
      <dl class="flex--c">
        <dt class="flex-c-c"/>
        <dd>
          <p><span>项目赛道：</span>{{info.track_name}}</p>
        </dd>
      </dl>
      <dl class="flex--c">
        <dt class="flex-c-c"/>
        <dd>
          <p><span>融资需求：</span>{{info.require_option}}</p>
        </dd>
      </dl>
      <dl class="flex--c" v-if="info.views_option">
        <dt class="flex-c-c"/>
        <dd>
          <p><span>对企业的看法：</span>{{info.views_option}}</p>
        </dd>
      </dl>
    </div>
    <template v-if="tab===1 || long">
      <div class="container grade">
        <h3>整体评分</h3>
        <bar :score="score" :refer="60" :text="'项目当前评分：'"></bar>
        <h4 class="orange">整体说明：</h4>
        <p>{{desc}}</p>
        <template v-if="risk.length">
          <h4 class="red">风险点：</h4>
          <p v-for="(item,i) in risk" :key="i"><b>{{item.name}}</b>：{{item.content}}</p>
        </template>
      </div>
      <div class="container grade">
        <h3>分项评分</h3>
        <berzier :width="width*.8" :data="dimension"></berzier>
      </div>
    </template>
    <div class="container" v-show="tab===2">
      <h3>答题记录</h3>
      <div class="box" v-for="(item, i) in answerList" :key="i">
        <h5>{{i+1}}. {{item.content}}</h5>
        <p :class="{active:it.selected}" v-for="(it,j) in item.option_list" :key="j">{{it.option_content}}</p>
      </div>
    </div>
  </div>
</template>
<script>
import bar from './chart/bar'
import berzier from './chart/berzier'
export default {
  components: {bar,berzier},
  props: {
    report_id: {default: null},
    hy_session: {default: null},
    version: {default: ''}
  },
  data(){
    return {
      tab: 0,
      width: 0,
      time: '',
      info: {},
      score: 0,
      desc: '',
      risk: [],
      dimension: '',
      answerList: [],
      origin: this.$route.query.origin || 'mobile',             //来源
      admin: this.$route.query.admin || 0,                //是否显示答题记录
      long: this.$route.query.long===undefined ? false : true, //是否报告长图
    }
  },
  mounted(){
    this.width = document.getElementById('VC').clientWidth
  },
  created(){
    this.$indicator.open({text: '加载中...',spinnerType: 'fading-circle'})
    let url = this.planetUrl+'/Venture/getExamReportInfo'
    if (this.origin == 'tiktok') url = this.tiktokUrl + '/Vc/getReportData'
    this.$http.post(url, {
      hy_session: this.hy_session,
      origin: this.origin,
      exam_id: this.report_id,
      is_admin: this.admin
    }).then(({data}) => {
      if (data.code == 0) {
        this.info = data.data.user_info
        this.score = data.data.report_data.score
        this.risk = data.data.report_data.dimension_risk
        this.desc = data.data.report_data.description
        this.dimension = data.data.report_data.dimension
        this.answerList = data.data.answer_list
      } else {
        this.info.project_name = '无法加载'
        this.$toast({message: data.msg})
      }
      this.$indicator.close();
    }).catch((err) => {
      this.info.project_name = '网络错误'
      this.$toast({message: err})
      this.$indicator.close();
    })
  },
  methods:{
    getcopyRight(){
      var y = new Date().getFullYear()
      return `${y-1}-${y}`
    },
  },
}
</script>
<style lang="scss" scoped>
.report{
  overflow: hidden;
  min-height: 100%;
  box-sizing: border-box;
  padding-bottom: 50px;
  user-select: none;
  &::before{
    content: '';
    position: fixed;
    width: 100%;
    height: 100%;
    left: 50%;
    top: 0; 
    transform: translateX(-50%);
    z-index: -1;
    background: url('../../../../static/img/planet_bg16.jpg') no-repeat;
    background-size: 100% 100%;
    max-width: 800px;
  }
  .orange{color: #f77c03 !important}
  .red{color: #e85b43 !important}
  header{
    padding: 40px 30px 30px;
    color: #fff;
    position: relative;
    font-size: 24px;
    h2{
      text-align: left;
      line-height: 1.6;
      position: relative;
      z-index: 2;
      white-space: nowrap;
      b{
        max-width: 300px;
        text-overflow: ellipsis;
        overflow: hidden;
      }
      span{
        margin-left: 20px;
      }
    }
  }
  .nav{
    font-size: 26px;
    color: #fff;
    border-top: 1px solid rgba(255,250,240,.85);
    border-bottom: 1px solid rgba(255,250,240,.85);
    margin-bottom: 50px;
    position: relative;
    li{
      flex: 1;
      box-sizing: border-box;
      padding: 0 16px;
      line-height: 54px;
      text-align: center;
      transition: .3s;
      cursor: pointer;
      border-right: 1px solid rgba(255,250,240,.85);
      &:last-child{border: none}
    }
    .curr{
      color: #5e5341;
      font-weight: bold;
      background: rgba(255,250,240,.85);
    }
    i{
      position: absolute;
      top: 0;
      left: 0;
      width: 33.34%;
      height: 100%;
      background: rgba(255,250,240,.85);
      transition: .1s;
      z-index: -1;
    }
  }
  .container{
    margin: 0 24px 30px;
    background: rgba(255,250,240,.85);
    border-radius: 12px;
    padding: 20px 0 60px;
    position: relative;
    z-index: 3;
    h3{
      font-size: 30px;
      color: #5e5341;
      padding: 40px 0;
      text-align: center;
      font-weight: bold;
    }

    dl{
      margin: 0 20px 30px;
      dt{
        width: 38px;
        height: 38px;
        border-radius: 50%;
        border: 6px solid #f77c03;
        background: #fffcf7;
        position: relative;
        &::before{
          content: '';
          display: block;
          width: 18px;
          height: 18px;
          background: #f77c03;
          border-radius: 50%;
        }
        &::after{
          content: '';
          position: absolute;
          left: 50%;
          top: 102%;
          transform: translateX(-50%);
          width: 1px;
          height: 130px;
          background: #f77c03;
        }
      }
      &:last-child dt::after{display: none;}
      dd{
        background: rgba(255,255,255,.8);
        border-radius: 10px;
        flex: 1;
        margin-left: 40px;
        position: relative;
        &::before{
          content: '';
          position: absolute;
          top: 50%;
          left: -20px;
          border-top: 10px solid transparent;
          border-bottom: 10px solid transparent;
          border-right: 20px solid rgba(255,255,255,.8);
          transform: translateY(-50%);
        }
        p{
          padding: 30px 40px;
          line-height: 1.5;
          flex: 1;
          text-align: justify;
          font-size: 28px;
          color: #5e5341;
          span{color: #f77c03}
        }
        .call{
          background: #f77c03;
          color: #fff;
          font-size: 30px;
          padding: 0 30px;
          border-top-right-radius: 10px;
          border-bottom-right-radius: 10px;
          text-decoration: none;
          img{
            width: 28px;
            height: 28px;
            margin-right: 12px;
          }
        }
      }
    }

    .box{
      margin: 0 20px 30px;
      padding: 24px 20px 20px;
      background: rgba(255,255,255,.8);
      border-radius: 10px;
      font-size: 28px;
      text-align: justify;
      h5{
        color: #f77c03;
        margin-bottom: 20px;
      }
      p{
        margin: 12px 0;
        padding: 20px;
        border: 1px solid #cbc6bd;
        border-radius: 10px;
        color: #5e5341;
      }
      .active{
        background: #f77c03;
        border-color: #f77c03;
        color: #fff
      }
    }
  }
  .grade{
    font-size: 28px;
    h4{
      margin: 50px 30px 0;
      text-align: left;
    }
    p{
      text-align: justify;
      color: #5e5341;
      line-height: 1.5;
      margin: 0 30px;
    }
  }
  .copyright{
    font-size: 20px;
    color: #ccc;
  }
}

</style>