<template>
  <div class="product">
    <div class="box">
      <dl v-for="(item,index) in product" :key="index" @click="tolink(item.product_id)">
        <dt :class="'pro'+index">
          <img :src="item.img">
        </dt>
        <dd>{{item.name}}</dd>
      </dl>
    </div>
  </div>
</template>
<script>
export default {
  data(){
    return {
      product: [
        {name: 'AMT领导者画像', img: require('../../../assets/images/product/pro_amt.png'), product_id: 11},
        {name: '职涯优选CBC', img: require('../../../assets/images/product/pro_cbc.png'), product_id: 6},
      ],
    }
  },
  created(){
    document.title = '产品与服务'
    // this.$http.get(this.spaceUrl+'/Product/index',).then(e => {
    //   if (e.data.code == 0) {
    //     console.log(e.data)
    //   } else {
    //     console.log(e.data)
    //   }
    // })
  },
  methods:{
    tolink(id){
      let ua = navigator.userAgent.toLowerCase();
      if (ua.match(/MicroMessenger/i) != 'micromessenger') {
        this.$toast({message: '请在微信内打开'});
        return
      }
      sessionStorage.wxPayProductId = id
      this.$router.push({path: '/wxPay/index',query:{productId:id}});
    },
  }
}
</script>
<style lang="scss" scoped>
.product{
  height: 100%;
  background: #f1f5f7;
  padding: 30px;
  .box{
    display: flex;
    flex-wrap: wrap;
  }
  dl{
    background: #fff;
    border-radius: 10px;
    display: block;
    margin: 10px;
    padding: 20px;
    dt{
      width: 285px;
      height: 285px;
      background-image: linear-gradient(-50deg,#5757f7,#939dff);
      img{width: 100%;display: block}
    }
    .pro1{
      background-image: linear-gradient(-50deg,#4889f8,#abcfff);
    }
    dd{
      margin: 20px 0 4px;
    }
  }
}
</style>