<template>
  <div class="community">
    <h1>推荐</h1>
    <ul class="flex">
      <!-- <li>
        <img class="image" src="../../../assets/images/bee/1.jpg">
        <h2>读书会高情商表达高情商表达高情商表达高情商表达</h2>
        <div class="logo flex--c">
          <img src="../../../assets/images/bee/logo_qingniao.png">青鸟汇
        </div>
        <h3>2023-08-17</h3>
      </li> -->
      <li v-for="(item,i) in list" :key="i" @click="show(i)">
        <img class="image" :src="item.thumb_img">
        <h2>{{item.title}}</h2>
        <div class="logo flex--c">
          <img :src="item.avatar">{{item.username}}
        </div>
        <h3>{{item.push_time}}</h3>
      </li>
    </ul>
  </div>
</template>
<script>
export default {
  data(){
    return {
      page: 1,
      list: [],
      total: 0,
    }
  },
  created(){
    this.getlist(1)
  },
  mounted(){
    window.onscroll = () => {
      var scrollTop = document.documentElement.scrollTop || document.body.scrollTop,
          clientHeight = document.documentElement.clientHeight || document.body.clientHeight,
          scrollHeight = document.documentElement.scrollHeight || document.body.scrollHeight;
      if (scrollTop + clientHeight >= scrollHeight) {
        console.log(this.page)
      }
    }
  },
  destroyed() {  
    window.onscroll = null
  },
  methods:{
    getlist(p) {
      if (p == 1) this.page = 1
      this.$http.post(this.ajaxUrlPath+'/BeeCommunity/getList', {
        page: this.page++,
        per_page: 20,
      }).then(({data}) => {
        if (data.code == 0) {
          this.list = data.data.data
          this.total = data.data.total
        } else {
          this.$toast({message: data.msg})
        }
      }, () => this.$toast({message:'网络错误'}))
    },
    show(i) {
      sessionStorage.setItem('html', this.list[i].content)
      this.$router.push({path: '/bee/html'})
    }
  },
}
</script>
<style lang="scss" scoped>
.community{
  text-align: left;
  height: 2000px;
  h1{
    font-size: 28px;
    color: #292929;
    font-weight: bold;
    padding: 30px 32px 10px;
  }
  ul{
    flex-wrap: wrap;
    padding: 0 15px;
    li{
      width: 330px;
      margin: 15px;
      border: 1px solid #f6f6f6;
      border-radius: 10px;
      padding-bottom: 4px;
      overflow: hidden;
      .image{
        display: block;
        width: 100%;
        // height: 180px;
        background: rgb(151, 211, 151);
      }
      h2{
        color: #292929;
        font-weight: bold;
        font-size: 28px;
        margin: 20px;
        line-height: 32px;
        height: 64px;
        overflow: hidden;
        text-overflow: ellipsis;
        text-overflow: -o-ellipsis-lastline;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        line-clamp: 2;
        -webkit-box-orient: vertical;
      }
      .logo{
        font-size: 22px;
        color: #8f9192;
        margin: 10px 16px;
        img{
          width: 36px;
          height: 36px;
          margin-right: 8px;
          border-radius: 50%;
        }
      }
      h3{
        font-size: 22px;
        color: #8f9192;
        margin: 16px 20px;
      }
    }
  }
}
</style>