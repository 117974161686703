<template>
  <div class="bar-row">

    <div class="leftLabel">
      <!-- <h1>{{h1}}</h1> -->
      <div v-for="(item,index) in dataArr" :key="item.id" @click="toggle(index)" :style="{'line-height':lineHeight+'px'}">{{item[0].name}}</div>
    </div>
    <div class="content">
      <div class="topLabel">
        <div>
          <span>警示</span>
        </div>
        <div>
          <span>中等</span>
        </div>
        <div>
          <span>良好</span>
        </div>
        <div>
          <span>优秀</span>
        </div>
        <div style="border:none;">
          <span>警示</span>
        </div>
      </div>
      <div class="barBox">
        <ul v-for="(item,i) in dataArr" :key="i">
          <li :class="'bar'+it.tierId" v-for="(it,j) in item" :key="j" :style="{width:setWidth(it.score)}"><i></i></li>
        </ul>
      </div>
    </div>

    <!-- <p v-if="click">点击维度名称可查看说明</p>
    <div class="tip" v-if="showIndex!=null">
      <h6>{{data[showIndex].dimension_detail}}</h6>
      <div class="close" @click="toggle(null)">关 闭</div>
    </div> -->
  </div>
</template>

<script>
export default {
  props: {
    data: {
      default: {},
    },
    h1: {
      type: String,
      default: ''
    },
    // click: {
    //   type: Boolean,
    //   default: false
    // },
  },
  data(){
    return{
      min: 30,
      max: 90,
      range: [30,40,50,60,80,90],
      showIndex: null,
      dataArr: [],
      lineHeight: 10,
    }
  },
  created(){
    var init = (e) => {
      let value = Object.values(e),key = Object.keys(e)
      for (let i in value) {
        for (let j in value[i]) {
          value[i][j].tierId = key[i]
        }
      }
      this.lineHeight = value.length * 9 - 3
      this.dataArr = value[0].map(function (col, i) {
        return value.map(function (row) {
            return row[i];
        })
      })
    }
    if (this.data) {
      init(this.data)
      return
    }
    this.$watch('data', () => init(this.data))
  },
  methods:{
    setWidth(num){
      let result = 0;
      num = Math.round(num)
      if (num < this.range[1]) {
        result = (num - this.range[0]) / (this.range[1] - this.range[0]) * .2 
      } else if (num < this.range[2]) {
        result = (num - this.range[1]) / (this.range[2] - this.range[1]) * .2  + .2
      } else if (num < this.range[3]) {
        result = (num - this.range[2]) / (this.range[3] - this.range[2]) * .2  + .4
      } else if (num < this.range[4]) {
        result = (num - this.range[3]) / (this.range[4] - this.range[3]) * .2  + .6
      } else {
        result = (num - this.range[4]) / (this.max - this.range[4]) * .2  + .8
      }
      return result * 100 + '%'
    },
    // toggle(e){
    //   if(this.click) this.showIndex = e
    // }
  },
}
</script>
<style lang="scss" scope>
.bar-row{
  display: flex;
  font-size: 24px;
  padding: 70px 0 20px;
  margin: 40px 0 20px 0;
  position: relative;
  .leftLabel{
    width: 114px;
    height: 100%;
    text-align: left;
    font-size: 24px;
    flex-shrink: 0;
    padding: 1px 0;
    position: relative;
    color: #999;
    div{
      // line-height: 40px;
      margin: 30px 0;
    }
    h1{
      position: absolute;
      top: -50px;
      left: 0;
      color: #525252
    }
  }
  .content{
    flex: 1;
    background: #f7f7f7;
    position: relative;
    .topLabel{
      height: 100%;
      width: 100%;
      display: flex;
      left: 0;
      top: 0;
      position: absolute;
      div{
        flex: 1;
        height: 100%;
        position: relative;
        color: #999;
        border-right: 1px dashed #b4b4b4;
        box-sizing: border-box;
        span{
          position: absolute;
          top: -50px;
          left: 0;
          width: 100%;
        }
        i{
          position: absolute;
          bottom: -40px;
          right: -12px;
        }
        &:nth-child(1){color: #e07556;background: #ffeaea}
        &:nth-child(5){color: #e07556;background: #ffeaea}
      }
    }
    .barBox{
      position: relative;
      z-index: 2;
      height: 100%;
      padding: 1px 0;
      ul{
        margin: 30px 0;
        text-align: right;
        color: #fff;
        padding-right: 10px;
        li{
          height: 6PX;
          background: #42b2fc;
          margin: 3PX 0;
          position: relative;
          width: 0;
          animation: width .6s forwards;
          // transition: width .4s;
          border-top-right-radius: 3PX;
          border-bottom-right-radius: 3PX;
          i{
            position: absolute;
            width: 8PX;
            height: 8PX;
            border-radius: 50%;
            right: 0;
            top: -1PX;
          }
        }
        .bar1{background: #3ba4e8;i{background: #3ba4e8}}
        .bar2{background: #28c8b7;i{background: #28c8b7}}
        .bar3{background: #ed6631;i{background: #ed6631}}
        .bar4{background: #f147bb;i{background: #f147bb}}
      }
    }
  }

  p{
    position: absolute;
    width: 100%;
    bottom: -40px;
    left: 0;
  }
}
// .bar1{
//   .content .barBox{
//   }
// }

.tip{
  position: absolute;
  width: 540px;padding: 30px 40px 10px;
  background: #fff;border-radius: 10px;
  left: 50%;top: 50%;transform: translate(-50%,-50%);
  box-shadow: 0 0 6px 2px rgba(200,200,200,.3);
  animation: dimFade .3s;
  z-index: 3;
  h5{
    font-size: 28px;
    color: #333;
    line-height: 2;
  }
  h6{
    text-align: justify;
    font-size: 24px;
  }
  .close{
    width: 100px;
    color: #42b2fc;
    height:40px;
    display: flex;
    align-items: center; 
    justify-content: center;
    margin: 20px auto 10px;
    border-radius: 6px;
  }
}

@keyframes width {
  0%{width: 0;}
}
</style>
