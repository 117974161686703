<template>
  <div class="classTool">
    <div class="pic">
      <div class="box">
        <h6>我在一个什么样的大环境里<span>社会：</span></h6>
        <div class="circle circle2">
          <h6>我在什么样的位置<span>组织：</span></h6>
        </div>
        <div class="circle circle3">
          <h6>我和哪些人有什么联系<span>群体：</span></h6>
        </div>
        <div class="circle circle4">
          <h6>我的特征<span>个体：</span></h6>
        </div>
      </div>
    </div>
    
    <h1>个体：我的特征</h1>
    <p v-html="content1"></p>
    <h1 class="h2">群体：我和哪些人有什么联系</h1>
    <p>{{content2}}</p>
    <h1 class="h3">组织：我在什么样的位置</h1>
    <p>{{content3}}</p>
    <h1 class="h4">社会：我在一个什么样的大环境里</h1>
    <p>{{content4}}</p>
  </div>
</template>
<script>
export default {
  data(){
    return {
      id: this.$route.query.id || '',
      content1: '',
      content2: '',
      content3: '',
      content4: '',
    }
  },
  created(){
    this.$http.get(this.planetUrl+'/CourseAuxiliary/getDetail?id='+this.id).then(({data}) => {
      if (data.code == 0) {
        this.content1 = data.data.field_content1
        this.content2 = data.data.field_content2
        this.content3 = data.data.field_content3
        this.content4 = data.data.field_content4
      }
    })
  },
  mounted(){},
  methods:{},
}
</script>
<style lang="scss" scoped>
.classTool{
  padding: 1px 0;
  min-height: 100%;
  background: #1d212d;
  color: #fff;
  .pic{
    margin: 30px 30px 60px;
    padding: 30px;
    background: #2d2b43;
    border-radius: 10px;
    text-align: center;
    font-size: 24px;
    position: relative;
    .box{
      height: 460px;
      border: 4px solid #7386ff;
      border-radius: 50%;
      position: relative;
    }
    h6{
      position: absolute;
      right: 90px;
      width: 30px;
      font-size: 24px;
      text-align: center;
      top: 50%;
      transform: translateY(-50%);
      line-height: 1;
      span{
        width: 20px;
        word-wrap: break-word;
        word-break: normal;
        color: #8493f1;
        display: block;
        position: absolute;
        left: 40px;
        top: 50%;
        transform: translateY(-50%);
      }
    }
    .circle{
      position: absolute;
      left: -4px;
      top: 50%;
      transform: translateY(-50%);
      border: 4px solid #559db5;
      border-radius: 50%;
    }
    .circle2{
      border-color: #559db5;
      height: 360px;
      width: 480px;
      span{color: #75cdea;}
    }
    .circle3{
      border-color: #987df6;
      height: 280px;
      width: 330px;
      span{color: #987df6;}
    }
    .circle4{
      border-color: #d66ab4;
      height: 160px;
      width: 190px;
      span{color: #d66ab4;}
    }
  }
  h1{
    font-size: 26px;
    margin: 0 30px;
    line-height: 60px;
    padding-left: 30px;
    position: relative;
    text-align: left;
    &::before{
      content: '';
      position: absolute;
      width: 18px;
      height: 18px;
      border-radius: 50%;
      background: #d66ab4;
      left: 0;
      top: 50%;
      margin-top: -10px;
    }
  }
  .h2::before{background: #987df6;}
  .h3::before{background: #75cdea;}
  .h4::before{background: #8493f1;}
  p{
    display: block;
    font-size: 28px;
    margin: 10px 60px 60px;
    text-align: justify;
    word-break: break-all;
    white-space: pre-wrap;
  }
}
</style>